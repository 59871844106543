define('diesel/initializers/flash-messages-service', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(application) {
    application.inject('route', 'flashMessages', 'service:flash-messages');
    application.inject('controller', 'flashMessages', 'service:flash-messages');
  }

  exports['default'] = {
    name: 'flash-messages-service',
    initialize: initialize
  };
});