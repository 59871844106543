define('diesel/components/modal-email-verification-challenges/component', ['exports', 'ember', 'diesel/mixins/components/modal-base'], function (exports, _ember, _dieselMixinsComponentsModalBase) {
  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsModalBase['default'], {
    // Inputs
    emailVerificationChallenges: null,
    model: null,

    title: "Pending Email Verifications",

    description: _ember['default'].computed("model.user.email", function () {
      return 'Current email: ' + this.get("model.user.email");
    }),

    actions: {
      revokeEmailVerificationChallenge: function revokeEmailVerificationChallenge(challenge) {
        this.sendAction("revokeEmailVerificationChallenge", challenge);
      }
    }
  });
});