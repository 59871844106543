define('diesel/gridiron/policy-editor/node/route', ['exports', 'ember'], function (exports, _ember) {
  var set = _ember['default'].set;
  exports['default'] = _ember['default'].Route.extend({
    model: function model(_ref) {
      var path = _ref.path;

      if (path === 'root') {
        path = [];
      } else {
        path = path.split('-');
      }

      var model = this.modelFor('gridiron.policy-editor');
      set(model, 'path', path);

      return model;
    }
  });
});