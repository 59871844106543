define('diesel/components/modal-plan-change/component', ['exports', 'ember', 'ember-validations', 'diesel/mixins/components/modal-base'], function (exports, _ember, _emberValidations, _dieselMixinsComponentsModalBase) {
  exports['default'] = _ember['default'].Component.extend(_emberValidations['default'], _dieselMixinsComponentsModalBase['default'], {
    session: _ember['default'].inject.service(),

    actions: {
      cancel: function cancel() {
        this.sendAction('dismissModal');
      },
      changePlan: function changePlan() {
        this.sendAction('onChangePlan');
      }
    }
  });
});