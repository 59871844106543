define('diesel/organization/environments/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var context = this.modelFor('organization');
      var organization = context.get('organization');
      return this.store.findAccountsFor(organization);
    },

    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('organization', this.modelFor('organization').get('organization'));
    }
  });
});