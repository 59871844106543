define('diesel/accounts/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    redirect: function redirect() {
      var currentContext = this.get('authorization.currentContext');
      var currentAccount = currentContext.get('accounts.firstObject');

      // If we switch to an org without accounts, catch it here
      if (!currentAccount) {
        this.transitionTo('no-account');
      }
      if (currentAccount.get('activated')) {
        this.transitionTo('apps', currentAccount);
      } else {
        this.transitionTo('account.activate', currentAccount);
      }
    },

    model: function model() {
      return this.get('authorization.currentOrganization.accounts');
    }
  });
});