define('diesel/components/bs-popover/component', ['exports', 'ember', 'diesel/mixins/components/bootstrap-component-options'], function (exports, _ember, _dieselMixinsComponentsBootstrapComponentOptions) {
  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsBootstrapComponentOptions['default'], {
    tagName: 'span',

    setupPopover: _ember['default'].on('didInsertElement', function () {
      this.$(':first-child').popover(this.getBootstrapOptions());
    })
  });
});
/**
 * Usage
 *
 * Required params:
 *   * title: The text to be displayed in the popover title
 *   * content: The text to be displayed in the popover body
 * Optional params:
 *   * placement: 'left','right','top','bottom' (default: 'bottom')
 *   * bs-html: Whether HTML should be allowed. Default: false
 *   * bs-trigger: The triggering event ('click', 'focus', etc.). Default: none
 *   * bs-container: The container to use for the tooltip (e.g., 'body'). Default: none
 *
  {{#bs-popover title='The title' content='The popover body' bs-container='body' placement='right' bs-trigger='focus'}}
    <input type='text' />
  {{/bs-popover}}
 */