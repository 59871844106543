define('diesel/utils/belongs-to-record-pool', ['exports', 'ember'], function (exports, _ember) {

  var ERROR_EXHAUSTED = Error('Exhausted');
  ERROR_EXHAUSTED.poolExhausted = true;

  var PoolResponse = function PoolResponse(pool, record) {
    this.pool = pool;
    this.record = record;
    this.sortPriority = -parseInt(record.get('id'));
  };

  // TODO: Consider adding watermark check to preload the next page?
  var BelongsToRecordPool = function BelongsToRecordPool(resource, recordType) {
    this.resource = resource;
    this.recordType = recordType;
    this.store = resource.get('store');

    this._pending = [];

    this._pool = [];
    this._fillInProgress = false;
    this._knownIds = new Set();

    this._currentPage = 1;
    this._totalCount = null;
  };

  BelongsToRecordPool.prototype.take = function () {
    var _this = this;

    if (this._pool.length > 0) {
      return new _ember['default'].RSVP.resolve(this._pool.shift());
    }

    return new _ember['default'].RSVP.Promise(function (resolve, reject) {
      _this._pending.push({ resolve: resolve, reject: reject });
      _this.tryFill();
    });
  };

  BelongsToRecordPool.prototype.tryFill = function () {
    var _this2 = this;

    // Dedup calls
    if (this._fillInProgress) {
      return;
    }
    this._fillInProgress = true;

    var recordsPromise = undefined;

    if (this._totalCount !== null && this._knownIds.size >= this._totalCount) {
      // We've seen all records already, so since we're only ever fetching older
      // records (our pagination returns most recent records first), we know
      // we're exhausted.
      recordsPromise = _ember['default'].RSVP.reject(ERROR_EXHAUSTED);
    } else {
      var query = this._composeQuery(this._currentPage);
      recordsPromise = this.store.query(this.recordType, query);
    }

    return recordsPromise.then(function (records) {
      if (_this2._totalCount === null) {
        // We record the total count only the first time we see records, this
        // ensures that if new records are added (we'll never see them since we
        // paginate towards older records), we don't re-try requests to fetch
        // them (which is wasteful albeit not problematic). This assumes that
        // records aren't deleted, which isn't entirely accurate, but this is
        // just an optimization, so that's OK.
        _this2._totalCount = records.get('meta').total_count;
      }

      var recordPushes = records.map(function (o) {
        return _this2._tryPush(o);
      });

      // If none of the records made it to the pool (possibly because we
      // received none), then we'll notify all listeners that this pool is
      // exhausted.
      if (recordPushes.every(function (r) {
        return !r;
      })) {
        throw ERROR_EXHAUSTED;
      }

      _this2._currentPage++;
    })['catch'](function (e) {
      _this2._rejectPending(e);
    })['finally'](function () {
      _this2._fillInProgress = false;

      // But maybe we didn't receive enough records to satisfy all pending
      // requests!
      if (_this2._pending.length > 0) {
        _this2.tryFill();
      }
    });
  };

  BelongsToRecordPool.prototype._composeQuery = function (page) {
    var query = { page: page };

    // NOTE: constructor.modelName gives us the dasherized name, but this query
    // will go to the adapter, where we expect the camel-cased name.
    var model = _ember['default'].String.camelize(this.resource.get('constructor.modelName'));
    query[model] = this.resource;
    return query;
  };

  BelongsToRecordPool.prototype._tryPush = function (record) {
    var recordId = record.get('id');
    if (this._knownIds.has(recordId)) {
      // Deduplicate records. Since we use pagination, we're not guaranteed
      // not to see the same record multiple times.
      return false;
    }
    this._knownIds.add(recordId);

    var r = new PoolResponse(this, record);

    var nextPending = this._pending.shift();

    if (nextPending) {
      nextPending.resolve(r);
    } else {
      this._pool.push(r);
    }

    return true;
  };

  BelongsToRecordPool.prototype._rejectPending = function (err) {
    while (1) {
      // eslint-disable-line no-constant-condition
      var nextPending = this._pending.shift();

      if (nextPending) {
        nextPending.reject(err);
      } else {
        break;
      }
    }
  };

  exports['default'] = BelongsToRecordPool;
});