define('diesel/organization/members/edit/controller', ['exports', 'ember', 'diesel/models/organization-profile'], function (exports, _ember, _dieselModelsOrganizationProfile) {
  exports['default'] = _ember['default'].Controller.extend({
    isSecurityOfficer: _ember['default'].computed('authorizationContext.organizationProfile.securityOfficerUrl', 'model.id', function () {
      return this.get('authorizationContext.organizationProfile.securityOfficerUrl') === this.get('model.links.self');
    }),

    buildRemoveMessage: function buildRemoveMessage(userName, orgName, officer) {
      return userName + ' is ' + orgName + '\'s ' + officer + ' and cannot be removed until\n            another user is assigned.';
    },

    designations: _ember['default'].computed('authorizationContext.organizationProfile', 'model.id', function () {
      var op = this.get('authorizationContext.organizationProfile');
      var userHref = this.get('model.links.self');

      if (!op) {
        return [];
      }

      var designations = [];

      Object.keys(_dieselModelsOrganizationProfile.USER_DESIGNATIONS).forEach(function (designationKey) {
        var designation = _dieselModelsOrganizationProfile.USER_DESIGNATIONS[designationKey];
        if (op.get(designationKey + 'Url') === userHref) {
          designations.push(designation);
        }
      });

      return designations;
    }),

    disableRemoveMessage: _ember['default'].computed('isSecurityOfficer', function () {
      var userName = this.get('model.name');
      var orgName = this.get('organization.name');
      if (this.get('isSecurityOfficer')) {
        return this.buildRemoveMessage(userName, orgName, 'Security Officer');
      }
      return false;
    })
  });
});