define('diesel/components/payments-table/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    sortBy: ['createdAt:desc'],

    payments: null,
    paymentMethods: null,

    sortedPayments: _ember['default'].computed.sort('payments', 'sortBy'),

    totalPaid: _ember['default'].computed('payments.[]', function () {
      var payments = this.get('payments').filter(function (p) {
        return p.get('isNotFailed');
      });
      return payments.map(function (p) {
        return p.get('cashValue');
      }).reduce(function (acc, e) {
        return acc + e;
      }, 0);
    })
  });
});