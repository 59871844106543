define('diesel/settings/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);
      this.render('sidebars/settings', {
        into: 'settings',
        outlet: 'sidebar'
      });
    }
  });
});