define('diesel/helpers/privacy-officer', ['exports', 'ember'], function (exports, _ember) {
  exports.formatPrivacyOfficer = formatPrivacyOfficer;

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  function formatPrivacyOfficer(organizationProfile) {
    var title = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

    var privacyOfficerName = organizationProfile.get('privacyOfficerName');
    var shortName = organizationProfile.get('shortName');

    if (privacyOfficerName) {
      return privacyOfficerName + ' (the ' + shortName + ' Privacy Officer)';
    }

    return (title ? 'T' : 't') + 'he ' + shortName + ' Privacy Officer';
  }

  exports['default'] = _ember['default'].Helper.helper(function (params) {
    return formatPrivacyOfficer.apply(undefined, _toConsumableArray(params));
  });
});