define('diesel/gridiron-organization/index/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    redirect: function redirect() {
      if (this.get('complianceStatus.authorizationContext.userIsGridironOrOrganizationAdmin')) {
        this.transitionTo('gridiron-admin');
      } else {
        this.transitionTo('gridiron-user');
      }
    }
  });
});