define('diesel/reports/business-continuity-report/route', ['exports', 'ember'], function (exports, _ember) {

  function titleize(str) {
    return str.split('_').map(function (c) {
      return c.capitalize();
    }).join(' ');
  }

  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var organizationProfile = this.get('complianceStatus.organizationProfile');
      var reportHref = organizationProfile.get('links.business_continuity_report');
      var accessToken = this.get("session.token.accessToken");

      if (!reportHref) {
        this.transitionTo('gridiron-admin');
      }

      return _ember['default'].RSVP.hash({
        reportCSVHref: reportHref + '.csv',
        reportData: _ember['default'].$.ajax(reportHref, { headers: { Authorization: 'Bearer ' + accessToken } })
      });
    },

    setupController: function setupController(controller, model) {
      var componentTypes = ['apps', 'databases', 'data_stores'];
      var sections = componentTypes.map(function (type) {
        return { type: type, title: titleize(type), assets: model.reportData.report[type] };
      });
      controller.setProperties({ sections: sections, reportCSVHref: model.reportCSVHref });
    },

    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);

      this.render('sidebars/engine-sidebar', {
        into: 'reports.business-continuity-report',
        outlet: 'sidebar',
        controller: this.controllerFor('gridiron-organization')
      });
    }
  });
});