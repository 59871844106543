define("diesel/settings/impersonate/route", ["exports", "ember", "diesel/utils/location", "diesel/utils/tokens", "diesel/utils/assign"], function (exports, _ember, _dieselUtilsLocation, _dieselUtilsTokens, _dieselUtilsAssign) {

  function prepareSubjectParameters(email, organizationHref) {
    if (email) {
      return {
        subject_token: email,
        subject_token_type: 'aptible:user:email'
      };
    } else if (organizationHref) {
      return {
        subject_token: organizationHref.match(/^http/) ? organizationHref : 'https://auth.aptible.com/organizations/' + organizationHref,
        subject_token_type: 'aptible:organization:href'
      };
    } else {
      return {};
    }
  }

  exports["default"] = _ember["default"].Route.extend({
    model: function model() {
      return _ember["default"].Object.create({
        email: '',
        organizationHref: '',
        manage: false,
        sso: false
      });
    },

    actions: {
      impersonate: function impersonate(authAttempt) {
        var _this = this;

        var adminToken = this.get("session.token");

        var credentials = {
          grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
          actor_token: adminToken.get('accessToken'),
          actor_token_type: 'urn:ietf:params:oauth:token-type:jwt',
          scope: authAttempt.get('manage') ? 'manage' : 'read'
        };

        (0, _dieselUtilsAssign["default"])(credentials, prepareSubjectParameters(authAttempt.email, authAttempt.organizationHref));

        if (authAttempt.sso) {
          var orgHref = authAttempt.organizationHref.match(/^http/) ? authAttempt.organizationHref : "https://auth.aptible.com/organizations/" + authAttempt.organizationHref;

          (0, _dieselUtilsAssign["default"])(credentials, { sso_organization: orgHref });
        }

        this.controller.set('inProgress', true);
        this.currentModel.set('error', null);

        // To impersonate a user, this will first attempt to create a token for
        // them and persist it to our cookies. If that succeeds, then all we need
        // to do is destroy our admin token (without persisting that change to
        // cookies) and reload the UI: upon reload the new token will be fetched
        // from cookies, and we'll be logged in as whoever we intended to
        // impersonate. If it fails, then we don't have to clean up anything
        // (since nothing was created).
        return (0, _dieselUtilsTokens.createToken)(credentials).then(function () {
          return _this.session.close('application', {
            token: adminToken,
            noClearCookies: true
          });
        }).then(function () {
          return _dieselUtilsLocation["default"].replaceAndWait('/');
        })["catch"](function (e) {
          _this.currentModel.set('error', "Error: " + (e.message || JSON.stringify(e)));
        })["finally"](function () {
          _this.controller.set('inProgress', false);
        });
      }
    }
  });
});