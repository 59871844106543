define('diesel/initializers/compliance-status', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(application) {
    application.inject('controller', 'complianceStatus', 'service:complianceStatus');
    application.inject('component', 'complianceStatus', 'service:complianceStatus');
    application.inject('route', 'complianceStatus', 'service:complianceStatus');
  }

  exports['default'] = {
    name: 'compliance-status-service',
    initialize: initialize
  };
});