define('diesel/components/vulnerability-status/component', ['exports', 'ember'], function (exports, _ember) {

  function itself(x) {
    return x;
  }

  var STATUS_FIX_AVAILABLE = 1;
  var STATUS_PARTIAL_FIX_AVAILABLE = 2;
  var STATUS_NO_FIX = 3;

  var STATUS_LABELS = {};
  STATUS_LABELS[STATUS_FIX_AVAILABLE] = 'Fix Available';
  STATUS_LABELS[STATUS_PARTIAL_FIX_AVAILABLE] = 'Partial Fix Available';
  STATUS_LABELS[STATUS_NO_FIX] = 'No Fix';

  exports['default'] = _ember['default'].Component.extend({
    tagName: 'span',
    vulnerability: null,

    fixStatus: _ember['default'].computed('vulnerability.packages.[]', function () {
      var packageStatus = this.get('vulnerability.packages').map(function (p) {
        return p.fixAvailable;
      });

      if (packageStatus.every(itself)) {
        return STATUS_FIX_AVAILABLE;
      }

      if (packageStatus.any(itself)) {
        return STATUS_PARTIAL_FIX_AVAILABLE;
      }

      return STATUS_NO_FIX;
    }),

    fixLabel: _ember['default'].computed('fixStatus', function () {
      return STATUS_LABELS[this.get('fixStatus')];
    }),

    isFixed: _ember['default'].computed.equal('fixStatus', STATUS_FIX_AVAILABLE)
  });
});