define('diesel/components/c-compliance-status/component', ['exports', 'ember'], function (exports, _ember) {

  var VARIANTS = {
    'default': 'c-compliance-status--default',
    danger: 'c-compliance-status--danger',
    success: 'c-compliance-status--success',
    warn: 'c-compliance-status--warn'
  };

  exports['default'] = _ember['default'].Component.extend({
    tagName: 'span',
    classNames: ['c-compliance-status'],
    classNameBindings: ['statusCn'],
    variant: 'default',
    statusCn: _ember['default'].computed('variant', function () {
      return VARIANTS[this.get('variant')] || VARIANTS['default'];
    })
  });
});