define('diesel/models/payment', ['exports', 'ember', 'ember-data', 'diesel/models/base'], function (exports, _ember, _emberData, _dieselModelsBase) {
  exports['default'] = _dieselModelsBase['default'].extend({
    invoice: _emberData['default'].belongsTo('invoice'),
    createdAt: _emberData['default'].attr('iso-8601-timestamp'),
    cashValue: _emberData['default'].attr('number'),
    sequenceNumber: _emberData['default'].attr('number'),
    paymentMethod: _emberData['default'].attr('polymorphic-write-only'), // TODO!!

    paymentClosure: _emberData['default'].belongsTo('payment-closure', { async: false }),

    isNotFailed: _ember['default'].computed('paymentClosure', 'paymentClosure.success', function () {
      if (!this.get('paymentClosure')) {
        return true;
      }
      return this.get('paymentClosure.success');
    })
  });
});