define('diesel/mixins/adapters/api-path', ['exports', 'ember'], function (exports, _ember) {
  var pluralize = _ember['default'].String.pluralize;
  var decamelize = _ember['default'].String.decamelize;
  var underscore = _ember['default'].String.underscore;

  exports['default'] = _ember['default'].Mixin.create({
    // In URLs and JSON payloads, use e.g. "log_drains" instead of "logDrains" or
    // "log-drains"
    pathForType: function pathForType(type) {
      return underscore(pluralize(decamelize(type)));
    }
  });
});