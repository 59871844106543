define('diesel/elevate/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    elevation: _ember['default'].inject.service(),

    model: function model(queryParams) {
      return _ember['default'].Object.create({
        email: this.get('session.currentUser.email'),
        password: '',
        loginCallback: _ember['default'].run.bind(this, this.loginCallback),
        redirectTo: queryParams['redirectTo']
      });
    },

    loginCallback: function loginCallback(credentials) {
      return this.get('elevation').createElevatedToken(credentials);
    },

    actions: {
      loggedIn: function loggedIn() {
        var redirectTo = this.currentModel.get('redirectTo') || 'enclave';

        // redirectTo is persisted in the URL, which makes this page work
        // after a reload. However, it means it can be spoofed by sending
        // someone to this page with a invalid redirectTo. However unlikely,
        // this could hypothetically be exploited to facilitate a social
        // engineering attack. So, if we get a route not found error, let's
        // replace it with a more generic one.
        try {
          this.transitionTo(redirectTo);
        } catch (transitionError) {
          var errorMessage = transitionError.message;
          if (errorMessage.match(/route[^]+not found/)) {
            errorMessage = 'Route not found.';
          }
          this.get('flashMessages').danger(errorMessage);
        }
      }
    }
  });
});