define('diesel/serializers/risk-scope', ['exports', 'diesel/serializers/application'], function (exports, _dieselSerializersApplication) {
  var RISK_ASSESSMENT_COMPONENTS = ['mitigations', 'threat_sources', 'security_controls', 'vulnerabilities', 'security_control_categories', 'predisposing_conditions', 'threat_events'];

  exports.RISK_ASSESSMENT_COMPONENTS = RISK_ASSESSMENT_COMPONENTS;
  // This serializer does 2 important things to make Ember Data relationships work
  // with risk assessment components.  Components will always be embedded--there
  // should never be an AJAX request to load any component of a risk assessment.

  // Job 1:
  // The Risk Assessment's ID is used to prefix all model component's own IDs.
  // This prevents risk assessment components from colliding with components
  // of other risk assessments once loaded into the Ember Data store.  Without
  // the prefix, we could possibly load common component IDs multiple times:
  // e.g. `craft_spear_phishing` likely exists as a threat event in multiple
  // risk assessments--these threat events would become one if we didn't otherwise
  // differentiate their IDs.

  // Job 2:
  // All components are updated to included updated references to related
  // components.  This includes:
  //  * Adding the `risk_assessment_id` field to all components
  //  * Flattening mitigations and instead adding the relationships between
  //  vulnerabilities and security controls to fields directly on those components
  //  * Updating all relationship keys to include the risk assessment id as a prefix

  exports['default'] = _dieselSerializersApplication['default'].extend({
    embeddedCollectionKey: 'risk_assessments',
    embeddedResourceKey: 'risk_assessment_id',

    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var collectionKey = this.get('embeddedCollectionKey');
      var isArray = payload._embedded && payload._embedded[collectionKey];
      if (isArray) {
        payload._embedded[collectionKey].forEach(this._extractRiskAssessment.bind(this));
      } else {
        this._extractRiskAssessment(payload);
      }
      return this._super(store, primaryModelClass, payload, id, requestType);
    },

    _extractRiskAssessment: function _extractRiskAssessment(payload) {
      // Each of these steps mutate the payload directly
      this._addRiskAssesmentIdToEmbeddeds(payload);
      this._updateRelationshipKeysWithPrefix(payload);
      this._updateRelationshipToSecuritycontrolCategories(payload);
      this._flattenMitigations(payload);
    },

    _addRiskAssesmentIdToEmbeddeds: function _addRiskAssesmentIdToEmbeddeds(riskAssessment) {
      var embeddedResourceKey = this.get('embeddedResourceKey');
      // Step 1:
      // For each resource, add a reference to `risk_assessment_id` and update
      // the resources ID to be prefixed with the risk assessment ID.
      RISK_ASSESSMENT_COMPONENTS.forEach(function (type) {
        // First ensure that each type is at least an empty set and not null
        riskAssessment._embedded[type] = riskAssessment._embedded[type] || [];
        riskAssessment._embedded[type].forEach(function (typeInstance) {
          typeInstance[embeddedResourceKey] = riskAssessment.id;
          typeInstance.id = riskAssessment.id + '_' + typeInstance.id;

          // If this particular risk model instance has a schema, we should serialize
          // additional properties into `metadata`.
          // Look at properties included in schema and inject them into `metadata`
          // attribute on teh payload
          if (typeInstance.schema && typeInstance.schema.properties) {
            (function () {
              var metaKeys = Object.keys(typeInstance.schema.properties);
              var metadata = {};
              metaKeys.forEach(function (key) {
                if (typeInstance[key]) {
                  metadata[key] = typeInstance[key];
                }
              });
              typeInstance.metadata = metadata;
            })();
          }
        });
      });
    },

    _updateRelationshipKeysWithPrefix: function _updateRelationshipKeysWithPrefix(riskAssessment) {
      // Step 2:
      // For each threat event, updated hasMany keys to include the risk assessment
      // ID as a prefix for each key.
      riskAssessment._embedded.threat_events.forEach(function (threatEvent) {
        threatEvent._embedded = {};
        ['vulnerabilities', 'threat_sources', 'predisposing_conditions'].forEach(function (relationship) {
          threatEvent._embedded[relationship] = threatEvent[relationship].map(function (key) {
            return riskAssessment.id + '_' + key;
          });
        });
      });

      // Update vulnerability and security control keys on mitigations
      // TODO-ember-data Ensure riskAssessment.mitigations.vulnerability are shown
      // in UI
      riskAssessment._embedded.mitigations.forEach(function (mitigation) {
        mitigation.vulnerability = riskAssessment.id + '_' + mitigation.vulnerability;
        mitigation.security_control = riskAssessment.id + '_' + mitigation.security_control;
      });
    },

    _updateRelationshipToSecuritycontrolCategories: function _updateRelationshipToSecuritycontrolCategories(riskAssessment) {
      // Step 3:
      // Update IDs for security control categories to include risk assessment id
      // TODO-ember-data Ensure embedded securityControl categories are shown
      riskAssessment._embedded.security_controls.forEach(function (sc) {
        sc._embedded = {};
        if (sc.category) {
          var category = riskAssessment.id + '_' + sc.category;
          var categoryInstance = riskAssessment._embedded.security_control_categories.findBy('id', category);
          sc.category = category;
          sc._embedded.category = categoryInstance;
        }
      });
    },

    _flattenMitigations: function _flattenMitigations(riskAssessment) {
      // Step 4:
      // Security controls and vulnerabilitis are many-to-many through mitigations
      // loop over mitigations and set references to relationships directly on
      // each vuln and sc.
      riskAssessment._embedded.mitigations.forEach(function (mitigation) {
        var vulnerability = riskAssessment._embedded.vulnerabilities.find(function (v) {
          return v.id === mitigation.vulnerability;
        });
        var sc = riskAssessment._embedded.security_controls.find(function (sc) {
          return sc.id === mitigation.security_control;
        });

        if (!vulnerability || !sc) {
          return;
        }

        // Add sc to reference
        vulnerability.security_controls = vulnerability.security_controls || [];
        vulnerability.security_controls.push(mitigation.security_control);

        // Embed sc on vulnerability
        vulnerability._embedded = vulnerability._embedded || [];
        vulnerability._embedded.security_controls = vulnerability._embedded.security_controls || [];
        vulnerability._embedded.security_controls.push(mitigation.security_control);

        // Add vulnerability to reference
        sc.vulnerabilities = sc.vulnerabilities || [];
        sc.vulnerabilities.push(mitigation.vulnerability);

        // Embed vulnerability on sc
        sc._embedded = sc._embedded || {};
        sc._embedded.vulnerabilities = sc._embedded.vulnerabilities || [];
        sc._embedded.vulnerabilities.push(mitigation.vulnerability);
      });
    }
  });
});