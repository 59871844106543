define('diesel/app/security-scan/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    modalDisplay: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),

    model: function model() {
      var app = this.modelFor('app');
      return app.get('currentImage').then(function (image) {
        return _ember['default'].Object.create({ app: app, image: image });
      });
    },

    titleToken: function titleToken() {
      return this.modelFor('app').get('handle') + ' Security Scan';
    }
  });
});