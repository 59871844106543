define('diesel/components/input-container-size/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    sizes: null,
    selected: null,
    actions: {
      setContainerSize: function setContainerSize(value) {
        this.attrs.onSelect(value);
      }
    }
  });
});