define('diesel/serializers/application', ['exports', 'ember', 'ember-data-hal-9000/mixin', 'active-model-adapter', 'diesel/config/environment'], function (exports, _ember, _emberDataHal9000Mixin, _activeModelAdapter, _dieselConfigEnvironment) {
  exports.modelNameFromPayloadKey = _modelNameFromPayloadKey;
  var _Ember$String = _ember['default'].String;
  var decamelize = _Ember$String.decamelize;
  var singularize = _Ember$String.singularize;
  var MODEL_NAME_MAP = {
    criterium: 'criterion'
  };

  exports.MODEL_NAME_MAP = MODEL_NAME_MAP;

  function _modelNameFromPayloadKey(key) {
    return _ember['default'].get(MODEL_NAME_MAP, key) || key;
  }

  function buildUrlValidationMixin(environment) {
    if (environment === 'production') {
      // Don't validate at all in production. We wouldn't do anything with the
      // information if validation doesn't pass, so we might as well not do it.
      return _ember['default'].Mixin.create({
        _validateUrlMatch: function _validateUrlMatch() {}
      });
    }

    // In test, assert. In development, just warn.
    var method = environment === 'test' ? 'assert' : 'warn';

    return _ember['default'].Mixin.create({
      _validateUrlMatch: function _validateUrlMatch(type, id, linkHref) {
        var store = this.get('store');
        var adapter = store.adapterFor(type);
        var computedHref = adapter.buildURL(type, id, null, 'findRecord');

        _ember['default'][method]('Computed href (' + computedHref + ') does not match link href (' + linkHref + ').', linkHref === computedHref, { id: 'x-link-extraction' });
      }
    });
  }

  // Without these custom mappings Ember will simply decamelize() attribute keys.
  // which, to be clear, doesnt work for these attributes because:
  // `allowMInstanceProfile`.decamelize() == `allow_minstance_profile` which is not what we want
  // (or expect tbh)
  var customAttributeKeys = {
    'allowMInstanceProfile': 'allow_m_instance_profile',
    'allowTInstanceProfile': 'allow_t_instance_profile',
    'allowRInstanceProfile': 'allow_r_instance_profile',
    'allowCInstanceProfile': 'allow_c_instance_profile'
  };

  exports['default'] = _activeModelAdapter.ActiveModelSerializer.extend(_emberDataHal9000Mixin['default'], buildUrlValidationMixin(_dieselConfigEnvironment['default'].environment), {
    isNewSerializerAPI: true,
    /*
     * Overridden to remove top-level namespaces.
     * The adapter would ordinarily return a hash like:
     * `{user: {id: 1, ...}}` when serializing a user.
     * Our API expects no top-level namespace so this is transformed into
     * `{id: 1, ...}`
     */
    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      var serialized = this.serialize(record, options);

      for (var key in serialized) {
        hash[key] = serialized[key];
      }
    },

    keyForAttribute: function keyForAttribute(attr) {
      return customAttributeKeys[attr] || decamelize(attr);
    },

    keyForLink: function keyForLink(attr) {
      return decamelize(attr);
    },

    // From ActiveModelSerializer#keyForRelationship:
    // see https://github.com/emberjs/data/blob/3d40f37767cc84e355033e3e89bf26dd1803d996/packages/activemodel-adapter/lib/system/active-model-serializer.js#L127-L136
    keyForRelationship: function keyForRelationship(relationshipModelName, kind) {
      var key = decamelize(relationshipModelName);
      if (kind === 'belongsTo') {
        return key + '_id';
      } else if (kind === 'hasMany') {
        return singularize(key) + '_ids';
      } else {
        return key;
      }
    },

    modelNameFromPayloadKey: function modelNameFromPayloadKey(key) {
      var result = this._super(key);
      return _modelNameFromPayloadKey(result);
    },

    /**
     * This method should return a JSONAPI-structured response that will
     * become the `data` property of the full response that ember-data will
     * consume
     */
    normalize: function normalize(type) {
      var payload = this._super.apply(this, arguments);

      payload = this._setRelationshipResourceLinkages(payload, type);

      return payload;
    },

    // Add a reference to the original pre-normalized payload to the payload
    // itself.  The pre-normalized payload can then be used when attempting to
    // fetch from cache
    // TODO-ember-data remove this call (it is not used) and handle the `rawPayload`
    // some other way
    extractSingle: function extractSingle(store, primaryType, rawPayload, recordId) {
      var payload = rawPayload;
      payload.rawPayload = _ember['default'].$.extend(true, {}, payload);
      return this._super(store, primaryType, payload, recordId);
    },

    /**
     * Set the relationship id when possible on the payload by reading it from
     * the href in links.
     *
     * This checks each of the model's relationships and, if a `links` property is
     * present, converts the `related` href into an id and sets it as a resource
     * linkage (`data`) property.
     * E.g. if a `comment` model `belongsTo` a `post` and the payload looks like:
     * ```
     *  { ..., relationships: { post: {
     *    links: { related: "/posts/1" }
     *  } } }
     * ```
     * This method will mutate the `relationships.post` to make the payload
     * look like:
     * ```
     *  { ..., relationships: { post: {
     *    links: { related: "/posts/1" },
     *    data: { type: "post", id: "1" }
     *  } } }
     * ```
     *
     * Notably, this allows Ember's relationships API to work.
     *
     */
    _setRelationshipResourceLinkages: function _setRelationshipResourceLinkages(payload, type) {
      var _this = this;

      var relationships = payload.relationships;
      if (!relationships) {
        return payload;
      }

      type.eachRelationship(function (key, relationshipMeta) {
        if (relationshipMeta.kind !== 'belongsTo') {
          return;
        }

        var rel = relationships[key];
        if (!rel) {
          // Relationship is not in the payload. There is nothing we can do.
          return;
        }

        if (rel.data) {
          // We already have type + id, no need to do work here.
          return;
        }

        var href = rel.links && rel.links.related;
        if (!href) {
          // We don't have an HREF, nothing we can do.
          return;
        }

        // We have everything we need to replace this relationship with data!
        var type = relationshipMeta.type;
        var id = _this._idFromHref(href);

        _this._validateUrlMatch(type, id, href);

        relationships[key].data = { type: type, id: id };
      });

      payload.relationships = relationships;
      return payload;
    },

    _idFromHref: function _idFromHref(href) {
      var parts = href.split('/');
      return parts[parts.length - 1];
    }
  });
});