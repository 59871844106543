define('diesel/models/agreement', ['exports', 'ember-data', 'diesel/models/base', 'ember', 'diesel/mixins/models/attachable'], function (exports, _emberData, _dieselModelsBase, _ember, _dieselMixinsModelsAttachable) {
  var TERM_OPTIONS = [{ key: 'perpetual', value: 'Perpetual' }, { key: 'contingent', value: 'Contingent' }, { key: 'date', value: 'On Date' }];

  exports.TERM_OPTIONS = TERM_OPTIONS;
  var TYPES = [{ key: 'tos', value: 'Terms of Service' }, { key: 'msa', value: 'Master Services Agreement' }, { key: 'hipaa_baa', value: 'HIPAA BAA' }, { key: 'gdpr_dpa', value: 'GDPR Data Protection Agreement' }, { key: 'other', value: 'Other' }];

  exports.TYPES = TYPES;
  var TIP_HIPAA_SECURITY_INCIDENT = "\"attempted or successful unauthorized access, use, disclosure, modification, or destruction of information or interference with system operations in an information system\"";

  exports.TIP_HIPAA_SECURITY_INCIDENT = TIP_HIPAA_SECURITY_INCIDENT;
  exports['default'] = _dieselModelsBase['default'].extend(_dieselMixinsModelsAttachable['default'], {
    name: _emberData['default'].attr('string'),
    enforceable: _emberData['default'].attr('boolean', { defaultValue: false }),
    enforceableDescription: _emberData['default'].attr('string'),
    reportingSuspectedPolicyViolation: _emberData['default'].attr('boolean', { defaultValue: false }),
    reportingSuspectedPolicyViolationTime: _emberData['default'].attr('string'),
    reportingActualPolicyViolation: _emberData['default'].attr('boolean', { defaultValue: false }),
    reportingActualPolicyViolationTime: _emberData['default'].attr('string'),
    reportingHipaaIncident: _emberData['default'].attr('boolean', { defaultValue: false }),
    reportingHipaaIncidentTime: _emberData['default'].attr('string'),
    reportingHipaaBreach: _emberData['default'].attr('boolean', { defaultValue: false }),
    reportingHipaaBreachTime: _emberData['default'].attr('string'),
    reportingOther: _emberData['default'].attr('boolean', { defaultValue: false }),
    reportingOtherDescription: _emberData['default'].attr('string'),
    reportingOtherTime: _emberData['default'].attr('string'),
    termExpiration: _emberData['default'].attr('string', { defaultValue: 'perpetual' }),
    termExpirationDate: _emberData['default'].attr('date'),
    type: _emberData['default'].attr('string'),
    version: _emberData['default'].attr('string'),
    createdAt: _emberData['default'].attr('date'),
    updatedAt: _emberData['default'].attr('date'),
    termContingency: _emberData['default'].belongsTo('agreement', {
      inverse: 'contingencyFor',
      async: false
    }),
    // In order for the self-referential `termContingency` relationship to work,
    // we must also define an inverse.  Beware, however, that this inverse relationship
    // is only defined here on the client.  Gridiron does not have a route for
    // `contingencyFor`
    contingencyFor: _emberData['default'].hasMany('agreement', { async: true, inverse: 'termContingency' }),
    customer: _emberData['default'].belongsTo('customer', { async: true }),
    vendor: _emberData['default'].belongsTo('vendor', { async: true }),
    termExpirationIsContingent: _ember['default'].computed.equal('termExpiration', 'contingent'),
    termExpiresOnDate: _ember['default'].computed.equal('termExpiration', 'date'),
    termDoesNotExpire: _ember['default'].computed.equal('termExpiration', 'perpetual'),
    hasNoReportingRequirements: _ember['default'].computed.not('hasReportingRequirements'),
    isTermContingency: _ember['default'].computed.gt('agreementsContingentOnSelf.length', 0),
    canDelete: _ember['default'].computed.not('isTermContingency'),

    owner: _ember['default'].computed('customer', 'vendor', function () {
      if (this.get('customer.id')) {
        return this.get('customer');
      }

      if (this.get('vendor.id')) {
        return this.get('vendor');
      }
    }),

    tipHipaaSecurityIncident: TIP_HIPAA_SECURITY_INCIDENT,

    enforceabilityLabel: _ember['default'].computed('enforceable', function () {
      return this.get('enforceable') ? 'Yes' : 'No';
    }),

    termOptions: _ember['default'].computed('otherAgreements.[]', function () {
      if (this.get('otherAgreements.length') > 0) {
        return TERM_OPTIONS;
      }
      return [TERM_OPTIONS[0], TERM_OPTIONS[2]];
    }),

    termExpirationLabel: _ember['default'].computed('termExpiration', function () {
      var termExpiration = this.get('termExpiration');
      return TERM_OPTIONS.findBy('key', termExpiration).value;
    }),

    otherAgreements: _ember['default'].computed('owner.agreements.[]', function () {
      var id = this.get('id');
      return (this.get('owner.agreements') || _ember['default'].A()).rejectBy('isNew').rejectBy('id', id);
    }),

    hasReportingRequirements: _ember['default'].computed.or('reportingSuspectedPolicyViolation', 'reportingActualPolicyViolation', 'reportingHipaaIncident', 'reportingHipaaBreach', 'reportingOther'),

    agreementsContingentOnSelf: _ember['default'].computed('otherAgreements.[]', function () {
      var id = this.get('id');
      return this.get('otherAgreements').filterBy('termContingency.id', id);
    }),

    dependentContingencyTip: _ember['default'].computed('isTermContingency', function () {
      if (!this.get('isTermContingency')) {
        return;
      }

      var dependents = this.get('agreementsContingentOnSelf');
      var dependentsCount = dependents.get('length');
      var s = dependentsCount === 1 ? '' : 's';
      var contingencyNames = dependents.mapBy('name').join(', ');
      var title = dependentsCount + ' other agreement' + s + ' depend' + (s ? '' : 's') + ' on this agreement.';
      var body = 'Remove the contingencies from these agreements before removing this agreement: ' + contingencyNames;

      return { title: title, body: body };
    })
  });
});