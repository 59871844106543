define('diesel/components/simple-create-system/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    className: ['simple-create-system'],
    // Inputs
    system: null,
    roles: null,

    actions: {
      beforeDismiss: function beforeDismiss() {
        this.setProperties({ errors: null, system: null });
      },

      updateRoleOwner: function updateRoleOwner(role) {
        var system = this.get('system');
        system.setOwnerRole(role);
      },

      save: function save() {
        var system = this.get('system');
        this.get('save')(system);
      },

      cancel: function cancel() {
        this.get('cancel')();
      }
    }
  });
});