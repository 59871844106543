define('diesel/components/payment-method-by-href/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    href: null,
    paymentMethods: null,

    paymentMethod: _ember['default'].computed('href', 'paymentMethods.[]', function () {
      var _getProperties = this.getProperties('href', 'paymentMethods');

      var href = _getProperties.href;
      var paymentMethods = _getProperties.paymentMethods;

      return paymentMethods.find(function (p) {
        return p.get('links.self') === href;
      });
    })
  });
});