define('diesel/models/metric-drain', ['exports', 'ember', 'ember-data', 'diesel/models/base'], function (exports, _ember, _emberData, _dieselModelsBase) {
  exports['default'] = _dieselModelsBase['default'].extend({
    handle: _emberData['default'].attr('string'),
    status: _emberData['default'].attr('string'),

    drainType: _emberData['default'].attr('string'),
    drainConfiguration: _emberData['default'].attr(),

    account: _emberData['default'].belongsTo('account', { async: true }),
    database: _emberData['default'].belongsTo('database', { async: true }),

    drainConfigurationJson: _ember['default'].computed('drainConfiguration', function () {
      return JSON.stringify(this.get('drainConfiguration'));
    })
  });
});