define('diesel/role/members/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    invitedUser: null,
    pendingInvitations: _ember['default'].computed.filterBy('invitations', 'isNew', false),

    nonmembers: _ember['default'].computed('role.users.[]', 'authorizationContext.users.[]', function () {
      var members = this.get('role.users');
      var organizationUsers = this.get('authorizationContext.users');
      return organizationUsers.reject(function (user) {
        return members.includes(user);
      });
    }),
    sortBy: ['name:asc'],
    sortedNonMembers: _ember['default'].computed.sort('nonmembers', 'sortBy')
  });
});