define('diesel/reports/business-continuity-report/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      download: function download() {
        var href = this.get('reportCSVHref');
        var accessToken = this.get("session.token.accessToken");
        _ember['default'].$.ajax(href, { headers: { Authorization: 'Bearer ' + accessToken } }).then(function (data) {
          var blob = new Blob([data], { type: 'application/octet-stream' });
          var downloadUrl = URL.createObjectURL(blob);
          var filename = 'business_continuity_report.csv';

          var a = document.createElement("a");
          a.href = downloadUrl;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(downloadUrl);
        });
      }
    }
  });
});