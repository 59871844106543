define('diesel/transforms/polymorphic-write-only', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Transform.extend({
    deserialize: function deserialize() {
      throw new Error('cannot deserialize (write-only)');
    },

    serialize: function serialize(obj) {
      if (!obj) {
        return obj;
      }

      return obj.get('links.self');
    }
  });
});