define('diesel/mixins/routes/goldenboy', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    goldenboy: _ember['default'].inject.service(),

    beforeModel: function beforeModel() {
      return this.get('goldenboy').refresh();
    },

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('goldenboyLib', this.get('goldenboy').getLib());
    }
  });
});