define('diesel/gridiron-settings/team/contact/route', ['exports', 'ember', 'diesel/models/organization-profile', 'diesel/models/user-profile'], function (exports, _ember, _dieselModelsOrganizationProfile, _dieselModelsUserProfile) {
  var decamelize = _ember['default'].String.decamelize;
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var complianceStatus = this.get('complianceStatus');
      var organizationProfile = this.get('complianceStatus.organizationProfile');

      var _complianceStatus$getProperties = complianceStatus.getProperties('organization', 'users');

      var organization = _complianceStatus$getProperties.organization;
      var users = _complianceStatus$getProperties.users;

      var roleDesignations = [];

      // Loop over all users and reject any user that:
      // * Is not in a special role designation
      // * Is not in a special individual designation

      Object.keys(_dieselModelsOrganizationProfile.ROLE_DESIGNATIONS).forEach(function (designationName) {
        var designationPath = 'links.' + decamelize(designationName);

        if (organizationProfile.get(designationPath)) {
          roleDesignations.push(organizationProfile.get(designationName));
        }
      });

      roleDesignations = _ember['default'].RSVP.all(roleDesignations);

      return _ember['default'].RSVP.hash({ organization: organization, users: users, roleDesignations: roleDesignations, organizationProfile: organizationProfile });
    },

    afterModel: function afterModel(model) {
      return _ember['default'].RSVP.all(model.roleDesignations.map(function (r) {
        return r.get('users');
      }));
    },

    setupController: function setupController(controller, model) {
      var _this = this;

      var organizationProfile = this.get('complianceStatus.organizationProfile');
      var specialDesignationUserHrefs = {};
      var usersWithDesignations = [];

      Object.keys(_dieselModelsOrganizationProfile.USER_DESIGNATIONS).forEach(function (designationName) {
        var href = organizationProfile.get(designationName + 'Url');
        var designation = _dieselModelsOrganizationProfile.USER_DESIGNATIONS[designationName];

        if (href) {
          if (!specialDesignationUserHrefs[href]) {
            specialDesignationUserHrefs[href] = [];
          }

          specialDesignationUserHrefs[href].push({ name: designation.name, type: 'designation' });
        }
      });

      Object.keys(_dieselModelsOrganizationProfile.ROLE_DESIGNATIONS).forEach(function (designationName) {
        var designation = _dieselModelsOrganizationProfile.ROLE_DESIGNATIONS[designationName];
        var designationPath = 'links.' + decamelize(designationName);

        if (!organizationProfile.get(designationPath)) {
          return;
        }

        var role = organizationProfile.get(designationName);

        if (role) {
          role.get('users').forEach(function (u) {
            var href = u.get('links.self');
            if (!specialDesignationUserHrefs[href]) {
              specialDesignationUserHrefs[href] = [];
            }
            specialDesignationUserHrefs[href].push({ name: designation.name, type: 'role' });
          });
        }
      });

      model.users.forEach(function (user) {
        var designations = specialDesignationUserHrefs[user.get('links.self')] || [];
        var userProfile = _dieselModelsUserProfile['default'].findOrCreate(user, organizationProfile, _this.store);
        var designationCount = designations.length;

        usersWithDesignations.push({ user: user, designations: designations, userProfile: userProfile, designationCount: designationCount });
      });

      usersWithDesignations = usersWithDesignations.sortBy('designationCount').reverse();
      controller.setProperties({ model: model, usersWithDesignations: usersWithDesignations });
    }
  });
});