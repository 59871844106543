define('diesel/components/membership-table-row/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['aptable__member-row'],
    tagName: 'tr',
    busy: false,
    user: _ember['default'].computed.reads('membership.user'),
    memberUserRoles: _ember['default'].computed.reads('membership.user.roles'),
    currentUserRoles: _ember['default'].computed.reads('authorizationContext.currentUserRoles'),
    organization: _ember['default'].computed.reads('authorizationContext.organization'),
    billingDetail: _ember['default'].computed.reads('authorizationContext.billingDetail'),
    organizationProfile: _ember['default'].computed.reads('authorizationContext.organizationProfile'),
    privilegedMemberships: _ember['default'].computed.filterBy('role.memberships', 'privileged', true),

    isOnlyRole: _ember['default'].computed('membership.user', 'memberUserRoles.[]', function () {
      return this.get('memberUserRoles.length') === 1;
    }),

    hasOwnerShield: _ember['default'].computed('membership.user', 'memberUserRoles.[]', function () {
      return this.isRoleOwner(this.get('user'), this.get('memberUserRoles'));
    }),

    removeTooltip: _ember['default'].computed('organization.name', 'membership.user', 'memberUserRoles.[]', function () {
      if (!this.get('isOnlyRole')) {
        return "Remove role membership";
      }
      return this.get('user.name') + ' must be assigned to another role before\n      they can be removed from ' + this.get('role.name') + '.';
    }),

    linkToEditMember: _ember['default'].computed('authorizationContext.currentUserRoles.[]', function () {
      return this.get('isOnlyRole') && this.get('authorizationContext.userIsOrganizationAdmin');
    }),

    // Account | Deploy | Gridiron Owners effectively have admin privileges,
    // so it gets toggled and disabled.
    isPrivilegedMember: _ember['default'].computed('membership.privileged', 'memberUserRoles.[]', function () {
      //return this.isRoleOwner(this.get('membership.user'), this.get('memberUserRoles')) ||
      return this.get('membership.privileged');
    }),

    isToggleDisabled: _ember['default'].computed('memberUserRoles.[]', 'authorizationContext.currentUserRoles.[]', function () {
      if (this.get('role.isOwner')) {
        return true;
      }

      // Disable if current user is not a platform, compliance, or account owner
      if (!this.isRoleOwner(this.get('authorizationContext.currentUser'), this.get('authorizationContext.currentUserRoles'))) {
        return true;
      }
      // If current user is a role owner, check this role member and disable
      // if they are an owner
      return this.isRoleOwner(this.get('membership.user'), this.get('memberUserRoles'));
    }),

    isRoleOwner: function isRoleOwner(user, roles) {
      if (!this.get('user')) {
        return false;
      }
      if (user.isAccountOwner(roles, this.get('organization'))) {
        return true;
      }
      if (this.get('role.isCompliance')) {
        return user.isComplianceOwner(roles, this.get('organization'));
      }
      return user.isPlatformOwner(roles, this.get('organization'));
    },

    actions: {
      togglePrivileged: function togglePrivileged(valueOptions) {
        var _this = this;

        var isOn = !!valueOptions.newValue;
        var membership = this.get('membership');

        if (isOn === membership.get('privileged')) {
          // No need to re-save if value isn't changed
          return;
        }

        // No need to update if this user is an owner.
        if (this.isRoleOwner(membership.get('user'), this.get('memberUserRoles'))) {
          return;
        }

        membership.set('privileged', isOn);
        this.set('busy', true);
        membership.save().then(function () {
          _this.set('busy', false);
        });
      },

      // Confirm deletes, also message that the user will be removed from the
      // org if this is their only role
      destroyMembership: function destroyMembership() {
        var _this2 = this;

        var role = this.get('role');
        var membership = this.get('membership');
        var user = membership.get('user');

        var subject = user.get('name');
        if (user.get('id') === this.get('authorizationContext.currentUser.id')) {
          subject = 'yourself';
        }

        // Confirm...
        var confirmMsg = '\nAre you sure you want to remove ' + subject + ' from ' + role.get('name') + '?\n';
        if (!confirm(confirmMsg)) {
          return false;
        }

        membership.destroyRecord().then(function () {
          var message = user.get('name') + ' removed from ' + role.get('name') + '.';

          _this2.sendAction('completedAction', message);
          // Ember Data relates users to roles (the membership relationship is inferred),
          // need to remove the user from the role's list of users
          role.get('users').removeObject(user);
          role.get('memberships').removeObject(membership);
          return;
        });
      }
    }
  });
});