define('diesel/components/organization-membership-checkbox/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'input',
    attributeBindings: ['type', 'checked', 'name', 'disabled'],
    type: 'checkbox',
    name: 'user-role',

    disabled: _ember['default'].computed.reads('isDisabled'),
    checked: _ember['default'].computed.reads('isChecked'),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this._stagedObjectKey = {
        organizationRole: this.get('organizationRole'),
        user: this.get('user')
      };

      this.updateUI();
      this.get('changeset').subscribeAll(function () {
        return _this.updateUI();
      });
    },

    updateUI: function updateUI() {
      this.updateCheckbox();
      this.updateDisabled();
    },

    updateCheckbox: function updateCheckbox() {
      var isChecked = this.get('changeset').value(this._stagedObjectKey);
      this.set('isChecked', isChecked);
    },

    updateDisabled: function updateDisabled() {
      var _this2 = this;

      var activeMemberships = [];
      this.get('changeset').forEachValue(function (keyData, initialValue, value) {
        if (value === true) {
          activeMemberships.push(keyData);
        }
      });
      var isDisabled = activeMemberships.length === 1 && activeMemberships[0] === this._stagedObjectKey;

      // TODO-double-modify
      _ember['default'].run.schedule('afterRender', function () {
        _this2.set('isDisabled', isDisabled);
      });
    },

    click: function click() {
      var isChecked = this.$().is(':checked');
      this.get('changeset').setValue(this._stagedObjectKey, isChecked);
    }
  });
});