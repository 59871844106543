define('diesel/vendor/route', ['exports', 'ember', 'diesel/mixins/routes/agreement-owner-route-mixin'], function (exports, _ember, _dieselMixinsRoutesAgreementOwnerRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_dieselMixinsRoutesAgreementOwnerRouteMixin['default'], {
    modalDisplay: _ember['default'].inject.service(),

    MODEL_NAME: 'vendor',

    openSystemModal: function openSystemModal(modalParams) {
      var modalDisplay = this.get('modalDisplay');
      modalDisplay.showModal('modal-create-system', { createSystemModal: modalParams }, {
        actions: {
          onSystemUpdate: modalDisplay.modalAction(this, 'onSystemUpdate')
        }
      });
    },

    actions: {
      editSystem: function editSystem(system) {
        var organizationProfile = this.get('complianceStatus.organizationProfile');

        var _get$getProperties = this.get('complianceStatus.authorizationContext').getProperties('roles', 'users');

        var roles = _get$getProperties.roles;
        var users = _get$getProperties.users;

        this.openSystemModal({ system: system, roles: roles, users: users, organizationProfile: organizationProfile });
      },

      onSystemUpdate: function onSystemUpdate(system) {
        var organizationProfile = this.get('complianceStatus.organizationProfile');
        var message = system.get('name') + ' configured successfully!';
        this.get('flashMessages').success(message);

        organizationProfile.reloadCurrentSecurityReview();
        organizationProfile.observeDocumentBuilds();
      }
    }
  });
});