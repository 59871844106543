define("diesel/components/modal-create-account/component", ["exports", "ember", "diesel/config/environment", "ember-validations", "diesel/mixins/components/resizable-modal", "diesel/mixins/components/modal-base", "diesel/utils/ignore-invalid-error"], function (exports, _ember, _dieselConfigEnvironment, _emberValidations, _dieselMixinsComponentsResizableModal, _dieselMixinsComponentsModalBase, _dieselUtilsIgnoreInvalidError) {
  exports["default"] = _ember["default"].Component.extend(_dieselMixinsComponentsResizableModal["default"], _emberValidations["default"], _dieselMixinsComponentsModalBase["default"], {
    // Inputs
    createAccountModel: null,

    authorization: _ember["default"].inject.service(),
    analytics: _ember["default"].inject.service(),

    resizeWindowBuffer: 250,

    disableSave: _ember["default"].computed.or('hasFormError', 'hasValidationError', 'hasModelErrors', 'newAccount.isSaving'),
    hasValidationError: _ember["default"].computed.gt('errors.model.handle.length', 0),
    hasFormError: _ember["default"].computed.or('needsPlanUpgrade', 'needsStackSelection'),
    hasModelErrors: _ember["default"].computed.gt('newAccount.errors.length', 0),

    model: _ember["default"].computed.alias('createAccountModel'),
    newAccount: _ember["default"].computed.alias('model.newAccount'),

    title: _ember["default"].computed('model.organization.name', function () {
      var bits = ['Create a new Environment'];
      var organizationName = this.get('model.organization.name');
      if (organizationName) {
        bits.push('in');
        bits.push(organizationName);
      }
      return bits.join(' ');
    }),

    authorizationContext: _ember["default"].computed('model.organization.id', function () {
      var organizationId = this.get('model.organization.id');
      return this.get('authorization').getContext(organizationId);
    }),

    eligibleStacks: _ember["default"].computed('authorizationContext.eligibleDevelopmentStacks.[]', 'authorizationContext.eligibleProductionStacks.[]', 'model.type', function () {
      // NOTE: During component teardown, the array might be null.
      var attr = this.get('model.type') === 'production' ? 'eligibleProductionStacks' : 'eligibleDevelopmentStacks';
      return this.get("authorizationContext." + attr) || [];
    }),

    needsStackSelection: _ember["default"].computed('eligibleStacks.[]', 'model.stack', function () {
      if (_ember["default"].isEmpty(this.get('eligibleStacks'))) {
        return false;
      }

      return !this.get('model.stack');
    }),

    // TODO: Unit test coverage for this executing on init (this is otherwise
    // implicitly tested by "Acceptance: Stacks: Accounts | it allows creating a
    // new production environment").
    resetStackSelection: _ember["default"].observer('model.stack', 'eligibleStacks.[]', function () {
      var stackId = this.get('model.stack.id');

      if (!stackId) {
        return;
      }

      var eligibleStackIds = this.get('eligibleStacks').mapBy('id');

      if (eligibleStackIds.indexOf(stackId) < 0) {
        this.set('model.stack', null);
      }
    }).on('init'),

    setup: _ember["default"].on('didInsertElement', function () {
      // After initializing add default fixed window class

      // Adding this class to remove the overflows just the account modal for now.
      this.$('.panel-body').addClass('no-overflow');
    }),

    validations: {
      'model.handle': {
        uniqueness: {
          message: 'is taken.',
          paramName: 'handle',
          url: (_dieselConfigEnvironment["default"].apiBaseUri || '') + "/claims/account"
        }
      }
    },

    actions: {
      beforeDismiss: function beforeDismiss() {
        this.get('newAccount').rollbackAttributes();
      },

      createAccount: function createAccount() {
        var _this = this;

        // NOTE: I don't really like saving here (rather than an action), but
        // unfortunately, we need to sendAction('dismissModal') to properly close this
        // modal, so...
        // Update Note: The calling route/controller can now close the modal so it
        // is possible to refactor this code so that the action happens on the route.
        var newAccount = this.get('newAccount');

        newAccount.setProperties({
          handle: this.get('model.handle'),
          stack: this.get('model.stack'),
          organization: this.get('model.organization'),
          type: this.get('model.type')
        });

        return newAccount.save().then(function (account) {
          _this.get('analytics').trackAccountCreation(account);
          _this.sendAction('accountCreated', account);
        })["catch"](_dieselUtilsIgnoreInvalidError["default"]);
      }
    }
  });
});