define('diesel/verify-email/controller', ['exports', 'ember', 'diesel/utils/location'], function (exports, _ember, _dieselUtilsLocation) {
  exports['default'] = _ember['default'].Controller.extend({
    sending: false,
    sent: false,
    error: null,
    isDisabled: _ember['default'].computed.or('sending', 'sent'),
    actions: {
      doLogout: function doLogout() {
        return this.get('session').close('application', {
          token: this.get('session.token')
        })['finally'](function () {
          return _dieselUtilsLocation['default'].replaceAndWait('/');
        });
      },
      resendVerification: function resendVerification() {
        var _this = this;

        this.setProperties({ sending: true, error: null });
        var user = this.get('session.currentUser');
        this.get("store").createRecord("email-verification-challenge", {
          user: user, email: user.get('email')
        }).save().then(function () {
          _this.set('sent', true);
        }, function (e) {
          _this.set('error', e);
        })['finally'](function () {
          _this.set('sending', false);
        });
      }
    }
  });
});