define('diesel/models/incident', ['exports', 'ember-data', 'diesel/models/base', 'ember'], function (exports, _emberData, _dieselModelsBase, _ember) {
  var INCIDENT_SEVERITY_VALUES = ['Low', 'Medium', 'High', 'Critical'];
  exports.INCIDENT_SEVERITY_VALUES = INCIDENT_SEVERITY_VALUES;
  var INCIDENT_SEVERITY_ITEMS = [{
    handle: 1,
    name: INCIDENT_SEVERITY_VALUES[0],
    tooltip: 'Any of the following:\n              a) A minor policy violation or availability issue;\n              b) Adversely impacts a small number of non-critical systems\n              and/or people; or\n              c) Little probability of propagation and further disruption'
  }, {
    handle: 2,
    name: INCIDENT_SEVERITY_VALUES[1],
    tooltip: 'Any of the following:\n              a) Adversely impacts a moderate number of systems and/or\n              people; or\n              b) Moderate probability of propagating and causing further\n              disruption'
  }, {
    handle: 3,
    name: INCIDENT_SEVERITY_VALUES[2],
    tooltip: 'Any of the following:\n              a) Significant adverse impact on a large number of systems\n              and/or people;\n              b) Potential large financial risk or legal liability;\n              c) Involves potential criminal activity;\n              d) Threatens Confidential data;\n              e) Adversely impacts a critica system or service;\n              f) Adversely impacts a large number of systems and/or\n              people; or\n              g) High probability of propagating and causing further\n              disruption'
  }, {
    handle: 4,
    name: INCIDENT_SEVERITY_VALUES[3],
    tooltip: 'Any of the following:\n              a) Involves potential, accidental, or other unauthorized\n              access to, use, or disclosure of Sensitive/Regulated data; or\n              b) Significant and immediate threat to human safety'
  }];

  exports.INCIDENT_SEVERITY_ITEMS = INCIDENT_SEVERITY_ITEMS;
  exports['default'] = _dieselModelsBase['default'].extend({
    title: _emberData['default'].attr('string'),
    description: _emberData['default'].attr('string'),
    descriptionSafe: _emberData['default'].attr('string'),
    severity: _emberData['default'].attr('number', { defaultValue: 1 }),
    testTicket: _emberData['default'].attr('boolean'),
    eventTimeline: _emberData['default'].attr('string'),
    eventTimelineSafe: _emberData['default'].attr('string'),
    retrospective: _emberData['default'].attr('string'),
    retrospectiveSafe: _emberData['default'].attr('string'),
    impact: _emberData['default'].attr('string'),
    impactSafe: _emberData['default'].attr('string'),
    closedAt: _emberData['default'].attr('date'),
    createdAt: _emberData['default'].attr('date'),
    updatedAt: _emberData['default'].attr('date'),
    organizationProfile: _emberData['default'].belongsTo('organization-profile', { async: true }),
    activities: _emberData['default'].hasMany('activity', { async: true }),

    tipTestTicket: 'This is a sample ticket for incident response testing and training.',

    severityItems: INCIDENT_SEVERITY_ITEMS,

    severityItem: _ember['default'].computed('severityItems', function () {
      return this.get('severityItems')[this.get('severity') - 1];
    }),

    severityClass: _ember['default'].computed('severityLabel', function () {
      return this.get('severityLabel').toLowerCase();
    }),

    severityLabel: _ember['default'].computed('severity', function () {
      var index = this.get('severity') - 1 || 0;
      return INCIDENT_SEVERITY_VALUES[index];
    }),

    status: _ember['default'].computed('closedAt', function () {
      return this.get('closedAt') ? 'closed' : 'open';
    }),

    isComplete: _ember['default'].computed('title', 'description', 'severity', 'eventTimeline', 'retrospective', 'impact', function () {
      return _ember['default'].isPresent(this.get('title')) && _ember['default'].isPresent(this.get('description')) && _ember['default'].isPresent(this.get('eventTimeline')) && _ember['default'].isPresent(this.get('retrospective')) && _ember['default'].isPresent(this.get('impact'));
    }),

    isOpen: _ember['default'].computed.equal('status', 'open'),

    isClosed: _ember['default'].computed.not('isOpen')
  });
});