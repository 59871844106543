define('diesel/models/service', ['exports', 'ember', 'ember-data', 'diesel/models/base', 'diesel/mixins/components/service-scaling'], function (exports, _ember, _emberData, _dieselModelsBase, _dieselMixinsComponentsServiceScaling) {
  var GB = 1024;
  exports.GB = GB;
  var ABSOLUTE_MAX_CONTAINER_SIZE_IN_GB = 653;
  var containerSizes = [GB / 4, GB / 2]; // Container sizes start at 512?

  for (var i = 1; i <= ABSOLUTE_MAX_CONTAINER_SIZE_IN_GB; i++) {
    containerSizes.push(GB * i);
  }

  var LINEAR_CONTAINER_SIZES = containerSizes;
  exports.LINEAR_CONTAINER_SIZES = LINEAR_CONTAINER_SIZES;
  var EXPONENTIAL_CONTAINER_SIZES = [GB / 2, GB, 2 * GB, 4 * GB, 7 * GB, 15 * GB, 30 * GB, 60 * GB, 150 * GB, 240 * GB];
  exports.EXPONENTIAL_CONTAINER_SIZES = EXPONENTIAL_CONTAINER_SIZES;
  var DEFAULT_INSTANCE_CLASS = 'm4';
  exports.DEFAULT_INSTANCE_CLASS = DEFAULT_INSTANCE_CLASS;
  var CONTAINER_PROFILES = {
    t3: {
      name: 'Economy (T)',
      costPerContainerHourInCents: 3.5,
      cpuShare: .5 / GB,
      minimumContainerSize: GB / 4,
      maximumContainerSize: 27 * GB,
      maximumContainerCount: 32
    },
    m4: {
      name: 'General Purpose (M) - Legacy',
      costPerContainerHourInCents: 8,
      cpuShare: .25 / GB,
      minimumContainerSize: GB / 2,
      maximumContainerSize: 240 * GB,
      maximumContainerCount: 32
    },
    m5: {
      name: 'General Purpose (M)',
      costPerContainerHourInCents: 8,
      cpuShare: .25 / GB,
      minimumContainerSize: GB / 2,
      maximumContainerSize: 368 * GB,
      maximumContainerCount: 32
    },
    r4: {
      name: 'Memory Optimized (R) - Legacy',
      costPerContainerHourInCents: 5,
      cpuShare: .125 / GB,
      minimumContainerSize: 4 * GB,
      maximumContainerSize: 472 * GB,
      maximumContainerCount: 32
    },
    r5: {
      name: 'Memory Optimized (R)',
      costPerContainerHourInCents: 5,
      cpuShare: .125 / GB,
      minimumContainerSize: 4 * GB,
      maximumContainerSize: 653 * GB,
      maximumContainerCount: 32
    },
    c4: {
      name: 'Compute Optimized (C) - Legacy',
      costPerContainerHourInCents: 10,
      cpuShare: .5 / GB,
      minimumContainerSize: 2 * GB,
      maximumContainerSize: 58 * GB,
      maximumContainerCount: 32
    },
    c5: {
      name: 'Compute Optimized (C)',
      costPerContainerHourInCents: 10,
      cpuShare: .5 / GB,
      minimumContainerSize: 2 * GB,
      maximumContainerSize: 163 * GB,
      maximumContainerCount: 32
    }
  };

  exports.CONTAINER_PROFILES = CONTAINER_PROFILES;
  exports['default'] = _dieselModelsBase['default'].extend(_dieselMixinsComponentsServiceScaling['default'], {
    vhosts: _emberData['default'].hasMany('vhost', { async: true }),
    account: _emberData['default'].belongsTo('account', { async: true }),
    app: _emberData['default'].belongsTo('app', { async: true }),
    database: _emberData['default'].belongsTo('database', { async: true }),

    handle: _emberData['default'].attr('string'),
    command: _emberData['default'].attr('string'),
    containerCount: _emberData['default'].attr('number'),
    containerMemoryLimitMb: _emberData['default'].attr('number'),
    processType: _emberData['default'].attr('string'),

    currentRelease: _emberData['default'].belongsTo('release', { async: true }),
    inService: _ember['default'].computed.gt('containerCount', 0),
    instanceClass: _emberData['default'].attr('string', { defaultValue: DEFAULT_INSTANCE_CLASS }),

    containerSize: _ember['default'].computed('containerMemoryLimitMb', function () {
      return this.get('containerMemoryLimitMb') || GB;
    }),

    containerSizeGB: _ember['default'].computed('containerMemoryLimitMb', function () {
      return this.get('containerSize') / GB;
    }),

    usage: _ember['default'].computed('containerCount', 'containerSize', function () {
      return this.get('containerCount') * (this.get('containerSize') / GB);
    }),

    name: _ember['default'].computed('processType', 'displayCommand', function () {
      return this.get('processType') + ' - ' + this.get('displayCommand');
    }),

    displayCommand: _ember['default'].computed('command', function () {
      return this.get('command') || 'Docker Image CMD';
    })
  });
});