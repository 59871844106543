define('diesel/databases/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    newDatabase: null, // Set this to open new db modal
    diskSize: 10,
    sortBy: ['handle:asc'],

    persistedDatabases: _ember['default'].computed.filterBy('model', 'isNew', false),
    hasNoDatabases: _ember['default'].computed.equal('persistedDatabases.length', 0),
    deployedDatabases: _ember['default'].computed.filterBy('persistedDatabases', 'isProvisioned'),
    pendingDatabases: _ember['default'].computed.filterBy('persistedDatabases', 'isProvisioningOrPending'),
    deprovisioningDatabases: _ember['default'].computed.filterBy('persistedDatabases', 'isDeprovisioning'),

    deprovisionedDatabases: _ember['default'].computed.filterBy('persistedDatabases', 'isDeprovisioned'),
    failedProvisionDatabases: _ember['default'].computed.filterBy('persistedDatabases', 'hasFailedProvision'),
    failedDeprovisionDatabases: _ember['default'].computed.filterBy('persistedDatabases', 'hasFailedDeprovision'),

    // Sorted databases by status
    sortedDeployedDatabases: _ember['default'].computed.sort('deployedDatabases', 'sortBy'),
    sortedDeprovisioningDatabases: _ember['default'].computed.sort('deprovisioningDatabases', 'sortBy'),
    sortedPendingDatabases: _ember['default'].computed.sort('pendingDatabases', 'sortBy'),
    sortedDeprovisionedDatabases: _ember['default'].computed.sort('deprovisionedDatabases', 'sortBy'),
    sortedFailedProvisionDatabases: _ember['default'].computed.sort('failedProvisionDatabases', 'sortBy'),
    sortedFailedDeprovisionDatabases: _ember['default'].computed.sort('failedDeprovisionDatabases', 'sortBy'),

    hasActive: _ember['default'].computed.gt('deployedDatabases.length', 0),
    hasPending: _ember['default'].computed.gt('pendingDatabases.length', 0),
    hasDeprovisioning: _ember['default'].computed.gt('deprovisioningDatabases.length', 0),
    hasDeprovisioned: _ember['default'].computed.gt('deprovisionedDatabases.length', 0),
    hasFailedProvision: _ember['default'].computed.gt('failedProvisionDatabases.length', 0),
    hasFailedDeprovision: _ember['default'].computed.gt('failedDeprovisionDatabases.length', 0),
    showSortableHeader: _ember['default'].computed.or('hasPending', 'hasDeprovisioning', 'hasFailedProvision', 'hasFailedProvision')
  });
});