define('diesel/reports/hipaa-security-report/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    modalDisplay: _ember['default'].inject.service(),

    administrativeSections: _ember['default'].computed.filterBy('reportData.sections', 'safeguard_type', 'administrative'),
    physicalSections: _ember['default'].computed.filterBy('reportData.sections', 'safeguard_type', 'physical'),
    technicalSections: _ember['default'].computed.filterBy('reportData.sections', 'safeguard_type', 'technical'),

    configureControl: function configureControl(component) {
      var type = 'security-control';
      this.get('modalDisplay').showModal('modal-risk-component-editor', {
        modalRiskComponentEditorProperties: { component: component, type: type }
      });
    }
  });
});