define('diesel/components/bs-tooltip/component', ['exports', 'ember', 'diesel/mixins/components/bootstrap-component-options'], function (exports, _ember, _dieselMixinsComponentsBootstrapComponentOptions) {
  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsBootstrapComponentOptions['default'], {
    tagName: 'span',
    _el: null,
    setupTooltip: _ember['default'].on('didInsertElement', function () {
      var options = this.getBootstrapOptions();
      this._el = this.$();
      this._el.tooltip(options);

      if (options.trigger === 'immediate') {
        this._el.tooltip('show');
      }
    }),

    teardownTooltip: _ember['default'].on('willDestroyElement', function () {
      if (this._el) {
        this._el.tooltip('destroy');
        this._el = null;
      }
    })
  });
});
/**
 * Usage
 *
 * Required params:
 *   * title: The text to be displayed in the tooltip
 * Optional params:
 *   * placement: 'left','right','top','bottom' (default: 'bottom')
 *   * bs-html: Whether HTML should be allowed. Default: false
 *   * bs-trigger: The triggering event ('click', 'focus', etc.). Default: none
 *   * bs-container: The container to use for the tooltip (e.g., 'body'). Default: none
 *
  {{#bs-tooltip title='The tooltip text'}}
    Hover over this text to see the tooltip.
  {{/bs-tooltip}}
 */