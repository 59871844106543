define('diesel/app/vhosts/index/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    titleToken: function titleToken() {
      var app = this.modelFor('app');
      return app.get('handle') + ' Endpoints';
    },

    redirect: function redirect(model) {
      if (model.get('length') === 0) {
        return this.transitionTo('app.vhosts.new', this.modelFor('app'));
      }
    },

    actions: {
      startDeletion: function startDeletion() {
        this.controller.set('error', null);
      },

      failDeletion: function failDeletion() /* e */{
        this.controller.set('error', 'There was an error deleting the endpoint.');
      },

      completeDeletion: function completeDeletion() {
        this.controller.set('error', null);
      },

      forceRedirect: function forceRedirect() {
        return this.transitionTo('app.vhosts.new', this.modelFor('app'));
      }
    }
  });
});