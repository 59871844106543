define('diesel/components/vulnerability-report/component', ['exports', 'ember', 'diesel/config/environment'], function (exports, _ember, _dieselConfigEnvironment) {

  var OPERATION_TIMEOUT = _dieselConfigEnvironment['default'].timeouts.imageScanOperation;

  var STATE_PENDING = 0;
  var STATE_SCAN = 1;
  var STATE_REPORT = 2;
  var STATE_OK = 3;
  var STATE_FAIL = 4;

  exports['default'] = _ember['default'].Component.extend({
    slotMachine: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),

    classNames: ['panel', 'panel-default'],

    image: null,

    report: null,
    reportState: STATE_PENDING,
    reportError: null,

    isPending: _ember['default'].computed.equal('reportState', STATE_PENDING),
    isScanning: _ember['default'].computed.equal('reportState', STATE_SCAN),
    isReporting: _ember['default'].computed.equal('reportState', STATE_REPORT),

    isOk: _ember['default'].computed.equal('reportState', STATE_OK),
    isFail: _ember['default'].computed.equal('reportState', STATE_FAIL),

    isFinal: _ember['default'].computed.or('isOk', 'isFail'),
    isWaiting: _ember['default'].computed.not('isFinal'),

    reportStateLabel: _ember['default'].computed('reportState', function () {
      var state = this.get('reportState');

      if (state === STATE_PENDING) {
        return 'Preparing scan';
      }

      if (state === STATE_SCAN) {
        return 'Scanning image (this will take a few minutes)...';
      }

      if (state === STATE_REPORT) {
        return 'Identifying vulnerable packages (this will take a few seconds)...';
      }
    }),

    doReport: (function () {
      var _this = this;

      var image = this.get('image');
      var scanPromise = undefined;

      this.set('reportState', STATE_SCAN);
      if (image.get('scan')) {
        scanPromise = _ember['default'].RSVP.resolve();
      } else {
        scanPromise = this.get('store').createRecord('operation', {
          type: 'scan', image: image
        }).save().then(function (o) {
          return o.reloadUntilStatusChanged(OPERATION_TIMEOUT);
        });
      }

      scanPromise.then(function () {
        _this.set('reportState', STATE_REPORT);
        return _this.get('slotMachine').fetchReport(image).then(function (report) {
          _this.set('reportState', STATE_OK);
          _this.set('report', report);
        });
      })['catch'](function (e) {
        _this.set('reportState', STATE_FAIL);
        _this.set('error', e.message);
      });
    }).observes('image'),

    doInitialReport: (function () {
      this.doReport();
    }).on('init'),

    actions: {
      refresh: function refresh() {
        this.doReport();
      }
    }
  });
});