define('diesel/create-dedicated-stack/route', ['exports', 'ember', 'diesel/utils/handleify'], function (exports, _ember, _dieselUtilsHandleify) {
  function generateHandle(organizationName) {
    var suffix = "-production";
    var maxLength = 64 - suffix.length;
    return '' + (0, _dieselUtilsHandleify.handleify)(organizationName, maxLength) + suffix;
  }

  exports['default'] = _ember['default'].Route.extend({
    title: function title() {
      return "Activate Your Dedicated Stack";
    },

    model: function model() {
      return this.authorization.load();
    },

    setupController: function setupController(controller) {
      var organization = this.get('authorization.currentOrganization');
      var account = this.store.createRecord('account', {
        handle: generateHandle(organization.get('name')),
        type: 'production',
        organization: organization
      });
      controller.set('account', account);
    },

    actions: {
      activate: function activate() {
        var _this = this;

        this.controller.set('error', null);
        var organization = this.get('authorization.currentOrganization');
        var account = this.controller.get('account');
        var currentUser = this.session.get('currentUser');
        var eventParams = {
          organization_id: organization.get('id'),
          organization_name: organization.get('name'),
          user_name: currentUser.get('name'),
          user_email: currentUser.get('email'),
          user_id: currentUser.get('id')
        };

        this.controller.set('isSaving', true);

        account.save().then(function () {
          var analytics = _this.get('analytics');
          var EVENT_NAME = 'dedicated-stack.requested';
          analytics.track(EVENT_NAME, eventParams);
          analytics.aptibleEvent(EVENT_NAME, account.get('handle'));
          return _this.authorization.load();
        }).then(function () {
          _this.controller.set('isSaving', false);
          var message = 'Your Dedicated Stack Request has been received. Aptible staff will follow up with next steps shortly.';
          _ember['default'].get(_this, 'flashMessages').success(message);
          _this.transitionTo('accounts');
        })['catch'](function (e) {
          var msg = e.message || 'Something went wrong!';
          _this.controller.set('isSaving', false);
          _this.controller.set('error', msg);
        });
      }
    }
  });
});