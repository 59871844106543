define('diesel/components/component-profiles-filter-bar/component', ['exports', 'ember', 'diesel/models/component'], function (exports, _ember, _dieselModelsComponent) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['component-profiles__filter-bar', 'filter-bar'],
    types: _dieselModelsComponent.COMPONENT_TYPES,
    actions: {
      clear: function clear() {
        this.set('filters.type', null);
        this.set('filters.searchValue', null);
      },

      update: function update() {
        var type = this.$('select.type').val();
        this.set('filters.type', type);
      }
    }
  });
});