define('diesel/components/report-asset-inventory-row/component', ['exports', 'ember', 'diesel/reports/asset-inventory-report/route'], function (exports, _ember, _dieselReportsAssetInventoryReportRoute) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    showName: _ember['default'].computed('column', function () {
      return this.get('column') === _dieselReportsAssetInventoryReportRoute.NAME;
    }),
    showType: _ember['default'].computed('column', function () {
      return this.get('column') === _dieselReportsAssetInventoryReportRoute.TYPE;
    }),
    showOwner: _ember['default'].computed('column', function () {
      return this.get('column') === _dieselReportsAssetInventoryReportRoute.OWNER;
    }),
    showNetwork: _ember['default'].computed('column', function () {
      return this.get('column') === _dieselReportsAssetInventoryReportRoute.NETWORK;
    }),
    showVendor: _ember['default'].computed('column', function () {
      return this.get('column') === _dieselReportsAssetInventoryReportRoute.VENDOR;
    }),
    showDataClassification: _ember['default'].computed('column', function () {
      return this.get('column') === _dieselReportsAssetInventoryReportRoute.DATA_CLASSIFICATION;
    }),
    showBusinessCriticality: _ember['default'].computed('column', function () {
      return this.get('column') === _dieselReportsAssetInventoryReportRoute.BUSINESS_CRITICALITY;
    }),
    showFinancialValue: _ember['default'].computed('column', function () {
      return this.get('column') === _dieselReportsAssetInventoryReportRoute.FINANCIAL_VALUE;
    }),
    showStatus: _ember['default'].computed('column', function () {
      return this.get('column') === _dieselReportsAssetInventoryReportRoute.STATUS;
    }),
    showLegalRequirements: _ember['default'].computed('column', function () {
      return this.get('column') === _dieselReportsAssetInventoryReportRoute.LEGAL_REQUIREMENTS;
    })
  });
});