define('diesel/components/modal-wrapper/component', ['exports', 'ember', 'diesel/config/environment'], function (exports, _ember, _dieselConfigEnvironment) {

  var MAX_TRIES = 5;
  var FOCUS_DELAY = _dieselConfigEnvironment['default'].timeouts.modalFocus;

  exports['default'] = _ember['default'].Component.extend({
    modalDisplay: _ember['default'].inject.service(),

    wide: false,

    // This feels a little dirty, but it works. ¯\_(0_o)_/¯
    focusHandle: _ember['default'].on('didInsertElement', function () {
      var _this = this;

      // Since this is can be called via a 200ms delay, it can be removed before
      // the call happends.
      if (this.element === null) {
        return;
      }
      this._focusTries = this._focusTries || 0;
      this._focusTries++;

      if (this._focusTries >= MAX_TRIES) {
        // give up
        return;
      }

      // Note all selectors below use the ":visible" pseudo-selector because
      // otherwise this method will get stuck in a loop, repeatedly trying (and
      // failing) to focus on a hidden input
      // Get inputs first
      var focusMe = this.$('input:visible') || [];

      // textareas...
      if (focusMe.length < 1) {
        focusMe = this.$('textarea:visible');
      }

      // links...
      if (focusMe.length < 1) {
        focusMe = this.$('a:visible');
      }

      // any buttons?...
      if (focusMe.length < 1) {
        focusMe = this.$('button:visible');
      }

      // Finally, take the modal wrapper
      if (focusMe.length < 1) {
        // Adding a tabindex=0 makes it focus-able
        $(this.element).attr('tabindex', 0);
        focusMe = this.element;
      } else {
        // Grab the first element
        focusMe = focusMe[0];
      }

      _ember['default'].run.later(function () {
        focusMe.focus();
        // Try again, if the active element didn't change.
        if (document.activeElement.tagName === 'BODY') {
          _this.focusHandle();
        }
      }, FOCUS_DELAY);
    }),

    actions: {
      dismissModal: function dismissModal() {
        this.get('modalDisplay').dismissModal();
      }
    }
  });
});