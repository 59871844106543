define('diesel/policy-manual/print/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    titleToken: function titleToken() {
      return 'Policy Manual';
    },

    setupController: function setupController(controller, model) {
      var status = this.get('complianceStatus');
      controller.set('model', model);
      controller.set('complianceStatus', status);
    }
  });
});