define('diesel/utils/hubspot', ['exports', 'ember', 'diesel/config/environment', 'diesel/utils/url-parameters'], function (exports, _ember, _dieselConfigEnvironment, _dieselUtilsUrlParameters) {
  function _toArray(arr) { return Array.isArray(arr) ? arr : Array.from(arr); }

  var HUBSPOT_API_URL = 'https://api.hsforms.com/submissions/v3/integration/submit/' + _dieselConfigEnvironment['default'].hubspotPortalId + '/' + _dieselConfigEnvironment['default'].hubspotSignupFormId;
  var utms = ['utm_campaign', 'utm_medium', 'utm_source', 'utm_term', 'gclid'];

  var generateField = function generateField(field, value) {
    return {
      objectTypeId: '0-1', // 0-1 is used for Contacts
      name: field,
      value: value
    };
  };

  var addUtmsToPayload = function addUtmsToPayload(payload) {
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = utms[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var param = _step.value;

        var value = (0, _dieselUtilsUrlParameters.getUrlParameter)(window.location, param);
        if (value) {
          payload.fields.push(generateField(param, value));
        }
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator['return']) {
          _iterator['return']();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }
  };

  var submitHubspotForm = function submitHubspotForm(name, email, company, organization_id) {
    if (!_dieselConfigEnvironment['default'].hubspotPortalId || !_dieselConfigEnvironment['default'].hubspotSignupFormId) {
      return;
    }

    var _name$split = name.split(' ');

    var _name$split2 = _toArray(_name$split);

    var firstName = _name$split2[0];

    var lastName = _name$split2.slice(1);

    var payload = {
      fields: [generateField('firstname', firstName), generateField('lastname', lastName.toString()), generateField('email', email), generateField('company', company), generateField('organization_id', organization_id)],
      context: {
        pageUri: '' + window.location.origin + window.location.pathname,
        pageName: window.document.title
      }
    };

    // Capture UTMs, google click ID from URL
    addUtmsToPayload(payload);

    // Submit form through the Hubspot API
    _ember['default'].$.ajax(HUBSPOT_API_URL, {
      method: 'POST',
      data: JSON.stringify(payload),
      headers: { 'content-type': 'application/json' }
    });

    // hsforms.com might be ad-blocked. Instead of returning the promise from
    // the ajax request, which might cause the entire signup process to fail,
    // return a new promise that just waits 250ms to give the request enough time
    // to send, and resolves regardless.
    /* eslint-disable no-undef */
    return new Promise(function (resolve) {
      setTimeout(function () {
        resolve();
      }, 250);
    });
  };
  exports.submitHubspotForm = submitHubspotForm;
});