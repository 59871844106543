define('diesel/services/storage', ['exports', 'ember', 'diesel/utils/storage'], function (exports, _ember, _dieselUtilsStorage) {
  exports['default'] = _ember['default'].Service.extend({
    unknownProperty: function unknownProperty(key) {
      return _dieselUtilsStorage['default'].read(key);
    },

    setUnknownProperty: function setUnknownProperty(key, value) {
      _dieselUtilsStorage['default'].write(key, value);
      this.notifyPropertyChange(key);
    }
  });
});