define('diesel/router', ['exports', 'ember', 'ember-inflector', 'diesel/config/environment', 'diesel/utils/token-bucket', 'diesel/utils/ring-buffer'], function (exports, _ember, _emberInflector, _dieselConfigEnvironment, _dieselUtilsTokenBucket, _dieselUtilsRingBuffer) {
  var RISK_ASSESSMENT_COMPONENTS = ['threat_events', 'predisposing_conditions', 'security_controls', 'threat_sources', 'vulnerabilities'];

  var inflector = new _emberInflector['default'](_emberInflector['default'].defaultRules);

  var Router = _ember['default'].Router.extend({
    analytics: _ember['default'].inject.service(),
    elevation: _ember['default'].inject.service(),
    flashMessages: _ember['default'].inject.service(),
    location: _dieselConfigEnvironment['default'].locationType,
    rootURL: _dieselConfigEnvironment['default'].rootURL,
    modalDisplay: _ember['default'].inject.service(),

    init: function init() {
      this._super.apply(this, arguments);

      // Allow 20 transitions per second, burst up to 200.
      this.transitionTokenBucket = new _dieselUtilsTokenBucket['default'](200, 20);

      // Log the last 10 transitions.
      this.transitionRingBuffer = new _dieselUtilsRingBuffer['default'](10);
    },

    onBeforeTransition: _ember['default'].on('willTransition', function (transition) {
      // TODO: Can this be added to the requires-elevation route instead?
      return this.get('elevation').tryDropPrivileges(transition);
    }),

    _doTransition: function _doTransition(_targetRouteName, models, _queryParams) {
      this.transitionRingBuffer.push(_targetRouteName);

      var tokensLeft = this.transitionTokenBucket.take();
      _ember['default'].debug('_doTransition to ' + _targetRouteName + ': ' + tokensLeft + ' tokens left');

      if (!tokensLeft) {
        var lastTransitions = this.transitionRingBuffer.buffer.join(' > ');
        var m = '_doTransition: too many transitions, cycle? ' + lastTransitions;
        throw new Error(m);
      }

      return this._super(_targetRouteName, models, _queryParams);
    },

    onTransition: _ember['default'].on('didTransition', function () {
      this.get('analytics').page(document.location.pathname);
      this.get('modalDisplay').dismissModal();

      if (_dieselConfigEnvironment['default'].flashMessageDefaults.sticky) {
        this.get('flashMessages').clearMessages();
      }
    })
  });

  function spdSteps() {
    this.route('organization');
    this.route('locations');
    this.route('team', {}, function () {
      this.route('designations');
      this.route('roles');
      this.route('contact');
      this.route('legal');
    });
    this.route('procedures');
    this.route('systems');
    this.route('web-services');
    this.route('devices');
    this.route('components');
    this.route('predisposing-conditions');
    this.route('security-controls');
  }

  Router.map(function () {
    this.route('stacks');
    this.route('stacks', { path: 'stacks/*bits' });

    this.authenticatedRoute('requires-authorization', { path: '/', resetNamespace: true }, function () {
      this.route('verify-email', { path: 'verify-email', resetNamespace: true });
      this.route('requires-email-verification', { path: '', resetNamespace: true }, function () {
        this.route('settings', { resetNamespace: true }, function () {
          this.route('requires-elevation', { path: 'protected' }, function () {
            // REVIEW: We *need* to have a path for this, otherwise the index
            // redirect to profile won't work. Is /protected/ the 'right' path?
            this.route('admin');
            this.route('ssh');
          });
          this.route('profile');
          this.route('whitelist');
          this.route('cli-sso-token', {}, function () {
            this.route('display');
          });
          this.route('impersonate');
          this.route('logout');
        });

        this.route('organization', { resetNamespace: true, path: 'organizations/:organization_id' }, function () {
          this.route('members', {}, function () {
            this.route('edit', { path: ':user_id/edit' });
            this.route('otp-reset', { path: ':user_id/otp-reset' });
          });
          this.route('pending-invitations');
          this.route('role', { resetNamespace: true, path: '/roles/:role_id' }, function () {
            this.route('members');
            this.route('environments');
            this.route('settings');
          });
          this.route('roles', {}, function () {
            this.route('type', { path: ':type' });
          });
          this.route('invite');

          this.route('environments');
          this.route('single-sign-on');

          this.route('admin', {}, function () {
            this.route('contact-settings');
            this.route('billing', {}, function () {
              this.route('plan');
              this.route('billing-address');
              this.route('payment-methods', function () {
                this.route('new', function () {
                  this.route('ach');
                });
              });
              this.route('invoices', function () {
                this.route('empty');
              });
              this.route('billing-iteration', { path: '/invoices/:billing_iteration_id' }, function () {
                this.route('charges');
                this.route('usage');
                this.route('line-items');
                this.route('payments', function () {
                  this.route('new');
                });
              });
              this.route('contracts', function () {
                this.route('empty');
              });
              this.route('contract', { path: '/contracts/:contract_id' });
              this.route('credits');
              this.route('contacts', function () {
                this.route('new');
              });
            });
          });
        });

        this.route('print-controls', { resetNamespace: true });
        this.route('controls', { resetNamespace: true }, function () {
          this.route('no-access');
          this.route('control', { path: ':id' }, function () {
            this.route('implementation');
            this.route('details');
          });
        });

        this.route('create-dedicated-stack', { resetNamespace: true });

        this.route('enclave-wrapper', { path: '', resetNamespace: true }, function () {
          this.route('no-permissions', { resetNamespace: true });
          this.route('no-account', { resetNamespace: true });
          this.route('re-enter', { resetNamespace: true });
          this.route('enclave', { path: '', resetNamespace: true }, function () {
            this.route('app', {
              resetNamespace: true,
              path: 'apps/:app_id'
            }, function () {
              this.route('services', function () {
                this.route('service', { path: ':service_id' }, function () {
                  this.route('scale');
                  this.route('metrics');
                });
              });
              this.route('vhosts', {}, function () {
                this.route('new');
                this.route('edit', { path: ':vhost_id/edit' });
              });
              this.route('activity');
              this.route('security-scan');
              this.route('deprovision');
              this.route('get-started');
            });

            this.route('database', {
              resetNamespace: true,
              path: 'databases/:database_id'
            }, function () {
              this.route('metrics', function () {
                this.route('scale');
              });
              this.route('vhosts', function () {
                this.route('vhost', { path: ':vhost_id' });
              });
              this.route('activity');
              this.route('backups');
              this.route('replicate');
              this.route('cluster');
              this.route('deprovision');
            });

            // NOTE: This is under "stack" as opposed to "stacks", which is
            // somewhat inconsistent with the rest of our routing. However,
            // considering that accounts have historically been under "stacks",
            // reusing "stacks", we have a redirect from "stacks" to "accounts",
            // which would break if we used "stacks" here.  All in all, it's
            // probably not worth breaking URLs simply for the sake of using
            // "stacks" here, so we make the URL "stack" instead.
            this.route('stack', { resetNamespace: true, path: 'stack/:stack_id' }, function () {
              this.route('accounts');
              this.route('vpn-tunnels');
              this.route('vpc-peers');
              this.route('managed-hids');
              this.route('deprovision');
            });

            this.route('accounts', { resetNamespace: true });

            this.route('account', { resetNamespace: true, path: 'accounts/:account_id' }, function () {
              this.route('deprovision');
              this.route('activity');
              this.route('backups');
              this.route('activate', { path: 'activate' });
              this.route('metric-drains', { resetNamespace: true });
              this.route('log-drains', { path: 'logging', resetNamespace: true });
              this.route('apps', { resetNamespace: true }, function () {});
              this.route('databases', { resetNamespace: true }, function () {});
              this.route('certificates', { resetNamespace: true }, function () {});
            });
          });
        });

        this.route('gridiron', { path: 'gridiron', resetNamespace: true }, function () {
          this.route('gridiron-organization', { path: ':organization_id', resetNamespace: true }, function () {
            var _this2 = this;

            this.route('policy-manual', { path: 'policy_manuals/:policy_manual_id', resetNamespace: true }, function () {
              this.route('policy', { path: 'policies/:policy_id' });
              this.route('print');
            });

            this.route('assignment', { path: 'assignments/:assignment_id', resetNamespace: true }, function () {
              this.route('course', function () {
                this.route('material', function () {
                  this.route('module', { path: '/:module' });
                });
              });
            });

            this.route('incident', { path: 'incidents/:incident_id', resetNamespace: true }, function () {
              this.route('timeline');
              this.route('impact');
              this.route('retro');
              this.route('activity');
            });

            this.route('customer', { path: 'customers/:customer_id', resetNamespace: true });

            this.route('vendor', { path: 'vendors/:vendor_id', resetNamespace: true }, function () {
              this.route('agreements');
              this.route('certifications');
              this.route('systems');
            });

            this.authenticatedRoute('print-risk-assessment', { path: 'risk_assessments/:risk_assessment_id/print', resetNamespace: true }, function () {});
            this.authenticatedRoute("risk-assessment", { path: 'risk_assessments/:risk_assessment_id', resetNamespace: true }, function () {
              this.route("summary");
              this.route("methodology");
              this.route("background");
              this.route("risk-management");
              this.route("risk-model", {}, function () {
                var _this = this;

                RISK_ASSESSMENT_COMPONENTS.forEach(function (component) {
                  var indexRoute = component.replace('_', '-');
                  _this.route(indexRoute, { path: component, resetNamespace: true }, function () {});
                });
              });
            });

            RISK_ASSESSMENT_COMPONENTS.forEach(function (component) {
              var indexRoute = component.replace('_', '-');
              var showRoute = inflector.singularize(indexRoute);
              var showPath = component + '/:id';

              _this2.route(showRoute, { path: showPath, resetNamespace: true }, function () {});
            });

            this.route('gridiron-user', { path: 'user', resetNamespace: true }, function () {});
            this.route('gridiron-admin', { path: 'admin', resetNamespace: true }, function () {
              this.route('asset-new', { path: 'assets/new', resetNamespace: true }, function () {
                this.route('type', { path: ':type' }, function () {
                  this.route('handle', { path: ':handle' }, function () {});
                });
              });
              this.route('assets', { path: 'assets', resetNamespace: true }, function () {
                this.route('type', { path: ':type' });
              });
              this.route('asset', { path: 'assets/:type/:id', resetNamespace: true }, function () {
                this.route('dependencies');
                this.route('dependents');
                this.route('business-continuity');
                this.route('impacts');
                this.route('risks');
                this.route('pdcs');
                this.route('controls');
                this.route('procedures');
                this.route('reviews');
              });

              this.route('reports', { path: 'reports', resetNamespace: true }, function () {
                this.route('asset-inventory-report');
                this.route('audit-log-report');
                this.route('training-history-report');
                this.route('business-continuity-report');
                this.route('hipaa-security-report', {}, function () {});
              });
              this.route('training', { resetNamespace: true }, function () {
                this.route('courses');
                this.route('status');
              });
              this.route('security-assessments', { resetNamespace: true });
              this.route('security-assessment', { path: 'security-assessments/:scope/:handle', resetNamespace: true }, function () {
                this.route('predisposing-conditions', {}, function () {});
                this.route('security-controls', {}, function () {});
              });
              this.route('risk-assessments', { path: 'risk_assessments', resetNamespace: true });
              this.route('policy-manuals', { path: 'policies', resetNamespace: true });
              this.route('security');
              this.route('contracts');
              this.route('customers', { resetNamespace: true });
              this.route('incidents', { resetNamespace: true });
              this.route('security-reviews', { path: 'security_reviews' });
              this.route('vendors', { resetNamespace: true });
              this.route('gridiron-settings', { path: 'settings', resetNamespace: true }, spdSteps);
              this.route('gridiron-setup', { path: 'setup', resetNamespace: true }, function () {
                this.route('finish');
                spdSteps.call(this);
              });
            });
          });

          this.route('policy-editor', function () {
            this.route('node', { path: '/:path' });
            this.route('json');
          });
        });
      });
    });

    this.authenticatedRoute('welcome', { path: '/welcome/:organization_id', resetNamespace: true }, function () {
      this.route('payment-info');
      this.route('plan');
    });

    this.authenticatedRoute('elevate', { resetNamespace: true });

    this.authenticatedRoute('no-organization', { resetNamespace: true });

    this.route('sso', {}, function () {
      this.route('direct', { path: '/:organization_id' });
      this.route('failure', { path: '/failure' });
    });
    this.route('login');
    this.route('signup', {}, function () {
      this.route('invitation', { path: '/invitation/:invitation_id/:verification_code' });
    });

    this.route('password', {}, function () {
      this.route('reset');
      this.route('update', { path: 'update/:challenge_id/:verification_code' });
    });

    this.route('otp-reset-verify', { path: 'otp-reset-verify/:challenge_id/:verification_code' });

    this.authenticatedRoute('verify', { path: 'verify/:challenge_id/:verification_code' });

    this.route('claim', { path: 'claim/:invitation_id/:verification_code' });
    this.route('not-found', { path: '/*wildcard' });
  });

  exports['default'] = Router;
});