define('diesel/serializers/user', ['exports', 'diesel/serializers/application'], function (exports, _dieselSerializersApplication) {
  exports['default'] = _dieselSerializersApplication['default'].extend({
    serialize: function serialize(snapshot, options) {
      var data = this._super(snapshot, options);
      var otp = snapshot.belongsTo('currentOtpConfiguration');
      var otp_href = otp && otp.record && otp.record.get('links.self');

      if (otp_href) {
        data.current_otp_configuration = otp_href;
      }

      var selected_org = snapshot.belongsTo('selectedOrganization');
      var id = selected_org && selected_org.id;

      if (id) {
        data.selected_organization_id = id;
      }
      return data;
    }
  });
});