define('diesel/models/database-credential', ['exports', 'ember', 'ember-data', 'diesel/models/base', 'uri'], function (exports, _ember, _emberData, _dieselModelsBase, _uri) {
  exports['default'] = _dieselModelsBase['default'].extend({
    type: _emberData['default'].attr('string'),
    'default': _emberData['default'].attr('boolean'),
    connectionUrl: _emberData['default'].attr('string'),

    rewrite: function rewrite(host, portMapping) {
      var u = (0, _uri['default'])(this.get('connectionUrl'));
      u.hostname(host);

      var portPair = portMapping.find(function (pair) {
        return parseInt(u.port()) === pair[0];
      });

      if (!portPair) {
        return null;
      }

      u.port(portPair[1]);

      return _ember['default'].Object.create({
        type: this.get('type'),
        defaut: this.get('default'),
        connectionUrl: u.toString()
      });
    }
  });
});