define('diesel/apps/route', ['exports', 'ember', 'diesel/utils/fetch-all-pages', 'diesel/utils/ignore-invalid-error'], function (exports, _ember, _dieselUtilsFetchAllPages, _dieselUtilsIgnoreInvalidError) {
  exports['default'] = _ember['default'].Route.extend({
    modalDisplay: _ember['default'].inject.service(),

    title: function title() {
      var account = this.modelFor('account');
      return account.get('handle') + ' Apps';
    },

    model: function model() {
      var account = this.modelFor('account');
      return (0, _dieselUtilsFetchAllPages['default'])(this.store, account, 'app');
    },

    setupController: function setupController(controller, model) {
      var account = this.modelFor('account');
      controller.setProperties({ model: model, account: account });
    },

    actions: {
      openCreateAppModal: function openCreateAppModal() {
        var account = this.modelFor('account');
        var newApp = this.store.createRecord('app', { account: account });

        var modalDisplay = this.get('modalDisplay');
        modalDisplay.showModal('modal-create-app', { newApp: newApp, account: account }, {
          actions: { onCreateApp: modalDisplay.modalAction(this, 'onCreateApp') }
        });
      },

      onCreateApp: function onCreateApp(app) {
        var _this = this;

        _ember['default'].assert('App must have account with id', !!app.get('account.id'));

        return app.save().then(function () {
          _this.transitionTo('app', app);
          var message = app.get('handle') + ' app created';
          _ember['default'].get(_this, 'flashMessages').success(message);
        })['catch'](_dieselUtilsIgnoreInvalidError['default']);
      }
    }
  });
});