define('diesel/components/c-load-user-by-id/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),
    organization: null,
    userId: null,
    user: _ember['default'].computed('organization', 'userId', function () {
      var _getProperties = this.getProperties('store', 'userId');

      var store = _getProperties.store;
      var userId = _getProperties.userId;

      return store.find('user', userId);
    })
  });
});