define('diesel/database/activity/route', ['exports', 'ember', 'diesel/mixins/routes/multi-resource-operation-list'], function (exports, _ember, _dieselMixinsRoutesMultiResourceOperationList) {
  exports['default'] = _ember['default'].Route.extend(_dieselMixinsRoutesMultiResourceOperationList['default'], {
    getResources: function getResources() {
      var database = this.modelFor('database');
      var resources = [database];
      database.get('databaseCredentials').forEach(function (credential) {
        resources.push(credential);
      });
      return _ember['default'].RSVP.resolve(resources);
    },

    titleToken: function titleToken() {
      return this.modelFor('database').get('handle') + ' Activity';
    }
  });
});