define('diesel/instance-initializers/raven-setup', ['exports', 'ember', 'diesel/config/environment', 'diesel/utils/assign'], function (exports, _ember, _dieselConfigEnvironment, _dieselUtilsAssign) {
  exports.initialize = initialize;

  var EMBER_LOGGER_LEVEL_MAP = {
    debug: 'debug',
    info: 'info',
    log: 'info',
    warn: 'warning',
    error: 'error'
  };

  function wrapEmberLoggerMethod(method) {
    var originalMethod = _ember['default'].Logger[method];
    _ember['default'].Logger[method] = function () {
      for (var _len = arguments.length, bits = Array(_len), _key = 0; _key < _len; _key++) {
        bits[_key] = arguments[_key];
      }

      var message = bits.map(_ember['default'].inspect).join(' ');
      var category = 'Ember.Logger';
      var level = EMBER_LOGGER_LEVEL_MAP[method];

      window.Raven.captureBreadcrumb({ message: message, level: level, category: category });

      return originalMethod.apply(_ember['default'].Logger, bits);
    };
  }

  function initialize(appInstance) {
    if (_ember['default'].get(_dieselConfigEnvironment['default'], 'sentry.development') === true) {
      if (_ember['default'].get(_dieselConfigEnvironment['default'], 'sentry.debug') === true) {
        _ember['default'].Logger.info('`sentry` is configured for development mode.');
      }
      return;
    }

    if (!_dieselConfigEnvironment['default'].sentry) {
      throw new Error('`sentry` should be configured when not in development mode.');
    }

    var _config$sentry = _dieselConfigEnvironment['default'].sentry;
    var dsn = _config$sentry.dsn;
    var _config$sentry$debug = _config$sentry.debug;
    var debug = _config$sentry$debug === undefined ? true : _config$sentry$debug;
    var _config$sentry$includePaths = _config$sentry.includePaths;
    var includePaths = _config$sentry$includePaths === undefined ? [] : _config$sentry$includePaths;
    var _config$sentry$whitelistUrls = _config$sentry.whitelistUrls;
    var whitelistUrls = _config$sentry$whitelistUrls === undefined ? [] : _config$sentry$whitelistUrls;
    var _config$sentry$serviceName = _config$sentry.serviceName;
    var serviceName = _config$sentry$serviceName === undefined ? 'raven' : _config$sentry$serviceName;
    var _config$sentry$ravenOptions = _config$sentry.ravenOptions;
    var ravenOptions = _config$sentry$ravenOptions === undefined ? {} : _config$sentry$ravenOptions;

    var lookupName = 'service:' + serviceName;
    var service = appInstance.lookup(lookupName);

    var release = window.$("meta[name='sentry:revision']").attr('content');
    if (!release) {
      release = service.get('release') || _dieselConfigEnvironment['default'].APP.version;
      _ember['default'].Logger.warn('sentry:revision is not set, sourcemaps may not work');
    }
    _ember['default'].Logger.info('sentry release: ' + release);

    try {
      window.Raven.debug = debug;

      // Keeping existing config values for includePaths, whitelistUrls, for compatibility.
      var ravenConfig = (0, _dieselUtilsAssign['default'])({
        includePaths: includePaths,
        whitelistUrls: whitelistUrls,
        release: release
      }, ravenOptions);

      window.Raven.config(dsn, ravenConfig);

      Object.keys(EMBER_LOGGER_LEVEL_MAP).forEach(wrapEmberLoggerMethod);
    } catch (e) {
      _ember['default'].Logger.error('Error during `sentry` initialization: ' + e);
      return;
    }

    window.Raven.install();

    var _config$sentry$globalErrorCatching = _dieselConfigEnvironment['default'].sentry.globalErrorCatching;
    var globalErrorCatching = _config$sentry$globalErrorCatching === undefined ? true : _config$sentry$globalErrorCatching;

    if (globalErrorCatching === true) {
      service.enableGlobalErrorCatching();
    }
  }

  exports['default'] = {
    initialize: initialize,
    name: 'sentry-setup'
  };
});