define('diesel/components/usage-quote-by-resource/component', ['exports', 'ember'], function (exports, _ember) {
  var HOURS_PER_MONTH = 730;

  exports.HOURS_PER_MONTH = HOURS_PER_MONTH;
  var RESOURCE_LABELS = {
    'domain': 'endpoint',
    'disk': 'disk',
    'container': 'container'
  };

  exports['default'] = _ember['default'].Component.extend({
    resourceHandle: _ember['default'].computed('resource', function () {
      return this.get('resource').toLowerCase();
    }),

    resourceTitle: _ember['default'].computed('resourceHandle', function () {
      return RESOURCE_LABELS[this.get('resourceHandle')].capitalize().pluralize();
    }),

    resourceLabel: _ember['default'].computed('resourceHandle', function () {
      return RESOURCE_LABELS[this.get('resourceHandle')];
    }),

    diskUsage: _ember['default'].computed.mapBy('accounts', 'totalDiskSize'),
    domainUsage: _ember['default'].computed.mapBy('accounts', 'domainCount'),
    containerUsage: _ember['default'].computed.mapBy('services', 'usage'),

    totalDiskUsage: _ember['default'].computed.sum('diskUsage'),
    totalDomainUsage: _ember['default'].computed.sum('domainUsage'),
    totalContainerUsage: _ember['default'].computed.sum('containerUsage'),

    grossUsage: _ember['default'].computed('resource', function () {
      switch (this.get('resource')) {
        case 'container':
          return this.get('totalContainerUsage');
        case 'disk':
          return this.get('totalDiskUsage');
        case 'domain':
          return this.get('totalDomainUsage');
      }
    }),

    rate: _ember['default'].computed('resource', 'hourlyRate', function () {
      if (this.get('resource') === 'disk') {
        var rate = Math.round(this.get('hourlyRate') * HOURS_PER_MONTH) / 100;
        return '$' + rate.toFixed(2) + '/GB per month';
      } else {
        return '$' + (this.get('hourlyRate') / 100).toFixed(2) + ' per hour';
      }
    }),

    netUsage: _ember['default'].computed('grossUsage', 'allowance', 'resource', function () {
      return Math.max(this.get('grossUsage') - this.get('allowance'), 0);
    }),

    totalUsageCost: _ember['default'].computed('resource', 'netUsage', 'hourlyRate', function () {
      return this.get('netUsage') * this.get('hourlyRate') * HOURS_PER_MONTH;
    })
  });
});