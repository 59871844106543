define('diesel/app/services/controller', ['exports', 'ember', 'diesel/config/environment'], function (exports, _ember, _dieselConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    inService: _ember['default'].computed('model.[]', 'app.hasBeenDeployed', function () {
      return this.get('app.hasBeenDeployed') && this.get('model.length');
    }),
    appUrlToAppDeployments: _ember['default'].computed('app.id', function () {
      return _dieselConfigEnvironment['default'].aptibleHosts.appUrl + '/apps/' + this.get('app.id') + '/git/setup';
    }),
    ftuxEnabled: _ember['default'].computed('account.onboardingStatus', function () {
      var status = this.get('account.onboardingStatus');
      return _dieselConfigEnvironment['default'].featureFlags.showFTUX && status !== 'unknown';
    })
  });
});