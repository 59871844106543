define('diesel/asset/dependents/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var asset = this.modelFor('asset');

      if (asset.get('isComponent')) {
        var dependents = asset.get('dependents');
        return _ember['default'].RSVP.hash({ asset: asset, dependents: dependents });
      } else {
        var _asset$getProperties = asset.getProperties('primaryComponents', 'systemDependencies');

        var primaryComponents = _asset$getProperties.primaryComponents;
        var systemDependencies = _asset$getProperties.systemDependencies;

        return _ember['default'].RSVP.hash({ asset: asset, primaryComponents: primaryComponents, systemDependencies: systemDependencies, dependents: [] });
      }
    },

    setupController: function setupController(controller, model) {
      // For systems with primary components, consolidate into single dependents
      // array
      if (model.primaryComponents && model.primaryComponents.get('length')) {
        model.dependents = model.dependents.concat(model.primaryComponents.toArray());
      }

      if (model.systemDependencies) {
        model.dependents = model.dependents.concat(model.systemDependencies.mapBy('component'));
      }

      controller.setProperties(model);
    }
  });
});