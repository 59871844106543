define('diesel/vulnerability/index/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.modelFor('vulnerability');
    },

    setupController: function setupController(controller, model) {
      controller.set('model', model.vulnerability);
      controller.set('riskAssessment', model.riskAssessment);
    }
  });
});