define('diesel/mixins/services/document-status', ['exports', 'ember', 'diesel/helpers/format-utc-timestamp'], function (exports, _ember, _dieselHelpersFormatUtcTimestamp) {
  var COMPLIANCE_STATUSES = {
    NOT_REQUIRED: 'notRequired',
    ACTIVE: 'complete',
    NEEDS_ATTENTION: 'expired',
    INCOMPLETE: 'incomplete'
  };

  exports.COMPLIANCE_STATUSES = COMPLIANCE_STATUSES;
  var RECENT_ACTIVITY_COUNT = 3;

  exports.RECENT_ACTIVITY_COUNT = RECENT_ACTIVITY_COUNT;
  function formatDate(date) {
    return (0, _dieselHelpersFormatUtcTimestamp.formatUtcTimestamp)(date, true);
  }

  exports['default'] = _ember['default'].Mixin.create({
    hasNoDocuments: _ember['default'].computed.equal('documents.length', 0),
    hasNoActiveDocuments: _ember['default'].computed.equal('activeDocuments.length', 0),
    hasActiveDocuments: _ember['default'].computed.gt('activeDocuments.length', 0),
    hasExpiredDocuments: _ember['default'].computed.gt('expiredDocuments.length', 0),
    activeDocuments: _ember['default'].computed.filterBy('documents', 'isExpired', false),
    expiredDocuments: _ember['default'].computed.filterBy('documents', 'isExpired', true),

    recentActivityDocuments: _ember['default'].computed('documents.[]', function () {
      if (!this.get('documents')) {
        return [];
      }

      return this.get('documents').slice(0, RECENT_ACTIVITY_COUNT);
    }),

    lastCompletedDate: _ember['default'].computed('activeDocuments.[]', function () {
      var completedDate = this.get('activeDocuments.firstObject.createdAt');

      if (completedDate) {
        return formatDate(completedDate);
      }
    }),

    lastCompletedExpirationDate: _ember['default'].computed('activeDocuments.[]', function () {
      var expiresDate = this.get('activeDocuments.firstObject.expiresAt');

      if (expiresDate) {
        return formatDate(expiresDate);
      }
    }),

    lastExpirationDate: _ember['default'].computed('documents.[]', function () {
      var lastExpired = this.get('documents.firstObject.expiresAt');

      if (lastExpired) {
        return formatDate(lastExpired);
      }
    }),

    getStatusByCounts: function getStatusByCounts(activeCount, expiredCount, totalCount) {
      if (activeCount === totalCount) {
        return COMPLIANCE_STATUSES.ACTIVE;
      }

      if (activeCount + expiredCount === totalCount) {
        return COMPLIANCE_STATUSES.NEEDS_ATTENTION;
      }

      return COMPLIANCE_STATUSES.INCOMPLETE;
    }
  });
});