define("diesel/models/saml-configuration", ["exports", "ember-data", "diesel/models/base", "ember"], function (exports, _emberData, _dieselModelsBase, _ember) {
  exports["default"] = _dieselModelsBase["default"].extend({
    metadataUrl: _emberData["default"].attr("string"),
    metadata: _emberData["default"].attr("string"),
    entityId: _emberData["default"].attr("string"),
    signInUrl: _emberData["default"].attr("string"),
    nameFormat: _emberData["default"].attr("string"),
    handle: _emberData["default"].attr("string"),

    organization: _emberData["default"].belongsTo("organization", { async: false }),

    ssoProvider: _ember["default"].computed("entityId", function () {
      var provider = this.get("entityId");
      if (provider && provider.match(/^https?:\/\/([a-z]+\.)?okta/)) {
        return "Okta";
      }
      return provider;
    })
  });
});