define('diesel/components/modal-approve-document/component', ['exports', 'ember', 'diesel/mixins/components/modal-base'], function (exports, _ember, _dieselMixinsComponentsModalBase) {
  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsModalBase['default'], {
    // Inputs
    approvalDocumentParams: null,

    store: _ember['default'].inject.service(),
    changeTypes: _ember['default'].computed('approvalDocument.version', 'approvalDocument.revision', function () {
      var approvalDocument = this.get('approvalDocument');
      if (!approvalDocument) {
        return [];
      }

      var _approvalDocument$getProperties = approvalDocument.getProperties('version', 'revision');

      var version = _approvalDocument$getProperties.version;
      var revision = _approvalDocument$getProperties.revision;

      return [{ handle: 'version', name: 'Version ' + (version + 1) + ' Revision 1', tooltip: 'Version changes indicate a significant change to this document.' }, { handle: 'revision', name: 'Version ' + version + ' Revision ' + (revision + 1), tooltip: 'Revision changes indicate a minor change to this document.' }];
    }),

    isInitialDraftDocument: _ember['default'].computed.equal('approvalDocument.version', 0),
    changeType: 'version',
    approvalDescription: null,

    approvalDocument: _ember['default'].computed.alias('approvalDocumentParams.approvalDocument'),
    description: _ember['default'].computed.alias('approvalDocumentParams.approvalDocument.name'),

    title: _ember['default'].computed('approvalDocumentParams.type', function () {
      var type = this.get('approvalDocumentParams.type');

      if (type === 'policyManual') {
        return 'Approve Policy Manual';
      } else {
        return 'Approve Risk Assessment';
      }
    }),

    disableSave: _ember['default'].computed('signature', 'isSaving', function () {
      if (this.get('isSaving')) {
        return true;
      }

      return this.get('signature') !== this.get('approvalDocumentParams.currentUser.name');
    }),

    todaysDate: _ember['default'].computed(function () {
      return new Date().toLocaleDateString("en-US");
    }),

    actions: {
      beforeDismiss: function beforeDismiss() {
        this.set('approvalDescription', null);
        this.set('signature', null);
      },

      approveDocument: function approveDocument() {
        var _this = this;

        this.set('isSaving', true);

        var _get = this.get('approvalDocumentParams');

        var type = _get.type;
        var currentUserProfile = _get.currentUserProfile;
        var organizationProfile = _get.organizationProfile;
        var approvalDocument = _get.approvalDocument;

        var _getProperties = this.getProperties('changeType', 'approvalDescription');

        var changeType = _getProperties.changeType;
        var approvalDescription = _getProperties.approvalDescription;

        var approval = undefined;
        var store = this.get('store');

        if (type === 'policyManual') {
          var policyManual = approvalDocument;
          approval = store.createRecord('policy-manual-approval', {
            policyManual: policyManual, approvalDescription: approvalDescription, changeType: changeType
          });
        } else {
          var riskAssessment = approvalDocument;
          approval = store.createRecord('risk-assessment-approval', {
            riskAssessment: riskAssessment, approvalDescription: approvalDescription, changeType: changeType
          });
        }

        currentUserProfile.save().then(function () {
          return approval.save();
        }).then(function () {
          return organizationProfile.reload();
        }).then(function () {
          return organizationProfile.reloadDocuments();
        }).then(function () {
          _this.sendAction('onDocumentApproval', approvalDocument);
        })['catch'](function (error) {
          _this.set('isSaving', false);
          _this.sendAction('onApprovalError', error);
        });
      }
    }
  });
});