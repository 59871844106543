define('diesel/components/account-type-notice/component', ['exports', 'ember', 'diesel/config/environment'], function (exports, _ember, _dieselConfigEnvironment) {

  var contentArgs = {
    "false": {
      iconPath: "/assets/images/ui-icons/exclamation-circle-sm.svg",
      iconAlt: 'Warning',
      title: function title(type) {
        return 'is a Shared Environment. Apps and Databases created in this Environment are NOT safe for regulated data.';
      }
    },
    "true": {
      iconPath: "/assets/images/ui-icons/check-circle-sm.svg",
      iconAlt: 'Good to go',
      title: function title() {
        return 'is on a Dedicated Stack and ready for Production.';
      }
    }
  };
  exports['default'] = _ember['default'].Component.extend({
    account: null,
    type: 'environment',
    featureFlags: _dieselConfigEnvironment['default'].featureFlags,
    tagName: '',
    content: _ember['default'].computed('account.allowPHI', 'type', function () {
      var content = contentArgs[this.get('account.allowPHI') || "false"];

      return {
        iconPath: content.iconPath,
        iconAlt: content.iconAlt,
        title: content.title(this.get('type'))
      };
    })
  });
});