define('diesel/stack/deprovision/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    authorization: _ember['default'].inject.service(),

    title: function title() {
      var stack = this.modelFor('stack');
      return 'Deprovision ' + stack.get('name') + ' Stack';
    }

  });
});