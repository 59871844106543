define('diesel/mixins/models/provisionable', ['exports', 'ember', 'ember-data', 'diesel/mixins/models/statuses', 'diesel/config/environment'], function (exports, _ember, _emberData, _dieselMixinsModelsStatuses, _dieselConfigEnvironment) {
  var RELOAD_RETRY_DELAY = _dieselConfigEnvironment['default'].timeouts.provisionableRetry;

  exports.RELOAD_RETRY_DELAY = RELOAD_RETRY_DELAY;
  var observer = _ember['default'].observer;
  var computed = _ember['default'].computed;
  var run = _ember['default'].run;
  var on = _ember['default'].on;
  var ProvisionableBaseMixin = _ember['default'].Mixin.create({
    _forceReload: false,
    _reloadRetryDelay: RELOAD_RETRY_DELAY,

    _shouldReload: function _shouldReload() {
      if (!this._forceReload && _dieselConfigEnvironment['default'].environment === 'test') {
        return false;
      }

      var _getProperties = this.getProperties('status', 'isDeleted', 'isDestroying', 'isDestroyed');

      var status = _getProperties.status;
      var isDeleted = _getProperties.isDeleted;
      var isDestroying = _getProperties.isDestroying;
      var isDestroyed = _getProperties.isDestroyed;

      var inReloadStatus = this.get('reloadOn').indexOf(status) > -1;
      var currentState = this.get('currentState.stateName');
      var inLoadedState = currentState !== 'root.empty' && currentState !== 'root.deleted.uncommitted';
      var hasDeletedAt = this.get('deletedAt') != null; // not null or undefined

      return inLoadedState && inReloadStatus && !isDeleted && !isDestroyed && !isDestroying && !hasDeletedAt;
    },

    // we should refactor this away from using observers once
    // we update to Ember Data 1.13 (where we can use the `shouldBackgroundRefresh`
    // hooks)
    _recursiveReload: observer('status', on('init', function () {
      var _this = this;

      if (!this._shouldReload()) {
        return;
      }

      this.reload().then(function () {
        run.later(_this, function () {
          if (_this.isDestroyed || _this.isDestroying) {
            return;
          }
          _this._recursiveReload();
        }, _this._reloadRetryDelay);
      })['catch'](function (err) {
        // Believe it or not, this console.log has to currently be here. This is a textbook
        // definition of a heisenbug. After adding the err.status check, if I had not added a
        // console.log or a window.alert before this check, the model would immediately remove
        // itself from the UI. If something wrong occurs, then the model reappears. Until someone
        // figures out why that is, the console.log stays.
        console.log(err); // eslint-disable-line no-console
        if (404 === err.status || err.message && err.message.indexOf('notFound') > -1) {
          _this.unloadRecord();
          return;
        }

        throw err;
      });
    })),

    isDeprovisioned: computed.equal('status', _dieselMixinsModelsStatuses['default'].DEPROVISIONED),
    isDeprovisioning: computed.equal('status', _dieselMixinsModelsStatuses['default'].DEPROVISIONING),
    isProvisioned: computed.equal('status', _dieselMixinsModelsStatuses['default'].PROVISIONED),
    isProvisioning: computed.equal('status', _dieselMixinsModelsStatuses['default'].PROVISIONING),
    hasFailedProvision: computed.equal('status', _dieselMixinsModelsStatuses['default'].PROVISION_FAILED),
    hasFailedDeprovision: computed.equal('status', _dieselMixinsModelsStatuses['default'].DEPROVISION_FAILED),
    isPending: computed.equal('status', _dieselMixinsModelsStatuses['default'].PENDING),

    // TODO-ember-data re-evaluate this property. Should it really be true when
    // "isDeprovisioning" is true? This causes a vhost to be listed twice (under
    // "deprovisioning" and "deprovisioned" headers) after clicking "delete" on
    // the apps/vhosts page. See related note in the acceptance/apps/vhosts-test
    // file
    hasBeenDeprovisioned: computed.or('isDeprovisioned', 'isDeprovisioning'),
    hasBeenDeployed: computed.not('isPending'),
    isSettled: computed.or("isDeprovisioned", "isProvisioned", "hasFailedProvision", "hasFailedDeprovision")
  });

  exports.ProvisionableBaseMixin = ProvisionableBaseMixin;
  exports['default'] = _ember['default'].Mixin.create({
    status: _emberData['default'].attr('string', { defaultValue: _dieselMixinsModelsStatuses['default'].PENDING })
  }, ProvisionableBaseMixin);
});