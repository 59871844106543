define('diesel/models/base', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    /**
     * Many records rely on `data.links` being present.
     * This model Base class explicitly defines the `links` attr.
     * This ensures that it is available at `model.get('links')`.
     * Accessing via the internal `data` property, e.g. `model.get('data.links.X')`,
     * should *not* be done, as this can cause Ember/Ember-Data to turn the internal `_data`
     * property into an ember object getters and setters. See, e.g.
     * https://github.com/201-created/ember-data-hal-9000/issues/35
     *
     */
    links: _emberData['default'].attr()
  });
});