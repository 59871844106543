define("diesel/components/container-memory-metrics/component", ["exports", "ember", "diesel/mixins/components/container-metrics"], function (exports, _ember, _dieselMixinsComponentsContainerMetrics) {
  exports["default"] = _ember["default"].Component.extend(_dieselMixinsComponentsContainerMetrics["default"], {
    showCaches: true,
    showMemoryLimit: true,

    axisLabel: "Memory usage",

    axisFormatter: function axisFormatter(v) {
      if (v > 10240) {
        // Show GBs above 10GB of usage.
        return v / 1024 + " GB";
      }
      return v + " MB";
    },

    axisBottomPadding: 0, // Memory is always > 0, we don't need padding on top of it.

    metric: _ember["default"].computed("showCaches", function () {
      if (this.get("showCaches")) {
        return "memory_all";
      } else {
        return "memory";
      }
    }),

    gridLines: _ember["default"].computed("minMemoryLimit", function () {
      var minMemoryLimit = this.get("minMemoryLimit"),
          gridLines = [];

      if (minMemoryLimit < Infinity) {
        gridLines.push({
          value: minMemoryLimit,
          text: "Memory limit (" + minMemoryLimit + " MB)",
          "class": this.get('limitEnforced') ? 'enforced' : 'not-enforced'
        });
      }

      return gridLines;
    }),

    axisMax: _ember["default"].computed("minMemoryLimit", "showMemoryLimit", function () {
      var showMemoryLimit = this.get("showMemoryLimit"),
          minMemoryLimit = this.get("minMemoryLimit");

      if (showMemoryLimit && minMemoryLimit < Infinity) {
        return minMemoryLimit;
      }
    }),

    allMemoryLimits: _ember["default"].computed.mapBy("containers", "memoryLimit"),
    applicableMemoryLimits: _ember["default"].computed.filter("allMemoryLimits", function (memoryLimit) {
      return !_ember["default"].isEmpty(memoryLimit) && memoryLimit > 0;
    }),

    minMemoryLimit: _ember["default"].computed.min("applicableMemoryLimits"),
    noMemoryLimit: _ember["default"].computed.equal("minMemoryLimit", Infinity),

    limitEnforced: _ember["default"].computed.alias('stack.memoryLimits')
  });
});