define('diesel/helpers/format-list', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports.formatList = formatList;

  // FIXME this is not bound to the property passed in. It will only
  // show the correct values on a complete rerender of a template

  function formatList(params) {
    var _params = _slicedToArray(params, 3);

    var list = _params[0];
    var property = _params[1];
    var separator = _params[2];

    return list.map(function (i) {
      return _ember['default'].get(i, property);
    }).join(separator || ', ');
  }

  exports['default'] = _ember['default'].Helper.helper(formatList);
});