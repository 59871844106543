define('diesel/asset/dependencies/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      onUpdate: function onUpdate() {
        this.get('flashMessages').success('Updated successfully');
      },

      onError: function onError(e) {
        this.get('flashMessages').danger(e.message);
      }
    }
  });
});