define('diesel/training/status/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    title: function title() {
      var organization = this.get('complianceStatus.organization.name');
      return 'Training Status - Gridiron Admin - ' + organization;
    },

    model: function model() {
      return this.get('complianceStatus.trainingStatusReport');
    }
  });
});