define('diesel/services/features', ['exports', 'ember-feature-flags/services/features', 'diesel/config/environment'], function (exports, _emberFeatureFlagsServicesFeatures, _dieselConfigEnvironment) {
  exports['default'] = _emberFeatureFlagsServicesFeatures['default'].extend({
    config: _dieselConfigEnvironment['default'],
    init: function init() {
      this._super.apply(this, arguments);

      if (_dieselConfigEnvironment['default'].featureFlags) {
        this.setup(_dieselConfigEnvironment['default'].featureFlags);
      }
    }

  });
});