define('diesel/print-controls/route', ['exports', 'ember', 'diesel/mixins/routes/controls-route-mixin'], function (exports, _ember, _dieselMixinsRoutesControlsRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_dieselMixinsRoutesControlsRouteMixin['default'], {
    title: function title() {
      return "Cloud Security Posture on Aptible (Print View)";
    },

    actions: {
      didTransition: function didTransition() {
        var analytics = this.get('analytics');
        var EVENT_NAME = 'controlReport.viewed';
        analytics.track(EVENT_NAME);
        analytics.aptibleEvent(EVENT_NAME);
      }
    }
  });
});