define('diesel/models/policy-manual', ['exports', 'ember', 'ember-data', 'diesel/models/base', 'diesel/utils/to-policy-outline', 'diesel/utils/get-documents', 'diesel/mixins/models/approvable-document'], function (exports, _ember, _emberData, _dieselModelsBase, _dieselUtilsToPolicyOutline, _dieselUtilsGetDocuments, _dieselMixinsModelsApprovableDocument) {
  exports['default'] = _dieselModelsBase['default'].extend(_dieselMixinsModelsApprovableDocument['default'], {
    status: _emberData['default'].attr('string', { defaultvalue: 'draft' }),
    graphBody: _emberData['default'].attr(),
    approvedAt: _emberData['default'].attr('date'),

    organizationProfile: _emberData['default'].belongsTo('organization-profile', { async: true, inverse: 'policyManuals' }),

    isDraft: _ember['default'].computed.equal('status', 'draft'),
    isCurrent: _ember['default'].computed.equal('status', 'current'),
    isArchived: _ember['default'].computed.equal('status', 'archive'),

    outline: _ember['default'].computed(function () {
      return (0, _dieselUtilsToPolicyOutline['default'])(this.get('graph.children'));
    }),

    documents: _ember['default'].computed(function () {
      return (0, _dieselUtilsGetDocuments['default'])(this.get('graph.children'));
    }),

    graph: _ember['default'].computed(function () {
      return (0, _dieselUtilsGetDocuments.withPolicyPaths)(this.get('graphBody'));
    })
  });
});