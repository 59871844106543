define('diesel/adapters/organization-profile-resource', ['exports', 'diesel/adapters/gridiron', 'diesel/utils/build-url-with-prefix-map'], function (exports, _dieselAdaptersGridiron, _dieselUtilsBuildUrlWithPrefixMap) {
  exports['default'] = _dieselAdaptersGridiron['default'].extend({
    _buildURL: (0, _dieselUtilsBuildUrlWithPrefixMap['default'])({
      'organization_profiles': {
        property: 'organizationProfile.id', only: ['createRecord', 'query']
      }
    }),

    buildURL: function buildURL(type, id, snapshot, requestType) {
      var params = arguments.length <= 4 || arguments[4] === undefined ? {} : arguments[4];

      if (params.organizationProfile) {
        // When loading current attestations by handle, we need to specify an
        // organization profile ID.
        var temp = this.store.createRecord(type, {
          organizationProfile: params.organizationProfile
        });

        snapshot = { record: temp };
        delete params.organizationProfile;
      }

      return this._buildURL.call(this, type, id, snapshot, requestType);
    }
  });
});