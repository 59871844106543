define('diesel/gridiron-organization/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params) {
      var authorization = this.get('authorization');
      var authorizationContext = authorization.getContext(params.organization_id);
      var complianceStatus = this.get('complianceStatus');

      // Make sure gridiron and deploy organizations are in sync
      if (params.organization_id != authorization.get('currentOrganization.id')) {
        authorization.set('currentOrganization', authorizationContext.organization);
        return authorization.load().then(function () {
          return complianceStatus.loadOrganizationStatus(authorizationContext);
        });
      } else {
        return complianceStatus.loadOrganizationStatus(authorizationContext);
      }
    },

    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('organizations', this.modelFor('gridiron'));
    }
  });
});