define('diesel/role/members/route', ['exports', 'ember', 'diesel/utils/ignore-invalid-error'], function (exports, _ember, _dieselUtilsIgnoreInvalidError) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.modelFor('role');
    },

    afterModel: function afterModel(model) {
      return model.get('memberships');
    },

    setupController: function setupController(controller, model) {
      var contextHref = model.get('links.organization');
      var authorizationContext = this.get('authorization').getContextByHref(contextHref);
      var organization = authorizationContext.organization;
      var currentUserRoles = authorizationContext.currentUserRoles;

      controller.set('role', model);
      controller.set('memberships', model.get('memberships'));
      controller.set('invitations', model.get('invitations'));
      controller.setProperties({ authorizationContext: authorizationContext, organization: organization, currentUserRoles: currentUserRoles });
    },

    actions: {
      completedAction: function completedAction(message) {
        _ember['default'].get(this, 'flashMessages').success(message);
      },

      failedAction: function failedAction(message) {
        _ember['default'].get(this, 'flashMessages').danger(message);
      },

      addMember: function addMember() {
        var _this = this;

        var user = this.controller.get('invitedUser');
        if (!user) {
          return;
        }

        var role = this.controller.get('role');
        var userUrl = user.get('links.self');
        var membership = this.store.createRecord('membership', { role: role, userUrl: userUrl, user: user });

        membership.save().then(function () {
          var message = user.get('name') + ' added to ' + role.get('name');

          role.get('users').pushObject(user);
          role.get('memberships').pushObject(membership);

          _this.controller.set('invitedUser', '');
          _ember['default'].get(_this, 'flashMessages').success(message);
        });
      },

      inviteByEmail: function inviteByEmail(email) {
        var _this2 = this;

        var role = this.controller.get('role');
        var invitation = this.controller.get('invitation');
        if (invitation) {
          invitation.set('email', email);
        } else {
          invitation = this.store.createRecord('invitation', {
            email: email,
            role: role
          });
          this.controller.set('invitation', invitation);
        }
        invitation.save().then(function () {
          _this2.controller.set('invitation', null);
          _this2.controller.set('invitedEmail', '');
          var message = 'Invitation sent to ' + email;
          _ember['default'].get(_this2, 'flashMessages').success(message);
        })['catch'](_dieselUtilsIgnoreInvalidError['default']);
      },

      destroyInvitation: function destroyInvitation(invitation) {
        var _this3 = this;

        invitation.destroyRecord().then(function () {
          var message = 'Invitation to ' + invitation.get('email') + ' destroyed';
          _ember['default'].get(_this3, 'flashMessages').success(message);
        });
      },

      resendInvitation: function resendInvitation(invitation) {
        var _this4 = this;

        var reset = this.store.createRecord('reset');
        reset.setProperties({
          type: 'invitation',
          invitationId: invitation.get('id')
        });
        reset.save().then(function () {
          var message = 'Invitation resent to ' + invitation.get('email');
          _ember['default'].get(_this4, 'flashMessages').success(message);
        });
      }
    }
  });
});