define('diesel/organization/admin/billing/contacts/new/route', ['exports', 'ember'], function (exports, _ember) {

  var BillingRoute = 'organization.admin.billing';

  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return { name: '', email: '' };
    },

    actions: {
      saveBillingContact: function saveBillingContact(model) {
        var _this = this;

        var billingDetail = this.modelFor(BillingRoute);
        var name = model.name;
        var email = model.email;

        var billingContact = this.store.createRecord('billing-contact', {
          billingDetail: billingDetail, name: name, email: email
        });

        this.controller.set('isSaving', true);

        billingContact.save().then(function () {
          _this.transitionTo(BillingRoute + '.contacts');
        }, function (e) {
          billingContact.rollbackAttributes();
          throw e;
        })['catch'](function (e) {
          _ember['default'].get(_this, 'flashMessages').danger(e.message);
        })['finally'](function () {
          _this.controller.set('isSaving', false);
        });
      }
    }
  });
});