define('diesel/components/modal-create-certificate/component', ['exports', 'ember', 'diesel/mixins/components/modal-base'], function (exports, _ember, _dieselMixinsComponentsModalBase) {
  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsModalBase['default'], {
    newCertificate: null,
    disableSave: _ember['default'].computed.or('hasError', 'isPending'),
    isPending: _ember['default'].computed.or('newCertificate.isSaving', 'newCertificate.isValidating'),
    hasError: _ember['default'].computed.gt('errors.length', 0),

    title: _ember['default'].computed('account.handle', function () {
      return 'Create a new certificate on ' + this.get('account.handle');
    }),

    description: _ember['default'].computed('account.allowPHI', function () {
      return 'Adding a certificate to ' + this.get('account.handle') + ' will allow you\n            to provision app endpoints with custom domains.';
    }),

    actions: {
      beforeDismiss: function beforeDismiss() {
        this.get('newCertificate').rollbackAttributes();
      },

      createCertificate: function createCertificate() {
        var certificate = this.get('newCertificate');
        this.sendAction('onCreateCertificate', certificate);
      }
    }
  });
});