define('diesel/components/asset-type-filters/component', ['exports', 'ember', 'diesel/mixins/models/classification'], function (exports, _ember, _dieselMixinsModelsClassification) {

  function allClassificationLevels() {
    return [{ value: 'any', label: 'Any' }].concat(Object.keys(_dieselMixinsModelsClassification.CLASSIFICATION_LEVELS).map(function (value) {
      return { value: value, label: _dieselMixinsModelsClassification.CLASSIFICATION_LEVELS[value] };
    }));
  }

  function defaultFilters() {
    return { search: '', classification: 'any' };
  }
  exports['default'] = _ember['default'].Component.extend({
    classificationLevels: allClassificationLevels(),
    filters: _ember['default'].computed(function () {
      return defaultFilters();
    }),
    showClear: _ember['default'].computed('filters.search', 'filters.classification', function () {
      return this.get('filters.search.length') || this.get('filters.classification') !== 'any';
    }),

    actions: {
      clear: function clear() {
        this.set('filters', defaultFilters());
      }
    }
  });
});