define('diesel/models/mitigation', ['exports', 'ember-data', 'diesel/models/base'], function (exports, _emberData, _dieselModelsBase) {
  exports['default'] = _dieselModelsBase['default'].extend({
    riskAssessment: _emberData['default'].belongsTo('risk-assessment'),
    efficacy: _emberData['default'].attr('number'),
    vulnerability: _emberData['default'].belongsTo('vulnerability', {
      async: false
    }),
    securityControl: _emberData['default'].belongsTo('security-control', {
      async: false
    })
  });
});