define('diesel/components/security-controls-list/component', ['exports', 'ember', 'diesel/utils/filter-risk-components', 'diesel/mixins/components/column-config'], function (exports, _ember, _dieselUtilsFilterRiskComponents, _dieselMixinsComponentsColumnConfig) {

  var COLUMN_PRESETS = {
    'title': 'Security Control',
    'description': 'Description',
    'status': 'Implemented',
    'vulnerabilities': 'Vulnerabilities'
  };

  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsColumnConfig['default'], {
    filters: { search: '', sort: null, pervasiveness: null },
    tagName: 'table',
    classNames: ['base-table'],

    columns: ['title', 'description', 'status', 'vulnerabilities'],
    columnPresets: COLUMN_PRESETS,

    filteredSecurityControls: _ember['default'].computed('filters.implemented', 'filters.sort', 'filters.search', 'securityControls.[]', function () {
      var securityControls = this.get('securityControls');
      var filters = this.get('filters');
      // For now, let's omit any security controls missing a title.  We will soon
      // seed the risk graph with all needed data.
      securityControls = securityControls.rejectBy('title', '');
      return (0, _dieselUtilsFilterRiskComponents['default'])(securityControls, filters);
    })
  });
});