define('diesel/components/modal-create-metric-drain/component', ['exports', 'ember', 'ember-validations/mixin', 'diesel/mixins/components/modal-base'], function (exports, _ember, _emberValidationsMixin, _dieselMixinsComponentsModalBase) {
  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsModalBase['default'], _emberValidationsMixin['default'], {
    createMetricDrainModel: null, // set when opening modal

    account: _ember['default'].computed.alias('createMetricDrainModel.account'),
    databases: _ember['default'].computed.alias('createMetricDrainModel.databases'),
    metricDrain: _ember['default'].computed.alias('createMetricDrainModel.metricDrain'),

    // This needs to be stored as an attribute on the component, otherwise we
    // can't read it back synchronously after writing it (because the database
    // relationship is async).
    selectedDatabase: null,

    drainTypes: [{ key: 'influxdb_database', name: 'InfluxDB (this Environment)' }, { key: 'influxdb', name: 'InfluxDB (anywhere)' }, { key: 'datadog', name: 'Datadog' }],

    title: _ember['default'].computed('account.handle', function () {
      var handle = this.get('account.handle');
      return 'Create a new Metric Drain in ' + handle;
    }),

    description: _ember['default'].computed('account.handle', function () {
      return 'Metric Drains let you collect metrics from apps and databases\n            deployed in the ' + this.get('account.handle') + ' environment and\n            route them to a metrics destination.';
    }),

    isInfluxdbDatabase: _ember['default'].computed.equal('metricDrain.drainType', 'influxdb_database'),
    isInfluxdb: _ember['default'].computed.equal('metricDrain.drainType', 'influxdb'),
    isDatadog: _ember['default'].computed.equal('metricDrain.drainType', 'datadog'),

    disableSave: _ember['default'].computed('isValid', 'metricDrain.isSaving', 'metricDrain.drainType', 'metricDrain.drainConfiguration', 'selectedDatabase', function () {
      if (!this.get('isValid')) {
        return true;
      }

      var isSaving = this.get('metricDrain.isSaving');
      if (isSaving) {
        return false;
      }

      var drainType = this.get('metricDrain.drainType');
      if (!drainType) {
        return true;
      }

      var drainConfiguration = this.get('metricDrain.drainConfiguration');
      var selectedDatabase = this.get('selectedDatabase');

      if (drainType === "influxdb_database") {
        if (!selectedDatabase) {
          return true;
        }
      } else {
        if (!drainConfiguration) {
          return true;
        }
      }

      return false;
    }),

    resetOnDrainTypeChange: _ember['default'].observer('metricDrain.drainType', function () {
      if (this.get('metricDrain.isDeleted')) {
        return;
      }

      this.set('selectedDatabase');
      this.set('metricDrain.drainConfiguration');
    }),

    validations: {
      'metricDrain.handle': { presence: true }
    },

    actions: {
      beforeDismiss: function beforeDismiss() {
        this.get('metricDrain').rollbackAttributes();
      },

      onSave: function onSave(metricDrain) {
        // See note above regarding selectedDatabase.
        var selectedDatabase = this.get('selectedDatabase');
        if (selectedDatabase) {
          this.set('metricDrain.database', selectedDatabase);
        }

        this.sendAction('createMetricDrain', metricDrain);
      }
    }
  });
});