define('diesel/components/pool-pagination/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    resourceLabel: 'Records',

    lastFetch: { exhausted: false },

    hasMore: _ember['default'].computed.not('lastFetch.exhausted'),

    actions: {
      loadMore: function loadMore() {
        this.sendAction('loadMore');
      }
    }
  });
});