define('diesel/gridiron-setup/components/route', ['exports', 'diesel/mixins/routes/spd-route', 'diesel/gridiron-settings/components/route', 'ember'], function (exports, _dieselMixinsRoutesSpdRoute, _dieselGridironSettingsComponentsRoute, _ember) {
  exports['default'] = _dieselGridironSettingsComponentsRoute['default'].extend(_dieselMixinsRoutesSpdRoute['default'], {
    afterModel: function afterModel(model) {
      return _ember['default'].RSVP.hash({
        apps: _ember['default'].RSVP.all(model.productionAccounts.map(function (account) {
          return account.get('apps');
        })),
        databases: _ember['default'].RSVP.all(model.productionAccounts.map(function (account) {
          return account.get('databases');
        })),
        componentSystemDependencies: _ember['default'].RSVP.all(model.components.map(function (profile) {
          return profile.get('systemDependencies');
        }))
      });
    }
  });
});