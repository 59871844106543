define('diesel/password/reset/route', ['exports', 'ember', 'diesel/mixins/routes/disallow-authenticated'], function (exports, _ember, _dieselMixinsRoutesDisallowAuthenticated) {
  exports['default'] = _ember['default'].Route.extend(_dieselMixinsRoutesDisallowAuthenticated['default'], {
    model: function model() {
      return this.store.createRecord('password-reset-request');
    },

    resetController: function resetController(controller) {
      controller.setProperties({
        error: null,
        hasSubmitted: false
      });
    },

    actions: {
      reset: function reset(model) {
        var _this = this;

        model.save().then(function () {
          var message = 'A password reset email has been sent to ' + model.get('email');
          _ember['default'].get(_this, 'flashMessages').success(message);
          _this.transitionTo('login');
        }, function (e) {
          var message = e.message || 'There was an error resetting your password.';
          _this.controller.set('error', message);
        });
      },
      willTransition: function willTransition() {
        this.currentModel.rollbackAttributes();
        this.controllerFor('password/reset').set('error', null);
      }
    }
  });
});