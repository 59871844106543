define('diesel/utils/token-bucket', ['exports'], function (exports) {
  exports['default'] = TokenBucket;
  // Adapted from https://github.com/gjohnson/token-bucket/ (MIT)

  function TokenBucket(capacity, refillRate) {
    if (!capacity || !refillRate) {
      throw new Error('capacity and refillRate are required');
    }

    this.capacity = capacity;
    this.refillRate = refillRate;
    this.left = this.capacity;
    this.last = time();
  }

  TokenBucket.prototype.take = function () {
    if (this.capacity === Infinity) {
      return Infinity;
    }

    // Refill rate is per second
    var now = time();
    var delta = Math.max(now - this.last, 0) / 1000;
    var amount = delta * this.refillRate;

    this.last = now;
    this.left = Math.min(this.left + amount, this.capacity);

    if (this.left < 1) {
      return false;
    }

    this.left -= 1;
    return this.left;
  };

  function time() {
    return new Date().getTime();
  }
});