define('diesel/models/plan', ['exports', 'ember-data', 'diesel/models/base'], function (exports, _emberData, _dieselModelsBase) {
  exports['default'] = _dieselModelsBase['default'].extend({
    complianceDashboardAccess: _emberData['default'].attr('boolean'),
    containerMemoryLimitMb: _emberData['default'].attr('number'),
    // gnarly, but for below - https://stackoverflow.com/a/26107853
    cpuAllowedProfiles: _emberData['default'].attr(),
    diskLimitGb: _emberData['default'].attr('number'),
    ephemeralSessionLimit: _emberData['default'].attr('number'),
    environmentLimit: _emberData['default'].attr('number'),
    name: _emberData['default'].attr('string'),
    vhostLimit: _emberData['default'].attr('number'),
    includedContainerMb: _emberData['default'].attr('number'),
    includedDiskGb: _emberData['default'].attr('number'),
    includedVhosts: _emberData['default'].attr('number')
  });
});
// import Ember from 'ember';