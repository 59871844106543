define('diesel/gridiron-settings/components/controller', ['exports', 'ember', 'diesel/models/component'], function (exports, _ember, _dieselModelsComponent) {
  var assert = _ember['default'].assert;
  exports['default'] = _ember['default'].Controller.extend({
    modalDisplay: _ember['default'].inject.service(),

    sortBy: ['handle:asc'],
    filters: { searchValue: null, type: null },

    // TODO-ember-data After Ember v2.3.1, remove the "content" property in this CP.
    // See:
    //   * Ember Data bug report detailing the need to add "content" to observe relationship CPs: https://github.com/emberjs/data/issues/3911
    //   * fix in Ember: https://github.com/emberjs/ember.js/pull/12908
    persistedComponents: _ember['default'].computed.filterBy('organizationProfile.components.content', 'isNew', false),
    components: _ember['default'].computed('filters.searchValue', 'filters.type', 'persistedComponents.@each.handle', function () {
      var searchTerms = _ember['default'].$.trim(this.get('filters.searchValue'));
      var typeFilter = this.get('filters.type');
      var persistedComponents = this.get('persistedComponents');

      if (typeFilter) {
        persistedComponents = persistedComponents.filterBy('type', typeFilter);
      }

      if (searchTerms) {
        searchTerms = new RegExp(searchTerms.toLowerCase().replace(/\W/g, ''));
        persistedComponents = persistedComponents.filter(function (component) {
          var keyword = component.get('handle').toLowerCase().replace(/\W/g, '');
          return searchTerms.test(keyword);
        });
      }

      return persistedComponents;
    }),

    emptyResultsMessage: _ember['default'].computed('components.length', 'filters.type', 'filters.searchValue', function () {
      var _get = this.get('filters');

      var type = _get.type;
      var searchValue = _get.searchValue;

      var readableType = undefined;

      if (type) {
        readableType = _dieselModelsComponent.COMPONENT_TYPES.findBy('handle', type).name;
      }

      if (type && searchValue) {
        return 'You haven\'t added any ' + readableType + ' components matching \'' + searchValue + '\'.';
      }

      if (type) {
        return 'You haven\'t added any ' + readableType + ' components.';
      }

      if (searchValue) {
        return 'You haven\'t added any components matching \'' + searchValue + '\'.';
      }

      return "You haven't added any apps, databases, or networks to Gridiron yet.";
    }),

    sortedComponents: _ember['default'].computed.sort('components', 'sortBy'),
    appComponents: _ember['default'].computed.filterBy('components', 'isApp'),
    databaseComponents: _ember['default'].computed.filterBy('components', 'isDatabase'),
    networkComponents: _ember['default'].computed.filterBy('components', 'isNetwork'),
    hasNoComponents: _ember['default'].computed.equal('components.length', 0),
    modalComponentEditorProperties: null,

    editComponent: function editComponent(component, organizationProfile, components, existingSystems, componentType, roles, users, systemTemplates) {
      assert('Must pass systemTemplates when editing component', !!systemTemplates);

      this.get('modalDisplay').showModal('modal-edit-system-component', {
        modalComponentEditorProperties: {
          allowModifyDependencies: true,
          component: component,
          organizationProfile: organizationProfile,
          components: components,
          existingSystems: existingSystems,
          componentType: componentType,
          roles: roles,
          users: users,
          systemTemplates: systemTemplates
        }
      });
    },

    importApps: function importApps(_ref, type) {
      var organizationProfile = _ref.organizationProfile;
      var productionAccounts = _ref.productionAccounts;
      var systemTemplates = _ref.systemTemplates;

      this.get('modalDisplay').showModal('modal-import-system-components', {
        modalImportAppsProperties: {
          organizationProfile: organizationProfile, productionAccounts: productionAccounts, type: type, systemTemplates: systemTemplates
        }
      });
    }
  });
});