define('diesel/models/system', ['exports', 'ember-data', 'diesel/models/base', 'ember', 'diesel/mixins/models/asset', 'diesel/mixins/models/classification', 'diesel/mixins/models/business-criticality', 'diesel/mixins/models/impacts'], function (exports, _emberData, _dieselModelsBase, _ember, _dieselMixinsModelsAsset, _dieselMixinsModelsClassification, _dieselMixinsModelsBusinessCriticality, _dieselMixinsModelsImpacts) {

  // TODO(security-reviews): The API should decorate the system response with this data
  var FREQUENCIES = {
    sensitive: 'Monthly',
    restricted: 'Quarterly',
    confidential: 'Bi-Annually',
    'public': 'Annually'
  };

  var SYSTEM_TYPES = [{ handle: 'primary', name: 'Host', tooltip: 'Hosts are platform or compute systems that host Apps and Databases.' }, { handle: 'storage', name: 'Storage Service', tooltip: 'Storage systems provide additional storage for Apps and Databases.' }, { handle: 'saas', name: 'Web Service', tooltip: 'Web Services are Third-Party Services, SaaS, and Productivity tools that your workforce uses.' }, { handle: 'logging', name: 'Logging Service', tooltip: 'Logging Services are the services acting as log destinations for your Apps and Databases.' }, { handle: 'device', name: 'Device', tooltip: 'Devices are the laptops, workstations, and mobile devices you use. ' }];

  exports.SYSTEM_TYPES = SYSTEM_TYPES;
  exports['default'] = _dieselModelsBase['default'].extend(_dieselMixinsModelsAsset['default'], _dieselMixinsModelsClassification['default'], _dieselMixinsModelsBusinessCriticality['default'], _dieselMixinsModelsImpacts['default'], {
    isSystem: true,
    name: _emberData['default'].attr('string'),
    items: _emberData['default'].attr(), // array
    templateHandle: _emberData['default'].attr('string'),
    organizationProfile: _emberData['default'].belongsTo('organizationProfile', { async: true }),
    primaryComponents: _emberData['default'].hasMany('component', { async: true, inverse: 'primarySystem' }),
    systemDependencies: _emberData['default'].hasMany('system-dependency', { async: true }),
    lastReviewedAt: _emberData['default'].attr('iso-8601-timestamp'),
    nextReviewAt: _emberData['default'].attr('iso-8601-timestamp'),
    assessmentName: _ember['default'].computed.reads('name'),
    type: _emberData['default'].attr('string'),
    requiredForIncidentResponse: _emberData['default'].attr('boolean', { defaultValue: false }),
    requiredForIncidentResponseExplanation: _emberData['default'].attr('string'),

    components: _ember['default'].computed('systemDependencies.@each.component', function () {
      return this.get('systemDependencies').map(function (sd) {
        return sd.get('component');
      });
    }),

    componentCount: _ember['default'].computed('components.length', 'primaryComponents.length', function () {
      return (this.get('components.length') || 0) + (this.get('primaryComponents.length') || 0);
    }),

    hasDependentApps: _ember['default'].computed.gt('componentCount', 0),

    frequency: _ember['default'].computed('classification', function () {
      return FREQUENCIES[this.get('classification')];
    }),

    vendorName: _ember['default'].computed('vendor.name', 'systemTemplate.readableVendor', function () {
      if (this.get('vendor')) {
        return this.get('vendor.name');
      }
      return this.get('systemTemplate.readableVendor');
    }),

    vendor: _emberData['default'].belongsTo('vendor', { async: false }),
    systemTemplate: _emberData['default'].belongsTo('systemTemplate', { async: false }),

    isPrimary: _ember['default'].computed.equal('type', 'primary'),
    isStorage: _ember['default'].computed.equal('type', 'storage'),
    isWebService: _ember['default'].computed.equal('type', 'saas'),
    isLogging: _ember['default'].computed.equal('type', 'logging'),
    isDevice: _ember['default'].computed.equal('type', 'device'),
    hasItems: _ember['default'].computed.gt('items.length', 0),

    readableType: _ember['default'].computed('type', function () {
      return SYSTEM_TYPES.findBy('handle', this.get('type')).name;
    }),

    regenerateSecurityReview: function regenerateSecurityReview() {
      // When a system changes or is created, a new security review config will
      // be generated in gridiron.  This method reloads the current security
      // review
      if (!this.get('hasItems')) {
        return;
      }

      this.get('organizationProfile').then(function (organizationProfile) {
        organizationProfile.get('currentSecurityReview').then(function (currentSecurityReview) {
          if (currentSecurityReview) {
            currentSecurityReview.reload();
          }
        });
      });
    }
  });
});