define('diesel/gridiron-settings/web-services/controller', ['exports', 'ember', 'diesel/mixins/controllers/third-party-services'], function (exports, _ember, _dieselMixinsControllersThirdPartyServices) {
  exports['default'] = _ember['default'].Controller.extend(_dieselMixinsControllersThirdPartyServices['default'], {
    filterSystemsBy: 'isWebService',
    inUseSystems: _ember['default'].computed.filterBy('systems', 'type', 'saas'),
    inUseTitle: 'In-Use Third-Party Web Services',
    availableTitle: 'Available Third-Party Web Services',
    matchNotFoundTitle: 'No Third-Party Web Services',
    inUseMatchNotFoundTitle: 'No In-Use Third-Party Web Services',

    filter: null,

    showInUse: _ember['default'].computed('filter', function () {
      return this.get('filteredInUseSaasSystems') && (this.get('filter') === null || this.get('filter') === 'inuse');
    }),

    showAvailable: _ember['default'].computed('filter', function () {
      return this.get('filteredAvailableSystemTemplates') && (this.get('filter') === null || this.get('filter') === 'available');
    }),

    actions: {
      setFilter: function setFilter(filter) {
        var val = filter;

        // Clicking again deactivates filter
        if (this.get('filter') === filter) {
          val = null;
        }

        this.set('filter', val);
      }
    }
  });
});