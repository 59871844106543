define('diesel/components/training-engine-status/component', ['exports', 'ember', 'diesel/models/assignment-state'], function (exports, _ember, _dieselModelsAssignmentState) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['panel', 'panel-default', 'compliance-engine-status', 'resource-list-item'],

    trackableAssignments: _ember['default'].computed('assignments.@each.status', function () {
      return this.get('assignments').reject(function (a) {
        return _dieselModelsAssignmentState.UNASSIGNED_STATES.includes(a.status);
      });
    }),

    totalCount: _ember['default'].computed.reads('trackableAssignments.length'),

    completed: _ember['default'].computed.filterBy('assignments', 'status', _dieselModelsAssignmentState.ASSIGNMENT_TRANSITIONS.COMPLETED),
    completeCount: _ember['default'].computed.reads('completed.length'),

    assignments: _ember['default'].computed("trainingStatusReport.report", function () {
      var assignments = [];
      var report = this.get('trainingStatusReport.report');
      if (!report) {
        return [];
      }
      report.users.forEach(function (u) {
        u.courseAssignments.forEach(function (assignment) {
          return assignments.push(assignment);
        });
      });
      return assignments;
    }),

    status: _ember['default'].computed('totalCount', 'completeCount', function () {
      var totalCount = this.get('totalCount');
      var completeCount = this.get('completeCount');
      var status = totalCount === completeCount ? 'complete' : 'incomplete';
      return { status: status };
    }),

    statusText: _ember['default'].computed('totalCount', 'completeCount', function () {
      var total = this.get('totalCount');
      var complete = this.get('completeCount');

      if (complete === total) {
        return 'All ' + complete + ' training assignments complete.';
      } else {
        return complete + ' of ' + total + ' total assignments complete';
      }
    })
  });
});