define('diesel/controls/control/implementation/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    analytics: _ember['default'].inject.service(),
    actions: {
      onIgnore: function onIgnore(resource) {
        var analytics = this.get('analytics');
        var control = this.get('model.globalControl.name');
        var EVENT_NAME = 'control.ignore.created';
        analytics.track(EVENT_NAME, { control: control, resource: resource });
        analytics.aptibleEvent(EVENT_NAME, control);
        resource.set('ignored', true);
        resource.save();
      },

      removeIgnore: function removeIgnore(resource) {
        var analytics = this.get('analytics');
        var control = this.get('model.globalControl.name');
        var EVENT_NAME = 'control.ignore.deleted';
        analytics.track(EVENT_NAME, { control: control, resource: resource });
        analytics.aptibleEvent(EVENT_NAME, control);
        resource.set('ignored', false);
        resource.save();
      },

      runEvaluation: function runEvaluation(customerControl) {
        var analytics = this.get('analytics');
        var control = this.get('model.globalControl.name');
        var EVENT_NAME = 'control.evaluated';
        analytics.track(EVENT_NAME, { control: control, customerControl: customerControl });
        analytics.aptibleEvent(EVENT_NAME, control);
        var accessToken = this.get('session.token.accessToken');
        customerControl.evaluate(accessToken).then(function () {
          customerControl.reload();
        });
      }
    }
  });
});