define("diesel/claim/route", ["exports", "ember", "diesel/utils/location", "diesel/utils/tokens"], function (exports, _ember, _dieselUtilsLocation, _dieselUtilsTokens) {
  exports["default"] = _ember["default"].Route.extend({
    model: function model(params) {
      var invitationId = params.invitation_id;
      var verificationCode = params.verification_code;

      return this.store.find('invitation', invitationId).then(function (invitation) {
        return { invitation: invitation, verificationCode: verificationCode };
      });
    },

    setupController: function setupController(controller, model) {
      var invitation = model.invitation;
      var verificationCode = model.verificationCode;

      controller.set('model', invitation);
      controller.set('verificationCode', verificationCode);
    },

    afterModel: function afterModel(model, transition) {
      if (!this.get('session.isAuthenticated')) {
        this.get('session').attemptedTransition = transition;

        var invitationId = model.invitation.id;
        var verificationCode = model.verificationCode;
        this.transitionTo('signup.invitation', invitationId, verificationCode);
      }
    },

    actions: {
      claim: function claim() {
        var _this = this;

        var invitation = this.controller.get('model');
        var verificationCode = this.controller.get('verificationCode');

        var verification = this.store.createRecord('verification', {
          type: 'invitation',
          invitationId: invitation.get('id'),
          verificationCode: verificationCode
        });

        return verification.save().then(function () {
          // If the verification succeeded, then we exchange our current token
          // for an identical replacement token. Since caching on the server-side
          // is bound to tokens, this ensures that all APIs will not use cached
          // organizations for any requests we make after the reload. This is
          // obviously a little brittle, but covered by tests in Aptible
          // Integration.
          var credentials = _this.get('session.token').credentialsForTokenExchange();
          return (0, _dieselUtilsTokens.createToken)(credentials)["finally"](function () {
            return _dieselUtilsLocation["default"].replaceAndWait('/');
          });
        }, function () {
          _this.controllerFor('claim').set('error', "\n          There was an error accepting this invitation. Perhaps\n          the verification code has expired?\n        ");
        });
      }
    }
  });
});