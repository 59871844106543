define('diesel/serializers/risk-assessment-approval', ['exports', 'diesel/serializers/application'], function (exports, _dieselSerializersApplication) {

  var generatedId = 0;

  exports['default'] = _dieselSerializersApplication['default'].extend({
    normalize: function normalize() /* modelClass, resourceHash */{
      /**
       * TODO-ember-data The server returns an invalid HAL response here
       * The structure of the server's response looks like:
        {
           _embedded: {
             id: organizationProfileId, // <-- should be the id of the created approval
             _embedded: {
               organization_profile: [ // <-- should be an object, not an array
                                       // and should contain data about the
                                       // organization profile, not its risk
                                       // assessments
                  riskAssessment1JSON, // e.g. { id, status, version, revision, ...  }
                 riskAssessment2JSON,
               ]
             }
           }
         }
       *
       * The server should be changed to provide a valid HAL response, but until
       * then the payload can be massaged here.
       * In Ember Data the `normalize`d form of the payload ends up looking like this:
       * (the included information about the risk assessments ends up being discarded):
       * {
       *   id: null,
       *   type: 'risk-assessment-approval'
       * }
       * In Ember Data 2.9.0 and below, this was an acceptable response.
       * Starting in Ember 2.10.0, the response must have a valid 'id'.
       * The solution for now is to generate an id in this normalize method.
       * We (for now) discard any other information in the API payload, as it
       * was formerly being inadvertently discarded anyway.
       */
      return {
        id: 'generated-id-' + ++generatedId,
        type: 'risk-assessment-approval'
      };
    }
  });
});