define('diesel/services/raven', ['exports', 'ember-cli-sentry/services/raven', 'ember'], function (exports, _emberCliSentryServicesRaven, _ember) {
  exports['default'] = _emberCliSentryServicesRaven['default'].extend({
    captureException: function captureException(error) {
      if (this.get('isRavenUsable')) {
        var _window$Raven;

        (_window$Raven = window.Raven).captureException.apply(_window$Raven, arguments);
      } else {
        _ember['default'].Logger.error(error);
      }
    }
  });
});