define('diesel/gridiron-settings/predisposing-conditions/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var organizationProfile = this.get('complianceStatus.organizationProfile');
      var draftRiskAssessment = organizationProfile.get('draftRiskAssessment');

      return _ember['default'].RSVP.hash({ organizationProfile: organizationProfile, draftRiskAssessment: draftRiskAssessment });
    },

    actions: {
      editComponent: function editComponent(component) {
        if (component.get('isEditable')) {
          this.controller.editComponent(component);
        }
      }
    }
  });
});