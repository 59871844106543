define('diesel/components/billing-unit/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    unit: null,
    goldenboyLib: null,

    formattedUnit: _ember['default'].computed('unit', function () {
      var unit = this.get('unit');
      return this.get('goldenboyLib').formatUnit(unit);
    })
  });
});