define('diesel/components/asset-panel-primary-system/component', ['exports', 'ember', 'diesel/models/system'], function (exports, _ember, _dieselModelsSystem) {
  exports['default'] = _ember['default'].Component.extend({
    isSaving: null,
    savingMessage: null,
    isEditing: null,
    newPrimarySystem: null,
    newAvailableSystem: null,
    error: null,
    isRequired: _ember['default'].computed.not('asset.isNetwork'),
    description: _ember['default'].computed(function () {
      return _dieselModelsSystem.SYSTEM_TYPES.findBy('handle', 'primary').tooltip;
    }),
    primarySystems: _ember['default'].computed.filterBy('systems', 'isPrimary'),
    primarySystemTemplates: _ember['default'].computed.filterBy('systemTemplates', 'isPrimary'),
    availableSystems: _ember['default'].computed('primarySystems.[]', 'primarySystemTemplates.[]', function () {
      var _this = this;

      // Only show system templates that don't yet have a system and that support this asset type
      return this.get('primarySystemTemplates').filter(function (st) {
        var inUse = _this.get('primarySystems').filterBy('templateHandle', st.get('handle')).length;
        return !inUse && st.supportsAppType(_this.get('asset.type'));
      });
    }),

    _finishSaving: function _finishSaving() {
      var _this2 = this;

      this.set('savingMessage', 'Save successful!');
      _ember['default'].run.later(this, function () {
        _this2.setProperties({ isEditing: false, isSaving: false, savingMessage: false, error: null });
        _this2.get('onUpdate')();
      }, 1000);
    },

    _saveNewPrimarySystem: function _saveNewPrimarySystem() {
      var _this3 = this;

      var _getProperties = this.getProperties('newPrimarySystem', 'asset');

      var newPrimarySystem = _getProperties.newPrimarySystem;
      var asset = _getProperties.asset;

      if (newPrimarySystem.get('id') === asset.get('primarySystem.id')) {
        // No change made
        this.setProperties({ isEditing: false, isSaving: false, savingMessage: false });
        return;
      }
      var savingMessage = 'Setting ' + newPrimarySystem.get('name') + ' as the new Primary System for ' + asset.get('assessmentName') + '...';
      this.setProperties({ isSaving: true, savingMessage: savingMessage, error: null });
      asset.set('primarySystem', newPrimarySystem);
      asset.save().then(this._finishSaving.bind(this))['catch'](function (e) {
        var error = e.message || 'Error updating ' + asset.get('assessmentName') + '!';
        _this3.setProperties({ isEditing: true, isSaving: false, savingMessage: false, error: error });
      });
    },

    _saveNewAvailableSystemTemplate: function _saveNewAvailableSystemTemplate() {
      var _this4 = this;

      var _getProperties2 = this.getProperties('newAvailableSystem', 'asset', 'organizationProfile');

      var newAvailableSystem = _getProperties2.newAvailableSystem;
      var asset = _getProperties2.asset;
      var organizationProfile = _getProperties2.organizationProfile;

      var savingMessage = 'Adding ' + newAvailableSystem.get('name') + ' as an asset to Gridiron...';

      this.setProperties({ isSaving: true, savingMessage: savingMessage, error: null });
      organizationProfile.findOrCreateSystem(newAvailableSystem).then(function (newPrimarySystem) {
        _this4.set('savingMessage', newPrimarySystem.get('name') + ' added to Gridiron! Setting as the new Primary System for ' + asset.get('assessmentName') + '...');
        asset.set('primarySystem', newPrimarySystem);
        asset.save().then(_this4._finishSaving.bind(_this4));
      })['catch'](function (e) {
        var error = e.message || 'Error updating ' + asset.get('assessmentName') + '!';
        _this4.setProperties({ isEditing: true, isSaving: false, savingMessage: false, error: error });
      });
    },

    _saveWithoutPrimarySystem: function _saveWithoutPrimarySystem() {
      var _this5 = this;

      var asset = this.get('asset');
      if (!asset.get('primarySystem.id')) {
        // If there isn't a primary system, then there is nothing to save;
        this.setProperties({ isEditing: false, isSaving: false, savingMessage: false });
        return;
      }
      var savingMessage = 'Unsetting ' + asset.get('assessmentName') + '\'s Primary Host';
      this.setProperties({ isSaving: true, savingMessage: savingMessage, error: null });
      asset.set('primarySystem', null);
      asset.save().then(this._finishSaving.bind(this))['catch'](function (e) {
        var error = e.message || 'Error updating ' + asset.get('assessmentName') + '!';
        _this5.setProperties({ isEditing: true, isSaving: false, savingMessage: false, error: error });
      });
    },

    actions: {
      edit: function edit() {
        this.setProperties({ isEditing: true, newPrimarySystem: this.get('primarySystem') });
      },

      cancel: function cancel() {
        if (this.get('isSaving')) {
          return;
        }
        this.setProperties({ isEditing: null, newPrimarySystem: null, newAvailableSystem: null });
      },

      save: function save() {
        if (this.get('isSaving')) {
          return;
        }

        if (this.get('newPrimarySystem')) {
          return this._saveNewPrimarySystem();
        }

        if (this.get('newAvailableSystem')) {
          return this._saveNewAvailableSystemTemplate();
        }

        return this._saveWithoutPrimarySystem();
      },

      selectCurrentSystem: function selectCurrentSystem(system) {
        var newPrimarySystem = this.get('newPrimarySystem') === system ? null : system;
        this.setProperties({ newPrimarySystem: newPrimarySystem, newAvailableSystem: null });
      },

      selectAvailableSystem: function selectAvailableSystem(systemTemplate) {
        this.setProperties({ newPrimarySystem: null, newAvailableSystem: systemTemplate });
      }
    }
  });
});