define('diesel/organization/admin/billing/billing-iteration/payments/index/route', ['exports', 'ember'], function (exports, _ember) {

  var BillingRoute = 'organization.admin.billing';
  var InvoiceRoute = BillingRoute + '.billing-iteration';
  var PaymentsRoute = InvoiceRoute + '.payments';

  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.modelFor(PaymentsRoute);
    },

    setupController: function setupController(controller, model) {
      var payments = model.payments;
      var paymentMethods = model.paymentMethods;
      var invoice = model.invoice;

      controller.set('model', payments);
      controller.set('paymentMethods', paymentMethods);
      controller.set('invoice', invoice);
    }
  });
});