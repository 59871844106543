define('diesel/gridiron-admin/index/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var organizationProfile = this.get('complianceStatus.organizationProfile');
      var trainingAssignments = [];
      var trainingDocuments = [];

      // When a user is removed from an organization, Gridiron has no way to know.
      // Because of this, removed users assignments will remain, causing
      // assignment counts to be higher than what is actually relevant.
      // This fixes that issue by only including assignments that belong to
      // current users.
      if (!organizationProfile.get('isNew')) {
        trainingAssignments = this.store.query('assignment', { organizationProfile: organizationProfile });
      }

      return _ember['default'].RSVP.hash({ trainingAssignments: trainingAssignments, trainingDocuments: trainingDocuments });
    },

    setupController: function setupController(controller, model) {
      var userHrefs = this.get('complianceStatus.users').map(function (u) {
        return u.get('links.self');
      });
      var trainingAssignments = model.trainingAssignments.filter(function (assignment) {
        return userHrefs.includes(assignment.get('userUrl'));
      });

      controller.set('model', this.get('complianceStatus'));
      controller.set('trainingAssignments', trainingAssignments);
    }
  });
});