define('diesel/components/attachment-download-link/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['attachment-download-link'],
    isVisible: _ember['default'].computed.bool('attachment'),
    isDownload: _ember['default'].computed.bool('attachment.fileOriginalName'),
    icon: _ember['default'].computed('attachment.fileOriginalName', function () {
      return this.get('attachment.fileOriginalName') ? 'download' : 'external-link';
    }),
    url: _ember['default'].computed('isDownload', 'attachment.downloadURL', 'attachment.fileURL', function () {
      return this.get('isDownload') ? this.get('attachment.downloadURL') : this.get('attachment.fileURL');
    }),
    name: _ember['default'].computed('attachment.fileOriginalName', 'attachment.downloadURL', 'attachment.fileURL', function () {
      return this.get('attachment.fileOriginalName') || this.get('attachment.fileURL');
    })
  });
});