define('diesel/services/u2f', ['exports', 'ember', 'diesel/config/environment'], function (exports, _ember, _dieselConfigEnvironment) {

  var REGISTER_TIMEOUT_MS = _dieselConfigEnvironment['default'].timeouts.u2fRegister;
  var SIGN_TIMEOUT_MS = _dieselConfigEnvironment['default'].timeouts.u2fSign;
  var TEST_U2F_TIMEOUT_MS = _dieselConfigEnvironment['default'].timeouts.u2fTest;

  var U2F_VERSION = 'WEBAUTHN';

  exports.U2F_VERSION = U2F_VERSION;
  var stubU2fApi = (function () {
    var KEY_HANDLE = 'stub key';
    var CHALLENGE_OK = 'stub challenge ok';
    var CHALLENGE_TIMEOUT = 'stub challenge timeout';
    var U2F_RESPONSE = 'stub success';
    var MESSAGE_PORT = 'stub mesage port';

    var register = function register(appId, requests, keys, callback) {
      try {
        if (!requests.any(function (r) {
          return r.version === U2F_VERSION;
        })) {
          return callback({ errorCode: u2f.ErrorCodes.OTHER_ERROR });
        }

        if (requests.any(function (r) {
          return r.challenge === CHALLENGE_TIMEOUT;
        })) {
          return callback({ errorCode: u2f.ErrorCodes.TIMEOUT });
        }

        if (!requests.any(function (r) {
          return r.challenge === CHALLENGE_OK;
        })) {
          return callback({ errorCode: u2f.ErrorCodes.OTHER_ERROR });
        }

        if (keys.any(function (k) {
          return k.keyHandle === KEY_HANDLE;
        })) {
          return callback({ errorCode: u2f.ErrorCodes.DEVICE_INELIGIBLE });
        }

        return callback(U2F_RESPONSE);
      } catch (e) {
        _ember['default'].Logger.warn('Error in stubU2fApi.register: ' + e);
      }
    };

    var sign = function sign(appId, challenge, keys, callback) {
      try {
        if (!keys.any(function (k) {
          return k.keyHandle === KEY_HANDLE;
        })) {
          return callback({ errorCode: u2f.ErrorCodes.DEVICE_INELIGIBLE });
        }

        if (challenge === CHALLENGE_TIMEOUT) {
          return callback({ errorCode: u2f.ErrorCodes.TIMEOUT });
        }

        if (challenge !== CHALLENGE_OK) {
          return callback({ errorCode: u2f.ErrorCodes.OTHER_ERROR });
        }

        return callback(U2F_RESPONSE);
      } catch (e) {
        _ember['default'].Logger.warn('Error in stubU2fApi.sign: ' + e);
      }
    };

    var getMessagePort = function getMessagePort(callback) {
      return callback(MESSAGE_PORT);
    };

    return {
      register: register, sign: sign, getMessagePort: getMessagePort,
      KEY_HANDLE: KEY_HANDLE, CHALLENGE_OK: CHALLENGE_OK, CHALLENGE_TIMEOUT: CHALLENGE_TIMEOUT, U2F_RESPONSE: U2F_RESPONSE
    };
  })();

  exports.stubU2fApi = stubU2fApi;
  function raceWithTimeout(realPromise, timeout, onTimeout) {
    // NOTE: Timeouts aren't super reliable in U2F: the device sometimes bails
    // out early and we never get a timeout, which is we set up races.
    if (_dieselConfigEnvironment['default'].environment === 'test') {
      // In test, we don't actually enforce the timeout, otherwise all our tests
      // will hang on waiting for the timeout to be reached every time we call
      // andThen().
      return realPromise;
    }

    var timeoutPromise = new _ember['default'].RSVP.Promise(function (resolve) {
      _ember['default'].run.later(function () {
        resolve(onTimeout);
      }, timeout);
    });

    return _ember['default'].RSVP.race([realPromise, timeoutPromise]);
  }

  exports['default'] = _ember['default'].Service.extend({
    cachedSupport: undefined,
    overrideU2fApi: null,

    getAppId: function getAppId() {
      return _dieselConfigEnvironment['default'].authBaseUri + '/u2f/trusted_facets';
    },

    getU2fApi: function getU2fApi() {
      return this.get('overrideU2fApi') || (_dieselConfigEnvironment['default'].environment === 'test' ? stubU2fApi : u2f);
    },

    testSupport: function testSupport() {
      var _this = this;

      var cachedSupport = this.get('cachedSupport');
      if (cachedSupport !== undefined) {
        return _ember['default'].RSVP.resolve(cachedSupport);
      }

      var peek = new _ember['default'].RSVP.Promise(function (resolve) {
        _this.getU2fApi().getMessagePort(function () {
          return resolve(true);
        });
      })['catch'](function () {
        return false;
      });

      return raceWithTimeout(peek, TEST_U2F_TIMEOUT_MS, false).then(function (r) {
        _ember['default'].debug('U2F support: ' + (r ? 'present' : 'absent'));
        _this.set('cachedSupport', r);
        return r;
      });
    },

    testWebAuthnSupport: function testWebAuthnSupport() {
      var _this2 = this;

      var cachedSupport = this.get('cachedSupport');
      if (cachedSupport !== undefined) {
        return _ember['default'].RSVP.resolve(cachedSupport);
      }

      var peek = new _ember['default'].RSVP.Promise(function (resolve) {
        if (window.PublicKeyCredential) {
          resolve(true);
        } else {
          resolve(false);
        }
      });

      return raceWithTimeout(peek, TEST_U2F_TIMEOUT_MS, false).then(function (r) {
        _ember['default'].debug('U2F support: ' + (r ? 'present' : 'absent'));
        _this2.set('cachedSupport', r);
        return r;
      });
    },

    requireSupport: function requireSupport() {
      return this.testSupport().then(function (hasU2fSupport) {
        if (!hasU2fSupport) {
          throw new Error('Your browser does not support Security Keys');
        }
      });
    },

    requireWebAuthnSupport: function requireWebAuthnSupport() {
      return this.testWebAuthnSupport().then(function (hasU2fSupport) {
        if (!hasU2fSupport) {
          throw new Error('Your browser does not support Security Keys');
        }
      });
    },

    register: function register(u2fChallenge, u2fDevices) {
      var publicKey = u2fChallenge.get('payload');

      var registerResponse = this.requireWebAuthnSupport().then(function () {
        /* eslint-disable no-undef */
        return webauthnJSON.create({ publicKey: publicKey });
      }).then(function (u2f) {
        return { u2f: u2f, version: U2F_VERSION };
      })['catch'](function (error) {
        throw new Error(error);
      });

      return raceWithTimeout(registerResponse, REGISTER_TIMEOUT_MS, {
        errorCode: u2f.ErrorCodes.TIMEOUT
      });
    },

    sign: function sign(authU2f) {
      var publicKey = Object.assign({}, authU2f.payload);
      publicKey.devices = authU2f.devices;
      publicKey.userVerification = 'discouraged';

      var signResponse = this.requireWebAuthnSupport().then(function () {
        /* eslint-disable no-undef */
        return webauthnJSON.get({ publicKey: publicKey });
      }).then(function (r) {
        return r;
      })['catch'](function (error) {
        throw new Error(error);
      });

      return raceWithTimeout(signResponse, SIGN_TIMEOUT_MS, {
        errorCode: u2f.ErrorCodes.TIMEOUT
      });
    }
  });
});
/* global u2f */