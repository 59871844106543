define("diesel/components/container-cpu-metrics/component", ["exports", "ember", "diesel/mixins/components/container-metrics"], function (exports, _ember, _dieselMixinsComponentsContainerMetrics) {
  exports["default"] = _ember["default"].Component.extend(_dieselMixinsComponentsContainerMetrics["default"], {
    init: function init() {
      this._super.apply(this, arguments);
      this.set("uiState.showCpuLimit", false);
    },

    cpuLimit: _ember["default"].computed('cpuLimitDecimal', function () {
      return this.get('cpuLimitDecimal') * 100;
    }),

    axisLabel: "CPU usage",

    axisFormatter: function axisFormatter(v) {
      // Don't show negative values on the axis (they'd be the result of padding).
      if (v >= 0) {
        return v + "%";
      }
    },

    metric: 'cpu_pct',

    gridLines: _ember["default"].computed("cpuLimit", function () {
      var cpuLimit = this.get("cpuLimit"),
          gridLines = [];

      gridLines.push({
        value: cpuLimit,
        text: "CPU limit (" + cpuLimit + "%)",
        "class": this.get('limitEnforced') ? 'enforced' : 'not-enforced'
      });

      return gridLines;
    }),

    axisMax: _ember["default"].computed("cpuLimit", "uiState.showCpuLimit", function () {
      var showCpuLimit = this.get("uiState.showCpuLimit"),
          cpuLimit = this.get("cpuLimit");
      return showCpuLimit ? cpuLimit : null;
    }),

    limitEnforced: _ember["default"].computed.alias('stack.cpuLimits')
  });
});