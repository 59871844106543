define("diesel/settings/requires-elevation/route", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Route.extend({
    elevation: _ember["default"].inject.service(),

    beforeModel: function beforeModel(transition) {
      var elevationService = this.get("elevation");
      if (!elevationService.isElevated()) {
        this.transitionTo("elevate", { queryParams: { redirectTo: transition.targetName } });
      }
    }
  });
});