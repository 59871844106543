define('diesel/components/modal-security-review/component', ['exports', 'ember', 'diesel/helpers/format-date', 'diesel/mixins/components/resizable-modal', 'diesel/mixins/components/modal-base'], function (exports, _ember, _dieselHelpersFormatDate, _dieselMixinsComponentsResizableModal, _dieselMixinsComponentsModalBase) {
  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsResizableModal['default'], _dieselMixinsComponentsModalBase['default'], {
    securityReviewModal: null, // set by route to open this modal

    finalizing: false,
    fixedWindowSelector: '.editable-security-review-systems',
    securityReview: _ember['default'].computed.reads('securityReviewModal.securityReview'),
    authorization: _ember['default'].inject.service(),
    flashMessages: _ember['default'].inject.service(),
    currentUserName: _ember['default'].computed.reads('authorization.currentUser.name'),
    title: _ember['default'].computed('securityReview.dueAt', function () {
      var dueAt = this.get('securityReview.dueAt');
      return 'Security Review Due ' + (0, _dieselHelpersFormatDate.formatDate)(dueAt);
    }),

    description: _ember['default'].computed('securityReview.dueAt', function () {
      var assigned = this.get('complianceStatus.organizationProfile.securityOfficerName');
      return 'Assigned to ' + assigned;
    }),

    actions: {
      saveReview: function saveReview(securityReview) {
        var _this = this;

        securityReview.save().then(function () {
          _this.get('flashMessages').success('Progress saved.');
        });
      },

      beginFinalize: function beginFinalize() {
        this.set('finalizing', true);
      },

      cancelFinalize: function cancelFinalize(securityReview) {
        securityReview.set('incompleteFinalizeReason', '');
        this.set('finalizing', false);
      },

      finishFinalize: function finishFinalize(securityReview) {
        var _this2 = this;

        if (!securityReview.get('incompleteFinalizeReason')) {
          this.get('flashMessages').danger('You can\'t finalize an incomplete security review without adding a reason.');
        }

        securityReview.save().then(function () {
          _this2.sendAction('onReviewComplete');
          securityReview.addToCompletedReviews();
        })['catch'](function () {
          _this2.get('flashMessages').danger('There was an error saving the security review');
        });
      },

      completeReview: function completeReview(securityReview) {
        var _this3 = this;

        securityReview.save().then(function () {
          _this3.sendAction('onReviewComplete');
          securityReview.addToCompletedReviews();
        })['catch'](function () {
          _this3.get('flashMessages').danger('There was an error saving the security review');
        });
      },

      beforeDismiss: function beforeDismiss() {
        this.get('securityReview').rollbackAttributes();
      }
    }
  });
});