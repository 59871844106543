define('diesel/helpers/array-concat', ['exports', 'ember'], function (exports, _ember) {
  exports.arrayConcat = arrayConcat;

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  function arrayConcat(array, item) {
    if (array !== null && array !== undefined && !Array.isArray(array)) {
      array = [array];
    }

    return (array || []).concat(item);
  }

  exports['default'] = _ember['default'].Helper.helper(function (params) {
    return arrayConcat.apply(undefined, _toConsumableArray(params));
  });
});