define('diesel/gridiron-setup/security-controls/route', ['exports', 'ember', 'diesel/mixins/routes/spd-route'], function (exports, _ember, _dieselMixinsRoutesSpdRoute) {
  exports['default'] = _ember['default'].Route.extend(_dieselMixinsRoutesSpdRoute['default'], {
    stepName: 'security-controls',
    model: function model() {
      var organizationProfile = this.modelFor('gridiron-setup');
      var draftRiskAssessment = organizationProfile.get('draftRiskAssessment');

      return _ember['default'].RSVP.hash({ organizationProfile: organizationProfile, draftRiskAssessment: draftRiskAssessment });
    },

    next: function next() {
      this.finish();
    },

    actions: {
      editComponent: function editComponent(component) {
        if (component.get('isEditable')) {
          this.controller.editComponent(component);
        }
      }
    }
  });
});