define("diesel/password/update/controller", ["exports", "ember", "ember-validations"], function (exports, _ember, _emberValidations) {
  exports["default"] = _ember["default"].Controller.extend(_emberValidations["default"], {
    hasSubmitted: false,

    validations: {
      'model.password': {
        presence: true,
        confirmation: { message: 'does not match password' },
        'password-complexity': true
      }
    },

    actions: {

      save: function save(model) {
        var _this = this;

        var hasSubmitted = this.get('hasSubmitted');
        if (!hasSubmitted) {
          this.set('hasSubmitted', true);
        }
        this.validate().then(function () {
          _this.get('target').send('save', model);
        })["catch"](function () {
          // Silence the validation exception
        });
      }

    }

  });
});