define('diesel/components/backup-retention-policy-panel/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    backupRetentionPolicy: null,

    actions: {
      editPolicy: function editPolicy(backupRetentionPolicy) {
        this.sendAction('onEditBackupRetentionPolicy', backupRetentionPolicy);
      }
    }
  });
});