define('diesel/components/account-metadata/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    stackMetadata: false,
    stackLink: true,

    maxVisibleDomainNames: 1,

    displayVhostNames: _ember['default'].computed('model.vhostNames', function () {
      return this.model.get('vhostNames').join(', ');
    }),

    showVhostTooltip: _ember['default'].computed('model.vhostNames', function () {
      return this.model.get('vhostNames.length') > this.get('maxVisibleDomainNames');
    }),

    vhostRemaining: _ember['default'].computed('model.vhostNames', function () {
      return this.model.get('vhostNames.length') - this.get('maxVisibleDomainNames');
    }),

    vhostNamesSnippet: _ember['default'].computed('model.vhostNames', function () {
      var names = this.model.get('vhostNames');
      return names.slice(0, this.get('maxVisibleDomainNames')).join(', ');
    }),

    vhostNamesTooltip: _ember['default'].computed('model.vhostNames', function () {
      var names = this.model.get('vhostNames');
      return names.slice(this.get('maxVisibleDomainNames')).join(', ');
    }),

    displayLogDrainNames: _ember['default'].computed('model.logDrainNames', function () {
      return this.model.get('logDrainNames').join(', ');
    }),

    hasLogDrains: _ember['default'].computed.notEmpty("model.logDrains")
  });
});