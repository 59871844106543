define('diesel/components/modal-edit-system-component/component', ['exports', 'ember', 'diesel/models/component', 'diesel/mixins/components/asset-edit', 'diesel/mixins/components/modal-base'], function (exports, _ember, _dieselModelsComponent, _dieselMixinsComponentsAssetEdit, _dieselMixinsComponentsModalBase) {
  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsAssetEdit['default'], _dieselMixinsComponentsModalBase['default'], {
    // Inputs
    modalComponentEditorProperties: null,
    allowDependencyChanges: _ember['default'].computed('component.isNew', 'componentType', function () {
      // If the component is not new, we don't allow dependency changes.
      return this.get('modalComponentEditorProperties.allowModifyDependencies') && !this.get('isNetwork');
    }),
    componentType: _ember['default'].computed.alias('modalComponentEditorProperties.componentType'),
    users: _ember['default'].computed('modalComponentEditorProperties.users', function () {
      return this.get('modalComponentEditorProperties.users') || [];
    }),
    roles: _ember['default'].computed('modalComponentEditorProperties.roles', function () {
      return this.get('modalComponentEditorProperties.roles') || [];
    }),
    systemTemplates: _ember['default'].computed.reads('modalComponentEditorProperties.systemTemplates', function () {
      return this.get('modalComponentEditorProperties.systemTemplates') || [];
    }),
    isDatabase: _ember['default'].computed.equal('componentType', 'database'),
    isNetwork: _ember['default'].computed.equal('componentType', 'network'),
    isApp: _ember['default'].computed('componentType', function () {
      var componentType = this.get('componentType');
      if (!componentType) {
        return;
      }
      var typeConfig = _dieselModelsComponent.COMPONENT_TYPES.find(function (config) {
        return config.handle === componentType;
      });
      return typeConfig.app;
    }),

    asset: _ember['default'].computed.alias('component'),

    developedByTypes: _dieselModelsComponent.DEVELOPER_TYPES,

    errors: null,
    newPrimarySystem: null,
    newSecondarySystems: null,
    newComponentDependencies: null,

    types: _ember['default'].computed('component.appUrl', function () {
      // Only show types that are `app:true`, e.g. exclude network and database
      // component types
      var types = _dieselModelsComponent.COMPONENT_TYPES.filterBy('app', true);

      // If has appUrl, it's enclave app and we should only show app types
      // that work on enclave.  e.g. we don't show iOS app type
      if (!this.get('component.appUrl')) {
        return types;
      }

      return types.filter(function (at) {
        return at.enclave;
      });
    }),

    initialPrimarySystem: _ember['default'].computed('component.primarySystem.templateHandle', function () {
      return this.get('systemTemplates').findBy('handle', this.get('component.primarySystem.templateHandle'));
    }),

    primarySystems: _ember['default'].computed('component.type', function () {
      var type = this.get('component.type');
      if (this.get('component.appUrl')) {
        // Deploy app, no need to show other systems
        return this.get('systemTemplates').filterBy('handle', 'enclave_app');
      }

      return this.get('systemTemplates').filterBy('isPrimary', true).filter(function (systemTemplate) {
        return systemTemplate.supportsAppType(type);
      });
    }),

    secondarySystems: _ember['default'].computed.filterBy('systemTemplates', 'isStorage'),
    loggingSystems: _ember['default'].computed.filterBy('systemTemplates', 'isLogging'),

    systems: _ember['default'].computed.alias('modalComponentEditorProperties.existingSystems'),
    component: _ember['default'].computed.alias('modalComponentEditorProperties.component'),
    isNew: _ember['default'].computed.bool('component.isNew'),

    typeLabel: _ember['default'].computed('componentType', function () {
      var componentType = this.get('componentType');
      if (!componentType) {
        return;
      }
      var typeConfig = _dieselModelsComponent.COMPONENT_TYPES.find(function (config) {
        return config.handle === componentType;
      });
      return typeConfig.name;
    }),

    title: _ember['default'].computed('component.handle', function () {
      if (this.get('component.isNew')) {
        return 'Add New Asset';
      }

      return this.get('component.handle');
    }),

    description: _ember['default'].computed.alias('typeLabel'),

    components: _ember['default'].computed.filterBy('modalComponentEditorProperties.components', 'isNew', false),
    otherAppComponents: _ember['default'].computed('components.[]', function () {
      var currentComponentId = this.get('component.id');
      return this.get('components').rejectBy('id', currentComponentId).filterBy('isApp', true);
    }),

    databaseComponents: _ember['default'].computed.filterBy('components', 'type', 'database'),
    networkComponents: _ember['default'].computed.filterBy('components', 'type', 'network'),

    selectedSystems: _ember['default'].computed('component.systemDependencies.[]', function () {
      var _this = this;

      return this.get('component.systemDependencies').mapBy('system.templateHandle').map(function (templateHandle) {
        return _this.get('systemTemplates').findBy('handle', templateHandle);
      });
    }),

    thirdPartyServices: _ember['default'].computed('systems.[]', function () {
      var inUseThirdPartyServiceHandles = this.get('systems').filterBy('isWebService').mapBy('templateHandle');
      return this.get('systemTemplates').filter(function (st) {
        return inUseThirdPartyServiceHandles.includes(st.get('handle'));
      });
    }),

    sortWebServicesBy: ['name:asc'],
    sortedThirdPartyServices: _ember['default'].computed.sort('thirdPartyServices', 'sortWebServicesBy'),

    // Do this so select multiple is operating against a clone of dependencies
    selectedDependencies: _ember['default'].computed('component.componentDependencies.[]', function () {
      // Omit services dependencies, those are managed separately in `selectedNetworkDependency`
      return this.get('component.componentDependencies').map(function (b) {
        return b.get('dependsOnComponent');
      });
    }),

    selectedNetworkDependency: _ember['default'].computed('component.componentDependencies.[]', function () {
      return this.get('component.componentDependencies').findBy('type', 'network');
    }),

    actions: {
      clearMessages: function clearMessages() {
        this.set('errors', null);
      },

      removeComponent: function removeComponent(component) {
        var _this2 = this;

        var handle = component.get('handle');
        var message = 'Are you sure you want to remove ' + handle + ' from Gridiron?';

        if (!window.confirm(message)) {
          return;
        }

        return component.destroyRecord().then(function () {
          return _this2.sendAction('onComponentRemove', handle);
        });
      },

      beforeDismiss: function beforeDismiss() {
        this.get('component').rollbackAttributes();
        this.setProperties({
          newPrimarySystem: null,
          newSecondarySystems: null,
          newComponentDependencies: null,
          errors: null
        });
      },

      saveComponent: function saveComponent() {
        var _this3 = this;

        var _getProperties = this.getProperties('newPrimarySystem', 'newSecondarySystems', 'newComponentDependencies', 'component');

        var newPrimarySystem = _getProperties.newPrimarySystem;
        var newSecondarySystems = _getProperties.newSecondarySystems;
        var newComponentDependencies = _getProperties.newComponentDependencies;
        var component = _getProperties.component;

        var organizationProfile = this.get('modalComponentEditorProperties.organizationProfile');
        if (!component.get('hasOwner')) {
          this.set('errors', 'Asset owner is required.');
          this.scrollToTop();
          return;
        }

        if (!_ember['default'].$.trim(component.get('handle'))) {
          this.set('errors', 'Handle is required.');
          this.scrollToTop();
          return;
        }

        this._previousTab = this.get('currentTab');
        this.set('isSaving', true);
        this.set('currentTab', 'saving');

        return component.updateRelationshipsAndSave(newPrimarySystem, newSecondarySystems, newComponentDependencies).then(function () {
          organizationProfile.observeDocumentBuilds();
          _this3.set('currentTab', 'basic');
          _this3.sendAction('dismissModal');
          _this3.sendAction('onComponentUpdate', component);
        })['catch'](function (e) {
          var message = e.message || 'Something went wrong!';
          _this3.set('errors', message);
          _this3.set('currentTab', _this3._previousTab);
        })['finally'](function () {
          _this3.set('isSaving', false);
        });
      },

      updateAppType: function updateAppType(type) {
        var component = this.get('component');
        var currentPrimarySystemTemplateAppTypes = this.get('initialPrimarySystem.appTypes') || [];

        component.set('type', type);

        if (!currentPrimarySystemTemplateAppTypes.includes(type)) {
          component.set('primarySystem', null);
        }
      },

      updatePrimarySystem: function updatePrimarySystem(systemTemplate) {
        this.set('newPrimarySystem', systemTemplate);
      },

      updateSecondarySystems: function updateSecondarySystems(selectedSystems) {
        this.set('newSecondarySystems', selectedSystems);
      },

      updateDependsOnComponents: function updateDependsOnComponents(components) {
        this.set('newComponentDependencies', components);
      }
    }
  });
});