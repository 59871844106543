define('diesel/utils/belongs-to-record-pool-wrapper', ['exports', 'ember', 'diesel/utils/belongs-to-record-pool'], function (exports, _ember, _dieselUtilsBelongsToRecordPool) {

  var BelongsToRecordPoolWrapper = function BelongsToRecordPoolWrapper(resources, recordType) {
    var _this = this;

    this.recordType = recordType;
    var pools = resources.map(function (resource) {
      return new _dieselUtilsBelongsToRecordPool['default'](resource, _this.recordType);
    });
    this._poolState = pools.map(function (pool) {
      return pool.take();
    });
  };

  BelongsToRecordPoolWrapper.prototype.fetch = function (count) {
    var _this2 = this;

    return new _ember['default'].RSVP.Promise(function (resolve, reject) {
      var records = [];

      var consumePools = function consumePools(promises) {
        _ember['default'].RSVP.allSettled(promises).then(function (poolResponses) {
          // First, remove all poolExhausted rejected promises.
          poolResponses = poolResponses.filter(function (res) {
            return !(res.state === 'rejected' && res.reason.poolExhausted);
          });

          // Now, did we hit any non-exhausted errors?
          var errors = poolResponses.filter(function (res) {
            return res.state === 'rejected';
          });

          if (errors.length > 0) {
            throw errors[0];
          }

          // If we don't have any responses left, just return what we have.
          if (poolResponses.length === 0) {
            return resolve({ records: records, exhausted: true });
          }

          poolResponses = poolResponses.map(function (res) {
            return res.value;
          });
          poolResponses = poolResponses.sortBy('sortPriority');

          records.push(poolResponses[0].record);
          poolResponses[0] = poolResponses[0].pool.take();

          _this2._poolState = poolResponses;

          if (records.length >= count) {
            return resolve({ records: records, exhausted: false });
          }

          // If we need more records, then let's go at it again.
          return consumePools(_this2._poolState);
        })['catch'](function (e) {
          return reject(e);
        });
      };
      consumePools(_this2._poolState);
    });
  };

  exports['default'] = BelongsToRecordPoolWrapper;
});