define('diesel/components/invoice-subtotals/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    sortBy: ['quantity:desc'],

    subtotals: null,
    goldenboyLib: null,
    isProjection: null,

    sortedSubtotals: _ember['default'].computed.sort('subtotals', 'sortBy'),

    filteredSortedSubtotals: _ember['default'].computed('sortedSubtotals.[]', function () {
      var goldenboyLib = this.get('goldenboyLib');

      return this.get('sortedSubtotals').filter(function (s) {
        return goldenboyLib.showUnit(_ember['default'].get(s, 'unit'));
      }).filter(function (s) {
        return _ember['default'].get(s, 'pretaxCashValue') !== 0;
      });
    })
  });
});