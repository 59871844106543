define('diesel/requires-authorization/index/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    redirect: function redirect() {
      var context = this.get('authorization.currentContext');

      if (context.get('organizationHasEnclaveProduct') && context.get('userHasEnclaveAccess')) {
        return this.transitionTo('enclave');
      }

      if (context.get('organizationHasGridironProduct')) {
        return this.transitionTo('gridiron');
      }

      this.transitionTo('enclave');
    }
  });
});