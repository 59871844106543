define('diesel/mixins/routes/controls-route-mixin', ['exports', 'ember', 'diesel/utils/aggregate-customer-controls', 'diesel/mixins/controllers/filterable-controls'], function (exports, _ember, _dieselUtilsAggregateCustomerControls, _dieselMixinsControllersFilterableControls) {
  var MAX_ACCOUNT_SELECTION = 10;

  exports.MAX_ACCOUNT_SELECTION = MAX_ACCOUNT_SELECTION;
  exports['default'] = _ember['default'].Mixin.create({
    queryParams: {
      scope: {
        refreshModel: true
      }
    },

    model: function model(params) {
      var _get$getProperties = this.get('authorization.currentContext').getProperties('accountsWithVisibility', 'developmentAccountsWithVisibility', 'productionAccountsWithVisibility', 'organization', 'organizationRequiresDedicatedStack');

      var accountsWithVisibility = _get$getProperties.accountsWithVisibility;
      var developmentAccountsWithVisibility = _get$getProperties.developmentAccountsWithVisibility;
      var productionAccountsWithVisibility = _get$getProperties.productionAccountsWithVisibility;
      var organization = _get$getProperties.organization;
      var organizationRequiresDedicatedStack = _get$getProperties.organizationRequiresDedicatedStack;

      if (accountsWithVisibility.get('length') === 0) {
        return this.transitionTo('controls.no-access');
      }

      var defaultScope = organizationRequiresDedicatedStack ? developmentAccountsWithVisibility : productionAccountsWithVisibility;
      var scopeArray = params.scope ? params.scope.split(',') : defaultScope.mapBy('id');
      var accountsInScope = accountsWithVisibility.filter(function (a) {
        return scopeArray.includes(a.get('id'));
      });
      scopeArray = scopeArray.slice(0, MAX_ACCOUNT_SELECTION);

      var scope = scopeArray.join(',');
      var isDevelopmentView = organizationRequiresDedicatedStack || accountsInScope.get('firstObject.type') === 'development';

      var query = {
        per_page: 500,
        organization_id: organization.get("id"),
        account_ids: accountsInScope.mapBy('id')
      };

      var reportScope = accountsInScope.mapBy('handle').join(', ');

      return this.store.findAll('global-control').then(function (globalControls) {
        var mainControlAggregator = (0, _dieselUtilsAggregateCustomerControls.createMainControlAggregateFromGlobalControls)(globalControls);
        return {
          mainControlAggregator: mainControlAggregator,
          query: query,
          isLoading: true,
          organization: organization,
          scope: scope,
          categories: globalControls.mapBy('area').uniq(),
          isAggregateScope: accountsInScope.get("length") > 1,
          requiresDedicatedStack: organizationRequiresDedicatedStack,
          isDevelopmentView: isDevelopmentView,
          accountsWithVisibility: accountsWithVisibility,
          developmentAccountsWithVisibility: developmentAccountsWithVisibility,
          productionAccountsWithVisibility: productionAccountsWithVisibility,
          accountsInScope: accountsInScope,
          reportScope: reportScope,
          sort: _dieselMixinsControllersFilterableControls.NAME_SORT[0]
        };
      });
    },

    setupController: function setupController(controller, routeState) {
      controller.setProperties(routeState);

      return this.store.query('customer-control', routeState.query).then(function (customerControls) {
        routeState.mainControlAggregator.ingestNewCustomerControlSet(customerControls);
        controller.set('isLoading', false);
        controller.set('sort', _dieselMixinsControllersFilterableControls.STATUS_SORT[0]);
      });
    }
  });
});