define("diesel/adapters/application", ["exports", "ember-data", "ember", "diesel/config/environment", "diesel/serializers/application"], function (exports, _emberData, _ember, _dieselConfigEnvironment, _dieselSerializersApplication) {
  exports.setAccessToken = setAccessToken;
  exports.getAccessToken = getAccessToken;

  var accessToken = undefined;

  function setAccessToken(token) {
    accessToken = token;
  }

  function getAccessToken() {
    return accessToken;
  }

  /**
   * Later versions of Ember-Data include a `requestData` parameter to handleResponse
   * that will have the status text, but we add it here for now so that it can
   * be shown at the error route
   */
  var HTTP_STATUS_NAMES = {
    200: 'Ok',
    201: 'Created',
    204: 'No Content',
    400: 'Bad Request',
    401: 'Unauthorized',
    403: 'Forbidden',
    404: 'Not Found',
    406: 'Conflict',
    422: 'Unprocessable Entity',
    500: 'Internal Server Error'
  };

  function httpStatusNameFor(status) {
    if (HTTP_STATUS_NAMES[status]) {
      return HTTP_STATUS_NAMES[status];
    } else if (status >= 200 && status < 300) {
      return HTTP_STATUS_NAMES[200];
    } else if (status >= 400 && status < 500) {
      return HTTP_STATUS_NAMES[400];
    } else if (status >= 500) {
      return HTTP_STATUS_NAMES[500];
    }
  }

  exports["default"] = _emberData["default"].RESTAdapter.extend({
    raven: _ember["default"].inject.service(),

    host: _dieselConfigEnvironment["default"].apiBaseUri,

    headers: _ember["default"].computed(function () {
      if (!getAccessToken()) {
        return {};
      }
      return {
        'Authorization': 'Bearer ' + getAccessToken()
      };
    }).volatile(),

    shouldReloadAll: function shouldReloadAll() {
      return true;
    },

    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    },

    handleResponse: function handleResponse(status, headers, payload) {
      var response = undefined;
      if (status === 200 || status === 201 || status === 204) {
        response = this._super.apply(this, arguments);
      } else if (status >= 400 && status < 500) {
        if (payload && payload.message) {
          response = new _emberData["default"].InvalidError([{
            source: { pointer: '/data/attributes/base' },
            detail: payload.message
          }]);
        }
      }

      if (!response) {
        response = this._super.apply(this, arguments);
      }

      if (status >= 400) {
        // Stash properties from the original request to accomodate that parts of the codebase
        // that inspect `e.status` in error handlers (usually to detect a 404).
        // In later versions of Ember Data
        // we will have TimeoutError, NotFoundError, etc, but until then there are
        // places where we check the error status
        if (payload && payload.message) {
          response.message = payload.message;
        }
        response._requestStatus = status;
        response.status = status; // ember-data-todo: handle not-founds better
        response._requestStatusText = httpStatusNameFor(status) || 'AJAX Adapter Error';
        response._requestError = payload && payload.error || 'unknown';
      }

      return response;
    },

    // Our current  version of ED defaults to loading belongsTo relationships
    // directly from the server.  This patch changes that behavior to first
    // see if the record is already loaded in store.  If so, that record is
    // resolved immediately and a request is never made.
    findBelongsTo: function findBelongsTo(store, snapshot, relationshipPath, metaForProperty) {
      var segments = relationshipPath.split('/');
      var id = segments[segments.length - 1];
      var modelName = metaForProperty.type;
      var payload = undefined;

      modelName = (0, _dieselSerializersApplication.modelNameFromPayloadKey)(modelName);
      payload = this._payloadFromCache(modelName, id, store);

      if (!metaForProperty.requireReload && payload) {
        return _ember["default"].RSVP.resolve(payload);
      } else {
        return this._super.apply(this, arguments);
      }
    },

    // Given a modelName, id, and store, check if model exists in store and
    // return its pre-normalized payload
    _payloadFromCache: function _payloadFromCache(modelName, id, store) {
      var record = undefined,
          payload = undefined;

      if (modelName && id && store.hasRecordForId(modelName, id)) {
        record = store.recordForId(modelName, id);
        payload = record.get('_data.rawPayload');
      }

      return payload;
    },

    ajax: function ajax(url, type, options) {
      var _this = this;

      return this._super(url, type, options)["catch"](function (e) {
        if (!(e.status && e.status >= 400 && e.status < 500)) {
          _this.get('raven').captureException(e);
        }
        throw e;
      });
    }
  });
});