define('diesel/policy-manuals/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    showSPDNotice: _ember['default'].computed.or('hasNoPolicyManuals', 'hasNotCompletedSetup'),

    hasNotCompletedSetup: _ember['default'].computed.equal('organizationProfile.hasCompletedSetup', false),
    persistedPolicyManuals: _ember['default'].computed.filterBy('policyManuals', 'isNew', false),

    hasNoPolicyManuals: _ember['default'].computed.equal('persistedPolicyManuals.length', 0),
    archivedPolicyManuals: _ember['default'].computed.filterBy('persistedPolicyManuals', 'status', 'archived'),

    hasArchivedPolicyManuals: _ember['default'].computed('archivedPolicyManuals.[]', 'v1ArchivedPolicyManuals.[]', function () {
      return this.get('archivedPolicyManuals.length') + this.get('v1ArchivedPolicyManuals.length') > 0;
    })
  });
});