define('diesel/models/system-config', ['exports', 'ember'], function (exports, _ember) {

  var System = _ember['default'].Object.extend({
    id: null,
    name: null,
    isComplete: _ember['default'].computed('items.@each.checked', function () {
      return this.get('items').isEvery('checked');
    })
  });

  var SystemConfig = _ember['default'].Object.extend({
    isComplete: _ember['default'].computed('systems.@each.isComplete', function () {
      return this.get('systems').isEvery('isComplete');
    }),

    systemSummary: _ember['default'].computed('systems.@each.name', function () {
      return this.get('systems').mapBy('name').join(', ');
    }),

    toJSON: function toJSON() {
      var getProperties = _ember['default'].getProperties;

      var systems = this.get('systems').map(function (system) {
        var _getProperties = getProperties(system, 'id', 'name', 'items');

        var id = _getProperties.id;
        var name = _getProperties.name;
        var items = _getProperties.items;

        items = items.map(function (item) {
          return getProperties(item, 'value', 'checked');
        });

        return { id: id, name: name, items: items };
      });

      return { systems: systems };
    }
  });

  SystemConfig.reopenClass({
    fromJSON: function fromJSON(json) {
      var systems = json.systems.map(function (system) {
        return System.create(system);
      });
      var config = SystemConfig.create({ systems: systems });
      return config;
    }
  });

  exports['default'] = SystemConfig;
});