define('diesel/enclave/route', ['exports', 'ember', 'diesel/config/environment'], function (exports, _ember, _dieselConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend({
    redirect: function redirect() {
      return this.handleOnlyInaccessibleOrganizations() || this.handleNoOrganizations() || this.handleNopaymentDetails() || this.handleComplianceOnlyUsers() || this.handleNoAccounts() || true;
    },

    handleOnlyInaccessibleOrganizations: function handleOnlyInaccessibleOrganizations() {
      if (this.get('authorization.hasNoOrganizations') && this.get('authorization.hasInaccessibleOrganizations')) {
        return this.transitionTo('sso');
      }
    },

    handleNoOrganizations: function handleNoOrganizations() {
      if (this.get('authorization.hasNoOrganizations')) {
        return this.transitionTo('no-organization');
      }
    },

    handleNopaymentDetails: function handleNopaymentDetails() {
      if (_dieselConfigEnvironment['default'].featureFlags.skipBilling) {
        return _ember['default'].RSVP.resolve();
      }

      // User's only organization is missing billing detail. get out of here
      var context = this.get('authorization.organizationContexts.firstObject');
      if (context.get('billingDetail.hasNoPaymentMethod')) {
        return this.transitionTo('welcome.plan', context.get('organization.id'));
      }
    },

    handleComplianceOnlyUsers: function handleComplianceOnlyUsers() {
      // If no organization context has any Deploy access, get out of here
      if (!this.get('authorization.hasAnyEnclaveAccess')) {
        // REVIEW:  Don't warn until we update to have a more intelligent default
        // route
        // let message = `You do not have access to view Deploy resources. If this
        //                is a mistake, please contact either your account owner or
        //                support@aptible.com`;

        // Ember.get(this, 'flashMessages').danger(message);
        var context = this.get('authorization.organizationContexts.firstObject');
        var organization = context.get('organization.id');

        if (!context.get('organizationHasGridironProduct')) {
          return this.transitionTo('no-permissions');
        }

        if (context.get('userIsGridironAdmin')) {
          return this.transitionTo('gridiron-admin', organization);
        }

        return this.transitionTo('gridiron-user', organization);
      }
    },

    handleNoAccounts: function handleNoAccounts() {
      // Incomplete organization: should be able to link to setup payment method
      // Complete organization, should be able to view compliance dashboard

      if (this.get('authorization.hasNoAccounts')) {
        return this.transitionTo('no-account');
      }
    }
  });
});