define('diesel/vulnerability/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    title: function title(tokens) {
      if (tokens.length === 0) {
        tokens.push(this.currentModel.vulnerability.get('title'));
      }

      tokens.push('Vulnerabilities');

      return tokens.join(' - ');
    },

    model: function model(params) {
      var _this = this;

      var riskAssessmentId = params.id.split('_')[0];
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        var findPromise = _this.store.find('riskAssessment', riskAssessmentId);

        findPromise.then(function (riskAssessment) {
          resolve({
            riskAssessment: riskAssessment,
            vulnerability: riskAssessment.get('vulnerabilities').findBy('id', params.id)
          });
        }, reject);
      });
    },

    setupController: function setupController(controller, model) {
      controller.set('riskAssessment', model.riskAssessment);
      controller.set('model', model.vulnerability);
    }
  });
});