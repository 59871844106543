define('diesel/components/user-training-item/component', ['exports', 'ember', 'diesel/models/assignment-state'], function (exports, _ember, _dieselModelsAssignmentState) {

  function percent(num, den) {
    if (num === 0 || den === 0) {
      return "0";
    }
    return (num * 100 / den).toFixed(2).toString();
  }

  exports['default'] = _ember['default'].Component.extend({
    assignments: _ember['default'].computed.reads('userTrainingStatus.courseAssignments'),
    completedCount: _ember['default'].computed('assignments.[]', function () {
      return this.get('assignments').filterBy('status', 'completed').length;
    }),

    expiredCount: _ember['default'].computed('assignments.[]', function () {
      return this.get('assignments').filterBy('status', 'expired').length;
    }),

    assignedCount: _ember['default'].computed('assignments.@each.status', function () {
      return this.get('assignments').reject(function (a) {
        return _dieselModelsAssignmentState.UNASSIGNED_STATES.includes(a.status);
      }).length;
    }),

    completeProgress: _ember['default'].computed('assignedCount', 'completedCount', function () {
      return percent(this.get('completedCount'), this.get('assignedCount'));
    }),

    expiredProgress: _ember['default'].computed('assignedCount', 'expiredCount', function () {
      return percent(this.get('expiredCount'), this.get('assignedCount'));
    })
  });
});