define('diesel/otp-reset-verify/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    hasSubmitted: false,

    actions: {

      save: function save(model) {
        var hasSubmitted = this.get('hasSubmitted');
        if (!hasSubmitted) {
          this.set('hasSubmitted', true);
        }
        this.get('target').send('save', model);
      }

    }

  });
});