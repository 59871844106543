define('diesel/gridiron-user/route', ['exports', 'ember', 'diesel/models/user-profile', 'diesel/utils/course-assignment-state'], function (exports, _ember, _dieselModelsUserProfile, _dieselUtilsCourseAssignmentState) {
  exports['default'] = _ember['default'].Route.extend({
    title: function title() {
      return 'My Gridiron Status';
    },

    model: function model() {
      var user = this.get('session.currentUser');
      var organizationProfile = this.get('complianceStatus.organizationProfile');
      return _dieselModelsUserProfile['default'].findOrCreate(user, organizationProfile, this.store).then(function (userProfile) {
        return _ember['default'].RSVP.hash({
          assignments: userProfile.get('assignments'),
          // We call assignment.get('course') for each of the user assignments.
          // Loading the courses here as a collection will save from N+1 requests
          courses: organizationProfile.get('courses')
        });
      }).then(function (_ref) {
        var assignments = _ref.assignments;

        // If any assignments belong to a disabled course, remove them from the list
        // Each assignment's course is preloaded via the courses#collection request
        // in previous promise
        var enabledAssignments = assignments.filter(function (a) {
          return a.get('course.isEnabled');
        });

        // Convert assignments into assignment-course-state objects which include
        // the given assignments progress state
        return enabledAssignments.map(function (assignment) {
          var _assignment$getProperties = assignment.getProperties('currentModule', 'course');

          var currentModule = _assignment$getProperties.currentModule;
          var course = _assignment$getProperties.course;

          if (!(0, _dieselUtilsCourseAssignmentState.courseHasModule)(course, currentModule)) {
            currentModule = course.get('defaultModule');
          }
          return (0, _dieselUtilsCourseAssignmentState['default'])({ module: currentModule, course: course, assignment: assignment });
        });
      });
    },

    setupController: function setupController(controller, courseAssignmentStates) {
      var approvalId = this.get('complianceStatus.currentApprovalId');
      var approvalPath = approvalId ? 'design/programs/' + approvalId + '/browse' : null;
      var showComplyGRCManual = this.get('authorization.currentContext.enabledFeatures.complyGrcProgram');
      var showComplianceOSManual = !showComplyGRCManual && approvalPath;
      var showClassicManual = !showComplyGRCManual && !showComplianceOSManual;

      var organizationProfile = this.get('complianceStatus.organizationProfile');
      var courseAssignmentsWithState = courseAssignmentStates.filter(function (cas) {
        return cas.assignment.get('isAssigned');
      });
      controller.setProperties({
        approvalId: approvalId, approvalPath: approvalPath, courseAssignmentsWithState: courseAssignmentsWithState, organizationProfile: organizationProfile,
        showComplyGRCManual: showComplyGRCManual, showComplianceOSManual: showComplianceOSManual, showClassicManual: showClassicManual
      });
    }
  });
});