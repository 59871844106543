define('diesel/serializers/vpn-tunnel', ['exports', 'diesel/serializers/application'], function (exports, _dieselSerializersApplication) {
  exports['default'] = _dieselSerializersApplication['default'].extend({
    keyForAttribute: function keyForAttribute(attr, method) {
      // phase1Foo -> phase_1_foo, as opposed to phase1_foo.
      var ret = this._super(attr, method);
      if (ret.startsWith('phase1') || ret.startsWith('phase2')) {
        ret = 'phase_' + ret.slice(5);
      }
      return ret;
    }
  });
});