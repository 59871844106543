define('diesel/models/risk-profile', ['exports', 'ember', 'ember-data', 'diesel/models/base'], function (exports, _ember, _emberData, _dieselModelsBase) {

  function sortBySeverity(threatEvents) {
    return threatEvents.sortBy('riskLevel', 'impact', 'relevance', 'overallLikelihood');
  }

  exports['default'] = _dieselModelsBase['default'].extend({
    vulnerabilities: _emberData['default'].hasMany('vulnerability', {
      async: false
    }),
    threatEvents: _emberData['default'].hasMany('threat-event', {
      async: false
    }),
    predisposingConditions: _emberData['default'].hasMany('predisposing-condition', {
      async: false
    }),
    threatSources: _emberData['default'].hasMany('threat-source', {
      async: false
    }),
    securityControls: _emberData['default'].hasMany('security-control', {
      async: false
    }),
    securityControlCategories: _emberData['default'].hasMany('security-control-category', {
      async: false
    }),
    mitigations: _emberData['default'].hasMany('mitigation', {
      async: false
    }),

    relevantThreatEvents: _ember['default'].computed.filterBy('threatEvents', 'relevant', true),
    maximumRisk: _ember['default'].computed('relevantThreatEvents.[]', function () {
      return sortBySeverity(this.get('relevantThreatEvents')).get('lastObject');
    }),

    maximumRisks: _ember['default'].computed('relevantThreatEvents.[]', function () {
      var max = this.get('maximumRisk.riskLevel');
      return sortBySeverity(this.get('relevantThreatEvents').filterBy('riskLevel', max));
    }),

    veryHighRisks: _ember['default'].computed.filterBy('relevantThreatEvents', 'riskLevel', 5),
    highRisks: _ember['default'].computed.filterBy('relevantThreatEvents', 'riskLevel', 4),
    mediumRisks: _ember['default'].computed.filterBy('relevantThreatEvents', 'riskLevel', 3),
    lowRisks: _ember['default'].computed.filterBy('relevantThreatEvents', 'riskLevel', 2),
    veryLowRisks: _ember['default'].computed.filterBy('relevantThreatEvents', 'riskLevel', 1),

    hasSecurityControls: _ember['default'].computed.gt('securityControls.length', 0),
    hasPredisposingConditions: _ember['default'].computed.gt('predisposingConditions.length', 0),
    hasAssessmentQuestionnaire: _ember['default'].computed.or('hasSecurityControls', 'hasPredisposingConditions'),

    relevantPredisposingConditions: _ember['default'].computed.filterBy('predisposingConditions', 'relevant', true),
    relevantSecurityControls: _ember['default'].computed.filterBy('securityControls', 'relevant', true),
    implementedSecurityControls: _ember['default'].computed.filterBy('securityControls', 'status', 'implemented'),
    unImplementedSecurityControls: _ember['default'].computed.filterBy('securityControls', 'status', 'unimplemented'),
    unconfiguredSecurityControls: _ember['default'].computed.filterBy('securityControls', 'status', 'unconfigured')
  });
});