define('diesel/components/plan-card/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    flashMessages: _ember['default'].inject.service(),
    modalDisplay: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    paymentMethod: null,
    activePlan: null,
    organization: null,
    plan: null,
    curPlan: null,
    isVisible: true,
    isSaving: false,
    onSaved: function onSaved() {},
    mutate: 'create',
    actions: {
      createPlan: function createPlan() {
        var _this = this;

        var plan = this.get('plan');
        this.set('isSaving', true);
        var store = this.get('store');
        var org = this.get('organization');
        var selectedPlan = store.peekRecord('plan', plan.id);
        var activePlan = store.createRecord('activePlan', {
          plan: selectedPlan,
          organization: org
        });
        activePlan.save().then(function (p) {
          _this.set('isSaving', false);
          _ember['default'].get(_this, 'flashMessages').success('Plan saved!');
          var onSaved = _this.get('onSaved');
          onSaved(p);
        })['catch'](function (e) {
          _this.set('isSaving', false);
          _ember['default'].get(_this, 'flashMessages').danger(e.message);
          return e;
        });
      },
      openPlanChangeModal: function openPlanChangeModal() {
        var plan = this.get('plan');
        var curPlan = this.get('curPlan');
        var activePlan = this.get('activePlan');
        var modalDisplay = this.get('modalDisplay');
        var paymentMethod = this.get('paymentMethod');

        modalDisplay.showModal('modal-plan-change', { plan: plan, activePlan: activePlan, curPlan: curPlan, paymentMethod: paymentMethod }, {
          actions: { onChangePlan: modalDisplay.modalAction(this, 'onChangePlan') }
        });
      },
      onChangePlan: function onChangePlan() {
        var _this2 = this;

        var plan = this.get('plan');
        var activePlan = this.get('activePlan');
        var org = this.get('organization');
        var store = this.get('store');
        var selectedPlan = store.peekRecord('plan', plan.id);

        this.set('isSaving', true);
        activePlan.set('plan', selectedPlan);
        activePlan.save().then(function () {
          _this2.set('isSaving', false);
          _ember['default'].get(_this2, 'flashMessages').success('Plan updated!');
          _this2.get('refresh')();
        })['catch'](function (e) {
          _this2.set('isSaving', false);
          if (e.message.includes("The store cannot assign a new id to a record that already has an id.")) {
            // TODO - this probably shouldn't happen
            _this2.get('store').query('activePlan', {
              organization_id: org.id
            });
            _ember['default'].get(_this2, 'flashMessages').success('Plan updated!');
            _this2.get('refresh')();
            return;
          }
          _ember['default'].get(_this2, 'flashMessages').danger(e.message);
          return e;
        });
      }
    }
  });
});