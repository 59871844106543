define('diesel/mixins/routes/spd-route', ['exports', 'ember', 'diesel/models/organization-profile'], function (exports, _ember, _dieselModelsOrganizationProfile) {
  exports['default'] = _ember['default'].Mixin.create({
    stepName: _ember['default'].computed('routeName', function () {
      return this.get('routeName').split('.').get('lastObject');
    }),

    beforeModel: function beforeModel() {
      var profile = this.modelFor('gridiron-setup');
      var currentStep = profile.get('currentStep') || _dieselModelsOrganizationProfile.FIRST_STEP;

      if (!profile.isReadyForStep(this.get('stepName'))) {
        return this.transitionTo('gridiron-setup.' + currentStep);
      }
    },

    afterModel: function afterModel(model) {
      if (model.schemaDocument && model.attestation) {
        model.schemaDocument.load(model.attestation.get('document'));
      }

      this.modelFor('gridiron-setup').set('loading', false);
    },

    next: function next() {
      var _this = this;

      var profile = this.modelFor('gridiron-setup');

      profile.next(this.get('stepName'));
      profile.save()['catch'](function (e) {
        var message = e.message || 'An error occurred';
        _ember['default'].get(_this, 'flashMessages').danger('Save Failed! ' + message);
      });

      this.transitionTo('gridiron-setup.' + profile.get('currentStep'));
    },

    previous: function previous() {
      var _this2 = this;

      var profile = this.modelFor('gridiron-setup');

      profile.previous(this.get('stepName'));
      profile.save()['catch'](function (e) {
        var message = e.message || 'An error occurred';
        _ember['default'].get(_this2, 'flashMessages').danger('Save Failed! ' + message);
      });

      this.transitionTo('gridiron-setup.' + profile.get('currentStep'));
    },

    finish: function finish() {
      var _this3 = this;

      var profile = this.modelFor('gridiron-setup');
      profile.setProperties({ hasCompletedSetup: true, currentStep: 'finish' });

      profile.save().then(function () {
        profile.observeDocumentBuilds();
      })['catch'](function (e) {
        var message = e.message || 'An error occurred';
        _ember['default'].get(_this3, 'flashMessages').danger('Save Failed! ' + message);
      });

      this.transitionTo('gridiron-setup.finish');
    },

    save: function save() {
      var _this4 = this;

      var _currentModel = this.currentModel;
      var schemaDocument = _currentModel.schemaDocument;
      var attestation = _currentModel.attestation;

      attestation.set('document', schemaDocument.dump({ excludeInvalid: true }));
      attestation.set('user', this.session.get('currentUser'));
      return attestation.save().then(function () {
        var message = 'Progress saved.';
        _ember['default'].get(_this4, 'flashMessages').success(message);
        return { success: true, error: null };
      }, function (e) {
        var message = e.message || 'An error occurred';
        _ember['default'].get(_this4, 'flashMessages').danger('Save Failed! ' + message);
        return { success: false, error: e };
      });
    },

    actions: {
      onSave: function onSave() {
        this.save();
      },

      onPrevious: function onPrevious() {
        this.previous();
      },

      onNext: function onNext() {
        this.next();
      }
    }
  });
});