define('diesel/mixins/components/column-config', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    columns: [],
    columnPresets: {},
    columnConfig: _ember['default'].computed('columns', function () {
      var enabledColumns = this.get('columns');
      var columnPresets = this.get('columnPresets');
      var config = {};

      for (var column in columnPresets) {
        config[column] = enabledColumns.indexOf(column) > -1;
      }

      return config;
    }),

    columnHeaders: _ember['default'].computed('columns', function () {
      var columnPresets = this.get('columnPresets');
      return this.get('columns').map(function (c) {
        return columnPresets[c];
      });
    })
  });
});