define('diesel/components/configure-vhost-ip-filtering/component', ['exports', 'ember'], function (exports, _ember) {

  var IP_WHITELIST_MAX_SIZE = 50;

  function tryParseCidr(c) {
    try {
      return ipaddr.IPv4.parseCIDR(c);
    } catch (e) {
      if (!e.message.match(/ipaddr/)) {
        throw e;
      }
    }

    try {
      return [ipaddr.IPv4.parse(c), 32];
    } catch (e) {
      if (!e.message.match(/ipaddr/)) {
        throw e;
      }
    }

    return null;
  }

  exports['default'] = _ember['default'].Component.extend({
    // Inputs
    vhost: null,
    account: null,

    // Optional Inputs
    rawIpWhitelist: null,
    defaultEnabled: false,

    // Optional Outputs
    valid: null,

    // Internal state
    useWhitelist: false,
    notUseWhitelist: _ember['default'].computed.not('useWhitelist'),

    syntaxErrors: [],
    syntaxValid: _ember['default'].computed.empty('syntaxErrors'),
    syntaxInvalid: _ember['default'].computed.not('syntaxValid'),

    count: 0,

    maxValid: _ember['default'].computed.lte('count', IP_WHITELIST_MAX_SIZE),
    maxInvalid: _ember['default'].computed.not('maxValid'),
    maxAllowed: IP_WHITELIST_MAX_SIZE,

    minValid: _ember['default'].computed.gt('count', 0),
    minInvalid: _ember['default'].computed.not('minValid'),

    stackDoesNotSupportIpFiltering: _ember['default'].computed.not('account.isV2'),

    whitelistIsValid: _ember['default'].computed.and('syntaxValid', 'minValid', 'maxValid'),
    componentIsValid: _ember['default'].computed.or('stackDoesNotSupportIpFiltering', 'notUseWhitelist', 'whitelistIsValid'),

    pushedInitialValidState: false,

    errorClass: _ember['default'].computed('componentIsValid', function () {
      return this.get('componentIsValid') ? '' : 'has-error';
    }),

    pushValidState: (function () {
      var _this = this;

      // This needs to happen as an observer and not a computed so that we can
      // push it back to whoever includes this component.
      // TODO-double-modify
      _ember['default'].run.schedule('afterRender', function () {
        _this.sendAction('update', _this.get('componentIsValid'));
      });
    }).observes('componentIsValid'),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      // If rawIpWhitelist was not passed, but the VHOST has a rawIpWhitelist,
      // then we load that one here. This ensures that callers do not need to
      // pass the VHOST's current ipWhitelist via rawIpWhitelist.
      if (!this.get('rawIpWhitelist')) {
        var vhostRawIpWhitelist = (this.get('vhost.ipWhitelist') || []).join('\n');
        this.set('rawIpWhitelist', vhostRawIpWhitelist);
      }
    },

    listWasUpdated: (function () {
      var _this2 = this;

      var list = this.get('rawIpWhitelist') || '';
      var candidates = list.split(/\s+/).filter(function (c) {
        return !!c;
      });

      var cidrs = [];

      var errors = candidates.filter(function (c) {
        var r = tryParseCidr(c);

        if (r === null) {
          return true;
        }

        cidrs.push(r[0].toString() + '/' + r[1]);
        return false;
      });

      if (this.get('defaultEnabled') || candidates.length > 0) {
        // This is here for initial render: if we're passed in a whitelist, then
        // we should allow the user to edit it.
        this.set('useWhitelist', true);
      }

      this.set('count', cidrs.length);
      this.set('syntaxErrors', errors);

      // TODO-double-modify
      _ember['default'].run.schedule('afterRender', function () {
        return _this2.set('vhost.ipWhitelist', cidrs);
      });
    }).observes('rawIpWhitelist').on('init'),

    didInsertElement: function didInsertElement() {
      // Even if we run listWasUpdated during init, that doesn't actually trigger
      // pushValidState, so controllers that bind to our valid property will not
      // receive an update. This fixes that.
      this.pushValidState();
    },

    actions: {
      setUseWhitelist: function setUseWhitelist(useWhitelist) {
        this.set('useWhitelist', useWhitelist);

        if (!useWhitelist) {
          this.set('rawIpWhitelist', '');
          this.set('vhost.ipWhitelist', []);
        }
      }
    }
  });
});
/* global ipaddr */