define('diesel/instance-initializers/aptible-status', ['exports', 'diesel/config/environment'], function (exports, _dieselConfigEnvironment) {
  exports.initialize = initialize;

  function initialize(appInstance) {
    if (_dieselConfigEnvironment['default'].environment !== 'test') {
      // Kick off the status page refresh loop (but not in test, otherwise the
      // tests will always have a live promise that prevents from exiting).
      appInstance.lookup('service:aptible-status').refreshStatusAndReschedule();
    }
  }

  exports['default'] = {
    name: 'aptible-status',
    initialize: initialize
  };
});