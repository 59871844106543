define('diesel/components/billing-rate/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    unit: null,
    quantity: null,
    interval: null,
    goldenboyLib: null,

    formattedRate: _ember['default'].computed('unit', 'quantity', 'interval', function () {
      var _getProperties = this.getProperties('unit', 'quantity', 'interval');

      var unit = _getProperties.unit;
      var quantity = _getProperties.quantity;
      var interval = _getProperties.interval;

      return this.get('goldenboyLib').formatRate(unit, quantity, interval);
    })
  });
});