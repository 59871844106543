define('diesel/components/aptible-pagination/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    currentPage: 1,
    totalCount: 0,
    perPage: 0,

    hasPrev: _ember['default'].computed('currentPage', function () {
      return this.get('currentPage') > 1;
    }),

    hasNext: _ember['default'].computed('currentPage', 'totalCount', 'perPage', function () {
      var currentPage = this.get('currentPage'),
          totalCount = this.get('totalCount'),
          perPage = this.get('perPage');

      return totalCount / perPage > currentPage;
    }),

    actions: {
      nextPage: function nextPage(currentPage) {
        this.sendAction('nextPage', currentPage);
      },
      prevPage: function prevPage(currentPage) {
        this.sendAction('prevPage', currentPage);
      }
    }
  });
});