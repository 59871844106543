define('diesel/components/editable-security-review-system/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['editable-security-review-system'],
    systemConfig: null, // passed in

    actions: {
      toggleItem: function toggleItem(item) {
        var isChecked = _ember['default'].get(item, 'checked');
        _ember['default'].set(item, 'checked', !isChecked);
      }
    }
  });
});