define('diesel/app/deprovision/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    titleToken: function titleToken() {
      var app = this.modelFor('app');
      return 'Deprovision ' + app.get('handle');
    },

    actions: {
      deprovision: function deprovision() {
        var app = this.currentModel;
        var route = this;
        var controller = this.controller;
        var store = this.store;
        controller.set('error', null);

        app.get('account').then(function (account) {
          var op = store.createRecord('operation', {
            type: 'deprovision',
            app: app
          });
          return op.save().then(function () {
            return app.set('status', 'deprovisioning');
          }).then(function () {
            return route.transitionTo('apps', account);
          }, function (e) {
            return controller.set('error', e);
          });
        });
      }
    }

  });
});