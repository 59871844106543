define('diesel/components/c-control-customer-resources-panel/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    customerControl: null,
    actions: {
      onIgnore: function onIgnore(resource) {
        this.attrs.onIgnore(resource);
      },
      onRemoveIgnore: function onRemoveIgnore(resource) {
        this.attrs.onRemoveIgnore(resource);
      },
      onRunEvaluation: function onRunEvaluation(customerControl) {
        this.attrs.onRunEvaluation(customerControl);
        return false;
      }
    }
  });
});