define('diesel/reports/hipaa-security-report/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var organizationProfile = this.get('complianceStatus.organizationProfile');
      var reportHref = organizationProfile.get('links.hipaa_security_report');
      var accessToken = this.get("session.token.accessToken");

      if (!reportHref) {
        this.transitionTo('gridiron-admin');
      }

      return _ember['default'].RSVP.hash({
        reportData: _ember['default'].$.ajax(reportHref, { headers: { Authorization: 'Bearer ' + accessToken } }),
        currentRiskAssessment: organizationProfile.get('draftRiskAssessment')
      });
    },

    setupController: function setupController(controller, _ref) {
      var _this = this;

      var reportData = _ref.reportData;
      var currentRiskAssessment = _ref.currentRiskAssessment;

      var securityControlConfig = currentRiskAssessment.get('securityControls');
      var allSecurityControls = [];

      // Map security controls into actual security control instances from current
      // draft risk assessment
      reportData.report.sections.forEach(function (section) {
        section.requirements.forEach(function (requirement) {
          requirement.security_controls = requirement.security_controls.map(function (sc) {
            var instance = securityControlConfig.findBy('handle', sc.handle);
            // This will happen if the report includes a security control handle
            // that doesn't existing in the current draft risk assessment.
            // Fortunately, the report should include any data we need to render,
            // but the control will nto be Configurable from this screen.

            if (!instance) {
              _ember['default'].Logger.warn('No security control instance found for ' + sc.title + ', this is a problem!');
              instance = _this.store.createRecord('security-control', sc);
            }

            return instance;
          });

          allSecurityControls = allSecurityControls.concat(requirement.security_controls);
        });
      });

      controller.set('reportData', _ember['default'].Object.create(reportData.report));
      controller.set('allSecurityControls', allSecurityControls);
    },

    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);

      this.render('sidebars/engine-sidebar', {
        into: 'reports.hipaa-security-report',
        outlet: 'sidebar',
        controller: this.controllerFor('gridiron-organization')
      });
    },

    actions: {
      configureControl: function configureControl(securityControl) {
        if (securityControl.get('isEditable')) {
          this.controller.configureControl(securityControl);
        }
      }
    }
  });
});