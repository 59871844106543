define('diesel/components/asset-panel-systems/component', ['exports', 'ember', 'diesel/models/system'], function (exports, _ember, _dieselModelsSystem) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),

    isSaving: null,
    savingMessage: null,
    isEditing: null,
    newSystems: null,
    newSystemTemplates: null,
    error: null,
    isRequired: _ember['default'].computed.bool('asset.isApp'),
    persistedDependencies: _ember['default'].computed.filterBy('dependencies', 'isNew', false),
    description: _ember['default'].computed('type', function () {
      return _dieselModelsSystem.SYSTEM_TYPES.findBy('handle', this.get('type')).tooltip;
    }),
    systemOptions: _ember['default'].computed('type', 'systems.[]', 'newSystems.[]', function () {
      var type = this.get('type');
      var newSystemIds = this.get('newSystems').mapBy('id');

      return this.get('systems').filterBy('type', type).map(function (system) {
        return { system: system, isSelected: newSystemIds.includes(system.get('id')) };
      });
    }),

    systemTemplateOptions: _ember['default'].computed('type', 'systemTemplates.[]', 'newSystemTemplates.[]', function () {
      var _this = this;

      var type = this.get('type');
      var inUseSystemTemplateHandles = this.get('systems').mapBy('templateHandle');
      var newTemplateHandles = this.get('newSystemTemplates').mapBy('handle');

      return this.get('systemTemplates').filterBy('type', type).filter(function (st) {
        var inUse = inUseSystemTemplateHandles.includes(st.get('handle'));
        return !inUse && st.supportsAppType(_this.get('asset.type'));
      }).map(function (systemTemplate) {
        return { systemTemplate: systemTemplate, isSelected: newTemplateHandles.includes(systemTemplate.get('handle')) };
      });
    }),

    actions: {
      edit: function edit() {
        var existing = this.get('dependencies').mapBy('system');
        this.setProperties({ isEditing: true, newSystems: existing, newSystemTemplates: [], error: null });
      },

      cancel: function cancel() {
        if (this.get('isSaving')) {
          return;
        }
        this.setProperties({
          newSystems: null, newSystemTemplates: null, isEditing: null, error: null
        });
      },

      save: function save() {
        var _this2 = this;

        var _getProperties = this.getProperties('newSystemTemplates', 'newSystems', 'store', 'type', 'asset', 'organizationProfile');

        var newSystemTemplates = _getProperties.newSystemTemplates;
        var newSystems = _getProperties.newSystems;
        var store = _getProperties.store;
        var type = _getProperties.type;
        var asset = _getProperties.asset;
        var organizationProfile = _getProperties.organizationProfile;

        var saveAllSystemTemplatesAsSystems = _ember['default'].RSVP.resolve();
        this.setProperties({ isSaving: true, error: null });

        if (newSystemTemplates.length) {
          this.set('savingMessage', 'Adding new ' + type + ' systems to Gridiron...');
          saveAllSystemTemplatesAsSystems = _ember['default'].RSVP.all(this.get('newSystemTemplates').map(function (st) {
            return organizationProfile.findOrCreateSystem(st);
          }));
        }

        saveAllSystemTemplatesAsSystems.then(function (newlySavedSystemTemplates) {
          _this2.set('savingMessage', 'Updating ' + type + ' systems for ' + asset.get('assessmentName'));
          // For each selected system Template, create system and push onto new set
          // of systems to set as dependencies.
          var operations = [];
          var existingSystems = _this2.get('dependencies').mapBy('system');
          var existingSystemIds = existingSystems.mapBy('id');

          if (newlySavedSystemTemplates) {
            newSystems = newSystems.concat(newlySavedSystemTemplates);
          }

          // For each system...
          newSystems.forEach(function (system) {
            // Does it exist on current set of dependencies? If so do nothing
            // Is this a new dependency? If so, create dependency record
            if (!existingSystemIds.includes(system.get('id'))) {
              // This system ID isn't a current dependency. Add the dependency
              var dependency = store.createRecord('system-dependency', { component: asset, system: system });
              asset.get('systemDependencies').pushObject(dependency);
              _this2.get('dependencies').pushObject(dependency);
              operations.push(dependency.save());
            }
          });

          var newSystemIds = _this2.get('newSystems').mapBy('id');

          // For each existing system
          _this2.get('dependencies').forEach(function (dependency) {
            if (!dependency.get('id')) {
              return;
            }

            var system = dependency.get('system');
            // Does it exist in new set of systems? if so do nothing
            // Is existing dependency missing from new set of systems? if so delete dependency record;
            if (!newSystemIds.includes(system.get('id'))) {
              // This existing system isn't indlued in the new list of systems.
              // Delete the dependency
              asset.get('systemDependencies').removeObject(dependency);
              _this2.get('dependencies').removeObject(dependency);
              operations.push(dependency.destroyRecord());
            }
          });
          return _ember['default'].RSVP.all(operations);
        }).then(function () {
          _this2.set('savingMessage', 'Save successful!');
          _ember['default'].run.later(_this2, function () {
            _this2.setProperties({ isEditing: false, isSaving: false, savingMessage: false });
            _this2.get('onUpdate')();
          }, 1000);
        })['catch'](function (e) {
          var error = e.message || 'Error updating ' + asset.get('assessmentName') + '!';
          _this2.setProperties({ isEditing: true, isSaving: false, savingMessage: false, error: error });
        });
      },

      selectCurrentSystem: function selectCurrentSystem(systemOption) {
        var newSystems = this.get('newSystems');
        var system = systemOption.system;
        var isSelected = systemOption.isSelected;

        var action = isSelected ? 'removeObject' : 'pushObject';
        newSystems[action](system);
        this.set('newSystems', newSystems);
      },

      selectAvailableSystem: function selectAvailableSystem(systemTemplateOption) {
        var selectedAvailable = this.get('newSystemTemplates') || [];
        var systemTemplate = systemTemplateOption.systemTemplate;
        var isSelected = systemTemplateOption.isSelected;

        var action = isSelected ? 'removeObject' : 'pushObject';
        selectedAvailable[action](systemTemplate);
        this.set('newSystemTemplates', selectedAvailable);
      }
    }
  });
});