define('diesel/account/activate/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.modelFor('account');
    },

    redirect: function redirect() {
      var account = this.modelFor('account');

      if (account.get('activated')) {
        this.transitionTo('apps');
      }
    }
  });
});