define('diesel/app/services/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    titleToken: function titleToken() {
      var app = this.modelFor('app');

      return app.get('handle') + ' Services';
    },

    model: function model() {
      var app = this.modelFor('app');
      return app.get('services');
    },

    setupController: function setupController(controller, model) {
      var app = this.modelFor('app');

      controller.set('model', model);
      controller.set('app', app);
      controller.set('account', app.get('account'));
    }
  });
});