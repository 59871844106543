define('diesel/components/policy-editor/text-block/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    isEditing: false,
    textarea: null,

    // injected action
    onChange: function onChange() {},

    click: function click() {
      this.set('isEditing', true);
    },

    didRender: function didRender() {
      if (this.get('isEditing')) {
        this.textarea = this.$('textarea')[0];
        this.textarea.focus();
        this._updateTextareaHeight();
      }
    },

    focusOut: function focusOut() {
      this.set('isEditing', false);
    },

    change: function change(_ref) {
      var target = _ref.target;

      this.get('onChange')(target.value);
    },

    input: function input() {
      this._updateTextareaHeight();
    },

    _updateTextareaHeight: function _updateTextareaHeight() {
      var ta = this.textarea;
      ta.style.height = 'auto';
      ta.style.height = ta.scrollHeight + 'px';
    }
  });
});