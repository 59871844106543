define('diesel/organization/admin/billing/credits/route', ['exports', 'ember'], function (exports, _ember) {

  var BillingRoute = 'organization.admin.billing';

  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var billingDetail = this.modelFor(BillingRoute);
      return billingDetail.get('credits');
    }
  });
});