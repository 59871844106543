define('diesel/reports/training-history-report/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var organizationProfile = this.get('complianceStatus.organizationProfile');
      var reportHref = organizationProfile.get('links.training_history_report');
      var accessToken = this.get("session.token.accessToken");

      if (!reportHref) {
        this.transitionTo('gridiron-admin');
      }

      return _ember['default'].RSVP.hash({
        reportCSVHref: reportHref + '.csv',
        reportData: _ember['default'].$.ajax(reportHref, { headers: { Authorization: 'Bearer ' + accessToken } })
      });
    },

    setupController: function setupController(controller, model) {
      var documents = model.reportData.report.map(function (d) {
        if (d.completed_at) {
          d.completed_at = new Date(d.completed_at);
        }
        return d;
      });

      controller.setProperties(model);
      controller.set('documents', documents);
    },

    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);

      this.render('sidebars/engine-sidebar', {
        into: 'reports.training-history-report',
        outlet: 'sidebar',
        controller: this.controllerFor('gridiron-organization')
      });
    }
  });
});