define('diesel/components/credits-table/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    sortBy: ['createdAt:desc'],

    credits: null,

    openCredits: _ember['default'].computed('credits.[]', function () {
      var credits = this.get('credits');
      return credits.reject(function (c) {
        return c.belongsTo('creditClosure').id();
      });
    }),

    sortedCredits: _ember['default'].computed.sort('openCredits', 'sortBy')
  });
});