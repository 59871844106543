define('diesel/components/spd-nav/component', ['exports', 'ember'], function (exports, _ember) {
  exports.getStepIndex = getStepIndex;

  function getStepIndex(currentPath) {
    var stepConfig = arguments.length <= 1 || arguments[1] === undefined ? [] : arguments[1];

    var prefix = /^requires-authorization.gridiron\.gridiron\-organization\./ig;
    var i = -1;

    if (!currentPath) {
      _ember['default'].warn("`getStepIndex` called without a valid path");
      return null;
    }

    // Remove initial segment to prevent hits on organization SPD step erroneously
    currentPath = currentPath.replace(prefix, '');

    stepConfig.forEach(function (step, index) {
      if (currentPath.indexOf(step.key) >= 0) {
        i = index;
      }
    });

    return i;
  }

  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    onNext: null,
    showStepFeedback: true,
    routingService: _ember['default'].inject.service('routing'),

    currentStepIndex: _ember['default'].computed('routingService.currentPath', function () {
      return getStepIndex(this.get('routingService.currentPath'), this.get('stepConfig'));
    }),

    isOnAStep: _ember['default'].computed.gte('currentStepIndex', 0),
    showSave: _ember['default'].computed('onSave', function () {
      return !!this.get('onSave');
    }),

    showPrevious: _ember['default'].computed('onPrevious', function () {
      return !!this.get('onPrevious');
    }),

    isSaving: _ember['default'].computed('attestation.isSaving', 'profile.isSaving', function () {
      return this.get('attestation.isSaving') || this.get('profile.isSaving');
    }),

    steps: _ember['default'].computed('routingService.currentPath', function () {
      var currentStepIndex = this.get('currentStepIndex');
      var steps = this.get('stepConfig');

      steps.map(function (step, stepIndex) {
        _ember['default'].setProperties(step, {
          current: stepIndex === currentStepIndex,
          completed: stepIndex < currentStepIndex,
          path: 'gridiron-setup.' + step.key
        });

        return step;
      });

      return steps;
    }),

    next: _ember['default'].computed('currentStepIndex', 'steps', function () {
      var index = this.get('currentStepIndex');

      if (index === this.get('steps.length') - 1) {
        return false;
      }

      return this.get('steps')[index + 1];
    }),

    previous: _ember['default'].computed('currentStepIndex', 'steps', function () {
      var index = this.get('currentStepIndex');

      if (index === 0) {
        return false;
      }

      return this.get('steps')[index - 1];
    }),

    actions: {
      save: function save() {
        this.sendAction('onSave');
      },

      next: function next(nextPath) {
        this.sendAction('onNext', nextPath);
      },

      previous: function previous(previousPath) {
        this.sendAction('onPrevious', previousPath);
      },

      finish: function finish() {
        this.sendAction('onFinish');
      }
    }
  });
});