define('diesel/organization/admin/billing/route', ['exports', 'ember'], function (exports, _ember) {

  var OrganizationRoute = 'organization';

  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.modelFor(OrganizationRoute).get('billingDetail');
    },

    setupController: function setupController(controller, model) {
      var organization = model.get('organization'); // TODO: Remove
      var billingDetail = organization.get('billingDetail');
      var accounts = model.accounts; // TODO: Remove

      controller.setProperties({ organization: organization, billingDetail: billingDetail, accounts: accounts });
    }
  });
});