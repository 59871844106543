define('diesel/models/course', ['exports', 'ember', 'ember-data', 'diesel/utils/training-config', 'diesel/models/base'], function (exports, _ember, _emberData, _dieselUtilsTrainingConfig, _dieselModelsBase) {
  exports['default'] = _dieselModelsBase['default'].extend({
    protocol: _emberData['default'].belongsTo('protocol', { async: true }),
    assignments: _emberData['default'].hasMany('assignment', { async: true }),
    name: _emberData['default'].attr('string'),
    targetRole: _emberData['default'].attr('string'),
    handle: _emberData['default'].attr('string'),
    slug: _ember['default'].computed('handle', function () {
      return (this.get('handle') || '').dasherize();
    }),
    modules: _ember['default'].computed('slug', function () {
      var config = _dieselUtilsTrainingConfig.COURSE_CONTENT[this.get('slug')];
      return config && config.modules ? config.modules : [];
    }),
    moduleKeys: _ember['default'].computed.mapBy('modules', 'key'),
    isEnabled: _ember['default'].computed.gt('modules.length', 0),

    defaultModule: _ember['default'].computed('modules.[]', function () {
      return this.get('modules.firstObject.key');
    })
  });
});