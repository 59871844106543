define('diesel/components/document-build-status-indicator/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    // Accepts an organization profile.  This component oobservs the document build
    // status of the organization profile and shows itself when documents are
    // building
    organizationProfile: null,
    isBuildingDocuments: _ember['default'].computed.reads('organizationProfile.isBuildingDocuments'),
    hasCompletedSetup: _ember['default'].computed.reads('organizationProfile.hasCompletedSetup'),
    shouldShow: _ember['default'].computed.and('isBuildingDocuments', 'hasCompletedSetup')
  });
});