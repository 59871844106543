define('diesel/components/billing-iteration-period-summary/component', ['exports', 'ember', 'diesel/helpers/format-date'], function (exports, _ember, _dieselHelpersFormatDate) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    billingIteration: null,
    start: _ember['default'].computed('billingIteration.startsAt', function () {
      return (0, _dieselHelpersFormatDate.formatDate)(this.get('billingIteration.startsAt'), 3);
    }),
    end: _ember['default'].computed('billingIteration.endsAt', function () {
      return (0, _dieselHelpersFormatDate.formatDate)(this.get('billingIteration.endsAt'), 3);
    })
  });
});