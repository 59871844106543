define('diesel/components/select-container-size/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    sizes: null,
    selected: null,
    actions: {
      onSelect: function onSelect(value) {
        this.attrs.onSelect(value);
      }
    }
  });
});