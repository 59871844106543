define('diesel/adapters/user', ['exports', 'diesel/adapters/auth', 'diesel/utils/build-url-with-prefix-map'], function (exports, _dieselAdaptersAuth, _dieselUtilsBuildUrlWithPrefixMap) {
  exports['default'] = _dieselAdaptersAuth['default'].extend({
    buildURL: (0, _dieselUtilsBuildUrlWithPrefixMap['default'])({
      'organizations': { property: 'organizationId', only: ['deleteRecord'] }
    }),

    ajaxOptions: function ajaxOptions(url, type, options) {
      var hash = this._super(url, type, options);

      hash = hash || {};
      hash.xhrFields = hash.xhrFields || {};
      // Allows the cookie set by logging in to be persisted
      // (by the browser). That cookie provides credentials to
      // the dashboard app.
      hash.xhrFields.withCredentials = true;

      return hash;
    }

  });
});