define('diesel/mixins/routes/fetch-all-related-resources-mixin', ['exports', 'ember', 'diesel/utils/fetch-all-pages'], function (exports, _ember, _dieselUtilsFetchAllPages) {
  exports['default'] = _ember['default'].Mixin.create({
    _fetchAllRelatedResources: function _fetchAllRelatedResources(model) {
      var _this = this;

      return model.get('account').then(function (a) {
        _this._account = a;
        return (0, _dieselUtilsFetchAllPages['default'])(_this.store, _this._account, 'app');
      }).then(function (apps) {
        _this._apps = apps;
        return (0, _dieselUtilsFetchAllPages['default'])(_this.store, _this._account, 'database');
      }).then(function (dbs) {
        _this._dbs = dbs;
      });
    },

    _setupAllRelatedResources: function _setupAllRelatedResources(controller, model) {
      controller.setProperties({
        model: model, account: this._account, apps: this._apps, databases: this._dbs
      });
    },

    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);
      this.render('sidebars/account', {
        into: 'enclave-wrapper',
        outlet: 'sidebar'
      });
    }
  });
});