define('diesel/components/permission-checkbox/component', ['exports', 'ember'], function (exports, _ember) {
  /* Example usage:
   *
    {{permission-checkbox
      scope="manage"
      account=account
      role=role
      changeset=manageChangeset}}
  
  */

  exports['default'] = _ember['default'].Component.extend({
    tagName: 'input',
    classNames: ['permission-checkbox'],
    attributeBindings: ['checked', 'type', 'disabled'],
    type: 'checkbox',
    checked: _ember['default'].computed.or('isChecked', 'disabled'),

    isChecked: null,

    disabled: _ember['default'].computed.reads('role.privileged'),

    init: function init() {
      var _this = this;

      this._super();
      this._stagedObjectKey = {
        account: this.get('account'),
        scope: this.get('scope'),
        role: this.get('role')
      };

      this.updateIsChecked();
      this.get('changeset').subscribe(this._stagedObjectKey, function () {
        _this.updateIsChecked();
      });
    },

    updateIsChecked: function updateIsChecked() {
      var value = this.get('changeset').value(this._stagedObjectKey);
      this.set('isChecked', value.isEnabled);
    },

    click: function click() {
      var isChecked = this.$().is(':checked');
      var value = this.get('changeset').value(this._stagedObjectKey);
      this.get('changeset').setValue(this._stagedObjectKey, { permission: value.permission, isEnabled: !!isChecked });
    }
  });
});