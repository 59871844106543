define('diesel/incidents/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    sortBy: ['createdAt:desc'],
    sortClosedAtBy: ['closedAt:desc', 'createdAt:desc'],

    hasNoIncidents: _ember['default'].computed.equal('incidents.length', 0),
    hasOpenIncidents: _ember['default'].computed.gt('openIncidents.length', 0),
    hasClosedIncidents: _ember['default'].computed.gt('closedIncidents.length', 0),

    // Incidents by status
    openIncidents: _ember['default'].computed.filterBy('incidents', 'isOpen', true),
    closedIncidents: _ember['default'].computed.filterBy('incidents', 'isClosed', true),

    sortedOpenIncidents: _ember['default'].computed.sort('openIncidents', 'sortBy'),
    sortedClosedIncidents: _ember['default'].computed.sort('closedIncidents', 'sortClosedAtBy')
  });
});