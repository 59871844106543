define('diesel/organization/admin/billing/contacts/index/route', ['exports', 'ember'], function (exports, _ember) {

  var BillingRoute = 'organization.admin.billing';

  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var billingDetail = this.modelFor(BillingRoute);
      return billingDetail.get('billingContacts');
    },

    actions: {
      destroyBillingContact: function destroyBillingContact(billingContact) {
        var _this = this;

        billingContact.destroyRecord()['catch'](function (e) {
          _ember['default'].get(_this, 'flashMessages').danger(e.message);
        });
      }
    }
  });
});