define('diesel/settings/whitelist/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var user = this.session.get('currentUser');
      return this.get('store').query('whitelistMembership', { user: user }).then(function (memberships) {
        return memberships.map(function (m) {
          return m.get('organization');
        });
      });
    }
  });
});