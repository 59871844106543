define("diesel/components/modal-edit-single-sign-on/component", ["exports", "ember", "ember-validations", "diesel/mixins/components/modal-base"], function (exports, _ember, _emberValidations, _dieselMixinsComponentsModalBase) {
  exports["default"] = _ember["default"].Component.extend(_emberValidations["default"], _dieselMixinsComponentsModalBase["default"], {
    // Input
    newSaml: null,

    // User inputs
    metadataUrl: "",
    metadataXml: "",

    // Control UI element state
    disableSave: _ember["default"].computed.or("hasUrlError", "hasXmlError", "isPending", "noInput"),
    isPending: _ember["default"].computed.or("newSaml.isSaving", "newSaml.isValidating"),
    hasUrlError: _ember["default"].computed.gt("errors.metadataUrl.length", 0),
    hasXmlError: _ember["default"].computed.gt("errors.metadataXml.length", 0),
    noInput: _ember["default"].computed("metadataUrl", "metadataXml", function () {
      return !this.get("metadataUrl") && !this.get("metadataXml");
    }),

    title: "Configure an SSO Provider",
    description: "",

    validations: {
      metadataUrl: {
        format: {
          "with": /^https?:\/\//,
          allowBlank: true,
          message: "must be a complete URL"
        }
      },
      metadataXml: {
        format: {
          "with": /^</,
          allowBlank: true,
          message: "must be XML content"
        }
      }
    },

    actions: {
      commitChanges: function commitChanges() {
        var _this = this;

        var saml = this.get("newSaml");
        var metadataUrl = this.get("metadataUrl").trim();
        var rawXml = this.get("metadataXml").trim();

        saml.set("metadataUrl", metadataUrl);
        if (rawXml) {
          // Base64 encode the file contents if given
          saml.set("metadata", window.btoa(rawXml));
        }

        return saml.save().then(function () {
          return _this.sendAction("dismissModal");
        });
      }
    }
  });
});