define('diesel/components/c-control-table-filter-bar/component', ['exports', 'ember', 'diesel/config/environment', 'diesel/mixins/controllers/filterable-controls'], function (exports, _ember, _dieselConfigEnvironment, _dieselMixinsControllersFilterableControls) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: "",
    controls: null,
    categories: null,
    searchValue: null,
    selectedFramework: null,
    selectedCategory: null,
    selectedSearchTerm: null,
    sortOptions: _dieselMixinsControllersFilterableControls.sortOptions,
    scope: null, // We just pass this through to the print Url

    // This seems to be the only reliable way to pass forward query params in a link
    // 1) generate the query string manually
    // 2) Use a <a> rather than link-to
    // I'm sure there is a better way, however what is suggested in the Ember docs doesn't actually work
    //  🤷‍♂️

    printUrl: _ember['default'].computed('selectedSort', 'selectedCategory', 'scope', function () {
      var _getProperties = this.getProperties('selectedSort', 'selectedCategory', 'scope');

      var selectedSort = _getProperties.selectedSort;
      var selectedCategory = _getProperties.selectedCategory;
      var scope = _getProperties.scope;

      var host = _dieselConfigEnvironment['default'].aptibleHosts.dashboard;
      var params = [];
      var path = 'print-controls';

      if (scope) params.push('scope=' + scope);
      if (selectedCategory) params.push('category=' + selectedCategory);
      if (selectedSort) params.push('sort=' + selectedSort);

      return host + '/' + path + '?' + params.join('&');
    }),

    actions: {
      onCategorySelect: function onCategorySelect(category) {
        this.attrs.onCategorySelect(category);
      },
      onSortSelect: function onSortSelect(sort) {
        this.attrs.onSortSelect(sort);
      }
    }
  });
});