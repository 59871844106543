define('diesel/components/enrolled-course-item/component', ['exports', 'ember', 'diesel/models/organization-profile'], function (exports, _ember, _dieselModelsOrganizationProfile) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['enrolled-course-item', 'panel', 'component-profile'],
    targetRoleDescription: _ember['default'].computed('course.targetRole', function () {
      var targetRole = this.get('course.targetRole');
      var shortName = this.get('organizationProfile.shortName');

      if (!targetRole) {
        return 'All ' + shortName + ' workforce members.';
      }

      var config = _dieselModelsOrganizationProfile.ROLE_DESIGNATIONS[targetRole.camelize() + 'Role'];

      if (config) {
        return config.description;
      }
    }),

    targetRoleName: _ember['default'].computed('course.targetRole', 'roles.[]', function () {
      var targetRole = this.get('course.targetRole');
      var organizationProfile = this.get('organizationProfile');
      var roles = this.get('roles');

      if (!targetRole) {
        return 'All Workforce';
      }

      var roleHref = organizationProfile.get('links.' + targetRole + '_role');
      var role = roles.findBy('links.self', roleHref);
      if (role) {
        return role.get('name');
      }
    })
  });
});