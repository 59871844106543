define('diesel/assignment/course/material/index/route', ['exports', 'ember', 'diesel/utils/course-assignment-state'], function (exports, _ember, _dieselUtilsCourseAssignmentState) {
  exports['default'] = _ember['default'].Route.extend({
    redirect: function redirect() {
      var course = this.modelFor('assignment.course');
      var assignment = this.modelFor('assignment');
      var currentModule = assignment.get('currentModule');
      var showWarning = currentModule && !(0, _dieselUtilsCourseAssignmentState.courseHasModule)(course, currentModule);
      var module = currentModule || course.get('defaultModule');

      if (showWarning) {
        // User's assignment saved a module reference that no longer exists for course
        // config.  Redirecting to default with message
        var redirectMessage = 'This course\'s content has changed since your last visit.\n                              You have been redirected back to the start of the course';
        _ember['default'].get(this, 'flashMessages').danger(redirectMessage);
      }

      this.transitionTo('assignment.course.material.module', module);
    }
  });
});