define('diesel/models/disk', ['exports', 'ember-data', 'diesel/models/base', 'ember'], function (exports, _emberData, _dieselModelsBase, _ember) {

  var DISK_COST = 20; // cents/GB

  exports['default'] = _dieselModelsBase['default'].extend({
    size: _emberData['default'].attr('number'),
    baselineIops: _emberData['default'].attr('number'),
    keyBytes: _emberData['default'].attr('number'),
    ebsVolumeType: _emberData['default'].attr('string'),

    database: _emberData['default'].belongsTo('database', { async: true }),

    encryptionAlgorithm: _ember['default'].computed('keyBytes', function () {
      return 'AES-' + this.get('keyBytes') * 8;
    }),

    estimatedMonthlyCostInCents: _ember['default'].computed('size', function () {
      return this.get('size') * DISK_COST;
    })
  });
});