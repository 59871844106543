define('diesel/components/select-year/component', ['exports', 'ember'], function (exports, _ember) {

  var YearsRangeSize = 10;

  function range(start, end) {
    return new Array(end - start).join(0).split(0).map(function (val, id) {
      return id + start;
    });
  }

  exports['default'] = _ember['default'].Component.extend({
    years: range(new Date().getFullYear(), new Date().getFullYear() + YearsRangeSize).map(function (y) {
      return y.toString();
    })
  });
});