define("diesel/components/organization-switcher/component", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    modalDisplay: _ember["default"].inject.service(),
    // Input
    authorization: null,

    tagName: "li",
    classNames: ["dropdown", "currentUser"],

    actions: {
      openOrgPicker: function openOrgPicker() {
        var modalDisplay = this.get("modalDisplay");
        modalDisplay.showModal("modal-org-picker", { authorization: this.get("authorization") }, {
          actions: {
            onOrgPicked: modalDisplay.modalAction(this, "onOrgPicked"),
            onReauthenticate: modalDisplay.modalAction(this, "onReauthenticate")
          }
        });
      },

      onOrgPicked: function onOrgPicked(organization) {
        var _this = this;

        var authorization = this.get("authorization");
        authorization.set("currentOrganization", organization);

        var user = this.get("authorization.currentUser");
        user.set("selectedOrganization", organization);
        user.save().then(function () {
          _this.sendAction("onOrgSwitch", organization);
        });
      },

      onReauthenticate: function onReauthenticate(organization) {
        var _this2 = this;

        var user = this.get("authorization.currentUser");
        user.set("selectedOrganization", organization);
        user.save().then(function () {
          _this2.sendAction("reauthenticate");
        });
      }
    }
  });
});