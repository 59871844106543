define('diesel/components/modal-create-log-drain-v1/component', ['exports', 'ember', 'diesel/mixins/components/modal-base'], function (exports, _ember, _dieselMixinsComponentsModalBase) {

  // We create an anchor since that is, afaik, the easiest way to parse a url in javascript
  function parseUrl(url) {
    var a = document.createElement('a');
    a.href = url;
    a.hostWithoutPort = a.host.substring(0, a.host.lastIndexOf(':')); // Remove port

    // Workaround for HTMLAnchorElement not properly parsing username and password in phantomjs.
    if (!a.username && !a.password) {
      var credentials = url.substring(a.protocol.length + 2, url.lastIndexOf('@' + a.hostWithoutPort)).split(':');
      a.username = credentials[0];
      a.password = credentials[1];
    }

    return a;
  }

  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsModalBase['default'], {
    newLogDrainV1: null, // set when opening modal

    title: _ember['default'].computed('account.handle', function () {
      return 'Create a new log drain on ' + this.get('account.handle');
    }),

    description: _ember['default'].computed('account.handle', function () {
      return 'Log Drains let you collect stdout and stderr logs from your apps\n              and databases deployed in the ' + this.get('account.handle') + '\n              environment and route them to a log destination.';
    }),
    isPending: _ember['default'].computed.or('newLogDrainV1.isSaving', 'newLogDrainV1.isValidating'),
    hasError: _ember['default'].computed.gt('errors.newLogDrainV1.handle.length', 0),
    isSyslogDrain: _ember['default'].computed.equal('newLogDrainV1.drainType', 'syslog_tls_tcp'),
    isHttpsDrain: _ember['default'].computed.equal('newLogDrainV1.drainType', 'https'),
    isHostPortDrain: _ember['default'].computed.or('isSyslogDrain', 'isHttpsDrain'),

    disableSave: _ember['default'].computed('isHostPortDrain', 'esDatabases', function () {
      return this.get('newLogDrainV1.isSaving') || !this.get('isHostPortDrain') && this.get('esDatabases.length') === 0;
    }),

    setDrainFromDatabase: _ember['default'].observer('esDatabase', function () {
      var database = this.get('esDatabase');

      if (database) {
        var connectionUrl = database.get('connectionUrl');
        var a = parseUrl(connectionUrl);

        var newLogDrainV1 = this.get('newLogDrainV1');
        newLogDrainV1.set('drainHost', a.hostWithoutPort);
        newLogDrainV1.set('drainPort', a.port);
        newLogDrainV1.set('drainUsername', a.username);
        newLogDrainV1.set('drainPassword', a.password);
      }
    }),

    actions: {
      httpsSelected: function httpsSelected() {
        var newLogDrainV1 = this.get('newLogDrainV1');
        if (!newLogDrainV1.get('drainPort')) {
          newLogDrainV1.set('drainPort', '443');
        }
      },

      beforeDismiss: function beforeDismiss() {
        this.get('newLogDrainV1').rollbackAttributes();
      },

      createLogDrain: function createLogDrain() {
        var logDrain = this.get('newLogDrainV1');
        this.sendAction('onCreateLogDrain', logDrain);
      }
    }
  });
});