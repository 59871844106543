define('diesel/gridiron-settings/organization/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.modelFor('gridiron-settings');
    },

    setupController: function setupController(controller, model) {
      var organization = this.get('complianceStatus.organization');

      if (_ember['default'].isEmpty(model.get('longName'))) {
        model.set('longName', organization.get('name'));
      }

      controller.setProperties({ model: model, organization: organization });
    },

    actions: {
      save: function save() {
        var _this = this;

        this.currentModel.save().then(function () {
          var message = 'Organization profile saved.';
          _this.currentModel.observeDocumentBuilds();
          _ember['default'].get(_this, 'flashMessages').success(message);
        }, function (e) {
          var message = _ember['default'].getWithDefault(e, 'errors.message', 'An error occurred');
          _ember['default'].get(_this, 'flashMessages').danger('Save Failed! ' + message);
        });
      }
    }
  });
});