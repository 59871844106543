define('diesel/models/assignment-state', ['exports', 'ember-data', 'diesel/models/base'], function (exports, _emberData, _dieselModelsBase) {
  var _ASSIGNMENT_STATES_AS_TEXT;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var ASSIGNMENT_STATES = {
    ASSIGNED: 'assigned_due',
    ASSIGNED_OVERDUE: 'assigned_overdue',
    COMPLETED: 'assigned_complete',
    EXPIRED: 'assigned_expired',
    UNASSIGNED: 'unassigned',
    NEVER_ASSIGNED: 'never_assigned'
  };

  exports.ASSIGNMENT_STATES = ASSIGNMENT_STATES;
  var ASSIGNMENT_TRANSITIONS = {
    ASSIGNED: 'assigned',
    COMPLETED: 'completed',
    EXPIRED: 'expired',
    UNASSIGNED: 'unassigned',
    REASSIGNED: 'reassigned',
    PASSED_DUE: 'passed_due',
    NEVER_ASSIGNED: 'never_assigned'
  };

  exports.ASSIGNMENT_TRANSITIONS = ASSIGNMENT_TRANSITIONS;
  var ASSIGNMENT_STATES_AS_TEXT = (_ASSIGNMENT_STATES_AS_TEXT = {}, _defineProperty(_ASSIGNMENT_STATES_AS_TEXT, ASSIGNMENT_TRANSITIONS.ASSIGNED, 'Assigned'), _defineProperty(_ASSIGNMENT_STATES_AS_TEXT, ASSIGNMENT_TRANSITIONS.PASSED_DUE, 'Overdue'), _defineProperty(_ASSIGNMENT_STATES_AS_TEXT, ASSIGNMENT_TRANSITIONS.COMPLETED, 'Completed'), _defineProperty(_ASSIGNMENT_STATES_AS_TEXT, ASSIGNMENT_TRANSITIONS.EXPIRED, 'Expired'), _defineProperty(_ASSIGNMENT_STATES_AS_TEXT, ASSIGNMENT_TRANSITIONS.UNASSIGNED, 'Unassigned'), _defineProperty(_ASSIGNMENT_STATES_AS_TEXT, ASSIGNMENT_TRANSITIONS.NEVER_ASSIGNED, ''), _ASSIGNMENT_STATES_AS_TEXT);

  exports.ASSIGNMENT_STATES_AS_TEXT = ASSIGNMENT_STATES_AS_TEXT;
  var UNASSIGNED_STATES = [ASSIGNMENT_STATES.NEVER_ASSIGNED, ASSIGNMENT_STATES.UNASSIGNED];

  exports.UNASSIGNED_STATES = UNASSIGNED_STATES;
  exports['default'] = _dieselModelsBase['default'].extend({
    assignment: _emberData['default'].belongsTo('assignment', { async: true }),
    transition: _emberData['default'].attr('string'),
    state: _emberData['default'].attr('string'),
    notifiedAt: _emberData['default'].attr('iso-8601-timestamp'),
    createdAt: _emberData['default'].attr('iso-8601-timestamp'),
    createdByUserName: _emberData['default'].attr('string'),
    createdByUserEmail: _emberData['default'].attr('string'),
    createdByUserUrl: _emberData['default'].attr('string')
  });
});