define('diesel/models/verification-attempt', ['exports', 'ember-data', 'diesel/models/base'], function (exports, _emberData, _dieselModelsBase) {
  exports['default'] = _dieselModelsBase['default'].extend({
    stripeSource: _emberData['default'].belongsTo('stripe-source', { async: true }),

    amount1: _emberData['default'].attr('number'),
    amount2: _emberData['default'].attr('number'),
    amountDecimal1: _emberData['default'].attr('string'),
    amountDecimal2: _emberData['default'].attr('string'),
    success: _emberData['default'].attr('boolean'),
    createdAt: _emberData['default'].attr('iso-8601-timestamp')
  });
});