define('diesel/app/services/service/scale/route', ['exports', 'ember', 'diesel/config/environment'], function (exports, _ember, _dieselConfigEnvironment) {

  var OPERATION_TIMEOUT = _dieselConfigEnvironment['default'].timeouts.serviceScaleOperation;

  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var app = this.modelFor('app');
      var service = this.modelFor('app.services.service');

      return _ember['default'].RSVP.hash({
        app: app, service: service, stack: service.get('account.stack')
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties(model);
    },
    actions: {
      onScaleService: function onScaleService(service, instanceProfile, containerCount, containerSize, deferred) {
        return this.store.createRecord('operation', {
          type: 'scale',
          containerSize: containerSize,
          containerCount: containerCount,
          instanceProfile: instanceProfile,
          service: service
        }).save().then(function (operation) {
          return operation.reloadUntilStatusChanged(OPERATION_TIMEOUT);
        }).then(function () {
          return service.reload();
        }).then(deferred.resolve, deferred.reject);
      }
    }
  });
});