define('diesel/components/security-controls-filters/component', ['exports', 'ember'], function (exports, _ember) {

  var STATUSES = [{ title: 'Implemented', value: true }, { title: 'Unimplemented', value: false }];

  exports['default'] = _ember['default'].Component.extend({
    filters: { search: '', implemented: null },
    classNames: ['risk-assessment__filters form-inline'],
    statuses: STATUSES,

    showClear: _ember['default'].computed('filters.search', 'filters.implemented', function () {
      var currentSearch = this.get('filters.search') || '';
      var implemented = this.get('filters.implemented');

      return _ember['default'].$.trim(currentSearch) !== '' || implemented && implemented !== '';
    }),

    actions: {
      clear: function clear() {
        this.set('filters.implemented', null);
        this.set('filters.search', '');
      },

      update: function update() {
        var implemented = this.$('select.implemented').val();
        this.set('filters.implemented', implemented);
      }
    }
  });
});