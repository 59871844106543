define('diesel/components/aptible-ability/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    authorization: _ember['default'].inject.service(),
    tagName: '',

    scope: null,
    permittable: null,
    scopes: null,
    hasAbility: _ember['default'].computed('scope', 'permittable', 'scopes', function () {
      var _this = this;

      var _getProperties = this.getProperties('scope', 'permittable', 'scopes');

      var scope = _getProperties.scope;
      var permittable = _getProperties.permittable;
      var scopes = _getProperties.scopes;

      _ember['default'].assert("You must provide a scope to aptible-ability", !!scope);
      _ember['default'].assert("You must provide a permittable to aptible-ability", !!permittable);
      if (scope === 'multiple') {
        return scopes.some(function (scope) {
          return _this.get('authorization').checkAbility(scope, permittable);
        });
      } else {
        return this.get('authorization').checkAbility(scope, permittable);
      }
    })
  });
});