define('diesel/models/operation', ['exports', 'ember-data', 'diesel/models/base', 'ember', 'diesel/config/environment'], function (exports, _emberData, _dieselModelsBase, _ember, _dieselConfigEnvironment) {

  var BASE_INTERVAL = _dieselConfigEnvironment['default'].timeouts.operationPollBaseInterval;
  var BACKOFF_FACTOR = 1.2;

  function makeOperationPollSchedule(baseInterval, backoffFactor, timeout) {
    _ember['default'].assert('baseInterval must be > 0', baseInterval > 0);
    _ember['default'].assert('backoffFactor must be >= 1', backoffFactor >= 1);
    _ember['default'].assert('timeout must be > 0', timeout > 0);

    var schedule = [];
    var total = 0;

    function pushInterval(interval) {
      total += interval;
      schedule.push(interval);
    }

    var nextInterval = baseInterval;
    while (total < timeout) {
      pushInterval(nextInterval);
      nextInterval = Math.floor(nextInterval * backoffFactor);
    }

    return schedule;
  }

  exports['default'] = _dieselModelsBase['default'].extend({
    type: _emberData['default'].attr('string'),
    status: _emberData['default'].attr('string', { defaultValue: 'queued' }),
    createdAt: _emberData['default'].attr('iso-8601-timestamp'),
    updatedAt: _emberData['default'].attr('iso-8601-timestamp'),
    userName: _emberData['default'].attr('string'),
    userEmail: _emberData['default'].attr('string'),
    gitRef: _emberData['default'].attr('string'),

    // note for why a operation happened
    note: _emberData['default'].attr('string'),

    // provisioning databases
    diskSize: _emberData['default'].attr('number'),

    // scaling services
    containerSize: _emberData['default'].attr('number'), // when scaling size of container
    containerCount: _emberData['default'].attr('number'), // when scaling number of containers
    instanceProfile: _emberData['default'].attr('string'), // when changing container profile
    env: _emberData['default'].attr(),

    // vhosts
    certificate: _emberData['default'].attr(),
    privateKey: _emberData['default'].attr(),

    // append these values for a nested url. They are
    // not actual attributes in the server payload, and
    // as such not ember-data `attrs`.
    database: null,
    app: null,
    vhost: null,
    logDrain: null,
    metricDrain: null,
    service: null,
    image: null,

    reloadUntilStatusChanged: function reloadUntilStatusChanged(timeout) {
      var operation = this;
      var schedule = makeOperationPollSchedule(BASE_INTERVAL, BACKOFF_FACTOR, timeout);

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        var scheduleNextPoll = function scheduleNextPoll() {
          operation.reload().then(function (o) {
            var operationStatus = o.get("status");

            if (operationStatus === "succeeded") {
              return resolve(o);
            }

            if (operationStatus === "failed") {
              throw new Error('Operation failed');
            }

            var nextInterval = schedule.shift();

            if (nextInterval === undefined) {
              throw new Error('Operation timed out.');
            }

            _ember['default'].run.later(operation, scheduleNextPoll, nextInterval);
          }, function (e) {
            if (e.status !== 404) {
              throw e;
            }
            // NOTE: operationsManager relies on this return value being null.
            resolve();
          })['catch'](function (e) {
            e.operation = operation;
            reject(e);
          });
        };

        scheduleNextPoll();
      });
    },

    isDone: _ember['default'].computed("status", function () {
      var operationStatus = this.get("status");
      if (operationStatus === "succeeded") {
        return true;
      }
      if (operationStatus === "failed") {
        return true;
      }
      return false;
    })
  });
});