define('diesel/verify/route', ['exports', 'ember', 'diesel/models/database', 'diesel/config/environment'], function (exports, _ember, _dieselModelsDatabase, _dieselConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend({
    authorization: _ember['default'].inject.service(),
    model: function model(params) {
      var _this = this;

      var options = {
        challengeId: params.challenge_id,
        verificationCode: params.verification_code,
        type: 'email_verification_challenge'
      };

      var verification = this.store.createRecord('verification', options);
      var auth = this.get('authorization');

      return verification.save().then(function () {
        var user = _this.session.get('currentUser');
        // this will update the 'verification' property
        return user.reload();
      }).then(function (user) {
        return (0, _dieselModelsDatabase.provisionDatabases)(user, _this.store);
      }).then(function () {
        return _this.get('authorization').load();
      }).then(function () {
        return _ember['default'].RSVP.hash({
          currentTrial: auth.get('currentOrganization.billingDetail.currentTrial')
        });
      });
    },

    afterModel: function afterModel(model) {
      if (model.currentTrial && model.currentTrial.get('active') && _dieselConfigEnvironment['default'].featureFlags.showFTUX) {
        window.location = _dieselConfigEnvironment['default'].aptibleHosts.appUrl + '/create';
      } else {
        this.replaceWith('enclave');
      }
    }
  });
});