define('diesel/mixins/routes/paginated-operations', ['exports', 'ember', 'diesel/mixins/routes/paginated'], function (exports, _ember, _dieselMixinsRoutesPaginated) {
  exports['default'] = _ember['default'].Mixin.create(_dieselMixinsRoutesPaginated['default'], {
    // Methods to override
    getPaginatedResourceOwnerType: function getPaginatedResourceOwnerType() {
      _ember['default'].assert('Must override getPaginatedResourceOwnerType');
    },

    // Paginated hooks
    getPaginatedResourceType: function getPaginatedResourceType() {
      return 'operation';
    },

    composeQuery: function composeQuery(page) {
      var paginatedResourceOwnerType = this.getPaginatedResourceOwnerType();
      var paginatedResourceOwner = this.modelFor(paginatedResourceOwnerType);

      var query = { page: page };
      query[paginatedResourceOwnerType] = paginatedResourceOwner;
      return query;
    },

    // Ember hooks
    titleToken: function titleToken() {
      var resource = this.modelFor(this.getPaginatedResourceOwnerType());
      return resource.get('handle') + ' Activity';
    }
  });
});