define('diesel/gridiron-setup/finish/route', ['exports', 'ember', 'diesel/mixins/routes/spd-route'], function (exports, _ember, _dieselMixinsRoutesSpdRoute) {
  exports['default'] = _ember['default'].Route.extend(_dieselMixinsRoutesSpdRoute['default'], {
    model: function model() {
      var organizationProfile = this.modelFor('gridiron-setup');
      var draftRiskAssessment = organizationProfile.get('draftRiskAssessment');
      var organization = this.modelFor('gridiron-organization').get('organization');

      return _ember['default'].RSVP.hash({ organizationProfile: organizationProfile, draftRiskAssessment: draftRiskAssessment, organization: organization });
    }
  });
});