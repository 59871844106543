define('diesel/components/asset-impact-scaler/component', ['exports', 'ember'], function (exports, _ember) {
  var IMPACT_DESCRIPTIONS = {
    na: 'is not applicable to this component.',
    very_low: 'in this component would result in negligible adverse effect.',
    low: 'in this component would result in limited adverse effect, such as disrupting the organization, incurring minor damage to organization assets, minor financial loss, or minor harm to individuals.',
    medium: 'in this component would result in a serious adverse effect, such as disrupting the organization, incurring significant damage to organization assets, significant financial loss, or significant harm to individuals.',
    high: 'in this component would result in severe or catastrophic adverse effect, such as crippling the organization, incurring major damage to organization assets, major financial loss, or physical harm to individuals.',
    very_high: 'in this component would result in multiple severe or catastrophic adverse effects.'
  };

  exports['default'] = _ember['default'].Component.extend({
    impactName: _ember['default'].computed('scale', function () {
      return this.get('scale').capitalize();
    }),

    impactValue: _ember['default'].computed('impact', function () {
      return this.get('impact').capitalize();
    }),

    impact: _ember['default'].computed('scale', 'asset', 'currentValue', function () {
      // Add observer for currentValue so we no when to recompute impact from
      // a mutation
      var asset = this.get('asset');
      var scale = this.get('scale') + 'Impact';
      return asset ? asset.get(scale) : 'na';
    }),

    impactDescription: _ember['default'].computed('impactName', function () {
      var impact = this.get('impact');
      return IMPACT_DESCRIPTIONS[impact];
    })
  });
});