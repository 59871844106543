define('diesel/app/vhosts/route', ['exports', 'ember', 'diesel/config/environment'], function (exports, _ember, _dieselConfigEnvironment) {

  var OPERATION_TIMEOUT = _dieselConfigEnvironment['default'].timeouts.vhostProvisionOperation;

  exports['default'] = _ember['default'].Route.extend({
    modalDisplay: _ember['default'].inject.service(),

    model: function model() {
      var app = this.modelFor('app');
      return app.get('vhosts');
    },

    actions: {
      openUpgradeVhostModal: function openUpgradeVhostModal(vhostToUpgrade) {
        var modalDisplay = this.get('modalDisplay');
        modalDisplay.showModal('modal-upgrade-to-alb', { vhostToUpgrade: vhostToUpgrade }, {
          actions: {
            upgradeVhost: modalDisplay.modalAction(this, 'upgradeVhost')
          }
        });
      },

      upgradeVhost: function upgradeVhost(vhost) {
        var _this = this;

        vhost.setProperties({ platform: 'alb' });

        return vhost.save().then(function () {
          var message = 'Endpoint ' + vhost.get('displayHost') + ' is being upgraded to ALB.';
          _ember['default'].get(_this, 'flashMessages').info(message);
        }).then(function () {
          var op = _this.store.createRecord('operation', { type: 'provision', vhost: vhost });
          return op.save();
        }).then(function (op) {
          return op.reloadUntilStatusChanged(OPERATION_TIMEOUT);
        }).then(function () {
          var message = 'Endpoint ' + vhost.get('displayHost') + ' was upgraded to ALB.';
          _ember['default'].get(_this, 'flashMessages').success(message);
        })['catch'](function () {
          var message = 'Failed to upgrade Endpoint ' + vhost.get('displayHost') + ' to ALB.\n                         Use aptible restart to access debugging information.';
          _ember['default'].get(_this, 'flashMessages').danger(message);
        })['finally'](function () {
          return vhost.reload();
        });
      }
    }
  });
});