define('diesel/components/confirmation-modal/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    confirmationModalService: _ember['default'].inject.service('confirmation-modal'),
    classNames: ['confirmation-modal-wrapper'],
    tagName: '',
    model: _ember['default'].computed.reads('modal.model'),

    startConfirmationModalServiceListener: _ember['default'].on('init', function () {
      var _this = this;

      this._super.apply(this, arguments);
      this.get('confirmationModalService').on('open', function (modal) {
        _this.set('modal', modal);
        _this.set('isOpen', true);
      });
    }),

    close: function close() {
      this.set('isOpen', false);
      this.set('modal', null);
    },

    actions: {

      cancel: function cancel() {
        var _this2 = this;

        var modal = this.get('modal');
        new _ember['default'].RSVP.Promise(function (resolve) {
          resolve(modal && modal.onCancel && modal.onCancel());
        }).then(function () {
          _this2.close();
        });
      },

      confirm: function confirm() {
        var _this3 = this;

        var modal = this.get('modal');
        new _ember['default'].RSVP.Promise(function (resolve) {
          resolve(modal && modal.onConfirm && modal.onConfirm());
        }).then(function () {
          _this3.close();
        });
      }

    }
  });
});