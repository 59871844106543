define('diesel/utils/training/hipaa-awareness-assessment', ['exports'], function (exports) {
  exports['default'] = { passingScore: 8, questions: [{
      title: 'What kind of information is regulated under HIPAA?',
      choices: ['Personally identifiable information (PII)', 'All health data', 'Protected Health Information (PHI)', 'Payment-card information'],
      answer: 2,
      error: 'Incorrect answer'
    }, {
      title: 'What is PHI?',
      choices: ['Any information that can identify an individual', 'Any information related to healthcare (including anonymous data)', 'Any information that identifies (or could identify) an individual and that relates to the health of the individual'],
      answer: 2,
      error: 'Incorrect answer'
    }, {
      title: 'When we say we are “HIPAA compliant,” which HHS rules are we usually talking about?',
      choices: ['The Security Rule', 'The Privacy Rule', 'The Breach Notification Rule', 'All of the above'],
      answer: 3,
      error: 'Incorrect answer'
    }, {
      title: 'For what reasons can you use or disclose PHI?',
      choices: ['For any reason', 'When you’re bored or curious', 'Solely for work reasons'],
      answer: 2,
      error: 'Incorrect answer'
    }, {
      title: 'When you’re authorized to use or disclose PHI, what is the appropriate guiding principle?',
      choices: ['Only use or disclose the minimum amount of PHI necessary to complete your job at the moment', 'Use or disclose all the PHI our organization has', 'Use or disclose at least the PHI that is required, but more is OK'],
      answer: 1,
      error: 'Incorrect answer'
    }, {
      title: 'When can you send PHI to a vendor?',
      choices: ['When my organization has any contractual relationship with the vendor', 'When my organization is negotiating a contract with the vendor', 'Whenever instructed to by the Security Officer', 'Only when my organization has a business associate agreement (BAA) with the vendor'],
      answer: 3,
      error: 'Incorrect answer'
    }, {
      title: 'When PHI is encrypted, it is no longer regulated under HIPAA.',
      choices: ['True', 'False'],
      answer: 1,
      error: 'Incorrect answer'
    }, {
      title: 'When you suspect that your organization has suffered a security or privacy incident, what should you do?',
      choices: ['Nothing', 'Report the potential/actual incident to the Security Officer', 'Investigate the incident on your own'],
      answer: 1,
      error: 'Incorrect answer'
    }, {
      title: 'HIPAA only covers privacy, and does not cover the security of PHI.',
      choices: ['True', 'False'],
      answer: 1,
      error: 'Incorrect answer'
    }, {
      title: 'If you’re unsure about a HIPAA requirement related to your use or disclosure PHI, what should you do?',
      choices: ['Ask questions!', 'Research HIPAA on your own', 'Use or disclose the PHI anyway'],
      answer: 0,
      error: 'Incorrect answer'
    }] };
});