define('diesel/components/agreement-owner-item/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['agreement-owner-item', 'panel', 'component-profile', 'component-profile--agreement-owner'],
    openAttachmentModal: 'openAttachmentModal',

    actions: {
      openAttachmentModal: function openAttachmentModal(agreement) {
        this.sendAction('openAttachmentModal', agreement);
      }
    }
  });
});