define('diesel/policy-manual/policy/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params) {
      var policyManual = this.modelFor('policy-manual');
      var organizationProfile = this.get('complianceStatus.organizationProfile');
      var policy = policyManual.get('documents').findBy('title', params.policy_id);

      return _ember['default'].RSVP.hash({ policy: policy, policyManual: policyManual, organizationProfile: organizationProfile });
    }
  });
});