define("diesel/adapters/whitelist-membership", ["exports", "diesel/adapters/auth", "diesel/utils/build-url-with-prefix-map"], function (exports, _dieselAdaptersAuth, _dieselUtilsBuildUrlWithPrefixMap) {
  exports["default"] = _dieselAdaptersAuth["default"].extend({
    buildURL: (0, _dieselUtilsBuildUrlWithPrefixMap["default"])({
      organizations: { property: "organization.id", only: ["createRecord"] }
    }),

    query: function query(store, type, _query) {
      // Default to lookup by organization
      var id = undefined,
          resource = undefined;

      if (_query.organization) {
        id = _query.organization.get('id');
        resource = "organizations";
      }
      // Allow lookup by user as well
      if (_query.user) {
        id = _query.user.get('id');
        resource = "users";
      }
      this.set("namespace", resource + "/" + id);
      var url = this.buildURL(type.modelName, null, null, "findAll");
      this.set("namespace", "");
      return this.ajax(url, "GET", {});
    }
  });
});