define('diesel/models/trial', ['exports', 'ember', 'ember-data', 'diesel/models/base', 'diesel/helpers/format-utc-timestamp'], function (exports, _ember, _emberData, _dieselModelsBase, _dieselHelpersFormatUtcTimestamp) {
  exports['default'] = _dieselModelsBase['default'].extend({
    billingDetail: _emberData['default'].belongsTo('billing-detail', { async: true }),

    rangeBegin: _emberData['default'].attr('iso-8601-timestamp'),
    rangeEnd: _emberData['default'].attr('iso-8601-timestamp'),

    rangeBeginPretty: _ember['default'].computed('rangeBegin', function () {
      var rangeBegin = this.get('rangeBegin');
      return (0, _dieselHelpersFormatUtcTimestamp.formatUtcTimestamp)(rangeBegin, true);
    }),

    rangeEndPretty: _ember['default'].computed('rangeEnd', function () {
      var rangeEnd = this.get('rangeEnd');
      return (0, _dieselHelpersFormatUtcTimestamp.formatUtcTimestamp)(rangeEnd, true);
    }),

    active: _ember['default'].computed('rangeBegin', 'rangeEnd', function () {
      return Date.now() <= this.get('rangeEnd') && Date.now() >= this.get('rangeBegin');
    }),

    description: _ember['default'].computed('rangeBegin', 'rangeEnd', function () {
      var rangeBegin = this.get('rangeBeginPretty');
      var rangeEnd = this.get('rangeEndPretty');
      return 'Free Trial: ' + rangeBegin + ' - ' + rangeEnd;
    }),

    daysRemaining: _ember['default'].computed('rangeEnd', function () {
      var rangeEnd = this.get('rangeEnd');
      return Math.floor((rangeEnd - Date.now()) / (1000 * 3600 * 24));
    }),

    typeCanDeactivate: false
  });
});