define('diesel/organization/invite/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    isDisabled: _ember['default'].computed('model.isSaving', 'model.email', 'model.role.id', function () {
      if (this.get('model.isSaving')) {
        return true;
      }

      var email = this.get('model.email'),
          roleId = this.get('model.role.id');

      return _ember['default'].isBlank(email) || _ember['default'].isBlank(roleId);
    })
  });
});