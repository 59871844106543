define('diesel/gridiron-setup/locations/route', ['exports', 'diesel/gridiron-settings/locations/route', 'diesel/mixins/routes/spd-route'], function (exports, _dieselGridironSettingsLocationsRoute, _dieselMixinsRoutesSpdRoute) {
  exports['default'] = _dieselGridironSettingsLocationsRoute['default'].extend(_dieselMixinsRoutesSpdRoute['default'], {
    model: function model() {
      var organizationProfile = this.modelFor('gridiron-setup');
      return organizationProfile.get('locations');
    },

    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('organizationProfile', this.modelFor('gridiron-setup'));
    }
  });
});