define('diesel/mixins/routes/disallow-authenticated', ['exports', 'ember'], function (exports, _ember) {

  // If a user is authenticated, redirect them to `/`. If they
  // are not authenticated, allow them to continue.
  exports['default'] = _ember['default'].Mixin.create({
    beforeModel: function beforeModel() {
      var _this = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        var isAuthenticated = _this.get('session.isAuthenticated');
        if (isAuthenticated) {
          reject();
        } else if (isAuthenticated === undefined) {
          return _this.session.fetch('aptible').then(reject, resolve);
        } else {
          resolve();
        }
      })['catch'](function () {
        _this.transitionTo('requires-authorization');
      });
    }

  });
});