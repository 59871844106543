define('diesel/security-assessment/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params) {
      var scope = params.scope;
      var handle = params.handle;

      var organizationProfile = this.get('complianceStatus.organizationProfile');

      return new _ember['default'].RSVP.Promise(function (resolve) {
        if (scope === 'organization') {
          return resolve({ resource: organizationProfile, scope: scope, handle: handle });
        }

        organizationProfile.get(scope.pluralize()).then(function (resources) {
          resolve({ resource: resources.findBy('id', handle), scope: scope, handle: handle });
        });
      });
    },

    afterModel: function afterModel(model) {
      return model.resource.get('currentRiskProfile');
    },

    setupController: function setupController(controller, model) {
      var riskProfile = model.resource.get('currentRiskProfile');
      var resource = model.resource;
      var scope = model.scope;
      var handle = model.handle;

      var _riskProfile$getProperties = riskProfile.getProperties('securityControlCategories');

      var securityControlCategories = _riskProfile$getProperties.securityControlCategories;

      var organization = this.get('complianceStatus.organization');

      var assessmentName = resource.get('assessmentName');

      if (scope === 'organization') {
        // For organization scope, the `handle` param will be a security control category
        assessmentName = securityControlCategories.findBy('handle', handle).get('title');
      }

      controller.setProperties({ scope: scope, handle: handle, resource: resource, assessmentName: assessmentName, organization: organization });
    },

    title: function title() {
      var organization = this.get('complianceStatus.organization');
      return 'Security Assessments - Gridiron Admin - ' + organization.get('name');
    },

    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);

      this.render('sidebars/engine-sidebar', {
        into: 'security-assessment',
        outlet: 'sidebar',
        controller: this.controllerFor('gridiron-organization')
      });
    },

    actions: {
      editComponent: function editComponent(component) {
        if (component.get('isEditable')) {
          this.controller.editComponent(component);
        }
      }
    }
  });
});