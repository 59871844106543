define('diesel/gridiron-settings/team/index/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    redirect: function redirect() {
      var complianceStatus = this.modelFor('gridiron-organization');

      var redirectRoute = complianceStatus.get('hasComplianceOs') ? 'roles' : 'designations';

      this.transitionTo('gridiron-settings.team.' + redirectRoute);
    }
  });
});