define('diesel/utils/ring-buffer', ['exports'], function (exports) {
  exports['default'] = RingBuffer;

  function RingBuffer(size) {
    if (!size) {
      throw new Error('size is required');
    }

    this.size = size;
    this.nextPosition = 0;
    this.buffer = Array(size);
  }

  RingBuffer.prototype.push = function (e) {
    this.buffer[this.nextPosition] = e;
    this.nextPosition = (this.nextPosition + 1) % this.size;
  };
});