define('diesel/organization/admin/billing/payment-methods/new/index/route', ['exports', 'ember'], function (exports, _ember) {

  var BillingRoute = 'organization.admin.billing';

  exports['default'] = _ember['default'].Route.extend({
    actions: {
      saveCreditCard: function saveCreditCard(creditCard) {
        var _this = this;

        var billingDetail = this.modelFor(BillingRoute);

        this.controller.set('isSaving', true);
        billingDetail.addDefaultCreditCard(creditCard).then(function () {
          _this.transitionTo(BillingRoute + '.payment-methods');
        })['catch'](function (e) {
          _ember['default'].get(_this, 'flashMessages').danger(e.message);
        })['finally'](function () {
          _this.controller.set('isSaving', false);
        });
      }
    }
  });
});