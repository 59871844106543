define('diesel/threat-source/index/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.modelFor('threat-source');
    },

    setupController: function setupController(controller, model) {
      controller.set('model', model.threatSource);
      controller.set('riskAssessment', model.riskAssessment);
    }
  });
});