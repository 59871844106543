define('diesel/components/modal-certification/component', ['exports', 'ember', 'diesel/mixins/components/resizable-modal', 'diesel/mixins/components/modal-base', 'diesel/models/certification'], function (exports, _ember, _dieselMixinsComponentsResizableModal, _dieselMixinsComponentsModalBase, _dieselModelsCertification) {
  var ERROR_MESSAGE = 'There was an error saving the agreement';
  var computed = _ember['default'].computed;
  var reads = _ember['default'].computed.reads;
  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsResizableModal['default'], _dieselMixinsComponentsModalBase['default'], {
    store: _ember['default'].inject.service(),

    modalCertification: null, // this is set when the modal is shown
    vendor: reads('modalCertification.vendor'),
    types: _dieselModelsCertification.TYPES,
    intervals: _dieselModelsCertification.INTERVALS,
    newCertificationSystems: null,

    init: function init() {
      this._super.apply(this, arguments);
      var certification = this.get('modalCertification.certification');
      var attachment = this.get('modalCertification.attachment');
      var vendor = this.get('vendor');
      var title = 'Add a Certification';
      var description = vendor.get('name') + ' Certifications';
      var store = this.get('store');

      if (!certification) {
        certification = store.createRecord('certification', { vendor: vendor });
      }

      if (!attachment) {
        attachment = certification.get('attachments.firstObject') || store.createRecord('attachment', { certification: certification });
      }

      if (!certification.get('isNew')) {
        title = 'Update a Certification';
      }

      this.set('title', title);
      this.set('description', description);
      this.set('certification', certification);
      this.set('attachment', attachment);
      this.set('errors', _ember['default'].A());
    },

    selectedSystems: _ember['default'].computed('certification.systemCertifications', function () {
      return this.get('certification.systemCertifications').map(function (sc) {
        return sc.get('system');
      });
    }),

    buttonLabel: computed('certification.isNew', function () {
      var isNew = this.get('certification.isNew');
      return (isNew ? 'Save' : 'Update') + ' Certification';
    }),

    validate: function validate() {
      var errors = _ember['default'].A();

      var _getProperties = this.getProperties('certification');

      var certification = _getProperties.certification;

      if (!certification.get('type')) {
        errors.push('A certification type is required');
      }

      if (!certification.get('reviewInterval')) {
        errors.push('A review interval is required.');
      }

      this.set('errors', errors);
    },

    prepModelsForSave: function prepModelsForSave() {
      var attachment = this.get('attachment');
      if (attachment.get('fileURL')) {
        attachment.set('fileOriginalName', null);
      } else {
        attachment.set('fileURL', null);
      }
    },

    actions: {
      updateCertificationSystems: function updateCertificationSystems(systems) {
        this.set('newCertificationSystems', systems);
      },

      clearMessages: function clearMessages() {
        this.set('errors', null);
      },

      save: function save() {
        var _this = this;

        var _getProperties2 = this.getProperties('certification', 'attachment', 'newCertificationSystems');

        var certification = _getProperties2.certification;
        var attachment = _getProperties2.attachment;
        var newCertificationSystems = _getProperties2.newCertificationSystems;

        var file = attachment.get('file');

        this.validate();
        if (this.get('errors.length')) {
          this.scrollToTop();
          return;
        }

        this.prepModelsForSave();

        this.set('isSaving', true);
        return certification.updateSystemsAndSave(newCertificationSystems).then(function () {
          if (file) {
            return attachment.save();
          } else {
            return _ember['default'].RSVP.resolve(attachment);
          }
        }).then(function (savedAttachment) {
          var s3 = savedAttachment.get('s3Credentials');
          if (file && s3) {
            return file.upload(s3.url, {
              data: s3.credentials,
              headers: { 'x-amz-server-side-encryption': 'AES256' }
            });
          }
        }).then(function () {
          _this.sendAction('dismissModal');
        })['catch'](function (e) {
          _this.set('errors', [e.message || ERROR_MESSAGE]);
          _this.scrollToTop();
        })['finally'](function () {
          return _this.set('isSaving', false);
        });
      },

      beforeDismiss: function beforeDismiss() {
        if (this.get('isSaving')) {
          // We can't do anything at this point anyways!
          return;
        }

        this.get('certification').rollbackAttributes();
        if (this.get('attachment')) {
          this.get('attachment').rollbackAttributes();
        }
        this.set('errors', null);
      }
    }
  });
});