define('diesel/models/attestation', ['exports', 'ember-data', 'diesel/models/base', 'ember', 'diesel/models/user', 'diesel/utils/attestation-validation-error'], function (exports, _emberData, _dieselModelsBase, _ember, _dieselModelsUser, _dieselUtilsAttestationValidationError) {

  var Attestation = _dieselModelsBase['default'].extend({
    userUrl: _emberData['default'].attr('string'),
    userName: _emberData['default'].attr('string'),
    userEmail: _emberData['default'].attr('string'),
    schema: _emberData['default'].attr({ defaultValue: function defaultValue() {} }),
    document: _emberData['default'].attr({ defaultValue: function defaultValue() {} }),
    createdAt: _emberData['default'].attr('iso-8601-timestamp'),
    organizationProfile: _emberData['default'].belongsTo('organizationProfile', { async: true }),
    user: _ember['default'].computed({
      get: function get() {
        return this._user;
      },
      set: function set(key, user) {
        _ember['default'].assert('user must be a User model', user instanceof _dieselModelsUser['default']);
        this._user = user;

        var userUrl = user.get('links.self');
        var userName = user.get('name');
        var userEmail = user.get('email');

        this.setProperties({ userUrl: userUrl, userName: userName, userEmail: userEmail });
      }
    }),

    validationErrors: _ember['default'].computed('errors.[]', function () {
      return this.get('errors').map(function (e) {
        return _dieselUtilsAttestationValidationError['default'].create({ message: e.message });
      });
    })
  });

  Attestation.reopenClass({
    findOrCreate: function findOrCreate(params, store) {

      if (!(params.handle && params.organizationProfile)) {
        throw new Error('You must provide both a `handle` and an `organizationProfile` to `Attestation.findOrCreate`');
      }

      var handle = params.handle;
      var organizationProfile = params.organizationProfile;

      var findParams = { current: true, handle: handle, organizationProfile: organizationProfile };

      return new _ember['default'].RSVP.Promise(function (resolve) {
        store.query('attestation', findParams).then(function (attestations) {
          // Resolve any attestation returned (should be limited to 1), otherwise
          // instantiate a new attestation and return it
          var attestation = attestations.get('firstObject') || store.createRecord('attestation', params);
          resolve(attestation);
        })

        // In case of error, just create new attestation
        ['catch'](function (e) {
          if (e.status && e.status === 404) {
            resolve(store.createRecord('attestation', params));
          } else {
            throw e;
          }
        });
      });
    }
  });

  exports['default'] = Attestation;
});