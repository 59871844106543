define("diesel/create-dedicated-stack/controller", ["exports", "ember", "diesel/config/environment", "ember-validations"], function (exports, _ember, _dieselConfigEnvironment, _emberValidations) {
  exports["default"] = _ember["default"].Controller.extend(_emberValidations["default"], {
    validations: {
      'account.handle': {
        uniqueness: {
          message: 'is taken.',
          paramName: 'handle',
          url: (_dieselConfigEnvironment["default"].apiBaseUri || '') + "/claims/account"
        }
      }
    }
  });
});