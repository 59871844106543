define('diesel/models/organization-profile', ['exports', 'ember-data', 'diesel/models/base', 'ember', 'diesel/mixins/models/risk-scope', 'diesel/mixins/models/document-build'], function (exports, _emberData, _dieselModelsBase, _ember, _dieselMixinsModelsRiskScope, _dieselMixinsModelsDocumentBuild) {
  exports.nextStep = nextStep;
  exports.previousStep = previousStep;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var decamelize = _ember['default'].String.decamelize;
  var STEPS = [{ key: 'organization', name: 'Organization', description: 'Basic information about your organization' }, { key: 'locations', name: 'Locations', description: 'Manage your workforce locations' }, { key: 'team', name: 'Workforce', description: 'Configure training roles and Gridiron admins' }, { key: 'devices', name: 'Devices', description: 'Configure settings for workforce laptops, workstations, and mobile devices' }, { key: 'web-services', name: 'Third-Party Web Services', description: 'Add SaaS and other services you depend on' }, { key: 'components', name: 'Apps, DBs, Networks', description: 'Add apps, databases, and networks you control' }, { key: 'systems', name: 'Systems', description: 'Identify backends, logging destinations, and data storage systems' }, { key: 'predisposing-conditions', name: 'Predisposing Conditions', description: 'Conditions that predispose you to certain risks' }, { key: 'security-controls', name: 'Security Controls', description: 'Configure organizational security controls' }, { key: 'finish', name: 'Finish' }];

  exports.STEPS = STEPS;
  var SETUP_STEPS = STEPS.mapBy('key');
  exports.SETUP_STEPS = SETUP_STEPS;
  var FIRST_STEP = SETUP_STEPS[0];

  exports.FIRST_STEP = FIRST_STEP;

  function nextStep(step) {
    return SETUP_STEPS[indexOfStep(step) + 1];
  }

  function previousStep(step) {
    return SETUP_STEPS[indexOfStep(step) - 1];
  }

  function indexOfStep(step) {
    return SETUP_STEPS.indexOf(step);
  }
  function intersect(arr1, arr2) {
    var arr = arr1.filter(function (n) {
      return arr2.indexOf(n) !== -1;
    });
    return arr.length > 0;
  }

  var HIPAA_BA = 'hipaa_ba';
  exports.HIPAA_BA = HIPAA_BA;
  var GDPR = 'gdpr';
  exports.GDPR = GDPR;
  var SOC2 = 'soc2';
  exports.SOC2 = SOC2;
  var ISO27001 = 'iso27001';
  exports.ISO27001 = ISO27001;
  var HIPAA_CE = 'hipaa_ce';

  exports.HIPAA_CE = HIPAA_CE;
  var ALL_PROTOCOLS = [HIPAA_BA, GDPR, SOC2, ISO27001, HIPAA_CE];

  exports.ALL_PROTOCOLS = ALL_PROTOCOLS;
  var USER_DESIGNATIONS = {
    securityOfficer: {
      name: 'Security Officer',
      description: 'The primary individual responsible for the development, implementation, and monitoring of security policies and procedures. May be non-technical, but must work closely with technical roles.',
      role: 'securityRole', // Name of role attribute to auto-enroll users in this designation
      'for': ALL_PROTOCOLS
    },
    privacyOfficer: {
      name: 'Privacy Officer',
      description: 'The primary individual responsible for the development, implementation, and monitoring of privacy policies and procedures.',
      'for': ALL_PROTOCOLS
    },
    // approvingAuthority: {
    //   name: 'Approving Authority',
    //   description: 'A member of the management team who is permitted to make security and privacy authorization decisions, such as approving risk assessments and policies.'
    // },
    incidentResponseLead: {
      name: 'Incident Response Team Leader',
      description: 'The primary individual responsible for leading privacy and security incident response.',
      role: 'incidentResponseRole', // Name of role attribute to auto-enroll users in this designation
      'for': ALL_PROTOCOLS
    },
    incidentResponseLegal: {
      name: 'Incident Response (Legal)',
      description: 'A member of your incident response team responsible for legal issues and coordination.',
      role: 'incidentResponseRole', // Name of role attribute to auto-enroll users in this designation
      'for': ALL_PROTOCOLS
    },
    incidentResponsePr: {
      name: 'Incident Response (PR)',
      description: 'A member of your incident response team responsible for speaking with the media.',
      role: 'incidentResponseRole', // Name of role attribute to auto-enroll users in this designation
      'for': ALL_PROTOCOLS
    },
    incidentResponseManagement: {
      name: 'Incident Response (Management)',
      description: 'A member of your incident response team responsible for internal coordination.',
      role: 'incidentResponseRole', // Name of role attribute to auto-enroll users in this designation
      'for': ALL_PROTOCOLS
    },
    opsLead: {
      name: 'Ops Team Leader',
      description: 'Leads the Ops Team, which is responsible for day-to-day operation of your information systems, and for business continuity/disaster recovery operations.',
      role: 'opsRole', // Name of role attribute to auto-enroll users in this designation
      'for': ALL_PROTOCOLS
    },
    dataProtectionOfficer: {
      name: 'Data Protection Officer',
      description: 'The individual designated as your GDPR data protection officer.',
      'for': [GDPR]
    }
  };

  exports.USER_DESIGNATIONS = USER_DESIGNATIONS;
  var ROLE_DESIGNATIONS = {
    gridironOwnersRole: {
      name: 'Gridiron Owners',
      description: 'Responsible for owning and maintaining Information Security Management System (ISMS).'
    },
    managementRole: {
      name: 'Management Team',
      description: 'Executives and managers responsible for promoting, overseeing, and enforcing adherence to {{shortName}} privacy and security policies.'
    },
    incidentResponseRole: {
      name: 'Incident Response Team',
      description: 'Responsible for investigating and resolving suspected or actual security and privacy incidents. Membership commonly overlaps with the Security, Ops, and Dev teams.'
      //protectedDesignations: ['incidentResponseLegal', 'incidentResponsePr', 'incidentResponseManagement', 'incidentResponseLead']
    },
    securityRole: {
      name: 'Security Team',
      description: 'Helps the Security Officer develop, implement, and monitor security controls and policies. Membership commonly overlaps withe the Incident Response, Dev, and Ops teams.'
    },
    opsRole: {
      name: 'Ops Team',
      description: 'Responsible for the day-to-day operations of the information systems. Has primary responsibility for technical business continuity operations. Membership commonly overlaps with the Incident Response, Dev, and Security Teams.'
      //protectedDesignations: ['opsLead']
    },
    devRole: {
      name: 'Dev Team',
      description: 'Responsible for software development. Membership commonly overlaps with the Incident Response, Ops, and Security Teams.'
    },
    customerSupportRole: {
      name: 'Customer Support Team',
      description: 'Responsible for interacting with customers and resolving technical or account issues.'
    },
    legalRole: {
      name: 'Legal Team',
      description: 'Performs legal and human resources functions, such as contract review, onboarding and offboarding, and disciplinary action for workforce members involved in security and privacy incidents.'
    }
  };

  exports.ROLE_DESIGNATIONS = ROLE_DESIGNATIONS;
  var AVAILABLE_REPORTS = ['hipaaSecurityReport', 'assetInventoryReport', 'trainingHistoryReport', 'auditLogReport', 'businessContinuityReport'];

  exports.AVAILABLE_REPORTS = AVAILABLE_REPORTS;
  var RiskyDocumentBuilderMixin = _ember['default'].Mixin.create(_dieselMixinsModelsRiskScope['default'], _dieselMixinsModelsDocumentBuild['default']);
  var OrganizationProfile = _dieselModelsBase['default'].extend(RiskyDocumentBuilderMixin, {
    hipaaProtocolDescription: "Business Associate", //TODO: business associate/covered entity
    stepConfiguration: STEPS.slice(0),
    currentStep: _emberData['default'].attr('string', { defaultValue: function defaultValue() {
        return SETUP_STEPS[0];
      } }),
    hasCompletedSetup: _emberData['default'].attr('boolean', { defaultValue: false }),
    aboutOrganization: _emberData['default'].attr('string'),
    aboutProduct: _emberData['default'].attr('string'),
    aboutArchitecture: _emberData['default'].attr('string'),
    aboutBusinessModel: _emberData['default'].attr('string'),
    aboutTeam: _emberData['default'].attr('string'),
    aboutGoToMarket: _emberData['default'].attr('string'),
    features: _emberData['default'].attr(),
    legalContactName: _emberData['default'].attr('string'),
    legalContactFirm: _emberData['default'].attr('string'),
    legalContactEmail: _emberData['default'].attr('string'),
    legalContactPhone: _emberData['default'].attr('string'),
    organization: _emberData['default'].attr('string'),
    shortName: _emberData['default'].attr('string'),
    longName: _emberData['default'].attr('string'),
    remoteWorkforce: _emberData['default'].attr('boolean', { defaultValue: false }),
    protocols: _emberData['default'].attr(), // Array of strings
    complianceOsProtocol: _emberData['default'].attr('string'),

    reportHrefs: _ember['default'].computed(function () {
      var _this = this;

      var reports = {};

      AVAILABLE_REPORTS.forEach(function (r) {
        var href = _this.get('links.' + decamelize(r));
        if (href) {
          reports[r] = href;
        }
      });

      return reports;
    }),

    reportsReady: _ember['default'].computed('reportHrefs', function () {
      return Object.keys(this.get('reportHrefs'));
    }),

    hasReports: _ember['default'].computed.gt('reportsReady.length', 0),

    notificationProcedures: _emberData['default'].attr('string'),
    proceduresReferenceTitle: _emberData['default'].attr('string'),
    proceduresReferenceUrl: _emberData['default'].attr('string'),

    attestations: _emberData['default'].hasMany('attestation', { async: true }),
    courses: _emberData['default'].hasMany('course', { async: true }),
    currentRiskAssessment: _emberData['default'].belongsTo('risk-assessment', { async: true }),
    draftRiskAssessment: _emberData['default'].belongsTo('risk-assessment', { async: true }),
    riskAssessments: _emberData['default'].hasMany('risk-assessment', { async: true }),
    currentPolicyManual: _emberData['default'].belongsTo('policy-manual', { async: true }),
    draftPolicyManual: _emberData['default'].belongsTo('policy-manual', { async: true }),
    policyManuals: _emberData['default'].hasMany('policy-manual', { async: true }),
    assignments: _emberData['default'].hasMany('assignment', { async: true }),
    systems: _emberData['default'].hasMany('system', { async: true }),
    userProfiles: _emberData['default'].hasMany('user-profile', { async: true }),
    components: _emberData['default'].hasMany('component', { async: true }),
    currentSecurityReview: _emberData['default'].belongsTo('security-review', { async: true }),
    completedSecurityReviews: _emberData['default'].hasMany('security-review', { async: true }),
    customers: _emberData['default'].hasMany('customer', { async: true }),
    incidents: _emberData['default'].hasMany('incident', { async: true }),
    vendors: _emberData['default'].hasMany('vendor', { async: true }),
    locations: _emberData['default'].hasMany('location', { async: true }),

    previousStep: _ember['default'].computed('currentStep', function () {
      return previousStep(this.get('currentStep'));
    }),

    nextStep: _ember['default'].computed('currentStep', function () {
      return nextStep(this.get('currentStep'));
    }),

    workforceDesignations: _ember['default'].computed('protocols', function () {
      var _this2 = this;

      var filteredWorkforceDesignations = {};
      Object.keys(USER_DESIGNATIONS).forEach(function (key) {
        if (intersect(_this2.get('protocols'), USER_DESIGNATIONS[key]['for'])) {
          filteredWorkforceDesignations[key] = USER_DESIGNATIONS[key];
        }
      });
      return filteredWorkforceDesignations;
    }),

    isReadyForStep: function isReadyForStep(step) {
      return indexOfStep(step) <= indexOfStep(this.get('currentStep'));
    },

    next: function next(currentStep) {
      this.set('currentStep', nextStep(currentStep));
      return this;
    },

    previous: function previous(currentStep) {
      this.set('currentStep', previousStep(currentStep));
      return this;
    },

    findOrCreateSystem: function findOrCreateSystem(systemTemplate) {
      var _this3 = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _this3.get('systems').then(function (systems) {
          var system = systems.findBy('templateHandle', systemTemplate.get('handle'));
          if (system) {
            resolve(system);
          } else {
            _this3.get('opsRole').then(function () {
              system = systemTemplate.createSystem(_this3, _this3.store);
              system.save().then(function (system) {
                resolve(system);
              }, reject);
            });
          }
        }, reject);
      });
    },

    addDesignationToRole: function addDesignationToRole(user, roleAttributeName) {
      var _this4 = this;

      // roleAttributeName is the name of one of the org profiles special
      // role designations.  This method makes it easy to quickly add a user to
      // the given role designation
      var role = undefined,
          membership = undefined;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _this4.get(roleAttributeName).then(function (roleDesignation) {
          var userUrl = user.get('links.self');
          role = roleDesignation;
          membership = _this4.store.createRecord('membership', { role: role, user: user, userUrl: userUrl });
          return membership.save();
        }).then(function (membership) {
          resolve({ user: user, role: role, membership: membership });
        })['catch'](function (e) {
          membership.rollbackAttributes();
          reject(e);
        });
      });
    },

    reloadCurrentSecurityReview: function reloadCurrentSecurityReview() {
      var _this5 = this;

      // When the current security review is completed, Gridiron will build a new
      // security review and set it as the new current security review.
      if (!this.get('links.current_security_review')) {
        // Nothing to reload if there is no current security review...though this
        // is an unusual state
        return;
      }

      if (this.get('currentSecurityReview') && this.get('currentSecurityReview.content')) {
        this.get('currentSecurityReview.content').unloadRecord();
      }

      this.set('currentSecurityReview', null);
      var organizationProfile = this;

      return this.reload().then(function (op) {
        organizationProfile = op;
        var securityReviewId = organizationProfile.get('links.current_security_review').split('/').reverse()[0];

        return _this5.store.find('security-review', securityReviewId);
      }).then(function (newCurrentSecurityReview) {
        organizationProfile.set('currentSecurityReview', newCurrentSecurityReview);
      });
    }
  });

  /**
   * Add attributes to the OrganizationProfile model for each of the possible
   * decomposed User attrs so that we can send these to the
   * backend. Loops through the given workforceDesignation names and adds DS attrs
   * for each name+attribute combo (e.g. workforce designation approvingAuthority ->
   * attrs for approvingAuthorityName, approvingAuthorityEmail, etc.).
   * Adds a computed setter for the workforce designation itself (e.g. `set('approvingAuthority', user)`)
   * that decomposes the user into the expected attrs and sets those on the OrganizationProfile.
   */
  var capitalize = _ember['default'].String.capitalize;

  Object.keys(USER_DESIGNATIONS).forEach(function (designation) {
    var userAttrs = ['name', 'email', 'url'];
    var designationAttrs = userAttrs.map(function (attr) {
      return '' + designation + capitalize(attr);
    });

    designationAttrs.forEach(function (attr) {
      OrganizationProfile.reopen(_defineProperty({}, attr, _emberData['default'].attr('string')));
    });

    OrganizationProfile.reopen(_defineProperty({}, designation, _ember['default'].computed({
      set: function set(designation, user) {
        var userProperties = user.getProperties.apply(user, userAttrs);
        var designationProperties = {};
        userAttrs.forEach(function (attr) {
          var key = '' + designation + capitalize(attr);
          var val = userProperties[attr];
          designationProperties[key] = val;
        });

        this.setProperties(designationProperties);
      }
    })));
  });

  Object.keys(ROLE_DESIGNATIONS).forEach(function (roleDesignation) {
    OrganizationProfile.reopen(_defineProperty({}, roleDesignation, _emberData['default'].belongsTo('role', { async: true })));
  });

  OrganizationProfile.reopenClass({
    _createFromOrganization: function _createFromOrganization(organization, store) {
      var newProfileParams = { id: organization.get('id'),
        organization: organization.get('links.self') };
      return store.createRecord('organization-profile', newProfileParams);
    },

    findOrCreate: function findOrCreate(organization, store) {
      var _this6 = this;

      return new _ember['default'].RSVP.Promise(function (resolve) {
        store.find('organization-profile', organization.get('id')).then(function (profile) {
          resolve(profile);
        })['catch'](function (e) {
          // This covers the case where we have an org, but the first step of
          // SPD has not been completed.
          if (e.status && e.status === 404) {
            resolve(_this6._createFromOrganization(organization, store));
          } else {
            throw e;
          }
        });
      });
    }
  });

  exports['default'] = OrganizationProfile;
});