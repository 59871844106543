define('diesel/models/payment-closure', ['exports', 'ember', 'ember-data', 'diesel/models/base'], function (exports, _ember, _emberData, _dieselModelsBase) {
  exports['default'] = _dieselModelsBase['default'].extend({
    payment: _emberData['default'].belongsTo('payment', { async: true }),
    createdAt: _emberData['default'].attr('iso-8601-timestamp'),
    success: _emberData['default'].attr('boolean'),
    message: _emberData['default'].attr('string'),

    messageOrNa: _ember['default'].computed('message', function () {
      return this.get('message') || 'N/A';
    })
  });
});