define('diesel/components/payment-method-actions/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'span',

    disabled: false,
    billingDetail: null,
    paymentMethod: null,

    cannotMakeDefault: _ember['default'].computed('disabled', 'paymentMethod.isBankAccount', 'paymentMethod.isVerified', function () {
      if (this.get('disabled')) {
        return true;
      }

      if (this.get('paymentMethod.isBankAccount') && !this.get('paymentMethod.isVerified')) {
        return true;
      }

      return false;
    }),

    cannotDeactivate: _ember['default'].computed('disabled', 'paymentMethod.typeCanDeactivate', function () {
      if (this.get('disabled')) {
        return true;
      }

      if (!this.get('paymentMethod.typeCanDeactivate')) {
        return true;
      }

      return false;
    }),

    actions: {
      makeDefault: function makeDefault(paymentMethod) {
        this.sendAction('makeDefault', paymentMethod);
      },

      deactivate: function deactivate(paymentMethod) {
        this.sendAction('deactivate', paymentMethod);
      },

      openVerifyBankAccountModal: function openVerifyBankAccountModal(paymentMethod) {
        this.sendAction('openVerifyBankAccountModal', paymentMethod);
      }
    }
  });
});