define('diesel/enclave-wrapper/route', ['exports', 'ember', 'diesel/utils/location'], function (exports, _ember, _dieselUtilsLocation) {
  exports['default'] = _ember['default'].Route.extend({
    modalDisplay: _ember['default'].inject.service(),

    actions: {
      onOrgSwitch: function onOrgSwitch(organization) {
        var message = 'Switched to ' + organization.get('name');
        _ember['default'].get(this, 'flashMessages').success(message);
        return this.authorization.load().then(function () {
          return _dieselUtilsLocation['default'].replaceAndWait('/');
        });
      },

      reauthenticate: function reauthenticate() {
        var _this = this;

        //This is from logout/route.js
        return this.session.close("application", {
          token: this.get("session.token")
        })['catch'](function (e) {
          // e will be a jqXHR error object (not a DS.AdapterError)
          // because the application torii adapter makes a jQuery ajax call
          // to close the session
          if (e.responseJSON && e.responseJSON.error === "expired_token") {
            // If the user's token has expired, then we don't care to log them
            // out "further".
            return;
          }
          throw e;
        }).then(function () {
          return _this.get("analytics").logout();
        }).then(function () {
          return _dieselUtilsLocation['default'].replaceAndWait("/");
        })['catch'](function (e) {
          var message = e.responseJSON && e.responseJSON.message || e.message || "An unexpected error occurred.";
          _ember['default'].get(_this, "flashMessages").danger(message);
        });
      },

      onNewOrganizationAccount: function onNewOrganizationAccount(requestedStack) {
        var _this2 = this;

        var stackOrganizationPromise = requestedStack ? requestedStack.get('organization') : _ember['default'].RSVP.resolve(null);

        return stackOrganizationPromise.then(function (stackOrganization) {
          var newAccount = _this2.store.createRecord('account');
          var stack = requestedStack;

          var organization = _this2.get('authorization.currentOrganization');

          var type = stackOrganization ? 'production' : 'development';
          var handle = '';

          var modalDisplay = _this2.get('modalDisplay');
          modalDisplay.showModal('modal-create-account', {
            createAccountModel: { newAccount: newAccount, organization: organization, stack: stack, type: type, handle: handle }
          }, {
            actions: {
              accountCreated: modalDisplay.modalAction(_this2, 'onAccountCreated')
            }
          });
        });
      },

      onAccountCreated: function onAccountCreated(account) {
        var _this3 = this;

        var message = 'Environment created: ' + account.get('handle');
        _ember['default'].get(this, 'flashMessages').success(message);
        return this.authorization.load().then(function () {
          return _this3.transitionTo('account', account);
        });
      }
    }
  });
});