define("diesel/components/domain-input/component", ["exports", "ember"], function (exports, _ember) {

  // Intentionally permissive. We don't care about validation as much as
  // preventing obvious mistakes.
  var BASIC_DOMAIN_REGEXP = /^(\*\.)?[a-z0-9\-.]+\.[a-z]+$/i;

  exports.BASIC_DOMAIN_REGEXP = BASIC_DOMAIN_REGEXP;
  exports["default"] = _ember["default"].Component.extend({
    domain: null,
    valid: null,
    tip: null,

    domainValid: _ember["default"].computed("domain", function () {
      return BASIC_DOMAIN_REGEXP.test(this.get("domain"));
    }),

    errorClass: _ember["default"].computed("domainValid", function () {
      if (this.get("domainValid")) {
        return "";
      }
      return "has-error";
    }),

    domainValidObserver: (function () {
      this.set("valid", this.get("domainValid"));
    }).observes("domainValid")
  });
});