define('diesel/components/modal-attachment/component', ['exports', 'ember', 'diesel/mixins/components/resizable-modal', 'diesel/mixins/components/modal-base'], function (exports, _ember, _dieselMixinsComponentsResizableModal, _dieselMixinsComponentsModalBase) {

  var ERROR_MESSAGE = 'There was an error saving the agreement';

  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsResizableModal['default'], _dieselMixinsComponentsModalBase['default'], {
    store: _ember['default'].inject.service(),
    attachmentModal: null,
    errors: null,

    init: function init() {
      this._super.apply(this, arguments);

      var _get = this.get('attachmentModal');

      var agreement = _get.agreement;

      var store = this.get('store');
      var newAttachment = store.createRecord('attachment');

      var hasAttachments = agreement.get('attachments.length');
      var title = (hasAttachments ? 'Edit' : 'Add') + ' \'' + agreement.get('name') + '\' Attachments';
      var description = agreement.get('owner.name');

      this.setProperties({ agreement: agreement, description: description, newAttachment: newAttachment, title: title });
    },

    actions: {
      remove: function remove(attachment) {
        var _this = this;

        var message = 'Are you sure you want to delete ' + attachment.get('fileDisplayName') + '?';

        if (!confirm(message)) {
          return;
        }

        return attachment.destroyRecord()['catch'](function (e) {
          _this.set('errors', [e.message || ERROR_MESSAGE]);
        });
      },

      saveImmediately: function saveImmediately(attachment) {
        var _this2 = this;

        var agreement = this.get('agreement');
        attachment.set('agreement', agreement);
        var file = attachment.get('file');
        return attachment.save().then(function (attachment) {
          var s3 = attachment.get('s3Credentials');

          if (file && s3) {
            return file.upload(s3.url, {
              data: s3.credentials,
              headers: { 'x-amz-server-side-encryption': 'AES256' }
            });
          }
        }).then(function () {
          _this2.set('newAttachment', _this2.get('store').createRecord('attachment'));
        })['catch'](function (e) {
          attachment.set('agreement', null);
          _this2.set('errors', [e.message || ERROR_MESSAGE]);
        });
      }
    }
  });
});