define('diesel/components/request-policy-review/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    approvalPath: null,
    showComplyGRCManual: null,
    showComplianceOSManual: null,
    showClassicManual: null,
    classicPolicyManual: null,
    sentence: 'Before completing this training, please take a moment to review our company',
    cta: 'policies and procedures',
    policyCta: 'policies',
    proceduresCta: 'procedures'
  });
});