define('diesel/gridiron/policy-editor/node/controller', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Controller.extend({
    nodeAtPath: computed('model.{path,editor,document}', function () {
      return this.get('model.editor').getNode(this.get('model.path'));
    }),

    actions: {
      undo: function undo() {
        this.get('model.editor').undo();
      },

      redo: function redo() {
        this.get('model.editor').redo();
      }
    }
  });
});