define('diesel/database/metrics/route', ['exports', 'ember', 'diesel/mixins/routes/service-metrics'], function (exports, _ember, _dieselMixinsRoutesServiceMetrics) {
  exports['default'] = _ember['default'].Route.extend(_dieselMixinsRoutesServiceMetrics['default'], {
    getResource: function getResource() {
      return this.modelFor('database');
    },

    getService: function getService() {
      return this.getResource().get('service');
    }
  });
});