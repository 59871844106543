define('diesel/models/risk-assessment', ['exports', 'ember', 'ember-data', 'diesel/models/base', 'diesel/mixins/models/approvable-document'], function (exports, _ember, _emberData, _dieselModelsBase, _dieselMixinsModelsApprovableDocument) {

  function sortBySeverity(threatEvents) {
    return threatEvents.sortBy('riskLevel', 'impact', 'relevance', 'overallLikelihood');
  }

  // DEPRECATE THESE

  exports['default'] = _dieselModelsBase['default'].extend(_dieselMixinsModelsApprovableDocument['default'], {
    status: _emberData['default'].attr('string', { defaultvalue: 'draft' }),
    approvingAuthorityUserName: _emberData['default'].attr('string'),
    approvingAuthorityUserEmail: _emberData['default'].attr('string'),
    approvingAuthorityUrl: _emberData['default'].attr('string'),
    createdByUserName: _emberData['default'].attr('string'),
    createdByUserEmail: _emberData['default'].attr('string'),
    createdByUrl: _emberData['default'].attr('string'),
    createdAt: _emberData['default'].attr('iso-8601-timestamp'),

    attestations: _emberData['default'].hasMany('attestations', {
      async: false
    }),
    vulnerabilities: _emberData['default'].hasMany('vulnerability', {
      async: false
    }),
    threatEvents: _emberData['default'].hasMany('threat-event', {
      async: false
    }),
    predisposingConditions: _emberData['default'].hasMany('predisposing-condition', {
      async: false
    }),
    threatSources: _emberData['default'].hasMany('threat-source', {
      async: false
    }),
    securityControls: _emberData['default'].hasMany('security-control', {
      async: false
    }),
    securityControlCategories: _emberData['default'].hasMany('security-control-category', {
      async: false
    }),
    mitigations: _emberData['default'].hasMany('mitigation', {
      async: false
    }),
    organizationProfile: _emberData['default'].belongsTo('organization-profile', { async: true, inverse: 'riskAssessments' }),

    relevantThreatEvents: _ember['default'].computed.filterBy('threatEvents', 'relevant', true),
    maximumRisk: _ember['default'].computed('relevantThreatEvents.[]', function () {
      return sortBySeverity(this.get('relevantThreatEvents')).get('lastObject');
    }),

    maximumRisks: _ember['default'].computed('relevantThreatEvents.[]', function () {
      var max = this.get('maximumRisk.riskLevel');
      return sortBySeverity(this.get('relevantThreatEvents').filterBy('riskLevel', max));
    }),

    veryHighRisks: _ember['default'].computed.filterBy('relevantThreatEvents', 'riskLevel', 5),
    highRisks: _ember['default'].computed.filterBy('relevantThreatEvents', 'riskLevel', 4),
    mediumRisks: _ember['default'].computed.filterBy('relevantThreatEvents', 'riskLevel', 3),
    lowRisks: _ember['default'].computed.filterBy('relevantThreatEvents', 'riskLevel', 2),
    veryLowRisks: _ember['default'].computed.filterBy('relevantThreatEvents', 'riskLevel', 1),

    isDraft: _ember['default'].computed.equal('status', 'draft'),
    isCurrent: _ember['default'].computed.equal('status', 'current'),
    isArchived: _ember['default'].computed.equal('status', 'archive')
  });
});