define('diesel/helpers/plural-string', ['exports', 'ember'], function (exports, _ember) {
  exports.pluralString = pluralString;

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  // NOTE: {{plural}} is already a built-in Ember helper and
  // we cannot override it here, so this helper is called `plural-string`
  // instead.

  function pluralString(input, count) {
    if (count === 1) {
      return input;
    }
    return _ember['default'].String.pluralize(input);
  }

  exports['default'] = _ember['default'].Helper.helper(function (params) {
    return pluralString.apply(undefined, _toConsumableArray(params));
  });
});