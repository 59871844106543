define('diesel/mixins/controllers/third-party-services', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    searchValue: '',
    sortWebServicesBy: ['name:asc'],
    systemTemplates: null,
    availableSystemTemplates: _ember['default'].computed('systems.@each.templateHandle', 'systemTemplates.[]', function () {
      var systemFilter = this.get('filterSystemsBy') || 'isWebService';
      var systemTemplates = this.get('systemTemplates').filterBy(systemFilter);
      var systems = this.get('systems');

      // For each system template, look and see if there is an existing saas
      // system and stash it as a property on the template
      return systemTemplates.map(function (st) {
        st.system = systems.findBy('templateHandle', st.get('handle'));
        return st;
      });
    }),

    sortedAvailableSystemTemplates: _ember['default'].computed.sort('availableSystemTemplates', 'sortWebServicesBy'),
    filteredAvailableSystemTemplates: _ember['default'].computed('searchValue', 'sortedAvailableSystemTemplates', 'filteredInUseSaasSystems.[]', function () {
      var currentSearch = _ember['default'].$.trim(this.get('searchValue'));
      var templates = this.get('sortedAvailableSystemTemplates');

      // Reject templates that already have an associated system
      templates = templates.reject(function (t) {
        return t.system;
      });

      if (!currentSearch) {
        return templates;
      }

      currentSearch = new RegExp(currentSearch.toLowerCase().replace(/\W/g, ''));
      return templates.filter(function (template) {
        var _template$getProperties = template.getProperties('name', 'vendor');

        var name = _template$getProperties.name;
        var vendor = _template$getProperties.vendor;

        var keywords = (name + vendor).toLowerCase().replace(/\W/g, '');

        return currentSearch.test(keywords);
      });
    }),

    sortedSaasSystems: _ember['default'].computed.sort('inUseSystems', 'sortWebServicesBy'),
    filteredInUseSaasSystems: _ember['default'].computed('searchValue', 'systems.[]', function () {
      var currentSearch = _ember['default'].$.trim(this.get('searchValue'));
      var systems = this.get('sortedSaasSystems');

      if (!currentSearch) {
        return systems;
      }

      currentSearch = new RegExp(currentSearch.toLowerCase().replace(/\W/g, ''));

      return systems.filter(function (system) {
        var _system$getProperties = system.getProperties('name', 'vendor');

        var name = _system$getProperties.name;
        var vendor = _system$getProperties.vendor;

        var keywords = (name + vendor).toLowerCase().replace(/\W/g, '');
        return currentSearch.test(keywords);
      });
    })
  });
});