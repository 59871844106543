define('diesel/gridiron/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    title: function title() {
      var organization = this.get('complianceStatus.organization.name');
      return 'Gridiron Admin - ' + organization;
    },

    redirect: function redirect(authorization) {
      if (!authorization.get('hasAnyOrganizationsWithGridironProduct')) {
        this.transitionTo('enclave');
      }
    }
  });
});