define("diesel/components/c-control-ignored-resources-list/component", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    tagName: "",
    resources: null,

    actions: {
      removeIgnore: function removeIgnore(resource) {
        this.attrs.onRemoveIgnore(resource);
      }
    }
  });
});