define('diesel/components/list-vhosts/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    //sortBy: ['handle:asc'],

    arrayChanged: (function () {
      _ember['default'].run.once(this, 'checkModelArray');
    }).observes('model.[]'),

    checkModelArray: function checkModelArray() {
      if (this.get('model').get('length') === 0) {
        this.sendAction('redirect');
      }
    },

    provisionedDomains: _ember['default'].computed.filterBy('model', 'isProvisioned'),

    activeDomains: _ember['default'].computed.filterBy('provisionedDomains', 'hasActionRequired', false),
    actionRequiredDomains: _ember['default'].computed.filterBy('provisionedDomains', 'hasActionRequired'),
    pendingDomains: _ember['default'].computed.filterBy('model', 'isProvisioning'),
    deprovisioningDomains: _ember['default'].computed.filterBy('model', 'isDeprovisioning'),
    deprovisionedDomains: _ember['default'].computed.filterBy('model', 'hasBeenDeprovisioned'),

    hasActive: _ember['default'].computed.gt('activeDomains.length', 0),
    hasActionRequired: _ember['default'].computed.gt('actionRequiredDomains.length', 0),
    failedProvisionDomains: _ember['default'].computed.filterBy('model', 'hasFailedProvision'),
    failedDeprovisionDomains: _ember['default'].computed.filterBy('model', 'hasFailedDeprovision'),
    hasPending: _ember['default'].computed.gt('pendingDomains.length', 0),
    hasDeprovisioning: _ember['default'].computed.gt('deprovisioningDomains.length', 0),
    hasDeprovisioned: _ember['default'].computed.gt('deprovisionedDomains.length', 0),

    hasFailedProvision: _ember['default'].computed.gt('failedProvisionDomains.length', 0),
    hasFailedDeprovision: _ember['default'].computed.gt('failedDeprovisionDomains.length', 0),
    showSortableHeader: _ember['default'].computed.or('hasPending', 'hasDeprovisioning', 'hasFailedDeprovision', 'hasFailedProvision'),

    actions: {
      redirect: function redirect() {
        this.sendAction('forceRedirect');
      },
      startDeletion: function startDeletion() {
        this.sendAction('startDeletion');
      },
      failDeletion: function failDeletion() {
        this.sendAction('failDeletion');
      },
      completeDeletion: function completeDeletion() {
        this.sendAction('completeDeletion');
      },
      openUpgradeVhostModal: function openUpgradeVhostModal(vhost) {
        this.sendAction('openUpgradeVhostModal', vhost);
      }
    }
  });
});