define('diesel/utils/stripe', ['exports', 'jquery', 'ember', 'diesel/config/environment'], function (exports, _jquery, _ember, _dieselConfigEnvironment) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var loadPromise;

  function loadStripe() {
    if (!loadPromise) {
      if (window.Stripe) {
        loadPromise = _ember['default'].RSVP.resolve();
      } else {
        loadPromise = new _ember['default'].RSVP.Promise(function (resolve, reject) {
          _jquery['default'].getScript('https://js.stripe.com/v2/').then(_ember['default'].run.bind(null, resolve), _ember['default'].run.bind(null, reject));
        }).then(function () {
          Stripe.setPublishableKey(_dieselConfigEnvironment['default'].stripePublishableKey);
        });
      }
    }

    return loadPromise;
  }

  var identity = function identity(x) {
    return x;
  };

  var CreditCardSpec = {
    module: 'card',
    fields: {
      name: {},
      number: {},
      cvc: {},
      zip: {
        name: 'address_zip'
      },
      expMonth: {
        name: 'exp_month',
        transform: parseInt
      },
      expYear: {
        name: 'exp_year',
        transform: parseInt
      }
    }
  };

  var BankAccountSpec = {
    module: 'bankAccount',
    fields: {
      country: {},
      currency: {},
      routingNumber: {
        name: 'routing_number'
      },
      accountNumber: {
        name: 'account_number'
      },
      accountHolderName: {
        name: 'account_holder_name'
      },
      accountHolderType: {
        name: 'account_holder_type'
      }
    }
  };

  function createStripeToken(stripeSpec, basePayload) {
    var stripePayload = {};

    Object.entries(stripeSpec.fields).forEach(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2);

      var baseField = _ref2[0];
      var fieldSpec = _ref2[1];

      var baseValue = basePayload[baseField];

      var stripeField = fieldSpec.name || baseField;
      var stripeValue = (fieldSpec.transform || identity)(baseValue);

      stripePayload[stripeField] = stripeValue;
    });

    return loadStripe().then(function () {
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        Stripe[stripeSpec.module].createToken(stripePayload, function (status, result) {
          if (result.error) {
            _ember['default'].run(null, reject, result.error);
          } else {
            _ember['default'].run(null, resolve, result);
          }
        });
      });
    });
  }

  exports.loadStripe = loadStripe;
  exports.createStripeToken = createStripeToken;
  exports.CreditCardSpec = CreditCardSpec;
  exports.BankAccountSpec = BankAccountSpec;
});
/* globals Stripe */