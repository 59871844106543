define('diesel/components/policy-editor/node/component', ['exports', 'diesel/components/policy-editor/-utils/document-schema', 'ember'], function (exports, _dieselComponentsPolicyEditorUtilsDocumentSchema, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({
    path: undefined,
    isRoot: computed.not('path.length'),
    types: [_dieselComponentsPolicyEditorUtilsDocumentSchema.SECTION, _dieselComponentsPolicyEditorUtilsDocumentSchema.LIST, _dieselComponentsPolicyEditorUtilsDocumentSchema.GROUP],

    childrenMessage: computed('node', function () {
      var count = this.get('node.children.length');
      var children = count === 1 ? 'child' : 'children';

      return count + ' ' + children;
    }),

    validFields: computed('node', function () {
      return (0, _dieselComponentsPolicyEditorUtilsDocumentSchema.fieldsForType)(this.get('node.type') || 'root');
    }),

    linkPath: computed('path', function () {
      return this.get('path').join('-');
    }),

    actions: {
      addChildGroup: function addChildGroup() {
        this.get('editor').appendChild(this.get('path'), { type: 'group' });
      },

      addChildSection: function addChildSection() {
        this.get('editor').appendChild(this.get('path'), { type: 'section' });
      },

      removeNode: function removeNode() {
        this.get('editor').removeNode(this.get('path'));
      },

      moveNodeUp: function moveNodeUp() {
        this.get('editor').moveNode(this.get('path'), -1);
      },

      moveNodeDown: function moveNodeDown() {
        this.get('editor').moveNode(this.get('path'), 1);
      },

      set: function set(key, value) {
        if (value instanceof Event || value instanceof $.Event) {
          value = value.target.value;
        }

        this.get('editor').set(this.get('path'), key, value);
      }
    }
  });
});