define('diesel/initializers/operations-manager-service', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(application) {
    application.inject('route', 'operationsManager', 'service:operations-manager');
  }

  exports['default'] = {
    name: 'operations-manager-service',
    initialize: initialize
  };
});