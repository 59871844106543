define('diesel/asset/dependencies/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var asset = this.modelFor('asset');
      var organizationProfile = this.get('complianceStatus.organizationProfile');

      var _asset$getProperties = asset.getProperties('componentDependencies', 'systemDependencies', 'primarySystem');

      var componentDependencies = _asset$getProperties.componentDependencies;
      var systemDependencies = _asset$getProperties.systemDependencies;
      var primarySystem = _asset$getProperties.primarySystem;

      return _ember['default'].RSVP.hash({
        asset: asset,
        componentDependencies: componentDependencies,
        systemDependencies: systemDependencies,
        primarySystem: primarySystem,
        organizationProfile: organizationProfile,
        components: organizationProfile.get('components'),
        systems: organizationProfile.get('systems'),
        systemTemplates: this.store.findAll('system-template')
      });
    },

    setupController: function setupController(controller, model) {
      controller.setProperties(model);
      controller.setProperties({
        apps: model.componentDependencies.filterBy('dependsOnComponent.isApp'),
        networks: model.componentDependencies.filterBy('dependsOnComponent.type', 'network'),
        databases: model.componentDependencies.filterBy('dependsOnComponent.type', 'database'),
        storageDependencies: model.systemDependencies.filterBy('system.type', 'storage'),
        loggingDependencies: model.systemDependencies.filterBy('system.type', 'logging'),
        saasDependencies: model.systemDependencies.filterBy('system.type', 'saas')
      });
    }
  });
});