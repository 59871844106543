define('diesel/utils/frame-buster', ['exports'], function (exports) {
  exports['default'] = bustFrames;
  //
  // Dashboard should not be allowed in frames on other sites
  //
  // Preferred method to address this would be setting an X-Frame-Options header,
  // but this is not currently supported by S3
  // https://forums.aws.amazon.com/thread.jspa?threadID=149569
  //

  function bustFrames() {
    if (top.location !== location) {
      var body = document.body;

      while (body.firstChild) {
        // NOTE: this doesn't really seem to work to remove the loading indicator
        // for some reason. Not sure why, but probably not big enough a deal to
        // bother fixing here.
        body.removeChild(body.firstChild);
      }

      var err = document.createElement('p');
      err.appendChild(document.createTextNode('\n      You are viewing the Aptible Dashboard in an iframe. This is insecure, and\n      exposes you to clickjacking. To resolve this issue, navigate to the\n      following URL directly via your browser\'s URL bar:\n      ' + document.location.href + '\n    '));

      body.appendChild(err);

      // Tentatively try to bust the frame. This may or may not work depending on
      // how elaborate the framing is.
      // See: https://stackoverflow.com/questions/958997
      top.location.href = document.location.href;

      // Blow up the app.
      throw new Error('Framed!');
    }
  }
});