define('diesel/settings/requires-elevation/ssh/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    sortBy: ['name:asc'],
    error: null,
    newKey: null,

    // do not show the key that the user is in the progress of creating
    validKeys: (function () {
      return this.get('model').filter(function (key) {
        return key.get('isLoaded') && !key.get('hasDirtyAttributes');
      });
    }).property('model.@each.isLoaded', 'model.@each.hasDirtyAttributes'),

    sortedKeys: _ember['default'].computed.sort('validKeys', 'sortBy')
  });
});