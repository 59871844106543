define('diesel/mixins/routes/approvable-document-mixin', ['exports', 'ember', 'diesel/models/user-profile'], function (exports, _ember, _dieselModelsUserProfile) {
  exports['default'] = _ember['default'].Mixin.create({
    modalDisplay: _ember['default'].inject.service(),

    // We want to render the current user's title, so we need to load their user
    // profile or create it if it doesn't exist.
    afterModel: function afterModel() {
      var _this = this;

      var currentUser = this.get('session.currentUser');
      var organizationProfile = this.get('complianceStatus.organizationProfile');

      return new _ember['default'].RSVP.Promise(function (resolve) {
        _dieselModelsUserProfile['default'].findOrCreate(currentUser, organizationProfile, _this.store).then(function (userProfile) {
          _this._currentUserProfile = userProfile;
          resolve(userProfile);
        });
      });
    },

    actions: {
      approveDocument: function approveDocument(approvalDocument) {
        var currentUser = this.get('session.currentUser');
        var organizationProfile = this.get('complianceStatus.organizationProfile');
        var type = this.controller.get('documentType');
        var currentUserProfile = this._currentUserProfile;
        var approvalDocumentParams = {
          type: type, approvalDocument: approvalDocument, currentUser: currentUser, currentUserProfile: currentUserProfile, organizationProfile: organizationProfile
        };
        var modalDisplay = this.get('modalDisplay');
        modalDisplay.showModal('modal-approve-document', { approvalDocumentParams: approvalDocumentParams }, {
          actions: {
            onDocumentApproval: modalDisplay.modalAction(this, 'onDocumentApproval'),
            onApprovalError: modalDisplay.modalAction(this, 'onApprovalError')
          }
        });
      },

      onApprovalError: function onApprovalError(error) {
        this.get('flashMessages').danger('Your draft document could not be approved. (Error ' + error.status + ')');
        return error; // keep modal from being dismissed
      },

      onDocumentApproval: function onDocumentApproval() /*document*/{
        this.refresh();
        var message = 'Document approved!';
        this.get('flashMessages').success(message);
      }
    }
  });
});