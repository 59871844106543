define("diesel/models/vhost", ["exports", "ember", "ember-data", "diesel/models/base", "diesel/mixins/models/provisionable", "diesel/mixins/models/statuses"], function (exports, _ember, _emberData, _dieselModelsBase, _dieselMixinsModelsProvisionable, _dieselMixinsModelsStatuses) {
  exports["default"] = _dieselModelsBase["default"].extend(_dieselMixinsModelsProvisionable["default"], {
    externalHost: _emberData["default"].attr('string'),
    privateKey: _emberData["default"].attr('string'),
    certificateBody: _emberData["default"].attr('string'),
    type: _emberData["default"].attr('string', { defaultValue: 'http_proxy_protocol' }),
    isDefault: _emberData["default"].attr('boolean'),
    internal: _emberData["default"].attr('boolean', { defaultValue: false }),
    virtualDomain: _emberData["default"].attr('string'),
    userDomain: _emberData["default"].attr('string'),
    isAcme: _emberData["default"].attr('boolean', { defaultValue: false }),
    acmeStatus: _emberData["default"].attr('string'),
    platform: _emberData["default"].attr('string'),
    ipWhitelist: _emberData["default"].attr(),
    acmeDnsChallengeHost: _emberData["default"].attr('string'),
    containerPort: _emberData["default"].attr('number'),
    containerPorts: _emberData["default"].attr(),

    certificate: _emberData["default"].belongsTo('certificate', { async: true }),
    service: _emberData["default"].belongsTo('service', { async: true }),
    app: _emberData["default"].belongsTo('app', { async: true }),
    operations: _emberData["default"].hasMany('operation', { async: true }),

    useCertificate: true,

    reloadOn: [_dieselMixinsModelsStatuses["default"].PROVISIONING, _dieselMixinsModelsStatuses["default"].DEPROVISIONING],

    commonName: _ember["default"].computed.alias('virtualDomain'),

    displayName: _ember["default"].computed('virtualDomain', 'externalHost', function () {
      return this.get('virtualDomain') || this.get('externalHost') || 'New Endpoint';
    }),

    displayHost: _ember["default"].computed('isDefault', 'externalHost', 'virtualDomain', function () {
      if (this.get('isDefault')) {
        return this.get('virtualDomain');
      }
      return this.get('externalHost');
    }),

    requiresCertificate: _ember["default"].computed('isHttp', 'isTls', 'isDefault', 'isAcme', function () {
      return (this.get('isHttp') || this.get('isTls')) && !(this.get('isDefault') || this.get('isAcme'));
    }),

    requiresUserDomain: _ember["default"].computed.alias('isAcme'),

    usesContainerPort: _ember["default"].computed.alias("isHttp"),
    usesContainerPorts: _ember["default"].computed.alias("isTcpOrTls"),

    acmeIsPending: _ember["default"].computed.equal("acmeStatus", "pending"),
    acmeIsTransitioning: _ember["default"].computed.equal("acmeStatus", "transitioning"),
    acmeIsReady: _ember["default"].computed.equal("acmeStatus", "ready"),

    acmeActivationRequired: _ember["default"].computed('isProvisioned', 'isAcme', 'acmeIsReady', function () {
      if (!this.get('isProvisioned')) {
        return false;
      }
      if (!this.get('isAcme')) {
        return false;
      }
      return !this.get("acmeIsReady");
    }),

    actionsRequired: _ember["default"].computed('acmeActivationRequired', function () {
      var actions = [];
      if (this.get('acmeActivationRequired')) {
        actions.push('acme');
      }
      return actions;
    }),

    hasActionRequired: _ember["default"].computed.gt('actionsRequired.length', 0),
    failedToProvision: _ember["default"].computed.equal('status', 'provision_failed'),

    isElb: _ember["default"].computed.equal("platform", "elb"),
    isAlb: _ember["default"].computed.equal("platform", "alb"),

    isHttp: _ember["default"].computed('type', function () {
      var type = this.get('type');
      return type === 'http' || type === 'http_proxy_protocol';
    }),

    isTcp: _ember["default"].computed.equal('type', 'tcp'),
    isTls: _ember["default"].computed.equal('type', 'tls'),
    isTcpOrTls: _ember["default"].computed.or('isTcp', 'isTls'),

    ipFilteringDisabled: _ember["default"].computed.empty('ipWhitelist'),
    ipFilteringEnabled: _ember["default"].computed.not('ipFilteringDisabled'),

    acmeConfiguration: _emberData["default"].attr('humps')
  });
});