define('diesel/utils/build-url-with-prefix-map', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = buildURLWithPrefixMap;

  // Iterates through the items in the `prefixMapping` object and calls
  // the callback for each prefixMapping that applies to this requestType.
  // `requestType` is passed as the 4th argument to buildURL as of https://github.com/emberjs/data/commit/e8ceeeb4c099ab084a603c2b2564c56197c65fc5
  // We use the mixins/adapters/-lookup-methods-with-request-types mixin
  // to add this `requestType` parameter until an ember-data version is released
  // that has the requestType param.
  //
  // The callback is called with (prefixName, prefixPropName).
  //
  // The mapping can be an object with string keys and string values, or object values
  // that specify which request types are applicable.
  //
  // Example:
  // {
  //   'accounts': {property: 'account.id', only:['createRecord']}
  // }
  // This will result in prepending the string "/accounts/${record.get('account.id')}"
  // to create requests.
  // `eachApplicablePrefixPropertyMapping` will invoke the callback once with ('accounts', 'account.id')
  // if the requestType is 'createRecord'.
  //
  // See the model unit tests for more.

  function eachApplicablePrefixPropertyMapping(prefixMapping, requestType, callback) {
    Object.keys(prefixMapping).forEach(function (prefixName) {
      var prefixPropertyMapping = prefixMapping[prefixName];

      var prefixPropName = prefixPropertyMapping.property;
      var conditions = prefixPropertyMapping.only || [];

      if (checkConditions(requestType, conditions)) {
        callback(prefixName, prefixPropName);
      }
    });
  }

  function checkConditions(requestType, conditions) {
    if (conditions.length === 0) {
      return true;
    }

    return conditions.some(function (condition) {
      return requestType === condition;
    });
  }

  /*
   * `prefixPropertyMapping` is an object with a key that is the string value
   * to prepend to the url and an object value that has the property to check for on
   * the record and an optional `only` prop of request types to include
   * {
   *   'accounts': {property: 'account.id', only:['createRecord']}
   * }
   * Prepends `accounts/${record.get('account.id')}` when the requestType is 'createRecord'.
   * Valid values of `only` are the valid request types:
   *   * findRecord
   *   * createRecord
   *   * updateRecord
   *   * deleteRecord
   *   * query
   *   * queryRecord
   *
   * See ember-data source for full list of request types (note that "many" types like findMany are
   * valid in this context, because this function is meant for building a url based on a (single) record):
   * https://github.com/emberjs/data/blob/4b419d91b3773a4b78412bd963ebbe16cb2423ff/addon/-private/adapters/build-url-mixin.js#L56-L79
   */

  function buildURLWithPrefixMap(prefixPropertyMapping) {
    // From ember-data's buildURL: https://github.com/emberjs/data/blob/ff35ee78bfac058afb7a715a5dfc5760218cc05c/packages/ember-data/lib/adapters/build-url-mixin.js#L51
    return function buildURL(type, id, snapshot, requestType) {
      var _this = this;

      var url = [],
          host = _ember['default'].get(this, 'host'),
          prefix = this.urlPrefix();

      if (type) {
        (function () {

          // loop through prefix map {prefixName -> (propName OR propObject)}
          // If the requestType is not applicable (i.e. does not match the `propObject.only` array), skip.
          // else:
          //   If the propName property exists on the snapshot, prepend both the prefix name
          //   and the propName property value.
          var foundProp = undefined;
          eachApplicablePrefixPropertyMapping(prefixPropertyMapping, requestType, function (prefixName, prefixPropName) {
            var record = snapshot && snapshot.record;
            var prefixProp = record ? record.get(prefixPropName) : null;

            if (prefixProp) {
              _ember['default'].assert('Adapter buildURLWithPrefixMap found mutually exclusive properties while building URL for ' + type + ' (' + foundProp + ', ' + prefixPropName + ')', !foundProp);
              foundProp = prefixPropName;
              // add /prefixName/prefixProp to url

              url.push(prefixName);
              url.push(prefixProp);
            }
          });

          url.push(_this.pathForType(type));
        })();
      }

      if (id && !_ember['default'].isArray(id)) {
        url.push(encodeURIComponent(id));
      }

      if (prefix) {
        url.unshift(prefix);
      }

      url = url.join('/');
      if (!host && url) {
        url = '/' + url;
      }

      return url;
    };
  }
});