define('diesel/components/account-organization-selector/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    initialOrganization: null,
    eligibleOrganizations: null,

    // State
    activeOrganization: null,

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.get('activeOrganization')) {
        return;
      }

      var activeOrganization = this.get('initialOrganization');

      if (activeOrganization) {
        var eligibleIds = this.get('eligibleOrganizations').mapBy('id');
        if (eligibleIds.indexOf(activeOrganization.get('id')) < 0) {
          throw new Error('initialOrganization must be included in eligibleOrganizations');
        }
      } else {
        activeOrganization = this.get('eligibleOrganizations.firstObject');
      }

      if (!activeOrganization) {
        throw new Error('eligibleOrganizations must contain one or more Organizations');
      }

      this.set('activeOrganization', activeOrganization);

      // TODO-double-modify This afterRender call is slow and should be removed
      _ember['default'].run.schedule('afterRender', function () {
        _this.sendAction('update', activeOrganization);
      });
    },

    actions: {
      onUpdate: function onUpdate(organization) {
        this.sendAction('update', organization);
      }
    }
  });
});