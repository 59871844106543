define('diesel/components/c-network-intrusion-detection-implementation-detail/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    organization: null,
    requestSubmitted: false,
    showUpsellPanel: _ember['default'].computed.not('model.cumulativeMetrics.isFullyImplemented'),
    status: _ember['default'].computed('showUpsellPanel', function () {
      if (this.get('showUpsellPanel')) {
        return 'STATUS_INCOMPLETE';
      } else {
        return 'STATUS_COMPLETE';
      }
    }),
    actions: {
      onIgnore: function onIgnore(resource) {
        this.attrs.onIgnore(resource);
      },

      onRemoveIgnore: function onRemoveIgnore(resource) {
        this.attrs.onRemoveIgnore(resource);
      },

      onRunEvaluation: function onRunEvaluation(customerControl) {
        this.attrs.onRunEvaluation(customerControl);
      },
      talkToSales: function talkToSales() {
        var analytics = this.get('analytics');
        var EVENT_NAME = 'upsell.requested';
        var upsell = 'Network IDS';
        analytics.track(EVENT_NAME, { upsell: upsell });
        analytics.aptibleEvent(EVENT_NAME, upsell);
        this.set('requestSubmitted', true);
      }
    }
  });
});