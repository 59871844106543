define("diesel/components/permissions-by-account/component", ["exports", "ember"], function (exports, _ember) {

  var mappedScopes = {
    basic_read: "Basic Visibility",
    read: "Full Visibility",
    admin: "Environment Admin",
    deploy: "Deployment",
    destroy: "Destruction",
    observability: "Ops",
    sensitive: "Sensitive Access",
    tunnel: "Tunnel"
  };

  exports["default"] = _ember["default"].Component.extend({
    scopes: _ember["default"].computed("role.type", function () {
      var roleId = this.get("role").get("id");
      var scopes = [];
      this.get("account").get("permissions").forEach(function (permission) {
        if (permission.get("links.role").split("/").pop() === roleId) {
          var perm = permission.get("scope");
          if (mappedScopes[perm]) {
            scopes.push(mappedScopes[perm]);
          } else {
            scopes.push(perm);
          }
        }
      });
      return scopes.join(", ");
    })
  });
});