define('diesel/assets/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    modalDisplay: _ember['default'].inject.service(),

    model: function model() {
      var organizationProfile = this.get('complianceStatus.organizationProfile');
      return _ember['default'].RSVP.hash({
        organizationProfile: organizationProfile,
        components: organizationProfile.get('components'),
        systems: organizationProfile.get('systems'),
        systemTemplates: this.store.findAll('system-template'),
        productionAccounts: this.get('complianceStatus.authorizationContext.accounts').filterBy('allowPHI', true)
      });
    },

    setupController: function setupController(controller, model) {
      controller.setProperties(model);
      this.controller.setupImportables();
    },

    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);

      this.render('sidebars/engine-sidebar', {
        into: 'assets',
        outlet: 'sidebar',
        controller: this.controllerFor('gridiron-organization')
      });
    },

    actions: {
      onAssetCreate: function onAssetCreate(asset) {
        _ember['default'].get(this, 'flashMessages').success(asset.get('assessmentName') + ' created.');
      },

      openImportModal: function openImportModal(type) {
        this.controller.importApps(this.currentModel, type);
      },

      openCreateAssetModal: function openCreateAssetModal() {
        var organizationProfile = this.get('complianceStatus.organizationProfile');
        var roles = this.get('complianceStatus.authorizationContext').get('roles');
        var systemTemplates = this.currentModel.systemTemplates;

        var createAssetModal = { systemTemplates: systemTemplates, organizationProfile: organizationProfile, roles: roles };
        var modalDisplay = this.get('modalDisplay');

        modalDisplay.showModal('modal-create-asset', { createAssetModal: createAssetModal }, {
          actions: {
            onAssetCreate: modalDisplay.modalAction(this, 'onAssetCreate')
          }
        });
      }
    }
  });
});