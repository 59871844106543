define('diesel/components/modal-import-system-components/component', ['exports', 'ember', 'diesel/mixins/components/resizable-modal', 'diesel/mixins/components/modal-base', 'diesel/models/system-template'], function (exports, _ember, _dieselMixinsComponentsResizableModal, _dieselMixinsComponentsModalBase, _dieselModelsSystemTemplate) {

  var TITLES = {
    apps: 'Import Apps from Deploy',
    databases: 'Import Databases from Deploy'
  };

  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsResizableModal['default'], _dieselMixinsComponentsModalBase['default'], {
    modalImportAppsProperties: null, // set when opening modal
    store: _ember['default'].inject.service(),

    title: _ember['default'].computed('type', function () {
      return TITLES[this.get('type')];
    }),

    description: _ember['default'].computed('type', function () {
      var type = this.get('type');
      return 'Select ' + type + ' from your Deploy account to include\n            in your security program.';
    }),

    systemTemplates: _ember['default'].computed.reads('modalImportAppsProperties.systemTemplates'),
    organizationProfile: _ember['default'].computed.reads('modalImportAppsProperties.organizationProfile'),
    type: _ember['default'].computed.reads('modalImportAppsProperties.type'),

    systemTemplate: _ember['default'].computed('systemTemplates.[]', 'type', function () {
      var _getProperties = this.getProperties('systemTemplates', 'type');

      var systemTemplates = _getProperties.systemTemplates;
      var type = _getProperties.type;

      var templateHandle = type === 'apps' ? _dieselModelsSystemTemplate.ENCLAVE_APP_HANDLE : _dieselModelsSystemTemplate.ENCLAVE_DB_HANDLE;
      return systemTemplates.findBy('handle', templateHandle);
    }),

    importing: false,

    actions: {
      clearMessages: function clearMessages() {
        this.set('errors', null);
      },

      'import': function _import(resource) {
        var _this = this;

        this.set('errors', null);
        resource.set('importing', true); // Show spinner for this button in particular
        this.set('importing', true); // Disable all buttons for now

        var organizationProfile = this.get('organizationProfile');
        var type = this.get('type');
        var systemTemplate = this.get('systemTemplate');

        return organizationProfile.findOrCreateSystem(systemTemplate).then(function (enclaveSystem) {
          var componentParams = {
            organizationProfile: organizationProfile,
            handle: resource.get('handle'),
            primarySystem: enclaveSystem,
            ownerRoleUrl: organizationProfile.get('links.ops_role'),
            ownerRoleName: 'Ops Team'
          };

          if (type === 'apps') {
            componentParams.app = resource;
            componentParams.appUrl = resource.get('links.self');
          } else {
            componentParams.type = 'database';
            componentParams.database = resource;
            componentParams.databaseUrl = resource.get('links.self');
          }

          var newComponent = _this.get('store').createRecord('component', componentParams);
          return newComponent.save();
        }).then(function (component) {
          resource.set('component', component);
          resource.set('importing', false);
          _this.set('importing', false);
        })['catch'](function (e) {
          var message = e.message || 'Something went wrong!';
          _this.set('errors', message);
          resource.set('importing', false);
          _this.set('importing', false);
        });
      },

      remove: function remove(resource) {
        var organizationProfile = this.get('organizationProfile');
        var profile = resource.get('component');

        profile.destroyRecord().then(function () {
          resource.set('component', null);
          organizationProfile.get('components').removeObject(profile);
        });
      },

      outsideClick: function outsideClick() {
        return this;
      }
    }
  });
});