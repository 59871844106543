define('diesel/models/membership', ['exports', 'ember', 'ember-data', 'diesel/models/base'], function (exports, _ember, _emberData, _dieselModelsBase) {
  exports['default'] = _dieselModelsBase['default'].extend({
    userUrl: _emberData['default'].attr(),
    privileged: _emberData['default'].attr('boolean', { defaultValue: false }),
    user: _emberData['default'].belongsTo('user', { async: false }),
    role: _emberData['default'].belongsTo('role', { async: true }),
    createdAt: _emberData['default'].attr('date'),

    // This is to be set as a transient
    protectedDesignations: _ember['default'].computed(function () {
      return _ember['default'].A([]);
    }),

    protectedDesignationLabel: _ember['default'].computed('protectedDesignations.[]', function () {
      return this.get('protectedDesignations').join(', ');
    })
  });
});