define('diesel/components/modal-location-form/component', ['exports', 'ember', 'diesel/mixins/components/modal-base', 'diesel/mixins/components/asset-edit', 'diesel/models/location'], function (exports, _ember, _dieselMixinsComponentsModalBase, _dieselMixinsComponentsAssetEdit, _dieselModelsLocation) {

  var READABLE_FIELDS = {
    name: 'Name',
    description: 'Description',
    unit: 'Suite / unit number',
    streetAddress: 'Street address',
    city: 'City',
    governingDistrict: 'State / province / district',
    postalArea: 'Zip / postal code / postcode',
    country: 'Country'
  };

  var REQUIRED_INPUT_FIELDS = ['name', 'streetAddress', 'city', 'country'];

  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsAssetEdit['default'], _dieselMixinsComponentsModalBase['default'], {
    store: _ember['default'].inject.service(),
    isSaving: false,
    errors: null,
    currentTab: 'info',
    location: _ember['default'].computed.alias('modalLocationForm.location'),
    asset: _ember['default'].computed.alias('location'),
    roles: _ember['default'].computed.alias('modalLocationForm.roles'),
    readableFields: READABLE_FIELDS,
    types: _dieselModelsLocation.LOCATION_TYPES,
    title: _ember['default'].computed('location', function () {
      if (this.get('location.isNew')) {
        return 'Add New Location';
      }
      return 'Edit Location';
    }),

    validate: function validate() {
      var location = this.get('location');
      var errors = _ember['default'].A();

      REQUIRED_INPUT_FIELDS.forEach(function (field) {
        if (_ember['default'].isBlank(location.get(field))) {
          errors.push(READABLE_FIELDS[field].toLowerCase() + ' is required');
        }
      });
      if (!location.get('hasOwner')) {
        errors.push('asset owner is required');
      }
      if (errors.length > 0) {
        this.set('errors', 'Error: ' + errors.join(', '));
      }
      return;
    },

    actions: {
      beforeDismiss: function beforeDismiss() {
        this.get('location').rollbackAttributes();
      },

      clearMessages: function clearMessages() {
        this.set('errors', null);
      },

      saveLocation: function saveLocation() {
        var _this = this;

        this.set('errors', null);
        this.validate();

        if (_ember['default'].isPresent(this.get('errors'))) {
          this.scrollToTop();
          return;
        }

        this.set('isSaving', true);

        var location = this.get('location');

        return location.save().then(function () {
          _this.sendAction('dismissModal');
        })['catch'](function (e) {
          _this.set('isSaving', false);
          _this.set('errors', e.message);
          _this.scrollToTop();
          return e;
        });
      }
    }
  });
});