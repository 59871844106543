define('diesel/incident/activity/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.modelFor('incident').get('activities');
    },

    setupController: function setupController(controller, model) {
      controller.set('incident', this.modelFor('incident'));
      controller.set('activities', model);
    }
  });
});