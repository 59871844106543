define('diesel/components/dashboard-dropdown/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    className: ['dashboard-dropdown'],
    isOpen: false,

    title: _ember['default'].computed('user.name', 'actor.name', function () {
      var userName = this.get('user.name'),
          actorName = this.get('actor.name');
      if (actorName) {
        return actorName + ' (as ' + userName + ')';
      }
      return userName;
    }),

    init: function init() {
      this._super();
      this.eventName = 'click.' + _ember['default'].guidFor(this);
      this.body = _ember['default'].$(document.body);
    },

    updateDocumentClickListener: _ember['default'].observer('isOpen', function () {
      var component = this;

      if (!this.get('isOpen')) {
        this.detachDocumentClickListener();
        return;
      }

      this.body.on(this.eventName, function (event) {
        if (component.isDestroyed || _ember['default'].$.contains(component.element, event.target)) {
          return;
        }
        _ember['default'].run(function () {
          component.set('isOpen', false);
        });
      });
    }),

    detachDocumentClickListener: _ember['default'].on('willDestroyElement', function () {
      this.body.off(this.eventName);
    }),

    actions: {
      toggle: function toggle() {
        this.toggleProperty('isOpen');
      }
    }
  });
});