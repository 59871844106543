define('diesel/services/compliance-status', ['exports', 'ember', 'diesel/models/organization-profile', 'humps'], function (exports, _ember, _dieselModelsOrganizationProfile, _humps) {
  exports['default'] = _ember['default'].Service.extend({
    store: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    status: 'pending',

    // Loaded once loadOrganizationStatus is called
    authorizationContext: null,
    organization: null,
    organizationProfile: null,

    roles: _ember['default'].computed.alias('authorizationContext.roles'),
    invitations: _ember['default'].computed.alias('authorizationContext.invitations'),
    users: _ember['default'].computed.alias('authorizationContext.users'),
    billingDetail: _ember['default'].computed.alias('authorizationContext.billingDetail'),
    accounts: _ember['default'].computed.alias('authorizationContext.accounts'),

    hasComplianceOs: _ember['default'].computed.notEmpty('organizationProfile.complianceOsProtocol'),
    hasGridironClassic: _ember['default'].computed.not('hasComplianceOs'),

    loadOrganizationStatus: function loadOrganizationStatus(authorizationContext) {
      var _this = this;

      _ember['default'].assert('An authorizationContext is requried in order to load compliance status', authorizationContext);

      var organization = authorizationContext.get('organization');
      var organizationHref = organization.get('links.self');
      var store = this.get('store');
      var service = this;
      var props = {};

      this.setProperties({
        organization: organization, authorizationContext: authorizationContext, status: 'loading'
      });

      return _dieselModelsOrganizationProfile['default'].findOrCreate(organization, store).then(function (organizationProfile) {
        props.organizationProfile = organizationProfile;

        var reportHref = organizationProfile.get('links.training_status_report');
        if (!reportHref) {
          return _ember['default'].RSVP.resolve();
        }
        var accessToken = _this.get('session.token.accessToken');
        return _ember['default'].$.ajax(reportHref, { headers: { Authorization: 'Bearer ' + accessToken } });
      }).then(function (trainingStatusReport) {
        return _ember['default'].RSVP.resolve(_humps['default'].camelizeKeys(trainingStatusReport));
      }).then(function (trainingStatusReport) {
        props.trainingStatusReport = trainingStatusReport;

        return store.findAll('program').then(function (allPrograms) {
          var programs = allPrograms.filterBy('links.organization', organizationHref);
          var currentProgram = programs.get('firstObject');
          var currentApprovalId = currentProgram ? currentProgram.belongsTo('currentApproval').id() : null;

          _this.setProperties(Object.assign({}, props, {
            status: 'loaded', programs: programs, currentApprovalId: currentApprovalId, currentProgram: currentProgram
          }));
          return _ember['default'].RSVP.resolve(service);
        })['catch'](function () {
          service.setProperties(Object.assign({}, props, {
            status: 'loaded', programs: null, currentApprovalId: null, currentProgram: null
          }));
          return _ember['default'].RSVP.resolve(service);
        });
      });
    },

    reloadStatus: function reloadStatus() {
      var authorizationContext = this.get('authorizationContext');
      this.loadOrganizationStatus(authorizationContext);
    },

    hasCompletedSetup: _ember['default'].computed.equal('organizationProfile.hasCompletedSetup'),
    requiresSPD: _ember['default'].computed.not('organizationProfile.hasCompletedSetup')
  });
});