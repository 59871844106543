define('diesel/gridiron-settings/systems/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    sortBy: ['name:asc'],
    primarySystems: _ember['default'].computed.filterBy('systems', 'isPrimary'),
    storageSystems: _ember['default'].computed.filterBy('systems', 'isStorage'),
    loggingSystems: _ember['default'].computed.filterBy('systems', 'isLogging'),

    sortedPrimarySystems: _ember['default'].computed.sort('primarySystems', 'sortBy'),
    sortedStorageSystems: _ember['default'].computed.sort('storageSystems', 'sortBy'),
    sortedLoggingSystems: _ember['default'].computed.sort('loggingSystems', 'sortBy')
  });
});