define('diesel/mixins/models/ownable', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _ember['default'].Mixin.create({
    ownerRoleUrl: _emberData['default'].attr('string'),
    ownerRoleName: _emberData['default'].attr('string'),
    ownerUserUrl: _emberData['default'].attr('string'),
    ownerUserName: _emberData['default'].attr('string'),
    ownerUserEmail: _emberData['default'].attr('string'),

    ownerDisplay: _ember['default'].computed('ownerRoleName', 'ownerUserName', function () {
      var _getProperties = this.getProperties('ownerRoleName', 'ownerUserName');

      var ownerRoleName = _getProperties.ownerRoleName;
      var ownerUserName = _getProperties.ownerUserName;

      if (ownerUserName) {
        return ownerUserName;
      }

      return ownerRoleName;
    }),

    setOwnerUser: function setOwnerUser(user) {
      var ownerUserUrl = user.get('links.self');
      var ownerUserName = user.get('name');
      var ownerUserEmail = user.get('email');

      this.setProperties({ ownerUserUrl: ownerUserUrl, ownerUserName: ownerUserName, ownerUserEmail: ownerUserEmail, ownerRoleUrl: null, ownerRoleName: null });
    },

    setOwnerRole: function setOwnerRole(role) {
      var ownerRoleUrl = role.get('links.self');
      var ownerRoleName = role.get('name');

      this.setProperties({ ownerUserUrl: null, ownerUserName: null, ownerUserEmail: null, ownerRoleUrl: ownerRoleUrl, ownerRoleName: ownerRoleName });
    },

    hasOwner: _ember['default'].computed('ownerUserUrl', 'ownerRoleUrl', function () {
      return this.get('ownerUserUrl') || this.get('ownerRoleUrl');
    })
  });
});