define("diesel/components/container-la-metrics/component", ["exports", "ember", "diesel/mixins/components/container-metrics"], function (exports, _ember, _dieselMixinsComponentsContainerMetrics) {
  exports["default"] = _ember["default"].Component.extend(_dieselMixinsComponentsContainerMetrics["default"], {
    metric: "la",
    axisLabel: "Load average",
    axisFormatter: function axisFormatter(v) {
      // Don't show negative values on the axis (those can come up due to padding)
      if (v >= 0) {
        return v;
      }
    }
  });
});