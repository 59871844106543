define('diesel/role/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    setupController: function setupController(controller, model) {
      var contextHref = model.get('links.organization');
      var context = this.get('authorization').getContextByHref(contextHref);

      controller.set('model', model);
      controller.set('context', context);
    },

    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);
      this.render('sidebars/settings', {
        into: 'organization',
        outlet: 'sidebar'
      });
    }
  });
});