define("diesel/utils/time-ago-in-words", ["exports"], function (exports) {
  exports.timeAgoInWords = timeAgoInWords;

  function timeAgoInWords(toDate, fromDate) {
    if (typeof fromDate === 'undefined') {
      fromDate = new Date();
    }

    var seconds = Math.floor((fromDate - toDate) / 1000);
    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + "yr";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + "month";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + "d";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + "hr";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + "m";
    }
    return Math.floor(seconds) + "s";
  }
});