define('diesel/components/modal-agreement/component', ['exports', 'ember', 'diesel/mixins/components/resizable-modal', 'diesel/mixins/components/modal-base', 'diesel/models/agreement'], function (exports, _ember, _dieselMixinsComponentsResizableModal, _dieselMixinsComponentsModalBase, _dieselModelsAgreement) {

  var ERROR_MESSAGE = 'There was an error saving the agreement';

  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsResizableModal['default'], _dieselMixinsComponentsModalBase['default'], {
    agreementModal: null,
    errors: null,
    modelLabel: 'customer', // or 'vendor'
    isCustomer: _ember['default'].computed.equal('modelLabel', 'customer'),
    types: _dieselModelsAgreement.TYPES,

    init: function init() {
      this._super.apply(this, arguments);

      var _get = this.get('agreementModal');

      var agreement = _get.agreement;

      var title = 'Edit \'' + agreement.get('name') + '\' Agreement';
      var description = agreement.get('owner.name');

      if (agreement.get('isNew')) {
        title = 'Add Agreement';
      }

      this.setProperties({ agreement: agreement, title: title, description: description });
    },

    buttonLabel: _ember['default'].computed('agreement.isNew', function () {
      if (this.get('agreement.isNew') === false) {
        return 'Save Changes';
      }
      return 'Create Agreement';
    }),

    prepModelsForSave: function prepModelsForSave() {
      var agreement = this.get('agreement');

      // Null both options if perpetual
      if (agreement.get('termExpiresOnDate') || agreement.get('termDoesNotExpire')) {
        agreement.set('termContingency', null);
      }
      if (agreement.get('termExpirationIsContingent') || agreement.get('termDoesNotExpire')) {
        agreement.set('termExpirationDate', null);
      }
    },

    validate: function validate() {
      var agreement = this.get('agreement');
      var errors = _ember['default'].A();

      if (_ember['default'].isBlank(agreement.get('name'))) {
        errors.push('A name for this agreement is required.');
      }

      if (_ember['default'].isBlank(agreement.get('type'))) {
        errors.push('A type for this agreement is required.');
      }

      if (agreement.get('termExpirationIsContingent') && !agreement.get('termContingency.id')) {
        errors.push('A contingent agreement is required.');
      }

      if (agreement.get('termExpiresOnDate') && !agreement.get('termExpirationDate')) {
        errors.push('A term expiration date is required.');
      }
      if (agreement.get('enforceable') && _ember['default'].isBlank(agreement.get('enforceableDescription'))) {
        errors.push('Please describe the agreement enforceability requirements.');
      }
      this.set('errors', errors);
    },

    actions: {
      clearMessages: function clearMessages() {
        this.set('errors', []);
      },

      saveAgreement: function saveAgreement() {
        var _this = this;

        var agreement = this.get('agreement');
        var verb = "added";

        this.validate();

        if (_ember['default'].isPresent(this.get('errors'))) {
          this.scrollToTop();
          return;
        }

        this.prepModelsForSave();
        this.set('isSaving', true);

        if (agreement.get('isNew') === false) {
          verb = "updated";
        }

        return agreement.save().then(function () {
          if (_ember['default'].isBlank(_this.get('errors'))) {
            _this.sendAction('onAgreementUpdate', { agreement: _this.get('agreement'), verb: verb });
          }
        })['catch'](function (e) {
          _this.set('errors', [e.message || ERROR_MESSAGE]);
        })['finally'](function () {
          _this.set('isSaving', false);
        });
      },

      beforeDismiss: function beforeDismiss() {
        if (this.get('isSaving')) {
          return;
        }

        this.get('agreement').rollbackAttributes();

        this.set('errors', null);
      }
    }
  });
});