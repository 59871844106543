define('diesel/components/c-control-table-row/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: "",
    isDevelopmentView: null,
    requiresDedicatedStack: null,
    isAggregateScope: null,
    control: null,
    accountsInScope: null,
    showDedicatedStackWall: _ember['default'].computed('isDevelopmentView', 'control.globalControl.isDedicatedOnly', function () {
      var isDevelopmentView = this.get('isDevelopmentView');
      var isDedicatedOnlyControl = this.get('control.globalControl.isDedicatedOnly');

      return isDevelopmentView && isDedicatedOnlyControl;
    })
  });
});