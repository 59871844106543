define('diesel/security-assessments/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    sortByName: ['name:asc'],
    sortByHandle: ['handle:asc'],
    sortByTitle: ['title:asc'],
    hasNotCompletedSetup: _ember['default'].computed.equal('organizationProfile.hasCompletedSetup', false),
    sortedSecurityControlCategories: _ember['default'].computed.sort('securityControlCategories', 'sortByTitle'),
    sortedComponents: _ember['default'].computed.sort('components', 'sortByHandle'),
    sortedSystems: _ember['default'].computed.sort('systems', 'sortByName'),

    sortedSaasSystems: _ember['default'].computed.filterBy('sortedSystems', 'isSaas'),
    filteredSaasSystems: _ember['default'].computed('sortedSaasSystems.@each.hasAssessmentQuestionnaire', function () {
      return this.get('sortedSaasSystems').filterBy('hasAssessmentQuestionnaire');
    }),

    sortedHostSystems: _ember['default'].computed.filterBy('sortedSystems', 'isPrimary'),
    filteredHostSystems: _ember['default'].computed('sortedHostSystems.@each.hasAssessmentQuestionnaire', function () {
      return this.get('sortedHostSystems').filterBy('hasAssessmentQuestionnaire');
    }),

    sortedStorageSystems: _ember['default'].computed.filterBy('sortedSystems', 'isStorage'),
    filteredStorageSystems: _ember['default'].computed('sortedStorageSystems.@each.hasAssessmentQuestionnaire', function () {
      return this.get('sortedStorageSystems').filterBy('hasAssessmentQuestionnaire');
    }),

    sortedLoggingSystems: _ember['default'].computed.filterBy('sortedSystems', 'isLogging'),
    filteredLoggingSystems: _ember['default'].computed('sortedLoggingSystems.@each.hasAssessmentQuestionnaire', function () {
      return this.get('sortedLoggingSystems').filterBy('hasAssessmentQuestionnaire');
    }),

    sortedApps: _ember['default'].computed.filterBy('sortedComponents', 'isApp'),
    filteredApps: _ember['default'].computed('sortedApps.@each.hasAssessmentQuestionnaire', function () {
      return this.get('sortedApps').filterBy('hasAssessmentQuestionnaire');
    }),

    sortedDatabases: _ember['default'].computed.filterBy('sortedComponents', 'isDatabase'),
    filteredDatabases: _ember['default'].computed('sortedDatabases.@each.hasAssessmentQuestionnaire', function () {
      return this.get('sortedDatabases').filterBy('hasAssessmentQuestionnaire');
    })
  });
});