define('diesel/components/vendor-filters/component', ['exports', 'ember', 'diesel/mixins/models/classification'], function (exports, _ember, _dieselMixinsModelsClassification) {

  function allDataTypes() {
    return [{ handle: 'any', name: 'Any' }].concat(_dieselMixinsModelsClassification.REGULATED_DATA_TYPES);
  }

  function defaultFilters() {
    return { search: '', dataType: 'any' };
  }

  function filterBySearch(search, keys, vendors) {
    if (!search) {
      return vendors;
    }

    return vendors.filter(function (vendor) {
      return keys.any(function (key) {
        var value = _ember['default'].get(vendor, key);
        return value && value.toLowerCase().includes(search);
      });
    });
  }

  function filterByDataType(dataType, vendors) {
    if (!dataType || dataType === 'any') {
      return vendors;
    }

    return vendors.filter(function (vendor) {
      var dataTypes = _ember['default'].get(vendor, 'allRegulatedDataTypes');
      return dataTypes.includes(dataType);
    });
  }

  var DEFAULT_KEYS = 'name,services';

  exports['default'] = _ember['default'].Component.extend({
    filters: _ember['default'].computed(function () {
      return defaultFilters();
    }),
    dataTypes: allDataTypes(),
    keys: DEFAULT_KEYS,

    filteredVendors: _ember['default'].computed('filters.search', 'filters.dataType', 'vendors.@each', 'keys', function () {
      var search = this.get('filters.search').toLowerCase();
      var keys = this.get('keys').split(',');
      var dataType = this.get('filters.dataType');
      var vendors = this.get('vendors');

      var results = filterBySearch(search, keys, vendors);

      return filterByDataType(dataType, results);
    }),

    showClear: _ember['default'].computed('filters.search', 'filters.dataType', function () {
      return this.get('filters.search.length') || this.get('filters.dataType') !== 'any';
    }),

    actions: {
      clear: function clear() {
        this.set('filters', defaultFilters());
      }
    }
  });
});