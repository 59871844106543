define('diesel/components/vulnerability-criticality/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'span',
    classNameBindings: [':vulnerability-criticality', 'vulnerability.criticality'],
    vulnerability: null,

    tooltip: _ember['default'].computed('vulnerability.criticality', function () {
      return ({
        'not-applicable-kernel': 'This vulnerability affects a Linux Kernel\n       package installed in your image, but it is not applicable to you since\n       the Kernel used by your container is the one installed by Aptible on the\n       underlying host system.'
      })[this.get('vulnerability.criticality')];
    })
  });
});