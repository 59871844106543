define('diesel/mixins/controllers/filterable-controls', ['exports', 'ember'], function (exports, _ember) {
  var STATUS_SORT = ["cumulativeMetrics.statusSort:asc", 'Sort by Control Status'];
  exports.STATUS_SORT = STATUS_SORT;
  var CATEGORY_SORT = ["globalControl.area:asc", 'Sort by Control Category'];
  exports.CATEGORY_SORT = CATEGORY_SORT;
  var NAME_SORT = ["globalControl.name:asc", 'Sort by Control Name'];

  exports.NAME_SORT = NAME_SORT;
  var sortOptions = [STATUS_SORT, NAME_SORT, CATEGORY_SORT];

  exports.sortOptions = sortOptions;
  exports['default'] = _ember['default'].Mixin.create({
    analytics: _ember['default'].inject.service(),
    queryParams: ['scope', 'category', 'sort', 'framework'],
    scope: null,
    category: null,
    framework: 'all',
    mainControlAggregator: null,
    sort: NAME_SORT[0],
    sortCount: 0,
    filterCount: 0,
    sortBy: _ember['default'].computed('sort', function () {
      return [this.get("sort")];
    }),

    trackSortChange: _ember['default'].observer('sort', function () {
      var _getProperties = this.getProperties('analytics', 'sort', 'sortCount');

      var analytics = _getProperties.analytics;
      var sort = _getProperties.sort;
      var sortCount = _getProperties.sortCount;

      var EVENT_NAME = 'sort.updated';

      if (sortCount > 0) {
        // Sort gets set by default. We want to skip that first sort action
        analytics.track(EVENT_NAME, { sort: sort });
        analytics.aptibleEvent(EVENT_NAME, sort);
      }
      this.incrementProperty('sortCount');
    }),

    trackFilterChange: _ember['default'].observer('category', function () {
      var _getProperties2 = this.getProperties('analytics', 'category', 'filterCount');

      var analytics = _getProperties2.analytics;
      var category = _getProperties2.category;
      var filterCount = _getProperties2.filterCount;

      var EVENT_NAME = 'filter.updated';

      if (filterCount > 0) {
        analytics.track(EVENT_NAME, { category: category });
        analytics.aptibleEvent(EVENT_NAME, category);
      }
      this.incrementProperty("filterCount");
    }),

    sortedControls: _ember['default'].computed.sort('mainControlAggregator.aggregatedControls', 'sortBy'),

    sortedAndFilteredControls: _ember['default'].computed('sortedControls', 'category', 'sortBy', 'framework', function () {
      var category = this.get('category');
      var framework = this.get("framework");
      var filtered = this.get('sortedControls');

      if (category) filtered = filtered.filterBy('globalControl.area', category);
      if (framework && framework != 'all') filtered = filtered.filter(function (c) {
        return c.get('globalControl.frameworkHandles').includes(framework);
      });

      return filtered;
    })
  });
});