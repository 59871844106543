define('diesel/components/policy-editor/array-input/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'input',
    attributeBindings: ['placeholder'],

    change: function change(event) {
      var value = stringToArray(event.target.value);
      this.get('onChange')(value);
    },

    didRender: function didRender() {
      this.element.value = arrayToString(this.get('value'));
    }
  });

  function stringToArray() {
    var string = arguments.length <= 0 || arguments[0] === undefined ? '' : arguments[0];

    if (string === '') {
      return undefined;
    }

    return string.replace(/\s/g, '').split(',');
  }

  function arrayToString() {
    var array = arguments.length <= 0 || arguments[0] === undefined ? [] : arguments[0];

    return array.join(', ');
  }
});