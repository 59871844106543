define('diesel/components/user-training-assignment/component', ['exports', 'ember', 'diesel/models/assignment-state'], function (exports, _ember, _dieselModelsAssignmentState) {
  var _CTA_TEXTS, _ICONS;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var ASSIGNED_INPROGRESS = 'assigned_inprogress';

  var CTA_TEXTS = (_CTA_TEXTS = {}, _defineProperty(_CTA_TEXTS, _dieselModelsAssignmentState.ASSIGNMENT_TRANSITIONS.ASSIGNED, 'Begin Training →'), _defineProperty(_CTA_TEXTS, _dieselModelsAssignmentState.ASSIGNMENT_TRANSITIONS.PASSED_DUE, 'Begin Training →'), _defineProperty(_CTA_TEXTS, _dieselModelsAssignmentState.ASSIGNMENT_TRANSITIONS.EXPIRED, 'Retake Training →'), _defineProperty(_CTA_TEXTS, _dieselModelsAssignmentState.ASSIGNMENT_TRANSITIONS.COMPLETED, 'Review Training →'), _defineProperty(_CTA_TEXTS, ASSIGNED_INPROGRESS, 'Resume Training →'), _CTA_TEXTS);

  exports.CTA_TEXTS = CTA_TEXTS;
  var ICONS = (_ICONS = {}, _defineProperty(_ICONS, _dieselModelsAssignmentState.ASSIGNMENT_TRANSITIONS.PASSED_DUE, 'times'), _defineProperty(_ICONS, _dieselModelsAssignmentState.ASSIGNMENT_TRANSITIONS.ASSIGNED, 'exclamation'), _defineProperty(_ICONS, _dieselModelsAssignmentState.ASSIGNMENT_TRANSITIONS.EXPIRED, 'exclamation'), _defineProperty(_ICONS, _dieselModelsAssignmentState.ASSIGNMENT_TRANSITIONS.COMPLETED, 'check'), _defineProperty(_ICONS, ASSIGNED_INPROGRESS, 'exclamation'), _ICONS);

  exports.ICONS = ICONS;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['panel-link-item--nolink', 'user-training-assignment'],
    courseAssignmentState: null,
    assignment: _ember['default'].computed.alias('courseAssignmentState.assignment'),
    course: _ember['default'].computed.alias('courseAssignmentState.course'),
    ctaText: _ember['default'].computed('status', function () {
      return CTA_TEXTS[this.get('status')];
    }),

    status: _ember['default'].computed('assignment.status', 'assignment.currentModule', function () {
      var status = this.get('assignment.status');
      var module = this.get('assignment.currentModule');

      if (module && status === _dieselModelsAssignmentState.ASSIGNMENT_TRANSITIONS.ASSIGNED) {
        status = ASSIGNED_INPROGRESS;
      }
      return status;
    }),

    icon: _ember['default'].computed('status', function () {
      return ICONS[this.get('status')];
    }),

    savedDocuments: _ember['default'].computed.filterBy('assignment.documents', 'isNew', false)
  });
});