define('diesel/gridiron-settings/team/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    setupController: function setupController(controller) {
      var organizationProfile = this.get('complianceStatus.organizationProfile');
      controller.set('organizationProfile', organizationProfile);
    },

    actions: {
      save: function save() {
        var _this = this;

        var organizationProfile = this.get('complianceStatus.organizationProfile');

        organizationProfile.save().then(function () {
          _ember['default'].get(_this, 'flashMessages').success('Settings saved!');
        });
      }
    }
  });
});