define('diesel/components/role-type-options/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['split-selector-options'],

    actions: {
      updateType: function updateType(roleType) {
        this.get('role').set('type', roleType);
      }
    }
  });
});