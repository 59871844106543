define('diesel/components/button-with-confirmation/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    isConfirmed: null,
    isUnconfirmed: _ember['default'].computed.not('isConfirmed'),

    input: _ember['default'].on('init', function () {
      var enteredValue = this.get('enteredValue');
      var confirmValue = this.get('confirmValue');
      this.set('isConfirmed', enteredValue === confirmValue);
    }),

    actions: {
      submit: function submit() {
        if (this.get('isConfirmed')) {
          this.sendAction();
        }
      }
    }
  });
});