define('diesel/models/credit', ['exports', 'ember-data', 'diesel/models/base', 'ember'], function (exports, _emberData, _dieselModelsBase, _ember) {
  exports['default'] = _dieselModelsBase['default'].extend({
    createdAt: _emberData['default'].attr('iso-8601-timestamp'),

    billingDetail: _emberData['default'].belongsTo('billing-detail', { async: true }),
    creditClosure: _emberData['default'].belongsTo('credit-closure', { async: false }),

    // TODO: This breaks if inverse is not null. Let's make sure we have test
    // coverage for it.
    ancestor: _emberData['default'].belongsTo('credit', { async: true, inverse: null }),

    cashValue: _emberData['default'].attr('number'),
    description: _emberData['default'].attr('string'),

    hasClosure: _ember['default'].computed('id', function () {
      // TODO: Is using id the right thing to do here?
      return !!this.belongsTo('creditClosure').id();
    })
  });
});