define('diesel/vendor/certifications/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    modalDisplay: _ember['default'].inject.service(),

    model: function model() {
      return this.modelFor('vendor');
    },

    openCertificationModal: function openCertificationModal(params) {
      var modalDisplay = this.get('modalDisplay');
      modalDisplay.showModal('modal-certification', { modalCertification: params });
    },

    actions: {
      createCertification: function createCertification() {
        var modalCertification = { vendor: this.currentModel };
        this.openCertificationModal(modalCertification);
      },
      editCertification: function editCertification(certification) {
        var modalCertification = { certification: certification, vendor: this.currentModel };
        this.openCertificationModal(modalCertification);
      },
      deleteCertification: function deleteCertification(certification) {
        if (!window.confirm('Are you sure you want to delete this certification?')) {
          return;
        }

        certification.destroyRecord();
      }
    }
  });
});