define('diesel/components/account-sidebar/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    authorizationContext: null,

    sortBy: ['handle:asc'],
    sortedUnassignedAccounts: _ember['default'].computed.sort('authorizationContext.unassignedAccounts', 'sortBy'),

    hasNoUnassignedAccounts: _ember['default'].computed.empty('sortedUnassignedAccounts'),

    shownStacks: _ember['default'].computed.union('authorizationContext.stacks', 'authorizationContext.eligibleProductionStacks'),

    sortedStacks: _ember['default'].computed.sort('shownStacks', 'sortBy'),

    actions: {
      createAccount: function createAccount() {
        this.sendAction('newOrganizationAccount', null);
      }
    }
  });
});