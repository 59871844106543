define('diesel/components/disk-size-slider/component', ['exports', 'ember'], function (exports, _ember) {

  var DISK_SIZE_MIN = 10;
  var DISK_SIZE_MAX = 200;
  var DISK_SIZE_DEFAULT = DISK_SIZE_MIN;
  var DISK_SIZE_STEP = 10;

  exports['default'] = _ember['default'].Component.extend({
    // Inputs
    initialSize: null,

    // Optional inputs
    disabled: false,

    classNames: ['slider-wrapper', 'slider-disk-size'],

    rangeMin: _ember['default'].computed.min('range'),
    rangeMax: _ember['default'].computed.max('range'),
    range: _ember['default'].computed('initialSize', function () {
      var initialSize = this.get('initialSize');
      var sizes = [initialSize];

      var from = Math.ceil(initialSize / DISK_SIZE_STEP) * DISK_SIZE_STEP;

      for (var s = from; s <= DISK_SIZE_MAX; s += DISK_SIZE_STEP) {
        sizes.push(s);
      }

      // NOTE: if initialSize is a multiple of DISK_SIZE_STEP, then it'll get
      // added twice, so we have to handle it here with uniq().
      return sizes.uniq().sort(function (a, b) {
        return a - b;
      });
    }),

    rangeForSlider: _ember['default'].computed('range', function () {
      return this.get('range').join(',');
    }),

    disableSlider: _ember['default'].computed('range', function () {
      return this.get('range.length') <= 1;
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get('initialSize')) {
        this.set('initialSize', DISK_SIZE_DEFAULT);
        this.send('didSlide', DISK_SIZE_DEFAULT);
      }
    },

    actions: {
      didSlide: function didSlide(v) {
        this.sendAction('didSlide', v);
      },

      didSet: function didSet(v) {
        this.sendAction('didSet', v);
      }
    }
  });
});