define('diesel/mixins/models/classification', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  var REGULATED_DATA_TYPES = [{ handle: 'hipaa_phi', name: 'HIPAA PHI' }, { handle: 'pii', name: 'PII' }, { handle: 'eu_personal_data', name: 'EU Personal Data' }, { handle: 'payment_card', name: 'Payment Card' }];

  exports.REGULATED_DATA_TYPES = REGULATED_DATA_TYPES;
  var SENSITIVE_DATA_TYPES = [{ handle: 'ethnic', name: 'Racial/Ethnic Origin' }, { handle: 'political', name: 'Political Affiliation or Opinion' }, { handle: 'religion', name: 'Religious or Philosophical Beliefs' }, { handle: 'union_membership', name: 'Trade Union Membership' }, { handle: 'genetics', name: 'Genetic Data' }, { handle: 'biometrics', name: 'Biometric Identification/Authentication Data' }, { handle: 'health', name: 'Health Data' }, { handle: 'sex', name: 'Sexual History or Sexual Orientation Data' }, { handle: 'other', name: 'Other' }];

  exports.SENSITIVE_DATA_TYPES = SENSITIVE_DATA_TYPES;
  var LEGAL_REQUIREMENTS = [{ handle: 'sla', name: 'SLA' }, { handle: 'other', name: 'Other' }];

  exports.LEGAL_REQUIREMENTS = LEGAL_REQUIREMENTS;
  var DATA_RETENTION_TYPES = [{ handle: 'indefinite', name: 'Forever' }, { handle: 'period', name: 'Only a specific period' }];

  exports.DATA_RETENTION_TYPES = DATA_RETENTION_TYPES;
  var DATA_ROTATION_STRATEGIES = [{ handle: 'none', name: 'None', tooltip: 'Data is not rotated out of this system.' }, { handle: 'archive', name: 'Archived', tooltip: 'Data is rotated out of this system and stored in an archive.' }, { handle: 'destroy', name: 'Destroyed', tooltip: 'Data is rotated out of this system and destroyed.' }];

  exports.DATA_ROTATION_STRATEGIES = DATA_ROTATION_STRATEGIES;
  var DATA_BACKUP_STRATEGIES = [{ handle: 'none', name: 'None', tooltip: 'Data is not backed up from this system.' }, { handle: 'automatic', name: 'Automatic', tooltip: 'Data is automatically backed up from this system.' }, { handle: 'manual', name: 'Manual', tooltip: 'Data is manually backed up from this system' }];

  exports.DATA_BACKUP_STRATEGIES = DATA_BACKUP_STRATEGIES;
  var CLASSIFICATION_LEVELS = {
    sensitive: 'Sensitive/Regulated',
    restricted: 'Restricted',
    confidential: 'Confidential',
    'public': 'Public'
  };

  exports.CLASSIFICATION_LEVELS = CLASSIFICATION_LEVELS;
  exports['default'] = _ember['default'].Mixin.create({
    classification: _emberData['default'].attr('string', { defaultValue: 'sensitive' }),
    classificationLabel: _ember['default'].computed('classification', function () {
      return CLASSIFICATION_LEVELS[this.get('classification')];
    }),

    classificationConvertedToCriticality: _ember['default'].computed('classification', function () {
      // This converts the classification scale to na-low-med-high so it can share
      // view components with the other criticalities
      var SCALE = {
        sensitive: 'high',
        restricted: 'medium',
        confidential: 'low',
        'public': 'na'
      };

      return SCALE[this.get('classification')];
    }),

    legalRequirements: _emberData['default'].attr({ defaultValue: function defaultValue() {
        return [];
      } }), // Array
    legalRequirementNames: _ember['default'].computed('legalRequirements.[]', function () {
      return this.get('legalRequirements').map(function (r) {
        return LEGAL_REQUIREMENTS.findBy('handle', r).name;
      });
    }),
    otherLegalRequirement: _emberData['default'].attr('string'),

    regulatedDataTypes: _emberData['default'].attr({ defaultValue: function defaultValue() {
        return [];
      } }), // Array
    otherRegulatedDataTypes: _emberData['default'].attr('string'),
    regulatedDataTypeNames: _ember['default'].computed('regulatedDataTypes.[]', function () {
      return this.get('regulatedDataTypes').map(function (t) {
        return REGULATED_DATA_TYPES.findBy('handle', t).name;
      });
    }),

    sensitiveDataTypes: _emberData['default'].attr({ defaultValue: function defaultValue() {
        return [];
      } }), // Array
    sensitiveDataTypeNames: _ember['default'].computed('sensitiveDataTypes.[]', function () {
      return this.get('sensitiveDataTypes').map(function (t) {
        return SENSITIVE_DATA_TYPES.findBy('handle', t).name;
      });
    }),
    requiresOtherSensitiveDataType: _ember['default'].computed('sensitiveDataTypes.[]', function () {
      return this.get('sensitiveDataTypes.length') && this.get('sensitiveDataTypes').includes("other");
    }),

    otherSensitiveDataTypes: _emberData['default'].attr('string'),

    dataRetentionLifetime: _emberData['default'].attr('string', { defaultValue: DATA_RETENTION_TYPES[0].handle }),
    dataRetentionLifetimeValue: _emberData['default'].attr('number', { defaultValue: 1 }),
    dataRetentionLifetimeUnit: _emberData['default'].attr('string', { defaultValue: 'years' }),
    liveDataRetentionDescription: _ember['default'].computed('dataRetentionLifetime', 'dataRetentionLifetimeValue', 'dataRetentionLifetimeUnit', function () {
      var _getProperties = this.getProperties('dataRetentionLifetime', 'dataRetentionLifetimeUnit', 'dataRetentionLifetimeValue');

      var dataRetentionLifetime = _getProperties.dataRetentionLifetime;
      var dataRetentionLifetimeUnit = _getProperties.dataRetentionLifetimeUnit;
      var dataRetentionLifetimeValue = _getProperties.dataRetentionLifetimeValue;

      if (dataRetentionLifetime === 'indefinite') {
        return 'Live data is retained indefinitely.';
      }

      return 'Live data is retained for ' + dataRetentionLifetimeValue + ' ' + dataRetentionLifetimeUnit + '.';
    }),

    dataRotationStrategy: _emberData['default'].attr('string', { defaultValue: DATA_ROTATION_STRATEGIES[0].handle }),
    dataRotationLocation: _emberData['default'].attr('string'),
    dataRotationLifetime: _emberData['default'].attr('string', { defaultValue: DATA_RETENTION_TYPES[0].handle }),
    dataRotationLifetimeValue: _emberData['default'].attr('number', { defaultValue: 1 }),
    dataRotationLifetimeUnit: _emberData['default'].attr('string', { defaultValue: 'years' }),
    dataRotationStrategyDescription: _ember['default'].computed('dataRotationStrategy', 'dataRotationLocation', 'dataRotationLifetime', 'dataRotationLifetimeValue', 'dataRotationLifetimeUnit', function () {
      var _getProperties2 = this.getProperties('dataRotationStrategy', 'dataRotationLocation', 'dataRotationLifetime', 'dataRotationLifetimeValue', 'dataRotationLifetimeUnit');

      var dataRotationStrategy = _getProperties2.dataRotationStrategy;
      var dataRotationLocation = _getProperties2.dataRotationLocation;
      var dataRotationLifetime = _getProperties2.dataRotationLifetime;
      var dataRotationLifetimeValue = _getProperties2.dataRotationLifetimeValue;
      var dataRotationLifetimeUnit = _getProperties2.dataRotationLifetimeUnit;

      if (dataRotationStrategy === 'none') {
        return 'Data in this system is not rotated';
      }

      if (dataRotationStrategy === 'destroy') {
        return 'Data in this system is routinely rotated and destroyed';
      }

      var archiveRetention = 'indefinitely';
      if (dataRotationLifetime !== 'indefinite') {
        archiveRetention = 'for ' + dataRotationLifetimeValue + ' ' + dataRotationLifetimeUnit;
      }
      return 'Data in this system is routinely archived. Archives are stored in ' + dataRotationLocation + ' and are persisted ' + archiveRetention + '.';
    }),

    dataBackupStrategy: _emberData['default'].attr('string', { defaultValue: DATA_BACKUP_STRATEGIES[0].handle }),
    dataBackupLocation: _emberData['default'].attr('string'),
    dataBackupLifetime: _emberData['default'].attr('string', { defaultValue: DATA_RETENTION_TYPES[0].handle }),
    dataBackupLifetimeValue: _emberData['default'].attr('number', { defaultValue: 1 }),
    dataBackupLifetimeUnit: _emberData['default'].attr('string', { defaultValue: 'years' }),

    dataBackupStrategyDescription: _ember['default'].computed('dataBackupStrategy', 'dataBackupLocation', 'dataBackupLifetime', 'dataBackupLifetimeUnit', 'dataBackupLifetimeValue', function () {
      var _getProperties3 = this.getProperties('dataBackupStrategy', 'dataBackupLocation', 'dataBackupLifetime', 'dataBackupLifetimeUnit', 'dataBackupLifetimeValue');

      var dataBackupStrategy = _getProperties3.dataBackupStrategy;
      var dataBackupLocation = _getProperties3.dataBackupLocation;
      var dataBackupLifetime = _getProperties3.dataBackupLifetime;
      var dataBackupLifetimeUnit = _getProperties3.dataBackupLifetimeUnit;
      var dataBackupLifetimeValue = _getProperties3.dataBackupLifetimeValue;

      if (dataBackupStrategy === 'none') {
        return 'Data in this system is not backed up.';
      }

      var strategy = dataBackupStrategy === 'manual' ? 'manually' : 'automatically';
      var retention = 'indefinitely.';
      if (dataBackupLifetime !== 'indefinite') {
        retention = 'for ' + dataBackupLifetimeValue + ' ' + dataBackupLifetimeUnit + '.';
      }

      return 'Backups for this system are performed ' + strategy + ' and are stored ' + dataBackupLocation + '.\n            Backups are retained ' + retention;
    })
  });
});