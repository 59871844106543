define('diesel/initializers/analytics-service', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(application) {
    application.inject('component', 'analytics', 'service:analytics');
    application.inject('route', 'analytics', 'service:analytics');
  }

  exports['default'] = {
    name: 'analytics-service',
    initialize: initialize
  };
});