define('diesel/models/location', ['exports', 'ember-data', 'diesel/models/base', 'ember', 'diesel/mixins/models/asset', 'diesel/mixins/models/classification', 'diesel/mixins/models/impacts'], function (exports, _emberData, _dieselModelsBase, _ember, _dieselMixinsModelsAsset, _dieselMixinsModelsClassification, _dieselMixinsModelsImpacts) {
  var LOCATION_TYPES = [{ handle: 'office', name: 'Office' }, { handle: 'data_center', name: 'Data center' }, { handle: 'clinic', name: 'Clinic' }, { handle: 'other', name: 'Other' }];

  exports.LOCATION_TYPES = LOCATION_TYPES;
  exports['default'] = _dieselModelsBase['default'].extend(_dieselMixinsModelsAsset['default'], _dieselMixinsModelsClassification['default'], _dieselMixinsModelsImpacts['default'], {
    name: _emberData['default'].attr('string'),
    type: _emberData['default'].attr('string', { defaultValue: 'office' }),
    unit: _emberData['default'].attr('string'),
    streetAddress: _emberData['default'].attr('string'),
    city: _emberData['default'].attr('string'),
    governingDistrict: _emberData['default'].attr('string'),
    postalArea: _emberData['default'].attr('string'),
    country: _emberData['default'].attr('string'),

    organizationProfile: _emberData['default'].belongsTo('organization-profile', { async: true }),

    readableType: _ember['default'].computed('type', function () {
      return LOCATION_TYPES[this.get('type')];
    }),

    fullAddress: _ember['default'].computed('unit', 'streetAddress', 'city', 'governingDistrict', 'postalArea', 'country', function () {
      var address = this.get('streetAddress');
      if (!_ember['default'].isBlank(this.get('unit'))) {
        address = this.get('unit') + '-' + address;
      }
      return [address, this.get('city'), this.get('governingDistrict'), this.get('postalArea'), this.get('country')].filter(Boolean).join(', ');
    })
  });
});