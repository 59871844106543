define('diesel/utils/assign', ['exports'], function (exports) {
  /**
   * MDN Pollyfill adapted as a utility.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign#Polyfill
   */

  var assign = undefined;

  if (typeof Object.assign === 'function') {
    assign = Object.assign;
  } else {
    /* jshint ignore:start */
    assign = function (target, varArgs) {
      // .length of function is 2
      if (target == null) {
        // TypeError if undefined or null
        throw new TypeError('Cannot convert undefined or null to object');
      }

      var to = Object(target);

      for (var index = 1; index < arguments.length; index++) {
        var nextSource = arguments[index];

        if (nextSource != null) {
          // Skip over if undefined or null
          for (var nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    };
    /* jshint ignore:end */
  }

  exports['default'] = assign;
});