define('diesel/components/threat-sources-list/component', ['exports', 'ember', 'diesel/utils/filter-risk-components', 'diesel/mixins/components/column-config'], function (exports, _ember, _dieselUtilsFilterRiskComponents, _dieselMixinsComponentsColumnConfig) {

  var COLUMN_PRESETS = {
    title: 'Threat Source',
    description: 'Description',
    threatVector: 'Threat Vector'
  };

  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsColumnConfig['default'], {
    filters: { search: '', sort: null, pervasiveness: null },
    tagName: 'table',
    classNames: ['base-table'],
    columns: ['title', 'description', 'threatVector'],
    columnPresets: COLUMN_PRESETS,

    filteredThreatSources: _ember['default'].computed('filters.adversarial', 'filters.sort', 'filters.search', 'threatSources.[]', function () {
      var threatSources = this.get('threatSources');
      var filters = this.get('filters');

      return (0, _dieselUtilsFilterRiskComponents['default'])(threatSources, filters);
    })
  });
});