define('diesel/gridiron/policy-editor/route', ['exports', 'ember', 'diesel/components/policy-editor/policy-document'], function (exports, _ember, _dieselComponentsPolicyEditorPolicyDocument) {
  var inject = _ember['default'].inject;
  exports['default'] = _ember['default'].Route.extend({
    storage: inject.service(),
    authorization: inject.service(),

    beforeModel: function beforeModel() {
      var authorization = this.get('authorization');

      if (!authorization.get('organizationContexts').filterBy('enabledFeatures.policyEditor').length) {
        this.transitionTo('not-found', { wildcard: 'not-found' });
      }
    },

    model: function model() {
      var _this = this;

      var doc = undefined;

      try {
        doc = this.get('storage.policy-editor-document');
      } catch (e) {
        doc = {};
      }

      var policyDocument = new _dieselComponentsPolicyEditorPolicyDocument['default']({
        doc: doc,
        didChange: function didChange(doc) {
          return _this.didChange(doc);
        }
      });

      return {
        editor: policyDocument,
        document: policyDocument.doc
      };
    },

    didChange: function didChange(doc) {
      this.set('controller.model.document', doc);
      this.set('storage.policy-editor-document', doc);
    }
  });
});