define('diesel/components/asset-panel-components/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),
    isSaving: null,
    savingMessage: null,
    isEditing: null,
    newComponents: null,
    error: null,
    isRequired: _ember['default'].computed.bool('asset.isApp'),
    persistedDependencies: _ember['default'].computed.filterBy('dependencies', 'isNew', false),
    description: _ember['default'].computed('type', function () {
      var _getProperties = this.getProperties('type', 'asset');

      var type = _getProperties.type;
      var asset = _getProperties.asset;

      switch (type) {
        case 'app':
          return 'App dependencies are other apps that ' + asset.get('assessmentName') + ' depends on, such as an authentication API';
        case 'database':
          return 'Database dependencies are databases that ' + asset.get('assessmentName') + ' depends on.';
        case 'network':
          return 'The Network dependecy is the primary network in which ' + asset.get('assessmentName') + ' resides.';
      }
    }),

    componentsOfType: _ember['default'].computed('components.@each.type', 'type', function () {
      var _get;

      var type = this.get('type');
      var filter = type === 'app' ? ['isApp'] : ['type', type];
      return (_get = this.get('components')).filterBy.apply(_get, filter);
    }),

    componentOptions: _ember['default'].computed('components.@each.type', 'newComponents.[]', 'type', function () {
      var _this = this;

      var inUseComponentIds = this.get('newComponents').mapBy('id');
      return this.get('componentsOfType').reject(function (c) {
        return c.get('id') === _this.get('asset.id');
      }) // That aren't this comopnent
      .map(function (component) {
        return { component: component, isSelected: inUseComponentIds.includes(component.get('id')) };
      }); // include if it's selected
    }),

    actions: {
      edit: function edit() {
        var existing = this.get('dependencies').mapBy('dependsOnComponent');
        this.setProperties({ isEditing: true, newComponents: existing, error: null });
      },

      cancel: function cancel() {
        if (this.get('isSaving')) {
          return;
        }
        this.setProperties({ newComponents: null, isEditing: null, error: null });
      },

      save: function save() {
        var _this2 = this;

        var _getProperties2 = this.getProperties('newComponents', 'store', 'type', 'asset');

        var newComponents = _getProperties2.newComponents;
        var store = _getProperties2.store;
        var type = _getProperties2.type;
        var asset = _getProperties2.asset;

        var operations = [];
        var existingComponentIds = this.get('dependencies').mapBy('dependsOnComponent.id');
        var newComponentIds = this.get('newComponents').mapBy('id');
        this.setProperties({ isSaving: true, savingMessage: 'Updating ' + type + ' components for ' + asset.get('assessmentName'), error: null });

        // For each new component...
        newComponents.forEach(function (component) {
          // Does the new component exist in the current set of dependencies?
          // If so, do nothing
          if (!existingComponentIds.includes(component.get('id'))) {
            // If not, create dependency
            var dependency = store.createRecord('component-dependency', { component: asset, dependsOnComponent: component });
            asset.get('componentDependencies').pushObject(dependency);
            _this2.get('dependencies').pushObject(dependency);
            operations.push(dependency.save());
          }
        });

        // For each existing component...
        this.get('dependencies').forEach(function (componentDependency) {
          // Does the existing component persist in the new list of components?
          // If so, do nothing
          if (!newComponentIds.includes(componentDependency.get('dependsOnComponent.id'))) {
            // If not, delete it's dependency
            asset.get('componentDependencies').removeObject(componentDependency);
            _this2.get('dependencies').removeObject(componentDependency);
            operations.push(componentDependency.destroyRecord());
          }
        });

        _ember['default'].RSVP.all(operations).then(function () {
          _this2.set('savingMessage', 'Save successful!');
          _ember['default'].run.later(_this2, function () {
            _this2.setProperties({ isEditing: false, isSaving: false, savingMessage: false });
            _this2.get('onUpdate')();
          }, 1000);
        })['catch'](function (e) {
          var error = e.message || 'Error updating ' + asset.get('assessmentName') + '!';
          _this2.setProperties({ isEditing: true, isSaving: false, savingMessage: false, error: error });
        });
      },

      selectCurrentComponent: function selectCurrentComponent(componentOption) {
        var newComponents = this.get('newComponents');
        var component = componentOption.component;
        var isSelected = componentOption.isSelected;

        var type = this.get('type');

        if (type === 'network') {
          if (isSelected) {
            newComponents.removeObject(component);
          } else {
            newComponents = [component];
          }
        } else {
          var action = isSelected ? 'removeObject' : 'pushObject';
          newComponents[action](component);
        }

        this.set('newComponents', newComponents);
      }
    }
  });
});