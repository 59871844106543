define('diesel/gridiron-settings/components/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var organizationProfile = this.get('complianceStatus.organizationProfile');
      return _ember['default'].RSVP.hash({
        organizationProfile: organizationProfile,
        components: organizationProfile.get('components'),
        systems: organizationProfile.get('systems'),
        systemTemplates: this.store.findAll('system-template'),
        productionAccounts: this.get('complianceStatus.authorizationContext.accounts').filterBy('allowPHI', true)
      });
    },

    afterModel: function afterModel(model) {
      return _ember['default'].RSVP.hash({
        apps: _ember['default'].RSVP.all(model.productionAccounts.map(function (account) {
          return account.get('apps');
        })),
        databases: _ember['default'].RSVP.all(model.productionAccounts.map(function (account) {
          return account.get('databases');
        })),
        componentSystemDependencies: _ember['default'].RSVP.all(model.components.map(function (profile) {
          return profile.get('systemDependencies');
        }))
      });
    },

    setupController: function setupController(controller, model) {
      var productionApps = [];
      var productionDatabases = [];

      model.productionAccounts.forEach(function (account) {
        account.get('apps').forEach(function (app) {
          return productionApps.push(app);
        });
        account.get('databases').forEach(function (database) {
          return productionDatabases.push(database);
        });
      });

      // Match up component to any existing enclave app or database and attach it
      // to the model instance.  This makes it easier to detect if the given
      // component has already been imported (in the import modal)
      model.components.forEach(function (profile) {
        var appUrl = profile.get('appUrl');
        var databaseUrl = profile.get('databaseUrl');
        if (appUrl) {
          var app = productionApps.findBy('links.self', appUrl);
          if (app) {
            app.set('component', profile);
          }
        }

        if (databaseUrl) {
          var database = productionDatabases.findBy('links.self', databaseUrl);
          if (database) {
            database.set('component', profile);
          }
        }
      });

      var organizationProfile = model.organizationProfile;

      this._productionApps = productionApps;
      this._productionDatabases = productionDatabases;

      var hasImportables = productionApps.length || productionDatabases.length;

      controller.setProperties({
        organizationProfile: organizationProfile,
        productionApps: productionApps.sortBy('handle'),
        productionDatabases: productionDatabases.sortBy('handle'),
        hasImportables: hasImportables
      });
    },

    actions: {
      updateFilterType: function updateFilterType(type) {
        this.controller.set('filterType', type);
      },

      openImportModal: function openImportModal(type) {
        this.controller.importApps(this.currentModel, type);
      },

      openCreateModal: function openCreateModal(componentType) {
        var _currentModel = this.currentModel;
        var organizationProfile = _currentModel.organizationProfile;
        var components = _currentModel.components;
        var systems = _currentModel.systems;
        var systemTemplates = _currentModel.systemTemplates;

        var componentParams = { organizationProfile: organizationProfile, type: componentType };

        var _get$getProperties = this.get('complianceStatus.authorizationContext').getProperties('roles', 'users');

        var roles = _get$getProperties.roles;
        var users = _get$getProperties.users;

        var component = this.store.createRecord('component', componentParams);
        this.controller.editComponent(component, organizationProfile, components, systems, componentType, roles, users, systemTemplates);
      },

      openEditModal: function openEditModal(component) {
        var _currentModel2 = this.currentModel;
        var organizationProfile = _currentModel2.organizationProfile;
        var components = _currentModel2.components;
        var systems = _currentModel2.systems;
        var systemTemplates = _currentModel2.systemTemplates;

        var componentType = component.get('type');

        var _get$getProperties2 = this.get('complianceStatus.authorizationContext').getProperties('roles', 'users');

        var roles = _get$getProperties2.roles;
        var users = _get$getProperties2.users;

        this.controller.editComponent(component, organizationProfile, components, systems, componentType, roles, users, systemTemplates);
      },

      removeComponent: function removeComponent(component) {
        var organizationProfile = this.currentModel.organizationProfile;

        var handle = component.get('handle');
        var message = 'Are you sure you want to remove ' + handle + ' from Gridiron?';

        if (component.get('appUrl')) {
          var app = this._productionApps.findBy('links.self', component.get('appUrl'));
          if (app) {
            app.set('component', null);
          }
        }

        if (component.get('databaseUrl')) {
          var db = this._productionDatabases.findBy('links.self', component.get('databaseUrl'));
          if (db) {
            db.set('component', null);
          }
        }

        if (!window.confirm(message)) {
          return;
        }

        return component.destroyRecord().then(function () {
          organizationProfile.observeDocumentBuilds();
        });
      }
    }
  });
});