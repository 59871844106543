define('diesel/signup/invitation/route', ['exports', 'ember', 'diesel/components/login-form/component', 'diesel/mixins/routes/disallow-authenticated', 'diesel/utils/cookies', 'diesel/login/route'], function (exports, _ember, _dieselComponentsLoginFormComponent, _dieselMixinsRoutesDisallowAuthenticated, _dieselUtilsCookies, _dieselLoginRoute) {
  var CREATED_USER_FROM_INVITE_EVENT_NAME = 'Created User From Invitation';

  exports.CREATED_USER_FROM_INVITE_EVENT_NAME = CREATED_USER_FROM_INVITE_EVENT_NAME;
  exports['default'] = _ember['default'].Route.extend(_dieselMixinsRoutesDisallowAuthenticated['default'], {
    model: function model(params) {
      var invitationId = params.invitation_id;
      var verificationCode = params.verification_code;

      return this.store.find('invitation', invitationId).then(function (invitation) {
        return { invitation: invitation, verificationCode: verificationCode };
      });
    },

    afterModel: function afterModel(model) {
      var invitation = model.invitation;
      var verificationCode = model.verificationCode;

      var router = this.get('router');
      var afterAuthUrl = router.generate('claim', invitation.get('id'), verificationCode);
      _dieselUtilsCookies['default'].create(_dieselLoginRoute.AFTER_AUTH_COOKIE, afterAuthUrl, 2);
    },

    setupController: function setupController(controller, model) {
      var invitation = model.invitation;
      var verificationCode = model.verificationCode;

      _ember['default'].assert('This route\'s model hook must be passed an invitation id and verification code', invitation && verificationCode);

      var user = this.store.createRecord('user');
      user.set('email', invitation.get('email'));

      controller.set('model', user);

      // used later to redirect user back to accept invitation
      controller.set('invitation', invitation);
      controller.set('verificationCode', verificationCode);
    },
    actions: {
      signup: function signup(user) {
        var _this = this;

        this.controller.set('isSaving', true);

        return user.save().then(function () {
          var _user$getProperties = user.getProperties('email', 'password');

          var email = _user$getProperties.email;
          var password = _user$getProperties.password;

          var credentials = (0, _dieselComponentsLoginFormComponent.buildCredentials)(email, password);
          return _this.get('session').open('application', credentials);
        }).then(function () {
          var invitationId = _this.controller.get('invitation.id');
          var verificationCode = _this.controller.get('verificationCode');
          _this.get('analytics').track(CREATED_USER_FROM_INVITE_EVENT_NAME, {});
          _this.transitionTo('claim', invitationId, verificationCode);
        })['catch'](function (e) {
          return _this.get('flashMessages').danger('There was an error: ' + e.message);
        })['finally'](function () {
          return _this.controller.set('isSaving', false);
        });
      }
    }
  });
});