define('diesel/mixins/services/criterion-status', ['exports', 'ember', 'diesel/mixins/services/document-status'], function (exports, _ember, _dieselMixinsServicesDocumentStatus) {
  exports['default'] = _ember['default'].Mixin.create({
    documents: null,
    productionApps: _ember['default'].computed.alias('complianceStatus.productionApps'),
    isAppSecurity: _ember['default'].computed.equal('criterion.handle', 'app_security_interview'),
    isBasicTraining: _ember['default'].computed.equal('criterion.handle', 'training_log'),
    isSecurityTraining: _ember['default'].computed.equal('criterion.handle', 'security_officer_training_log'),
    isDeveloperTraining: _ember['default'].computed.equal('criterion.handle', 'developer_training_log'),
    isRiskAssessment: _ember['default'].computed.equal('criterion.handle', 'risk_assessment'),
    isPolicyManual: _ember['default'].computed.equal('criterion.handle', 'policy_manua'),
    isRed: _ember['default'].computed.equal('status', _dieselMixinsServicesDocumentStatus.COMPLIANCE_STATUSES.INCOMPLETE),
    isYellow: _ember['default'].computed.equal('status', _dieselMixinsServicesDocumentStatus.COMPLIANCE_STATUSES.NEEDS_ATTENTION),
    isGreen: _ember['default'].computed.equal('status', _dieselMixinsServicesDocumentStatus.COMPLIANCE_STATUSES.ACTIVE),

    status: _ember['default'].computed('hasActiveDocuments', 'hasNoDocuments', function () {
      if (this.get('hasActiveDocuments')) {
        return _dieselMixinsServicesDocumentStatus.COMPLIANCE_STATUSES.ACTIVE;
      }

      if (this.get('hasNoDocuments')) {
        return _dieselMixinsServicesDocumentStatus.COMPLIANCE_STATUSES.INCOMPLETE;
      }

      return _dieselMixinsServicesDocumentStatus.COMPLIANCE_STATUSES.NEEDS_ATTENTION;
    }),

    statusText: _ember['default'].computed('isRed', 'isYellow', 'isGreen', function () {
      if (this.get('isYellow')) {
        return 'Complete, but expired on ' + this.get('lastExpirationDate');
      }

      if (this.get('isGreen')) {
        return 'Last completed on ' + this.get('lastCompletedDate') + '. Expires on ' + this.get('lastCompletedExpirationDate');
      }

      return 'Never completed.';
    })
  }, _dieselMixinsServicesDocumentStatus['default']);
});