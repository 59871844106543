define('diesel/organization/environments/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    sortBy: ['handle:asc'],

    activatedAccounts: _ember['default'].computed.filterBy('model', 'activated'),
    pendingAccounts: _ember['default'].computed.filterBy('model', 'pending'),

    sortedActivatedAccounts: _ember['default'].computed.sort('activatedAccounts', 'sortBy'),
    sortedPendingAccounts: _ember['default'].computed.sort('pendingAccounts', 'sortBy'),

    hasPending: _ember['default'].computed.gt('pendingAccounts.length', 0)
  });
});