define('diesel/components/modal-close-incident/component', ['exports', 'ember', 'diesel/mixins/components/modal-base'], function (exports, _ember, _dieselMixinsComponentsModalBase) {
  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsModalBase['default'], {
    // Inputs
    closeIncident: null,

    title: 'Mark as Closed',

    actions: {
      closeIncident: function closeIncident() {
        this.sendAction('onCloseIncident', this.get('closeIncident'));
      }
    }
  });
});