define('diesel/models/security-review', ['exports', 'ember-data', 'diesel/models/base'], function (exports, _emberData, _dieselModelsBase) {
  exports['default'] = _dieselModelsBase['default'].extend({
    dueAt: _emberData['default'].attr('iso-8601-timestamp'),
    systemConfig: _emberData['default'].attr('system-config'),
    completedAt: _emberData['default'].attr('iso-8601-timestamp'),
    completedByUserName: _emberData['default'].attr('string'),
    completedByUserEmail: _emberData['default'].attr('string'),
    incompleteFinalizeReason: _emberData['default'].attr('string'),
    assignedTo: _emberData['default'].belongsTo('user'),
    completedBy: _emberData['default'].belongsTo('user'),

    organizationProfile: _emberData['default'].belongsTo('organization-profile', { async: true, inverse: 'currentSecurityReview' }),

    addToCompletedReviews: function addToCompletedReviews() {
      var _this = this;

      this.get('organizationProfile').then(function (organizationProfile) {
        if (organizationProfile) {
          organizationProfile.get('completedSecurityReviews').then(function (completed) {
            completed.pushObject(_this);
          });
        }
      });
    }
  });
});