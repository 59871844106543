define('diesel/components/metric-drain-influxdb-database-configuration/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    account: null,
    databases: null,

    influxdbDatabases: _ember['default'].computed.filterBy('databases', 'type', 'influxdb'),

    actions: {
      onUpdate: function onUpdate(database) {
        this.sendAction('update', database);
      }
    }
  });
});