define('diesel/log-drains/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    sortBy: ['handle:asc'],
    persistedLogDrains: _ember['default'].computed.filterBy('model', 'isNew', false),
    hasNoLogDrains: _ember['default'].computed.equal('persistedLogDrains.length', 0),
    provisionedLogDrains: _ember['default'].computed.filterBy('persistedLogDrains', 'isProvisioned'),
    pendingLogDrains: _ember['default'].computed.filterBy('persistedLogDrains', 'isPending'),
    provisioningLogDrains: _ember['default'].computed.filterBy('persistedLogDrains', 'isProvisioning'),
    failedProvisioningLogDrains: _ember['default'].computed.filterBy('persistedLogDrains', 'hasFailedProvision'),
    failedDeprovisioningLogDrains: _ember['default'].computed.filterBy('persistedLogDrains', 'hasFailedDeprovision'),
    pendingOrProvisioningLogDrains: _ember['default'].computed.union('provisioningLogDrains', 'pendingLogDrains'),
    deprovisionedLogDrains: _ember['default'].computed.filterBy('persistedLogDrains', 'hasBeenDeprovisioned'),
    hasActive: _ember['default'].computed.gt('provisionedLogDrains.length', 0),
    hasPending: _ember['default'].computed.gt('pendingOrProvisioningLogDrains.length', 0),
    hasDeprovisioning: _ember['default'].computed.gt('deprovisionedLogDrains.length', 0),
    hasFailedProvision: _ember['default'].computed.gt('failedProvisioningLogDrains.length', 0),
    hasFailedDeprovision: _ember['default'].computed.gt('failedDeprovisioningLogDrains.length', 0),
    showSortableHeader: _ember['default'].computed.or('hasPending', 'hasDeprovisioning'),

    scopesForCreateLogDrains: ['deploy', 'observability'],

    actions: {
      completedAction: function completedAction(message) {
        _ember['default'].get(this, 'flashMessages').success(message);
      },

      failedAction: function failedAction(message) {
        _ember['default'].get(this, 'flashMessages').danger(message);
      }
    }
  });
});