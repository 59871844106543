define('diesel/models/backup-retention-policy', ['exports', 'ember-data', 'diesel/models/base'], function (exports, _emberData, _dieselModelsBase) {
  exports['default'] = _dieselModelsBase['default'].extend({
    daily: _emberData['default'].attr('number'),
    monthly: _emberData['default'].attr('number'),
    createdAt: _emberData['default'].attr('iso-8601-timestamp'),
    keepFinal: _emberData['default'].attr('boolean'),
    makeCopy: _emberData['default'].attr('boolean'),

    account: _emberData['default'].belongsTo('account', { async: true })
  });
});