define("diesel/components/modal-create-db/component", ["exports", "ember", "diesel/config/environment", "ember-validations", "diesel/mixins/components/modal-base"], function (exports, _ember, _dieselConfigEnvironment, _emberValidations, _dieselMixinsComponentsModalBase) {
  exports["default"] = _ember["default"].Component.extend(_emberValidations["default"], _dieselMixinsComponentsModalBase["default"], {
    session: _ember["default"].inject.service(),

    newDb: null,
    containerSize: 1024,
    diskSize: 10,

    disableSave: _ember["default"].computed.or('hasError', 'isPending', 'isHandleEmpty'),
    isPending: _ember["default"].computed.or('newDb.isSaving', 'newDb.isValidating'),
    isHandleEmpty: _ember["default"].computed.empty('newDb.handle'),
    hasError: _ember["default"].computed.gt('errors.newDb.handle.length', 0),
    title: _ember["default"].computed('account.handle', function () {
      return "Create a new database on " + this.get('account.handle');
    }),

    description: _ember["default"].computed('account.allowPHI', function () {
      if (this.get('account.allowPHI')) {
        return this.get('account.handle') + " is a dedicated environment. Databases\n              created in this environment are safe for regulated data.";
      }

      return this.get('account.handle') + " is a shared environment.  Databases created\n            in this environment are NOT safe for regulated data.";
    }),

    validations: {
      'newDb.handle': {
        uniqueness: {
          message: 'is taken.',
          paramName: 'handle',
          url: function url() {
            return (_dieselConfigEnvironment["default"].apiBaseUri || '') + "/accounts/" + this.get('account.id') + "/claims/database";
          },
          headers: function headers() {
            return { 'Authorization': "Bearer " + this.get("session.token.accessToken") };
          }
        }
      }
    },

    actions: {
      beforeDismiss: function beforeDismiss() {
        this.get('newDb').rollbackAttributes();
      },

      createDb: function createDb() {
        var db = this.get('newDb');
        var diskSize = this.get('diskSize');
        var containerSize = this.get('containerSize');
        this.sendAction('onCreateDb', db, diskSize, containerSize);
      },

      diskSizeDidSlide: function diskSizeDidSlide(val) {
        this.set('diskSize', val);
      },

      containerSizeDidSlide: function containerSizeDidSlide(val) {
        this.set('containerSize', val);
      },

      selectDbType: function selectDbType(type) {
        this.set('newDb.type', type);
      },

      imageSelected: function imageSelected(type) {
        this.set('newDb.databaseImage', type);
      }
    }
  });
});