define('diesel/organization/members/otp-reset/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params) {
      var context = this.modelFor('organization');
      return context.get('users').findBy('id', params.user_id);
    },

    setupController: function setupController(controller, model) {
      var context = this.modelFor('organization');
      controller.set('model', model);
      controller.set('authorizationContext', context);
    },

    resetController: function resetController(controller) {
      controller.setProperties({
        error: null,
        hasSubmitted: false
      });
    },

    actions: {
      reset: function reset(model) {
        var _this = this;

        var challenge = this.store.createRecord("otp-reset-challenge", {
          user: model
        });
        challenge.save().then(function () {
          var message = 'A 2FA reset email has been sent to ' + model.get('email');
          _ember['default'].get(_this, 'flashMessages').success(message);
          _this.transitionTo('organization.members.index');
        }, function (e) {
          var message = e.message || 'There was an error resetting ' + model.get('email') + '\'s 2FA.';
          _this.controller.set('error', message);
        })['finally'](function () {
          _this.controller.set("hasSubmitted", false);
        });
      },
      willTransition: function willTransition() {
        this.currentModel.rollbackAttributes();
        this.controllerFor('otp-reset').set('error', null);
      },
      cancel: function cancel() {
        this.transitionTo('organization.members');
      }
    }
  });
});