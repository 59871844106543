define('diesel/components/database-vhosts-list/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    // Required parameters
    database: null,
    vhosts: null,

    actions: {
      addVhost: function addVhost() {
        this.sendAction('onAddVhost');
      },

      editVhost: function editVhost(vhost) {
        this.sendAction('onEditVhost', vhost);
      },

      deleteVhost: function deleteVhost(vhost) {
        this.sendAction('onDeleteVhost', vhost);
      },

      revealVhostCredentials: function revealVhostCredentials(vhost) {
        this.sendAction('onRevealVhostCredentials', vhost);
      }
    }
  });
});