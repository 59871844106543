define('diesel/models/global-control-framework', ['exports', 'ember', 'ember-data', 'diesel/models/base'], function (exports, _ember, _emberData, _dieselModelsBase) {
  exports['default'] = _dieselModelsBase['default'].extend({
    globalControl: _emberData['default'].belongsTo('globalControl'),
    framework: _emberData['default'].belongsTo('framework', { async: false }),
    category: _emberData['default'].attr('string'),
    frameworkName: _ember['default'].computed('framework.name', 'category', function () {
      var name = this.get('framework.name');
      var category = this.get('category');

      var nameSuffix = '';
      if (name === 'HIPAA') {
        nameSuffix = category === "Required" ? ' Required' : ' Addressable';
      }

      return '' + name + nameSuffix;
    }),
    metricHandle: _ember['default'].computed('framework.name', 'category', function () {
      var name = this.get('framework.name');
      var category = this.get('category');

      var handleSuffix = '';
      if (name === 'HIPAA') {
        handleSuffix = category === "Required" ? '-required' : '-addressable';
      }

      return '' + name.dasherize() + handleSuffix;
    })
  });
});