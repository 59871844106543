define('diesel/components/user-table-row/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['aptable__member-row'],
    tagName: 'tr',

    organizationRoles: _ember['default'].computed('user.roles.[]', 'organization', function () {
      var organizationHref = this.get('organization.links.self');
      return this.get('user.roles').filterBy('links.organization', organizationHref).sortBy('name');
    }),

    csvLastIndex: _ember['default'].computed('organizationRoles.[]', function () {
      return this.get('organizationRoles.length') - 1;
    })
  });
});