define('diesel/components/modal-vendor/component', ['exports', 'ember', 'diesel/mixins/components/modal-base', 'diesel/mixins/models/classification'], function (exports, _ember, _dieselMixinsComponentsModalBase, _dieselMixinsModelsClassification) {

  var ERROR_MESSAGE = 'There was an error saving the vendor';

  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsModalBase['default'], {
    modalDetails: null, // set by route to open this modal
    allRegulatedDataTypes: _dieselMixinsModelsClassification.REGULATED_DATA_TYPES,

    availableRegulatedDataTypes: _ember['default'].computed('allRegulatedDataTypes.[]', 'model.systemRegulatedDataTypes.[]', function () {
      var allRegulatedDataTypes = this.get('allRegulatedDataTypes');
      var systemRegulatedDataTypes = this.get('model.systemRegulatedDataTypes');
      return allRegulatedDataTypes.filter(function (d) {
        return !systemRegulatedDataTypes.includes(d.handle);
      });
    }),

    disabledRegulatedDataTypes: _ember['default'].computed('allRegulatedDataTypes.[]', 'model.systemRegulatedDataTypes.[]', function () {
      var allRegulatedDataTypes = this.get('allRegulatedDataTypes');
      var systemRegulatedDataTypes = this.get('model.systemRegulatedDataTypes');
      return allRegulatedDataTypes.filter(function (d) {
        return systemRegulatedDataTypes.includes(d.handle);
      });
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('model', this.get('modalDetails.model'));

      if (this.get('model.isNew')) {
        this.set('title', 'Add Vendor');
      } else {
        this.set('title', 'Edit Vendor');
      }
    },

    disableSave: _ember['default'].computed.or('hasError', 'isPending'),
    isPending: _ember['default'].computed.alias('model.isSaving'),
    hasError: _ember['default'].computed.gt('errors.model.name.length', 0),

    buttonLabel: _ember['default'].computed('model.isNew', function () {
      if (this.get('model.isNew') === false) {
        return 'Save Changes';
      }
      return 'Create Vendor';
    }),

    actions: {
      save: function save() {
        var _this = this;

        var model = this.get('model');

        this.set('isSaving', true);
        return model.save().then(function (model) {
          _this.sendAction('onUpdate', model);
          _this.sendAction('dismissModal');
        })['catch'](function (e) {
          _this.set('errors', e.errors.message || ERROR_MESSAGE);
        })['finally'](function () {
          _this.set('isSaving', false);
        });
      },

      beforeDismiss: function beforeDismiss() {
        this.get('model').rollbackAttributes();

        // Fixes issue where tearing down the template would update
        // properties bound to the model's attrs and would cause Ember Data to
        // complain about modifying a deleted record
        this.set('model', null);
        this.set('errors', null);
      }
    }
  });
});