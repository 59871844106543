define('diesel/utils/training/culture-and-security-assessment', ['exports'], function (exports) {
  exports['default'] = { passingScore: 5, questions: [{
      title: 'What is an Information Security Management System (ISMS)?',
      choices: ['The set of policies, procedures, guidelines, and related resources and activities that help an organization protect its information assets', 'A temporary response system enacted in the wake of a data breach', 'An operating system that can be installed on all employee devices to prevent security incidents', 'A government-appointed board of auditors who test systems of national security importance'],
      answer: 0,
      error: 'Incorrect answer'
    }, {
      title: 'What is meant by “Data Minimization”?',
      choices: ['Compressing Big Data to more manageable file sizes', 'Reducing the scope of sensitive data down to necessary components', 'Containing Public Relations fallout following a security incident', 'Keeping employees in the dark about security best practices'],
      answer: 1,
      error: 'Incorrect answer'
    }, {
      title: 'We should only be concerned with adversarial threat sources.',
      choices: ['True', 'False'],
      answer: 1,
      error: 'Incorrect answer'
    }, {
      title: 'Which of the following is NOT an example of an adversarial risk?',
      choices: ['Hacktivism', 'Advanced Persistent Threats', 'Accidents', 'Insider Hacking'],
      answer: 2,
      error: 'Incorrect answer'
    }, {
      title: 'Which of the following strategies can be effective ways of reducing the risk of a data breach?',
      choices: ['Applying technical controls', 'Training employees about security', 'Testing and independently auditing your controls', 'All of the above'],
      answer: 3,
      error: 'Incorrect answer'
    }, {
      title: 'Which of the following is NOT an effective method of building a strong security culture?',
      choices: ['Publicly praising individuals who bring up security concerns', 'Hiring a rockstar Security Officer and announcing that no one else need concern themselves with security anymore', 'Including security and privacy in performance reviews', 'Publicly praising individuals who take action to mitigate security risks'],
      answer: 1,
      error: 'Incorrect answer'
    }] };
});