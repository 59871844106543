define('diesel/mixins/models/asset', ['exports', 'ember', 'ember-data', 'diesel/mixins/models/risk-scope', 'diesel/mixins/models/ownable'], function (exports, _ember, _emberData, _dieselMixinsModelsRiskScope, _dieselMixinsModelsOwnable) {
  var STATUSES = [{ handle: 'active', name: 'Active' }, { handle: 'retired', name: 'Retired' }];

  exports.STATUSES = STATUSES;
  var TIME_UNITS = [{ handle: 'minutes', name: 'Minutes' }, { handle: 'hours', name: 'Hours' }, { handle: 'days', name: 'Days' }, { handle: 'weeks', name: 'Weeks' }, { handle: 'months', name: 'Months' }, { handle: 'years', name: 'Years' }];

  exports.TIME_UNITS = TIME_UNITS;
  var APPS = 'apps';
  exports.APPS = APPS;
  var DATABASES = 'databases';
  exports.DATABASES = DATABASES;
  var NETWORKS = 'networks';
  exports.NETWORKS = NETWORKS;
  var COMPUTE_BACKENDS = 'compute_backends';
  exports.COMPUTE_BACKENDS = COMPUTE_BACKENDS;
  var DATA_STORES = 'data_stores';
  exports.DATA_STORES = DATA_STORES;
  var LOGGING_DESTINATIONS = 'logging_destinations';
  exports.LOGGING_DESTINATIONS = LOGGING_DESTINATIONS;
  var SAAS_SERVICES = 'saas_services';
  exports.SAAS_SERVICES = SAAS_SERVICES;
  var LOCATIONS = 'locations';

  exports.LOCATIONS = LOCATIONS;
  var ASSET_TYPES = [APPS, DATABASES, NETWORKS, COMPUTE_BACKENDS, DATA_STORES, LOGGING_DESTINATIONS, SAAS_SERVICES, LOCATIONS];

  exports.ASSET_TYPES = ASSET_TYPES;
  var STORAGE_ASSET_TYPES = ['database', 'primary', 'storage', 'loggging'];

  exports.STORAGE_ASSET_TYPES = STORAGE_ASSET_TYPES;
  var REQUIRES_RECOVERY_TIME = STORAGE_ASSET_TYPES.concat(['api_web', 'frontend_web', 'single_page', 'isomorphic', 'tcp_service', 'background', 'mac', 'ios', 'android', 'windows', 'network']);

  exports.REQUIRES_RECOVERY_TIME = REQUIRES_RECOVERY_TIME;
  exports['default'] = _ember['default'].Mixin.create(_dieselMixinsModelsRiskScope['default'], _dieselMixinsModelsOwnable['default'], {
    createdAt: _emberData['default'].attr('iso-8601-timestamp'),
    updatedAt: _emberData['default'].attr('iso-8601-timestamp'),
    description: _emberData['default'].attr('string'),
    purpose: _emberData['default'].attr('string'),
    hasInfo: _ember['default'].computed('description', 'purpose', function () {
      return this.get('description') || this.get('purpose');
    }),
    notes: _emberData['default'].attr('string'),

    status: _emberData['default'].attr('string', { defaultValue: STATUSES[0].handle }),
    readableStatus: _ember['default'].computed('status', function () {
      return STATUSES.findBy('handle', this.get('status')).name;
    }),

    requiresStorageAttrs: _ember['default'].computed('type', function () {
      return STORAGE_ASSET_TYPES.includes(this.get('type'));
    }),

    requiresRecoveryAttrs: _ember['default'].computed('type', function () {
      return REQUIRES_RECOVERY_TIME.includes(this.get('type'));
    }),

    requiresOtherLegalRequirement: _ember['default'].computed('legalRequirements.[]', function () {
      return this.get('legalRequirements') && this.get('legalRequirements').includes('other');
    }),

    canHaveDependencies: _ember['default'].computed.or('isApp', 'isDatabase')
  });
});