define('diesel/account/index/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    redirect: function redirect() {
      var account = this.modelFor('account');
      if (!account.get('activated')) {
        return this.transitionTo('account.activate');
      }

      if (this.get('authorization').checkAbility('basic_read', account)) {
        this.transitionTo('apps');
      }
      if (this.get('authorization').checkAbility('read', account)) {
        this.transitionTo('apps');
      }
    }
  });
});