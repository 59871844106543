define('diesel/organization/admin/billing/billing-iteration/payments/new/route', ['exports', 'ember'], function (exports, _ember) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var BillingRoute = 'organization.admin.billing';
  var InvoiceRoute = BillingRoute + '.billing-iteration';
  var PaymentsRoute = InvoiceRoute + '.payments';

  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var billingDetail = this.modelFor(BillingRoute);
      var invoice = this.modelFor(InvoiceRoute);

      var _modelFor = this.modelFor(PaymentsRoute);

      var payments = _modelFor.payments;

      var paymentMethods = billingDetail.get('paymentMethodsForManualPayment');

      // TODO: need to tell the user what to do if the sequence number is invalid.
      return _ember['default'].RSVP.hash({ billingDetail: billingDetail, invoice: invoice, payments: payments, paymentMethods: paymentMethods }).then(function (_ref) {
        var payments = _ref.payments;
        var paymentMethods = _ref.paymentMethods;

        var sequenceNumber = 0;

        var paymentSequenceNumbers = payments.map(function (p) {
          return p.get('sequenceNumber');
        });
        if (paymentSequenceNumbers.length > 0) {
          sequenceNumber = Math.max.apply(Math, _toConsumableArray(paymentSequenceNumbers)) + 1;
        }

        return {
          model: {
            paymentMethod: paymentMethods.get('firstObject'),
            cashValue: null
          },
          paymentMethods: paymentMethods, sequenceNumber: sequenceNumber
        };
      });
    },

    afterModel: function afterModel(_ref2) {
      var paymentMethods = _ref2.paymentMethods;

      if (paymentMethods.get('length') > 0) {
        return;
      }

      this.get('flashMessages').danger('You have no usable Payment Method to make Payments');
      this.transitionTo(BillingRoute + '.payment-methods.new');
    },

    setupController: function setupController(controller, model) {
      controller.setProperties(model);
    },

    actions: {
      savePayment: function savePayment(model, sequenceNumber) {
        var _this = this;

        var invoice = this.modelFor(InvoiceRoute);
        var paymentMethod = model.paymentMethod;
        var cashValue = model.cashValue;

        var payment = this.store.createRecord('payment', {
          invoice: invoice, paymentMethod: paymentMethod, cashValue: cashValue, sequenceNumber: sequenceNumber });

        // TODO
        this.controller.set('isSaving', true);

        payment.save()['catch'](function (e) {
          payment.rollbackAttributes();
          throw e;
        }).then(function () {
          var closure = payment.get('paymentClosure');
          if (closure) {
            var _closure$getProperties = closure.getProperties('success', 'message');

            var success = _closure$getProperties.success;
            var message = _closure$getProperties.message;

            var method = success ? 'success' : 'danger';
            _this.get('flashMessages')[method](message);
            return invoice.reload();
          }
        }).then(function () {
          _this.transitionTo(PaymentsRoute);
        })['catch'](function (e) {
          _this.get('flashMessages').danger(e.message);
        })['finally'](function () {
          _this.controller.set('isSaving', false);
        });
      }
    }
  });
});