define('diesel/gridiron-admin/security-reviews/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    modalDisplay: _ember['default'].inject.service(),

    model: function model() {
      var organizationProfile = this.modelFor('gridiron-admin');

      return _ember['default'].RSVP.hash({
        organizationProfile: organizationProfile,
        systems: organizationProfile.get('systems'),
        currentSecurityReview: organizationProfile.get('currentSecurityReview'),
        completedSecurityReviews: organizationProfile.get('completedSecurityReviews')
      });
    },

    setupController: function setupController(controller, data) {
      controller.setProperties(data);
    },

    refreshModel: function refreshModel() {
      var _this = this;

      var organizationProfile = this.modelFor('gridiron-admin');
      organizationProfile.reloadCurrentSecurityReview().then(function () {
        _this.refresh();
      });
    },

    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);

      this.render('sidebars/engine-sidebar', {
        into: 'gridiron-admin.security-reviews',
        outlet: 'sidebar',
        controller: this.controllerFor('gridiron-organization')
      });
    },

    actions: {
      beginSecurityReview: function beginSecurityReview(securityReview) {
        var modalDisplay = this.get('modalDisplay');
        modalDisplay.showModal('modal-security-review', { securityReview: securityReview }, {
          actions: {
            onReviewComplete: modalDisplay.modalAction(this, 'onReviewComplete')
          }
        });
      },

      onReviewComplete: function onReviewComplete() {
        // Whenever a review is completed, reload
        this.refreshModel();
      }
    }
  });
});