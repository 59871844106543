define('diesel/components/backup-item/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),

    classNameBindings: [':panel', ':panel-default', ':resource-list-item'],
    orphan: false,
    canRestore: _ember['default'].computed('restore', function () {
      return !!this.get('restore');
    }),

    actions: {
      restore: function restore(backup) {
        this.sendAction('restore', backup);
      },
      purge: function purge(backup) {
        var _this = this;

        return backup.get('copies').then(function (copies) {
          // Confirm...
          var confirmMsg = '\nPurging will remove this backup';

          if (copies.length == 1) {
            confirmMsg += ' and its copy';
          }

          if (copies.length > 1) {
            confirmMsg += ' and its copies';
          }

          confirmMsg += '. This CANNOT be undone.\n\n';
          confirmMsg += 'Are you sure you want to continue?\n';
          if (!confirm(confirmMsg)) {
            return false;
          }

          _this.sendAction('purge', backup);
        });
      }
    }
  });
});