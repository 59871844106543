define('diesel/models/component', ['exports', 'ember-data', 'diesel/models/base', 'ember', 'diesel/mixins/models/asset', 'diesel/mixins/models/classification', 'diesel/mixins/models/business-criticality', 'diesel/mixins/models/impacts'], function (exports, _emberData, _dieselModelsBase, _ember, _dieselMixinsModelsAsset, _dieselMixinsModelsClassification, _dieselMixinsModelsBusinessCriticality, _dieselMixinsModelsImpacts) {
  var COMPONENT_TYPES = [{ handle: 'api_web', name: 'Web API', enclave: true, app: true, tooltip: 'An App that has an HTTPS Endpoint that serves HTTPS traffic consumed mostly by application clients rather than human users.' }, { handle: 'frontend_web', name: 'Web App', enclave: true, app: true, tooltip: 'An App that has an HTTPS Endpoint that serves HTTPS traffic consumed mostly by human users.' }, { handle: 'single_page', name: 'Single-Page App', enclave: true, app: true, tooltip: 'An App that is served statically with HTML and JavaScript.' }, { handle: 'isomorphic', name: 'Isomorphic/Universal App', enclave: true, app: true, tooltip: 'An App that has an HTTPS Endpoint, renders universally on the server and client, and is consumed mostly by human users.' }, { handle: 'tcp_service', name: 'TCP Service', enclave: true, app: true, tooltip: 'An App that has a TCP (non-HTTPS) Endpoint and listens for TCP traffic.' }, { handle: 'background', name: 'Background Service', enclave: true, app: true, tooltip: 'An App that has no Endpoint and does not communicate with other apps/services by listening for TCP/HTTP traffic.' }, { handle: 'mac', name: 'macOS App', app: true, tooltip: 'An App that runs on Apple macOS' }, { handle: 'ios', name: 'iOS App', app: true, tooltip: 'An App that runs on Apple iOS.' }, { handle: 'android', name: 'Android App', app: true, tooltip: 'An App that runs on Android OS.' }, { handle: 'windows', name: 'Windows App', app: true, tooltip: 'An App that runs on Microsoft Windows OS.' }, { handle: 'database', name: 'Database' }, { handle: 'network', name: 'Network' }];

  exports.COMPONENT_TYPES = COMPONENT_TYPES;
  var APP_TYPES = COMPONENT_TYPES.filterBy('app').mapBy('handle');

  exports.APP_TYPES = APP_TYPES;
  var DEVELOPER_TYPES = [{ handle: 'in_house', name: 'In-house' }, { handle: 'contractor', name: 'Contractor' }, { handle: 'open_source', name: 'Open Source' }, { handle: 'license', name: 'Purchase/License' }];

  exports.DEVELOPER_TYPES = DEVELOPER_TYPES;
  var Component = _dieselModelsBase['default'].extend(_dieselMixinsModelsAsset['default'], _dieselMixinsModelsClassification['default'], _dieselMixinsModelsBusinessCriticality['default'], _dieselMixinsModelsImpacts['default'], {
    isComponent: true,
    appUrl: _emberData['default'].attr('string'),
    databaseUrl: _emberData['default'].attr('string'),
    handle: _emberData['default'].attr('string'),
    type: _emberData['default'].attr('string', { defaultValue: 'api_web' }),
    primarySystem: _emberData['default'].belongsTo('system', {
      async: false
    }),
    attestations: _emberData['default'].hasMany('attestation'),
    systemDependencies: _emberData['default'].hasMany('system-dependency', { async: true }),
    componentDependencies: _emberData['default'].hasMany('component-dependency', { inverse: 'component', async: true }),
    dependencies: _emberData['default'].hasMany('component', { async: true, inverse: 'dependents' }),
    dependents: _emberData['default'].hasMany('component', { async: true, inverse: 'dependencies' }),
    app: _emberData['default'].belongsTo('app', { async: true }),
    database: _emberData['default'].belongsTo('database', { async: true }),
    organizationProfile: _emberData['default'].belongsTo('organization-profile', { async: true }),

    assessmentName: _ember['default'].computed.reads('handle'),
    typeConfig: _ember['default'].computed('typeConfig', function () {
      return COMPONENT_TYPES.findBy('handle', this.get('type'));
    }),

    shortReadableType: _ember['default'].computed('typeConfig', function () {
      var typeConfig = this.get('typeConfig');
      if (typeConfig.app) {
        return 'App';
      }
      return this.get('type').capitalize();
    }),
    readableType: _ember['default'].computed.reads('typeConfig.name'),
    readableTypeDescription: _ember['default'].computed.reads('typeConfig.tooltip'),

    isApp: _ember['default'].computed.bool('typeConfig.app'),
    isNetwork: _ember['default'].computed.equal('type', 'network'),
    isDatabase: _ember['default'].computed.equal('type', 'database'),

    hasSystems: _ember['default'].computed.or('primarySystem', 'systemDependencies.length'),
    developedBy: _emberData['default'].attr('string', { defaultValue: DEVELOPER_TYPES[0].handle }),
    loggingSystemDependencies: _ember['default'].computed('systemDependencies.[]', function () {
      return this.get('systemDependencies').filter(function (sd) {
        return sd.get('system.isLogging');
      });
    }),

    /* Updates related models and saves component.
     * Each relationship has to first be loaded and systems will need to be
     * `findOrCreate`ed.  This should happen sequentially so we don't try to double
     * create a given system.
     */
    updateRelationshipsAndSave: function updateRelationshipsAndSave(newPrimarySystem, newSecondarySystems, newDependsOnComponents) {
      var _this = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        var initialPromise = undefined;

        if (_this.get('isNew')) {
          initialPromise = _this.save();
        } else {
          initialPromise = _ember['default'].RSVP.resolve(_this);
        }

        initialPromise.then(function () {
          return _this.get('systemDependencies');
        }).then(function () {
          return _this.get('organizationProfile');
        }).then(function (organizationProfile) {
          if (newPrimarySystem) {
            return organizationProfile.findOrCreateSystem(newPrimarySystem);
          }

          return _ember['default'].RSVP.resolve();
        }).then(function (primarySystem) {
          var organizationProfile = _this.get('organizationProfile.content');
          if (newPrimarySystem && primarySystem) {
            _this.set('primarySystem', primarySystem);
          }

          if (newSecondarySystems) {
            newSecondarySystems = newSecondarySystems.reject(function (s) {
              return !s;
            });
            return _ember['default'].RSVP.all(newSecondarySystems.map(function (system) {
              return organizationProfile.findOrCreateSystem(system);
            }));
          }

          return _ember['default'].RSVP.resolve();
        }).then(function (secondarySystems) {
          if (!newSecondarySystems || !secondarySystems) {
            return _ember['default'].RSVP.resolve();
          }

          var promises = [];
          var newSystemHrefs = secondarySystems.mapBy('links.self');
          var currentSystemHrefs = _this.get('systemDependencies').mapBy('system.links.self');

          // Look at all current, if any current are not in new, delete that
          // current
          currentSystemHrefs.forEach(function (currentHref) {
            if (!newSystemHrefs.includes(currentHref)) {
              // delete currentHref
              var dependency = _this.get('systemDependencies').findBy('system.links.self', currentHref);
              promises.push(dependency.destroyRecord());
            }
          });

          // Look at all new, if any in new are not in current, create that
          // current
          newSystemHrefs.forEach(function (newHref) {
            if (!currentSystemHrefs.includes(newHref)) {
              // create new system
              var newSystem = secondarySystems.findBy('links.self', newHref);
              var dependency = _this.store.createRecord('system-dependency', {
                component: _this, system: newSystem
              });

              promises.push(dependency.save());
            }
          });

          return _ember['default'].RSVP.all(promises);
        }).then(function () {
          return _this.get('componentDependencies');
        }).then(function (currentComponentDependencies) {
          if (!newDependsOnComponents) {
            return _ember['default'].RSVP.resolve();
          }

          var promises = [];
          var newComponentIdsToDependOn = newDependsOnComponents.mapBy('id');
          var currentDependsOnComponents = currentComponentDependencies.mapBy('dependsOnComponent');
          var currentComponentIdsToDependOn = currentDependsOnComponents.mapBy('id');
          var currentDependsOnComponentsToRemove = [];

          // Look at all current, if any are not in new, delete that dependency
          // and remove from current
          currentDependsOnComponents.forEach(function (dependsOnComponent) {
            var dependsOnId = dependsOnComponent.get('id');
            if (!newComponentIdsToDependOn.includes(dependsOnId)) {
              var componentDependency = _this.get('componentDependencies').findBy('dependsOnComponent.id', dependsOnComponent.get('id'));

              // Gather all the components here to remove before removing them
              currentDependsOnComponentsToRemove.push({
                dependsOnComponent: dependsOnComponent, componentDependency: componentDependency
              });
            }
          });

          // Remove all the dependent components that were unselected
          currentDependsOnComponentsToRemove.forEach(function (_ref) {
            var dependsOnComponent = _ref.dependsOnComponent;
            var componentDependency = _ref.componentDependency;

            _this.get('dependencies').removeObject(dependsOnComponent);
            promises.push(componentDependency.destroyRecord());
          });

          newDependsOnComponents.forEach(function (newDependsOnComponent) {
            var dependsOnId = newDependsOnComponent.get('id');

            if (!currentComponentIdsToDependOn.includes(dependsOnId)) {
              var dependency = _this.store.createRecord('component-dependency', {
                component: _this,
                dependsOnComponent: newDependsOnComponent
              });

              _this.get('dependencies').pushObject(newDependsOnComponent);
              promises.push(dependency.save());
            }
          });

          return _ember['default'].RSVP.all(promises);
        }).then(function () {
          return _this.save();
        }).then(function () {
          return resolve(_this);
        })['catch'](reject);
      });
    }
  });

  exports['default'] = Component;
});