define("diesel/components/sso-login-form/component", ["exports", "ember", "diesel/config/environment", "diesel/utils/location"], function (exports, _ember, _dieselConfigEnvironment, _dieselUtilsLocation) {
  exports["default"] = _ember["default"].Component.extend({
    // Required parameters
    formTitle: null,
    actionTitle: null,
    spinnerTitle: null,

    // Optional parameters
    calloutComponent: null,

    // Internal state
    isLoggingIn: false,

    login: function login(organization) {
      _ember["default"].debug("\n      sso-login\n      organization=" + organization + "\n    ");

      this.set("isLoggingIn", true);

      var dashBase = !/^https?:\/\//i.test(_dieselConfigEnvironment["default"].dashboardBaseUri) ? "https://" + _dieselConfigEnvironment["default"].dashboardBaseUri : _dieselConfigEnvironment["default"].dashboardBaseUri;
      var failure_page = encodeURIComponent(dashBase + "/sso/failure");
      _dieselUtilsLocation["default"].replace(_dieselConfigEnvironment["default"].authBaseUri + "/organizations/" + organization + "/saml/login?redirect_uri=" + failure_page);
    },
    actions: {
      login: function login() {
        this.login.apply(this, arguments);
      }
    }
  });
});