define('diesel/app/vhosts/edit/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    ipFilteringValid: null,
    containerPortValid: null,

    formValid: _ember['default'].computed.and('ipFilteringValid', 'containerPortValid'),
    formInvalid: _ember['default'].computed.not('formValid'),

    formDisabled: _ember['default'].computed.or('formInvalid', 'model.isSaving')
  });
});