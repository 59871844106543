define('diesel/models/external-payment-source', ['exports', 'ember-data', 'diesel/models/base'], function (exports, _emberData, _dieselModelsBase) {
  exports['default'] = _dieselModelsBase['default'].extend({
    billingDetail: _emberData['default'].belongsTo('billing-detail', { async: true }),

    description: _emberData['default'].attr('string'),

    active: true,

    typeCanDeactivate: false
  });
});