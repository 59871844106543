define('diesel/organization/admin/contact-settings/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.modelFor('organization');
    },

    setupController: function setupController(controller, model) {
      controller.set('model', model.get('organization'));
    },

    resetController: function resetController(controller) {
      controller.set('error', null);
    },

    actions: {
      save: function save(model) {
        var _this = this;

        this.controller.set('isSaving', true);

        model.save().then(function () {
          var message = 'Contact settings saved';
          _ember['default'].get(_this, 'flashMessages').success(message);
          _this.controller.set('isSaving', false);
        })['catch'](function (e) {
          var message = e.message || 'There was an error updating contact settings';
          _ember['default'].get(_this, 'flashMessages').danger(message);
        });
      }
    }
  });
});