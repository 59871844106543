define('diesel/app/index/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var app = this.modelFor('app');
      return app.get('services');
    },

    afterModel: function afterModel(services) {
      this.transitionTo(services.get('length') > 0 ? 'app.services' : 'app.get-started');
    }
  });
});