define('diesel/gridiron/policy-editor/json/controller', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Controller.extend({
    encodedData: computed('model.document', function () {
      var json = encodeURIComponent(this.get('model.editor').serialize());
      return 'data:text/plain;charset=utf-8,' + json;
    }),

    json: computed('model.document', function () {
      return this.get('model.editor').prettyString;
    }),

    actions: {
      replaceDocument: function replaceDocument(event) {
        this.get('model.editor').replace(JSON.parse(event.target.value));
      }
    }
  });
});