define('diesel/utils/fetch-all-pages', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = fetchAllPages;
  var _Ember$RSVP = _ember['default'].RSVP;
  var all = _Ember$RSVP.all;
  var resolve = _Ember$RSVP.resolve;

  function buildQuery(page, parent) {
    var query = { page: page };

    if (parent) {
      parent = parent.content ? parent.content : parent; // in case parent is a promise proxy
      query[parent.constructor.modelName] = parent;
    }

    return query;
  }

  function shouldResetRelationshipsOnParent(parent, relationship) {
    return parent && parent._internalModel && parent._internalModel._relationships && parent._internalModel._relationships.get(relationship);
  }

  function fetchAllPages(store, parent, modelName) {
    return resolve(store.query(modelName, buildQuery(1, parent))).then(function (items) {
      var resolvedItems = items.toArray();
      var promises = [];
      var metadata = items.get('meta') || {};
      var currentPage = metadata.current_page;
      var totalPages = Math.ceil(metadata.total_count / metadata.per_page);

      if (!currentPage || !totalPages) {
        return items;
      }

      while (currentPage < totalPages) {
        var query = buildQuery(++currentPage, parent);

        promises.push(store.query(modelName, query).then(function (models) {
          resolvedItems = resolvedItems.concat(models.toArray());
        }));
      }

      return all(promises).then(function () {
        var key = modelName.pluralize();

        if (shouldResetRelationshipsOnParent(parent, key)) {
          // Push the results of the fetchAllPages into the parent's hasMany(modelName) collection
          // We are calling private Ember Data methods here to avoid an additional request imposed
          // by calling parent.get(modelName) directly
          var relationship = parent._internalModel._relationships.get(key);
          relationship.clear();
          relationship.addRecords(_ember['default'].A(resolvedItems).mapBy('_internalModel'));
        }
        return resolvedItems;
      });
    });
  }
});