define('diesel/organization/admin/billing/billing-address/route', ['exports', 'ember'], function (exports, _ember) {

  var BillingRoute = 'organization.admin.billing';

  var READABLE_FIELDS = {
    streetOne: 'Street address',
    city: 'City',
    state: 'State / province / district',
    post_code: 'Zip / postal code / postcode',
    country: 'Country'
  };

  var REQUIRED_INPUT_FIELDS = ['streetOne', 'city', 'state', 'postCode', 'country'];

  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var billingDetail = this.modelFor(BillingRoute);
      return billingDetail.get('organizationDetailsJson');
    },

    validate: function validate(billingDetail) {
      this.controller.set('errors', '');

      var _billingDetail$get = billingDetail.get('organizationDetailsJson');

      var billingAddress = _billingDetail$get.billingAddress;
      var country = billingAddress.country;
      var postCode = billingAddress.postCode;
      var state = billingAddress.state;

      var errors = _ember['default'].A();

      REQUIRED_INPUT_FIELDS.forEach(function (field) {
        if (_ember['default'].isBlank(billingAddress[field])) {
          errors.push(READABLE_FIELDS[field] + ' is required');
        }
      });

      // Check for valid ZIP in US only
      if (country === 'US' && postCode && !/^[0-9]{5}(?:-[0-9]{4})?$/.test(postCode)) {
        errors.push('Invalid Zip Code');
      }

      if (['US', 'CA'].includes(country) && state === 'NA') {
        errors.push(READABLE_FIELDS.state + ' is required');
      }

      if (errors.length > 0) {
        this.controller.set('errors', 'Error: ' + errors.join(', '));
      }
      return;
    },

    setupController: function setupController(controller, model) {
      var _model$billingAddress = model.billingAddress;
      var billingAddress = _model$billingAddress === undefined ? {} : _model$billingAddress;

      this.controller.set('billingAddress', billingAddress);
    },

    actions: {
      submit: function submit(billingAddress) {
        var _this = this;

        this.controller.set('isSaving', true);
        var billingDetail = this.modelFor(BillingRoute);
        billingDetail.set('organizationDetailsJson.billingAddress', billingAddress);
        this.validate(billingDetail);
        var errors = this.controller.get('errors');

        if (errors.length !== 0) {
          this.controller.set('isSaving', false);
          _ember['default'].get(this, 'flashMessages').danger(errors);
          return;
        }

        billingDetail.save().then(function () {
          var message = 'Billing Address updated';
          _ember['default'].get(_this, 'flashMessages').success(message);
        })['catch'](function (e) {
          _this.controller.set('isSaving', false);
          _ember['default'].get(_this, 'flashMessages').danger(e.message);
          return e;
        });
      }
    }
  });
});