define('diesel/components/with-active-class/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: ['isActive:active'],

    isActive: _ember['default'].computed('route', 'applicationController.currentPath', function () {
      var currentPath = this.applicationController.get('currentPath');
      var route = this.get('route');
      return currentPath.indexOf(route) > -1;
    })
  });
});