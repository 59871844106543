define('diesel/reports/audit-log-report/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var organizationProfile = this.get('complianceStatus.organizationProfile');
      var reportHref = organizationProfile.get('links.audit_log_report');
      var accessToken = this.get("session.token.accessToken");

      if (!reportHref) {
        this.transitionTo('gridiron-admin');
      }

      return _ember['default'].RSVP.hash({
        reportCSVHref: reportHref + '.csv',
        reportData: _ember['default'].$.ajax(reportHref, { headers: { Authorization: 'Bearer ' + accessToken } })
      });
    },

    setupController: function setupController(controller, model) {
      var assets = model.reportData.report.logging_destinations.map(function (asset) {
        asset.components = asset.components.join(', ');
        return asset;
      });

      controller.setProperties({ assets: assets, reportCSVHref: model.reportCSVHref });
    },

    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);

      this.render('sidebars/engine-sidebar', {
        into: 'reports.audit-log-report',
        outlet: 'sidebar',
        controller: this.controllerFor('gridiron-organization')
      });
    }
  });
});