define("diesel/adapters/otp-reset-challenge", ["exports", "diesel/adapters/auth", "ember"], function (exports, _dieselAdaptersAuth, _ember) {
  exports["default"] = _dieselAdaptersAuth["default"].extend({
    buildURL: function buildURL() {
      var url = [],
          host = _ember["default"].get(this, 'host'),
          prefix = this.urlPrefix();

      if (prefix) {
        url.push(prefix);
      }

      url.push('otp/resets/new');

      url = url.join('/');
      if (!host && url) {
        url = '/' + url;
      }

      return url;
    }
  });
});