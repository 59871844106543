define('diesel/transforms/humps', ['exports', 'ember-data', 'humps'], function (exports, _emberData, _humps) {
  exports['default'] = _emberData['default'].Transform.extend({
    deserialize: function deserialize(serialized) {
      if (!serialized) {
        return serialized;
      }

      return _humps['default'].camelizeKeys(serialized);
    },

    serialize: function serialize(deserialized) {
      if (!deserialized) {
        return deserialized;
      }

      return _humps['default'].decamelizeKeys(deserialized);
    }
  });
});