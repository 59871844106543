define('diesel/app/activity/route', ['exports', 'ember', 'diesel/mixins/routes/multi-resource-operation-list'], function (exports, _ember, _dieselMixinsRoutesMultiResourceOperationList) {
  exports['default'] = _ember['default'].Route.extend(_dieselMixinsRoutesMultiResourceOperationList['default'], {
    getResources: function getResources() {
      var app = this.modelFor('app');
      var resources = [app];
      return app.get('services').then(function (services) {
        services.forEach(function (service) {
          return resources.push(service);
        });
        return resources;
      });
    },

    titleToken: function titleToken() {
      return this.modelFor('app').get('handle') + ' Activity';
    }
  });
});