define('diesel/components/predisposing-condition-list/component', ['exports', 'ember', 'diesel/utils/filter-risk-components', 'diesel/mixins/components/column-config'], function (exports, _ember, _dieselUtilsFilterRiskComponents, _dieselMixinsComponentsColumnConfig) {
  var COLUMN_PRESETS = {
    'title': 'Predisposing Condition',
    'description': 'Description',
    'pervasiveness': 'Pervasiveness'
  };

  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsColumnConfig['default'], {
    filters: { search: '', sort: 'pervasiveness', pervasiveness: null },
    tagName: 'table',
    classNames: ['base-table'],

    columns: ['title', 'description', 'pervasiveness'],
    columnPresets: COLUMN_PRESETS,

    filteredPredisposingConditions: _ember['default'].computed('filters.pervasiveness', 'filters.sort', 'filters.search', 'predisposingConditions.[]', function () {
      var predisposingConditions = this.get('predisposingConditions');
      var filters = this.get('filters');
      filters.relevant = true;
      return (0, _dieselUtilsFilterRiskComponents['default'])(predisposingConditions, filters);
    })
  });
});