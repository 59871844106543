define('diesel/components/security-assessment-status-security-control-category/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    securityControlCategory: null,
    tagName: 'tr',
    classNames: ['security-assessment-status-row', 'security-assessment-status-row__security-control-category'],
    securityControls: _ember['default'].computed('securityControlCategory', 'riskProfile.securityControls.[]', function () {
      var id = this.get('securityControlCategory.id');
      return this.get('riskProfile.securityControls').filter(function (sc) {
        return sc.get('category.id') === id;
      });
    }),
    relevantSecurityControls: _ember['default'].computed.filterBy('securityControls', 'relevant'),
    unconfiguredSecurityControls: _ember['default'].computed.filterBy('securityControls', 'status', 'unconfigured'),
    isVisible: _ember['default'].computed.gt('securityControls.length', 0)
  });
});