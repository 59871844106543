define('diesel/mixins/models/document-build', ['exports', 'ember', 'diesel/config/environment', 'ember-data'], function (exports, _ember, _dieselConfigEnvironment, _emberData) {
  var DOCUMENT_BUILD_STATUSES = {
    BUILDING: 'building',
    COMPLETE: 'built'
  };

  exports.DOCUMENT_BUILD_STATUSES = DOCUMENT_BUILD_STATUSES;
  var DOCUMENT_BUILD_TIMEOUT = _dieselConfigEnvironment['default'].timeouts.documentBuild;

  var DOCUMENT_BUILDING_STATUS = 'building';
  exports.DOCUMENT_BUILDING_STATUS = DOCUMENT_BUILDING_STATUS;
  exports['default'] = _ember['default'].Mixin.create({
    riskProfiles: _emberData['default'].hasMany('risk-profile', { async: true }),
    currentRiskProfile: _emberData['default'].belongsTo('risk-profile', { async: true }),
    documentBuildStatus: _emberData['default'].attr('string'),
    isBuildingDocuments: _ember['default'].computed.equal('documentBuildStatus', DOCUMENT_BUILD_STATUSES.BUILDING),

    observeDocumentBuilds: function observeDocumentBuilds() {
      if (this.get('hasCompletedSetup')) {
        this.set('documentBuildStatus', DOCUMENT_BUILD_STATUSES.BUILDING);
        this._recursiveReload();
      }
    },

    _shouldReload: function _shouldReload() {
      var _getProperties = this.getProperties('isDeleted', 'isDestroying', 'isDestroyed');

      var isDeleted = _getProperties.isDeleted;
      var isDestroying = _getProperties.isDestroying;
      var isDestroyed = _getProperties.isDestroyed;

      var isBuildingDocuments = this.get('data.documentBuildStatus') === DOCUMENT_BUILD_STATUSES.BUILDING;
      var hasCompletedSetup = this.get('data.hasCompletedSetup');
      var currentState = this.get('currentState.stateName');
      var inLoadedState = currentState !== 'root.empty' && currentState !== 'root.deleted.uncommitted';

      return inLoadedState && hasCompletedSetup && isBuildingDocuments && !isDeleted && !isDestroyed && !isDestroying;
    },

    _recursiveReload: function _recursiveReload() {
      var _this = this;

      if (this._isReloading) {
        // Prevent creating multiple reloads
        return;
      }

      this._isReloading = true;
      this.reload().then(function () {
        if (_this._shouldReload()) {
          _ember['default'].run.later(_this, '_recursiveReload', DOCUMENT_BUILD_TIMEOUT);
        } else {
          _this.set('documentBuildStatus', DOCUMENT_BUILD_STATUSES.COMPLETE);
          _this.reloadDocuments();
        }
      })['finally'](function () {
        _this._isReloading = false;
      });
    },
    reloadDocuments: function reloadDocuments() {
      var _this2 = this;

      // This method is called after an attestation is created so that dependent
      // draft documents are reloaded.  This is ugly.  There is no way to
      // guarantee that Ember Data will load a new `belongsTo` without
      // explicitly unloading the record and forcibly setting a new one back on
      // the belongsTo attr.  This is fixed in newer versions of ED
      var RELOAD_DOCUMENTS = {
        draftPolicyManual: 'policy-manual',
        currentPolicyManual: 'policy-manual',
        currentRiskAssessment: 'risk-assessment'
      };

      var promises = Object.keys(RELOAD_DOCUMENTS).map(function (documentName) {
        return new _ember['default'].RSVP.Promise(function (resolve) {
          _this2.get(documentName).then(function (complianceDocument) {
            if (complianceDocument) {
              var type = RELOAD_DOCUMENTS[documentName];
              var recordId = complianceDocument.get('id');

              if (type === 'policy-manual') {
                complianceDocument.unloadRecord();
              }

              _this2.get('store').find(type, recordId).then(function (newDpm) {
                _this2.set(documentName, newDpm);

                // If the new document has a PDF status, start reloading
                if (newDpm.get('pdfIsGenerating')) {
                  newDpm.observePDFBuilds();
                }

                resolve();
              });
            }
            resolve();
          });
        });
      });

      return _ember['default'].RSVP.all(promises);
    }
  });
});