define('diesel/components/aptible-status/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'li',
    classNameBindings: ['statusIndicator', ':aptible-status'],

    aptibleStatus: _ember['default'].inject.service(),

    statusIndicator: _ember['default'].computed.reads('aptibleStatus.status.indicator'),

    showComponent: _ember['default'].computed('statusIndicator', function () {
      return ({
        none: false,
        minor: true,
        major: true,
        critical: true
      })[this.get('statusIndicator')];
    }),

    linkClass: _ember['default'].computed('statusIndicator', function () {
      return 'btn ' + this.get('statusIndicator');
    })
  });
});