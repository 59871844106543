define('diesel/components/invoice-line-items/component', ['exports', 'ember', 'goldenboy-renderer'], function (exports, _ember, _goldenboyRenderer) {
  exports['default'] = _ember['default'].Component.extend({
    sortBy: ['quantity:desc'],

    lineItems: null,
    goldenboyLib: null,

    groups: _ember['default'].computed('lineItems.[]', function () {
      var lineItems = this.get('lineItems');
      return (0, _goldenboyRenderer.groupLineItems)(lineItems);
    }),

    sortedGroups: _ember['default'].computed.sort('groups', 'sortBy'),

    filteredSortedGroups: _ember['default'].computed('sortedGroups.[]', function () {
      var goldenboyLib = this.get('goldenboyLib');

      return this.get('sortedGroups').filter(function (g) {
        return goldenboyLib.showUnit(_ember['default'].get(g, 'unit'));
      });
    })
  });
});