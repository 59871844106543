define("diesel/settings/cli-sso-token/index/route", ["exports", "ember", "diesel/config/environment", "diesel/utils/location"], function (exports, _ember, _dieselConfigEnvironment, _dieselUtilsLocation) {
  exports["default"] = _ember["default"].Route.extend({
    authorization: _ember["default"].inject.service(),

    model: function model() {
      return this.get('authorization.currentOrganization');
    },

    actions: {
      submit: function submit(org, duration) {
        var dashBase = !/^https?:\/\//i.test(_dieselConfigEnvironment["default"].dashboardBaseUri) ? "https://" + _dieselConfigEnvironment["default"].dashboardBaseUri : _dieselConfigEnvironment["default"].dashboardBaseUri;
        var failure_page = encodeURIComponent(dashBase + "/sso/failure");
        _dieselUtilsLocation["default"].replace(_dieselConfigEnvironment["default"].authBaseUri + "/organizations/" + org.id + "/saml/login?landing_page=clitoken&duration=" + duration + "&redirect_uri=" + failure_page);
      }
    }
  });
});