define('diesel/components/modal-create-system/component', ['exports', 'ember', 'diesel/mixins/components/asset-edit', 'diesel/mixins/components/modal-base'], function (exports, _ember, _dieselMixinsComponentsAssetEdit, _dieselMixinsComponentsModalBase) {

  var ERROR_MESSAGE = 'There was an error saving the system';
  var NAME_REQUIRED_ERROR_MESSAGE = 'System name required';
  var MODAL_DESCRIPTION = '';

  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsAssetEdit['default'], _dieselMixinsComponentsModalBase['default'], {
    createSystemModal: null, // set by route to open this modal
    asset: _ember['default'].computed.alias('system'),

    description: MODAL_DESCRIPTION,
    errors: null,
    // Returns [{value, label}]

    // To ensure that when the modal is hidden (and createSystemModal is set to null),
    // we don't cause an error by passing `null` to `ultimate-select` in the template,
    // both users and roles are computed to have an empty array as a default value:
    users: _ember['default'].computed('createSystemModal.users', function () {
      return this.get('createSystemModal.users') || [];
    }),
    roles: _ember['default'].computed('createSystemModal.roles', function () {
      return this.get('createSystemModal.roles') || [];
    }),
    organizationProfile: _ember['default'].computed.reads('createSystemModal.organizationProfile'),

    modalTitle: _ember['default'].computed('system.name', function () {
      return 'System Settings: ' + this.get('system.name');
    }),

    init: function init() {
      this._super.apply(this, arguments);

      var system = this.get('createSystemModal.system');
      this.set('system', system);

      // operate on a copy of the items to avoid mutating the items property on
      // system in case the user cancels and we need to rollback
      this.set('items', system.get('items').slice());
    },

    actions: {
      clearMessages: function clearMessages() {
        this.set('errors', null);
      },

      addItem: function addItem() {
        var item = this.get('newItem');
        this.get('items').pushObject(item);
        this.set('newItem', '');
      },

      updateItem: function updateItem(index, item) {
        this.get('items').replace(index, 1, item);
      },

      removeItem: function removeItem(index) {
        this.get('items').removeAt(index);
      },

      setRequiredForIncidentResponse: function setRequiredForIncidentResponse(val) {
        var system = this.get('system');
        val = val === 'true' ? true : false;
        system.set('requiredForIncidentResponse', val);
      },

      saveSystem: function saveSystem() {
        var _this = this;

        var _getProperties = this.getProperties('system', 'items');

        var system = _getProperties.system;
        var items = _getProperties.items;

        if (_ember['default'].isEmpty(system.get('name'))) {
          this.set('errors', NAME_REQUIRED_ERROR_MESSAGE);
          this.scrollToTop();
          return;
        }

        if (!system.get('hasOwner')) {
          this.set('errors', 'Asset owner is required.');
          this.scrollToTop();
          return;
        }

        system.set('items', items);

        this._previousTab = this.get('currentTab');
        this.set('isSaving', true);
        this.set('currentTab', 'saving');

        return system.save().then(function () {
          return _this.get('organizationProfile.vendors').reload();
        }).then(function () {
          _this.set('currentTab', 'basic');
          _this.sendAction('onSystemUpdate', system);
        })['catch'](function (e) {
          _this.set('errors', e.message || ERROR_MESSAGE);
          _this.set('currentTab', _this._previousTab);
        })['finally'](function () {
          _this.set('isSaving', false);
        });
      },

      removeSystem: function removeSystem() {
        var _this2 = this;

        var system = this.get('system');
        var handle = system.get('name');

        var _get = this.get('createSystemModal');

        var organizationProfile = _get.organizationProfile;

        var message = 'Are you sure you want to remove ' + handle + ' from Gridiron?';

        if (!window.confirm(message)) {
          return;
        }

        return system.destroyRecord().then(function () {
          organizationProfile.observeDocumentBuilds();
          _this2.sendAction('onSystemRemove', handle);
          _this2.sendAction('dismissModal');
        });
      },

      beforeDismiss: function beforeDismiss() {
        this.get('system').rollbackAttributes();
        this.set('errors', null);
      }
    }
  });
});