define('diesel/stack/managed-hids/route', ['exports', 'ember', 'diesel/utils/belongs-to-record-pool-wrapper'], function (exports, _ember, _dieselUtilsBelongsToRecordPoolWrapper) {
  var INTRUSION_DETECTION_REPORT_BATCH_SIZE = 20;

  exports.INTRUSION_DETECTION_REPORT_BATCH_SIZE = INTRUSION_DETECTION_REPORT_BATCH_SIZE;
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params) {
      var stack = this.modelFor('stack');
      var poolWrapper = new _dieselUtilsBelongsToRecordPoolWrapper['default']([stack], 'intrusion-detection-report');

      return poolWrapper.fetch(params.intrusionDetectionReportCount).then(function (fetchResponse) {
        return {
          stack: stack,
          poolWrapper: poolWrapper,
          intrusionDetectionReports: fetchResponse.records,
          lastFetch: fetchResponse
        };
      });
    },

    actions: {
      loadMore: function loadMore() {
        var _this = this;

        this.controller.get("model.poolWrapper").fetch(INTRUSION_DETECTION_REPORT_BATCH_SIZE).then(function (fetchResponse) {
          _this.controller.set('model.lastFetch', fetchResponse);

          var intrusionDetectionReports = _this.controller.get('model.intrusionDetectionReports');
          fetchResponse.records.forEach(function (o) {
            return intrusionDetectionReports.pushObject(o);
          });

          _this.controller.set('intrusionDetectionReportCount', intrusionDetectionReports.get("length"));
        })['catch'](function (e) {
          _ember['default'].get(_this, 'flashMessages').danger('An error occured fetching activity reports');
          throw e;
        });
      }
    }
  });
});