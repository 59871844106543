define('diesel/settings/logout/route', ['exports', 'ember', 'diesel/utils/location'], function (exports, _ember, _dieselUtilsLocation) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return _ember['default'].Object.create({
        inProgress: false
      });
    },

    setupController: function setupController(controller, model) {
      controller.set("model", model);
    },

    actions: {
      doLogout: function doLogout() {
        var _this = this;

        this.currentModel.set("inProgress", true);

        var logoutPromise = this.session.close('application', {
          token: this.get('session.token')
        })['catch'](function (e) {
          // e will be a jqXHR error object (not a DS.AdapterError)
          // because the application torii adapter makes a jQuery ajax call
          // to close the session
          if (e.responseJSON && e.responseJSON.error === 'expired_token') {
            // If the user's token has expired, then we don't care to log them
            // out "further".
            return;
          }
          throw e;
        });

        return logoutPromise.then(function () {
          return _this.get('analytics').logout();
        }).then(function () {
          return _dieselUtilsLocation['default'].replaceAndWait("/");
        })['catch'](function (e) {
          var message = e.responseJSON && e.responseJSON.message || e.message || 'An unexpected error occurred.';
          _ember['default'].get(_this, 'flashMessages').danger(message);
        })['finally'](function () {
          _this.currentModel.set("inProgress", false);
        });
      }
    }
  });
});