define('diesel/components/operable-action/component', ['exports', 'ember', 'diesel/mixins/components/operable'], function (exports, _ember, _dieselMixinsComponentsOperable) {
  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsOperable['default'], {
    tagName: '',

    actions: {
      onButton: function onButton(operable) {
        this.sendAction('action', operable);
      }
    }
  });
});