define('diesel/components/modal-customer/component', ['exports', 'ember', 'diesel/mixins/components/modal-base'], function (exports, _ember, _dieselMixinsComponentsModalBase) {

  var ERROR_MESSAGE = 'There was an error saving the customer';

  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsModalBase['default'], {
    modalDetails: null, // passed in when modal is shown

    init: function init() {
      this._super.apply(this, arguments);
      this.set('customer', this.get('modalDetails.model'));

      if (this.get('customer.isNew')) {
        this.set('title', 'Add Customer');
      } else {
        this.set('title', 'Edit Customer');
      }
    },

    disableSave: _ember['default'].computed.or('hasError', 'isPending'),
    isPending: _ember['default'].computed.alias('customer.isSaving'),
    hasError: _ember['default'].computed.gt('errors.customer.name.length', 0),

    buttonLabel: _ember['default'].computed('customer.isNew', function () {
      if (this.get('customer.isNew') === false) {
        return 'Save Changes';
      }
      return 'Create Customer';
    }),

    actions: {
      saveCustomer: function saveCustomer() {
        var _this = this;

        var customer = this.get('customer');

        this.set('isSaving', true);

        return customer.save().then(function () {
          _this.sendAction('onUpdate', customer);
        })['catch'](function (e) {
          _this.set('errors', e.errors.message || ERROR_MESSAGE);
        })['finally'](function () {
          _this.set('isSaving', false);
        });
      },

      beforeDismiss: function beforeDismiss() {
        this.get('customer').rollbackAttributes();

        // Fixes issue where tearing down the template would update
        // properties bound to the model's attrs and would cause Ember Data to
        // complain about modifying a deleted record
        this.set('customer', null);
        this.set('errors', null);
      }
    }
  });
});