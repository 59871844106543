define('diesel/components/agreement-owner-agreement-item/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['agreement-owner-agreement-item', 'panel', 'component-profile', 'component-profile--agreement-owner-agreement'],
    isVisible: _ember['default'].computed.not('agreement.isNew'),
    openAttachmentModal: 'openAttachmentModal',

    actions: {
      openAttachmentModal: function openAttachmentModal(agreement) {
        this.sendAction('openAttachmentModal', agreement);
      },
      openAgreementModal: function openAgreementModal(agreement) {
        this.sendAction('openAgreementModal', agreement);
      },
      removeAgreement: function removeAgreement(agreement) {
        this.sendAction('removeAgreement', agreement);
      }
    }
  });
});