define('diesel/models/certification', ['exports', 'ember', 'ember-data', 'diesel/models/base', 'diesel/mixins/models/attachable'], function (exports, _ember, _emberData, _dieselModelsBase, _dieselMixinsModelsAttachable) {
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var hasMany = _emberData['default'].hasMany;

  var TYPES = [{ key: 'iso_27001', value: 'ISO 27001' }, { key: 'soc_2', value: 'SOC2' }, { key: 'hitrust', value: 'HITRUST' }, { key: 'hipaa', value: 'HIPAA Audit' }, { key: 'pci_dss_level_1', value: 'PCI-DSS L1' }];

  var INTERVALS = [{ key: 'monthly', value: 'Monthly' }, { key: 'semiannually', value: 'Semi-annually' }, { key: 'annually', value: 'Annually' }];

  exports.TYPES = TYPES;
  exports.INTERVALS = INTERVALS;
  exports['default'] = _dieselModelsBase['default'].extend(_dieselMixinsModelsAttachable['default'], {
    createdAt: attr('iso-8601-timestamp'),
    expiresAt: attr('iso-8601-timestamp'),
    reviewInterval: attr('string', { defaultValue: INTERVALS[0].key }),
    type: attr('string', { defaultValue: TYPES[0].key }),

    organizationProfile: belongsTo(),
    vendor: belongsTo('vendor', { async: true }),

    displayType: _ember['default'].computed('type', function () {
      var type = this.get('type');
      if (!type) {
        return null;
      }

      return TYPES.findBy('key', type).value;
    }),

    displayReviewInterval: _ember['default'].computed('reviewInterval', function () {
      return INTERVALS.findBy('key', this.get('reviewInterval')).value;
    }),

    systemCertifications: hasMany('system-certification', { async: false }),
    systems: _ember['default'].computed('systemCertifications.[]', function () {
      return this.get('systemCertifications').map(function (sc) {
        return sc.get('system');
      });
    }),

    updateSystemsAndSave: function updateSystemsAndSave(newSystems) {
      var _this = this;

      var newSystemHrefs = newSystems ? newSystems.map(function (sys) {
        return sys.get('links.self');
      }) : [];
      var vendor = undefined;
      var vendorSystems = undefined;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _this.save().then(function () {
          return _this.get('vendor');
        }).then(function (v) {
          vendor = v;
          return vendor.get('systems');
        }).then(function (vSystems) {
          vendorSystems = vSystems;
          return _this.get('systems');
        }).then(function (currentSystems) {
          var promises = [];
          var currentSystemHrefs = currentSystems.map(function (sys) {
            return sys.get('links.self');
          });

          // Look at all currrent, if any are not in new, queue for deletion
          currentSystems.forEach(function (currentSystem) {
            var currentSystemHref = currentSystem.get('links.self');
            if (!newSystemHrefs.includes(currentSystemHref)) {
              // delete current system reference
              var systemCertification = _this.get('systemCertifications').findBy('system.links.self', currentSystemHref);
              promises.push(systemCertification.destroyRecord());
            }
          });

          // Look at all new, if any in new are not in current, queue for creation
          newSystemHrefs.forEach(function (newSystemHref) {
            if (!currentSystemHrefs.includes(newSystemHref)) {
              var newSystem = vendorSystems.findBy('links.self', newSystemHref);
              var systemCertification = _this.store.createRecord('system-certification', {
                certification: _this, system: newSystem
              });
              promises.push(systemCertification.save());
            }
          });

          return _ember['default'].RSVP.all(promises);
        }).then(function () {
          return resolve(_this);
        })['catch'](reject);
      });
    }
  });
});