define('diesel/components/c-radial-progress/component', ['exports', 'ember'], function (exports, _ember) {

  var VARIANTS = {
    success: { strokeColor: '#22C55E' },
    danger: { strokeColor: '#EF4444' },
    warn: { strokeColor: '#F97316' }
  };

  exports['default'] = _ember['default'].Component.extend({
    classNames: 'c-radial-progress',
    stroke: 3,
    radius: 17,
    fill: 'transparent',
    variant: 'warn',
    strokeColor: _ember['default'].computed('variant', function () {
      return VARIANTS[this.get('variant') || 'warn'].strokeColor;
    }),

    onProgressChange: _ember['default'].observer('progress', function () {
      this.setProgress();
    }),

    setProgress: function setProgress() {
      var _getProperties = this.getProperties('progress', 'circumference');

      var progress = _getProperties.progress;
      var circumference = _getProperties.circumference;

      var offset = circumference - progress / 100 * circumference;
      var circle = this.$("circle")[0];
      circle.style.strokeDashoffset = offset;
    },

    setup: _ember['default'].on('didInsertElement', function () {
      this.setProgress();
    }),

    setInitialStyles: _ember['default'].on('init', function () {
      var _getProperties2 = this.getProperties('radius', 'stroke');

      var radius = _getProperties2.radius;
      var stroke = _getProperties2.stroke;

      var normalizedRadius = radius - stroke * 2;
      var boxSize = radius * 2;
      var circumference = normalizedRadius * 2 * Math.PI;
      var dashArray = circumference + ' ' + circumference;
      var style = 'stroke-dashoffset:' + circumference;

      this.setProperties({
        boxSize: boxSize,
        normalizedRadius: normalizedRadius,
        dashArray: dashArray,
        style: style,
        circumference: circumference
      });
    })
  });
});