define('diesel/components/threat-sources-filters/component', ['exports', 'ember'], function (exports, _ember) {

  var SORT = [{ title: 'Highest Threat Vector', value: 'threatVector' }, { title: 'Highest Range of Effects', value: 'rangeOfEffects' }, { title: 'Title', value: 'title' }];

  exports['default'] = _ember['default'].Component.extend({
    filters: { search: '', sort: SORT[0], adversarial: 'any' },

    classNames: ['risk-assessment__filters form-inline'],
    sorts: SORT,

    showClear: _ember['default'].computed('filters.search', 'filters.adversarial', function () {
      var currentSearch = this.get('filters.search') || '';
      var adversarial = this.get('filters.adversarial');

      return _ember['default'].$.trim(currentSearch) !== '' || !_ember['default'].isEmpty(adversarial) && adversarial !== 'any';
    }),

    actions: {
      clear: function clear() {
        this.set('filters.sort', SORT[0].value);
        this.set('filters.adversarial', 'any');
        this.set('filters.search', '');
      },

      update: function update() {
        var sort = this.$('select.sort').val();
        var adversarial = this.$('select.adversarial').val();
        if (adversarial !== 'any') {
          adversarial = !!parseInt(adversarial, 10);
        }

        this.set('filters.sort', sort);
        this.set('filters.adversarial', adversarial);
      }
    }
  });
});