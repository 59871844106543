define('diesel/helpers/format-currency', ['exports', 'ember'], function (exports, _ember) {
  exports.formatCurrency = formatCurrency;

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  function formatCurrency(cents) {
    var prefix = cents < 0 ? '- ' : '';
    cents = cents < 0 ? -cents : cents;
    return prefix + '$' + (cents / 100).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
  }

  /**
   * Takes money values in cents and returns a value in dollars
   * 123456789 will return $1,234,567.89
   * 12345 will return $123.45
   * 1 will return $0.01
   */
  exports['default'] = _ember['default'].Helper.helper(function (params) {
    return formatCurrency.apply(undefined, _toConsumableArray(params));
  });
});