define('diesel/components/vhost-resource-usage/component', ['exports', 'ember'], function (exports, _ember) {
  var HOURS_PER_MONTH = 730;

  exports.HOURS_PER_MONTH = HOURS_PER_MONTH;
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'tr',
    isProvisioned: _ember['default'].computed.equal('status', 'provisioned'),

    total: _ember['default'].computed('hourlyRate', function () {
      return this.get('hourlyRate') * HOURS_PER_MONTH;
    })
  });
});