define('diesel/components/user-course-item-row/component', ['exports', 'ember', 'diesel/models/assignment-state'], function (exports, _ember, _dieselModelsAssignmentState) {

  var ASSIGNABLE = [_dieselModelsAssignmentState.ASSIGNMENT_STATES.NEVER_ASSIGNED, _dieselModelsAssignmentState.ASSIGNMENT_STATES.UNASSIGNED];
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    assignable: _ember['default'].computed('courseAssignment.status', function () {
      return ASSIGNABLE.includes(this.get('courseAssignment.status'));
    }),

    actions: {
      assign: function assign(userTrainingModel, course) {
        var action = this.get('onAssign');
        action(userTrainingModel, course);
      },

      unassign: function unassign(userTrainingModel, assignment) {
        var action = this.get('onUnassign');
        action(userTrainingModel, assignment);
      }
    }
  });
});