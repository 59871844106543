define('diesel/components/threat-type-icon/component', ['exports', 'ember'], function (exports, _ember) {

  var adversarialTip = 'Adversarial threat sources are characterized by capability,\n                      intent, and targeting. We express the interaction of an\n                      adversarial threat source\'s capability, intention, and\n                      targeting as a rounded average of those attributes (each\n                      expressed on a five-point scale, from very low to very\n                      high), referred to as a threat vector.';

  var nonAdversarialTip = 'Non-adversarial threat sources are characterized by\n                         their range of effects, expressed as a five point\n                         scale.';

  var CONFIG = {
    'adversarial': {
      title: 'Adversarial',
      tip: adversarialTip,
      icon: 'fa-user-secret'
    },

    'non-adversarial': {
      title: 'Non-adversarial',
      tip: nonAdversarialTip,
      icon: 'fa-flash'
    }
  };

  exports['default'] = _ember['default'].Component.extend({
    tagName: 'span',
    classNames: 'threat-type-icon',
    classNameBindings: ['type'],
    config: _ember['default'].computed('type', function () {
      return CONFIG[this.get('type')];
    })
  });
});