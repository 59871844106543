define('diesel/transforms/iso-8601-timestamp', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Transform.extend({
    deserialize: function deserialize(serialized) {
      if (serialized) {
        return new Date(serialized);
      }
    },

    serialize: function serialize(deserialized) {
      if (deserialized) {
        return deserialized.toISOString();
      }
    }
  });
});