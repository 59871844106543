define('diesel/utils/attestation-validation-error', ['exports', 'ember'], function (exports, _ember) {
  exports.humanize = humanize;
  var _Ember$String = _ember['default'].String;
  var decamelize = _Ember$String.decamelize;
  var capitalize = _Ember$String.capitalize;

  var VALIDATION_PROPERTY_MATCH = /The property '#\/([^']+)'.+/;
  var MISSING_REQUIRED_PROPERTY_TEST = /did not contain a required property/;
  var MISSING_ITEM_PROPERTY_TEST = /did not contain a minimum number of items/;
  var ENUM_MISMATCH_PROPERTY_TEST = /did not match one of the following values/;

  function humanize(property) {
    return capitalize(decamelize(property).replace(/_/ig, ' '));
  }

  function propertyNameFromPath(path) {
    return humanize(path.split('.').reverse()[0]);
  }

  exports['default'] = _ember['default'].Object.extend({
    init: function init() {
      this._super.apply(this, arguments);

      var message = this.get('message');

      if (message.match(MISSING_REQUIRED_PROPERTY_TEST)) {
        this.initAsMissingRequiredPropertyError();
      } else if (message.match(MISSING_ITEM_PROPERTY_TEST)) {
        this.initAsMissingItemError();
      } else if (message.match(ENUM_MISMATCH_PROPERTY_TEST)) {
        this.initAsMissingEnumError();
      } else {
        this.initAsGenericPropertyError();
      }
    },

    propertyPath: _ember['default'].computed('message', function () {
      return this.get('message').replace(VALIDATION_PROPERTY_MATCH, '$1').replace(/\//ig, '.');
    }),

    initAsMissingRequiredPropertyError: function initAsMissingRequiredPropertyError() {
      // Example Error:
      // "The property '#/separateOrganizationalUnits' did not contain a required
      // property of 'implemented'"
      var PATH_SUFFIX_MATCH = /.*did not contain a required property of '([^']+)'$/;
      var message = this.get('message');
      var initialPath = this.get('propertyPath');
      var pathSuffix = message.replace(PATH_SUFFIX_MATCH, '$1');
      var path = initialPath + '.' + pathSuffix;
      var error = 'is required';

      var propertyName = humanize(pathSuffix) + ' ' + humanize(initialPath);

      this.setProperties({ path: path, error: error, propertyName: propertyName });
    },

    initAsMissingItemError: function initAsMissingItemError() {
      // Example Error:
      // "The property '#/alertNotifications/enabledNotifications' did not contain
      // a minimum number of items 1"
      var VALIDATION_ERROR_MATCH = /The property '#\/.+' (.+)/;
      var message = this.get('message');
      var path = this.get('propertyPath');
      var error = message.replace(VALIDATION_ERROR_MATCH, '$1').replace('did', 'does');
      var propertyName = propertyNameFromPath(path);

      this.setProperties({ path: path, error: error, propertyName: propertyName });
    },

    initAsMissingEnumError: function initAsMissingEnumError() {
      // Example Error:
      // The property '#/security_controls/application_unified_logging/technologies'
      // value \"\" did not match one of the following values: ElasticSearch,
      // Papertrail, Splunk, SumoLogic, Other
      var message = this.get('message');
      var path = this.get('propertyPath');

      var REQUIRED_VALUES_MATCH = /^.+one of the following values: (.+)$/;
      var requiredValues = message.replace(REQUIRED_VALUES_MATCH, '$1');
      var error = 'does not match one of the following values: ' + requiredValues;
      var propertyName = propertyNameFromPath(path);

      this.setProperties({ path: path, error: error, propertyName: propertyName });
    },

    initAsGenericPropertyError: function initAsGenericPropertyError() {
      var path = null;
      var propertyName = null;
      var error = this.get('message');

      this.setProperties({ path: path, error: error, propertyName: propertyName });
    }
  });
});