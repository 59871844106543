define('diesel/components/vulnerability-package-list/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'span',
    vulnerability: null,

    body: _ember['default'].computed('vulnerability.packages.[]', function () {
      return this.get('vulnerability.packages').map(function (p) {
        return p.get('name');
      }).uniq().join(', ');
    })
  });
});