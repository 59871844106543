define('diesel/reports/asset-inventory-report/route', ['exports', 'ember', 'diesel/mixins/models/asset'], function (exports, _ember, _dieselMixinsModelsAsset) {

  function titleize(str) {
    return str.split('_').map(function (c) {
      return c.capitalize();
    }).join(' ');
  }

  function columnsFor(assetType) {
    var filteredColumns = COLUMNS_ON_ASSETS.filter(function (column) {
      return column.only.includes(assetType);
    });
    return filteredColumns.map(function (column) {
      return column.name;
    });
  }

  var NAME = 'Name';
  exports.NAME = NAME;
  var DATA_CLASSIFICATION = 'Data Classification';
  exports.DATA_CLASSIFICATION = DATA_CLASSIFICATION;
  var TYPE = 'Type';
  exports.TYPE = TYPE;
  var OWNER = 'Owner';
  exports.OWNER = OWNER;
  var NETWORK = 'Network';
  exports.NETWORK = NETWORK;
  var VENDOR = 'Vendor';
  exports.VENDOR = VENDOR;
  var BUSINESS_CRITICALITY = 'Business Criticality';
  exports.BUSINESS_CRITICALITY = BUSINESS_CRITICALITY;
  var FINANCIAL_VALUE = 'Financial Value';
  exports.FINANCIAL_VALUE = FINANCIAL_VALUE;
  var STATUS = 'Status';
  exports.STATUS = STATUS;
  var LEGAL_REQUIREMENTS = 'Legal Requirements';

  exports.LEGAL_REQUIREMENTS = LEGAL_REQUIREMENTS;
  var COLUMNS_ON_ASSETS = [{ name: NAME, only: _dieselMixinsModelsAsset.ASSET_TYPES }, { name: DATA_CLASSIFICATION, only: _dieselMixinsModelsAsset.ASSET_TYPES }, { name: TYPE, only: [_dieselMixinsModelsAsset.APPS, _dieselMixinsModelsAsset.LOCATIONS] }, { name: OWNER, only: _dieselMixinsModelsAsset.ASSET_TYPES }, { name: NETWORK, only: [_dieselMixinsModelsAsset.APPS, _dieselMixinsModelsAsset.DATABASES] }, { name: VENDOR, only: [_dieselMixinsModelsAsset.DATA_STORES, _dieselMixinsModelsAsset.COMPUTE_BACKENDS, _dieselMixinsModelsAsset.LOGGING_DESTINATIONS, _dieselMixinsModelsAsset.SAAS_SERVICES] }, { name: BUSINESS_CRITICALITY, only: _dieselMixinsModelsAsset.ASSET_TYPES }, { name: FINANCIAL_VALUE, only: _dieselMixinsModelsAsset.ASSET_TYPES }, { name: STATUS, only: _dieselMixinsModelsAsset.ASSET_TYPES }, { name: LEGAL_REQUIREMENTS, only: _dieselMixinsModelsAsset.ASSET_TYPES }];

  exports.COLUMNS_ON_ASSETS = COLUMNS_ON_ASSETS;
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var organizationProfile = this.get('complianceStatus.organizationProfile');
      var reportHref = organizationProfile.get('links.asset_inventory_report');
      var accessToken = this.get("session.token.accessToken");

      if (!reportHref) {
        return this.transitionTo('gridiron-admin');
      }

      return _ember['default'].RSVP.hash({
        reportCSVHref: reportHref + '.csv',
        reportData: _ember['default'].$.ajax(reportHref, { headers: { Authorization: 'Bearer ' + accessToken } })
      });
    },

    setupController: function setupController(controller, model) {
      var sections = _dieselMixinsModelsAsset.ASSET_TYPES.map(function (type) {
        return {
          type: type,
          title: titleize(type),
          assets: model.reportData.report[type],
          columns: columnsFor(type)
        };
      });
      controller.setProperties({ sections: sections, reportCSVHref: model.reportCSVHref });
    },

    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);

      this.render('sidebars/engine-sidebar', {
        into: 'reports.asset-inventory-report',
        outlet: 'sidebar',
        controller: this.controllerFor('gridiron-organization')
      });
    }
  });
});

// Commented out unused constants, leaving here for completeness

// NETWORKS,