define('diesel/welcome/route', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _ember['default'].Route.extend({
    beforeModel: function beforeModel() {
      if (!this.session.get('isAuthenticated')) {
        this.transitionTo('login');
      }
    },

    model: function model(params) {
      return _ember['default'].RSVP.hash({
        authorization: this.get('authorization').load()
      }).then(function (res) {
        var authorizationContext = res.authorization.getContext(params.organization_id);

        if (!authorizationContext) {
          // Emulate an Ember Data not-found error so our app.error page can render it. There's
          // probably a better way to do this, but for now this is what it is.
          var e = new _emberData['default'].AdapterError();
          e.message = 'Organization ' + params.organization_id + ' not found';
          e._requestStatus = 404;
          e._requestStatusText = 'Not Found';
          throw e;
        }

        return {
          authorizationContext: authorizationContext,
          organization: authorizationContext.get('organization'),
          billingDetail: authorizationContext.get('billingDetail')
        };
      });
    },

    redirect: function redirect(model) {
      if (model.authorizationContext.get('billingDetail.hasPaymentMethod')) {
        return this.transitionTo('enclave');
      }
    }
  });
});