define('diesel/models/app', ['exports', 'ember-data', 'diesel/models/base', 'ember', 'diesel/mixins/models/provisionable', 'diesel/mixins/models/statuses'], function (exports, _emberData, _dieselModelsBase, _ember, _dieselMixinsModelsProvisionable, _dieselMixinsModelsStatuses) {
  exports['default'] = _dieselModelsBase['default'].extend(_dieselMixinsModelsProvisionable['default'], {
    // properties
    handle: _emberData['default'].attr('string'),
    gitRepo: _emberData['default'].attr('string'),
    createdAt: _emberData['default'].attr('iso-8601-timestamp'),

    reloadOn: [_dieselMixinsModelsStatuses['default'].DEPROVISIONING],

    // relationships
    account: _emberData['default'].belongsTo('account', { async: true }),
    services: _emberData['default'].hasMany('service', { async: true }),
    operations: _emberData['default'].hasMany('operation', { async: true }),
    vhosts: _emberData['default'].hasMany('vhost', { async: true }),
    lastOperation: _emberData['default'].belongsTo('operation', { async: true }),
    lastDeployOperation: _emberData['default'].belongsTo('operation', { async: true }),
    currentImage: _emberData['default'].belongsTo('image', { async: true }),
    currentConfiguration: _emberData['default'].belongsTo('configuration', { async: true }),

    serviceUsage: _ember['default'].computed.mapBy('services', 'usage'),
    usage: _ember['default'].computed.sum('serviceUsage')
  });
});