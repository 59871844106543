define('diesel/models/threat-source', ['exports', 'ember-data', 'diesel/models/base'], function (exports, _emberData, _dieselModelsBase) {
  exports['default'] = _dieselModelsBase['default'].extend({
    riskAssessment: _emberData['default'].belongsTo('risk-assessment'),
    handle: _emberData['default'].attr('string'),
    title: _emberData['default'].attr('string'),
    description: _emberData['default'].attr('string'),
    capability: _emberData['default'].attr('number'),
    intent: _emberData['default'].attr('number'),
    targeting: _emberData['default'].attr('number'),
    threatVector: _emberData['default'].attr('number'),
    adversarial: _emberData['default'].attr('boolean'),
    threatEvents: _emberData['default'].hasMany('threat-event', {
      async: false
    })
  });
});