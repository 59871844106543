define('diesel/components/default-payment-method/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    billingDetail: null,
    paymentMethod: null,

    isDefault: _ember['default'].computed('billingDetail.links.payment_method', 'paymentMethod.links.self', function () {
      var defaultHref = this.get('billingDetail.links.payment_method');
      var methodHref = this.get('paymentMethod.links.self');
      return defaultHref === methodHref;
    })
  });
});