define('diesel/password/update/route', ['exports', 'ember', 'diesel/mixins/routes/disallow-authenticated'], function (exports, _ember, _dieselMixinsRoutesDisallowAuthenticated) {
  exports['default'] = _ember['default'].Route.extend(_dieselMixinsRoutesDisallowAuthenticated['default'], {
    model: function model(params) {
      return {
        challengeId: params.challenge_id,
        verificationCode: params.verification_code,
        userId: params.user_id,
        password: null,
        passwordConfirmation: null
      };
    },

    actions: {
      save: function save(model) {
        var _this = this;

        var verification = this.store.createRecord('verification', {
          challengeId: model.challengeId,
          verificationCode: model.verificationCode,
          password: model.password,
          type: 'password_reset_challenge'
        });
        verification.save().then(function () {
          _this.transitionTo('login');
        }, function (e) {
          verification.destroy();

          var message = e.message || 'An unexpected error occurred.';

          _this.controllerFor('password/update').set('error', '\n          There was an error resetting your password: ' + message + '.\n        ');
        });
      }
    }
  });
});