define('diesel/components/modal-verify-bank-account/component', ['exports', 'ember', 'diesel/mixins/components/modal-base'], function (exports, _ember, _dieselMixinsComponentsModalBase) {
  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsModalBase['default'], {
    store: _ember['default'].inject.service(),

    paymentMethod: null,
    isVerifying: false,
    error: null,
    amountDecimal1: null,
    amountDecimal2: null,
    title: 'Verify bank account',

    description: _ember['default'].computed.alias('bankAccount.description'),
    bankAccount: _ember['default'].computed.alias('paymentMethod'),

    validate: function validate(a1, a2) {
      if (_ember['default'].isBlank(a1) || _ember['default'].isBlank(a2)) {
        return 'Both deposit amounts are required';
      }

      return;
    },

    actions: {
      beforeDismiss: function beforeDismiss() {
        this.get('paymentMethod').rollbackAttributes();
      },

      clearMessages: function clearMessages() {
        this.set('error', null);
      },

      verifyBankAccount: function verifyBankAccount() {
        var _this = this;

        this.set('error', null);

        var _getProperties = this.getProperties('bankAccount', 'amountDecimal1', 'amountDecimal2', 'store');

        var store = _getProperties.store;
        var bankAccount = _getProperties.bankAccount;
        var amountDecimal1 = _getProperties.amountDecimal1;
        var amountDecimal2 = _getProperties.amountDecimal2;

        var error = this.validate(amountDecimal1, amountDecimal2);

        if (error) {
          this.set('error', error);
          return;
        }

        this.set('isVerifying', true);

        return store.createRecord('verification-attempt', {
          stripeSource: bankAccount,
          amountDecimal1: amountDecimal1,
          amountDecimal2: amountDecimal2
        }).save().then(function () {
          bankAccount.reload().then(function () {
            return _this.sendAction('dismissModal');
          });
        })['catch'](function (e) {
          _this.set('isVerifying', false);
          _this.set('error', e.message);
          return e;
        });
      }
    }
  });
});