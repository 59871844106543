define('diesel/components/policy-editor/policy-document', ['exports', 'diesel/utils/assign', 'diesel/components/policy-editor/-utils/document-schema'], function (exports, _dieselUtilsAssign, _dieselComponentsPolicyEditorUtilsDocumentSchema) {
  var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

  exports.update = update;

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

  var PolicyDocument = (function () {
    function PolicyDocument() {
      var _ref = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      var doc = _ref.doc;
      var didChange = _ref.didChange;
      var allowsField = _ref.allowsField;

      _classCallCheck(this, PolicyDocument);

      this.doc = doc || {};
      this.didChange = didChange || function () {};
      this.allowsField = allowsField || _dieselComponentsPolicyEditorUtilsDocumentSchema.allowsField;
      this.history = [doc];
      this.future = [];
    }

    /**
     * This function "updates" a javascript object by returning a shallow
     * copy of the object and any objects along the path of the update.
     */

    _createClass(PolicyDocument, [{
      key: 'appendChild',
      value: function appendChild(path, obj) {
        var childrenPath = [].concat(_toConsumableArray(buildChildrenPath(path)), ['children']);
        this._update(childrenPath, push, obj);
      }
    }, {
      key: 'removeNode',
      value: function removeNode(path) {
        var indexToRemove = path.pop();
        var childrenPath = [].concat(_toConsumableArray(buildChildrenPath(path)), ['children']);
        this._update(childrenPath, remove, indexToRemove);
      }
    }, {
      key: 'moveNode',
      value: function moveNode(path, adjustment) {
        var indexToMove = path.pop();
        var childrenPath = [].concat(_toConsumableArray(buildChildrenPath(path)), ['children']);

        this._update(childrenPath, move, indexToMove, adjustment);
      }
    }, {
      key: 'set',
      value: function set(path, key, value) {
        var childrenPath = buildChildrenPath(path);
        this._update(childrenPath, _set, key, value);
      }
    }, {
      key: 'getNode',
      value: function getNode(path) {
        var childrenPath = buildChildrenPath(path);
        var node = this.doc;

        while (childrenPath.length) {
          var key = childrenPath.shift();
          node = node[key];
        }

        return node;
      }
    }, {
      key: 'serialize',
      value: function serialize() {
        // Inside of a replacer, we need to access `this`, the current object
        // being serialized. So we save this function in the closure.
        var allowsField = this.allowsField;

        var replacer = function replacer(key, value) {
          if (Array.isArray(this) || allowsField(this.type, key)) {
            return value;
          }
        };

        return JSON.stringify(this.doc, replacer, '  ');
      }
    }, {
      key: 'replace',
      value: function replace(newDoc) {
        this.history.push(newDoc);
        this.future = [];
        this.doc = newDoc;
        this.didChange(this.doc);
      }
    }, {
      key: 'undo',
      value: function undo() {
        if (this.history.length === 1) {
          return;
        }

        var now = this.history.pop();
        this.future.push(now);
        this.doc = this.history[this.history.length - 1];
        this.didChange(this.doc);
      }
    }, {
      key: 'redo',
      value: function redo() {
        if (this.future.length === 0) {
          return;
        }

        var future = this.future.pop();
        this.history.push(future);
        this.doc = this.history[this.history.length - 1];
        this.didChange(this.doc);
      }
    }, {
      key: '_update',
      value: function _update() {
        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        this.replace(update.apply(undefined, [this.doc].concat(args)));
      }
    }, {
      key: 'prettyString',
      get: function get() {
        return JSON.stringify(this.doc, null, "  ");
      }
    }]);

    return PolicyDocument;
  })();

  exports['default'] = PolicyDocument;

  function update(object, path, operation) {
    var key = path[0];

    if (!key && !object) {
      throw new Error('update must be passed either an object/array or path containing a key');
    }

    // We were passed an empty path so just perform the operation on the object.

    for (var _len2 = arguments.length, args = Array(_len2 > 3 ? _len2 - 3 : 0), _key2 = 3; _key2 < _len2; _key2++) {
      args[_key2 - 3] = arguments[_key2];
    }

    if (key === undefined) {
      // Returns a copy. If the object is undefined, operation should return an
      // appropriate type of object for that operation.
      return operation.apply(undefined, [object].concat(args));
    }

    // At this point we know we'll be assigning a value to the object so we make
    // a shallow copy.
    var objectCopy = copyOrCreate(object, key);

    // We only have one key left so perform the operation, assigning the result
    // to the objectCopy.
    if (path.length === 1) {
      objectCopy[key] = operation.apply(undefined, [objectCopy[key]].concat(args));
      return objectCopy;
    }

    // If we've made it this far we want to recursively call update with the
    // nested path and then return the first objectCopy we made.
    objectCopy[key] = update.apply(undefined, [objectCopy[key], path.slice(1), operation].concat(args));

    return objectCopy;
  }

  function buildChildrenPath(array) {
    array = Array.isArray(array) ? array : [array];
    return array.reduce(function (p, i) {
      return [].concat(_toConsumableArray(p), ['children', i]);
    }, []);
  }

  function copyOrCreate(object, key) {
    if (Array.isArray(object)) {
      return object.slice();
    } else if (object === undefined) {
      if (typeof key === 'number') {
        return [];
      } else if (typeof key === 'string') {
        return {};
      } else {
        throw new Error('invalid key found in path: ' + key + ', type: ' + typeof key);
      }
    } else {
      return (0, _dieselUtilsAssign['default'])({}, object);
    }
  }

  /* Functions for use with `update` */

  function _set(o, k, v) {
    return (0, _dieselUtilsAssign['default'])({}, o, _defineProperty({}, k, v));
  }

  function push(array, value) {
    return [].concat(_toConsumableArray(array || []), [value]);
  }

  function remove(array, index) {
    var copy = (array || []).slice();
    copy.splice(index, 1);
    return copy;
  }

  function move(array, itemIndex, adjustment) {
    if (array) {
      var copy = array.slice();
      var item = array[itemIndex];

      // Remove
      copy.splice(itemIndex, 1);

      // Insert
      copy.splice(itemIndex + adjustment, 0, item);

      return copy;
    } else {
      return [];
    }
  }
});