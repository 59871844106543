define('diesel/adapters/account-resource', ['exports', 'diesel/adapters/api', 'diesel/utils/build-url-with-prefix-map'], function (exports, _dieselAdaptersApi, _dieselUtilsBuildUrlWithPrefixMap) {
  exports['default'] = _dieselAdaptersApi['default'].extend({
    buildURL: (0, _dieselUtilsBuildUrlWithPrefixMap['default'])({
      'accounts': { property: 'account.id', only: ['createRecord', 'query'] }
    }),

    query: function query(store, type, _query) {
      if (!_query.account) {
        return this._super.apply(this, arguments);
      }

      var record = store.createRecord(type.modelName, { account: _query.account });
      var url = this.buildURL(type.modelName, null, { record: record }, 'query');

      record.rollbackAttributes();
      delete _query.account;
      return this.ajax(url, 'GET', { data: _query });
    }
  });
});