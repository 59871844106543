define('diesel/databases/route', ['exports', 'ember', 'diesel/utils/fetch-all-pages', 'diesel/config/environment'], function (exports, _ember, _dieselUtilsFetchAllPages, _dieselConfigEnvironment) {

  var PROVISION_TIMEOUT = _dieselConfigEnvironment['default'].timeouts.databaseProvisionOperation;

  exports['default'] = _ember['default'].Route.extend({
    modalDisplay: _ember['default'].inject.service(),

    title: function title() {
      var account = this.modelFor('account');
      return account.get('handle') + ' Databases';
    },

    model: function model() {
      var account = this.modelFor('account');
      return _ember['default'].RSVP.hash({
        account: account,
        databaseImages: (0, _dieselUtilsFetchAllPages['default'])(this.store, null, 'database-image'),
        databases: (0, _dieselUtilsFetchAllPages['default'])(this.store, account, 'database')
      });
    },

    setupController: function setupController(controller, _ref) {
      var databaseImages = _ref.databaseImages;
      var databases = _ref.databases;
      var account = _ref.account;

      controller.setProperties({ model: databases, account: account, databaseImages: databaseImages });
    },

    actions: {
      openCreateDbModal: function openCreateDbModal() {
        var account = this.modelFor('account');
        var newDb = this.store.createRecord('database', { account: account });
        var databaseImages = this.controller.get('databaseImages');

        var modalDisplay = this.get('modalDisplay');
        modalDisplay.showModal('modal-create-db', { newDb: newDb, account: account, databaseImages: databaseImages }, {
          actions: { onCreateDb: modalDisplay.modalAction(this, 'onCreateDb') }
        });
      },

      onCreateDb: function onCreateDb(database, diskSize, containerSize) {
        var _this = this;

        var handle = database.get('handle');

        _ember['default'].assert('Database must have account with id', !!database.get('account.id'));
        return database.save().then(function () {
          var operation = _this.store.createRecord('operation', {
            type: 'provision', diskSize: diskSize, containerSize: containerSize, database: database
          });

          return operation.save();
        }).then(function (operation) {
          var message = handle + ' database created';

          _this.transitionTo('database', database.get('id'));
          _ember['default'].get(_this, 'flashMessages').success(message);

          // Do not return this value, we do not want the promise chain to block
          // waiting for it to finish
          operation.reloadUntilStatusChanged(PROVISION_TIMEOUT).then(function () {
            return database.reload();
          });
        })['catch'](function (e) {
          var defaultMessage = 'There was an error saving ' + handle;
          var message = e.message || defaultMessage;
          _ember['default'].get(_this, 'flashMessages').danger(message);
          return e;
        });
      }
    }
  });
});