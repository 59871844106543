define('diesel/utils/plan', ['exports'], function (exports) {
  exports.transformPlansToMap = transformPlansToMap;
  exports.transformPlanData = transformPlanData;
  function planToPlanView(plan, currentPlan, availablePlans) {
    var planId = plan.get('id');
    var name = plan.get('name');
    switch (name) {
      case 'starter':
        {
          return {
            id: planId,
            name: name,
            currentPlan: currentPlan,
            desc: 'Deploy your first few resources',
            descComputeIncludes: '-',
            descComputeAvailable: '3 GB',
            descStorageIncludes: '-',
            descStorageAvailable: '20 GB',
            descEndpointsIncludes: '-',
            descEndpointsAvailable: '1',
            descSub: '',
            price: '$0',
            maxPrice: '$220.56',
            available: availablePlans.includes(name),
            features: ['Deploy on Shared Stacks (Networking and Compute shared with other Aptible customers)', 'Deploy in US East (N. Virginia)']
          };
        }
      case 'growth':
        {
          return {
            id: planId,
            name: name,
            currentPlan: currentPlan,
            desc: 'Begin the search for product-market fit',
            descComputeIncludes: '3 GB',
            descComputeAvailable: '11 GB',
            descStorageIncludes: '10 GB',
            descStorageAvailable: '60 GB',
            descEndpointsIncludes: '1',
            descEndpointsAvailable: '3',
            prev: 'starter',
            price: '$185',
            maxPrice: '$1,256.56',
            available: availablePlans.includes(name),
            features: ['15% discount on included resources', 'Up to 2 concurrent SSH Sessions for temporary container access', 'Deploy in 1 region (of your choice)', 'Support: Choose from Standard or Premium', 'Dedicated Stacks (Isolated Tenancy) Available', 'Available HIPAA BAA']
          };
        }
      case 'scale':
        {
          return {
            id: planId,
            name: name,
            currentPlan: currentPlan,
            desc: 'Scale your product and business',
            descComputeIncludes: '10 GB',
            descComputeAvailable: '40 GB',
            descStorageIncludes: '100 GB',
            descStorageAvailable: '400 GB',
            descEndpointsIncludes: '4',
            descEndpointsAvailable: '20',
            prev: 'growth',
            price: '$599',
            maxPrice: '$3,698.80',
            available: availablePlans.includes(name),
            features: ['20% discount on included resources', 'Up to 3 concurrent SSH Sessions for temporary container access', 'Deploy in 1 region (of your choice)', 'Support: Choose from Standard or Premium', 'Dedicated Stacks (Isolated Tenancy) Available', 'Available HIPAA BAA']
          };
        }
      case "enterprise":
        {
          return {
            id: planId,
            name: name,
            currentPlan: currentPlan,
            desc: 'Meet the highest requirements for security and reliability',
            descComputeIncludes: 'Custom',
            descComputeAvailable: 'Unlimited',
            descStorageIncludes: 'Custom',
            descStorageAvailable: 'Unlimited',
            descEndpointsIncludes: 'Custom',
            descEndpointsAvailable: 'Unlimited',
            prev: 'scale',
            price: 'Custom',
            maxPrice: 'Custom',
            available: availablePlans.includes(name),
            features: ['No limits on available Compute, Database Storage, or Endpoints', 'Deploy in 15+ Regions', '99.95% Uptime SLA', 'Advanced Networking Features such as IPsec VPNs and VPC Peering', 'Available HITRUST Inheritance and Security & Compliance Dashboard', 'Support: Choose from Standard, Premium, Enterprise (24/7 Support)', 'Custom pricing and payment options with annual commitments and payments']
          };
        }
    }
  }

  function transformPlansToMap(planListData) {
    var availablePlans = arguments.length <= 1 || arguments[1] === undefined ? [] : arguments[1];
    var curPlanId = arguments.length <= 2 || arguments[2] === undefined ? '' : arguments[2];

    var planList = planListData.toArray();
    return planList.reduce(function (acc, plan) {
      var isPlanTheCurrentPlan = plan.get('id') === curPlanId;
      acc[plan.get('name')] = planToPlanView(plan, isPlanTheCurrentPlan, availablePlans);
      return acc;
    }, {});
  }

  function transformPlanData(data) {
    var forbidPlanSelection = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

    var org = data.org;
    var planListData = data.planList;
    var activePlanList = data.activePlanList;

    var activePlan = activePlanList.get('firstObject');
    var availablePlans = forbidPlanSelection ? [] : activePlan.get('availablePlans') || [];
    var curPlan = activePlan.get('plan');

    return {
      plans: transformPlansToMap(planListData, availablePlans, curPlan.get('id')),
      activePlan: activePlan,
      // curPlan is the actual plan model (not the activePlan) that is currently on activePlan via plan_id
      curPlan: curPlan,
      organization: org
    };
  }
});