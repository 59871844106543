define('diesel/models/threat-event', ['exports', 'ember-data', 'diesel/models/base', 'ember'], function (exports, _emberData, _dieselModelsBase, _ember) {
  var RELEVANCE_THRESHOLD = 1;

  exports.RELEVANCE_THRESHOLD = RELEVANCE_THRESHOLD;
  exports['default'] = _dieselModelsBase['default'].extend({
    riskAssessment: _emberData['default'].belongsTo('risk-assessment'),
    handle: _emberData['default'].attr('string'),
    title: _emberData['default'].attr('string'),
    description: _emberData['default'].attr('string'),

    riskLevel: _emberData['default'].attr('number'),
    relevance: _emberData['default'].attr('number'),
    overallLikelihood: _emberData['default'].attr('number'),
    impact: _emberData['default'].attr('number'),
    likelihoodOfInitiation: _emberData['default'].attr('number'),
    likelihoodOfAdverseImpacts: _emberData['default'].attr('number'),
    exceedsAtackerCapacity: _emberData['default'].attr('number'),
    attackerSkills: _emberData['default'].attr('number'),

    typicalSeverity: _emberData['default'].attr('number'),
    adversarial: _emberData['default'].attr('boolean'),

    threatSources: _emberData['default'].hasMany('threat-source', {
      async: false
    }),
    predisposingConditions: _emberData['default'].hasMany('predisposing-condition', {
      async: false
    }),
    vulnerabilities: _emberData['default'].hasMany('vulnerability', {
      async: false
    }),

    relevant: _ember['default'].computed('predisposingConditions.@each.relevant', function () {
      return this.get('predisposingConditions').any(function (pdc) {
        return pdc.get('relevant');
      });
    }),
    relevantIndex: _ember['default'].computed('relevant', function () {
      //for sorting easil
      return this.get('relevant') ? 0 : 1;
    }),
    securityControls: _ember['default'].computed('vulnerabilities.[]', function () {
      var securityControls = {};

      this.get('vulnerabilities').forEach(function (vulnerability) {
        vulnerability.get('securityControls').forEach(function (sc) {
          securityControls[sc.get('id')] = sc;
        });
      });

      return Object.keys(securityControls).map(function (id) {
        return securityControls[id];
      });
    }),

    briefDescription: _ember['default'].computed('description', function () {
      var maxLength = 99;
      if (this.get('description.length') < maxLength) {
        return false;
      }

      return '' + this.get('description').substr(0, maxLength - 1);
    }),

    relevanceTitle: _ember['default'].computed('predisposingConditions.[]', function () {
      var pdc = this.get('predisposingConditions').mapBy('title');
      var s = pdc.length === 1 ? '' : 's';

      if (pdc.length === 0) {
        return 'There are no relevant predisposing conditions for this threat. This threat is not considered relevant';
      }

      return 'This threat is considered relevant based on the predisposing condition' + s + ': ' + pdc.join(', ');
    })
  });
});