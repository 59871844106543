define('diesel/components/login-form/component', ['exports', 'ember', 'diesel/config/environment'], function (exports, _ember, _dieselConfigEnvironment) {
  exports.buildCredentials = buildCredentials;

  var U2F_STATE_NONE = 0;
  var U2F_STATE_WAITING = 1;
  var U2F_STATE_NOT_AVAILABLE = 2;
  var U2F_STATE_ERRORED = 3;

  function buildCredentials(email, password, otpToken, u2f) {
    var credentials = {
      username: email,
      password: password,
      grant_type: 'password',
      scope: 'manage',
      expires_in: 12 * 60 * 60 // 12 hours
    };

    if (!_ember['default'].isNone(otpToken)) {
      credentials.otp_token = otpToken;
    }

    if (!_ember['default'].isNone(u2f)) {
      credentials.u2f = u2f;
    }

    return credentials;
  }

  exports['default'] = _ember['default'].Component.extend({
    u2f: _ember['default'].inject.service(),
    appBaseUrl: _dieselConfigEnvironment['default'].aptibleHosts.appUrl,

    // Required parameters
    loginCallback: null,
    formTitle: null,
    actionTitle: null,
    spinnerTitle: null,

    // Optional parameters
    email: null,
    password: null,
    emailDisabled: false,
    showHelpLinks: true,
    customHelpText: null,
    // Leave disabled until we're ready to launch SSO
    showSSOLink: false,

    // Internal state
    isLoggingIn: false,
    otpRequested: false,
    otpToken: null,

    u2fState: U2F_STATE_NONE,
    u2fError: null,

    autofocusEmail: _ember['default'].computed.not('emailDisabled'),
    autofocusPassword: _ember['default'].computed.not('autofocusEmail'),

    u2fNone: _ember['default'].computed.equal('u2fState', U2F_STATE_NONE),
    u2fWaiting: _ember['default'].computed.equal('u2fState', U2F_STATE_WAITING),
    u2fNotAvailable: _ember['default'].computed.equal('u2fState', U2F_STATE_NOT_AVAILABLE),
    u2fErrored: _ember['default'].computed.equal('u2fState', U2F_STATE_ERRORED),

    otpPlaceholder: _ember['default'].computed('u2fWaiting', function () {
      if (this.get('u2fWaiting')) {
        return 'Alternatively, touch your Security Key now.';
      }
      return '';
    }),

    login: function login(email, password, otpToken, u2f, isU2fRetry) {
      var _this = this;

      _ember['default'].debug('\n      login\n      email=' + email + ' password=' + password + '\n      otpToken=' + otpToken + ' u2f=' + _ember['default'].inspect(u2f) + '\n      isU2fRetry=' + isU2fRetry + '\n    ');

      this.set('isLoggingIn', true);
      this.set('error', null);

      var credentials = buildCredentials(email, password, otpToken, u2f);

      this.get('loginCallback')(credentials).then(function () {
        _this.sendAction('loggedIn');
      }, function (e) {
        if (isU2fRetry) {
          // Perhaps the user changed their password in the meantime? Let's just
          // start from scratch.
          _this.set('error', e.message);
          _this.set('otpRequested', false);
          _this.set('u2fState', U2F_STATE_NONE);
          return;
        }

        if (e.authError !== 'otp_token_required') {
          _this.set('error', e.message);
          return;
        }

        _this.set('otpRequested', true);
        _this.focusOtp();

        if (e.authU2f) {
          return _this.get('u2f').testWebAuthnSupport().then(function (hasU2fSupport) {
            var u2fState = U2F_STATE_NOT_AVAILABLE;

            if (hasU2fSupport) {
              u2fState = U2F_STATE_WAITING;
              _this.get('u2f').sign(e.authU2f).then(function (r) {
                return _this.login(email, password, null, r, true);
              }, function (e) {
                _this.set('u2fState', U2F_STATE_ERRORED);
                _this.set('u2fError', e.message);
              });
            }

            _this.set('u2fState', u2fState);
          });
        }
      })['finally'](function () {
        _this.set('isLoggingIn', false);
      });
    },

    focusOtp: function focusOtp() {
      this.$('input[name=otp-token]').focus();
    },

    actions: {
      login: function login() {
        this.login.apply(this, arguments);
      }
    }
  });
});