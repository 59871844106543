define('diesel/components/bank-account-form/component', ['exports', 'ember', 'diesel/utils/stripe'], function (exports, _ember, _dieselUtilsStripe) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var BankAccountFields = Object.keys(_dieselUtilsStripe.BankAccountSpec.fields);

  exports['default'] = _ember['default'].Component.extend({
    disabled: false,

    country: 'US',
    currency: 'usd',
    routingNumber: null,
    accountNumber: null,
    accountHolderName: null,
    accountHolderType: null,

    validAccountHolderTypes: ['individual', 'company'],

    changeObserver: _ember['default'].observer.apply(_ember['default'], _toConsumableArray(BankAccountFields).concat([function () {
      _ember['default'].run.once(this, 'trySendUpdate');
    }])),

    trySendUpdate: function trySendUpdate() {
      var fields = this.getProperties(BankAccountFields);
      var isValid = Object.entries(fields).every(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2);

        var v = _ref2[1];
        return !!v;
      });

      if (isValid) {
        this.sendAction('update', fields);
      }
    }
  });
});