define('diesel/organization/admin/billing/payment-methods/index/route', ['exports', 'ember'], function (exports, _ember) {

  var BillingRoute = 'organization.admin.billing';

  exports['default'] = _ember['default'].Route.extend({
    modalDisplay: _ember['default'].inject.service(),

    model: function model() {
      var billingDetail = this.modelFor(BillingRoute);
      return billingDetail.get('paymentMethods');
    },

    setupController: function setupController(controller, model) {
      var billingDetail = this.modelFor(BillingRoute);
      controller.set('billingDetail', billingDetail);
      controller.set('model', model);
    },

    actions: {
      updateDefaultPaymentMethod: function updateDefaultPaymentMethod(paymentMethod) {
        var _this = this;

        var billingDetail = this.modelFor(BillingRoute);
        billingDetail.set('paymentMethod', paymentMethod);

        this.controller.set('isSaving', true);
        billingDetail.save()['catch'](function (e) {
          return _this.get('flashMessages').danger(e.message);
        })['finally'](function () {
          return _this.controller.set('isSaving', false);
        });
      },

      deactivatePaymentMethod: function deactivatePaymentMethod(paymentMethod) {
        var _this2 = this;

        this.controller.set('isSaving', true);
        paymentMethod.set('deactivatedAt', new Date());
        paymentMethod.save()['catch'](function (e) {
          return _this2.get('flashMessages').danger(e.message);
        })['finally'](function () {
          return _this2.controller.set('isSaving', false);
        });
      },

      openVerifyBankAccountModal: function openVerifyBankAccountModal(paymentMethod) {
        var modalDisplay = this.get('modalDisplay');
        modalDisplay.showModal('modal-verify-bank-account', { paymentMethod: paymentMethod });
      }
    }
  });
});