define('diesel/components/modal-create-u2f-device/component', ['exports', 'ember', 'diesel/mixins/components/modal-base'], function (exports, _ember, _dieselMixinsComponentsModalBase) {
  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsModalBase['default'], {
    // Inputs
    newU2fDevice: null,

    store: _ember['default'].inject.service(),
    u2f: _ember['default'].inject.service(),
    flashMessages: _ember['default'].inject.service(),

    isRegistering: false,

    disableSave: _ember['default'].computed.or('isPending', 'isRegistering'),
    isPending: _ember['default'].computed.or('newU2fDevice.isSaving', 'newU2fDevice.isValidating'),

    title: 'Register a new Security Key',

    buttonLabel: _ember['default'].computed('disableSave', 'isRegistering', function () {
      if (!this.get('disableSave')) {
        return 'Register';
      }

      if (this.get('isRegistering')) {
        return 'Touch your Security Key to proceed';
      }

      return 'Saving ...';
    }),

    actions: {
      beforeDismiss: function beforeDismiss() {
        this.get('newU2fDevice').rollbackAttributes();
      },

      createU2fDevice: function createU2fDevice() {
        var _this = this;

        var u2fDevice = this.get('newU2fDevice');
        var user = u2fDevice.get('user');

        return _ember['default'].RSVP.hash({
          u2fChallenge: this.get('store').createRecord('u2f-challenge', { user: user }).save(),
          u2fDevices: user.get('u2fDevices')
        }).then(function (h) {
          _this.set('isRegistering', true);
          return _this.get('u2f').register(h.u2fChallenge, h.u2fDevices).then(function (attributes) {
            u2fDevice.setProperties(attributes);
            return u2fDevice.save().then(function () {
              return _this.sendAction('dismissModal');
            });
          }, function (e) {
            _this.set('registrationError', e.message);
          })['finally'](function () {
            _this.set('isRegistering', false);
          });
        })['catch'](function (e) {
          var message = e.message || 'An unexpected error occurred.';
          _ember['default'].get(_this, 'flashMessages').danger(message);
        });
      }
    }
  });
});