define('diesel/utils/handleify', ['exports'], function (exports) {
  var maxChars = 64;

  exports.maxChars = maxChars;
  function replaceWithLower(match) {
    return match.toLowerCase();
  }

  function truncate(input, maxCharsOverride) {
    var max = maxCharsOverride || maxChars;
    return input.slice(0, max);
  }

  var capitalLetters = /[A-Z]/g;
  var spaces = /\s+/g;
  var nonAlphaNumerics = /[^0-9a-z._-]/g;

  var handleify = function handleify(input, maxCharsOverride) {
    return truncate(input, maxCharsOverride).replace(capitalLetters, replaceWithLower).replace(spaces, '-').
    // FIXME: nonAlphaNumerics really should be replaced with an empty string,
    // however that breaks the `value` attr binding so that the first invalid
    // character is never actually stripped.
    replace(nonAlphaNumerics, '');
  };
  exports.handleify = handleify;
});