define('diesel/initializers/with-active-class', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(application) {
    application.inject('component:with-active-class', 'applicationController', 'controller:application');
  }

  exports['default'] = {
    name: 'with-active-class',
    initialize: initialize
  };
});