define('diesel/components/v2-only/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    // Required
    account: null,

    // Optional
    quiet: false,

    tagName: ''
  });
});