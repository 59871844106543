define('diesel/components/disk-resource-usage/component', ['exports', 'ember'], function (exports, _ember) {
  var HOURS_PER_MONTH = 730;

  exports.HOURS_PER_MONTH = HOURS_PER_MONTH;
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'tr',

    hasDisk: _ember['default'].computed.notEmpty('database.disk.size'),

    total: _ember['default'].computed('database.disk.size', 'hourlyRate', function () {
      return this.get('database.disk.size') * this.get('hourlyRate') * HOURS_PER_MONTH;
    })
  });
});