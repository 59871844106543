define('diesel/components/file-textarea/component', ['exports', 'ember'], function (exports, _ember) {
  //
  // Appends a textarea with the contents of whatever file was dropped on it.
  //
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: ['dropTargetActive'],

    dropTargetActive: false,

    doneReading: function doneReading(event) {
      var file = event.target;
      this.set('value', _ember['default'].getWithDefault(this, 'value', '') + file.result);
    },

    dragEnter: function dragEnter() {
      this.set('dropTargetActive', true);
    },

    dragLeave: function dragLeave() {
      this.set('dropTargetActive', false);
    },

    drop: function drop(event) {
      event.stopPropagation();
      event.preventDefault();
      this.set('dropTargetActive', false);
      for (var i in event.dataTransfer.files) {
        try {
          var reader = new FileReader();
          var file = event.dataTransfer.files[i];

          reader.onload = _ember['default'].run.bind(this, this.doneReading);
          reader.readAsText(file);
        } catch (error) {
          // no-op
        }
      }
    }
  });
});