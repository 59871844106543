define('diesel/mixins/models/business-criticality', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {

  var unitValueJoin = function unitValueJoin(_ref) {
    var u = _ref.u;
    var v = _ref.v;

    u = v === 1 ? u.singularize() : u;
    return v + ' ' + u;
  };

  exports['default'] = _ember['default'].Mixin.create({
    recoveryPointObjectiveValue: _emberData['default'].attr('number', { defaultValue: 24 }),
    recoveryPointObjectiveUnit: _emberData['default'].attr('string', { defaultValue: 'hours' }),
    recoveryPointObjective: _ember['default'].computed('recoveryPointObjectiveUnit', 'recoveryPointObjectiveValue', function () {
      return unitValueJoin({ v: this.get('recoveryPointObjectiveValue'), u: this.get('recoveryPointObjectiveUnit') });
    }),

    recoveryTimeObjectiveValue: _emberData['default'].attr('number', { defaultValue: 24 }),
    recoveryTimeObjectiveUnit: _emberData['default'].attr('string', { defaultValue: 'hours' }),
    recoveryTimeObjective: _ember['default'].computed('recoveryTimeObjectiveUnit', 'recoveryTimeObjectiveValue', function () {
      return unitValueJoin({ v: this.get('recoveryTimeObjectiveValue'), u: this.get('recoveryTimeObjectiveUnit') });
    }),

    maximumTolerableDowntimeValue: _emberData['default'].attr('number', { defaultValue: 72 }),
    maximumTolerableDowntimeUnit: _emberData['default'].attr('string', { defaultValue: 'hours' }),
    maximumTolerableDowntime: _ember['default'].computed('maximumTolerableDowntimeValue', 'maximumTolerableDowntimeUnie', function () {
      return unitValueJoin({ v: this.get('maximumTolerableDowntimeValue'), u: this.get('maximumTolerableDowntimeUnit') });
    })
  });
});