define('diesel/utils/to-policy-outline', ['exports', 'diesel/utils/get-documents'], function (exports, _dieselUtilsGetDocuments) {
  exports['default'] = toPolicyOutline;

  function toPolicyOutline(groups) {
    return groups.map(function (g) {
      return {
        title: g.title,
        children: (0, _dieselUtilsGetDocuments['default'])(g.children, undefined, [g]).map(function (d) {
          return {
            title: d.title,
            summary: getSummary(d),
            category: d.parentGroups[1] && d.parentGroups[1].title
          };
        })
      };
    });
  }

  function getSummary(node) {
    var children = node.children;

    if (!children) {
      return '';
    }

    for (var i = 0; i < children.length; i++) {
      var child = children[i];

      if (child.title && child.title.toLowerCase() === 'summary') {
        return child.template;
      }
    }
  }
});