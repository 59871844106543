define('diesel/components/each-key-value-in/component', ['exports', 'ember'], function (exports, _ember) {

  /**
   * This is component-based version of the Ember 2.1.0 helper {{#each-in}}.
   * See: http://emberjs.com/api/classes/Ember.Templates.helpers.html#method_each-in
   * When this codebase is using Ember >= 2.1.0, this should be removed in favor
   * of `each-in`.
   * Usage:
   * {{! object = { a: 'foo', b: 'bar' } }}
   * {{#each-key-value-in object=object as |key value|}}
   *   {{key}}: {{value}}
   * {{/each}}
   */
  exports['default'] = _ember['default'].Component.extend({
    object: null,

    keys: _ember['default'].computed('object', function () {
      var object = this.get('object');
      _ember['default'].assert('Must pass `object` to {{each-key-value-in}}', !!object);
      return Object.keys(object);
    })
  });
});