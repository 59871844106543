define('diesel/components/modal-edit-vhost/component', ['exports', 'ember', 'diesel/mixins/components/modal-base'], function (exports, _ember, _dieselMixinsComponentsModalBase) {
  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsModalBase['default'], {
    // Inputs
    vhostToEdit: null,
    service: null,

    // State
    ipFilteringValid: null,

    operationsManager: _ember['default'].inject.service(),

    title: _ember['default'].computed.alias('vhostToEdit.displayName'),

    formValid: _ember['default'].computed.alias('ipFilteringValid'),
    formInvalid: _ember['default'].computed.not('formValid'),

    actions: {
      beforeDismiss: function beforeDismiss() {
        this.get('vhostToEdit').rollbackAttributes();
      },

      commitChanges: function commitChanges() {
        var _this = this;

        var vhost = this.get('vhostToEdit');

        return vhost.save().then(function () {
          return _this.get('operationsManager').createBackgroundOperation(vhost, { type: 'provision' });
        }).then(function () {
          return _this.sendAction('dismissModal');
        });
      }
    }
  });
});