define('diesel/models/customer-control-resource', ['exports', 'ember', 'ember-data', 'diesel/models/base'], function (exports, _ember, _emberData, _dieselModelsBase) {

  var CUSTOM_RESOURCE_HANDLE_TYPES = ['MfaControlProxyUser'];

  var RESOURCE_TO_MODEL_TYPES = {
    'MfaControlProxyUser': 'user'
  };

  var RESOURCE_TYPE_NAMES = {
    'Account': 'Environment',
    'MfaControlProxyUser': 'User',
    'Vhost': 'Endpont'
  };

  function getModelType(resourceType) {
    return RESOURCE_TO_MODEL_TYPES[resourceType] || resourceType.dasherize();
  }

  function buildVhostLinkParams(resource) {
    var vhostId = resource.get('resourceId');
    return resource.store.find('vhost', vhostId).then(function (vhost) {
      return vhost.get('service');
    }).then(function (service) {
      if (service.get('links.database')) {
        return {
          path: 'database.vhosts.vhost',
          parentId: service.get('database.id'),
          modelId: vhostId
        };
      }

      if (service.get('links.app')) {
        return {
          path: 'app.vhosts.edit',
          parentId: service.get('app.id'),
          modelId: vhostId
        };
      }
      return null;
    });
  }

  function buildProxyUserLinkParams(resource) {
    return resource.get('customerControl').then(function (cc) {
      return cc.get('account');
    }).then(function (account) {
      return account.get('organization.id');
    }).then(function (parentId) {
      return { path: 'organization.members', parentId: parentId, modelId: null };
    });
  }

  function buildServiceLinkParams(resource) {
    var serviceId = resource.get('resourceId');
    return resource.store.find('service', serviceId).then(function (service) {
      return {
        path: 'app.services.service.scale',
        parentId: service.get('app.id'),
        modelId: serviceId
      };
    });
  }

  function buildDefaultLinkParams(resource) {
    var type = resource.get('resourceType');
    return _ember['default'].RSVP.resolve({
      path: type.underscore(),
      modelId: resource.get('resourceId'),
      parentId: null
    });
  }

  exports['default'] = _dieselModelsBase['default'].extend({
    compliant: _emberData['default'].attr('boolean'),
    customerControl: _emberData['default'].belongsTo('customerControl'),
    ignored: _emberData['default'].attr('boolean', { defaultValue: false }),
    detailsText: _emberData['default'].attr('string', { defaultValue: 'Details text' }),
    resourceHandle: _emberData['default'].attr('string'),
    resourceType: _emberData['default'].attr('string'),
    resourceId: _emberData['default'].attr('string'),
    requiresCustomResourceHandle: _ember['default'].computed('resourceType', function () {
      return CUSTOM_RESOURCE_HANDLE_TYPES.includes(this.get('resourceType'));
    }),
    loadedResource: _ember['default'].computed('resourceId', 'resourceType', function () {
      var type = getModelType(this.get('resourceType'));
      return this.store.find(type, this.get('resourceHandle'));
    }),

    readableResourceType: _ember['default'].computed('resourceType', function () {
      var type = this.get('resourceType');
      return RESOURCE_TYPE_NAMES[type] || type.capitalize();
    }),

    linkToParameters: _ember['default'].computed('resourceType', function () {
      var type = this.get('resourceType');
      // For some of these parameters, we need to fetch related resources in order to
      // build route segments. For these cases, we'll return a promise proxy
      var AsyncResponse = _ember['default'].ObjectProxy.extend(_ember['default'].PromiseProxyMixin);

      switch (type) {
        case 'Vhost':
          return AsyncResponse.create({ promise: buildVhostLinkParams(this) });
        case 'Service':
          return AsyncResponse.create({ promise: buildServiceLinkParams(this) });
        case 'MfaControlProxyUser':
          return AsyncResponse.create({ promise: buildProxyUserLinkParams(this) });
        default:
          return AsyncResponse.create({ promise: buildDefaultLinkParams(this) });
      }
    })
  });
});