define('diesel/database/route', ['exports', 'ember', 'diesel/mixins/routes/multi-resource-operation-list', 'diesel/mixins/routes/fetch-all-related-resources-mixin'], function (exports, _ember, _dieselMixinsRoutesMultiResourceOperationList, _dieselMixinsRoutesFetchAllRelatedResourcesMixin) {
  exports['default'] = _ember['default'].Route.extend(_dieselMixinsRoutesFetchAllRelatedResourcesMixin['default'], {
    modalDisplay: _ember['default'].inject.service(),

    title: function title(tokens) {
      if (tokens.length === 0) {
        tokens.push(this.currentModel.get('handle'));
      }
      var account = this.currentModel.get('account');
      tokens.push(account.get('handle'));
      return tokens.join(' - ');
    },

    afterModel: function afterModel(model) {
      return this._fetchAllRelatedResources(model);
    },

    setupController: function setupController(controller, model) {
      return this._setupAllRelatedResources(controller, model);
    },

    actions: {
      openDatabaseCredentialsModal: function openDatabaseCredentialsModal(databaseCredentials) {
        this.get('modalDisplay').showModal('modal-database-credentials', {
          databaseCredentials: databaseCredentials
        });
      },

      openDatabaseRestartModal: function openDatabaseRestartModal(databaseToRestart) {
        var modalDisplay = this.get('modalDisplay');
        modalDisplay.showModal('modal-restart-database', {
          databaseToRestart: databaseToRestart
        }, {
          actions: {
            onDatabaseRestart: modalDisplay.modalAction(this, 'openDatabaseActivity')
          }
        });
      },

      openDatabaseActivity: function openDatabaseActivity() {
        // If the database was restarted while on the activity page, refresh it,
        // else transition to database.activity
        if (this.get('router').isActive('database.activity')) {
          var refreshParams = { operationCount: _dieselMixinsRoutesMultiResourceOperationList.OPERATION_BATCH_SIZE };
          return this.modelFor('database.activity').refresh(refreshParams);
        } else {
          return this.transitionTo('database.activity');
        }
      }
    }
  });
});