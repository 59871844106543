define('diesel/app', ['exports', 'ember', 'diesel/resolver', 'ember-load-initializers', 'diesel/config/environment', 'diesel/utils/title-route-extensions', 'diesel/utils/link-component-extension', 'diesel/utils/frame-buster'], function (exports, _ember, _dieselResolver, _emberLoadInitializers, _dieselConfigEnvironment, _dieselUtilsTitleRouteExtensions, _dieselUtilsLinkComponentExtension, _dieselUtilsFrameBuster) {

  (0, _dieselUtilsFrameBuster['default'])();

  _ember['default'].Route.reopen(_dieselUtilsTitleRouteExtensions.RouteExtension);
  _ember['default'].Router.reopen(_dieselUtilsTitleRouteExtensions.RouterExtension);
  _ember['default'].LinkComponent.reopen(_dieselUtilsLinkComponentExtension['default']);

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _dieselConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _dieselConfigEnvironment['default'].podModulePrefix,
    Resolver: _dieselResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _dieselConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});