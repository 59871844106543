define('diesel/models/user-profile', ['exports', 'ember-data', 'diesel/models/base', 'ember'], function (exports, _emberData, _dieselModelsBase, _ember) {

  var UserProfile = _dieselModelsBase['default'].extend({
    userUrl: _emberData['default'].attr('string'),
    phone: _emberData['default'].attr('string'),
    title: _emberData['default'].attr('string'),
    user: _emberData['default'].belongsTo('user', { async: true }),
    organizationProfile: _emberData['default'].belongsTo('organization-profile', { async: true }),
    assignments: _emberData['default'].hasMany('assignment', { async: true }),
    submissions: _emberData['default'].hasMany('submission', { async: true })
  });

  UserProfile.reopenClass({
    _createFromOrganization: function _createFromOrganization(user, organizationProfile, store) {
      var userUrl = user.get('links.self');
      var newProfileParams = { organizationProfile: organizationProfile, userUrl: userUrl };
      return store.createRecord('user-profile', newProfileParams);
    },

    findOrCreate: function findOrCreate(user, organizationProfile, store) {
      var _this = this;

      var ObjectPromiseProxy = _ember['default'].ObjectProxy.extend(_ember['default'].PromiseProxyMixin);
      var promise = new _ember['default'].RSVP.Promise(function (resolve) {
        var findUserProfile = { user: user.get('links.self'),
          organizationProfile: organizationProfile };
        store.query('user-profile', findUserProfile).then(function (profiles) {
          if (profiles && profiles.get('length') > 0) {
            resolve(profiles.get('firstObject'));
          } else {
            resolve(_this._createFromOrganization(user, organizationProfile, store));
          }
        })['catch'](function (e) {
          if (e.status && e.status === 404) {
            resolve(_this._createFromOrganization(user, organizationProfile, store));
          } else {
            throw e;
          }
        });
      });

      return ObjectPromiseProxy.create({ promise: promise });
    }
  });

  exports['default'] = UserProfile;
});