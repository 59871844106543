define('diesel/components/operation-icon/component', ['exports', 'ember'], function (exports, _ember) {

  var types = {
    backup: 'fa-hdd-o',
    clone: 'fa-copy',
    configure: 'fa-cog',
    deploy: 'fa-cloud-upload',
    deprovision: 'fa-remove',
    execute: 'fa-chevron-right',
    logs: 'fa-file-text',
    provision: 'fa-server',
    ps: 'fa-wrench',
    rebuild: 'fa-refresh',
    reload: 'fa-refresh',
    replicate: 'fa-clone',
    reprovision: 'fa-refresh',
    restart: 'fa-refresh',
    restart_recreate: 'fa-refresh',
    scale: 'fa-sliders',
    tunnel: 'fa-exchange',
    captain_comeback_restart: 'fa-refresh',
    recover: 'fa-refresh',
    recover_recreate: 'fa-refresh'
  };

  var statuses = {
    queued: 'status-queued',
    running: 'status-running',
    failed: 'status-failed',
    succeeded: 'status-succeeded'
  };

  exports['default'] = _ember['default'].Component.extend({
    tagName: 'i',
    classNameBindings: [':fa', 'icon', 'color'],
    attributeBindings: ['title'],

    title: _ember['default'].computed('type', 'status', function () {
      return this.get('type').capitalize() + ' ' + this.get('status');
    }),

    color: _ember['default'].computed('status', function () {
      return statuses[this.get('status')];
    }),

    icon: _ember['default'].computed('type', 'status', function () {
      var status = this.get('status');

      if (status === 'running' || status === 'queued') {
        return 'fa-spin fa-spinner';
      }
      return types[this.get('type')] || 'fa-cogs';
    })
  });
});