define("diesel/organization/admin/billing/billing-address/controller", ["exports", "ember", "diesel/utils/states-to-iso", "diesel/utils/countries-to-iso"], function (exports, _ember, _dieselUtilsStatesToIso, _dieselUtilsCountriesToIso) {
  exports["default"] = _ember["default"].Controller.extend({
    isSaving: false,
    statesOptions: Object.values(_dieselUtilsStatesToIso.statesToIso),
    countriesOptions: Object.values(_dieselUtilsCountriesToIso.countriesToIso),
    currentState: _ember["default"].computed('billingAddress.state', function () {
      return _dieselUtilsStatesToIso.statesToIso[this.get('billingAddress.state')];
    }),
    currentCountry: _ember["default"].computed('billingAddress.country', function () {
      return _dieselUtilsCountriesToIso.countriesToIso[this.get('billingAddress.country')];
    }),
    errors: ''
  });
});