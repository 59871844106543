define('diesel/services/goldenboy', ['exports', 'ember', 'goldenboy-renderer'], function (exports, _ember, _goldenboyRenderer) {

  function buildRenderer(_ref) {
    var unitModels = _ref.unitModels;
    var intervalModels = _ref.intervalModels;

    // TODO: The API should perhaps not include the name in renderOptions (and we
    // should do it here).
    var units = unitModels.map(function (m) {
      return m.get('renderOptions');
    });
    var intervals = intervalModels.map(function (i) {
      return { name: i.get('name'), seconds: i.get('seconds') };
    });
    return (0, _goldenboyRenderer.hydrate)(units, intervals);
  }

  exports['default'] = _ember['default'].Service.extend({
    store: _ember['default'].inject.service(),

    refresh: function refresh() {
      var _this = this;

      var unitModels = this.get('store').findAll('unit');
      var intervalModels = this.get('store').findAll('interval');
      return _ember['default'].RSVP.hash({ unitModels: unitModels, intervalModels: intervalModels }).then(function (h) {
        return buildRenderer(h);
      }).then(function (r) {
        return _this.set('renderer', r);
      });
    },

    getLib: function getLib() {
      var renderer = this.get('renderer');
      if (!renderer) {
        throw new Error('Must call refresh() and wait before calling getLib()!');
      }
      return renderer;
    }
  });
});