define('diesel/components/c-compliance-metrics/component', ['exports', 'ember', 'diesel/utils/implementation-metrics'], function (exports, _ember, _dieselUtilsImplementationMetrics) {
  var _STYLES;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var STYLES = (_STYLES = {}, _defineProperty(_STYLES, _dieselUtilsImplementationMetrics.STATUS_NO_SCOPE, {
    icon: 'minus-circle-sm',
    iconClass: 'compliance-icon compliance-icon--default',
    progressClass: 'compliance-metrics__progress compliance-metrics__progress--default'
  }), _defineProperty(_STYLES, _dieselUtilsImplementationMetrics.STATUS_COMPLETE, {
    icon: 'check-circle-sm',
    iconClass: 'compliance-icon compliance-icon--success',
    progressClass: 'compliance-metrics__progress compliance-metrics__progress--success'
  }), _defineProperty(_STYLES, _dieselUtilsImplementationMetrics.STATUS_NOT_STARTED, {
    icon: 'exclamation-circle-sm',
    iconClass: 'compliance-icon compliance-icon--danger',
    progressClass: 'compliance-metrics__progress compliance-metrics__progress--danger'
  }), _defineProperty(_STYLES, _dieselUtilsImplementationMetrics.STATUS_PARTIAL, {
    icon: null,
    iconClass: 'compliance-icon compliance-icon--warn',
    progressClass: 'compliance-metrics__progress compliance-metrics__progress--warn'
  }), _STYLES);

  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    metrics: null,
    init: function init() {
      this._super.apply(this, arguments);
      var metrics = this.get("metrics");
      _ember['default'].assert("You must provide an instance of `ImplementationMetrics`", metrics instanceof _dieselUtilsImplementationMetrics['default']);
    },
    ui: _ember['default'].computed('metrics.status', function () {
      var status = this.get('metrics.status');
      return STYLES[status];
    })
  });
});