define('diesel/components/assignment-status-indicator/component', ['exports', 'ember', 'diesel/models/assignment-state'], function (exports, _ember, _dieselModelsAssignmentState) {
  exports['default'] = _ember['default'].Component.extend({
    statusText: _ember['default'].computed('status', function () {
      return _dieselModelsAssignmentState.ASSIGNMENT_STATES_AS_TEXT[this.get('status')];
    }),

    isExpired: _ember['default'].computed.equal('status', _dieselModelsAssignmentState.ASSIGNMENT_TRANSITIONS.EXPIRED),
    isCompleted: _ember['default'].computed.equal('status', _dieselModelsAssignmentState.ASSIGNMENT_TRANSITIONS.COMPLETED),
    isAssigned: _ember['default'].computed.equal('status', _dieselModelsAssignmentState.ASSIGNMENT_TRANSITIONS.ASSIGNED),
    isOverdue: _ember['default'].computed.equal('status', _dieselModelsAssignmentState.ASSIGNMENT_TRANSITIONS.PASSED_DUE)
  });
});