define('diesel/components/user-gravatars/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'ul',
    classNames: 'user-gravatars',
    truncatedUsers: _ember['default'].computed('users.[]', 'count', function () {
      var count = this.get('count') || this.get('users.length');
      return _ember['default'].A(this.get('users').slice(0, count));
    }),
    difference: _ember['default'].computed('users.[]', 'count', function () {
      return Math.max(this.get('users.length') - this.get('count'), 0);
    })
  });
});