define('diesel/models/billing-iteration', ['exports', 'ember-data', 'diesel/models/base', 'ember'], function (exports, _emberData, _dieselModelsBase, _ember) {
  exports['default'] = _dieselModelsBase['default'].extend({
    billingCycle: _emberData['default'].belongsTo('billing-cycle', { async: true }),
    projectedInvoice: _emberData['default'].belongsTo('projected-invoice', { async: true }),
    invoice: _emberData['default'].belongsTo('invoice', { async: true }),

    epoch: _emberData['default'].attr('number'),
    startsAt: _emberData['default'].attr('iso-8601-timestamp'),
    endsAt: _emberData['default'].attr('iso-8601-timestamp'),
    hasInvoice: _ember['default'].computed.bool('links.invoice'),

    invoiceTotalCashValue: _emberData['default'].attr('number'),
    invoiceState: _emberData['default'].attr('string')
  });
});