define('diesel/components/metric-drain-datadog-configuration/component', ['exports', 'ember', 'ember-validations/mixin'], function (exports, _ember, _emberValidationsMixin) {
  exports['default'] = _ember['default'].Component.extend(_emberValidationsMixin['default'], {
    apiKey: null,

    sendUpdate: _ember['default'].observer('apiKey', 'isValid', function () {
      var attrs = null;
      if (this.get('isValid')) {
        attrs = { api_key: this.get('apiKey') };
      }
      this.sendAction('update', attrs);
    }),

    validations: {
      apiKey: { presence: true }
    }
  });
});