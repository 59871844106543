define('diesel/utils/jwt', ['exports', 'ember', 'diesel/utils/base64'], function (exports, _ember, _dieselUtilsBase64) {
  exports['default'] = _ember['default'].Object.extend({
    token: null,

    payload: _ember['default'].computed('token', function () {
      var token = this.get('token');

      if (!token || token === "null") {
        return {};
      }

      var parts = token.split('.');

      _ember['default'].assert('JWT token is missing payload section: ' + token, parts[1]);

      try {
        var decoded = _dieselUtilsBase64['default'].decode(parts[1]);
        return JSON.parse(decoded);
      } catch (e) {
        throw new Error('JWT token has invalid payload: ' + token);
      }
    })
  });
});