define('diesel/components/service-scaler/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    service: null,
    isSaving: false,
    containerSize: null,
    containerCount: null,

    isSliding: false,

    containerCountMin: 0,
    containerCountMax: 10,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('containerSize', this.get('service.containerSize'));
      this.set('containerCount', this.get('service.containerCount'));
    },

    isv2Stack: _ember['default'].computed.equal('service.account.sweetnessStackVersion', 'v2'),
    isv1Stack: _ember['default'].computed.not('isv2Stack'),

    shouldDisable: _ember['default'].computed.or('isv1Stack', 'isSaving'),
    hasCountChanged: _ember['default'].computed('containerCount', 'service.containerCount', function () {
      return this.get('containerCount') !== this.get('service.containerCount');
    }),

    hasSizeChanged: _ember['default'].computed('containerSize', 'service.containerSize', function () {
      return this.get('containerSize') !== this.get('service.containerSize');
    }),

    showActionButtons: _ember['default'].computed.or('hasCountChanged', 'hasSizeChanged'),

    unitOfMeasure: _ember['default'].computed('service.account.type', function () {
      var type = this.get('service.account.type');
      return type ? type.capitalize() + " App Container" : '';
    }),

    actions: {
      setContainerSize: function setContainerSize(value) {
        this.set('isSliding', true);
        this.set('containerSize', value);
      },

      setContainerCount: function setContainerCount(value) {
        this.set('isSliding', true);
        this.set('containerCount', value);
      },

      finishSliding: function finishSliding() {
        this.set('isSliding', false);
      },

      cancel: function cancel() {
        this.set('containerSize', this.get('service.containerSize'));
        this.set('containerCount', this.get('service.containerCount'));

        // this resets no-ui-slider, as it doesn't have a simple way to
        // pass in the changed value
        this.rerender();
      },

      scale: function scale() {
        if (this.get('isSaving')) {
          return;
        }

        var component = this;

        var _getProperties = this.getProperties('service', 'containerSize', 'containerCount');

        var service = _getProperties.service;
        var containerSize = _getProperties.containerSize;
        var containerCount = _getProperties.containerCount;

        this.set('isSaving', true);

        var deferred = _ember['default'].RSVP.defer();
        this.sendAction('scaleService', service, containerCount, containerSize, deferred);

        deferred.promise.then(function () {
          if (component.isDestroyed) {
            return;
          }

          component.set('success', service.get('processType') + ' scaled to ' + containerCount + ' ' + containerSize + 'MB' + ' containers');
        })['catch'](function (e) {
          if (component.isDestroyed) {
            return;
          }

          component.set('error', e.message);
        })['finally'](function () {
          if (component.isDestroyed) {
            return;
          }

          component.set('isSaving', false);
        });
      },

      clearMessages: function clearMessages() {
        this.set('error', false);
        this.set('success', false);
      }

    }
  });
});