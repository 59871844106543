define('diesel/utils/location', ['exports', 'ember'], function (exports, _ember) {

  var Location = {
    replace: function replace(url) {
      window.location = url;
    },

    replaceAndWait: function replaceAndWait(url) {
      Location.replace(url);
      // never-resolving promise
      return new _ember['default'].RSVP.Promise(function () {});
    }
  };

  exports['default'] = Location;
});