define("diesel/components/error-page/component", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    tagName: "div",
    classNames: ["error-page"],

    errorCode: null,
    title: "An error occurred",
    message: "That's all we know",

    actionName: null,

    actions: {
      doAction: function doAction() {
        this.sendAction('onAction');
      }
    }
  });
});