define('diesel/services/aptible-status', ['exports', 'ember', 'diesel/config/environment'], function (exports, _ember, _dieselConfigEnvironment) {

  var POLL_INTERVAL = _dieselConfigEnvironment['default'].timeouts.aptibleStatusPoll;

  exports['default'] = _ember['default'].Service.extend({
    raven: _ember['default'].inject.service(),

    /**
     * maxCount is used in test to prevent polling from continuing indefinitely
     */
    refreshStatusAndReschedule: function refreshStatusAndReschedule() {
      var _this = this;

      var _ref = arguments.length <= 0 || arguments[0] === undefined ? { maxCount: Infinity } : arguments[0];

      var maxCount = _ref.maxCount;

      this._count = this._count || 0;
      if (this.isDestroyed) {
        // Interrupt the loop now.
        return;
      }

      if (!_dieselConfigEnvironment['default'].statusPageId) {
        _ember['default'].warn('No statusPageId is defined');
        return;
      }

      var url = 'https://' + _dieselConfigEnvironment['default'].statusPageId + '.statuspage.io/api/v2/status.json';

      _ember['default'].debug('Query updated status from: ' + url + ' (next refresh after ' + POLL_INTERVAL / 1000 + 's)');

      return new _ember['default'].RSVP.Promise(function (success, error) {
        _ember['default'].$.ajax(url, { success: success, error: error, crossDomain: true, dataType: 'json' });
      }).then(function (data) {
        if (!_this.isDestroyed) {
          _this.set('status', data.status);
          _this.set('page', data.page);
        }
      }, function (e) {
        _this.get('raven').captureException(e);
      })['finally'](function () {
        _this._count += 1;
        if (_this._count >= maxCount) {
          return;
        }
        _ember['default'].run.later(function () {
          _this.refreshStatusAndReschedule({ maxCount: maxCount });
        }, POLL_INTERVAL);
      });
    }
  });
});