define('diesel/adapters/operation', ['exports', 'diesel/adapters/api', 'diesel/utils/build-url-with-prefix-map'], function (exports, _dieselAdaptersApi, _dieselUtilsBuildUrlWithPrefixMap) {
  exports['default'] = _dieselAdaptersApi['default'].extend({
    buildURL: (0, _dieselUtilsBuildUrlWithPrefixMap['default'])({
      'databases': { property: 'database.id', only: ['createRecord'] },
      'apps': { property: 'app.id', only: ['createRecord'] },
      'images': { property: 'image.id', only: ['createRecord'] },
      'vhosts': { property: 'vhost.id', only: ['createRecord'] },
      'log_drains': { property: 'logDrain.id', only: ['createRecord'] },
      'metric_drains': { property: 'metricDrain.id', only: ['createRecord'] },
      'services': { property: 'service.id', only: ['createRecord'] },
      'database_credentials': { property: 'databaseCredential.id', only: ['createRecord'] },
      'backups': { property: 'backup.id', only: ['createRecord'] }
    }),

    query: function query(store, type, _query) {
      var url = this.buildURL(type.modelName, null, null, 'query');

      if (this.sortQueryParams) {
        _query = this.sortQueryParams(_query);
      }

      if (_query.app) {
        url = url.replace('/operations', '/apps/' + _query.app.id + '/operations');
        delete _query.app;
      } else if (_query.image) {
        url = url.replace('/operations', '/images/' + _query.image.id + '/operations');
        delete _query.image;
      } else if (_query.service) {
        url = url.replace('/operations', '/services/' + _query.service.id + '/operations');
        delete _query.service;
      } else if (_query.database) {
        url = url.replace('/operations', '/databases/' + _query.database.id + '/operations');
        delete _query.database;
      } else if (_query.databaseCredential) {
        url = url.replace('/operations', '/database_credentials/' + _query.databaseCredential.id + '/operations');
        delete _query.databaseCredential;
      }

      return this.ajax(url, 'GET', { data: _query });
    }
  });
});