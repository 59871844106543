define('diesel/components/c-load-ccr-resource/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),
    type: null,
    id: null,
    loadedResource: _ember['default'].computed('type', 'id', function () {
      var _getProperties = this.getProperties('type', 'id', 'store');

      var type = _getProperties.type;
      var id = _getProperties.id;
      var store = _getProperties.store;

      if (!type || !id) return null;
      return store.find(type.dasherize(), id);
    })
  });
});