define('diesel/utils/training/security-awareness-assessment', ['exports'], function (exports) {
  exports['default'] = { passingScore: 6, questions: [{
      title: 'What are the two core parameters in modeling risk?',
      choices: ['Intent and perpetrator', 'Vulnerabilities and security controls', 'Likelihood and impact'],
      answer: 2,
      error: 'Incorrect answer'
    }, {
      title: 'What’s the most secure way to create and manage passwords?',
      choices: ['Use an easy-to-remember password based on information only you know, like your last name, birth year, and favorite food (e.g., “Heston1923Spaghetti”)', 'Use a password manager to generate and store unique, strong passwords for each account', 'Because there have been so many data breaches, passwords don’t matter anymore', 'Create one strong passphrase and use it for all your most sensitive accounts'],
      answer: 1,
      error: 'Incorrect answer'
    }, {
      title: 'SMS-based multifactor authentication is just as strong as Time-based One Time Password (TOTP).',
      choices: ['True', 'False'],
      answer: 1,
      error: 'Incorrect answer'
    }, {
      title: 'If you receive an email that looks suspicious, you should:',
      choices: ['Follow the potentially fake links to investigate further', 'Immediately delete the emails and associated evidence to contain the spread', 'Report the potential phishing email to your Security Officer.', 'Download and investigate attachments with caution'],
      answer: 2,
      error: 'Incorrect answer'
    }, {
      title: 'Which statement is true regarding the sources of threats?',
      choices: ['Only outsiders can cause security or privacy incidents', 'Only malicious employees are considered insider threats', 'Insider threats can be caused by knowing and unknowing misuses of data'],
      answer: 2,
      error: 'Incorrect answer'
    }, {
      title: 'It’s OK to trust any extension hosted on the Google Chrome store, because all potentially malicious extensions have been screened out.',
      choices: ['True', 'False'],
      answer: 1,
      error: 'Incorrect answer'
    }, {
      title: 'What does “Mandatory Reporting” mean?',
      choices: ['If I suspect (or know) that one of our privacy or security policies has been violated, I must report it to the Security Officer', 'Only once I have evidence that a privacy or security policy has been violated must I report it to the Security Officer.', 'All teams must have a clear chain-of-command for reporting business metrics.', 'Our Security Officer must report on the health of our security in Board of Directors meetings.'],
      answer: 0,
      error: 'Incorrect answer'
    }] };
});