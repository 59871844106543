define('diesel/controls/control/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      onIgnore: function onIgnore(resource) {
        resource.set('ignored', true);
        resource.save();
      },

      onRemoveIgnore: function onRemoveIgnore(resource) {
        resource.set('ignored', false);
        resource.save();
      }
    }
  });
});