define('diesel/services/routing', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    currentPath: _ember['default'].computed.reads('applicationController.currentPath'),

    applicationController: null,

    init: function init() {
      this.applicationController = _ember['default'].getOwner(this).lookup('controller:application');
      this.router = _ember['default'].getOwner(this).lookup('router:main');
    },

    transitionTo: function transitionTo() {
      var _router;

      return (_router = this.router).transitionTo.apply(_router, arguments);
    }
  });
});