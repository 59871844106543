define('diesel/models/database', ['exports', 'ember-data', 'diesel/models/base', 'ember', 'diesel/mixins/models/provisionable', 'diesel/mixins/models/statuses', 'diesel/config/environment'], function (exports, _emberData, _dieselModelsBase, _ember, _dieselMixinsModelsProvisionable, _dieselMixinsModelsStatuses, _dieselConfigEnvironment) {
  exports.provisionDatabases = provisionDatabases;

  var OPERATION_RELOAD_TIMEOUT = _dieselConfigEnvironment['default'].timeouts.databaseOperationReload;

  exports['default'] = _dieselModelsBase['default'].extend(_dieselMixinsModelsProvisionable['default'], {
    name: _emberData['default'].attr('string'),
    handle: _emberData['default'].attr('string'),
    connectionUrl: _emberData['default'].attr('string'),
    type: _emberData['default'].attr('string', { defaultValue: 'postgresql' }), // postgresql, redis, etc.
    createdAt: _emberData['default'].attr('iso-8601-timestamp'),
    deletedAt: _emberData['default'].attr('iso-8601-timestamp'),
    initialDiskSize: _emberData['default'].attr('number'),
    initialContainerSize: _emberData['default'].attr('number'),
    portMapping: _emberData['default'].attr(),

    // relationships
    account: _emberData['default'].belongsTo('account', { async: true }),
    operations: _emberData['default'].hasMany('operation', { async: true }),
    disk: _emberData['default'].belongsTo('disk', { async: true }),
    databaseImage: _emberData['default'].belongsTo('database-image', { async: true }),
    initializeFrom: _emberData['default'].belongsTo('database', { async: true, inverse: 'dependents' }),
    dependents: _emberData['default'].hasMany('database', { async: true, inverse: 'initializeFrom' }),
    service: _emberData['default'].belongsTo('service', { async: true }),
    backups: _emberData['default'].hasMany('backups', { async: true }),
    databaseCredentials: _emberData['default'].hasMany('database-credential', {
      async: false
    }),

    supportsReplication: _ember['default'].computed('type', function () {
      var type = this.get('type');
      return type === 'redis' || type === 'postgresql' || type === 'mysql';
    }),

    reloadOn: [_dieselMixinsModelsStatuses['default'].PROVISIONING, _dieselMixinsModelsStatuses['default'].DEPROVISIONING],

    supportsClustering: _ember['default'].computed.equal('type', 'mongodb'),

    serviceUsage: _ember['default'].computed.mapBy('service', 'usage'),
    usage: _ember['default'].computed.sum('serviceUsage'),
    isProvisioningOrPending: _ember['default'].computed.or('isPending', 'isProvisioning'),

    databaseCredentialsWithFallback: _ember['default'].computed('databaseCredentials.[]', 'account.isV1', 'type', 'connectionUrl', function () {
      // v1 stacks don't have database credentials, so we need to provide a
      // record that looks like a credential from the DB.
      if (this.get('account.isV1')) {
        return [_ember['default'].Object.create({
          type: this.get('type'),
          'default': true,
          connectionUrl: this.get('connectionUrl')
        })];
      }

      return this.get('databaseCredentials');
    })
  });

  function provisionDatabases(user, store) {
    if (!user.get('verified')) {
      return _ember['default'].RSVP.resolve();
    }

    return store.findAll('database').then(function (databases) {
      var promises = databases.map(function (database) {
        if (!database.get('isPending')) {
          return _ember['default'].RSVP.resolve();
        }

        var op = store.createRecord('operation', {
          type: 'provision',
          diskSize: database.get('initialDiskSize') || '10',
          containerSize: database.get('initialContainerSize') || 1024,
          database: database
        });
        return op.save().then(function (op) {
          // NOTE: We don't actually return this promise, because the UI should
          // not block on the provisioning.
          op.reloadUntilStatusChanged(OPERATION_RELOAD_TIMEOUT).then(function () {
            return database.reload();
          });
        });
      });

      return _ember['default'].RSVP.all(promises);
    });
  }
});