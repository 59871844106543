define('diesel/gridiron-settings/locations/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    modalDisplay: _ember['default'].inject.service(),

    model: function model() {
      var organizationProfile = this.modelFor('gridiron-settings'); //organization profile
      return organizationProfile.get('locations');
    },

    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('organizationProfile', this.modelFor('gridiron-settings'));
    },

    actions: {
      addLocation: function addLocation(organizationProfile) {
        var modalDisplay = this.get('modalDisplay');
        var location = this.store.createRecord('location', {
          organizationProfile: organizationProfile
        });

        var _get$getProperties = this.get('complianceStatus.authorizationContext').getProperties('roles');

        var roles = _get$getProperties.roles;

        modalDisplay.showModal('modal-location-form', {
          modalLocationForm: { location: location, roles: roles }
        });
      },

      editLocation: function editLocation(location) {
        var modalDisplay = this.get('modalDisplay');

        var _get$getProperties2 = this.get('complianceStatus.authorizationContext').getProperties('roles');

        var roles = _get$getProperties2.roles;

        modalDisplay.showModal('modal-location-form', {
          modalLocationForm: {
            location: location,
            roles: roles
          }
        });
      },

      removeLocation: function removeLocation(location) {
        var _this = this;

        var locationName = location.get('name');
        var message = 'You are about to remove the location \'' + locationName + '\'. Continue?';
        if (!window.confirm(message)) {
          return;
        }
        location.destroyRecord().then(function () {
          _ember['default'].get(_this, 'flashMessages').success(locationName + ' removed!');
        }, function (e) {
          var message = e.message || 'There was an error adding location ' + locationName;
          _ember['default'].get(_this, 'flashMessages').danger(message);
        });
      }
    }
  });
});