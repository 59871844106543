define('diesel/components/c-sso-implementation-detail/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    organization: null,
    requestSubmitted: false,
    isEnforced: _ember['default'].computed.equal('status', 'STATUS_SSO_ENFORCED'),
    isConfigured: _ember['default'].computed.equal('status', 'STATUS_SSO_CONFIGURED'),
    isAvailable: _ember['default'].computed.equal('status', 'STATUS_SSO_AVAILABLE'),
    isUnavailable: _ember['default'].computed.equal('status', 'STATUS_SSO_UNAVAILABLE'),

    status: _ember['default'].computed('organization.ssoEnforced', function () {
      var ssoEnforced = this.get('organization.ssoEnforced');
      var samlConfiguration = this.get('organization.data.links.saml_configuration');

      if (ssoEnforced) {
        return 'STATUS_SSO_ENFORCED';
      }
      if (samlConfiguration) return 'STATUS_SSO_CONFIGURED';
      return 'STATUS_SSO_AVAILABLE';
    })
  });
});