define('diesel/components/show-banner/component', ['exports', 'ember', 'diesel/utils/banners'], function (exports, _ember, _dieselUtilsBanners) {
  exports['default'] = _ember['default'].Component.extend({
    name: '',
    isVisible: true,

    init: function init() {
      this._super.apply(this, arguments);
      var name = this.get('name');
      if (!name) {
        return;
      }

      if (!_dieselUtilsBanners['default'].shouldShow(name)) {
        this.set('isVisible', false);
      }
    },

    actions: {
      dismiss: function dismiss() {
        this.set('isVisible', false);
        this.sendAction();
        _dieselUtilsBanners['default'].dismiss(this.get('name'));
      }
    }
  });
});