define('diesel/models/contract', ['exports', 'ember', 'ember-data', 'diesel/models/base'], function (exports, _ember, _emberData, _dieselModelsBase) {
  var STATUS_CURRENT = 'current';
  var STATUS_PAST = 'past';
  var STATUS_FUTURE = 'future';

  exports['default'] = _dieselModelsBase['default'].extend({
    billingDetail: _emberData['default'].belongsTo('billing-detail', { async: true }),

    startsAt: _emberData['default'].attr('iso-8601-timestamp'),
    endsAt: _emberData['default'].attr('iso-8601-timestamp'),

    contractJson: _emberData['default'].attr(),

    status: _ember['default'].computed('startsAt', 'endsAt', function () {
      var now = new Date();

      var _getProperties = this.getProperties('startsAt', 'endsAt');

      var startsAt = _getProperties.startsAt;
      var endsAt = _getProperties.endsAt;

      if (now < startsAt) {
        return STATUS_FUTURE;
      }

      if (now > endsAt) {
        return STATUS_PAST;
      }

      return STATUS_CURRENT;
    })
  });
});