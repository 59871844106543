define("diesel/components/modal-edit-backup-retention-policy/component", ["exports", "ember", "ember-validations", "diesel/mixins/components/modal-base"], function (exports, _ember, _emberValidations, _dieselMixinsComponentsModalBase) {
  exports["default"] = _ember["default"].Component.extend(_emberValidations["default"], _dieselMixinsComponentsModalBase["default"], {
    backupRetentionPolicy: null,
    oldBackupRetentionPolicy: null,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      _ember["default"].run.later(function () {
        return _this.set('initialized', true);
      });
    },

    title: "Current Policy",

    originalDaily: _ember["default"].computed.alias("backupRetentionPolicy.daily"),
    originalMonthly: _ember["default"].computed.alias("backupRetentionPolicy.monthly"),
    originalMakeCopy: _ember["default"].computed.alias("backupRetentionPolicy.makeCopy"),
    originalKeepFinal: _ember["default"].computed.alias("backupRetentionPolicy.keepFinal"),

    disableSave: _ember["default"].computed.or("isPending", "isSaving", "isNotValid"),
    isPending: _ember["default"].computed.or("backupRetentionPolicy.isSaving", "backupRetentionPolicy.isValidating"),

    isNotValid: _ember["default"].computed('backupRetentionPolicy', 'originalMonthly', function () {
      var originalDaily = this.get("originalDaily");
      var originalMonthly = this.get("originalMonthly");
      var validations = this.get('validations');

      if (originalDaily >= validations.daily.numericality.greaterThan && originalMonthly >= validations.monthly.numericality.greaterThan) {
        return false;
      } else {
        return true;
      }
    }),

    validations: {
      daily: {
        presence: true,
        numericality: {
          onlyInteger: true,
          greaterThan: 1
        }
      },
      monthly: {
        presence: true,
        numericality: {
          onlyInteger: true,
          greaterThan: 0
        }
      }
    },

    actions: {
      onToggle: function onToggle(property) {
        if (!this.get('initialized')) {
          return;
        }
        this.set(property, !this.get(property));
      },
      verify: function verify(formValue, type) {
        var logicValue = false;
        var value = this.get("" + formValue);
        var validations = this.get('validations');
        var validationCheck = validations[type].numericality.greaterThan;

        if (!isNaN(Number(value))) {
          if (Number(value) < validationCheck) {
            logicValue = true;
          }
        } else {
          // if nan not allow button save
          logicValue = true;
        }

        this.set('isNotValid', logicValue);
      },
      createBackupRetentionPolicy: function createBackupRetentionPolicy() {},

      beforeDismiss: function beforeDismiss() {
        var backupRetentionPolicy = this.get("backupRetentionPolicy");
        if (backupRetentionPolicy.id === null) {
          this.get("backupRetentionPolicy").destroyRecord();
        }
      },

      commitChanges: function commitChanges() {
        var _this2 = this;

        var newBackupRetentionPolicy = this.get("backupRetentionPolicy");
        var oldBackupRetentionPolicy = this.get("oldBackupRetentionPolicy");
        return newBackupRetentionPolicy.save()
        // Reload backup retention policies, since the API endpoint for create deletes
        // pre-exisitng policies.
        .then(function () {
          if (oldBackupRetentionPolicy !== null) {
            // This is clean, but a hack.
            // If we got this far, then we know the new retention policy has been saved. The API endpoint for
            // creating a retention policy purges all old retention policies, so we also know that the old policy was
            // deleted. This marks the old policy as deleted from the store so that it no longer appears in the
            // UI, without making a call to the API (which would fail, since the policy no longer exists).
            oldBackupRetentionPolicy.transitionTo('deleted.saved');
          }
        }).then(function () {
          return _this2.sendAction("dismissModal");
        })["catch"](function (e) {
          _this2.set('errorMessage', e.message);
        });
      }
    }
  });
});