define('diesel/components/operable-status/component', ['exports', 'ember', 'diesel/mixins/components/operable'], function (exports, _ember, _dieselMixinsComponentsOperable) {
  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsOperable['default'], {
    statusIconClass: _ember['default'].computed('operable.status', function () {
      var status = this.get('operable.status');

      return ({
        provisioned: 'fa-check-circle success',
        deprovisioned: 'fa-times-circle',
        provision_failed: 'fa-exclamation-triangle danger',
        deprovision_failed: 'fa-exclamation-triangle danger'
      })[status] || 'fa-question-circle';
    }),

    statusLabel: _ember['default'].computed('operable.status', function () {
      var status = this.get('operable.status');

      return ({
        pending: 'Pending',

        provisioning: 'Provisioning',
        provisioned: 'Provisioned',

        deprovisioning: 'Deprovisioning',
        deprovisioned: 'Deprovisioned',

        provision_failed: 'Failed to provision',
        deprovision_failed: 'Failed to deprovision'
      })[status] || status;
    })
  });
});