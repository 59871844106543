define('diesel/components/workforce-designation/component', ['exports', 'ember', 'diesel/models/organization-profile'], function (exports, _ember, _dieselModelsOrganizationProfile) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['workforce-designation'],
    flashMessages: _ember['default'].inject.service(),
    // passed-in values
    organizationProfile: null,
    details: null,
    users: null,
    designation: null,

    description: _ember['default'].computed('organizationProfile.name', 'details.description', function () {
      var name = this.get('organizationProfile.name');
      return this.get('details.description').replace('{{shortName}}', name);
    }),

    actions: {
      updateDesignation: function updateDesignation(designation, selectedEmail) {
        var _this = this;

        var user = this.get('users').findBy('email', selectedEmail);
        var designationConfig = _dieselModelsOrganizationProfile.USER_DESIGNATIONS[designation];
        var organizationProfile = this.get('organizationProfile');

        if (!user) {
          return;
        }

        var success = false;
        var message = user.get('name') + ' set as ' + designationConfig.name;

        organizationProfile.set(designation, user);
        organizationProfile.save().then(function () {
          if (designationConfig.role) {
            // If a role is set for this designation, we should also create a
            // membership for this user... but only if they aren't already a member
            organizationProfile.addDesignationToRole(user, designationConfig.role).then(function (_ref) /*membership*/{
              var user = _ref.user;
              var role = _ref.role;

              // Memberhsip was successfully created, so user was also added to the role
              message = message + ' and added to ' + role.get('name');
              success = true;
            })['catch'](function (e) {
              // This will throw if the membership already exists.

              if (e.errors && !/Role has already been taken/.test(e.errors.message)) {
                // Wait, something else actually failed, re-throw
                throw e;
              }

              success = true;
            })['finally'](function () {
              if (success) {
                _ember['default'].get(_this, 'flashMessages').success(message);
                // The above will do a PUT request to org profiles and return a non-
                // building `documentBuildStatus`.  We need to wait a bit for that
                // request to setttle before updating and polling for status changes
                return organizationProfile.observeDocumentBuilds();
              }
            });
          }
        });
      }
    }
  });
});