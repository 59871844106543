define('diesel/error/controller', ['exports', 'ember'], function (exports, _ember) {

  /**
   * `model` is either an instance of DS.AdapterError from a failed ajax request
   * or an instance of `Error` from a JavaScript exception
   */
  exports['default'] = _ember['default'].Controller.extend({
    title: _ember['default'].computed.reads('model._requestStatusText'),

    errorCode: _ember['default'].computed('model._requestStatus', 'model.name', function () {
      return this.get('model._requestStatus') || this.get('model.name');
    }),

    message: _ember['default'].computed('model.message', function () {
      var errorLabel = this.get('model.message') || 'Our JavaScript raised an exception';
      return errorLabel + '. Maybe the URL you visited is no longer valid?';
    })
  });
});