define('diesel/components/policy-editor/-utils/document-schema', ['exports'], function (exports) {
  var _schema;

  exports.fieldsForType = fieldsForType;
  exports.allowsField = allowsField;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var SECTION = 'section';
  exports.SECTION = SECTION;
  var GROUP = 'group';
  exports.GROUP = GROUP;
  var LIST = 'list';
  exports.LIST = LIST;
  var ROOT = 'root';

  exports.ROOT = ROOT;
  var schema = (_schema = {}, _defineProperty(_schema, SECTION, {
    fields: {
      type: true,
      title: true,
      ccm_ids: true,
      show_expression: true,
      template: true,
      children: true
    }
  }), _defineProperty(_schema, GROUP, {
    fields: {
      type: true,
      title: true,
      children: true
    }
  }), _defineProperty(_schema, LIST, {
    fields: {
      type: true,
      title: true,
      ccm_ids: true,
      template: true,
      show_expression: true,
      children: true
    }
  }), _defineProperty(_schema, ROOT, {
    fields: {
      version: true,
      children: true
    }
  }), _schema);

  function fieldsForType(type) {
    return schema[type] && schema[type].fields;
  }

  function allowsField(type, field) {
    var fields = fieldsForType(type);

    if (!fields) {
      return true;
    }

    return fields[field];
  }
});