define('diesel/serializers/policy-manual-approval', ['exports', 'diesel/serializers/application'], function (exports, _dieselSerializersApplication) {

  var generatedId = 0;

  exports['default'] = _dieselSerializersApplication['default'].extend({
    normalize: function normalize() /* modelClass, resourceHash */{
      /**
       * TODO-ember-data The server returns an invalid HAL response here.
       * See the risk-assessment-approval serializer. The same situation applies here.
       *
       * The solution for now is to generate an id in this normalize method.
       * We (for now) discard any other information in the API payload, as it
       * was formerly being inadvertently discarded anyway.
       */
      return {
        id: 'generated-id-' + ++generatedId,
        type: 'policy-manual-approval'
      };
    }
  });
});