define('diesel/assets/type/controller', ['exports', 'ember', 'diesel/models/component', 'diesel/models/system', 'diesel/assets/type/route'], function (exports, _ember, _dieselModelsComponent, _dieselModelsSystem, _dieselAssetsTypeRoute) {
  exports['default'] = _ember['default'].Controller.extend({
    persistedComponents: _ember['default'].computed.filterBy('components', 'isNew', false),
    persistedSystems: _ember['default'].computed.filterBy('systems', 'isNew', false),

    emptyResultMessage: _ember['default'].computed('typeFilter', 'appTypes.[]', 'systemTypes.[]', 'networks.[]', 'databases.[]', function () {
      if (this.get('appTypes.length') || this.get('systemTypes.length') || this.get('networks.length') || this.get('databases.length')) {
        // Something showed up, return nothing
        return null;
      }

      var currentFilter = this.get('typeFilter');
      var type = currentFilter === 'all' ? 'assets' : _dieselAssetsTypeRoute.FILTER_TYPES[currentFilter];
      return 'You haven\'t added any ' + type + ' to Gridiron yet.';
    }),

    appTypes: _ember['default'].computed('persistedComponents.@each.type', 'typeFilter', function () {
      var components = this.get('persistedComponents');
      var typeFilter = this.get('typeFilter');

      if (['apps', 'all'].includes(typeFilter)) {
        return _dieselModelsComponent.COMPONENT_TYPES.filterBy('app').filter(function (appType) {
          return components.filterBy('type', appType.handle).length;
        }).map(function (appType) {
          var filteredComponents = components.filterBy('type', appType.handle);
          return { name: appType.name, components: filteredComponents };
        });
      }

      return _ember['default'].A();
    }),

    systemTypes: _ember['default'].computed('persistedSystems.@each.type', 'typeFilter', function () {
      var systems = this.get('persistedSystems');
      var systemTypeHandles = _dieselModelsSystem.SYSTEM_TYPES.mapBy('handle');
      var typeFilter = this.get('typeFilter');

      if (typeFilter === 'all') {
        return _dieselModelsSystem.SYSTEM_TYPES.filter(function (systemTypeDef) {
          return systems.filterBy('type', systemTypeDef.handle).length;
        }).map(function (systemTypeDef) {
          var name = systemTypeDef.name;
          var filteredSystems = systems.filterBy('type', systemTypeDef.handle);
          return { name: name, systems: filteredSystems };
        });
      } else if (systemTypeHandles.includes(typeFilter)) {
        // Specified typefilter
        var _name = _dieselModelsSystem.SYSTEM_TYPES.findBy('handle', typeFilter).name;
        var filteredSystems = systems.filterBy('type', typeFilter);
        return [{ name: _name, systems: filteredSystems }];
      }

      return _ember['default'].A();
    }),

    networks: _ember['default'].computed('persistedComponents.@each.type', 'typeFilter', function () {
      var networks = this.get('persistedComponents').filterBy('isNetwork');
      var typeFilter = this.get('typeFilter');

      if (['all', 'networks'].includes(typeFilter)) {
        return networks;
      }

      return _ember['default'].A();
    }),

    databases: _ember['default'].computed('persistedComponents.@each.type', 'typeFilter', function () {
      var databases = this.get('persistedComponents').filterBy('isDatabase');
      var typeFilter = this.get('typeFilter');

      if (['all', 'databases'].includes(typeFilter)) {
        return databases;
      }

      return _ember['default'].A();
    })
  });
});