define('diesel/components/database-version-selector/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    selectedDatabaseImage: _ember['default'].computed('visibleDatabaseImages', function () {
      var dbImage = this.get('visibleDatabaseImages').find(function (image) {
        return image.get('default');
      });
      this.get('dbImage');
      this.set('dbImage', dbImage);
      return dbImage;
    }),

    visibleDatabaseImages: _ember['default'].computed('type', 'databaseImages.[]', function () {
      var type = this.get('type');
      if (!type) {
        return [];
      }
      var databaseImages = this.get('databaseImages') || [];
      return databaseImages.filter(function (image) {
        return image.get('visible') === true && image.get('type') === type;
      });
    }),

    selectImage: function selectImage(dbImage) {
      this.set('dbImage', dbImage);
      this.sendAction('imageSelected', this.get('dbImage'));
    }
  });
});