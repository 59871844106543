define("diesel/components/deploy-instructions/component", ["exports", "ember", "diesel/config/environment"], function (exports, _ember, _dieselConfigEnvironment) {
  exports["default"] = _ember["default"].Component.extend({
    externalUrls: _dieselConfigEnvironment["default"].externalUrls,
    // Defaulting to first option
    selectedApp: "Express",
    featureFlags: _dieselConfigEnvironment["default"].featureFlags,
    appStatusUrl: _ember["default"].computed("app.id", function () {
      var appUrl = _dieselConfigEnvironment["default"].aptibleHosts.appUrl;
      return appUrl + "/apps/" + this.get("app.id") + "/git/setup";
    }),
    showFtuxFlow: _ember["default"].computed("account.onboardingStatus", function () {
      var status = this.get("account.onboardingStatus");
      return _dieselConfigEnvironment["default"].featureFlags.showFTUX && status !== "unknown";
    }),

    actions: {
      deployDone: function deployDone(app) {
        this.sendAction("deployDone", app);
      },
      selectApp: function selectApp(app) {
        _ember["default"].set(this, "selectedApp", app);
      },
      open: function open() {
        this.sendAction("openCreateDbModal");
      }
    }
  });
});