define('diesel/components/modal-risk-component-editor/component', ['exports', 'ember', 'diesel/mixins/components/resizable-modal', 'diesel/mixins/components/modal-base'], function (exports, _ember, _dieselMixinsComponentsResizableModal, _dieselMixinsComponentsModalBase) {
  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsResizableModal['default'], _dieselMixinsComponentsModalBase['default'], {
    modalRiskComponentEditorProperties: null,

    store: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    isSaving: false,
    component: _ember['default'].computed.alias('modalRiskComponentEditorProperties.component'),
    type: _ember['default'].computed.alias('modalRiskComponentEditorProperties.type'),
    resource: _ember['default'].computed.alias('modalRiskComponentEditorProperties.resource'),
    resourceType: _ember['default'].computed.alias('modalRiskComponentEditorProperties.resourceType'),
    title: _ember['default'].computed.reads('component.title'),
    description: _ember['default'].computed.reads('component.description'),

    _buildSchemaDocument: (function () {
      var component = this.get('component');
      var schemaDocument = component.initializeSchemaDocument();
      var schema = schemaDocument._schema;
      var schemaProperties = schema.properties[component.get('SCHEMA_KEY')].properties;
      var keyPrefix = component.get('SCHEMA_KEY') + '.';

      this.setProperties({ schema: schema, schemaDocument: schemaDocument, schemaProperties: schemaProperties, keyPrefix: keyPrefix });
    }).on('init'),

    actions: {
      saveChanges: function saveChanges() {
        var _this = this;

        this.set('errors', null);

        var _getProperties = this.getProperties('component', 'schemaDocument', 'store', 'resource', 'resourceType');

        var component = _getProperties.component;
        var schemaDocument = _getProperties.schemaDocument;
        var store = _getProperties.store;
        var resource = _getProperties.resource;
        var resourceType = _getProperties.resourceType;

        var user = this.get('session.currentUser');
        var organizationProfile = this.get('complianceStatus.organizationProfile');
        var documentParams = { store: store, user: user, organizationProfile: organizationProfile, resource: resource, resourceType: resourceType };

        this.set('isSaving', true);

        component.saveSchemaDocument(schemaDocument, documentParams).then(function () {
          _this.set('isSaving', false);
          _this.sendAction('dismissModal');
        }, function (error) {
          var message = error.message || 'Something went wrong!';
          _this.set('errors', message);
          _this.set('isSaving', false);
        });
      }
    }
  });
});