define('diesel/components/app-profile-item/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['panel', 'component-profile', 'component-profile--app'],
    disableRemoveTooltip: _ember['default'].computed('component.dependents.[]', 'component.handle', function () {
      var handle = this.get('component.handle');
      var deps = this.get('component.dependents').mapBy('handle');
      var depsString = deps.join(', ');
      var s = deps.length === 1 ? '' : 's';

      return 'Removing \'' + handle + '\' is disabled because it has ' + deps.length + ' dependent component' + s + '. (' + depsString + ')';
    })
  });
});