define('diesel/training/status/controller', ['exports', 'ember', 'diesel/models/assignment-state', 'diesel/helpers/plural-string'], function (exports, _ember, _dieselModelsAssignmentState, _dieselHelpersPluralString) {
  exports['default'] = _ember['default'].Controller.extend({
    trainingSeatText: _ember['default'].computed('complianceStatus.organization.name', 'model.report.users', function () {
      var orgName = this.get('complianceStatus.organization.name');
      var count = this.get('model.report.users.length');

      return orgName + ' is currently using ' + count + ' training ' + (0, _dieselHelpersPluralString.pluralString)('seat', count);
    }),

    updateUserCourseStatus: function updateUserCourseStatus(userUrl, courseId, props) {
      // This isn't great, but since the report isn't bound to anything, we have
      // to inject the updated user-course-assignment status back into the
      // report.  This is called whenever an assignment status is changed
      // (assign or unassign)
      var updatedUsers = this.get('model.report.users').map(function (user) {
        if (user.userUrl === userUrl) {
          var newAssignments = user.courseAssignments.map(function (ca) {
            if (ca.courseId === courseId) {
              _ember['default'].setProperties(ca, props);
            }
            return ca;
          });
          _ember['default'].set(user, 'courseAssignments', newAssignments);
        }
        return user;
      });

      this.set('model.report.users', updatedUsers);
    },

    updateReportForUser: function updateReportForUser(userUrl, course) {
      var _this = this;

      return function (assignment) {
        var props = { status: assignment.get('status'),
          statusMessage: assignment.get('statusMessage'),
          assignmentId: assignment.get('id') };
        _this.updateUserCourseStatus(userUrl, course.id, props);
      };
    },

    actions: {
      onAssign: function onAssign(userTrainingStatus, courseAssignment) {
        var _this2 = this;

        // We do a bit of a dance here to serialize the report payload into Ember
        // Data models so that we can save the new assignment
        var organizationProfile = this.get('complianceStatus.organizationProfile');
        var userUrl = userTrainingStatus.userUrl;

        this.store.find('course', courseAssignment.courseId).then(function (course) {
          if (courseAssignment.assignmentId) {
            // Assignment exists, it is just in unassigned state.  Set state to assigned
            // 1. Find assignment
            // 2. Create new assignment state
            // 3. Reload assignment (to get new status/status message)
            // 4. Update current report
            _this2.store.find('assignment', courseAssignment.assignmentId).then(function (assignment) {
              return _this2.store.createRecord('assignment-state', {
                assignment: assignment, state: _dieselModelsAssignmentState.ASSIGNMENT_STATES.ASSIGNED
              }).save().then(function () {
                assignment.reload().then(_this2.updateReportForUser(userUrl, course));
              });
            });
          } else {
            // Assignment never existed, just need to create it
            // Gridiron will automatically create the initial assignment state
            _this2.store.createRecord('assignment', {
              course: course, userUrl: userUrl, organizationProfile: organizationProfile
            }).save().then(_this2.updateReportForUser(userUrl, course));
          }
        });
      },

      onUnassign: function onUnassign(userTrainingStatus, courseAssignment) {
        var _this3 = this;

        var confirmation = 'Are you sure you want to un-assign ' + courseAssignment.name + '?';

        if (!courseAssignment.assignmentId) {
          return;
        }
        if (!window.confirm(confirmation)) {
          return;
        }

        this.store.find('course', courseAssignment.courseId).then(function (course) {
          _this3.store.find('assignment', courseAssignment.assignmentId).then(function (assignment) {
            var userUrl = userTrainingStatus.userUrl;
            var newState = _this3.store.createRecord('assignment-state', { assignment: assignment, state: _dieselModelsAssignmentState.ASSIGNMENT_STATES.UNASSIGNED });
            newState.save().then(function () {
              return assignment.reload();
            }) // Pushing an assignment state will change the status and status message. Reload to get them.
            .then(_this3.updateReportForUser(userUrl, course));
          });
        });
      }
    }
  });
});