define('diesel/app/services/service/metrics/route', ['exports', 'ember', 'diesel/mixins/routes/service-metrics'], function (exports, _ember, _dieselMixinsRoutesServiceMetrics) {
  exports['default'] = _ember['default'].Route.extend(_dieselMixinsRoutesServiceMetrics['default'], {
    getResource: function getResource(params) {
      return this.modelFor('app');
    },

    getService: function getService(params) {
      return this.modelFor('app.services.service');
    }
  });
});