define('diesel/welcome/plan/route', ['exports', 'ember', 'diesel/utils/plan'], function (exports, _ember, _dieselUtilsPlan) {
  exports['default'] = _ember['default'].Route.extend({
    analytics: _ember['default'].inject.service(),

    model: function model() {
      var org = this.get('authorization').currentOrganization;
      var plans = this.get('store').findAll('plan');

      // below is a list of one item, confusing, but that's how it is
      var activePlanList = this.get('store').query('activePlan', {
        organization_id: org.id
      });

      return _ember['default'].RSVP.hash({
        org: org,
        planList: plans,
        activePlanList: activePlanList
      }).then(function (data) {
        return {
          activePlanList: activePlanList,
          organization: org,
          plans: (0, _dieselUtilsPlan.transformPlansToMap)(data.planList, ['starter', 'growth', 'scale'])
        };
      });
    },

    redirect: function redirect(model) {
      var activePlan = model.activePlanList.get('firstObject');
      if (activePlan) {
        this.transitionTo('welcome.payment-info');
      }
    }
  });
});