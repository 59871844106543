define('diesel/components/threat-event-list/component', ['exports', 'ember', 'diesel/utils/filter-risk-components', 'diesel/mixins/components/column-config'], function (exports, _ember, _dieselUtilsFilterRiskComponents, _dieselMixinsComponentsColumnConfig) {

  var COLUMN_PRESETS = {
    'title': 'Threat Event',
    'description': 'Description',
    'riskLevel': 'Risk Level',
    'relevance': 'Relevance',
    'impact': 'Impact',
    'overallLikelihood': 'Likelihood'
  };

  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsColumnConfig['default'], {
    filters: { minimumRiskLevel: null, sort: 'riskLevel' },
    tagName: 'table',
    classNames: ['base-table'],

    columns: ['title', 'description', 'overallLikelihood', 'impact', 'riskLevel'],
    columnPresets: COLUMN_PRESETS,

    filteredThreatEvents: _ember['default'].computed('filters.minimumRiskLevel', 'filters.relevance', 'filters.sort', 'filters.impact', 'filters.search', 'threatEvents.[]', function () {
      var threatEvents = this.get('threatEvents');
      var filters = this.get('filters');
      filters.relevant = true;
      return (0, _dieselUtilsFilterRiskComponents['default'])(threatEvents, filters);
    })
  });
});