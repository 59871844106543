define('diesel/incident/route', ['exports', 'ember', 'diesel/utils/ignore-invalid-error'], function (exports, _ember, _dieselUtilsIgnoreInvalidError) {
  exports['default'] = _ember['default'].Route.extend({
    modalDisplay: _ember['default'].inject.service(),

    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);

      this.render('sidebars/engine-sidebar', {
        into: 'incident',
        outlet: 'sidebar',
        controller: this.controllerFor('gridiron-organization')
      });
    },

    setupController: function setupController(controller, model) {
      controller.set('incident', model);
    },

    actions: {
      openEditIncidentModal: function openEditIncidentModal() {
        var modalDisplay = this.get('modalDisplay');
        modalDisplay.showModal('modal-edit-incident', { editIncident: this.currentModel }, {
          actions: {
            onEditIncident: modalDisplay.modalAction(this, 'onEditIncident')
          }
        });
      },

      onEditIncident: function onEditIncident(incident) {
        var _this = this;

        return incident.save().then(function () {
          var message = 'Incident response updated.';
          _ember['default'].get(_this, 'flashMessages').success(message);
          _this.currentModel.get('activities').reload();
        })['catch'](_dieselUtilsIgnoreInvalidError['default']);
      },

      openCloseIncidentModal: function openCloseIncidentModal() {
        var modalDisplay = this.get('modalDisplay');
        modalDisplay.showModal('modal-close-incident', { closeIncident: this.currentModel }, {
          actions: {
            onCloseIncident: modalDisplay.modalAction(this, 'onCloseIncident')
          }
        });
      },

      onCloseIncident: function onCloseIncident(incident) {
        var _this2 = this;

        incident.set('closedAt', new Date());
        return incident.save().then(function () {
          _this2.transitionTo('incidents');
          var message = 'Incident has been closed.';
          _ember['default'].get(_this2, 'flashMessages').success(message);
        })['catch'](function () {
          incident.rollbackAttributes();
        });
      },

      openIncident: function openIncident() {
        var _this3 = this;

        var incident = this.currentModel;
        incident.set('closedAt', null);
        incident.save().then(function () {
          var message = 'Incident response updated.';
          _ember['default'].get(_this3, 'flashMessages').success(message);
        })['catch'](function () {
          incident.rollbackAttributes();
        });
      }
    }
  });
});