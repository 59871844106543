define('diesel/policy-manuals/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    title: function title() {
      var organization = this.get('complianceStatus.organization');
      return organization.get('name') + ' Policy Manuals';
    },

    model: function model() {
      var organization = this.get('complianceStatus.organization');
      var organizationProfile = this.modelFor('gridiron-admin');
      var policyManuals = organizationProfile.get('policyManuals');

      var v1ArchivedPolicyManuals = this.store.findAll('criterion').then(function (criteria) {
        var policyCriterion = criteria.findBy('handle', 'policy_manual');
        var documentQuery = { organization: organization.get('links.self') };
        return policyCriterion.get('documents', documentQuery);
      });

      return _ember['default'].RSVP.hash({
        organization: organization, organizationProfile: organizationProfile, policyManuals: policyManuals, v1ArchivedPolicyManuals: v1ArchivedPolicyManuals
      });
    },

    afterModel: function afterModel(model) {
      model.policyManuals.forEach(function (pm) {
        return pm.observePDFBuilds();
      });
    },

    setupController: function setupController(controller, model) {
      var approvalId = this.get('complianceStatus.currentApprovalId');
      var approvalPath = approvalId ? 'program/' + approvalId + '/browse' : null;
      var showComplyGRCManual = this.get('authorization.currentContext.enabledFeatures.complyGrcProgram');
      var showComplianceOSManual = !showComplyGRCManual && approvalPath;
      var showClassicManual = !showComplyGRCManual && !showComplianceOSManual;

      controller.setProperties(Object.assign({}, model, {
        approvalId: approvalId, approvalPath: approvalPath, showComplyGRCManual: showComplyGRCManual, showComplianceOSManual: showComplianceOSManual, showClassicManual: showClassicManual
      }));
    },

    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);
      this.render('sidebars/engine-sidebar', {
        into: 'policy-manuals',
        outlet: 'sidebar',
        controller: this.controllerFor('gridiron-organization')
      });
    }
  });
});