define('diesel/components/configure-vhost-container-port/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    // Inputs
    vhost: null,
    image: null,
    account: null,

    // Internal state
    useCustomPort: false,
    customPortIsNotUsed: _ember['default'].computed.not('useCustomPort'),

    stackDoesNotSupportConfiguration: _ember['default'].computed.not('account.isV2'),

    componentIsValid: _ember['default'].computed.or('stackDoesNotSupportConfiguration', 'customPortIsNotUsed', 'portIsValid'),

    pushedInitialValidState: false,

    errorClass: _ember['default'].computed('componentIsValid', function () {
      return this.get('componentIsValid') ? '' : 'has-error';
    }),

    portIsExposed: _ember['default'].computed('image.exposedPorts', 'vhost.containerPort', function () {
      return (this.get('image.exposedPorts') || []).includes(this.get('vhost.containerPort'));
    }),

    // TODO-ember-data This observer triggers the deprecation:
    // "A property.*was modified inside the didInsertElement hook"
    pushValidState: (function () {
      var _this = this;

      // TODO-double-modify
      _ember['default'].run.schedule('afterRender', function () {
        _this.sendAction('update', _this.get('componentIsValid'));
      });
    }).observes('componentIsValid'),

    didReceiveAttrs: function didReceiveAttrs() {
      var _this2 = this;

      this._super.apply(this, arguments);

      ['vhost', 'image', 'account'].forEach(function (k) {
        _ember['default'].assert(k + ' is required', !!_this2.get(k));
      });

      var containerPort = this.get('vhost.containerPort');

      this.set('initialContainerPort', containerPort);
      this.set('useCustomPort', !!containerPort);
    },

    didInsertElement: function didInsertElement() {
      this.pushValidState();
    },

    actions: {
      setUseCustomPort: function setUseCustomPort(useCustomPort) {
        this.set('useCustomPort', useCustomPort);

        if (!useCustomPort) {
          this.set('vhost.containerPort', null);
        }
      },

      setContainerPort: function setContainerPort(port) {
        var _this3 = this;

        var intVal = parseInt(port, 10);

        var portIsValid = !isNaN(intVal) && intVal.toString() === port.toString() && intVal > 0 && intVal < 65536;

        // TODO-double-modify
        _ember['default'].run.schedule('afterRender', function () {
          _this3.set('portIsValid', portIsValid);
          _this3.set('vhost.containerPort', portIsValid ? intVal : null);
        });
      }
    }
  });
});