define('diesel/controls/control/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    title: function title() {
      return "Control";
    },

    model: function model(_ref) {
      var id = _ref.id;
      var aggregatedControls = this.modelFor('controls').mainControlAggregator.aggregatedControls;

      return aggregatedControls.findBy('id', id);
    },

    setupController: function setupController(controller, model) {
      this.controllerFor('controls').set('childControl', model);
      controller.setProperties({ model: model });
    },

    actions: {
      didTransition: function didTransition() {
        var analytics = this.get('analytics');
        var control = this.modelFor('controls.control').get('globalControl.name');
        var EVENT_NAME = 'control.selected';
        analytics.track(EVENT_NAME, { control: control });
        analytics.aptibleEvent(EVENT_NAME, control);
      },
      deactivate: function deactivate() {
        // We let the parent route know that our control was unselected
        // This allows the control listing to expand to the full width
        // of the window
        this.controllerFor('controls').set('childControl', null);
      }
    }
  });
});