define('diesel/components/agreement-list/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    agreementOwnerLabel: 'customer',

    // If popover is clicked, the row click disconnects shutting down the popover
    willDestroyElement: function willDestroyElement() {
      $('.popover').popover('hide');
    },

    actions: {
      openAgreementModal: function openAgreementModal(agreement) {
        this.sendAction('openAgreementModal', agreement);
      },

      removeAgreement: function removeAgreement(agreement) {
        this.sendAction('removeAgreement', agreement);
      }
    }
  });
});