define('diesel/organization/admin/billing/billing-iteration/payments/route', ['exports', 'ember'], function (exports, _ember) {

  var BillingRoute = 'organization.admin.billing';
  var InvoiceRoute = BillingRoute + '.billing-iteration';

  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var billingDetail = this.modelFor(BillingRoute);
      var paymentMethods = billingDetail.get('paymentMethods');
      var invoice = this.modelFor(InvoiceRoute);
      var payments = invoice.get('payments');
      return _ember['default'].RSVP.hash({ payments: payments, paymentMethods: paymentMethods, invoice: invoice });
    },

    setupController: function setupController(controller, model) {
      var payments = model.payments;
      var paymentMethods = model.paymentMethods;
      var invoice = model.invoice;

      controller.set('model', payments);
      controller.set('paymentMethods', paymentMethods);
      controller.set('invoice', invoice);
    }
  });
});