define('diesel/gridiron/index/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    title: function title() {
      var organization = this.get('complianceStatus.organization.name');
      return 'Gridiron Admin - ' + organization;
    },
    redirect: function redirect() {
      var authorization = this.get('authorization');
      var context = authorization.get('contextsWithGridironProduct.firstObject');
      var organization = context.get('organization');

      if (context.get('userIsGridironOrOrganizationAdmin')) {
        this.transitionTo('gridiron-admin', organization.get('id'));
      } else {
        this.transitionTo('gridiron-user', organization.get('id'));
      }
    }
  });
});