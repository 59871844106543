define('diesel/mixins/components/bootstrap-component-options', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    title: null,
    content: null,
    placement: 'bottom',
    'bs-container': 'body',

    // Other possible options:
    //  * bs-trigger
    //  * bs-container
    //  * bs-html

    getBootstrapOptions: function getBootstrapOptions() {
      var _this = this;

      // These will return functions, which is fine, because bootstrap
      // explicitly accepts callables for those attributes.
      var options = {
        title: function title() {
          return _this.get('title');
        },
        content: function content() {
          return _this.get('content');
        },
        placement: function placement() {
          return _this.get('placement');
        }
      };

      // NOTE: Bootstrap does not accept a callable for html, so we need to make
      // sure this isn't accidentally set to a function, which might
      // (unintentionally) evaluate to true!
      options.html = this.get('bs-html') === true;

      if (this.get('bs-container')) {
        options.container = this.get('bs-container');
      }

      if (this.get('bs-trigger')) {
        options.trigger = this.get('bs-trigger');
      }

      return options;
    }
  });
});