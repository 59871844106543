define('diesel/account/deprovision/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    authorization: _ember['default'].inject.service(),

    title: function title() {
      var account = this.modelFor('account');
      return 'Deprovision ' + account.get('handle') + ' environment';
    },

    actions: {
      deprovision: function deprovision() {
        var _this = this;

        var account = this.currentModel;
        this.controller.set('error', null);

        account.destroyRecord().then(function () {
          var message = account.get('handle') + ' is now deprovisioned';
          _this.get('flashMessages').success(message);
          return _this.get('authorization').load();
        }).then(function () {
          return _this.transitionTo('re-enter');
        }, function (e) {
          _this.controller.set('error', e);
        });
      }
    }
  });
});