define('diesel/gridiron-settings/team/designations/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    modalControllerName: 'gridiron-settings',
    model: function model() {
      var complianceStatus = this.get('complianceStatus');

      var _complianceStatus$getProperties = complianceStatus.getProperties('organization', 'invitations', 'users');

      var organization = _complianceStatus$getProperties.organization;
      var invitations = _complianceStatus$getProperties.invitations;
      var users = _complianceStatus$getProperties.users;

      return _ember['default'].RSVP.hash({ organization: organization, invitations: invitations, users: users });
    },

    setupController: function setupController(controller, model) {
      var organizationProfile = this.get('complianceStatus.organizationProfile');
      var workforceDesignations = organizationProfile.get('workforceDesignations');

      controller.setProperties({
        model: model, organizationProfile: organizationProfile, workforceDesignations: workforceDesignations
      });
    }
  });
});