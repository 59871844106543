define('diesel/transforms/system-config', ['exports', 'ember-data', 'diesel/models/system-config'], function (exports, _emberData, _dieselModelsSystemConfig) {
  exports['default'] = _emberData['default'].Transform.extend({
    deserialize: function deserialize() {
      var serialized = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      return _dieselModelsSystemConfig['default'].fromJSON(serialized);
    },

    serialize: function serialize(systemConfig) {
      if (systemConfig) {
        return systemConfig.toJSON();
      } else {
        return {};
      }
    }
  });
});