define('diesel/settings/profile/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.session.get('currentUser');
    },

    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('userName', model.get('name'));
    },

    actions: {
      submit: function submit(newName) {
        var _this = this;

        var user = this.currentModel;

        user.set('name', newName);
        user.save().then(function () {
          var message = 'Profile updated';
          _ember['default'].get(_this, 'flashMessages').success(message);
        });
      }
    }
  });
});