define('diesel/models/predisposing-condition', ['exports', 'ember', 'ember-data', 'diesel/models/base', 'diesel/mixins/models/schema-document'], function (exports, _ember, _emberData, _dieselModelsBase, _dieselMixinsModelsSchemaDocument) {
  exports['default'] = _dieselModelsBase['default'].extend(_dieselMixinsModelsSchemaDocument['default'], {
    SCHEMA_KEY: 'predisposing_conditions',
    riskAssessment: _emberData['default'].belongsTo('riskAssessment'),
    handle: _emberData['default'].attr('string'),
    title: _emberData['default'].attr('string'),
    description: _emberData['default'].attr('string'),
    pervasiveness: _emberData['default'].attr('number'),
    relevant: _emberData['default'].attr('boolean'),
    threatEvents: _emberData['default'].hasMany('threat-event', {
      async: false
    }),
    isOn: _ember['default'].computed.equal('relevant', true),

    threatEventHandlesAsString: _ember['default'].computed('threatEvents.[]', function () {
      return this.get('threatEvents').mapBy('title').join(', ');
    }),

    relevantIndex: _ember['default'].computed('relevant', function () {
      //for sorting easil
      return this.get('relevant') ? 0 : 1;
    })
  });
});