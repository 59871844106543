define('diesel/components/c-control-auditor-description/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    analytics: _ember['default'].inject.service(),
    tagName: '',
    controlName: null,
    actions: {
      onCopy: function onCopy() {
        var analytics = this.get('analytics');
        var control = this.get('controlName');
        var EVENT_NAME = 'control.description.copied';
        analytics.track(EVENT_NAME, { control: control });
        analytics.aptibleEvent(EVENT_NAME, control);
      }
    }
  });
});