define('diesel/components/database-vhost/component', ['exports', 'ember', 'diesel/mixins/models/statuses'], function (exports, _ember, _dieselMixinsModelsStatuses) {

  var displayStates = [];
  displayStates[_dieselMixinsModelsStatuses['default'].PENDING] = 'pending';
  displayStates[_dieselMixinsModelsStatuses['default'].PROVISIONING] = 'provisioning';
  displayStates[_dieselMixinsModelsStatuses['default'].DEPROVISIONING] = 'deprovisioning';

  exports['default'] = _ember['default'].Component.extend({
    // Required parameters
    vhost: null,

    isNotProvisioned: _ember['default'].computed.not('vhost.isProvisioned'),

    displayState: _ember['default'].computed('vhost.status', function () {
      return displayStates[this.get('vhost.status')];
    }),

    displayIpWhitelist: _ember['default'].computed('vhost.ipWhitelist', function () {
      return (this.get('vhost.ipWhitelist') || []).join(', ');
    }),

    actions: {
      edit: function edit(vhost) {
        this.sendAction('onEditVhost', vhost);
      },

      'delete': function _delete(vhost) {
        this.sendAction('onDeleteVhost', vhost);
      },

      revealCredentials: function revealCredentials(vhost) {
        this.sendAction('onRevealVhostCredentials', vhost);
      }
    }
  });
});