define('diesel/settings/requires-elevation/ssh/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var user = this.session.get('currentUser');

      return user.get('sshKeys');
    },

    setupController: function setupController(controller, model) {
      controller.set('model', model);
    },

    actions: {
      addKey: function addKey() {
        this.controller.set('newKey', this.store.createRecord('ssh-key', {
          user: this.session.get('currentUser')
        }));
      },

      cancelSaveKey: function cancelSaveKey() {
        this.controller.get('newKey').deleteRecord();
        this.controller.set('newKey', null);
      },

      saveKey: function saveKey() {
        var _this = this;

        var controller = this.controller;

        if (controller.get('newKey.isSaving')) {
          return;
        }

        var key = this.controller.get('newKey');

        key.save().then(function () {
          controller.set('newKey', null); // clear key
        })['catch'](function (e) {
          _ember['default'].get(_this, 'flashMessages').danger(e.message);
        });
      },

      deleteKey: function deleteKey(key) {
        var _this2 = this;

        key.destroyRecord().then(function () {
          var message = key.get('name') + ' deleted';
          _ember['default'].get(_this2, 'flashMessages').success(message);
        })['catch'](function () {
          var message = 'There was an error deleting this key.';
          _ember['default'].get(_this2, 'flashMessages').danger(message);
          key.rollbackAttributes();
        });
      }
    }
  });
});