define('diesel/components/asset-type-filters/filter-results/component', ['exports', 'ember'], function (exports, _ember) {

  function filterBySearch(search, keys, components) {
    if (!search) {
      return components;
    }

    return components.filter(function (component) {
      return keys.any(function (key) {
        var value = _ember['default'].get(component, key);
        return value && value.toLowerCase().includes(search);
      });
    });
  }

  function filterByClassification(classification, components) {
    if (!classification || classification === 'any') {
      return components;
    }

    return components.filterBy('classification', classification);
  }

  var DEFAULT_KEYS = 'handle,description';

  exports['default'] = _ember['default'].Component.extend({
    keys: DEFAULT_KEYS,
    filteredComponents: _ember['default'].computed('filters.search', 'filters.classification', 'components.@each', 'keys', function () {
      var search = this.get('filters.search').toLowerCase();
      var keys = this.get('keys').split(',');
      var components = this.get('components');
      var classification = this.get('filters.classification');

      var results = filterBySearch(search, keys, components);

      return filterByClassification(classification, results);
    })
  });
});