define('diesel/mixins/routes/paginated', ['exports', 'ember'], function (exports, _ember) {

  var setPaginationMeta = function setPaginationMeta(resource, controller) {
    var pagination = resource.get('meta');
    controller.set('currentPage', pagination.current_page);
    controller.set('totalCount', pagination.total_count);
    controller.set('perPage', pagination.per_page);
  };

  exports['default'] = _ember['default'].Mixin.create({
    // Methods to override
    getPaginatedResourceType: function getPaginatedResourceType() {
      _ember['default'].assert('Must override getPaginatedResourceType');
    },

    composeQuery: function composeQuery() {
      _ember['default'].assert('Must override composeQuery');
    },

    // Ember hooks
    model: function model(params) {
      return this.fetchResources(params.currentPage);
    },

    setupController: function setupController(controller, model) {
      controller.set('model', model);
      setPaginationMeta(model, controller);
    },

    actions: {
      goToPage: function goToPage(page) {
        var _this = this;

        return this.fetchResources(page).then(function (resources) {
          _this.controller.set('model', resources);
          setPaginationMeta(resources, _this.controller);
        });
      },

      nextPage: function nextPage(page) {
        this.send('goToPage', page + 1);
      },

      prevPage: function prevPage(page) {
        this.send('goToPage', page - 1);
      }
    },

    // Helpers
    fetchResources: function fetchResources(page) {
      return this.store.query(this.getPaginatedResourceType(), this.composeQuery(page));
    }
  });
});