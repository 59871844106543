define('diesel/helpers/security-officer', ['exports', 'ember'], function (exports, _ember) {
  exports.formatSecurityOfficer = formatSecurityOfficer;

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  function formatSecurityOfficer(organizationProfile) {
    var title = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

    var securityOfficerName = organizationProfile.get('securityOfficerName');
    var shortName = organizationProfile.get('shortName');

    if (securityOfficerName) {
      return securityOfficerName + ' (the ' + shortName + ' Security Officer)';
    }

    return (title ? 'T' : 't') + 'he ' + shortName + ' Security Officer';
  }

  exports['default'] = _ember['default'].Helper.helper(function (params) {
    return formatSecurityOfficer.apply(undefined, _toConsumableArray(params));
  });
});