define('diesel/components/cname-state/component', ['exports', 'ember'], function (exports, _ember) {

  var STATE_NODATA = -1;
  var STATE_PENDING = 0;
  var STATE_OK = 1;
  var STATE_FAIL = 2;

  var ICONS = {};
  ICONS[STATE_NODATA] = 'fa-exclamation-circle';
  ICONS[STATE_PENDING] = 'fa-question-circle';
  ICONS[STATE_OK] = 'fa-check-circle';
  ICONS[STATE_FAIL] = 'fa-exclamation-triangle';

  var COLOR = {};
  COLOR[STATE_NODATA] = 'status-nodata';
  COLOR[STATE_PENDING] = 'status-pending';
  COLOR[STATE_OK] = 'status-ok';
  COLOR[STATE_FAIL] = 'status-fail';

  var TOOLTIP = {};
  TOOLTIP[STATE_NODATA] = 'We were unable to load the status of this CNAME. Please try reloading.';
  TOOLTIP[STATE_PENDING] = 'We are loading the status of this CNAME.';
  TOOLTIP[STATE_OK] = 'This CNAME is properly configured. Certificate renewal will succeed.';
  TOOLTIP[STATE_FAIL] = 'This CNAME is misconfigured. If all CNAMEs are misconfigured, certificate renewal will fail.';

  exports['default'] = _ember['default'].Component.extend({
    httpsDns: _ember['default'].inject.service(),
    raven: _ember['default'].inject.service(),

    classNames: ['cname-state'],

    from: null,
    to: null,
    okOnly: false,

    cnameState: STATE_PENDING,

    isPending: _ember['default'].computed.equal('cnameState', STATE_PENDING),
    isOk: _ember['default'].computed.equal('cnameState', STATE_OK),

    showComponent: _ember['default'].computed('okOnly', 'isOk', function () {
      if (!this.get('okOnly')) {
        return true;
      }

      return this.get('isOk');
    }),

    icon: _ember['default'].computed('cnameState', function () {
      return ICONS[this.get('cnameState')];
    }),

    color: _ember['default'].computed('cnameState', function () {
      return COLOR[this.get('cnameState')];
    }),

    tooltip: _ember['default'].computed('cnameState', function () {
      return TOOLTIP[this.get('cnameState')];
    }),

    checkCname: (function () {
      var _this = this;

      this.set('cnameState', STATE_PENDING);
      this.get('httpsDns').testCnameMatch(this.get('from'), this.get('to')).then(function (r) {
        _this.set('cnameState', r ? STATE_OK : STATE_FAIL);
      })['catch'](function (e) {
        _this.set('cnameState', STATE_NODATA);
        _this.get('raven').captureException(e);
      });
    }).observes('from', 'to'),

    doIninitialCheck: (function () {
      this.checkCname();
    }).on('init'),

    actions: {
      refresh: function refresh() {
        this.checkCname();
      }
    }
  });
});