define('diesel/validators/password-complexity', ['exports', 'ember-validations/validators/base'], function (exports, _emberValidationsValidatorsBase) {

  var COMPLEXITY_RULES = [['must be at least 10 characters', /^.{0,9}$/], ['must no greater than 128 characters', /^.{129,}$/], ['must contain at least one uppercase letter', /^[^A-Z]+$/], ['must contain at least one lowercase letter', /^[^a-z]+$/], ['must contain at least one digit or special character', /^[^0-9!@#$%^&*()]+$/]];

  exports['default'] = _emberValidationsValidatorsBase['default'].extend({
    call: function call() {
      var possiblePassword = this.model.get(this.property);
      if (!possiblePassword) {
        return;
      }
      for (var i = 0, l = COMPLEXITY_RULES.length; i < l; i++) {
        var message = COMPLEXITY_RULES[i][0];
        var ruleRegex = COMPLEXITY_RULES[i][1];
        if (ruleRegex.exec(possiblePassword)) {
          this.errors.pushObject(message);
        }
      }
    }
  });
});