define('diesel/components/c-default-control-implementation-detail/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    actions: {
      onIgnore: function onIgnore(resource) {
        this.attrs.onIgnore(resource);
      },

      onRemoveIgnore: function onRemoveIgnore(resource) {
        this.attrs.onRemoveIgnore(resource);
      },

      onRunEvaluation: function onRunEvaluation(customerControl) {
        this.attrs.onRunEvaluation(customerControl);
      }
    }
  });
});