define('diesel/asset/controls/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    modalDisplay: _ember['default'].inject.service(),

    model: function model() {
      var asset = this.modelFor('asset');
      return asset.get('currentRiskProfile');
    },

    setupController: function setupController(controller, riskProfile) {
      var asset = this.modelFor('asset');
      var securityControls = riskProfile.get('securityControls').sortBy('relevantIndex');
      controller.setProperties({ asset: asset, riskProfile: riskProfile, securityControls: securityControls });
    },

    actions: {
      editRiskComponent: function editRiskComponent(component) {
        var type = 'security-control';
        var resource = this.modelFor('asset');
        var resourceType = resource.get('isComponent') ? 'component' : 'system';

        this.get('modalDisplay').showModal('modal-risk-component-editor', {
          modalRiskComponentEditorProperties: { component: component, type: type, resource: resource, resourceType: resourceType }
        });
      }
    }
  });
});