define('diesel/components/six-point-scale-name/component', ['exports', 'ember', 'diesel/utils/six-point-scale'], function (exports, _ember, _dieselUtilsSixPointScale) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'span',
    classNames: ['six-point-scale-name'],
    classNameBindings: ['levelClassname'],
    point: _ember['default'].computed('value', function () {
      return _dieselUtilsSixPointScale['default'].findBy('value', this.get('value'));
    }),

    levelClassname: _ember['default'].computed('title', function () {
      return _ember['default'].String.dasherize(this.get('title'));
    }),

    title: _ember['default'].computed('point', function () {
      return this.get('point.title');
    })
  });
});