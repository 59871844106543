define('diesel/mixins/components/asset-edit', ['exports', 'ember', 'diesel/mixins/components/resizable-modal', 'diesel/mixins/models/asset', 'diesel/mixins/models/impacts', 'diesel/mixins/models/classification'], function (exports, _ember, _dieselMixinsComponentsResizableModal, _dieselMixinsModelsAsset, _dieselMixinsModelsImpacts, _dieselMixinsModelsClassification) {

  var BOOLEANS = [{ handle: true, name: 'Yes' }, { handle: false, name: 'No' }];

  var OWNER_TYPES = [{ handle: 'role', name: 'Role' }, { handle: 'user', name: 'User' }];

  exports['default'] = _ember['default'].Mixin.create(_dieselMixinsComponentsResizableModal['default'], {
    businessScale: _dieselMixinsModelsImpacts.BUSINESS_CRITICALITY_SCALE,
    impactScale: _dieselMixinsModelsImpacts.IMPACT_SCALE,
    financialScale: _dieselMixinsModelsImpacts.FINANCIAL_VALUE_SCALE,
    statuses: _dieselMixinsModelsAsset.STATUSES,
    timeUnits: _dieselMixinsModelsAsset.TIME_UNITS,
    booleans: BOOLEANS,
    legalRequirements: _dieselMixinsModelsClassification.LEGAL_REQUIREMENTS,
    regulatedDataTypes: _dieselMixinsModelsClassification.REGULATED_DATA_TYPES,
    sensitiveDataTypes: _dieselMixinsModelsClassification.SENSITIVE_DATA_TYPES,
    ownerTypes: OWNER_TYPES,
    rotationStrategies: _dieselMixinsModelsClassification.DATA_ROTATION_STRATEGIES,
    backupStrategies: _dieselMixinsModelsClassification.DATA_BACKUP_STRATEGIES,
    retentionTypes: _dieselMixinsModelsClassification.DATA_RETENTION_TYPES,

    asset: _ember['default'].computed(function () {
      throw 'Asset should be set from the consuming component, not in this mixin!';
    }),

    currentTab: 'basic',

    selectedUserOwner: _ember['default'].computed('users.[]', 'asset.ownerUserUrl', function () {
      var href = this.get('asset.ownerUserUrl');
      var users = this.get('users') || [];
      return users.find(function (u) {
        return u.get('links.self') === href;
      });
    }),

    selectedRoleOwner: _ember['default'].computed('roles.[]', 'asset.ownerRoleUrl', function () {
      var href = this.get('asset.ownerRoleUrl');
      var roles = this.get('roles') || [];
      return roles.find(function (r) {
        return r.get('links.self') === href;
      });
    }),

    ownerType: _ember['default'].computed('asset.ownerUserUrl', function () {
      return this.get('asset.ownerUserUrl') ? 'user' : 'role';
    }),

    classificationLevels: _ember['default'].computed(function () {
      var levels = [];
      Object.keys(_dieselMixinsModelsClassification.CLASSIFICATION_LEVELS).forEach(function (key) {
        levels.push({ value: key, label: _dieselMixinsModelsClassification.CLASSIFICATION_LEVELS[key] });
      });
      return levels;
    }),

    actions: {
      setTab: function setTab(tab) {
        this.set('currentTab', tab);
      },

      updateUserOwner: function updateUserOwner(user) {
        var asset = this.get('asset');
        asset.setOwnerUser(user);
      },

      updateRoleOwner: function updateRoleOwner(role) {
        var asset = this.get('asset');
        asset.setOwnerRole(role);
      }
    }
  });
});