define("diesel/print-controls/controller", ["exports", "ember", "diesel/mixins/controllers/filterable-controls"], function (exports, _ember, _dieselMixinsControllersFilterableControls) {
  exports["default"] = _ember["default"].Controller.extend(_dieselMixinsControllersFilterableControls["default"], {
    generatedAt: new Date(),
    actions: {
      openPrintDialog: function openPrintDialog() {
        var analytics = this.get('analytics');
        var EVENT_NAME = 'controlReport.printed';
        analytics.track(EVENT_NAME);
        analytics.aptibleEvent(EVENT_NAME);
        if (window && window.print) window.print();
      }
    }
  });
});