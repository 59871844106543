define('diesel/assignment/course/material/module/route', ['exports', 'ember', 'diesel/utils/course-assignment-state', 'diesel/utils/training-config'], function (exports, _ember, _dieselUtilsCourseAssignmentState, _dieselUtilsTrainingConfig) {

  var calculateAssessmentErrors = function calculateAssessmentErrors(form, errors) {
    var questions = form.questions;
    var passingScore = form.passingScore;

    if (!errors.length) {
      return null;
    }

    if (passingScore) {
      var correctCount = questions.length - errors.length;
      var failed = correctCount < passingScore;
      return failed ? 'A score of ' + passingScore + ' or more is required to completed this training. You scored ' + correctCount + '.' : null;
    }

    return errors[0];
  };

  exports['default'] = _ember['default'].Route.extend({
    model: function model(_ref) {
      var module = _ref.module;

      var _modelFor = this.modelFor('assignment.course.material');

      var assignment = _modelFor.assignment;
      var course = _modelFor.course;

      if (!(0, _dieselUtilsCourseAssignmentState.courseHasModule)(course, module)) {
        // User visited a module URL that no longer exists in training-config.js
        // Redirect them to default module with redirect message
        var redirectMessage = course.get('name') + ' module "' + module + '" no longer exists.\n                              You have been redirected to the start of ' + course.get('name');
        _ember['default'].get(this, 'flashMessages').danger(redirectMessage);
        this.transitionTo('assignment.course.material.module', course.get('defaultModule'));
      }

      return (0, _dieselUtilsCourseAssignmentState['default'])({ module: module, course: course, assignment: assignment });
    },

    setupController: function setupController(controller, courseAssignmentState) {
      var organizationProfile = this.get('complianceStatus.organizationProfile');
      var approvalId = this.get('complianceStatus.currentApprovalId');
      var approvalPath = approvalId ? 'design/programs/' + approvalId + '/browse' : null;
      var showComplyGRCManual = this.get('authorization.currentContext.enabledFeatures.complyGrcProgram');
      var showComplianceOSManual = !showComplyGRCManual && approvalPath;
      var showClassicManual = !showComplyGRCManual && !showComplianceOSManual;
      var classicPolicyManual = organizationProfile.get('currentPolicyManual');

      controller.setProperties({
        courseAssignmentState: courseAssignmentState,
        organizationProfile: organizationProfile,
        approvalPath: approvalPath,
        showComplyGRCManual: showComplyGRCManual,
        showComplianceOSManual: showComplianceOSManual,
        showClassicManual: showClassicManual,
        classicPolicyManual: classicPolicyManual
      });
    },

    afterModel: function afterModel() {
      window.scrollTo(0, 0);
    },

    actions: {
      next: function next(_ref2) {
        var _this = this;

        var form = _ref2.form;
        var _currentModel = this.currentModel;
        var assignment = _currentModel.assignment;
        var isLast = _currentModel.isLast;
        var nextModule = _currentModel.nextModule;

        this.controller.set('assessmentError', null);

        if (form) {
          var errors = (0, _dieselUtilsTrainingConfig.validateModuleForm)(form);
          var assessmentError = calculateAssessmentErrors(form, errors);

          if (assessmentError) {
            this.controller.set('assessmentError', assessmentError);
            return;
          }
        }

        if (!isLast) {
          assignment.set('currentModule', nextModule.key);
          assignment.save().then(function () {
            return _this.transitionTo('assignment.course.material.module', nextModule.key);
          });
          return;
        }

        var userProfile = assignment.get('userProfile');
        var submission = this.store.createRecord('submission', {
          assignment: assignment, userProfile: userProfile, feedback: form
        });

        submission.save().then(function () {
          assignment.set('currentModule', null);
          assignment.save().then(function () {
            var message = assignment.get('course.name') + ' Completed!';
            _this.transitionTo('gridiron-user');
            _ember['default'].get(_this, 'flashMessages').success(message);
          });
        });
      },

      previous: function previous() {
        var previousModule = this.currentModel.previousModule;

        if (previousModule) {
          // REVIEW: This doesn't save your state if you go back.  Should we?
          this.transitionTo('assignment.course.material.module', previousModule.key);
        }
      },

      saveAndExit: function saveAndExit() {
        var _this2 = this;

        var _currentModel2 = this.currentModel;
        var assignment = _currentModel2.assignment;
        var module = _currentModel2.module;

        assignment.set('currentModule', module);
        assignment.save().then(function () {
          var message = assignment.get('course.name') + ' Progress saved!';
          _this2.transitionTo('gridiron-user');
          _ember['default'].get(_this2, 'flashMessages').success(message);
        });
      }
    }
  });
});