define('diesel/utils/get-documents', ['exports', 'diesel/utils/assign'], function (exports, _dieselUtilsAssign) {
  exports['default'] = getDocuments;
  exports.withPolicyPaths = withPolicyPaths;

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  function getDocuments(nodes) {
    var accum = arguments.length <= 1 || arguments[1] === undefined ? [] : arguments[1];
    var parentGroups = arguments.length <= 2 || arguments[2] === undefined ? [] : arguments[2];

    if (!nodes) {
      return [];
    }

    nodes.forEach(function (node) {
      if (node.type === 'group') {
        getDocuments(node.children, accum, [].concat(_toConsumableArray(parentGroups), [node]));
      } else {
        accum.push((0, _dieselUtilsAssign['default'])({ parentGroups: parentGroups }, node));
      }
    });

    return accum;
  }

  /**
   * This function returns a new policy document with a policyPath property
   * applied to certain sections. This quirky numbering highlights the fact that
   * there is semantic information we're inferring from the document's structure
   * to ease the burden of authoring policies. If this approach proves to be too
   * brittle, we can shift the onus to the documentation author to provide
   * information about what sections of the document require numbering.
   *
   * The policyPath properties show the path of the node in the tree with three
   * caveats applied:
   *
   * 1. We omit the first grouping level. All documents needing policyPaths are
   *    currently contained within a single, top-level group called "Policies".
   *    Therefore adding this index to the path wouldn't be interesting.
   *
   * 2. We omit the index for the "Policy" section within its parent. The
   *    sibling nodes won't show humanized IDs so adding information about the
   *    position of the "Policy" section isn't interesting.
   *
   * 3. We look for nodes within a section called "Policy", and begin adding
   *    policyPath properties at that point.
   *
   * @param {object} tree - A nested graph of objects representing a structured
   * document
   * @param {array} path - A list of indices that lead to the current node in the
   * tree
   * @param {boolean} isAddingPaths - A flag that marks the beginning of a Policy
   * section after which policyPath properties should be added to children nodes
   *
   * @return {object} A new tree with policyPath properties added to appropriate
   * nodes
   */

  function withPolicyPaths(_tree) {
    var path = arguments.length <= 1 || arguments[1] === undefined ? [] : arguments[1];
    var isAddingPaths = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];

    var tree = (0, _dieselUtilsAssign['default'])({}, _tree);

    if (isAddingPaths) {
      // Caveat #1 - Use path.slice(1) to omit the first grouping index each time
      // we set a policyPath.
      tree.policyPath = path.slice(1);
      tree.policyPathPrefix = tree.policyPath.map(function (i) {
        return i + 1;
      }).join('.');
    } else if (isPolicySection(tree)) {
      // Caveat #3 - We set isAddingPaths to true so that we start adding paths to
      // all subsequent children.
      isAddingPaths = true;

      // Caveat #2 - We omit the current "Policy" node index from the path.
      path = path.slice(0, -1);
    }

    if (tree.children) {
      tree.children = tree.children.map(function (node, i) {
        return withPolicyPaths(node, [].concat(_toConsumableArray(path), [i]), isAddingPaths);
      });
    }

    return tree;
  }

  function isPolicySection(node) {
    return node.title && node.title.toLowerCase() === 'policy';
  }
});