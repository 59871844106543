define('diesel/components/criterion-status-badge/component', ['exports', 'ember', 'diesel/mixins/services/criterion-status'], function (exports, _ember, _dieselMixinsServicesCriterionStatus) {
  exports['default'] = _ember['default'].Component.extend(_dieselMixinsServicesCriterionStatus['default'], {
    classNames: ['criterion-status-badge'],
    criterion: null,
    criterionDocuments: null,
    badgeText: _ember['default'].computed('status.status', function () {
      return ({
        incomplete: 'Incomplete',
        expired: 'Expired',
        complete: 'Complete'
      })[this.get('status.status')];
    })
  });
});