define("diesel/application/torii-provider", ["exports", "ember", "torii/providers/base", "diesel/utils/tokens"], function (exports, _ember, _toriiProvidersBase, _dieselUtilsTokens) {
  exports["default"] = _toriiProvidersBase["default"].extend({
    open: function open(credentials) {
      // If the credential has a "token", we just pass it right through
      // TODO: Check that it's an actual token object instead?
      var token = credentials.token;
      if (token !== undefined) {
        return new _ember["default"].RSVP.Promise(function (resolve) {
          return resolve(JSON.parse(token.get('rawPayload')));
        });
      }

      // If it's a tokenPayload, same
      var tokenPayload = credentials.tokenPayload;
      if (tokenPayload !== undefined) {
        return new _ember["default"].RSVP.Promise(function (resolve) {
          return resolve(tokenPayload);
        });
      }

      return (0, _dieselUtilsTokens.createToken)(credentials);
    }
  });
});