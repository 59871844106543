define('diesel/services/operations-manager', ['exports', 'ember', 'diesel/config/environment', 'diesel/utils/assign'], function (exports, _ember, _dieselConfigEnvironment, _dieselUtilsAssign) {
  exports.deepReloadResources = deepReloadResources;
  var decamelize = _ember['default'].String.decamelize;

  var OPERATION_TIMEOUT = _dieselConfigEnvironment['default'].timeouts.genericOperation;

  var DEEP_RELOAD_RELATIONS = ['app', 'database', 'service', 'currentRelease', 'disk'];

  var DEEP_RELOAD_MAX_DEPTH = 10;

  function deepReloadResources(resources, visited, depth) {
    if (resources.length === 0) {
      _ember['default'].debug('Reload complete');
      return _ember['default'].RSVP.resolve();
    }

    var nextVisited = _ember['default'].copy(visited || {});
    resources.forEach(function (r) {
      var rHref = r.get('links.self');
      _ember['default'].debug('Visited: ' + rHref);
      nextVisited[rHref] = true;
    });

    if (depth === undefined) {
      depth = 0;
    }

    if (depth > DEEP_RELOAD_MAX_DEPTH) {
      return _ember['default'].RSVP.reject(new Error('deepReloadResources: too deep: ' + depth));
    }

    return _ember['default'].RSVP.all(resources.map(function (r) {
      return r.reload();
    })).then(function (reloadedResources) {
      var nextResourcePromises = [];

      reloadedResources.forEach(function (resource) {
        var resourceHref = resource.get('links.self');
        if (!resourceHref) {
          _ember['default'].debug('Skip all relations on ' + resourceHref + ': no identity');
          return;
        }

        DEEP_RELOAD_RELATIONS.forEach(function (relName) {
          var relLoaded = resource.get(relName + '.isLoaded');
          var relHref = resource.get('links.' + decamelize(relName));

          if (relLoaded === undefined) {
            // Relationship does not exist.
            return;
          }

          if (!relLoaded) {
            // Resource hasn't been loaded yet, no use reloading it.
            _ember['default'].debug('Skip ' + relName + ' on ' + resourceHref + ': not loaded.');
            return;
          }

          if (!relHref) {
            // Whoops, we don't have a link for this resource, so we won't be
            // able to track whether we ever reloaded it. More importantly, this
            // probably emans that resource got loaded as a sideload, so it's not
            // even guaranteed that there is a link we could use.
            _ember['default'].debug('Skip ' + relName + ' on ' + resourceHref + ': no link found.');
            return;
          }

          if (nextVisited[relHref]) {
            // We already visited that resource. Avoid an infinite loop by not
            // visiting it again!
            _ember['default'].debug('Skip ' + relName + ' on ' + resourceHref + ': already visited ' + relHref + '.');
            return;
          }

          // We'll still get a promise back if the resource is async, even if no
          // request is being made.
          _ember['default'].debug('Reload ' + relName + ' on ' + resourceHref + ': ' + relHref);
          nextResourcePromises.push(resource.get(relName));
        });
      });

      return _ember['default'].RSVP.all(nextResourcePromises);
    }).then(function (nextResources) {
      return deepReloadResources(nextResources, nextVisited, depth + 1);
    });
  }

  exports['default'] = _ember['default'].Service.extend({
    store: _ember['default'].inject.service(),
    flashMessages: _ember['default'].inject.service(),

    operationStacks: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('operationIndex', 0);
      this.set('operationStacks', {});
    },

    createBackgroundOperation: function createBackgroundOperation(resource, attributes) {
      var _this = this;

      var flashMessages = this.get('flashMessages');

      var createAttributes = (0, _dieselUtilsAssign['default'])({}, attributes || {});

      var modelName = resource.get('constructor.modelName');
      _ember['default'].assert('Resource must have a modelName', modelName);

      var relationName = _ember['default'].String.camelize(modelName);
      createAttributes[relationName] = resource;

      // NOTE: We don't use the operation ID as the index because we want to
      // immediately register something. This matters: operable-action and
      // operable-status observe our resource's operation stack, and we want the
      // buttons to disable and statuses to update immediately upon clicking, not
      // after the operation has been created.
      var idx = this.nextIndex();
      this.pushOperation(resource, idx);

      return this.get('store').createRecord('operation', createAttributes).save().then(function (op) {
        var prefix = 'Operation ' + op.get('id') + ' (' + op.get('type') + ')';
        flashMessages.info(prefix + ': Operation created.');

        // Note: we intentionally do not return this, since we want to monitor
        // the operation in the background.
        op.reloadUntilStatusChanged(OPERATION_TIMEOUT).then(function (completedOperation) {
          if (!completedOperation) {
            // The operation was a deprovision: unload the record.
            return resource.unloadRecord();
          }
          return deepReloadResources([resource]);
        }).then(function () {
          flashMessages.success(prefix + ': Operation succeeded.');
        }, function (e) {
          flashMessages.danger(prefix + ': ' + (e.message || 'unknown error'));
        })['finally'](function () {
          _this.popOperation(resource, idx);
        });
      }, function (e) {
        _this.popOperation(resource, idx);
        flashMessages.danger('Operation creation failed: ' + (e.message || 'unknown error') + '.');
        throw e;
      });
    },

    getOperationStackByHref: function getOperationStackByHref(href) {
      _ember['default'].assert('getOperationStackByHref requires a href', !!href);

      var operationStacks = this.get('operationStacks');

      var ret = operationStacks[href];
      if (!ret) {
        ret = _ember['default'].A([]);
        operationStacks[href] = ret;
        _ember['default'].debug('getOperationStackByHref: initialized ' + href);
      }

      return ret;
    },

    pushOperation: function pushOperation(resource, idx) {
      var href = resource.get('links.self');
      _ember['default'].debug('pushOperation: ' + idx + ' in ' + href);
      this.getOperationStackByHref(href).addObject(idx);
    },

    popOperation: function popOperation(resource, idx) {
      var href = resource.get('links.self');
      _ember['default'].debug('popOperation: ' + idx + ' in ' + href);
      this.getOperationStackByHref(href).removeObject(idx);
    },

    nextIndex: function nextIndex() {
      var idx = this.get('operationIndex');
      this.set('operationIndex', idx + 1);
      return idx;
    }
  });
});