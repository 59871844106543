define('diesel/models/criterion', ['exports', 'ember', 'ember-data', 'diesel/models/base'], function (exports, _ember, _emberData, _dieselModelsBase) {
  exports['default'] = _dieselModelsBase['default'].extend({
    handle: _emberData['default'].attr('string'),
    name: _emberData['default'].attr('string'),
    description: _emberData['default'].attr('string'),
    scope: _emberData['default'].attr('string'),
    evidenceType: _emberData['default'].attr('string'),
    documents: _emberData['default'].hasMany('document', { async: true }),

    friendlyName: _ember['default'].computed('name', function () {
      var currentName = this.get('name');
      var handle = this.get('handle');

      if (handle === 'training_log') {
        return 'Basic Training';
      }

      if (/training_log/.test(handle)) {
        return currentName.replace('Log', '');
      }

      return currentName;
    }),

    getSubjectStatus: function getSubjectStatus(subject, organization) {
      // FIXME: This method assumes documents are already loaded.  Instead
      // It should be promise-based and resolve unloaded documents before
      // generating a status

      var scopeKey = ({ user: 'userUrl', app: 'appUrl' })[this.get('scope')];
      var subjectUrl = subject.get('links.self');
      var organizationUrl = organization.get('links.self');

      var subjectDocuments = this.get('documents.content').filter(function (d) {
        if (d.get('organizationUrl') !== organizationUrl) {
          // Users can belong to multiple organizations, so be sure to only
          // include documents related to the current organization
          return false;
        }

        return d.get(scopeKey) === subjectUrl;
      });

      if (subjectDocuments.get('length') > 0) {
        var lastDocument = this.get('documents').objectAt(0);
        return { green: true, completedOn: lastDocument.get('createdAt'),
          nextAssessment: lastDocument.get('nextAssessment'),
          lastDocument: lastDocument };
      } else {
        return { green: false, completedOn: 'Never',
          nextAssessment: 'Immediately' };
      }
    }
  });
});