define('diesel/helpers/format-utc-timestamp', ['exports', 'ember', 'diesel/utils/dates'], function (exports, _ember, _dieselUtilsDates) {
  exports.formatUtcTimestamp = formatUtcTimestamp;

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  function padLeft(str) {
    var len = arguments.length <= 1 || arguments[1] === undefined ? 2 : arguments[1];
    var filler = arguments.length <= 2 || arguments[2] === undefined ? "0" : arguments[2];

    str = '' + str; // coerce
    while (str.length < len) {
      str = filler + str;
    }

    return str;
  }

  function formatUtcTimestamp(date) {
    var excludeTime = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

    if (!date) {
      return;
    }

    _ember['default'].assert('format-utc-timestamp must be given an instanceof Date', date instanceof Date);

    var month = _dieselUtilsDates.monthNames[date.getUTCMonth()];
    var day = date.getUTCDate();
    var year = date.getUTCFullYear();
    var hours = date.getUTCHours();
    var period = hours >= 12 ? 'PM' : 'AM';
    hours = hours > 12 ? hours - 12 : hours;
    var minutes = padLeft(date.getUTCMinutes(), 2, '0');

    var formatted = month + ' ' + day + ', ' + year;

    if (typeof excludeTime !== 'boolean') {
      excludeTime = false;
    }

    if (!excludeTime) {
      formatted = formatted + ' ' + hours + ':' + minutes + period + ' UTC';
    }

    return formatted;
  }

  exports['default'] = _ember['default'].Helper.helper(function (params) {
    return formatUtcTimestamp.apply(undefined, _toConsumableArray(params));
  });
});