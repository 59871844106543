define('diesel/components/select-or-create-certificate/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    addNewCertificate: false,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      if (this.get('certificates.length') > 0) {
        if (!this.get('selected.id')) {
          // TODO-double-modify
          _ember['default'].run.schedule('afterRender', function () {
            _this.set('vhost.certificate', _this.get('certificates.firstObject'));
          });
        }
      } else {
        this.set('addNewCertificate', true);
      }
    },

    // This observer is needed so that the vhost certificateBody and privateKey get
    // reset to null if the user clicks to input the cert and then clicks to use
    // existing cert
    resetVhostCertificateInput: _ember['default'].observer('addNewCertificate', function () {
      if (!this.get('addNewCertificate')) {
        this.set('vhost.certificateBody', null);
        this.set('vhost.privateKey', null);
      }
    })
  });
});