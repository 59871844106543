define('diesel/security-assessment/index/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    redirect: function redirect() {
      var model = this.modelFor('security-assessment');

      if (model.scope === 'organization') {
        this.transitionTo('security-assessment.security-controls');
      } else {
        this.transitionTo('security-assessment.predisposing-conditions');
      }
    }
  });
});