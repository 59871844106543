define("diesel/sso/direct/route", ["exports", "ember", "diesel/config/environment", "diesel/utils/location"], function (exports, _ember, _dieselConfigEnvironment, _dieselUtilsLocation) {
  exports["default"] = _ember["default"].Route.extend({
    model: function model(params) {
      var model = _ember["default"].Object.create({
        organization_id: params.organization_id
      });
      return model;
    },
    afterModel: function afterModel(model) {
      var dashBase = !/^https?:\/\//i.test(_dieselConfigEnvironment["default"].dashboardBaseUri) ? "https://" + _dieselConfigEnvironment["default"].dashboardBaseUri : _dieselConfigEnvironment["default"].dashboardBaseUri;
      var failure_page = encodeURIComponent(dashBase + "/sso/failure");
      _dieselUtilsLocation["default"].replace(_dieselConfigEnvironment["default"].authBaseUri + "/organizations/" + model.organization_id + "/saml/login?redirect_uri=" + failure_page);
    }
  });
});