define('diesel/stack/accounts/route', ['exports', 'ember', 'diesel/utils/fetch-all-pages'], function (exports, _ember, _dieselUtilsFetchAllPages) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var _this = this;

      var stack = this.modelFor('stack');
      var accounts = (0, _dieselUtilsFetchAllPages['default'])(this.store, stack, 'account').then(function (accounts) {
        return accounts.filterBy('organization.id', _this.get('authorization.currentOrganization.id'));
      });

      return _ember['default'].RSVP.hash({ stack: stack, accounts: accounts });
    }
  });
});