define('diesel/components/metric-drain-influxdb-configuration/component', ['exports', 'ember', 'ember-validations/mixin'], function (exports, _ember, _emberValidationsMixin) {

  var INFLUXDB_ATTRS = ['address', 'username', 'password', 'database'];

  exports['default'] = _ember['default'].Component.extend(_emberValidationsMixin['default'], {
    protocolOptions: ['https', 'http'],

    protocol: 'https',
    hostname: null,
    port: null,
    username: null,
    password: null,
    database: null,

    address: _ember['default'].computed('protocol', 'hostname', 'port', function () {
      var _getProperties = this.getProperties('protocol', 'hostname', 'port');

      var protocol = _getProperties.protocol;
      var hostname = _getProperties.hostname;
      var port = _getProperties.port;

      if (!port) {
        port = protocol === 'http' ? 80 : 443;
      }
      return protocol + '://' + hostname + ':' + port;
    }),

    sendUpdate: _ember['default'].observer.apply(_ember['default'], INFLUXDB_ATTRS.concat(['isValid', function () {
      var attrs = null;
      if (this.get('isValid')) {
        attrs = this.getProperties.apply(this, INFLUXDB_ATTRS);
      }
      this.sendAction('update', attrs);
    }])),

    validations: {
      hostname: {
        presence: true,
        format: { 'with': /^(?!(http|https):\/\/)/i, message: 'Do not include the protocol.' }
      },
      username: { presence: true },
      password: { presence: true },
      database: { presence: true },
      port: {
        numericality: {
          allowBlank: true,
          onlyInteger: true,
          greaterThan: 0,
          lessThanOrEqualTo: 65535
        }
      }
    }
  });
});