define('diesel/services/modal-display', ['exports', 'ember'], function (exports, _ember) {

  /**
   * Modal Usage
   *
   * In order to show a modal:
   *   * Inject the modal-display service into the route/controller/component
   *     that will launch the modal
   *   * Create a component that will be the modal:
   *      * It should be named "modal-X" (e.g. "modal-create-app")
   *      * It must include the modal-base component mixin (this ensures that
   *        when modal-display opens the modal, all properties are set correctly
   *        on that component)
   *      * Its template should be wrapped by the modal-wrapper, e.g.:
   *        ```hbs
   *        {{#modal-wrapper title=X description=Y wide=TrueOrFalse}}
   *          Component HTML here...
   *        {{/modal-wrapper}}
   *        ```
   *      * The modal-wrapper accepts `title`, `description` and `wide` params
   *   * To open the modal:
   *      * From the route/controller/component, call: `modalDisplay.showModal`
   *      * This method takes 3 arguments:
   *        * componentName (e.g. 'modal-create-app')
   *        * an object of "primary" properties (e.g. { newApp: store.createRecord('app', ...) })
   *        * (optional) an object with an "actions" key
   *          * The actions must be functions that the modal's component.js can `sendAction` to
   *      * actions:
   *        * These are also set as properties on the modal component
   *        * The modal component can call "sendAction" to invoke these actions
   *        * modalDisplay has a helper method for delegating to an action on modal opener:
   *           * Call `modalDisplay.modalAction(target, actionName)` to generate a
   *             closed-over action to pass to the modal
   *           * This closed-over action is promise-aware and will automatically close
   *             the modal when the promise resolves (with some caveats, see its code below)
   *           * modalDisplay.modalAction takes a 3rd argument that can be {dismissAfter:false} in
   *             which case it will not dismiss the modal after the action resolves
   *        * You can also pass your own function in the `actions` argument to modalDisplay.showModal if
   *          you want to have different, custom behavior.
   *   * Closing the modal: There are several ways to do this:
   *     * You can call `modalDisplay.dismissModal()` any time. This method is idempotent and will
   *       do nothing if the modal is not currently shown
   *     * From the modal's template, you can do:
   *       ```hbs
   *         <button {{action "dismissModal"}}>
   *       ```
   *     * From the modal's component code you can call: `this.sendAction('dismissModal')`
   *     * The modal will also be closed if you call the `this.sendAction` to call
   *       an action that was created using `modalDisplay.modalAction` (see above)
   *     * The modal is closed if the user presses the `escape` key
   *     * The router dismisses the modal after route transitions in the `didTransition` hook
   *   * Modal cleanup on close:
   *     * In *all* cases that a modal is closed (whether to cancel the modal or after completing its action)
   *       modalDisplay will invoke a "beforeDismiss" action on the modal, if it exists
   *     * Define a `beforeDismiss` action on the modal to do any cleanup. This
   *       action should *not* attempt to close the modal (e.g. by calling `sendAction('dismissModal')`)
   *   * Test Helpers:
   *     * In tests, use the `assert.hasModal(modalNameWithoutModalPrefix)`
   *       (e.g. `assert.hasModal('create-app')` to check for presence of modal-create-app)
   *       and `assert.hasNoModal()` helpers to assert that the modal is shown or dismissed
   *
   *   Other notes:
   *     * The modal component does not need to define a "dismiss", "onDismiss",
   *       "escape" or "outsideclick" action
   *     * It's considered an error to open a modal while one is already open
   *     * It's considered an error to dismiss a modal while it is already
   *       being dismissed (e.g. if calling "dismissModal" inside the "beforeDismiss" action
   */

  var ESCAPE_KEY_CODE = 27;
  var KEYUP_EVENT_NAME = "keyup.modal-display";
  var DEFAULT_BEFORE_DISMISS_HANDLER = function DEFAULT_BEFORE_DISMISS_HANDLER() {};

  exports['default'] = _ember['default'].Service.extend({
    componentName: null,

    isOpen: _ember['default'].computed('componentName', function () {
      return !!this.get('componentName');
    }),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this._keyupListener = function (event) {
        if (event.keyCode === ESCAPE_KEY_CODE) {
          _ember['default'].run(_this, 'dismissModal');
        }
      };
    },

    // ========== public =========== //

    showModal: function showModal(componentName) {
      var props = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      var _ref = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

      var _ref$actions = _ref.actions;
      var actions = _ref$actions === undefined ? {} : _ref$actions;

      _ember['default'].assert('Cannot pass actions to showModal that clash with property names', Object.keys(actions).every(function (i) {
        return !Object.keys(props).includes(i);
      }));
      _ember['default'].assert('Cannot open a modal while one is already shown, ' + ('"' + this.get('componentName') + '" is currently open'), !this.get('componentName'));

      this._beforeDismissHandler = DEFAULT_BEFORE_DISMISS_HANDLER;
      var componentProperties = Object.assign({}, props, actions);
      this.setProperties({ componentName: componentName, componentProperties: componentProperties });
      this.startEscListener();
    },

    // This is a no-op if the modal is not already open
    dismissModal: function dismissModal() {
      if (!this.get('isOpen')) {
        return;
      }
      _ember['default'].assert('Cannot dismiss modal ' + this.get('componentName') + ' while it is already being dismissed', !this._isDismissing);

      // Use `this._isDismissing` to prevent an infinite loop if
      // the beforeDismissHandler incorrectly attempts to close the modal
      this._isDismissing = true;
      this._beforeDismissHandler();
      this._beforeDismissHandler = DEFAULT_BEFORE_DISMISS_HANDLER;
      this._isDismissing = false;

      this.stopEscListener();
      this.set('componentName', null);

      _ember['default'].run.later(function () {
        _ember['default'].$(".popover").popover("hide");
      });
    },

    setBeforeDismissHandler: function setBeforeDismissHandler(component, actionName) {
      this._beforeDismissHandler = function () {
        return component.send(actionName);
      };
    },

    // ========== private =========== //

    startEscListener: function startEscListener() {
      _ember['default'].$('body').on(KEYUP_EVENT_NAME, this._keyupListener);
    },

    stopEscListener: function stopEscListener() {
      _ember['default'].$('body').off(KEYUP_EVENT_NAME, this._keyupListener);
    },

    /**
     * Helper method used to create delegated actions to pass as properties to
     * the modal component.
     * Optionally (default:true) chains a call to dismiss the modal after successful
     * promise resolution
     */
    modalAction: function modalAction(context, fnName) {
      var _this2 = this;

      var options = arguments.length <= 2 || arguments[2] === undefined ? { dismissAfter: true } : arguments[2];

      _ember['default'].assert('Must pass extant action to modalAction', !!(context.actions && context.actions[fnName]));

      return function () {
        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        var res = context.actions[fnName].apply(context, args);
        return _ember['default'].RSVP.resolve(res).then(function (res) {
          if (res instanceof Error) {
            // Presumably this is a caught-and-handled error, because it
            // came from the success/then hook.
            // Ignore it and no-op
          } else if (options.dismissAfter) {
              _this2.dismissModal();
            }
        });
      };
    }
  });
});