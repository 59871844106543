define('diesel/assets/type/route', ['exports', 'ember'], function (exports, _ember) {
  var FILTER_TYPES = {
    all: 'All Assets',
    apps: 'Apps',
    databases: 'Databases',
    networks: 'Networks',
    primary: 'Hosts',
    storage: 'Storage Services',
    saas: 'Web Services',
    logging: 'Logging Services',
    device: 'Devices'
  };

  exports.FILTER_TYPES = FILTER_TYPES;
  exports['default'] = _ember['default'].Route.extend({
    model: function model(_ref) {
      var type = _ref.type;

      return { assets: this.modelFor('assets'), typeFilter: type };
    },

    setupController: function setupController(controller, model) {
      var filterTypes = Object.keys(FILTER_TYPES).map(function (filterTypeHandle) {
        var name = FILTER_TYPES[filterTypeHandle];
        return { handle: filterTypeHandle, name: name };
      });
      var _model$assets = model.assets;
      var components = _model$assets.components;
      var systems = _model$assets.systems;

      controller.setProperties({ components: components, systems: systems, filterTypes: filterTypes, typeFilter: model.typeFilter });
    }
  });
});