define('diesel/database/backups/route', ['exports', 'ember', 'diesel/config/environment', 'diesel/mixins/routes/paginated'], function (exports, _ember, _dieselConfigEnvironment, _dieselMixinsRoutesPaginated) {

  var OPERATION_TIMEOUT = _dieselConfigEnvironment['default'].timeouts.databaseBackupOperation;

  var setPaginationMeta = function setPaginationMeta(resource, controller) {
    var pagination = resource.get('meta');
    controller.set('currentPage', pagination.current_page);
    controller.set('totalCount', pagination.total_count);
    controller.set('perPage', pagination.per_page);
  };

  exports['default'] = _ember['default'].Route.extend(_dieselMixinsRoutesPaginated['default'], {
    modalDisplay: _ember['default'].inject.service(),
    getPaginatedResourceType: function getPaginatedResourceType() {
      return 'backup';
    },

    composeQuery: function composeQuery(page) {
      return {
        database: this.modelFor('database'),
        page: page
      };
    },

    setupController: function setupController(controller, model) {
      var database = this.modelFor('database');
      var account = database.get('account');
      controller.setProperties({ model: model, account: account.content });
      setPaginationMeta(model, controller);
    },

    actions: {
      createBackup: function createBackup() {
        var _this = this;

        var database = this.modelFor('database');

        return this.get("store").createRecord('operation', {
          type: 'backup',
          database: database
        }).save().then(function (operation) {
          _this.get("flashMessages").success("Backup scheduled");
          return operation;
        }).then(function (operation) {
          return operation.reloadUntilStatusChanged(OPERATION_TIMEOUT);
        }).then(function () {
          return _this.refresh();
        })['catch'](function (e) {
          _ember['default'].get(_this, 'flashMessages').danger(e.message);
        });
      },

      openRestoreBackupModal: function openRestoreBackupModal(backup) {
        this.get('modalDisplay').showModal('modal-restore-backup', {
          backupToRestore: backup
        });
      },

      purgeBackup: function purgeBackup(backup) {
        var _this2 = this;

        return this.get("store").createRecord('operation', {
          type: 'purge',
          backup: backup
        }).save().then(function (operation) {
          _this2.get("flashMessages").success("Backup removal scheduled");
          return operation;
        }).then(function (operation) {
          return operation.reloadUntilStatusChanged(OPERATION_TIMEOUT);
        }).then(function () {
          return _this2.refresh();
        });
      }
    }
  });
});