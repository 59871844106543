define('diesel/serializers/permission', ['exports', 'diesel/serializers/application'], function (exports, _dieselSerializersApplication) {
  exports['default'] = _dieselSerializersApplication['default'].extend({
    keyForRelationship: function keyForRelationship(key) {
      // This overrides the RESTSerializer's preference to add suffix "_id"
      // for the key of a `belongsTo` relationship
      if (key === 'role') {
        return 'role';
      }

      // TODO-ember-data Should not need a `this._super` check, should always be `_super`.
      // This is here because HalAdapter incorrectly breaks the inheritance chain
      // when it peels off this method before calling it:
      // https://github.com/201-created/ember-data-hal-9000/blob/de66dceb68a39ee868f226d14edfdc323cce0556/addon/mixin.js#L225-L227
      if (this._super) {
        return this._super.apply(this, arguments);
      } else {
        return key;
      }
    }
  });
});