define('diesel/signup/index/route', ['exports', 'ember', 'diesel/components/login-form/component', 'diesel/mixins/routes/disallow-authenticated', 'diesel/utils/hubspot', 'diesel/utils/handleify', 'diesel/config/environment'], function (exports, _ember, _dieselComponentsLoginFormComponent, _dieselMixinsRoutesDisallowAuthenticated, _dieselUtilsHubspot, _dieselUtilsHandleify, _dieselConfigEnvironment) {

  function generateHandle(organization) {
    var suffixLength = 4;
    return [(0, _dieselUtilsHandleify.handleify)(organization.get('name'), _dieselUtilsHandleify.maxChars - suffixLength), organization.get('id').slice(0, suffixLength - 1)].join('-');
  }

  var ORGANIZATION_CREATED_EVENT_NAME = 'Created Organization';

  exports.ORGANIZATION_CREATED_EVENT_NAME = ORGANIZATION_CREATED_EVENT_NAME;
  exports['default'] = _ember['default'].Route.extend(_dieselMixinsRoutesDisallowAuthenticated['default'], {
    queryParams: { email: {} },

    model: function model(params) {
      return params.email;
    },

    setupController: function setupController(controller, email) {
      var user = this.store.createRecord('user');
      var organization = this.store.createRecord('organization');
      controller.set('model', user);
      controller.set('organization', organization);

      if (email) {
        // We have to run this later so that the validations can run
        _ember['default'].run.later(function () {
          return user.set('email', email);
        });
      }
    },
    activate: function activate() {
      var analytics = this.get('analytics');
      var EVENT_NAME = 'nux.signup.viewed';
      analytics.track(EVENT_NAME, {});
      analytics.aptibleEvent(EVENT_NAME);
    },

    bootstrapAccount: function bootstrapAccount(organization) {
      return this.store.createRecord('account', {
        handle: generateHandle(organization),
        type: 'development',
        organization: organization
      }).save();
    },

    actions: {
      signup: function signup(user, organization) {
        var _this = this;

        var analytics = this.get('analytics');
        this.controller.set('isSaving', true);

        return user.save().then(function () {
          var _user$getProperties = user.getProperties('email', 'password');

          var email = _user$getProperties.email;
          var password = _user$getProperties.password;

          var credentials = (0, _dieselComponentsLoginFormComponent.buildCredentials)(email, password);
          var event = 'nux.signup.created-user';
          analytics.track(event, { email: email });
          analytics.aptibleEvent(event, email);
          return _this.get('session').open('application', credentials);
        }).then(function () {
          return organization.save();
        }).then(function (organization) {
          if (_dieselConfigEnvironment['default'].featureFlags.skipBilling) {
            return _ember['default'].RSVP.resolve();
          }

          var _organization$getProperties = organization.getProperties('id', 'name');

          var id = _organization$getProperties.id;
          var name = _organization$getProperties.name;

          var organizationDetailsJson = { name: name };
          var event = 'nux.signup.created-organization';
          analytics.track(event, { name: name });
          analytics.aptibleEvent(event, name);
          return _this.store.createRecord('billing-detail', { id: id, organizationDetailsJson: organizationDetailsJson }).save().then(function (billingDetail) {
            var anniversary = new Date();
            anniversary.setUTCHours(0);
            anniversary.setUTCMinutes(0);
            anniversary.setUTCSeconds(0);
            anniversary.setUTCMilliseconds(0);
            var period = 'month';
            var saveBillingCyclePromise = _this.store.createRecord('billing-cycle', { billingDetail: billingDetail, anniversary: anniversary, period: period }).save();

            var _user$getProperties2 = user.getProperties('name', 'email');

            var name = _user$getProperties2.name;
            var email = _user$getProperties2.email;

            var saveBillingContactPromise = _this.store.createRecord('billing-contact', { billingDetail: billingDetail, name: name, email: email }).save();

            return _ember['default'].RSVP.all([saveBillingCyclePromise, saveBillingContactPromise]);
          });
        }).then(function () {
          return _this.bootstrapAccount(organization);
        }).then(function () {
          var _user$getProperties3 = user.getProperties('email', 'name');

          var email = _user$getProperties3.email;
          var name = _user$getProperties3.name;

          var orgProperties = organization.getProperties('id', 'name');
          return (0, _dieselUtilsHubspot.submitHubspotForm)(name, email, orgProperties.name, orgProperties.id);
        }).then(function () {
          var currentUser = _this.session.get('currentUser');
          var roles = _this.session.get('currentUser.roles');
          // Roles are used to determine the visibility of nav items once
          // signup is complete.  We should force a reload here since
          // organization#create bootstraps several roles for this user.
          // We dont' need to wait on these since they won't be used
          // immediately.

          if (roles.get('isFulfilled')) {
            roles.reload();
          }

          var event = {
            organization_id: organization.get('id'),
            organization_name: organization.get('name'),
            created_by_user_name: currentUser.get('name'),
            created_by_user_email: currentUser.get('email'),
            created_by_user_id: currentUser.get('id')
          };

          _this.get('analytics').track(ORGANIZATION_CREATED_EVENT_NAME, event);
          _this.get('analytics').aptibleEvent('signup', currentUser.get('id'));
          _this.transitionTo('verify-email');
        })['catch'](function (e) {
          return _this.get('flashMessages').danger('There was an error: ' + e.message);
        })['finally'](function () {
          return _this.controller.set('isSaving', false);
        });
      }
    }
  });
});