define('diesel/components/modal-create-asset/component', ['exports', 'ember', 'diesel/mixins/components/modal-base', 'diesel/mixins/components/resizable-modal', 'diesel/models/component', 'diesel/models/system'], function (exports, _ember, _dieselMixinsComponentsModalBase, _dieselMixinsComponentsResizableModal, _dieselModelsComponent, _dieselModelsSystem) {

  Error.stackTraceLimit = 999;
  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsResizableModal['default'], _dieselMixinsComponentsModalBase['default'], {
    createAssetModal: null,
    store: _ember['default'].inject.service(),
    fixedWindowSelector: ".empty-modal-body",

    systemTemplates: _ember['default'].computed.alias('createAssetModal.systemTemplates'),
    organizationProfile: _ember['default'].computed.alias('createAssetModal.organizationProfile'),
    roles: _ember['default'].computed.alias('createAssetModal.roles'),

    externalSystems: _ember['default'].computed('systemTemplates.[]', function () {
      return this.get('systemTemplates').filter(function (st) {
        return !st.get('isDevice');
      });
    }),

    devices: _ember['default'].computed.filterBy('systemTemplates', 'isDevice'),
    systemTemplateName: _ember['default'].computed('systemType', function () {
      return _dieselModelsSystem.SYSTEM_TYPES.findBy('handle', this.get('systemType'));
    }),

    titleDescription: _ember['default'].computed('componentType', 'appType', 'systemType', 'system', 'component', function () {
      var _getProperties = this.getProperties('appType', 'systemType', 'component', 'system');

      var appType = _getProperties.appType;
      var systemType = _getProperties.systemType;
      var component = _getProperties.component;
      var system = _getProperties.system;

      var title = '';

      var description = 'Assets are components of your information security management that need to be classified, secured and assessed for risk.  What kind of asset are you adding?';

      if (systemType) {
        var systemConfig = _dieselModelsSystem.SYSTEM_TYPES.findBy('handle', systemType);
        title = 'Add a New ' + systemConfig.name + ' to Gridiron';
        description = systemConfig.tooltip;
      } else if (appType) {
        title = 'Add a New App to Gridiron';
      } else if (component) {
        title = 'Add a New ' + component.get('readableType') + ' to Gridiron';
        description = component.get('readableTypeDescription');
      } else if (system) {
        title = 'Add ' + system.get('name') + ' to Gridiron';
        description = system.get('description');
      } else {
        title = 'Add an Asset to Gridiron';
      }
      return { title: title, description: description };
    }),

    title: _ember['default'].computed.reads('titleDescription.title'),
    description: _ember['default'].computed.reads('titleDescription.description'),

    getInitialState: function getInitialState() {
      return { systemTemplate: null,
        appType: null,
        systemType: null,
        componentType: null,
        component: null,
        system: null };
    },

    appTypes: _ember['default'].computed(function () {
      return _dieselModelsComponent.COMPONENT_TYPES.filterBy('app');
    }),

    systemsOfSelectedType: _ember['default'].computed('systemTemplates.[]', 'systemType', function () {
      return this.get('systemTemplates').filterBy('type', this.get('systemType')).sortBy('name');
    }),

    _validateComponent: function _validateComponent(component) {
      var _component$getProperties = component.getProperties('handle', 'ownerRoleUrl');

      var handle = _component$getProperties.handle;
      var ownerRoleUrl = _component$getProperties.ownerRoleUrl;

      if (!handle) {
        return 'A handle is required.';
      }
      if (!ownerRoleUrl) {
        return 'An owner is reqired.';
      }
    },

    _validateSystem: function _validateSystem(system) {
      var _system$getProperties = system.getProperties('name', 'ownerRoleUrl');

      var name = _system$getProperties.name;
      var ownerRoleUrl = _system$getProperties.ownerRoleUrl;

      if (!name) {
        return 'A system is required.';
      }
      if (!ownerRoleUrl) {
        return 'An owner is reqired.';
      }
    },

    actions: {
      clearMessages: function clearMessages() {
        this.set('errors', null);
      },

      startOver: function startOver() {
        this.setProperties(this.getInitialState());
      },

      cancel: function cancel() {
        this.sendAction('dismiss');
      },

      saveComponent: function saveComponent(component) {
        var _this = this;

        this.set('errors', null);

        var errors = this._validateComponent(component);

        if (errors) {
          this.set('errors', errors);
          return;
        }

        component.save().then(function () {
          _this.sendAction('onAssetCreate', component);
        })['catch'](function (e) {
          var message = e.message || 'Something went wrong!';
          _this.set('errors', message);
        });
      },

      saveSystem: function saveSystem(system) {
        var _this2 = this;

        this.set('errors', null);
        var errors = this._validateSystem(system);
        if (errors) {
          this.set('errors', errors);
          return;
        }

        system.save().then(function () {
          _this2.sendAction('onAssetCreate', system);
        })['catch'](function (e) {
          var message = e.message || 'Something went wrong!';
          _this2.set('errors', message);
        });
      },

      setSystemType: function setSystemType(systemType) {
        var state = this.getInitialState();
        this.setProperties(Object.assign(state, { systemType: systemType }));
      },

      setSystemTemplate: function setSystemTemplate(systemTemplate) {
        var state = this.getInitialState();
        var organizationProfile = this.get('createAssetModal.organizationProfile');
        var system = systemTemplate.createSystem(organizationProfile, this.get('store'));
        this.setProperties(Object.assign(state, { systemTemplate: systemTemplate, system: system }));
      },

      setAppType: function setAppType(appType) {
        var state = this.getInitialState();
        this.setProperties(Object.assign(state, { appType: appType }));
      },

      setComponentType: function setComponentType(componentType) {
        var state = this.getInitialState();
        var organizationProfile = this.get('createAssetModal.organizationProfile');
        var component = this.get('store').createRecord('component', { organizationProfile: organizationProfile, type: componentType });

        this.setProperties(Object.assign(state, { componentType: componentType, component: component }));
      }
    }
  });
});