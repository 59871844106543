define('diesel/models/image', ['exports', 'ember', 'ember-data', 'diesel/models/base'], function (exports, _ember, _emberData, _dieselModelsBase) {
  exports['default'] = _dieselModelsBase['default'].extend({
    gitRef: _emberData['default'].attr('string'),
    app: _emberData['default'].belongsTo('app', { async: true }),
    scan: _emberData['default'].attr('string'),
    operations: _emberData['default'].hasMany('operation', { async: true }),
    exposedPorts: _emberData['default'].attr(),

    defaultExposedPort: _ember['default'].computed('exposedPorts', function () {
      var exposedPorts = this.get('exposedPorts');
      if (!exposedPorts || exposedPorts.length < 1) {
        return null;
      }

      // JS sort is lexicographical, like Docker's.
      return exposedPorts.sort()[0];
    })
  });
});