define('diesel/models/intrusion-detection-report', ['exports', 'ember-data', 'diesel/models/base', 'ember'], function (exports, _emberData, _dieselModelsBase, _ember) {
  exports['default'] = _dieselModelsBase['default'].extend({
    filename: _emberData['default'].attr('string'),
    startsAt: _emberData['default'].attr('iso-8601-timestamp'),
    endsAt: _emberData['default'].attr('iso-8601-timestamp'),
    createdAt: _emberData['default'].attr('iso-8601-timestamp'),

    stack: _emberData['default'].belongsTo('stack', { async: true }),

    pdfDownloadUrl: _ember['default'].computed.alias('links.download_pdf'),
    csvDownloadUrl: _ember['default'].computed.alias('links.download_csv')
  });
});