define("diesel/models/token", ["exports", "ember-data", "diesel/models/base"], function (exports, _emberData, _dieselModelsBase) {
  exports["default"] = _dieselModelsBase["default"].extend({
    accessToken: _emberData["default"].attr('string'),
    scope: _emberData["default"].attr('string'),
    expiresAt: _emberData["default"].attr('iso-8601-timestamp'),
    user: _emberData["default"].belongsTo('user', { async: true, requireReload: true, inverse: 'tokens' }),
    actor: _emberData["default"].belongsTo('user', { async: true, requireReload: true, inverse: null }),
    organization: _emberData["default"].belongsTo('organization', { async: true, requireReload: true, inverse: null }),
    rawPayload: _emberData["default"].attr('string'), // TODO: Probably don't need this anymore!

    expiresInRelativeToNow: function expiresInRelativeToNow() {
      return Math.floor((this.get('expiresAt') - new Date()) / 1000);
    },

    credentialsForTokenExchange: function credentialsForTokenExchange() {
      return {
        grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
        actor_token_type: 'urn:ietf:params:oauth:token-type:jwt',
        actor_token: this.get('accessToken'),
        subject_token_type: 'aptible:user:href',
        subject_token: this.get('user.links.self'),
        scope: this.get('scope'),
        expires_in: this.expiresInRelativeToNow()
      };
    }
  });
});