define('diesel/security-assessments/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var organization = this.get('complianceStatus.organization');
      var organizationProfile = this.modelFor('gridiron-admin');
      var currentOrganizationRiskProfile = organizationProfile.get('currentRiskProfile');
      var systems = organizationProfile.get('systems');
      var components = organizationProfile.get('components');

      return _ember['default'].RSVP.hash({
        currentOrganizationRiskProfile: currentOrganizationRiskProfile, organizationProfile: organizationProfile, organization: organization,
        systems: systems, components: components
      });
    },

    afterModel: function afterModel(model) {
      model.systems.forEach(function (s) {
        return s.get('currentRiskProfile');
      });
      model.components.forEach(function (s) {
        return s.get('currentRiskProfile');
      });
    },

    setupController: function setupController(controller, model) {
      controller.setProperties(model);

      if (model.currentOrganizationRiskProfile) {
        controller.set('securityControlCategories', model.currentOrganizationRiskProfile.get('securityControlCategories'));
      }
    },

    title: function title() {
      var organization = this.get('complianceStatus.organization');
      return 'Security Assessments - Gridiron Admin - ' + organization.get('name');
    },

    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);

      this.render('sidebars/engine-sidebar', {
        into: 'security-assessments',
        outlet: 'sidebar',
        controller: this.controllerFor('gridiron-organization')
      });
    }
  });
});