define('diesel/components/c-extract-metrics-for-account/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    account: null,
    control: null,
    metrics: _ember['default'].computed.reads('customerControl.metrics'),
    customerControl: _ember['default'].computed('account', 'control', function () {
      return this.get('control.customerControls').findBy('account.id', this.get('account.id'));
    })
  });
});