define('diesel/initializers/authorization', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(application) {
    application.inject('controller', 'authorization', 'service:authorization');
    application.inject('component', 'authorization', 'service:authorization');
    application.inject('route', 'authorization', 'service:authorization');
  }

  exports['default'] = {
    name: 'authorization-service',
    initialize: initialize
  };
});