define('diesel/components/c-visibility-is-account-in-scope/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    account: null,
    currentlySelectedScope: null,
    isChecked: _ember['default'].computed('account.id', 'currentlySelectedScope', function () {
      var currentlySelectedScope = this.get('currentlySelectedScope');
      return currentlySelectedScope && currentlySelectedScope.split(',').includes(this.get('account.id'));
    })
  });
});