define('diesel/organization/pending-invitations/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.modelFor('organization');
    },

    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('authorizationContext', model);
      controller.set('organization', model.get('organization'));
    },

    actions: {
      resendInvitation: function resendInvitation(invitation) {
        var _this = this;

        var reset = this.store.createRecord('reset');
        reset.setProperties({
          type: 'invitation',
          invitationId: invitation.get('id')
        });

        reset.save().then(function () {
          var message = 'Invitation resent to ' + invitation.get('email');
          _this.transitionTo('organization.pending-invitations');
          _ember['default'].get(_this, 'flashMessages').success(message);
        });
      },

      destroyInvitation: function destroyInvitation(invitation) {
        var _this2 = this;

        invitation.destroyRecord().then(function () {
          var message = 'Deleted invitation for ' + invitation.get('email');
          _this2.transitionTo('organization.pending-invitations');
          _ember['default'].get(_this2, 'flashMessages').success(message);
        });
      },

      // sent by the bs-alert component when it is dismissed
      clearSuccessMessage: function clearSuccessMessage() {
        this.controller.set('successMessage', null);
      }
    }
  });
});