define('diesel/components/otp-status/component', ['exports', 'ember'], function (exports, _ember) {

  var makeComputedToggle = function makeComputedToggle(enabledValue, disabledValue) {
    return _ember['default'].computed('otpEnabled', function () {
      var otpEnabled = this.get("otpEnabled");
      return otpEnabled ? enabledValue : disabledValue;
    });
  };

  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    label: makeComputedToggle('Enabled', 'Disabled'),
    color: makeComputedToggle('success', 'danger'),
    icon: makeComputedToggle('check', 'times')
  });
});