define('diesel/components/report-section-progress/component', ['exports', 'ember'], function (exports, _ember) {
  var LABEL_VISIBLE_WIDTH = 160;

  exports.LABEL_VISIBLE_WIDTH = LABEL_VISIBLE_WIDTH;
  exports['default'] = _ember['default'].Component.extend({
    implementedControls: _ember['default'].computed.filterBy('securityControls', 'status', 'implemented'),
    unimplementedControls: _ember['default'].computed.filterBy('securityControls', 'status', 'unimplemented'),
    unconfiguredControls: _ember['default'].computed.filterBy('securityControls', 'status', 'unconfigured'),
    totalControls: _ember['default'].computed.reads('securityControls.length'),

    implementedPercent: _ember['default'].computed('implementedControls.length', 'totalControls', function () {
      var implemented = this.get("implementedControls.length") || 0;
      var total = this.get('totalControls') || 0;

      if (total === 0) {
        return "0%";
      }

      return implemented / total * 100 + "%";
    }),

    unimplementedPercent: _ember['default'].computed('unimplementedControls.length', 'totalControls', function () {
      var unimplemented = this.get("unimplementedControls.length") || 0;
      var total = this.get('totalControls') || 0;

      if (total === 0) {
        return "0%";
      }

      return unimplemented / total * 100 + "%";
    }),

    unconfiguredPercent: _ember['default'].computed('unconfiguredControls.length', 'totalControls', function () {
      var unconfigured = this.get("unconfiguredControls.length") || 0;
      var total = this.get('totalControls') || 0;

      if (total === 0) {
        return "0%";
      }

      return unconfigured / total * 100 + "%";
    }),

    implementedProgress: _ember['default'].computed('implementedPercent', function () {
      var total = this.get('totalControls');
      var implemented = this.get("implementedControls.length") || 0;

      return implemented + ' of ' + total + ' security controls implemented';
    }),

    unimplementedProgress: _ember['default'].computed('unimplementedPercent', function () {
      var total = this.get('totalControls');
      var unimplemented = this.get("unimplementedControls.length") || 0;

      return unimplemented + ' of ' + total + ' security controls unimplemented.';
    }),

    unconfiguredProgress: _ember['default'].computed('unconfiguredPercent', function () {
      var total = this.get('totalControls');
      var unconfigured = this.get("unconfiguredControls.length") || 0;

      return unconfigured + ' of ' + total + ' security controls unconfigured.';
    }),

    // Anytime this renders, look at each column and determine if the label should
    // be visible for the given colum
    didRender: function didRender() {
      this._super.apply(this, arguments);
      this.$('.report__report-heading__progress__bar-column').each(function (index, column) {
        var $column = $(column);
        var showLabel = $column.width() >= LABEL_VISIBLE_WIDTH;

        $column.toggleClass('show-label', showLabel);
      });
    }
  });
});