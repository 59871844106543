define("diesel/services/elevation", ["exports", "ember", "diesel/utils/tokens"], function (exports, _ember, _dieselUtilsTokens) {

  var ELEVATED_SCOPE = "elevated";

  exports["default"] = _ember["default"].Service.extend({
    session: _ember["default"].inject.service(),
    authorization: _ember["default"].inject.service(),
    store: _ember["default"].inject.service(),

    isElevated: function isElevated() {
      var currentTokenScope = this.get("session.token.scope");
      return currentTokenScope === ELEVATED_SCOPE;
    },

    tryDropPrivileges: function tryDropPrivileges(transition) {
      var _this = this;

      if (!this.isElevated()) {
        // Current token is not elevated. There are no privileges to drop.
        return;
      }

      if (transition.targetName.indexOf("requires-elevation") >= 0) {
        // We're navigating to a route that requires elevation. Don't drop privileges.
        return;
      }

      if (this.persistedTokenIsElevated) {
        // We're in an odd situation where the persisted token is elevated. This
        // is not ideal, and should not happen (that's a security concern,
        // handled by specifically not persisting the elevated token), but we
        // need to keep going; otherwise we're going to just hang the UI.
        // TODO: Maybe we should just delete the token and reload?
        return;
      }

      // Note: We'd like to just return a promise before the transition happens,
      // but that's not possible, so instead we'll abort the transition, and then
      // retry it as soon as we've swapped out the token.
      transition.abort();

      return this.restorePersistedToken().then(function () {
        var authorizationService = _this.get("authorization");
        // We need to reset authorization because if the persisted token is SSO
        // then our authorizations will change on dropping the elevated "user"
        // token
        return authorizationService.load();
      }).then(function () {
        transition.retry();
      });
    },

    reopenSession: function reopenSession(tokenPayload, deleteCurrentToken) {
      var sessionService = this.get("session");
      var options = undefined;

      if (deleteCurrentToken) {
        options = { noClearCookies: true, token: sessionService.get('token') };
      } else {
        options = { noDelete: true };
      }

      return sessionService.close('application', options).then(function () {
        return sessionService.open('application', { tokenPayload: tokenPayload });
      });
    },

    createElevatedToken: function createElevatedToken(credentials) {
      var _this2 = this;

      // NOTE: Toggle noPersist below if you're developing on the settings page
      // and don't want to have to input your password (and possibly 2FA token)
      // over and over again.
      credentials.scope = ELEVATED_SCOPE;
      credentials.expires_in = 30 * 60; // 30 minutes.
      return (0, _dieselUtilsTokens.createToken)(credentials, { noPersist: true }).then(function (tokenPayload) {
        return _this2.reopenSession(tokenPayload, false);
      });
    },

    restorePersistedToken: function restorePersistedToken() {
      var _this3 = this;

      // Restores a regular token from the session.
      return (0, _dieselUtilsTokens.getPersistedToken)().then(function (tokenPayload) {
        if (tokenPayload.scope === ELEVATED_SCOPE) {
          _this3.persistedTokenIsElevated = true;
        }

        return _this3.reopenSession(tokenPayload, true);
      });
    }
  });
});