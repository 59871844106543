define('diesel/components/hash-navigation/component', ['exports', 'ember'], function (exports, _ember) {
  var run = _ember['default'].run;
  exports['default'] = _ember['default'].Component.extend({
    _pendingTimer: null,

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this._pendingTask = run.schedule('afterRender', function () {
        var elementId = location.hash;
        var element = _this.$(elementId)[0];

        if (element) {
          element.scrollIntoView();
        }
      });
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this._pendingTask) {
        run.cancel(this._pendingTask);
      }
    }
  });
});