define('diesel/components/risk-graph-editor/component', ['exports', 'ember', 'diesel/utils/filter-risk-components'], function (exports, _ember, _dieselUtilsFilterRiskComponents) {

  var PLACEHOLDERS = {
    securityControls: 'E.g. multi-factor authentication',
    predisposingConditions: 'E.g. bring your own device'
  };

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['risk-graph-editor', 'panel', 'panel--with-nav'],

    // Filters
    category: null,
    status: null,
    relevant: null,
    search: null,

    componentTitle: _ember['default'].computed('type', function () {
      return this.get('type').dasherize().replace('-', ' ').capitalize();
    }),

    placeholder: _ember['default'].computed('type', function () {
      return PLACEHOLDERS[this.get('type')];
    }),

    actions: {
      setFilter: function setFilter(filterName, filterValue) {
        if (filterValue === this.get(filterName)) {
          // Unset if selecting status again
          filterValue = null;
        }

        this.set(filterName, filterValue);
      },

      setCategory: function setCategory(category) {
        if (category === this.get('category')) {
          category = null;
        }

        this.set('category', category);
      },

      editComponent: function editComponent(component) {
        this.sendAction('editComponent', component);
      },

      resetFilters: function resetFilters() {
        this.setProperties({
          category: null, status: null,
          relevant: null, search: null
        });
      }
    },

    filteredComponents: _ember['default'].computed('riskAssessment', 'category', 'status', 'relevant', 'search', function () {
      var _getProperties = this.getProperties(['type', 'category', 'status', 'relevant', 'search']);

      var type = _getProperties.type;
      var category = _getProperties.category;
      var status = _getProperties.status;
      var relevant = _getProperties.relevant;
      var search = _getProperties.search;

      var sort = 'title';
      var components = this.get('riskAssessment.' + type).reject(function (c) {
        return !c.get('title');
      });
      var filters = { category: category, search: search, status: status, relevant: relevant, sort: sort };
      var filteredComponents = (0, _dieselUtilsFilterRiskComponents['default'])(components, filters);

      if (type === 'securityControls') {
        var _relevant = filteredComponents.filterBy('relevant').sortBy('title');
        var notrelevant = filteredComponents.filterBy('relevant', false).sortBy('title');
        filteredComponents = _relevant.concat(notrelevant);
      }

      return filteredComponents;
    })
  });
});