define('diesel/certificates/route', ['exports', 'ember', 'diesel/utils/ignore-invalid-error', 'diesel/utils/fetch-all-pages'], function (exports, _ember, _dieselUtilsIgnoreInvalidError, _dieselUtilsFetchAllPages) {
  exports['default'] = _ember['default'].Route.extend({
    modalDisplay: _ember['default'].inject.service(),

    title: function title() {
      var account = this.modelFor('account');
      return account.get('handle') + ' Certificates';
    },

    model: function model() {
      var account = this.modelFor('account');
      return (0, _dieselUtilsFetchAllPages['default'])(this.store, account, 'certificate');
    },

    setupController: function setupController(controller, model) {
      var account = this.modelFor('account');
      controller.setProperties({ model: model, account: account });
    },

    actions: {
      openUploadCertificateModal: function openUploadCertificateModal() {
        var account = this.modelFor('account');
        var newCertificate = this.store.createRecord('certificate', { account: account });

        var modalDisplay = this.get('modalDisplay');
        modalDisplay.showModal('modal-create-certificate', { newCertificate: newCertificate, account: account }, {
          actions: {
            onCreateCertificate: modalDisplay.modalAction(this, 'onCreateCertificate')
          }
        });
      },

      onCreateCertificate: function onCreateCertificate(certificate) {
        var _this = this;

        _ember['default'].assert('Certificate must have account with id', !!certificate.get('account.id'));
        return certificate.save().then(function () {
          var message = certificate.get('commonName') + ' created.';
          _this.refresh();
          _ember['default'].get(_this, 'flashMessages').success(message);
        })['catch'](_dieselUtilsIgnoreInvalidError['default']);
      },

      'delete': function _delete(model) {
        var _this2 = this;

        // Confirm...
        var confirmMsg = '\nAre you sure you want to delete ' + model.get('commonName') + '?\n';
        if (!confirm(confirmMsg)) {
          return false;
        }

        var account = model.get('account');

        model.deleteRecord();
        model.save().then(function () {
          var message = model.get('commonName') + ' certificate destroyed';

          _this2.transitionTo('certificates', account);
          _ember['default'].get(_this2, 'flashMessages').success(message);
        });
      }
    }
  });
});