define('diesel/components/modal-vendor-agreement/component', ['exports', 'ember', 'diesel/mixins/components/resizable-modal', 'diesel/mixins/components/modal-base'], function (exports, _ember, _dieselMixinsComponentsResizableModal, _dieselMixinsComponentsModalBase) {
  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsResizableModal['default'], _dieselMixinsComponentsModalBase['default'], {
    actions: {
      onAgreementUpdate: function onAgreementUpdate() {
        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        this.sendAction.apply(this, ['onAgreementUpdate'].concat(args));
      }
    }
  });
});