define('diesel/assets/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    modalDisplay: _ember['default'].inject.service(),

    setupImportables: function setupImportables() {
      var _this = this;

      this.set('productionApps', []);
      this.set('productionDatabases', []);

      var productionAccounts = this.get('productionAccounts');
      var components = this.get('components');

      productionAccounts.forEach(function (productionAccount) {
        productionAccount.get('apps').then(function (apps) {
          var productionApps = _this.get('productionApps');
          apps.forEach(function (app) {
            var href = app.get('links.self');
            var component = components.findBy('appUrl', href);
            if (component) {
              app.set('component', component);
            }
          });

          productionApps = productionApps.concat(apps);
          _this.set('productionApps', productionApps);
        });

        productionAccount.get('databases').then(function (dbs) {
          var productionDatabases = _this.get('productionDatabases');

          dbs.forEach(function (db) {
            var href = db.get('links.self');
            var component = components.findBy('databaseUrl', href);
            if (component) {
              db.set('component', component);
            }
          });

          productionDatabases = productionDatabases.concat(dbs);
          _this.set('productionDatabases', productionDatabases);
        });
      });
    },

    importApps: function importApps(_ref, type) {
      var organizationProfile = _ref.organizationProfile;
      var productionAccounts = _ref.productionAccounts;
      var systemTemplates = _ref.systemTemplates;

      this.get('modalDisplay').showModal('modal-import-system-components', {
        modalImportAppsProperties: {
          organizationProfile: organizationProfile, productionAccounts: productionAccounts, type: type, systemTemplates: systemTemplates
        }
      });
    }
  });
});