define('diesel/gridiron-settings/systems/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    modalDisplay: _ember['default'].inject.service(),

    model: function model() {
      var organizationProfile = this.get('complianceStatus.organizationProfile');
      return _ember['default'].RSVP.hash({
        organizationProfile: organizationProfile,
        systems: organizationProfile.get('systems'),
        systemTemplates: this.store.findAll('system-template')
      });
    },

    setupController: function setupController(controller, model) {
      controller.setProperties(model);
    },

    openSystemModal: function openSystemModal(modalParams) {
      var modalDisplay = this.get('modalDisplay');
      modalDisplay.showModal('modal-create-system', { createSystemModal: modalParams }, {
        actions: {
          onSystemUpdate: modalDisplay.modalAction(this, 'onSystemUpdate')
        }
      });
    },

    actions: {
      editSystem: function editSystem(system) {
        var organizationProfile = this.get('complianceStatus.organizationProfile');

        var _get$getProperties = this.get('complianceStatus.authorizationContext').getProperties('roles', 'users');

        var roles = _get$getProperties.roles;
        var users = _get$getProperties.users;

        this.openSystemModal({ system: system, roles: roles, users: users, organizationProfile: organizationProfile });
      },

      createSystem: function createSystem(template) {
        var organizationProfile = this.get('complianceStatus.organizationProfile');
        var system = template.createSystem(organizationProfile, this.store);

        var _get$getProperties2 = this.get('complianceStatus.authorizationContext').getProperties('roles', 'users');

        var roles = _get$getProperties2.roles;
        var users = _get$getProperties2.users;

        this.openSystemModal({ system: system, roles: roles, users: users, organizationProfile: organizationProfile });
      },

      onSystemUpdate: function onSystemUpdate(system) {
        var organizationProfile = this.get('complianceStatus.organizationProfile');
        var message = system.get('name') + ' configured successfully!';
        this.get('flashMessages').success(message);

        organizationProfile.reloadCurrentSecurityReview();
        organizationProfile.observeDocumentBuilds();
      }
    }
  });
});