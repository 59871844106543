define('diesel/mixins/components/modal-base', ['exports', 'ember', 'ember-spread'], function (exports, _ember, _emberSpread) {
  exports['default'] = _ember['default'].Mixin.create(_emberSpread['default'], {
    modalDisplay: _ember['default'].inject.service(),

    init: function init() {
      this._super.apply(this, arguments);

      var modalDisplay = this.get('modalDisplay');
      modalDisplay.setBeforeDismissHandler(this, 'beforeDismiss');
    },

    actions: {
      beforeDismiss: function beforeDismiss() {
        // no-op, can be overriden for special clean-up before the modal is
        // dismissed
      },

      /**
       * This action makes it possible to do `{{action "dismissModal"}}`
       * In the template of a component that mixes in ModalBase
       */
      dismissModal: function dismissModal() {
        this.get('modalDisplay').dismissModal();
      }
    }
  });
});