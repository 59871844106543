define('diesel/security-assessment/security-controls/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    setupController: function setupController(controller) {
      var assessment = this.modelFor('security-assessment');
      var resource = assessment.resource;
      var scope = assessment.scope;
      var handle = assessment.handle;

      var riskProfile = resource.get('currentRiskProfile');
      var category = undefined;

      if (scope === 'organization') {
        category = riskProfile.get('securityControlCategories').findBy('handle', handle);
      }

      controller.setProperties({ resource: resource, scope: scope, category: category, riskProfile: riskProfile });
    }
  });
});