define('diesel/components/payment-form/component', ['exports', 'ember'], function (exports, _ember) {

  var PaymentFields = ['paymentMethod', 'cashValue'];

  exports['default'] = _ember['default'].Component.extend({
    payFull: true,
    disabled: false,
    paymentMethods: null,

    paymentMethod: null,
    cashValue: null,

    changeObserver: _ember['default'].observer.apply(_ember['default'], PaymentFields.concat([function () {
      _ember['default'].run.once(this, 'trySendUpdate');
    }])),

    trySendUpdate: function trySendUpdate() {
      var fields = this.getProperties(PaymentFields);
      this.sendAction('update', fields);
    }
  });
});