define('diesel/utils/link-component-extension', ['exports', 'diesel/config/environment'], function (exports, _dieselConfigEnvironment) {
  var environment = _dieselConfigEnvironment['default'].environment;

  var ADDITONAL_BINDINGS = [];

  if (environment !== 'production') {
    ADDITONAL_BINDINGS.push('data-test');
  }

  exports['default'] = {
    attributeBindings: ADDITONAL_BINDINGS
  };
});