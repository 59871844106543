define('diesel/models/role', ['exports', 'ember-data', 'diesel/models/base', 'ember', 'diesel/models/organization-profile'], function (exports, _emberData, _dieselModelsBase, _ember, _dieselModelsOrganizationProfile) {
  var computed = _ember['default'].computed;

  var Role = _dieselModelsBase['default'].extend({
    name: _emberData['default'].attr(),
    type: _emberData['default'].attr({ defaultValue: 'platform_user' }),
    organization: _emberData['default'].belongsTo('organization', { async: true }),
    memberships: _emberData['default'].hasMany('membership', { async: true }),
    invitations: _emberData['default'].hasMany('invitations', { async: true }),
    permissions: _emberData['default'].hasMany('permissions', { async: true }),
    users: _emberData['default'].hasMany('users', { async: true }),
    description: computed('name', function () {
      var desc = null;
      var name = this.get('name');

      Object.keys(_dieselModelsOrganizationProfile.ROLE_DESIGNATIONS).forEach(function (designation) {
        var options = _dieselModelsOrganizationProfile.ROLE_DESIGNATIONS[designation];
        if (options.name === name) {
          desc = options.description;
        }
      });

      return desc;
    }),

    isComplianceRole: _ember['default'].computed.match('type', /compliance/),
    isPlatformRole: _ember['default'].computed.match('type', /platform/),

    isAccountOwner: _ember['default'].computed.equal('type', 'owner'),
    isComplianceOwner: _ember['default'].computed.equal('type', 'compliance_owner'),
    isPlatformOwner: _ember['default'].computed.equal('type', 'platform_owner'),
    isComplianceUser: _ember['default'].computed.equal('type', 'compliance_user'),
    isPlatformUser: _ember['default'].computed.equal('type', 'platform_user'),

    isOwner: _ember['default'].computed.or('isAccountOwner', 'isPlatformOwner', 'isComplianceOwner'),
    isUser: _ember['default'].computed.or('isPlatformUser', 'isComplianceUser'),

    isPlatform: _ember['default'].computed.or('isPlatformOwner', 'isPlatformUser'),
    isCompliance: _ember['default'].computed.or('isComplianceOwner', 'isComplianceUser'),

    isComplianceOnly: _ember['default'].computed.or('isComplianceOwner', 'isComplianceUser'),
    isPlatformOnly: _ember['default'].computed.or('isPlatformOwner', 'isPlatformUser'),
    privileged: _ember['default'].computed.deprecatingAlias('isOwner'),
    persistedInvitations: _ember['default'].computed.filterBy('invitations', 'isNew', false)
  });

  Role.reopenClass({
    // Find or create will attempt to find a matching role given a particular
    // organization and some params.  If a matching role does not exist, one is
    // created. `name`, `type`, and `organization` are requried params.
    findOrCreate: function findOrCreate(params, store) {
      _ember['default'].assert('You must provide an organization to `Role#findOrCreate`', params.organization);
      _ember['default'].assert('You must provide a type to `Role#findOrCreate`', params.type);
      _ember['default'].assert('You must provide a name to `Role#findOrCreate`', params.name);

      return new _ember['default'].RSVP.Promise(function (resolve) {
        params.organization.get('roles').then(function (roles) {
          var role = roles.find(function (role) {
            if (params.name && role.get('name') !== params.name) {
              return false;
            }

            if (params.type && role.get('type') !== params.type) {
              return false;
            }

            return true;
          });

          if (role) {
            resolve(role);
          } else {
            resolve(store.createRecord('role', params));
          }
        });
      });
    }
  });

  exports['default'] = Role;
});