define('diesel/serializers/vhost', ['exports', 'diesel/serializers/application'], function (exports, _dieselSerializersApplication) {
  exports['default'] = _dieselSerializersApplication['default'].extend({
    keyForRelationship: function keyForRelationship(key) {
      // This overrides the RESTSerializer's preference to add suffix "_id"
      // for the key of a `belongsTo` relationship
      if (key === 'certificate') {
        return 'certificate';
      }

      // TODO-ember-data Should not need a `this._super` check, should always be `_super`.
      // This is here because HalAdapter incorrectly breaks the inheritance chain
      // when it peels off this method before calling it:
      // https://github.com/201-created/ember-data-hal-9000/blob/de66dceb68a39ee868f226d14edfdc323cce0556/addon/mixin.js#L225-L227
      if (this._super) {
        return this._super.apply(this, arguments);
      } else {
        return key;
      }
    },

    keyForAttribute: function keyForAttribute(attrName /* , method */) {
      if (attrName === 'isAcme') {
        return 'acme';
      }
      if (attrName === 'isDefault') {
        return 'default';
      }
      return this._super.apply(this, arguments);
    },

    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);
      var cert = snapshot.belongsTo('certificate');
      var cert_href = cert && cert.record && cert.record.get('links.self');

      if (cert_href) {
        json.certificate = cert_href;
      }

      return json;
    }
  });
});