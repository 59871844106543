define('diesel/assignment/course/material/module/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    assessmentError: null,
    actions: {
      onNext: function onNext(form) {
        this.send('next', { form: form });
      },
      onPrevious: function onPrevious() {
        this.send('previous');
      },
      onSaveAndExit: function onSaveAndExit() {
        this.send('saveAndExit');
      }
    }
  });
});