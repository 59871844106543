define('diesel/transitions', ['exports', 'diesel/config/environment'], function (exports, _dieselConfigEnvironment) {
  var DEFAULT_EASING = 'easeInOut';

  var TRANSITION_DURATION = _dieselConfigEnvironment['default'].timeouts.transitionDuration;

  exports['default'] = function () {
    // let coreRoutes = ['account', 'apps', 'databases', 'log-drains', 'certificates',
    //                   'account.loading', 'app', 'database', 'organization.members',
    //                   'organization.admin', 'organization.roles', 'settings.profile',
    //                   'organization.pending-invitations',
    //                   'settings.ssh', 'settings.impersonate', 'organization.environments',
    //                   'organization.admin.contact-settings', 'organization.admin.billing',
    //                   'threat-sources', 'threat-events', 'vulnerabilities', 'predisposing-conditions', 'security-controls'];
    // this.transition(
    //   this.hasClass('click-to-reveal'),
    //   this.use('crossFade')
    // );

    var coreRoutes = ['policy-manual', 'policy-manual.index', 'policy-manual.policy'];
    this.transition(this.fromRoute(coreRoutes), this.use('crossFade'));

    this.transition(this.toRoute(coreRoutes), this.use('crossFade'));

    this.transition(this.toRoute(['sidebars.account']), this.outletName('sidebar'), this.use('toLeft', { duration: TRANSITION_DURATION, easing: DEFAULT_EASING }));

    this.transition(this.fromRoute(['sidebars.account']), this.outletName('sidebar'), this.use('toRight', { duration: TRANSITION_DURATION, easing: DEFAULT_EASING }));

    this.transition(this.hasClass('crossFade'), this.use('crossFade', { duration: TRANSITION_DURATION * 2, easing: DEFAULT_EASING }));
  };
});