define('diesel/components/vhost-action-required-acme/component', ['exports', 'ember', 'diesel/config/environment'], function (exports, _ember, _dieselConfigEnvironment) {

  var RENEW_TIMEOUT = _dieselConfigEnvironment['default'].timeouts.vhostRenewOperation;
  var PROVISION_TIMEOUT = _dieselConfigEnvironment['default'].timeouts.vhostProvisionOperation;

  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),
    flashMessages: _ember['default'].inject.service(),

    hasMultipleAcmeCnames: _ember['default'].computed.gt("vhost.acmeConfiguration.challenges.length", 1),

    actionLabel: _ember['default'].computed('hasMultipleAcmeCnames', function () {
      var record = 'record';
      if (this.get('hasMultipleAcmeCnames')) {
        record = 'records';
      }
      return 'I created the ' + record;
    }),

    isRenewing: false,

    actions: {
      renew: function renew() {
        var _this = this;

        var vhost = this.get("vhost");

        this.get('store').createRecord('operation', {
          type: 'renew',
          vhost: vhost
        }).save().then(function (operation) {
          _this.set('isRenewing', true);
          return operation;
        }).then(function (operation) {
          return operation.reloadUntilStatusChanged(RENEW_TIMEOUT);
        }).then(function () {
          var m = "Certificate generation succeeded; Aptible will finalize " + "your endpoint set up momentarily.";
          _this.get("flashMessages").success(m);

          // Once the renew operation completes, we'll have to wait for a
          // provision operation to complete to deploy the cert. Let's find it
          // and wait for that one.
          return vhost.reload().then(function (vhost) {
            return vhost.get("operations");
          }).then(function (operations) {
            var provisionOperation = operations.find(function (operation) {
              return operation.get("type") === "provision";
            });

            if (!provisionOperation) {
              throw new Error("Found no provision operation!");
            }

            var infoMessage = "Your endpoint is ready.";

            if (provisionOperation.get("isDone")) {
              // If the operation already succeeded, we're done.
              _this.get("flashMessages").success(infoMessage);
              return vhost.reload();
            }

            return provisionOperation.reloadUntilStatusChanged(PROVISION_TIMEOUT).then(function () {
              // Otherwise, let's wait until it completes.
              _this.get("flashMessages").success(infoMessage);
              return vhost.reload();
            });
          });
        })['catch'](function (e) {
          // Default to a generic error message, but be more specific if an
          // operation is attached.
          var errorMessage = 'An unexpected error occurred: ' + e.message;

          if (e.operation && e.operation.get("status") === "failed") {
            if (e.operation.get("type") === "renew") {
              errorMessage = "Failed to generate certificate. Please verify the CNAME " + "is set up correctly, and try again in a few minutes.";
            }
            if (e.operation.get("type") === "provision") {
              errorMessage = "Failed to install certificate. Please try again.";
            }
          }

          _this.get("flashMessages").danger(errorMessage);
        })['finally'](function () {
          // We need to be a little careful here: if our renewal succeeds, this
          // component is going to get torn down, and setting isRenewing back to
          // false could throw an exception.
          if (!(_this.get('isDestroyed') || _this.get('isDestroying'))) {
            _this.set('isRenewing', false);
          }
        });
      }
    }
  });
});