define('diesel/risk-assessment/route', ['exports', 'ember', 'diesel/mixins/routes/approvable-document-mixin'], function (exports, _ember, _dieselMixinsRoutesApprovableDocumentMixin) {
  exports['default'] = _ember['default'].Route.extend(_dieselMixinsRoutesApprovableDocumentMixin['default'], {
    titleToken: function titleToken() {
      return 'Risk Assessment';
    },

    setupController: function setupController(controller, model) {
      var organizationProfile = this.get('complianceStatus.organizationProfile');
      var approveMessage = 'Only Gridiron Owners can approve this risk assessment.';

      controller.set('model', model);
      controller.setProperties({ model: model, approveMessage: approveMessage, organizationProfile: organizationProfile });
    }
  });
});