define('diesel/organization/roles/type/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params) {
      return params.type;
    },

    setupController: function setupController(controller, model) {
      var context = this.modelFor('organization');
      var type = model;
      var userRoleTypeComputed = 'organization' + type.capitalize() + 'UserRoles';
      var adminRoleTypeComputed = 'organization' + type.capitalize() + 'AdminRoles';
      var userRoles = context.get(userRoleTypeComputed).sortBy('name');
      var adminRoles = context.get(adminRoleTypeComputed).sortBy('name');
      var ownerRoles = context.get('organizationOwnerRoles').sortBy('name');
      var accounts = context.get('accounts').sortBy('handle');

      controller.setProperties({ type: type, context: context, adminRoles: adminRoles, userRoles: userRoles, ownerRoles: ownerRoles, accounts: accounts });
    },

    redirect: function redirect(model) {
      var context = this.modelFor('organization');

      // If current type is platform but org doesn't have Enclave plan, change
      // types to compliance
      if (model === 'platform' && !context.get('organizationHasEnclaveProduct')) {
        return this.transitionTo('organization.roles.type', 'compliance');
      }

      // If current type is compliance but org doesn't have Gridiron plan, change
      // types to platform
      if (model === 'compliance' && !context.get('organizationHasGridironProduct')) {
        return this.transitionTo('organization.roles.type', 'platform');
      }
    }
  });
});