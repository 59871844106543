define('diesel/utils/ignore-invalid-error', ['exports', 'ember-data'], function (exports, _emberData) {

  /**
   * Use this handler in the `catch` method of a promise.
   * If the adapter's `handleResponse' returns a DS.InvalidError 
   * the reject hook of the promise will be entered, but in most cases
   * the existing route/template will inspect the model's `errors` property
   * and display the errors inline.
   * So in the default case we can ignore these types of errors and let the
   * template display them.
   * We need to catch them, though, because otherwise the Ember.RSVP.onerror handler
   * will take them and force a transition to the error substate.
   *
   * This export function simply wraps up this concept into a single util to
   * simplify code re-use.
   */

  exports['default'] = function (error) {
    if (!(error instanceof _emberData['default'].InvalidError)) {
      throw error;
    }
    return error;
  };
});