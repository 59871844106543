define('diesel/transitions/clean', ['exports'], function (exports) {
  // https://github.com/ember-animation/liquid-fire/issues/310
  // This is just a proxy transition that cleans up memory leaks after transition.

  exports['default'] = function (animationName) {
    var _this = this;

    animationName = animationName || 'toLeft';

    for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }

    return this.lookup(animationName).apply(this, args).then(function () {
      _this.oldElement.removeData();
    });
  };
});