define('diesel/models/vendor', ['exports', 'ember-data', 'ember', 'diesel/models/base', 'diesel/mixins/models/classification'], function (exports, _emberData, _ember, _dieselModelsBase, _dieselMixinsModelsClassification) {
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var hasMany = _emberData['default'].hasMany;
  exports['default'] = _dieselModelsBase['default'].extend({
    name: attr('string'),
    services: attr('string'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    regulatedDataTypes: _emberData['default'].attr({ defaultValue: function defaultValue() {
        return [];
      } }), // Array
    systemRegulatedDataTypes: _emberData['default'].attr({ defaultValue: function defaultValue() {
        return [];
      } }), // Array
    certifications: hasMany('certification', { async: true }),
    organizationProfile: belongsTo('organization-profile', { async: true }),
    agreements: hasMany('agreement', { async: true }),
    systems: hasMany('system', { async: true }),

    allRegulatedDataTypes: _ember['default'].computed.union('regulatedDataTypes', 'systemRegulatedDataTypes'),
    allRegulatedDataTypeNames: _ember['default'].computed('allRegulatedDataTypes.[]', function () {
      return this.get('allRegulatedDataTypes').map(function (t) {
        return _dieselMixinsModelsClassification.REGULATED_DATA_TYPES.findBy('handle', t).name;
      });
    })
  });
});