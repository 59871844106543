define('diesel/welcome/payment-info/route', ['exports', 'ember', 'diesel/utils/stripe', 'diesel/config/environment'], function (exports, _ember, _dieselUtilsStripe, _dieselConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend({
    analytics: _ember['default'].inject.service(),

    model: function model() {
      return (0, _dieselUtilsStripe.loadStripe)().then(function () {
        return null;
      });
    },

    setupController: function setupController(controller, model) {
      controller.set('model', model);
    },

    bootstrapBillingDetail: function bootstrapBillingDetail(billingDetail, creditCard) {
      if (_dieselConfigEnvironment['default'].featureFlags.skipBilling) {
        return _ember['default'].RSVP.resolve();
      }

      return billingDetail.addDefaultCreditCard(creditCard);
    },

    actions: {
      saveCreditCard: function saveCreditCard(creditCard) {
        var _this = this;

        this.controller.set('isSaving', true);

        var _modelFor = this.modelFor('welcome');

        var billingDetail = _modelFor.billingDetail;

        this.bootstrapBillingDetail(billingDetail, creditCard)['catch'](function (e) {
          _this.controller.set('error', e.message);
          _this.controller.set('isSaving', false);
          throw e;
        }).then(function () {
          var analytics = _this.get('analytics');
          var event = 'nux.signup.completed-payment-info';
          analytics.track(event);
          analytics.aptibleEvent(event);
          if (_dieselConfigEnvironment['default'].featureFlags.showFTUX) {
            window.location = _dieselConfigEnvironment['default'].aptibleHosts.appUrl + '/create';
          } else {
            _this.transitionTo('enclave');
          }
        })['finally'](function () {
          return _this.controller.set('isSaving', false);
        });
      }
    }
  });
});