define('diesel/incidents/route', ['exports', 'ember', 'diesel/utils/ignore-invalid-error'], function (exports, _ember, _dieselUtilsIgnoreInvalidError) {
  exports['default'] = _ember['default'].Route.extend({
    modalDisplay: _ember['default'].inject.service(),

    title: 'Incident Response',

    model: function model() {
      var organization = this.get('complianceStatus.organization');
      var organizationProfile = this.modelFor('gridiron-admin');
      var incidents = organizationProfile.get('incidents');

      return _ember['default'].RSVP.hash({
        organization: organization, organizationProfile: organizationProfile, incidents: incidents
      });
    },

    setupController: function setupController(controller, model) {
      controller.setProperties(model);
    },

    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);

      this.render('sidebars/engine-sidebar', {
        into: 'incidents',
        outlet: 'sidebar',
        controller: this.controllerFor('gridiron-organization')
      });
    },

    actions: {
      openCreateIncidentModal: function openCreateIncidentModal() {
        var newIncident = this.store.createRecord('incident', {
          organizationProfile: this.get('complianceStatus.organizationProfile')
        });
        var modalDisplay = this.get('modalDisplay');
        modalDisplay.showModal('modal-create-incident', { newIncident: newIncident }, {
          actions: {
            onCreateIncident: modalDisplay.modalAction(this, 'onCreateIncident')
          }
        });
      },

      onCreateIncident: function onCreateIncident(incident) {
        var _this = this;

        return incident.save().then(function () {
          _this.transitionTo('incident', incident);
          var message = incident.get('title') + ' incident created';
          _ember['default'].get(_this, 'flashMessages').success(message);
        })['catch'](_dieselUtilsIgnoreInvalidError['default']);
      }
    }
  });
});