define('diesel/components/c-framework-metrics/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: "",
    currentlySelectedFramework: null,
    metrics: _ember['default'].computed('frameworkMetrics', function () {
      var metrics = this.get('frameworkMetrics');
      return Object.keys(metrics).map(function (m) {
        return metrics[m];
      });
    }),

    actions: {
      onFrameworkSelect: function onFrameworkSelect(framework) {
        var analytics = this.get('analytics');
        var EVENT_NAME = 'framework.selected';
        analytics.track(EVENT_NAME, { framework: framework });
        analytics.aptibleEvent(EVENT_NAME, framework);
        this.attrs.onFrameworkSelect(framework);
      }
    }
  });
});