define('diesel/mixins/components/operable', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    operable: null,

    operationsManager: _ember['default'].inject.service(),

    operableIsBusy: _ember['default'].computed.gt('operableOperationsStack.length', 0),

    operableOperationsStack: _ember['default'].computed('operable.links.self', function () {
      var href = this.get('operable.links.self');
      if (!href) {
        return _ember['default'].A([]);
      }

      return this.get('operationsManager').getOperationStackByHref(href);
    }),

    isBusy: _ember['default'].computed.gt('operableOperationsStack.length', 0)
  });
});