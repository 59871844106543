define('diesel/models/assignment', ['exports', 'ember', 'ember-data', 'diesel/models/base', 'diesel/models/assignment-state'], function (exports, _ember, _emberData, _dieselModelsBase, _dieselModelsAssignmentState) {
  exports['default'] = _dieselModelsBase['default'].extend({
    userProfile: _emberData['default'].belongsTo('user-profile'),
    course: _emberData['default'].belongsTo('course', { async: true }),
    organizationProfile: _emberData['default'].belongsTo('organization-profile', { async: true }),
    userUrl: _emberData['default'].attr('string'),
    createdAt: _emberData['default'].attr('iso-8601-timestamp'),
    status: _emberData['default'].attr('string', { defaultValue: _dieselModelsAssignmentState.ASSIGNMENT_STATES.ASSIGNED }),
    statusMessage: _emberData['default'].attr('string'),
    dueAt: _emberData['default'].attr('iso-8601-timestamp'),
    completedAt: _emberData['default'].attr('iso-8601-timestamp'),
    currentModule: _emberData['default'].attr('string'),
    submissions: _emberData['default'].hasMany('submission', { async: true }),
    assignmentStates: _emberData['default'].hasMany('assignment-state', { async: true }),
    isUnassigned: _ember['default'].computed.equal('status', _dieselModelsAssignmentState.ASSIGNMENT_STATES.UNASSIGNED),
    isAssigned: _ember['default'].computed.not('isUnassigned')
  });
});