define("diesel/welcome/payment-info/controller", ["exports", "ember", "diesel/utils/location"], function (exports, _ember, _dieselUtilsLocation) {
  exports["default"] = _ember["default"].Controller.extend({
    model: null,
    isSaving: _ember["default"].computed.gt('saveProgress.currentStep', 0),
    showOrgSwitcher: _ember["default"].computed.or('authorization.hasManyOrganizations', 'authorization.hasInaccessibleOrganizations'),

    isNotSaving: _ember["default"].computed.not('isSaving'),
    canSave: _ember["default"].computed.and('isNotSaving', 'model'),
    cannotSave: _ember["default"].computed.not('canSave'),
    actions: {
      onOrgSwitch: function onOrgSwitch(organization) {
        var message = "Switched to " + organization.get('name');
        _ember["default"].get(this, 'flashMessages').success(message);
        return this.authorization.load().then(function () {
          return _dieselUtilsLocation["default"].replaceAndWait('/');
        });
      },
      doLogout: function doLogout() {
        return this.get('session').close('application', {
          token: this.get('session.token')
        })["finally"](function () {
          return _dieselUtilsLocation["default"].replaceAndWait('/');
        });
      }
    }
  });
});