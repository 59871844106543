define('diesel/security-assessment/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    modalDisplay: _ember['default'].inject.service(),

    editComponent: function editComponent(component) {
      var type = 'security-control';
      var resource = this.get('resource');
      var resourceType = this.get('scope');
      this.get('modalDisplay').showModal('modal-risk-component-editor', {
        modalRiskComponentEditorProperties: { component: component, type: type, resource: resource, resourceType: resourceType }
      });
    }
  });
});