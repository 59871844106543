define("diesel/database/metrics/scale/route", ["exports", "ember", "diesel/config/environment"], function (exports, _ember, _dieselConfigEnvironment) {

  var OPERATION_TIMEOUT = _dieselConfigEnvironment["default"].timeouts.serviceScaleOperation;

  exports["default"] = _ember["default"].Route.extend({
    titleToken: function titleToken() {
      var database = this.modelFor('database');
      return database.get('handle') + " Service";
    },

    model: function model() {
      var database = this.modelFor('database');
      var service = database.get('service');
      var disk = database.get('disk');
      return _ember["default"].RSVP.hash({
        database: database, service: service, stack: service.get('account.stack'), disk: disk
      });
    },

    setupController: function setupController(controller, model) {
      controller.setProperties(model);
    },

    actions: {
      onScaleService: function onScaleService(database, instanceProfile, containerCount, containerSize, deferred, diskSize) {
        return this.store.createRecord('operation', {
          type: 'restart',
          containerSize: containerSize,
          containerCount: containerCount,
          instanceProfile: instanceProfile,
          database: database,
          diskSize: diskSize
        }).save().then(function (operation) {
          return operation.reloadUntilStatusChanged(OPERATION_TIMEOUT);
        }).then(function () {
          return database.reload();
        }).then(deferred.resolve, deferred.reject);
      }
    }
  });
});