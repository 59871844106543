define('diesel/components/editable-system-item/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['system-items__item'],
    item: null,
    isEditing: false,

    actions: {
      finishEdit: function finishEdit() {
        this.get('onUpdate')(this.get('item'));
        this.set('isEditing', false);
      },
      edit: function edit() {
        this.set('isEditing', true);
      },
      remove: function remove() {
        this.get('onRemove')(this.get('item'));
      }
    }
  });
});