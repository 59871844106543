define('diesel/app/route', ['exports', 'ember', 'diesel/mixins/routes/fetch-all-related-resources-mixin'], function (exports, _ember, _dieselMixinsRoutesFetchAllRelatedResourcesMixin) {
  exports['default'] = _ember['default'].Route.extend(_dieselMixinsRoutesFetchAllRelatedResourcesMixin['default'], {
    title: function title(tokens) {
      if (tokens.length === 0) {
        tokens.push(this.currentModel.get('handle'));
      }

      // account will be a PromiseProxy, already populated because of
      // the fetch for it from the `afterModel` hook
      var account = this.currentModel.get('account');
      _ember['default'].assert('App Route must have a account', !!account);

      tokens.push(account.get('handle'));
      return tokens.join(' - ');
    },
    afterModel: function afterModel(model) {
      return this._fetchAllRelatedResources(model);
    },

    setupController: function setupController(controller, model) {
      this._setupAllRelatedResources(controller, model);
    }
  });
});