define('diesel/asset/route', ['exports', 'ember', 'diesel/models/component'], function (exports, _ember, _dieselModelsComponent) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(_ref) {
      var type = _ref.type;
      var id = _ref.id;

      var assetType = _dieselModelsComponent.COMPONENT_TYPES.mapBy('handle').includes(type) ? 'component' : 'system';
      return this.store.find(assetType, id);
    },

    afterModel: function afterModel() {
      var organizationProfile = this.get('complianceStatus.organizationProfile');
      return _ember['default'].RSVP.hash({
        components: organizationProfile.get('components'),
        systems: organizationProfile.get('systems'),
        systemTemplates: this.store.findAll('system-template')
      });
    },

    setupController: function setupController(controller, asset) {
      var organizationProfile = this.get('complianceStatus.organizationProfile');

      controller.setProperties({
        asset: asset,
        organizationProfile: organizationProfile,
        components: organizationProfile.get('components'),
        systems: organizationProfile.get('systems'),
        systemTemplates: this.store.findAll('system-template')
      });
    },

    actions: {
      editAsset: function editAsset() {
        var asset = this.currentModel;
        var organizationProfile = this.get('complianceStatus.organizationProfile');
        var components = organizationProfile.get('components');
        var systems = organizationProfile.get('systems');
        var systemTemplates = this.store.findAll('system-template');

        var _get$getProperties = this.get('complianceStatus.authorizationContext').getProperties('roles', 'users');

        var roles = _get$getProperties.roles;
        var users = _get$getProperties.users;

        if (asset.get('isSystem')) {
          this.controller.editSystem({ system: asset, roles: roles, users: users, organizationProfile: organizationProfile });
        } else {
          this.controller.editComponent({ component: asset, organizationProfile: organizationProfile, components: components, systems: systems, roles: roles, users: users, systemTemplates: systemTemplates });
        }
      }
    }
  });
});