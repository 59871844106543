define('diesel/asset/pdcs/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    modalDisplay: _ember['default'].inject.service(),

    model: function model() {
      var asset = this.modelFor('asset');
      return asset.get('currentRiskProfile');
    },

    setupController: function setupController(controller, riskProfile) {
      var asset = this.modelFor('asset');
      var predisposingConditions = riskProfile.get('predisposingConditions').sortBy('title');
      controller.setProperties({ asset: asset, riskProfile: riskProfile, predisposingConditions: predisposingConditions });
    },

    actions: {
      editRiskComponent: function editRiskComponent(component) {
        var type = 'predisposing-condition';
        var resource = this.modelFor('asset');
        var resourceType = resource.get('isComponent') ? 'component' : 'system';

        this.get('modalDisplay').showModal('modal-risk-component-editor', {
          modalRiskComponentEditorProperties: { component: component, type: type, resource: resource, resourceType: resourceType }
        });
      }
    }
  });
});