define('diesel/components/modal-restart-database/component', ['exports', 'ember', 'diesel/mixins/components/modal-base'], function (exports, _ember, _dieselMixinsComponentsModalBase) {
  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsModalBase['default'], {
    // Inputs
    databaseToRestart: null,

    // state
    diskSize: null,
    containerSize: null,

    operationsManager: _ember['default'].inject.service(),

    title: _ember['default'].computed.alias('databaseToRestart.handle'),
    description: 'Resize database',

    hasChanges: _ember['default'].computed.or('newDiskSize', 'newContainerSize'),

    originalDiskSize: _ember['default'].computed.alias('databaseToRestart.disk.size'),
    originalContainerSize: _ember['default'].computed.alias('databaseToRestart.service.containerSize'),

    newDiskSize: _ember['default'].computed('originalDiskSize', 'diskSize', function () {
      var originalSize = this.get('originalDiskSize');
      var selectedSize = this.get('diskSize');
      if (originalSize === selectedSize) {
        return null;
      }
      return selectedSize;
    }),

    newContainerSize: _ember['default'].computed('originalContainerSize', 'containerSize', function () {
      var originalSize = this.get('originalContainerSize');
      var selectedSize = this.get('containerSize');
      if (originalSize === selectedSize) {
        return null;
      }
      return selectedSize;
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      // TODO: Handle disk is at max size
      this._super.apply(this, arguments);
      this.set('diskSize', this.get('originalDiskSize'));
      this.set('containerSize', this.get('originalContainerSize'));
    },

    actions: {
      containerSizeDidSlide: function containerSizeDidSlide(sizeMb) {
        this.set('containerSize', sizeMb);
      },

      diskSizeDidSlide: function diskSizeDidSlide(sizeGb) {
        this.set('diskSize', sizeGb);
      },

      commitChanges: function commitChanges() {
        var _this = this;

        var database = this.get('databaseToRestart');
        var operationAttrs = { type: 'restart' };

        var newContainerSize = this.get('newContainerSize');
        if (newContainerSize) {
          operationAttrs.containerSize = newContainerSize;
        }

        var newDiskSize = this.get('newDiskSize');
        if (newDiskSize) {
          operationAttrs.diskSize = newDiskSize;
        }

        return this.get('operationsManager').createBackgroundOperation(database, operationAttrs).then(function () {
          _this.sendAction('onDatabaseRestart', database);
        });
      }
    }
  });
});