define('diesel/models/activity', ['exports', 'ember-data', 'diesel/models/base', 'ember'], function (exports, _emberData, _dieselModelsBase, _ember) {
  exports['default'] = _dieselModelsBase['default'].extend({
    field: _emberData['default'].attr('string'),
    oldValue: _emberData['default'].attr('string'),
    newValue: _emberData['default'].attr('string'),
    userURL: _emberData['default'].attr('string'),
    userName: _emberData['default'].attr('string'),
    userEmail: _emberData['default'].attr('string'),
    createdAt: _emberData['default'].attr('date'),
    updatedAt: _emberData['default'].attr('date'),

    incident: _emberData['default'].belongsTo('incident', { async: true, inverse: 'activities' }),

    isTextField: _ember['default'].computed('body', 'field', function () {
      return ['description', 'event_timeline', 'retrospective', 'impact'].includes(this.get('field'));
    })
  });
});