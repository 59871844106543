define('diesel/components/training-article/component', ['exports', 'ember'], function (exports, _ember) {

  function domainFromHref(href) {
    var anchor = document.createElement('a');
    anchor.href = href;
    return anchor.hostname;
  }

  var IMAGE_PATH = '/assets/images/training/$course/articles/$file';
  exports.IMAGE_PATH = IMAGE_PATH;
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'article',
    course: null,
    imageFile: null,
    imageHref: _ember['default'].computed('course', 'imageFile', function () {
      var _getProperties = this.getProperties('course', 'imageFile');

      var course = _getProperties.course;
      var imageFile = _getProperties.imageFile;

      if (course && imageFile) {
        return IMAGE_PATH.replace('$course', course).replace('$file', imageFile);
      }
    }),

    domain: _ember['default'].computed('url', function () {
      return domainFromHref(this.get('url'));
    })
  });
});