define('diesel/models/vulnerability', ['exports', 'ember-data', 'diesel/models/base'], function (exports, _emberData, _dieselModelsBase) {
  exports['default'] = _dieselModelsBase['default'].extend({
    riskAssessment: _emberData['default'].belongsTo('risk-assessment'),
    handle: _emberData['default'].attr('string'),
    title: _emberData['default'].attr('string'),
    description: _emberData['default'].attr('string'),
    severity: _emberData['default'].attr('number'),
    controlledSeverity: _emberData['default'].attr('number'),

    securityControls: _emberData['default'].hasMany('security-control', {
      async: false
    }),
    threatEvents: _emberData['default'].hasMany('threat-event', {
      async: false
    }),
    mitigations: _emberData['default'].hasMany('mitigation', {
      async: false
    })
  });
});