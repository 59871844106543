define('diesel/components/billing-quantity/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    unit: null,
    quantity: null,
    goldenboyLib: null,

    formattedQuantity: _ember['default'].computed('unit', 'quantity', function () {
      var _getProperties = this.getProperties('unit', 'quantity');

      var unit = _getProperties.unit;
      var quantity = _getProperties.quantity;

      return this.get('goldenboyLib').formatQuantity(unit, quantity);
    }),

    showTip: _ember['default'].computed.lt('quantity', 0)
  });
});