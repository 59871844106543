define('diesel/components/c-web-app-scanning-implementation-detail/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    organization: null,
    requestSubmitted: false,
    // Hard coding status, since no one can actually have this control yet
    isAvailable: false,
    isUnavailable: true,
    status: "UNAVAILABLE",
    actions: {
      talkToSales: function talkToSales() {
        var analytics = this.get('analytics');
        var EVENT_NAME = 'upsell.requested';
        var upsell = 'Web Application Vulnerability Scanning';
        analytics.track(EVENT_NAME, { upsell: upsell });
        analytics.aptibleEvent(EVENT_NAME, upsell);
        this.set('requestSubmitted', true);
      }
    }
  });
});