define('diesel/components/c-select-visibility-scope/component', ['exports', 'ember', 'diesel/mixins/routes/controls-route-mixin'], function (exports, _ember, _dieselMixinsRoutesControlsRouteMixin) {

  var SortedAccountCollection = _ember['default'].Object.extend({
    accounts: null,
    handleSort: ['handle:asc'],
    init: function init() {
      this._super.apply(this, arguments);
      this.set('accounts', _ember['default'].A([]));
    },
    sortedAccounts: _ember['default'].computed.sort('accounts', 'handleSort'),
    add: function add(account) {
      this.get('accounts').pushObject(account);
    }
  });
  exports['default'] = _ember['default'].Component.extend({
    analytics: _ember['default'].inject.service(),
    scope: null,
    currentlySelectedScope: null,
    tagName: 'nav',
    classNameBindings: [':layout-sidebar', ':layout-sidebar--narrow', ':layout-sidebar--collapsible', 'collapsedCn'],

    setup: _ember['default'].on('init', function () {
      this._super.apply(this, arguments);
      this.set('scope', this.get('currentlySelectedScope'));
    }),

    collapsedCn: _ember['default'].computed('isCollapsed', function () {
      var isCollapsed = this.get('isCollapsed');
      return 'layout-sidebar--' + (isCollapsed ? 'collapsed' : 'uncollapsed');
    }),

    isDirty: _ember['default'].computed('scope', 'currentlySelectedScope', function () {
      return this.get('scope') !== this.get('currentlySelectedScope');
    }),

    selectedAccountsLength: _ember['default'].computed('scope', function () {
      return this.get('scope').split(",").length;
    }),

    maxAccountSelection: _dieselMixinsRoutesControlsRouteMixin.MAX_ACCOUNT_SELECTION,
    exceedsMaxSelection: _ember['default'].computed.gt('selectedAccountsLength', _dieselMixinsRoutesControlsRouteMixin.MAX_ACCOUNT_SELECTION),

    disableButton: _ember['default'].computed('isDirty', 'exceedsMaxSelection', function () {
      return !this.get("isDirty") || this.get('exceedsMaxSelection');
    }),

    productionStacks: _ember['default'].computed('productionAccounts.stack.id', function () {
      var stacks = {};

      this.get('productionAccounts').forEach(function (a) {
        var stackId = a.get('stack.id');
        if (!stackId) {
          return;
        }
        if (!stacks[stackId]) {
          stacks[stackId] = {
            stackResource: a.get('stack'),
            accountCollection: SortedAccountCollection.create()
          };
        }
        stacks[stackId].accountCollection.add(a);
      });

      return Object.values(stacks).sortBy('stackResource.name');
    }),

    toggleInputs: function toggleInputs(selection, state) {
      var inputs = selection === 'development' ? this.$('input[data-type="development"]') : this.$('input[data-stack-id=' + selection + ']');

      inputs.prop("checked", state);
      this.$("form").trigger('change');
    },

    actions: {
      selectAll: function selectAll(selection) {
        var analytics = this.get('analytics');
        var EVENT_NAME = 'scope.all.selected';
        analytics.track(EVENT_NAME, { type: selection });
        analytics.aptibleEvent(EVENT_NAME, selection);

        this.toggleInputs(selection, true);
        return false;
      },

      unselectAll: function unselectAll(selection) {
        var analytics = this.get('analytics');
        var EVENT_NAME = 'scope.all.unselected';
        analytics.track(EVENT_NAME, { type: selection });
        analytics.aptibleEvent(EVENT_NAME, selection);

        this.toggleInputs(selection, false);
        return false;
      },

      onSubmit: function onSubmit() {
        this.attrs.onSelect(this.get('scope'));
      },

      onChange: function onChange() {
        var scope = $.map(this.$("input:checkbox:checked"), function (e) {
          return e.value;
        }).join(',');

        var analytics = this.get('analytics');
        var EVENT_NAME = 'scope.selected';
        analytics.track(EVENT_NAME, { scope: scope });
        analytics.aptibleEvent(EVENT_NAME, scope);

        this.set('scope', scope);
      },

      onToggleCollapse: function onToggleCollapse(state) {
        this.attrs.onToggleCollapse(state);
      }
    }
  });
});