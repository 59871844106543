define('diesel/components/click-to-reveal/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    text: 'Reveal',
    hidden: true,
    actions: {
      reveal: function reveal() {
        this.set('hidden', false);
      }
    }
  });
});