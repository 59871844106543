define('diesel/components/predisposing-condition-filters/component', ['exports', 'ember'], function (exports, _ember) {

  var SORT = [{ title: 'Highest Pervasiveness', value: 'pervasiveness' }, { title: 'Title', value: 'title' }];

  var PERVASIVENESSES = [{ title: 'All Systems', value: 5 }, { title: 'Most Systems', value: 4 }, { title: 'Many Systems', value: 3 }, { title: 'Some Systems', value: 2 }, { title: 'Few Systems', value: 1 }, { title: 'Not Applicable', value: 0 }];

  exports['default'] = _ember['default'].Component.extend({
    filters: { search: '', sort: SORT[0].value, pervasiveness: null },

    classNames: ['risk-assessment__filters form-inline'],
    sorts: SORT,
    pervasivenesses: PERVASIVENESSES,

    showClear: _ember['default'].computed('filters.search', 'filters.pervasiveness', function () {
      var currentSearch = this.get('filters.search') || '';
      var pervasiveness = this.get('filters.pervasiveness');

      return _ember['default'].$.trim(currentSearch) !== '' || pervasiveness && pervasiveness !== '';
    }),

    actions: {
      clear: function clear() {
        this.set('filters.sort', SORT[0].value);
        this.set('filters.pervasiveness', null);
        this.set('filters.search', '');
      },

      update: function update() {
        var sort = this.$('select.sort').val();
        var pervasiveness = parseInt(this.$('select.pervasiveness').val(), 10);

        this.set('filters.sort', sort);
        this.set('filters.pervasiveness', pervasiveness);
      }
    }
  });
});