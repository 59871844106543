define('diesel/mixins/models/schema-document', ['exports', 'ember', 'ember-json-schema-document/models/schema', 'ember-data'], function (exports, _ember, _emberJsonSchemaDocumentModelsSchema, _emberData) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  exports['default'] = _ember['default'].Mixin.create({
    metadata: _emberData['default'].attr(),
    schema: _emberData['default'].attr(),
    isEditable: _ember['default'].computed(function () {
      var schema = this.get('schema');
      return schema && !_ember['default'].$.isEmptyObject(schema);
    }),

    isSavingAttestation: false,

    schemaBody: function schemaBody() {
      _ember['default'].assert('SchemaDocument-based models require a `SCHEMA_KEY`, e.g. `security_controls`', this.get('SCHEMA_KEY'));

      return {
        "$schema": "http://json-schema.org/draft-04/schema#",
        id: this.get('SCHEMA_KEY') + '/' + this.get('handle'),
        title: this.get('title'),
        type: 'object',
        required: [this.get('SCHEMA_KEY')],
        properties: _defineProperty({}, this.get('SCHEMA_KEY'), {
          type: 'object',
          required: [this.get('handle')],
          properties: _defineProperty({}, this.get('handle'), this.get('schema'))
        })
      };
    },

    buildSchema: function buildSchema() {
      return new _emberJsonSchemaDocumentModelsSchema['default'](this.schemaBody());
    },

    toSchemaDocument: function toSchemaDocument() {
      _ember['default'].assert('SchemaDocument-based models require a `SCHEMA_KEY`, e.g. `security_controls`', this.get('SCHEMA_KEY'));
      var values = this.get('metadata');

      return _defineProperty({}, this.get('SCHEMA_KEY'), _defineProperty({}, this.get('handle'), values));
    },

    initializeSchemaDocument: function initializeSchemaDocument() {
      var schema = this.buildSchema();
      var schemaDocument = schema.buildDocument();
      schemaDocument.load(this.toSchemaDocument());

      return schemaDocument;
    },

    saveSchemaDocument: function saveSchemaDocument(schemaDocument, options) {
      var _this = this;

      // Build an attestation and create it against the current risk graph
      // Update internal state to match attestation document
      this.set('isSavingAttestation', true);
      var store = options.store;
      var user = options.user;
      var organizationProfile = options.organizationProfile;
      var resourceType = options.resourceType;
      var resource = options.resource;

      var attestationDocument = schemaDocument.dump();
      var attestation = store.createRecord('attestation', {
        organizationProfile: organizationProfile,
        document: attestationDocument,
        schema: this.schemaBody()
      });

      if (resource && resourceType && resourceType !== 'organization') {
        attestation.set(resourceType, resource);
        attestation.set('organizationProfile', null);
      }

      attestation.set('user', user);

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        attestation.save().then(function (attestation) {
          _this.set('isSavingAttestation', false);
          _this.setProperties(attestationDocument[_this.get('SCHEMA_KEY')][_this.get('handle')]);
          organizationProfile.observeDocumentBuilds();
          resolve(attestation);
        }, reject);
      });
    }
  });
});