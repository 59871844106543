define('diesel/models/global-control', ['exports', 'ember-data', 'diesel/models/base', 'ember'], function (exports, _emberData, _dieselModelsBase, _ember) {

  var DEDICATED_STACK_ONLY_CONTROL_HANDLES = ['host-intrusion-detection', 'network-intrusion-detection', 'drift-detection-and-protection', '24-7-site-reliability-and-incident-response', 'secure-isolated-network-connections-through-vpns-and-vpcs', 'secure-network-architecture'];

  // These get a premium badge, meaning additional cost is required
  var PREMIUM_CONTROL_HANDLES = ['host-intrusion-detection'];

  // These controls will fail, regardless of API, and are intended to be a fake door test
  var COMING_SOON_HANDLES = ['web-application-vulnerability-scanning'];

  // Controls that should use a custom component for their implementation details panel.
  // Usually do this for auth-based controls (like MFA) or for controls with upsells
  var CUSTOM_IMPLEMENTATION_UI = {
    'multi-factor-authentication': 'c-mfa-implementation-detail',
    'sso-integration': 'c-sso-implementation-detail',
    'network-intrusion-detection': 'c-network-intrusion-detection-implementation-detail',
    'host-intrusion-detection': 'c-hids-implementation-detail',
    'web-application-vulnerability-scanning': 'c-web-app-scanning-implementation-detail'
  };

  // Org-scoped controls should be treated differently.  Consider SSO or MFA.
  // These controls will return a customer control for each environment
  // however, these customer controls are all duplicative.  (because they are
  // reporting on org state anbd the org is shared across all environments). As such,
  // We should only pop off the first customer control and IGNORE all of the rest.
  // If we don't do this, then our cumulative metrics would count the same resources
  // repeatedly.
  // Quick example: the MFA control for an org with 2 environments and 13 users.Without
  // intervention, this org would see 26 MFA checks for their 13 users (2env x 13 users);
  var ORGANIZATION_SCOPED_CONTROLS = ['sso-integration', 'multi-factor-authentication'];

  var DEFAULT_IMPLEMENTATION_UI = 'c-default-control-implementation-detail';

  exports['default'] = _dieselModelsBase['default'].extend({
    name: _emberData['default'].attr('string'),
    handle: _emberData['default'].attr('string'),
    description: _emberData['default'].attr('string'),
    area: _emberData['default'].attr('string'),
    callToAction: _emberData['default'].attr('string'),
    detailedDescription: _emberData['default'].attr('string'),
    hasRemediation: _emberData['default'].attr('boolean'),
    customFrontend: _emberData['default'].attr('boolean'),
    globalControlFrameworks: _emberData['default'].hasMany('globalControlFramework', { async: false }),

    frameworks: _ember['default'].computed('globalControlFrameworks.@each.framework', function () {
      return this.get('globalControlFrameworks').mapBy('framework');
    }),

    frameworksAsString: _ember['default'].computed('globalControlFrameworks.@each.frameworkname', function () {
      return this.get('globalControlFrameworks').sortBy('frameworkName').mapBy('frameworkName').join(', ');
    }),

    frameworkHandles: _ember['default'].computed('globalControlFrameworks.@each.metricHandle', function () {
      return this.get('globalControlFrameworks').map(function (f) {
        return f.get('metricHandle');
      });
    }),

    isDedicatedOnly: _ember['default'].computed('handle', function () {
      return DEDICATED_STACK_ONLY_CONTROL_HANDLES.includes(this.get('handle'));
    }),

    isPremium: _ember['default'].computed('handle', function () {
      return PREMIUM_CONTROL_HANDLES.includes(this.get('handle'));
    }),

    isComingSoon: _ember['default'].computed('handle', function () {
      return COMING_SOON_HANDLES.includes(this.get('handle'));
    }),

    implementationUiComponent: _ember['default'].computed('handle', function () {
      return CUSTOM_IMPLEMENTATION_UI[this.get('handle')] || DEFAULT_IMPLEMENTATION_UI;
    }),

    isOrganizationScoped: _ember['default'].computed('handle', function () {
      return ORGANIZATION_SCOPED_CONTROLS.includes(this.get('handle'));
    })
  });
});