define('diesel/models/vpn-tunnel', ['exports', 'ember-data', 'diesel/models/base'], function (exports, _emberData, _dieselModelsBase) {
  exports['default'] = _dieselModelsBase['default'].extend({
    handle: _emberData['default'].attr('string'),

    phase1Alg: _emberData['default'].attr('string'),
    phase1DhGroup: _emberData['default'].attr('number'),
    phase1Lifetime: _emberData['default'].attr('number'),

    phase2Alg: _emberData['default'].attr('string'),
    phase2DhGroup: _emberData['default'].attr('number'),
    phase2Lifetime: _emberData['default'].attr('number'),

    perfectForwardSecrecy: _emberData['default'].attr('boolean'),

    ourGateway: _emberData['default'].attr('string'),
    ourNetworks: _emberData['default'].attr(),

    peerGateway: _emberData['default'].attr('string'),
    peerNetworks: _emberData['default'].attr(),

    stack: _emberData['default'].belongsTo('stack', { async: true })
  });
});