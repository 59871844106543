define('diesel/components/modal-2fa/component', ['exports', 'ember', 'diesel/mixins/components/modal-base'], function (exports, _ember, _dieselMixinsComponentsModalBase) {

  // Adapted from http://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
  var extractParameterFromUri = function extractParameterFromUri(name, url) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    var results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  };

  var OTP_STATE_DISABLED = 1;
  var OTP_STATE_PENDING = 2;
  var OTP_STATE_ENABLED = 3;

  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsModalBase['default'], {
    userIsSaving: _ember['default'].computed.bool('model.user.isSaving'),

    title: _ember['default'].computed('otpState', function () {
      if (this.get('otpPending')) {
        return 'Configure 2-Factor Authentication';
      }
      return 'Download 2-Factor Authentication Backup Codes';
    }),

    description: _ember['default'].computed('otpState', function () {
      if (this.get('otpPending')) {
        return 'Scan the QR code to proceed.';
      }
      return 'Use backup codes to login if your 2-factor authentication device is unavailable.';
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.get('model.workingOtpConfiguration.otpRecoveryCodes').reload();
    },

    otpState: _ember['default'].computed("model.user.otpEnabled", "model.workingOtpConfiguration", function () {
      var otpEnabled = this.get("model.user.otpEnabled");
      var workingOtpUri = this.get("model.workingOtpConfiguration.otpUri");
      if (otpEnabled) {
        return OTP_STATE_ENABLED;
      }
      if (workingOtpUri) {
        return OTP_STATE_PENDING;
      }
      return OTP_STATE_DISABLED;
    }),

    otpSecret: _ember['default'].computed("model.workingOtpConfiguration.otpUri", function () {
      var workingOtpUri = this.get("model.workingOtpConfiguration.otpUri");
      return extractParameterFromUri("secret", workingOtpUri);
    }),

    isActionDisabled: _ember['default'].computed.empty('model.otpToken'),
    otpDisabled: _ember['default'].computed.equal("otpState", OTP_STATE_DISABLED),
    otpPending: _ember['default'].computed.equal("otpState", OTP_STATE_PENDING),
    otpEnabled: _ember['default'].computed.equal("otpState", OTP_STATE_ENABLED),

    allCodes: _ember['default'].computed.alias('model.workingOtpConfiguration.otpRecoveryCodes'),
    usedCodes: _ember['default'].computed.filterBy('allCodes', "used", true),
    availableCodes: _ember['default'].computed.filterBy('allCodes', "used", false),
    availableCodeValues: _ember['default'].computed.mapBy('availableCodes', 'value'),

    hasAvailableCodes: _ember['default'].computed('availableCodes', function () {
      return this.get('availableCodes.length') > 0;
    }),

    formatCodesToCopy: _ember['default'].computed('availableCodeValues.[]', function () {
      return this.get('availableCodeValues').join('\n');
    }),

    actions: {
      toggle2FA: function toggle2FA() {
        this.sendAction('toggleOtp');
      },

      showOtpSecret: function showOtpSecret() {
        this.set('model.showOtpSecret', true);
      }
    }
  });
});