define('diesel/organization/admin/billing/plan/route', ['exports', 'ember', 'diesel/utils/plan'], function (exports, _ember, _dieselUtilsPlan) {

  var BillingRoute = 'organization.admin.billing';

  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var org = this.modelFor('organization');
      var plans = this.get('store').findAll('plan');
      var billingDetail = this.modelFor(BillingRoute);

      // below is a list of one item, confusing, but that's how it is
      var activePlanList = this.get('store').query('activePlan', {
        organization_id: org.organization.id
      });

      return _ember['default'].RSVP.hash({
        org: org.organization,
        planList: plans,
        activePlanList: activePlanList,
        currentTrial: billingDetail.get('currentTrial'),
        paymentMethods: billingDetail.get('paymentMethods'),
        paymentMethodsForManualPayment: billingDetail.get('paymentMethodsForManualPayment')
      }).then(function (data) {
        // warning, below line is recalculated as on models/billing-detail.js as some nested awaitable computed fields do not get awaited
        // by Ember RSVP.hash nor RSVP.hashSettled
        var activeTrialAndHasNoManualPaymentMethod = data.paymentMethodsForManualPayment && data.paymentMethodsForManualPayment.length == 0 && data.currentTrial.get('active');
        var planData = (0, _dieselUtilsPlan.transformPlanData)(data, activeTrialAndHasNoManualPaymentMethod);
        var resp = Object.assign(planData, {
          paymentMethod: data.paymentMethods.filter(function (pm) {
            return billingDetail.get('links.payment_method') === pm.get('links.self');
          }).get('firstObject')
        });
        return resp;
      });
    }
  });
});