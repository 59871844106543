define('diesel/gridiron-setup/organization/route', ['exports', 'ember', 'diesel/mixins/routes/spd-route'], function (exports, _ember, _dieselMixinsRoutesSpdRoute) {
  exports['default'] = _ember['default'].Route.extend(_dieselMixinsRoutesSpdRoute['default'], {
    model: function model() {
      return this.modelFor('gridiron-setup');
    },

    setupController: function setupController(controller, model) {
      var organization = this.get('complianceStatus.organization');
      controller.set('organization', organization);
      controller.set('model', model);
    },

    next: function next() {
      var _this = this;

      var profile = this.modelFor('gridiron-setup');
      if (profile.get('isNew')) {
        // When saving the org profile for the first time, there will be
        // significant delay as we bootstrap roles and memberships
        this.controller.set('showLoader', true);
      }

      profile.save().then(function () {
        return profile.next(_this.get('stepName')).save();
      }).then(function () {
        return _this.transitionTo('gridiron-setup.' + profile.get('currentStep'));
      })['finally'](function () {
        return _this.controller.set('showLoader', false);
      })['catch'](function (e) {
        var message = e.message || 'An error occurred';
        _ember['default'].get(_this, 'flashMessages').danger('Save Failed! ' + message);
      });
    },

    actions: {
      onSave: function onSave() {
        var _this2 = this;

        var organizationProfile = this.modelFor('gridiron-setup');
        // We could validate the controller here, but I'm choosing not to.  By
        // not validating the controller, we allow the user to save progress
        // even though they may not have completed the entirety of the interview.

        if (organizationProfile.get('isNew')) {
          // When saving the org profile for the first time, there will be
          // significant delay as we bootstrap roles and memberships
          this.controller.set('showLoader', true);
        }

        organizationProfile.save().then(function () {
          return _ember['default'].get(_this2, 'flashMessages').success('Progress saved.');
        })['catch'](function (e) {
          var message = _ember['default'].getWithDefault(e, 'errors.message', 'An error occurred');
          _ember['default'].get(_this2, 'flashMessages').danger('Save Failed! ' + message);
        })['finally'](function () {
          return _this2.controller.set('showLoader', false);
        });
      }
    }
  });
});