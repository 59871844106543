define('diesel/models/customer-control', ['exports', 'ember-data', 'ember', 'diesel/models/base', 'diesel/utils/implementation-metrics'], function (exports, _emberData, _ember, _dieselModelsBase, _dieselUtilsImplementationMetrics) {
  exports['default'] = _dieselModelsBase['default'].extend({
    isEvaluating: false,

    status: _emberData['default'].attr('string'),
    lastEvaluation: _emberData['default'].attr('iso-8601-timestamp'),
    globalControl: _emberData['default'].belongsTo('global-control', { async: true }),
    account: _emberData['default'].belongsTo('account', { async: false }),
    customerControlResources: _emberData['default'].hasMany('customerControlResource', { async: true }),

    isDisabled: _ember['default'].computed('globalControl.isDedicatedOnly', 'account.isDevelopment', function () {
      return this.get('globalControl.isDedicatedOnly') && this.get('account.isDevelopment');
    }),

    isComingSoon: _ember['default'].computed.reads('globalControl.isComingSoon'),

    resourceType: _ember['default'].computed('customerControlResources', function () {
      var resources = this.get('customerControlResources');
      if (resources.get('length')) return resources.get('firstObject.readableResourceType');
      return 'Resource';
    }),

    metrics: _ember['default'].computed('resourceType', 'lastEvaluation', 'customerControlResources.@each.compliant', 'customerControlResources.@each.ignored', function () {
      var _this = this;

      var _getProperties = this.getProperties('activeResources', 'ignoredResources', 'resourceType', 'lastEvaluation');

      var activeResources = _getProperties.activeResources;
      var ignoredResources = _getProperties.ignoredResources;
      var resourceType = _getProperties.resourceType;
      var lastEvaluation = _getProperties.lastEvaluation;

      _ember['default'].run.later(function () {
        _this.trigger('metricsRecalculated');
      });

      return _dieselUtilsImplementationMetrics['default'].create({
        totalInScope: activeResources.get('length') || 0,
        totalCompliant: this.get("isComingSoon") ? 0 : activeResources.filterBy('compliant', true).length,
        totalIgnored: ignoredResources.get('length') || 0,
        resourceType: resourceType,
        lastEvaluation: lastEvaluation
      });
    }),

    ignoredResources: _ember['default'].computed.filterBy('customerControlResources', 'ignored', true),
    activeResources: _ember['default'].computed.filterBy('customerControlResources', 'ignored', false),

    evaluate: function evaluate(accessToken) {
      var _this2 = this;

      var href = this.get('links.self') + '/run';
      this.set('isEvaluating', true);
      return _ember['default'].$.ajax(href, { method: 'POST', headers: { Authorization: 'Bearer ' + accessToken } }).then(function () {
        _this2.set('isEvaluating', false);
        return _this2.reload();
      })['catch'](function (e) {
        _this2.set('isEvaluating', false);
        return _ember['default'].RSVP.reject();
      });
    }
  });
});