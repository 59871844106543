define('diesel/utils/states-to-iso', ['exports'], function (exports) {
  var statesToIso = {
    NA: { id: 'NA', name: 'N/A' },
    AB: { id: 'AB', name: 'Alberta' },
    AL: { id: 'AL', name: 'Alabama' },
    AK: { id: 'AK', name: 'Alaska' },
    AZ: { id: 'AZ', name: 'Arizona' },
    AR: { id: 'AR', name: 'Arkansas' },
    BC: { id: 'BC', name: 'British Columbia' },
    CA: { id: 'CA', name: 'California' },
    CO: { id: 'CO', name: 'Colorado' },
    CT: { id: 'CT', name: 'Connecticut' },
    DE: { id: 'DE', name: 'Delaware' },
    DC: { id: 'DC', name: 'District Of Columbia' },
    FL: { id: 'FL', name: 'Florida' },
    GA: { id: 'GA', name: 'Georgia' },
    HI: { id: 'HI', name: 'Hawaii' },
    ID: { id: 'ID', name: 'Idaho' },
    IL: { id: 'IL', name: 'Illinois' },
    IN: { id: 'IN', name: 'Indiana' },
    IA: { id: 'IA', name: 'Iowa' },
    KS: { id: 'KS', name: 'Kansas' },
    KY: { id: 'KY', name: 'Kentucky' },
    LA: { id: 'LA', name: 'Louisiana' },
    ME: { id: 'ME', name: 'Maine' },
    MB: { id: 'MB', name: 'Manitoba' },
    MD: { id: 'MD', name: 'Maryland' },
    MA: { id: 'MA', name: 'Massachusetts' },
    MI: { id: 'MI', name: 'Michigan' },
    MN: { id: 'MN', name: 'Minnesota' },
    MS: { id: 'MS', name: 'Mississippi' },
    MO: { id: 'MO', name: 'Missouri' },
    MT: { id: 'MT', name: 'Montana' },
    NB: { id: 'NB', name: 'New Brunswick' },
    NE: { id: 'NE', name: 'Nebraska' },
    NV: { id: 'NV', name: 'Nevada' },
    NH: { id: 'NH', name: 'New Hampshire' },
    NJ: { id: 'NJ', name: 'New Jersey' },
    NM: { id: 'NM', name: 'New Mexico' },
    NY: { id: 'NY', name: 'New York' },
    NL: { id: 'NL', name: 'Newfoundland and Labrador' },
    NC: { id: 'NC', name: 'North Carolina' },
    ND: { id: 'ND', name: 'North Dakota' },
    NT: { id: 'NT', name: 'Northwest Territories' },
    NS: { id: 'NS', name: 'Nova Scotia' },
    NU: { id: 'NU', name: 'Nunavut' },
    OH: { id: 'OH', name: 'Ohio' },
    OK: { id: 'OK', name: 'Oklahoma' },
    ON: { id: 'ON', name: 'Ontario' },
    OR: { id: 'OR', name: 'Oregon' },
    PA: { id: 'PA', name: 'Pennsylvania' },
    PE: { id: 'PE', name: 'Prince Edward Island' },
    QC: { id: 'QC', name: 'Quebec' },
    RI: { id: 'RI', name: 'Rhode Island' },
    SK: { id: 'SK', name: 'Saskatchewan' },
    SC: { id: 'SC', name: 'South Carolina' },
    SD: { id: 'SD', name: 'South Dakota' },
    TN: { id: 'TN', name: 'Tennessee' },
    TX: { id: 'TX', name: 'Texas' },
    UT: { id: 'UT', name: 'Utah' },
    VT: { id: 'VT', name: 'Vermont' },
    VA: { id: 'VA', name: 'Virginia' },
    WA: { id: 'WA', name: 'Washington' },
    WV: { id: 'WV', name: 'West Virginia' },
    WI: { id: 'WI', name: 'Wisconsin' },
    WY: { id: 'WY', name: 'Wyoming' },
    YT: { id: 'YT', name: 'Yukon' }
  };
  exports.statesToIso = statesToIso;
});