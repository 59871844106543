define('diesel/threat-events/index/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    title: function title() {
      var riskAssessment = this.modelFor('risk-assessment');
      var status = riskAssessment.get('status').capitalize();

      return 'Threat Events - ' + status + ' Risk Assessment';
    },

    model: function model() {
      return this.modelFor('threat-events');
    }
  });
});