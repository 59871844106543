define('diesel/services/store', ['exports', 'ember-data'], function (exports, _emberData) {

  var clientSideIdCounter = 0;
  // The following models require client-side ids to be generated.
  // As of Ember Data 2.10, payloads require an id, but the API response for
  // these models is a 204 no-content. Ember Data's id assertion happens
  // before the payload is passed to the serializer (when the payload is null),
  // so it's not possible to fix this with a serializer hook and we patch up the
  // response in the (private) `store#didSaveRecord` method instead.
  var NEEDS_CLIENT_SIDE_ID = ['password-reset-request', 'reset', 'verification'];

  exports['default'] = _emberData['default'].Store.extend({

    findAccountsFor: function findAccountsFor(organization) {
      var _this = this;

      var organizationUrl = organization.get('links.self');
      var promise = this.findAll('account').then(function () {
        return _this.filter('account', function (account) {
          return account.get('links.organization') === organizationUrl;
        });
      });

      return _emberData['default'].PromiseArray.create({ promise: promise });
    },

    didSaveRecord: function didSaveRecord(internalModel, data) {
      // TODO: private

      if (NEEDS_CLIENT_SIDE_ID.includes(internalModel.modelName)) {
        data.data = data.data || {};
        data.data.id = 'generated-' + internalModel.modelName + '-' + ++clientSideIdCounter;
      }

      return this._super(internalModel, data);
    }
  });
});