define('diesel/components/download-report/component', ['exports', 'ember', 'diesel/utils/location'], function (exports, _ember, _dieselUtilsLocation) {
  /* Example usage:
   *
    {{download-report downloadUrl=downloadUrl}}
  
  */

  exports['default'] = _ember['default'].Component.extend({
    session: _ember['default'].inject.service(),

    tagName: 'a',
    attributeBindings: ['downloadUrl:data-test-download-report'],

    click: function click() {
      _ember['default'].$.ajax(this.get('downloadUrl'), { headers: { 'Authorization': 'Bearer ' + this.get("session.token.accessToken") } }).then(function (data) {
        _dieselUtilsLocation['default'].replace(data);
      });
    }
  });
});