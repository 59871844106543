define('diesel/mixins/components/service-scaling', ['exports', 'ember', 'diesel/models/service', 'diesel/helpers/plural-string'], function (exports, _ember, _dieselModelsService, _dieselHelpersPluralString) {
  var hoursPerMonth = 731;
  exports.hoursPerMonth = hoursPerMonth;
  var centsToDollars = function centsToDollars(cents) {
    return '$' + (cents / 100).toFixed(2);
  };

  exports.centsToDollars = centsToDollars;
  exports['default'] = _ember['default'].Mixin.create({
    containerProfile: _ember['default'].computed('instanceClass', function () {
      return _dieselModelsService.CONTAINER_PROFILES[this.get('instanceClass') || 'm4'];
    }),

    updateContainerSizeOnProfileChange: _ember['default'].observer('availableContainerSizes', function () {
      var _get = this.get('containerProfile');

      var minimumContainerSize = _get.minimumContainerSize;
      var maximumContainerSize = _get.maximumContainerSize;

      var size = this.get('containerSize');
      if (size < minimumContainerSize) {
        this.set('containerSize', minimumContainerSize);
      } else if (size > maximumContainerSize) {
        this.set('containerSize', maximumContainerSize);
      }
    }),

    cpuShare: _ember['default'].computed('containerSize', 'containerProfile', function () {
      var _get2 = this.get('containerProfile');

      var cpuShare = _get2.cpuShare;

      return this.get('containerSize') * cpuShare;
    }),

    rateInDollars: _ember['default'].computed('account.appContainerCentsPerHour', function () {
      return centsToDollars(this.get('account.appContainerCentsPerHour'));
    }),

    estimatedMonthlyDiskPriceInCents: _ember['default'].computed('diskSize', function () {
      var diskSize = this.get('diskSize');
      var diskCost = diskSize ? diskSize * 0.2 * 100 : 0;
      return diskCost;
    }),

    estimatedMonthlyCostInCents: _ember['default'].computed('containerProfile', 'containerCount', 'containerSize', 'estimatedMonthlyDiskPriceInCents', function () {
      var _get3 = this.get('containerProfile');

      var costPerContainerHourInCents = _get3.costPerContainerHourInCents;

      return hoursPerMonth * (this.get('containerCount') * (this.get('containerSize') / 1024)) * costPerContainerHourInCents + this.get('estimatedMonthlyDiskPriceInCents');
    }),

    estimatedCostInDollars: _ember['default'].computed('estimatedMonthlyCostInCents', function () {
      return centsToDollars(this.get('estimatedMonthlyCostInCents'));
    }),

    diskSizePricingDescription: _ember['default'].computed('diskSize', function () {
      var _getProperties = this.getProperties('diskSize');

      var diskSize = _getProperties.diskSize;

      return diskSize + ' GB disk × $0.20 per GB/Month';
    }),

    pricingDescription: _ember['default'].computed('containerSize', 'containerCount', 'containerProfile', function () {
      var _getProperties2 = this.getProperties('containerSize', 'containerCount');

      var containerSize = _getProperties2.containerSize;
      var containerCount = _getProperties2.containerCount;

      var _get4 = this.get('containerProfile');

      var costPerContainerHourInCents = _get4.costPerContainerHourInCents;

      var rate = centsToDollars(costPerContainerHourInCents);
      return [containerCount, containerSize / _dieselModelsService.GB + ' GB ' + (0, _dieselHelpersPluralString.pluralString)('container', containerCount), rate + ' per GB/Hour'].join(' × ');
    })
  });
});