define('diesel/gridiron-admin/security-reviews/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    currentSecurityReviewSystems: _ember['default'].computed('currentSecurityReview.systems', function () {
      var systems = this.get('currentSecurityReview.systems') || [];
      return systems.map(function (s) {
        return _ember['default'].get(s, 'name');
      }).join(', ');
    }),

    reviewSystems: _ember['default'].computed.filterBy('systems', 'hasItems', true)
  });
});