define('diesel/models/stripe-source', ['exports', 'ember', 'ember-data', 'diesel/models/base'], function (exports, _ember, _emberData, _dieselModelsBase) {
  exports['default'] = _dieselModelsBase['default'].extend({
    billingDetail: _emberData['default'].belongsTo('billing-detail', { async: true }),
    verificationAttempts: _emberData['default'].hasMany('verification-attempt', { async: true }),

    deactivatedAt: _emberData['default'].attr('iso-8601-timestamp'),

    description: _emberData['default'].attr('string'),
    stripeTokenId: _emberData['default'].attr('string'),
    stripeType: _emberData['default'].attr('string'),
    stripeMetadata: _emberData['default'].attr('humps'),

    active: _ember['default'].computed('deactivatedAt', function () {
      return !this.get('deactivatedAt');
    }),

    isBankAccount: _ember['default'].computed.equal('stripeType', 'BankAccount'),
    isVerified: _ember['default'].computed.equal('stripeMetadata.status', 'verified'),

    typeCanDeactivate: true
  });
});