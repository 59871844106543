define('diesel/models/attachment', ['exports', 'ember-data', 'diesel/models/base', 'ember'], function (exports, _emberData, _dieselModelsBase, _ember) {
  exports['default'] = _dieselModelsBase['default'].extend({
    attachableType: _emberData['default'].attr('string'),
    attachableID: _emberData['default'].attr('string'),
    contentStatus: _emberData['default'].attr('string', { defaultValue: 'empty' }),
    downloadURL: _emberData['default'].attr('string'),
    fileOriginalName: _emberData['default'].attr('string'),
    fileContentType: _emberData['default'].attr('string'),
    fileURL: _emberData['default'].attr('string'),
    s3Credentials: _emberData['default'].attr(),

    createdAt: _emberData['default'].attr('date'),
    updatedAt: _emberData['default'].attr('date'),

    agreement: _emberData['default'].belongsTo('agreement', { async: true }),
    certification: _emberData['default'].belongsTo('certification', { async: true }),
    // temp reference to file queued for uplaod
    file: null,

    hasNoFile: _ember['default'].computed('fileURL', 'fileOriginalName', function () {
      return _ember['default'].isBlank(this.get('fileURL')) && _ember['default'].isBlank(this.get('fileOriginalName'));
    }),
    fileDisplayName: _ember['default'].computed('fileOriginalName', function () {
      // For uploads, we can display the file name, why not?
      var fileName = this.get('fileOriginalName');
      if (fileName) {
        return fileName;
      }

      return this.get('downloadURL');
    })
  });
});