define('diesel/stack/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    title: function title() {
      var stack = this.modelFor('stack');
      return stack.get('name') + ' Stack';
    },

    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);
      this.render('sidebars/organizations-accounts', {
        into: 'enclave-wrapper',
        outlet: 'sidebar'
      });
    }
  });
});