define('diesel/gridiron-setup/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.get('complianceStatus.organizationProfile');
    },

    setupController: function setupController(controller, model) {
      var complianceStatus = this.get('complianceStatus');

      controller.set('model', model);
      controller.set('roles', complianceStatus.get('roles'));
      controller.set('organization', complianceStatus.get('organization'));
    }
  });
});