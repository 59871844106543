define("diesel/utils/tokens", ["exports", "diesel/utils/ajax", "diesel/config/environment", "diesel/adapters/application", "diesel/utils/assign"], function (exports, _dieselUtilsAjax, _dieselConfigEnvironment, _dieselAdaptersApplication, _dieselUtilsAssign) {
  exports.createToken = createToken;
  exports.getPersistedToken = getPersistedToken;
  exports.revokeAllAccessibleTokens = revokeAllAccessibleTokens;

  var AUTH_SOURCE = 'dashboard';

  function rethrowJqXhrError(jqXHR) {
    // TODO: Use this everywhere.
    if (jqXHR.responseJSON) {
      var err = new Error(jqXHR.responseJSON.message);
      err.authError = jqXHR.responseJSON.error;
      err.authU2f = jqXHR.responseJSON.exception_context && jqXHR.responseJSON.exception_context.u2f;
      throw err;
    } else if (jqXHR.responseText) {
      throw new Error(jqXHR.responseText);
    } else {
      throw new Error("Unknown error from the server.");
    }
  }

  function createToken(credentials, options) {
    // Accepted options:
    // - `noPersist` (defaults to `false`): don't persist the token as a cookie.
    options = options || {};

    return (0, _dieselUtilsAjax["default"])(_dieselConfigEnvironment["default"].authBaseUri + '/tokens', {
      type: 'POST',
      data: (0, _dieselUtilsAssign["default"])(credentials, { _source: AUTH_SOURCE }),
      xhrFields: {
        withCredentials: !options.noPersist
      }
    })["catch"](rethrowJqXhrError);
  }

  function getPersistedToken() {
    return (0, _dieselUtilsAjax["default"])(_dieselConfigEnvironment["default"].authBaseUri + '/current_token', {
      type: 'GET',
      xhrFields: {
        withCredentials: true
      }
    })["catch"](rethrowJqXhrError);
  }

  function revokeAllAccessibleTokens(options) {
    options = options || {};

    return (0, _dieselUtilsAjax["default"])(_dieselConfigEnvironment["default"].authBaseUri + '/tokens/revoke_all_accessible', {
      type: 'POST',
      data: {
        except_tokens: options.exceptTokenHrefs
      },
      headers: {
        'Authorization': "Bearer " + (0, _dieselAdaptersApplication.getAccessToken)()
      }
    })["catch"](rethrowJqXhrError);
  }
});