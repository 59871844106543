define('diesel/login/route', ['exports', 'ember', 'diesel/utils/cookies', 'diesel/utils/location', 'diesel/mixins/routes/disallow-authenticated'], function (exports, _ember, _dieselUtilsCookies, _dieselUtilsLocation, _dieselMixinsRoutesDisallowAuthenticated) {
  var AFTER_AUTH_COOKIE = 'afterAuthUrl';

  exports.AFTER_AUTH_COOKIE = AFTER_AUTH_COOKIE;
  exports['default'] = _ember['default'].Route.extend(_dieselMixinsRoutesDisallowAuthenticated['default'], {
    model: function model() {
      var model = _ember['default'].Object.create({
        email: '',
        password: '',
        loginCallback: _ember['default'].run.bind(this, this.loginCallback)
      });

      var afterAuthUrl = _dieselUtilsCookies['default'].read(AFTER_AUTH_COOKIE);
      if (afterAuthUrl) {
        _dieselUtilsCookies['default'].erase(AFTER_AUTH_COOKIE);
        model.set('afterAuthUrl', afterAuthUrl);
      }

      return model;
    },

    loginCallback: function loginCallback(credentials) {
      return this.session.open('application', credentials);
    },

    redirect: function redirect() {
      if (this.session.get('isAuthenticated')) {
        this.transitionTo('requires-authorization');
      }
    },

    actions: {
      loggedIn: function loggedIn() {
        if (this.session.attemptedTransition) {
          this.session.attemptedTransition.retry();
          this.session.attemptedTransition = null;
        } else if (this.currentModel.get('afterAuthUrl')) {
          _dieselUtilsLocation['default'].replace(this.currentModel.get('afterAuthUrl'));
        } else {
          this.transitionTo('requires-authorization');
        }
      }
    }
  });
});