define('diesel/components/modal-invite-team-to-role/component', ['exports', 'ember', 'diesel/validators/email', 'diesel/mixins/components/modal-base'], function (exports, _ember, _dieselValidatorsEmail, _dieselMixinsComponentsModalBase) {
  var EMAIL_STRING_DELIMITER = /[,|;|\s]+/;
  exports.EMAIL_STRING_DELIMITER = EMAIL_STRING_DELIMITER;
  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsModalBase['default'], {
    classNames: ['invite-team'],
    errors: null,
    invitesList: '',
    role: _ember['default'].computed.reads('inviteTeamToRoleModalProperties.role'),
    title: _ember['default'].computed('role.name', function () {
      return 'Invite your workforce to join \'' + this.get('role.name') + '\'';
    }),

    description: _ember['default'].computed.reads('role.description'),

    splitInviteList: _ember['default'].computed('invitesList', function () {
      var inviteListString = this.get('invitesList');
      return inviteListString.split(EMAIL_STRING_DELIMITER).filter(function (email) {
        return !!_ember['default'].$.trim(email);
      });
    }),

    validEmails: _ember['default'].computed.filter('splitInviteList', function (email) {
      return _dieselValidatorsEmail.VALID_EMAIL_REGEX.exec(email);
    }),

    invalidEmails: _ember['default'].computed.setDiff('splitInviteList', 'validEmails'),

    validate: function validate() {
      var error = null;

      var _getProperties = this.getProperties('invalidEmails');

      var invalidEmails = _getProperties.invalidEmails;

      if (invalidEmails.length > 0) {
        var invalid = invalidEmails.join(', ');
        error = 'Error: The following emails are invalid: ' + invalid;
      }

      this.set('errors', error);
      return this;
    },

    actions: {
      clearMessages: function clearMessages() {
        this.set('errors', null);
      },

      sendInvitations: function sendInvitations() {
        this.validate();
        if (this.get('errors')) {
          return;
        }

        this.sendAction('inviteTeam', this.get('validEmails'));
      }
    }
  });
});