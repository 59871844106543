define("diesel/settings/impersonate/controller", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Controller.extend({
    inProgress: false,
    disableEmail: _ember["default"].computed("model.sso", "model.organizationHref", function () {
      var model = this.get("model");
      return !!model.get("organizationHref") && !model.get("sso");
    }),
    disableOrg: _ember["default"].computed("model.sso", "model.email", function () {
      var model = this.get("model");
      return !!model.get("email") && !model.get("sso");
    })
  });
});