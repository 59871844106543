define('diesel/metric-drains/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    modalDisplay: _ember['default'].inject.service(),

    title: function title() {
      var account = this.modelFor('account');
      return account.get('handle') + ' Metric Drains';
    },

    model: function model() {
      var account = this.modelFor('account');
      return account.get('metricDrains');
    },

    setupController: function setupController(controller, model) {
      var account = this.modelFor('account');
      controller.setProperties({ account: account, model: model });
    },

    actions: {
      onRestartMetricDrain: function onRestartMetricDrain(metricDrain) {
        return this.get('operationsManager').createBackgroundOperation(metricDrain, {
          type: 'provision'
        });
      },

      onDeleteMetricDrain: function onDeleteMetricDrain(metricDrain) {
        return this.get('operationsManager').createBackgroundOperation(metricDrain, {
          type: 'deprovision'
        });
      },

      onOpenCreateMetricDrainModal: function onOpenCreateMetricDrainModal() {
        var _this = this;

        this.controller.set('modalLoading', true);

        var account = this.modelFor('account');
        var metricDrain = this.get('store').createRecord('metric-drain', { account: account });
        account.get('databases').then(function (databases) {
          var modalDisplay = _this.get('modalDisplay');

          var createMetricDrainModel = { account: account, databases: databases, metricDrain: metricDrain };

          modalDisplay.showModal('modal-create-metric-drain', { createMetricDrainModel: createMetricDrainModel }, {
            actions: {
              createMetricDrain: modalDisplay.modalAction(_this, 'onCreateMetricDrain')
            }
          });
        })['finally'](function () {
          _this.controller.set('modalLoading', null);
        });
      },

      onCreateMetricDrain: function onCreateMetricDrain(metricDrain) {
        var _this2 = this;

        return metricDrain.save().then(function () {
          return _this2.get('operationsManager').createBackgroundOperation(metricDrain, {
            type: 'provision'
          });
        }, function (e) {
          var defaultMessage = 'There was an error creating the metric drain.';
          var message = _ember['default'].getWithDefault(e, 'message', defaultMessage);
          _ember['default'].get(_this2, 'flashMessages').danger(message);
          return e; // keep modal from closing.
        });
      }
    }
  });
});