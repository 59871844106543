define('diesel/helpers/format-date', ['exports', 'ember', 'diesel/utils/dates'], function (exports, _ember, _dieselUtilsDates) {
  exports.formatDate = formatDate;

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  function formatDate(date) {
    var monthNameLength = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

    var months = _dieselUtilsDates.monthNames;
    if (!date) {
      return;
    }
    if (monthNameLength) {
      months = _dieselUtilsDates.monthNames.map(function (m) {
        return m.slice(0, monthNameLength);
      });
    }
    _ember['default'].assert('format-date must be called with an instanceof Date', date instanceof Date);

    var monthName = months[date.getMonth()];
    return monthName + ' ' + date.getDate() + ', ' + date.getFullYear();
  }

  exports['default'] = _ember['default'].Helper.helper(function (params) {
    return formatDate.apply(undefined, _toConsumableArray(params));
  });
});