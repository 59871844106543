define('diesel/components/attachment-file/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    attachment: null,

    hasFile: _ember['default'].computed.or('attachment.fileOriginalName', 'attachment.file'),

    progress: _ember['default'].computed('attachment.file.progress', function () {
      return Math.round(this.get('attachment.file.progress'));
    }),

    actions: {
      setAttachmentFile: function setAttachmentFile(file) {
        var attachment = this.get('attachment');
        attachment.setProperties({
          file: file,
          fileOriginalName: file.get('name'),
          fileContentType: file.get('type')
        });

        if (this.get('onAttachment')) {
          this.get('onAttachment')(attachment);
        }
      },

      onAttachment: function onAttachment(attachment) {
        if (!this.get('onAttachment')) {
          return;
        }
        return this.get('onAttachment')(attachment);
      },

      removeAttachmentFile: function removeAttachmentFile() {
        this.get('attachment').setProperties({
          file: null,
          fileOriginalName: null,
          fileContentType: null
        });
      }
    }
  });
});