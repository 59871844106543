define("diesel/validators/uniqueness", ["exports", "ember", "ember-validations/validators/base", "diesel/utils/ajax"], function (exports, _ember, _emberValidationsValidatorsBase, _dieselUtilsAjax) {

  // Note: this.model here refers to the parent controller that instantiated this
  // validator. https://github.com/dockyard/ember-validations/blob/master/addon/mixin.js#L119

  exports["default"] = _emberValidationsValidatorsBase["default"].extend({
    _validate: _ember["default"].on('init', function () {
      return this.call();
    }),

    setupOptions: _ember["default"].on('init', function () {
      if (this.options === true) {
        this.options = {};
      }

      var options = _ember["default"].$.extend({
        message: 'is taken.',
        debounce: 150
      }, this.options);

      this._options = options;
    }),

    getDataPropertyName: function getDataPropertyName() {
      return this.options.paramName || this.property.replace('model.', '');
    },

    call: function call() {
      var _this = this;

      if (_ember["default"].isBlank(_ember["default"].get(this.model, this.property))) {
        return _ember["default"].RSVP.resolve(true);
      }

      return new _ember["default"].RSVP.Promise(function (resolve) {
        _ember["default"].run.debounce(_this, function () {
          _this.fetch(resolve);
        }, _this._options.debounce);
      });
    },

    fetch: function fetch(resolve) {
      var _this2 = this;

      var options = this._options;
      var errors = this.errors;
      var model = this.model.get('model'); // See note above

      if (model) {
        _ember["default"].set(model, 'isValidating', true);
      }

      var url = options.url;
      if (typeof options.url === 'function') {
        url = url.apply(this.model);
      }

      var data = {};
      data[this.getDataPropertyName()] = this.model.get(this.property);

      var headers = options.headers || {};
      if (typeof headers === 'function') {
        headers = headers.apply(this.model);
      }

      var requestOptions = { type: 'post', data: data, headers: headers };

      return (0, _dieselUtilsAjax["default"])(url, requestOptions).then(function () {
        if (!_this2.model.isDestroyed) {
          errors.clear();
        }
        resolve(true);
      }, function () {
        if (!_this2.model.isDestroyed) {
          errors.clear();
          errors.pushObject(options.message);
        }
        resolve(false);
      })["finally"](function () {
        if (model) {
          _ember["default"].set(model, 'isValidating', false);
        }
      });
    }
  });
});