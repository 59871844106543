define('diesel/components/user-contact-item/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['workforce-designation'],
    designationList: _ember['default'].computed('designations.@each.name', function () {
      return this.get('designations').mapBy('name').join(', ');
    }),

    actions: {
      save: function save(userProfile) {
        // userProfile is a promise proxy, not a model yet!
        var model = userProfile.get('content');
        var organizationProfile = this.get('organizationProfile');
        return model.save().then(function () {
          return organizationProfile.observeDocumentBuilds();
        });
      }
    }
  });
});