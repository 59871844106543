define('diesel/components/spd-errors/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    errorMessages: _ember['default'].computed('errors', function () {
      if (this.get('errors.length')) {
        return this.get('errors').join(', ') + '.';
      }
    }),

    actions: {
      clearMessages: function clearMessages() {
        this.set('errors', null);
      }
    }
  });
});