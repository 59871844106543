define('diesel/components/modal-create-role/component', ['exports', 'ember', 'ember-validations', 'diesel/config/environment', 'diesel/mixins/components/modal-base', 'diesel/utils/ignore-invalid-error'], function (exports, _ember, _emberValidations, _dieselConfigEnvironment, _dieselMixinsComponentsModalBase, _dieselUtilsIgnoreInvalidError) {
  exports['default'] = _ember['default'].Component.extend(_emberValidations['default'], _dieselMixinsComponentsModalBase['default'], {
    // Inputs
    newRole: null,

    disableSave: _ember['default'].computed.or('hasError', 'isPending'),
    isPending: _ember['default'].computed.or('newRole.isSaving', 'newRole.isValidating'),
    hasError: _ember['default'].computed.gt('errors.newRole.handle.length', 0),
    title: _ember['default'].computed('authorizationContext.organization.name', function () {
      return 'Create a new role for ' + this.get('authorizationContext.organization.name');
    }),

    description: '',

    validations: {
      'newRole.name': {
        presence: true,
        length: { minimum: 3 }
      }
    },

    actions: {
      beforeDismiss: function beforeDismiss() {
        var record = this.get('newRole');

        if (!_dieselConfigEnvironment['default'].disableRollbackOnModalClose) {
          record.rollbackAttributes();
        }
      },

      createRole: function createRole() {
        var _this = this;

        var role = this.get('newRole');
        return role.save().then(function () {
          _this.sendAction('onCreateRole', role);
        })['catch'](_dieselUtilsIgnoreInvalidError['default']);
      }
    }
  });
});