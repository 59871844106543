define('diesel/risk-assessments/route', ['exports', 'ember'], function (exports, _ember) {

  Error.stackTraceLimit = 999;

  exports['default'] = _ember['default'].Route.extend({
    title: function title() {
      var organization = this.get('complianceStatus.organization');
      return 'Risk Assessments - Gridiron Admin - ' + organization.get('name');
    },

    model: function model() {
      var organization = this.get('complianceStatus.organization');
      var organizationProfile = this.modelFor('gridiron-admin');
      var riskAssessments = organizationProfile.get('riskAssessments');
      riskAssessments = riskAssessments.reload();

      return _ember['default'].RSVP.hash({
        organization: organization, organizationProfile: organizationProfile, riskAssessments: riskAssessments
      });
    },

    afterModel: function afterModel(model) {
      model.riskAssessments.forEach(function (ra) {
        return ra.observePDFBuilds();
      });
    },

    setupController: function setupController(controller, model) {
      controller.setProperties(model);
      controller.set('v1ArchivedRiskAssessments', this.get('complianceStatus.riskAssessmentDocuments'));
    },

    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);

      this.render('sidebars/engine-sidebar', {
        into: 'risk-assessments',
        outlet: 'sidebar',
        controller: this.controllerFor('gridiron-organization')
      });
    }
  });
});