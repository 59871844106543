define('diesel/components/policy-manual-activity/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['panel', 'panel-default', 'compliance-engine-status', 'resource-list-item'],

    draft: _ember['default'].computed.reads('organizationProfile.draftPolicyManual.content'),
    current: _ember['default'].computed.reads('organizationProfile.currentPolicyManual.content'),

    isComplete: _ember['default'].computed.bool('current'),

    status: _ember['default'].computed('isComplete', function () {
      return this.get('isComplete') ? 'complete' : 'incomplete';
    })
  });
});