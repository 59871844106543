define('diesel/components/no-ui-slider/component', ['exports', 'ember', 'diesel/utils/assign'], function (exports, _ember, _dieselUtilsAssign) {

  // see http://refreshless.com/nouislider/ for details
  // This component does not support all the options for nouislider
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['slider'],

    start: 0,
    rangeMin: 0,
    rangeMax: 0,
    step: null,

    validateProperties: (function () {
      _ember['default'].assert('no-ui-slider must have a value for `start`', !_ember['default'].isBlank(this.get('start')));
    }).on('init'),

    initializeSlider: (function () {
      var max = this.get('rangeMax');
      var range = {
        min: [this.get('rangeMin')],
        max: [max]
      };

      var rangeOptions = this.get('range');
      if (rangeOptions) {
        var values = rangeOptions.split(',').filter(function (val) {
          return val !== max;
        }).map(function (v) {
          return parseInt(v);
        }).sort(function (a, b) {
          return a - b;
        });
        var num = values.length - 1;

        var arr = values.map(function (e, i) {
          var percent = 100 / num * i;
          var value = {};
          value[percent + '%'] = values[i];
          return value;
        }).reduce(function (a, b) {
          return (0, _dieselUtilsAssign['default'])(a, b);
        });

        range = (0, _dieselUtilsAssign['default'])(range, arr);
      }

      var options = {
        start: [this.get('start')],
        range: range,
        connect: 'lower'
      };

      var step = this.get('step');
      if (step) {
        options.step = step;
      }

      var snap = this.get('snap');
      if (snap) {
        options.snap = snap;
      }

      var element = this.$();
      element.noUiSlider(options);

      var disabled = this.get('disabled');
      if (disabled) {
        element.attr('disabled', true);
      }

      this.setupEventListeners();
    }).on('didInsertElement'),

    setupEventListeners: function setupEventListeners() {
      var component = this;

      // `slide` event fired continuously during slide
      this.$().on('slide', function (e, value) {
        value = parseFloat(value);

        _ember['default'].run(component, 'sendAction', 'didSlide', value);
      });

      // `set` event fired once when user stops dragging/sliding
      this.$().on('set', function (e, value) {
        value = parseFloat(value);

        _ember['default'].run(component, 'sendAction', 'didSet', value);
      });
    },

    disabledObserver: (function () {
      var element = this.$();
      element.attr('disabled', this.get('disabled'));
    }).observes('disabled'),

    removeEventListeners: (function () {
      this.$().off('slide');
      this.$().off('set');
    }).on('willDestroyElement')
  });
});