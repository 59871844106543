define('diesel/components/container-size-slider/component', ['exports', 'ember'], function (exports, _ember) {

  var CONTAINER_SIZES = [512, 1 * 1024, 2 * 1024, 4 * 1024, 7 * 1024];

  var CONTAINER_SIZE_DEFAULT = 1024;

  exports['default'] = _ember['default'].Component.extend({
    // Inputs
    initialSize: null,

    // Optional inputs
    disabled: false,

    classNames: ['slider-wrapper', 'slider-container-size'],

    containerSizeMin: _ember['default'].computed.min('containerSizeRange'),
    containerSizeMax: _ember['default'].computed.max('containerSizeRange'),
    containerSizeRange: _ember['default'].computed('initialSize', function () {
      var initialSize = this.get('initialSize');
      var sizes = _ember['default'].copy(CONTAINER_SIZES);
      sizes.push(initialSize);
      return sizes.uniq().sort(function (a, b) {
        return a - b;
      });
    }),

    containerSizeRangeForSlider: _ember['default'].computed('containerSizeRange', function () {
      return this.get('containerSizeRange').join(',');
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get('initialSize')) {
        this.set('initialSize', CONTAINER_SIZE_DEFAULT);
        this.send('didSlide', CONTAINER_SIZE_DEFAULT);
      }
    },

    actions: {
      didSlide: function didSlide(v) {
        this.sendAction('didSlide', v);
      },

      didSet: function didSet(v) {
        this.sendAction('didSet', v);
      }
    }

  });
});