define('diesel/components/whitelist-table-row/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['aptable__member-row'],
    tagName: 'tr',

    actions: {
      remove: function remove() {
        var user = this.get('user');
        this.sendAction('removeFromWhitelist', user);
      }
    }
  });
});