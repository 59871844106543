define('diesel/components/metric-drain-panel/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    metricDrain: null,

    actions: {
      onRestartAction: function onRestartAction(metricDrain) {
        this.sendAction('restartMetricDrain', metricDrain);
      },

      onDeleteAction: function onDeleteAction(metricDrain) {
        this.sendAction('deleteMetricDrain', metricDrain);
      }
    }
  });
});