define('diesel/models/system-template', ['exports', 'ember-data', 'diesel/models/base', 'ember'], function (exports, _emberData, _dieselModelsBase, _ember) {
  var computed = _ember['default'].computed;
  var reads = _ember['default'].computed.reads;
  var ENCLAVE_DB_HANDLE = 'enclave_db';
  exports.ENCLAVE_DB_HANDLE = ENCLAVE_DB_HANDLE;
  var ENCLAVE_APP_HANDLE = 'enclave_app';

  exports.ENCLAVE_APP_HANDLE = ENCLAVE_APP_HANDLE;
  exports['default'] = _dieselModelsBase['default'].extend({
    appTypes: _emberData['default'].attr(),
    description: _emberData['default'].attr(),
    handle: _emberData['default'].attr(),
    name: _emberData['default'].attr(),
    type: _emberData['default'].attr(),
    items: _emberData['default'].attr(),

    isPrimary: computed.equal('type', 'primary'),
    isStorage: computed.equal('type', 'storage'),
    isLogging: computed.equal('type', 'logging'),
    isWebService: computed.equal('type', 'saas'),
    isDevice: computed.equal('type', 'device'),

    vendorTemplate: _emberData['default'].belongsTo('vendorTemplate', { async: false }),
    readableVendor: reads('vendorTemplate.name'),

    supportsAppType: function supportsAppType(type) {
      if (this.get('isWebService') || this.get('isLogging')) {
        return true;
      }

      var requiredTypes = this.get('appTypes') || [];
      return requiredTypes.includes('*') || requiredTypes.includes(type);
    },

    createSystem: function createSystem(organizationProfile, store) {
      var _getProperties = this.getProperties('items', 'name', 'type', 'handle', 'description');

      var items = _getProperties.items;
      var name = _getProperties.name;
      var type = _getProperties.type;
      var handle = _getProperties.handle;
      var description = _getProperties.description;

      var shortName = organizationProfile.get('shortName');
      var opsRoleUrl = organizationProfile.get('links.ops_role');
      var opsRoleName = organizationProfile.get('opsRole.name');

      if (items) {
        items = items.map(function (item) {
          return item.replace('{{shortName}}', shortName);
        });
      }

      var attrs = {
        items: items,
        name: name,
        type: type,
        description: description,
        organizationProfile: organizationProfile,
        systemTemplate: this,
        templateHandle: handle
      };

      if (opsRoleUrl) {
        attrs.ownerRoleUrl = opsRoleUrl;
        attrs.ownerRoleName = opsRoleName || 'Ops Team';
      }

      return store.createRecord('system', attrs);
    }
  });
});