define("diesel/utils/ajax", ["exports", "ember", "diesel/config/environment"], function (exports, _ember, _dieselConfigEnvironment) {
  exports["default"] = function (url, options) {
    if (_dieselConfigEnvironment["default"].environment === "test") {
      url = url.replace(new RegExp('[^/]*(//)?[^/]*/'), '/');
    }
    return new _ember["default"].RSVP.Promise(function (resolve, reject) {
      options.dataType = 'json';
      options.success = _ember["default"].run.bind(null, resolve);
      options.error = _ember["default"].run.bind(null, reject);
      options.crossDomain = true;
      options.contentType = 'application/json';
      options.data = JSON.stringify(options.data);
      _ember["default"].$.ajax(url, options);
    });
  };
});