define('diesel/controls/control/implementation/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    title: function title() {
      return "Control Performance";
    },
    model: function model() {
      return this.modelFor('controls.control');
    },

    setupController: function setupController(controller, model) {
      var _modelFor = this.modelFor('controls');

      var requiresDedicatedStack = _modelFor.requiresDedicatedStack;
      var organization = _modelFor.organization;
      var reportScope = _modelFor.reportScope;

      controller.setProperties({
        organization: organization,
        model: model,
        reportScope: reportScope,
        requiresDedicatedStack: requiresDedicatedStack
      });
    }
  });
});