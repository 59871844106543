define('diesel/role/environments/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.modelFor('role');
    },

    redirect: function redirect() {
      var role = this.modelFor('role');
      // If this is a compliance role, users shouldn't be here
      if (role.get('isComplianceRole')) {
        this.transitionTo('role.members');
      }
    },

    setupController: function setupController(controller, model) {
      var contextHref = model.get('links.organization');
      var context = this.get('authorization').getContextByHref(contextHref);

      controller.set('model', model);
      controller.set('accounts', context.get('accounts').sortBy('handle'));
      controller.set('organization', context.get('organization'));
      controller.set('currentUserRoles', context.get('currentUserRoles'));
      controller.set('authorizationContext', context);
    }
  });
});