define('diesel/error/route', ['exports', 'ember', 'diesel/utils/location'], function (exports, _ember, _dieselUtilsLocation) {
  exports['default'] = _ember['default'].Route.extend({
    actions: {
      doLogout: function doLogout() {
        return this.get('session').close('application', {
          token: this.get('session.token')
        })['finally'](function () {
          return _dieselUtilsLocation['default'].replaceAndWait('/');
        });
      }
    }
  });
});