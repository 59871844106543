define('diesel/initializers/ember-feature-flags', ['exports', 'diesel/config/environment', 'diesel/services/features'], function (exports, _dieselConfigEnvironment, _dieselServicesFeatures) {
  exports.initialize = initialize;

  function initialize() {
    var application = arguments[1] || arguments[0];
    var serviceName = _dieselConfigEnvironment['default'].featureFlagsService || 'features';
    var serviceLookupName = 'service:' + serviceName;

    application.register(serviceLookupName, _dieselServicesFeatures['default']);
    application.inject('route', serviceName, serviceLookupName);
    application.inject('controller', serviceName, serviceLookupName);
    application.inject('component', serviceName, serviceLookupName);
    application.inject(serviceLookupName, 'application', 'application:main');
  }

  exports['default'] = {
    name: 'ember-feature-flags',
    initialize: initialize
  };
});