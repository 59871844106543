define('diesel/components/c-control-mappings-table/component', ['exports', 'ember'], function (exports, _ember) {

  var sortOptions = [["status:asc", 'Sort by Status'], ["name:asc", 'Sort by Name']];

  exports['default'] = _ember['default'].Component.extend({
    tagName: "",
    controls: null,
    categories: null,
    searchValue: null,
    sortOptions: sortOptions,
    selectedFramework: null,
    selectedSort: sortOptions[0][0],
    selectedCategory: null,
    selectedSearchTerm: null,

    filteredResults: _ember['default'].computed('controls', 'selectedSort', 'selectedCategory', 'selectedSearchTerm', 'selectedFramework', function () {
      //const { controls, selectedSort, selectedCategory, selectedSearchTerm } = this.getProperties('controls', 'selectedSort', 'selectedCategory', 'selectedSearchTerm', 'selectedFramework');
      return this.get('controls').filter(function (c) {
        return true;
      });
    })
  });
});