define('diesel/database/deprovision/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    titleToken: function titleToken() {
      var database = this.modelFor('database');
      return 'Deprovision ' + database.get('handle');
    },

    actions: {
      deprovision: function deprovision() {
        var database = this.currentModel;
        var route = this;
        var controller = this.controller;
        var store = this.store;
        var message = database.get('handle') + ' is now deprovisioning';
        controller.set('error', null);

        database.get('account').then(function (account) {
          var op = store.createRecord('operation', {
            type: 'deprovision',
            database: database
          });
          op.save().then(function () {
            database.set('status', 'deprovisioning');
            // This is kind of crappy, but the reloading doesn't work properly unless the model is saved.
            database.save();
          }).then(function () {
            route.transitionTo('databases', account);
            _ember['default'].get(route, 'flashMessages').success(message);
          }, function (e) {
            controller.set('error', e);
          });
        });
      }
    }

  });
});