define('diesel/app/vhosts/edit/route', ['exports', 'ember', 'diesel/utils/fetch-all-pages'], function (exports, _ember, _dieselUtilsFetchAllPages) {
  exports['default'] = _ember['default'].Route.extend({
    title: function title() {
      var app = this.modelFor('app');

      return 'Edit ' + this.currentModel.vhost.get('virtualDomain') + ' - ' + app.get('handle');
    },

    model: function model(params) {
      var app = this.modelFor('app');
      var account = app.get('account');

      return _ember['default'].RSVP.hash({
        vhost: this.store.find('vhost', params.vhost_id),
        certificates: (0, _dieselUtilsFetchAllPages['default'])(this.store, account, 'certificate'),
        account: account
      });
    },

    afterModel: function afterModel(model) {
      var vhost = model.vhost;

      return _ember['default'].RSVP.hash({
        service: vhost.get('service'),
        certificate: vhost.get('certificate')
      });
    },

    setupController: function setupController(controller, model) {
      var vhost = model.vhost;

      controller.set('model', vhost);
      controller.set('certificates', model.certificates);
      controller.set('originalCertificate', vhost.get('certificate'));
    },

    actions: {
      save: function save() {
        var _this = this;

        var vhost = this.currentModel.vhost;
        var account = this.currentModel.account;
        var certificatePromise = undefined;

        // We only issue a provision operation if the VHOST is already
        // provisioned. This ensures that if a customer is e.g. updating their
        // cert on a running VHOST, the change will apply, but if that they're
        // just trying to use a new certificate and get their ALB to provision,
        // we send them to aptible restart.
        var willProvision = vhost.get("isProvisioned");

        if (vhost.get('certificateBody')) {
          var certificateBody = vhost.get('certificateBody');
          var privateKey = vhost.get('privateKey');
          var newCertificate = this.store.createRecord('certificate', { certificateBody: certificateBody, account: account, privateKey: privateKey });

          certificatePromise = newCertificate.save();
        } else {
          certificatePromise = new _ember['default'].RSVP.resolve(vhost.get('certificate'));
        }

        certificatePromise.then(function (certificate) {
          vhost.setProperties({ certificate: certificate, certificateBody: null, privateKey: null });
          return vhost.save();
        }).then(function () {
          if (!willProvision) {
            var message = 'Changes saved, restart your app to apply them.';
            _ember['default'].get(_this, 'flashMessages').warning(message);
            return vhost.reload();
          }

          var op = _this.store.createRecord('operation', {
            type: 'provision',
            vhost: vhost
          });

          return op.save().then(function () {
            var message = 'Endpoint ' + vhost.get('virtualDomain') + ' updated, your changes will take a few minutes to apply.';
            _ember['default'].get(_this, 'flashMessages').success(message);
            return vhost.reload();
          });
        }).then(function () {
          _this.transitionTo('app.vhosts.index');
        })['catch'](function (e) {
          var message = _ember['default'].get(e, 'message') || 'There was an error updating the ' + vhost.get('virtualDomain') + ' endpoint';
          _ember['default'].get(_this, 'flashMessages').danger(message);
        });
      },

      cancel: function cancel() {
        this.transitionTo('app.vhosts.index');
      },

      willTransition: function willTransition() {
        this.currentModel.vhost.set('certificate', this.controller.get('originalCertificate'));
        this.currentModel.vhost.rollbackAttributes();
      }
    }
  });
});