define('diesel/components/simple-create-component/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    className: ['simple-create-component'],
    // Inputs
    component: null,
    roles: null,

    actions: {
      clearMessages: function clearMessages() {
        this.set('errors', null);
      },

      beforeDismiss: function beforeDismiss() {
        this.setProperties({ errors: null, component: null });
      },

      updateRoleOwner: function updateRoleOwner(role) {
        var component = this.get('component');
        component.setOwnerRole(role);
      },

      save: function save() {
        var component = this.get('component');
        this.get('save')(component);
      },

      cancel: function cancel() {
        this.get('cancel')();
      }
    }
  });
});