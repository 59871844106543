define('diesel/mixins/components/resizable-modal', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    // Amount of space "around" the panel used to offset from window height.
    // You probably won't change this.
    resizeWindowBuffer: 350,

    // Minimum height of panel.  Panel won't be resized below this height
    minPanelHeight: 350,

    // Number of miliseconds to debounce resize handler
    debounceInterval: 50,

    // Class name of window
    fixedWindowSelector: '.panel-body',
    fixedWindowClass: 'fixed-panel-body',

    setup: _ember['default'].on('didInsertElement', function () {
      // After initializing add default fixed window class

      /* 
        If we want to remove the overflow to all modals,
        I will have to dig into which modals tooltips are off screen. 
        I do not know how changing the overflow for all modals will work  
      */

      this._fixedBodyEl().addClass(this.fixedWindowClass);
    }),

    _fixedBodyEl: function _fixedBodyEl() {
      return this.$(this.fixedWindowSelector);
    },

    _onWindowResize: function _onWindowResize() {
      _ember['default'].run.debounce(this, this._onDebouncedWindowResize, this.debounceInterval, true);
    },

    _onDebouncedWindowResize: function _onDebouncedWindowResize() {
      var currentWindowHeight = this._window.height();
      var newHeight = currentWindowHeight - this.resizeWindowBuffer;

      newHeight = Math.max(newHeight, this.minPanelHeight);

      this._fixedBodyEl().height(newHeight);
    },

    removeResizeListener: _ember['default'].on('willDestroyElement', function () {
      this._window.off('resize', this._resizeHandler);
      this._window = null;
      this._resizeHandler = null;
    }),

    addResizeListener: _ember['default'].on('didInsertElement', function () {
      this._window = _ember['default'].$(window);
      this._resizeHandler = this._onWindowResize.bind(this);
      this._window.on('resize', this._resizeHandler);
      this._onWindowResize();
    }),

    scrollToTop: function scrollToTop() {
      this.$('.fixed-panel-body').scrollTop(0);
    }
  });
});