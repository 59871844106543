define("diesel/adapters/organization", ["exports", "diesel/adapters/auth"], function (exports, _dieselAdaptersAuth) {
  exports["default"] = _dieselAdaptersAuth["default"].extend({
    query: function query(store, type, _query) {
      if (!_query.reauthenticate) {
        return this._super.apply(this, arguments);
      }

      var url = this.buildURL("reauthenticate_" + type.modelName, null, null, "query");
      return this.ajax(url, "GET");
    }
  });
});