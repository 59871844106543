define('diesel/services/metrics-api', ['exports', 'ember', 'diesel/utils/ajax', 'diesel/config/environment'], function (exports, _ember, _dieselUtilsAjax, _dieselConfigEnvironment) {

  function errorToMessage(e) {
    switch (e.status) {
      case 0:
        return "Metrics server is unavailable; please try again later";
      case 400:
        return "Metrics server declined to serve the request; please try reloading the page.";
      case 404:
        return "No data available. If you just launched your containers, please try again later. Otherwise, please contact support (metrics are partially unavailable on Aptible legacy infrastructure)";
      case 500:
        return "Metrics server is unavailable; please try again later";
      default:
        return "An unknown error occurred";
    }
  }

  exports['default'] = _ember['default'].Service.extend({
    session: _ember['default'].inject.service(),

    fetchMetrics: function fetchMetrics(containers, ts, horizon, metric) {
      var accessToken = this.get("session.token.accessToken");
      var containerIds = containers.map(function (container) {
        return container.get("id");
      });

      return (0, _dieselUtilsAjax['default'])(_dieselConfigEnvironment['default'].metricsBaseUri + '/proxy/' + containerIds.join(":") + '?horizon=' + horizon + '&ts=' + ts + '&metric=' + metric, {
        headers: {
          "Authorization": 'Bearer ' + accessToken
        }
      })['catch'](function (e) {
        throw new Error(errorToMessage(e));
      });
    }
  });
});