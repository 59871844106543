define('diesel/organization/roles/type/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    confirmationModal: _ember['default'].inject.service(),

    actions: {
      inviteTo: function inviteTo(role) {
        var organization = this.get('organization');
        this.transitionToRoute('organization.invite', organization, { queryParams: { role: role } });
      }
    }
  });
});