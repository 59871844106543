define('diesel/components/log-drain-item/component', ['exports', 'ember'], function (exports, _ember) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var DRAIN_FLAGS = [['apps', 'drainApps'], ['databases', 'drainDatabases'], ['SSH sessions', 'drainEphemeralSessions'], ['endpoints', 'drainProxies']];

  exports['default'] = _ember['default'].Component.extend({
    drainCaptures: _ember['default'].computed.apply(_ember['default'], _toConsumableArray(DRAIN_FLAGS.map(function (e) {
      return 'logDrain.' + e[1];
    })).concat([function () {
      var _this = this;

      var componentsLogged = [];

      DRAIN_FLAGS.forEach(function (e) {
        if (_this.get('logDrain.' + e[1])) {
          componentsLogged.push(e[0]);
        }
      });

      if (componentsLogged.length < 1) {
        return 'nothing';
      }

      var last = componentsLogged.pop();

      if (componentsLogged.length < 1) {
        return last;
      }

      return componentsLogged.join(', ') + ' and ' + last;
    }])),

    actions: {
      completedAction: function completedAction(message) {
        this.sendAction('completedAction', message);
      },
      failedAction: function failedAction(message) {
        this.sendAction('failedAction', message);
      }
    }
  });
});