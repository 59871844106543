define("diesel/mixins/controllers/signup", ["exports", "ember", "ember-validations", "diesel/config/environment"], function (exports, _ember, _emberValidations, _dieselConfigEnvironment) {
  exports["default"] = _ember["default"].Mixin.create(_emberValidations["default"], {
    isSaving: false,
    hasSubmitted: false,

    validateOrganization: function validateOrganization() {
      return !!this.get('organization');
    },

    validations: {
      'organization.name': {
        presence: { 'if': 'validateOrganization' },
        length: {
          'if': 'validateOrganization',
          minimum: 3
        }
      },
      'model.name': {
        presence: true,
        length: { minimum: 3 }
      },
      'model.email': {
        presence: true,
        email: true,
        uniqueness: {
          message: 'is in use.',
          url: (_dieselConfigEnvironment["default"].authBaseUri || '') + "/claims/user"
        }
      },
      'model.password': {
        presence: true,
        'password-complexity': true
      }
    },

    actions: {
      validateAndSignup: function validateAndSignup() {
        var _this = this;

        var hasSubmitted = this.get('hasSubmitted');
        if (!hasSubmitted) {
          this.set('hasSubmitted', true);
        }

        this.validate().then(function () {
          var user = _this.get('model');

          // will not be present if the user is signing up
          // to accept an invitation
          var organization = _this.get('organization');

          _this.get('target').send('signup', user, organization);
        })["catch"](function () {
          // Silence the validation exception, display it in UI
        });
      }
    }
  });
});