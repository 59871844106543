define('diesel/components/select-pills/component', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].Component.extend({
    name: null,
    'class': '',
    selected: null,
    selectedKey: null,
    itemKey: null,
    itemValue: null,
    optionTagName: 'span',

    items: _ember['default'].computed(function () {
      return _ember['default'].A([]);
    }),

    byKey: _ember['default'].computed.bool('selectedKey'),

    /**
     * @return [{key, value}]
     */
    keyValues: _ember['default'].computed('items.[]', 'itemKey', 'itemValue', function () {
      var _getProperties = this.getProperties('items', 'itemKey', 'itemValue');

      var items = _getProperties.items;
      var itemKey = _getProperties.itemKey;
      var itemValue = _getProperties.itemValue;

      return items.map(function (item) {
        var key = itemKey ? get(item, itemKey) : item;
        var value = itemValue ? get(item, itemValue) : item;
        return { key: key, value: value, item: item };
      });
    }),

    // returns the selectedIndex if there is a selection, else -1
    selectedIndex: _ember['default'].computed('items.[]', 'selectedValues.[]', 'selected', 'selectedKey', 'itemKey', function () {
      var _getProperties2 = this.getProperties('selected', 'selectedKey', 'itemKey');

      var selected = _getProperties2.selected;
      var selectedKey = _getProperties2.selectedKey;
      var itemKey = _getProperties2.itemKey;

      _ember['default'].assert('Only one of `selected` or `selectedKey` may be used in {{select-pills}}', !(selected && selectedKey));

      var haystack = [];
      var needle = undefined;

      if (selectedKey) {
        haystack = this.get('keyValues').mapBy('key');
        needle = selectedKey;
      } else if (selected) {
        haystack = this.get('items');
        needle = selected;

        if (itemKey) {
          haystack = haystack.mapBy(itemKey);
          needle = get(selected, itemKey);
        }
      }

      return haystack.indexOf(needle);
    }),

    actions: {
      selectItem: function selectItem(item) {
        if (this.get('disabled')) {
          return;
        }

        if (this.get('byKey')) {
          var itemKey = this.get('itemKey');
          var newSelectionKey = get(item, itemKey);
          this.set('selectedKey', newSelectionKey);
          this.attrs.update(newSelectionKey);
        } else {
          this.set('selected', item);
          this.attrs.update(item);
        }
      }
    }
  });
});