define('diesel/mixins/routes/agreement-owner-route-mixin', ['exports', 'ember'], function (exports, _ember) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var assert = _ember['default'].assert;
  var _Ember$String = _ember['default'].String;
  var pluralize = _Ember$String.pluralize;
  var capitalize = _Ember$String.capitalize;
  exports['default'] = _ember['default'].Mixin.create({
    modalDisplay: _ember['default'].inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      assert('Must set \'MODEL_NAME\' prop on AgreementOwnerRouteMixin', !!this.get('MODEL_NAME'));
    },

    title: function title() {
      return 'Gridiron ' + capitalize(this.get('MODEL_NAME')) + ' Management - ' + this.currentModel.get('name');
    },

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('organizationProfile', this.get('complianceStatus.organizationProfile'));
    },

    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);

      this.render('sidebars/engine-sidebar', {
        into: this.get('MODEL_NAME'),
        outlet: 'sidebar',
        controller: this.controllerFor('gridiron-organization')
      });
    },

    openAgreementModal: function openAgreementModal(params) {
      var modalDisplay = this.get('modalDisplay');
      var modalName = 'modal-' + this.get('MODEL_NAME') + '-agreement';
      modalDisplay.showModal(modalName, { agreementModal: params }, {
        actions: {
          onAgreementUpdate: modalDisplay.modalAction(this, 'onAgreementUpdate')
        }
      });
    },

    openAttachmentModal: function openAttachmentModal(params) {
      var modalDisplay = this.get('modalDisplay');
      var modalName = 'modal-attachment';
      modalDisplay.showModal(modalName, { attachmentModal: params }, {
        actions: {
          // onAgreementUpdate: modalDisplay.modalAction(this, 'onAgreementUpdate')
        }
      });
    },

    actions: {
      openEditModal: function openEditModal() {
        var model = this.currentModel;
        var modalDetails = { model: model };
        var modalDisplay = this.get('modalDisplay');
        var modalName = 'modal-' + this.get('MODEL_NAME');
        modalDisplay.showModal(modalName, { modalDetails: modalDetails }, {
          actions: { onUpdate: modalDisplay.modalAction(this, 'onUpdate') }
        });
      },

      onUpdate: function onUpdate(model) {
        var _this = this;

        this.refresh();
        _ember['default'].run.schedule('afterRender', function () {
          var message = 'Successfully updated ' + model.get('name');
          _ember['default'].get(_this, 'flashMessages').success(message);
        });
      },

      remove: function remove() {
        var _this2 = this;

        var model = this.currentModel;
        var confirmMsg = '\nAre you sure you want to remove ' + model.get('name') + '?\n';

        if (!window.confirm(confirmMsg)) {
          return false;
        }

        var itemKey = pluralize(this.get('MODEL_NAME'));
        var orgProfile = this.get('complianceStatus.organizationProfile');

        model.destroyRecord().then(function () {
          orgProfile.get(itemKey).removeObject(model);
          _this2.transitionTo(itemKey, orgProfile);
        })['catch'](function (e) {
          _this2.get('flashMessages').danger('There was an error while trying to remove the ' + _this2.get('MODEL_NAME') + ': ' + e.message);
        });
      },

      // Agreements
      createAgreementModal: function createAgreementModal() {
        var model = this.currentModel;
        var agreement = this.store.createRecord('agreement', _defineProperty({}, this.get('MODEL_NAME'), model));
        var attachment = this.store.createRecord('attachment', { agreement: agreement });
        this.openAgreementModal({ agreement: agreement, attachment: attachment });
        //this.controller.set('agreementModal', { agreement, attachment });
      },

      openAgreementModal: function openAgreementModal(agreement) {
        var attachment = agreement.get('attachments.firstObject');
        this.openAgreementModal({ agreement: agreement, attachment: attachment });
        //this.controller.set('agreementModal', { agreement, attachment });
      },

      openAttachmentModal: function openAttachmentModal(agreement) {
        this.openAttachmentModal({ agreement: agreement });
      },

      onAgreementUpdate: function onAgreementUpdate(success) {
        var _this3 = this;

        var model = this.currentModel;
        var verb = success.verb;
        var agreement = success.agreement;

        var message = 'Successfully ' + success.verb + ' ' + agreement.get('name');
        model.get('agreements').reload();
        _ember['default'].get(this, 'flashMessages').success(message);

        if (verb === 'added') {
          _ember['default'].run.later(function () {
            _this3.openAttachmentModal({ agreement: agreement });
          });
        }
      },

      removeAgreement: function removeAgreement(agreement) {
        var _this4 = this;

        var model = this.currentModel;
        var confirmMsg = '\nAre you sure you want to remove the ' + model.get('name') + ' ' + agreement.get('name') + '?\n';
        var message = 'Successfully deleted ' + agreement.get('name');

        if (!window.confirm(confirmMsg)) {
          return false;
        }

        agreement.destroyRecord().then(function () {
          _ember['default'].get(_this4, 'flashMessages').success(message);
          model.get('agreements').reload();
        })['catch'](function (e) {
          _this4.get('flashMessages').danger('There was an error while trying to remove the ' + _this4.get('MODEL_NAME') + ': ' + e.message);
        });
      }
    }
  });
});