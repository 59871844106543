define('diesel/components/account-type-selector/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['split-selector-options'],

    // Inputs
    initialType: null,

    // State
    activeType: null,

    isProduction: _ember['default'].computed.equal('activeType', 'production'),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (!this.get('activeType')) {
        this.doUpdate(this.get('initialType') || 'development');
      }
    },

    doUpdate: function doUpdate(type) {
      var _this = this;

      // TODO-double-modify This afterRender call is slow and should be removed.
      _ember['default'].run.schedule('afterRender', function () {
        _this.set('activeType', type);
        _this.sendAction('update', type);
      });
    },

    actions: {
      updateType: function updateType(type) {
        this.doUpdate(type);
      }
    }
  });
});