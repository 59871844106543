define('diesel/gridiron-admin/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.get('complianceStatus.organizationProfile');
    },

    setupController: function setupController(controller) {
      var organization = this.get('complianceStatus.organization');
      controller.set('organizations', this.modelFor('gridiron').get('organization'));
      controller.set('organization', organization);
    },

    redirect: function redirect(model) {
      var isComplianceAdmin = this.get('authorization').checkAbility('manage', model);

      // Redirect to gridiron user for users without manage scope
      if (!isComplianceAdmin) {
        var message = 'Access Denied: You must be a Gridiron Owner in order to view this page';
        _ember['default'].get(this, 'flashMessages').danger(message);

        this.transitionTo('gridiron-user');
      }
    }
  });
});