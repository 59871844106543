define('diesel/components/hipaa-security-rule-section/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['report-section', 'hipaa-security-rule-section'],
    securityControls: _ember['default'].computed('section.requirements.[]', function () {
      var securityControls = [];

      this.get('section.requirements').forEach(function (requirement) {
        securityControls = securityControls.concat(requirement.security_controls);
      });

      return securityControls;
    })
  });
});