define('diesel/models/security-control', ['exports', 'ember-data', 'diesel/models/base', 'ember', 'diesel/mixins/models/schema-document'], function (exports, _emberData, _dieselModelsBase, _ember, _dieselMixinsModelsSchemaDocument) {
  exports['default'] = _dieselModelsBase['default'].extend(_dieselMixinsModelsSchemaDocument['default'], {
    SCHEMA_KEY: 'security_controls',
    riskAssessment: _emberData['default'].belongsTo('riskAssessment'),
    handle: _emberData['default'].attr('string'),
    title: _emberData['default'].attr('string', { defaultValue: '' }),
    description: _emberData['default'].attr('string', { defaultValue: '' }),
    efficacy: _emberData['default'].attr('number'),
    implemented: _emberData['default'].attr(),

    hasRelevantThreats: _ember['default'].computed.gt('relevantThreatEvents.length', 0),
    relevantThreatEvents: _ember['default'].computed.filterBy('threatEvents', 'relevant', true),
    relevant: _ember['default'].computed.reads('hasRelevantThreats'),
    status: _ember['default'].computed('implemented', function () {
      switch (this.get('implemented')) {
        case true:
          return 'implemented';
        case false:
          return 'unimplemented';
        default:
          return 'unconfigured';
      }
    }),

    statusText: _ember['default'].computed('status', function () {
      return this.get('status').capitalize();
    }),

    plannedMilestone: _emberData['default'].attr('string'),
    planned: _ember['default'].computed.bool('plannedMilestone'),

    vulnerabilities: _emberData['default'].hasMany('vulnerability', {
      async: false
    }),
    mitigations: _emberData['default'].hasMany('mitigation', {
      async: false
    }),
    category: _emberData['default'].belongsTo('security-control-category', {
      async: false
    }),
    isOn: _ember['default'].computed.equal('implemented', true),
    predisposingConditions: _ember['default'].computed('threatEvents.[]', function () {
      var pdcs = {};
      this.get('threatEvents').forEach(function (te) {
        te.get('predisposingConditions').forEach(function (pdc) {
          pdcs[pdc.get('id')] = pdc;
        });
      });

      return Object.keys(pdcs).map(function (id) {
        return pdcs[id];
      });
    }),

    threatEvents: _ember['default'].computed('vulnerabilities.[]', function () {
      var threatEvents = {};
      this.get('vulnerabilities').forEach(function (vulnerability) {
        vulnerability.get('threatEvents').forEach(function (te) {
          threatEvents[te.get('id')] = te;
        });
      });

      return Object.keys(threatEvents).map(function (id) {
        return threatEvents[id];
      });
    }),

    relevanceTitle: _ember['default'].computed('predisposingConditions.[]', function () {
      var pdcs = this.get('predisposingConditions').filterBy('relevant').mapBy('title');
      var s = pdcs.length === 1 ? '' : 's';
      return 'This Security Control is considered relevant based off the relevant Predisposing Condition' + s + ': ' + pdcs;
    }),

    relevantIndex: _ember['default'].computed('relevant', function () {
      //for sorting easil
      return this.get('relevant') ? 0 : 1;
    })
  });
});