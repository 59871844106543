define('diesel/organization/admin/billing/billing-iteration/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params) {
      var _this = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _this.store.find('billing-iteration', params.billing_iteration_id).then(function (bi) {
          var invoice = bi.get('hasInvoice') ? bi.get('invoice') : bi.get('projectedInvoice');
          invoice.then(resolve);
        })['catch'](reject);
      });
    }
  });
});