define('diesel/components/modal-target/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    modalDisplay: _ember['default'].inject.service(),

    actions: {
      /**
       * This action makes it possible for a component rendered by the modal-target
       * template invocation of `{{component` to call `this.sendAction('dismissModal')
       */
      dismissModal: function dismissModal() {
        this.get('modalDisplay').dismissModal();
      }
    }
  });
});