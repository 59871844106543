define('diesel/training/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    title: function title() {
      var organization = this.get('complianceStatus.organization.name');
      return 'Training - Gridiron Admin - ' + organization;
    },

    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);

      this.render('sidebars/engine-sidebar', {
        into: 'training',
        outlet: 'sidebar',
        controller: this.controllerFor('gridiron-organization')
      });
    }
  });
});