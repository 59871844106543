define('diesel/components/delete-vhost/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'span',

    store: _ember['default'].inject.service(),

    vhost: null,
    isProvisioning: _ember['default'].computed.equal('vhost.isProvisioning', true),
    isDeprovisioning: _ember['default'].computed.equal('vhost.isDeprovisioning', true),

    actions: {
      'delete': function _delete() {
        var _this = this;

        var vhost = this.get('vhost');
        var message = 'Confirm Delete\n\nAre you sure you want to delete ' + vhost.get('displayName') + '?';

        if (!confirm(message)) {
          return;
        }

        var op = this.get('store').createRecord('operation', {
          type: 'deprovision',
          vhost: vhost
        });

        this.sendAction('startDeletion');
        this.set('isDeleting', true);

        op.save().then(function () {
          vhost.set('status', 'deprovisioning');
          _this.sendAction('completeDeletion');
        })['catch'](function (e) {
          vhost.set('status', 'deprovision_failed');
          _this.sendAction('failDeletion', e);
        });
      }
    }
  });
});