define('diesel/components/threat-event-filters/component', ['exports', 'ember', 'diesel/utils/six-point-scale'], function (exports, _ember, _dieselUtilsSixPointScale) {

  var SORT = [{ title: 'Highest Risk Level', value: 'riskLevel' }, { title: 'Highest Relevance', value: 'relevance' }, { title: 'Highest Impact', value: 'impact' }, { title: 'Title', value: 'title' }];

  exports['default'] = _ember['default'].Component.extend({
    filters: { search: '', sort: SORT[0].value, relevance: null, minimumRiskLevel: 3 },

    classNames: ['risk-assessment__filters form-inline'],
    sorts: SORT,
    relevances: _dieselUtilsSixPointScale['default'],
    impacts: _dieselUtilsSixPointScale['default'].slice(1),
    riskLevels: _dieselUtilsSixPointScale['default'].slice(1),

    showClear: _ember['default'].computed('filters.search', 'filters.minimumRiskLevel', 'filters.relevance', 'filters.impact', function () {
      var currentSearch = this.get('filters.search') || '';
      var relevance = this.get('filters.relevance');
      var impact = this.get('filters.impact');
      var level = this.get('filters.minimumRiskLevel');

      return _ember['default'].$.trim(currentSearch) !== '' || relevance && relevance !== '' || impact && impact !== '' || level && level !== '';
    }),

    actions: {
      clear: function clear() {
        this.set('filters.sort', SORT[0].value);
        this.set('filters.minimumRiskLevel', null);
        this.set('filters.relevance', null);
        this.set('filters.impact', null);
        this.set('filters.adversarial', null);
        this.set('filters.search', '');
      },

      update: function update() {
        var sort = this.$('select.sort').val();
        var relevance = parseInt(this.$('select.relevance').val(), 10);
        var impact = parseInt(this.$('select.impact').val(), 10);
        var minimumRiskLevel = parseInt(this.$('select.minimumRiskLevel').val(), 10);
        var adversarial = this.$('select.adversarial').val();
        if (adversarial !== 'any') {
          adversarial = !!parseInt(adversarial, 10);
        }

        this.set('filters.adversarial', adversarial);
        this.set('filters.sort', sort);
        this.set('filters.relevance', relevance);
        this.set('filters.impact', impact);
        this.set('filters.minimumRiskLevel', minimumRiskLevel);
      }
    }
  });
});