define('diesel/models/projected-invoice', ['exports', 'ember-data', 'diesel/models/base'], function (exports, _emberData, _dieselModelsBase) {

  // TODO: Dedupe with invoice?

  exports['default'] = _dieselModelsBase['default'].extend({
    isProjection: true,
    billingIteration: _emberData['default'].belongsTo('billing-iteration', { async: true }),

    startsAt: _emberData['default'].attr('iso-8601-timestamp'),
    endsAt: _emberData['default'].attr('iso-8601-timestamp'),
    dueAt: _emberData['default'].attr('iso-8601-timestamp'),
    // TODO: effectiveAt
    date: _emberData['default'].attr('iso-8601-timestamp'),

    totalCashValue: _emberData['default'].attr('number'),
    subtotals: _emberData['default'].attr('humps'),
    lineItems: _emberData['default'].attr('humps'),
    errors: _emberData['default'].attr('humps')
  });
});