define('diesel/organization/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params) {
      return this.get('authorization').getContext(params.organization_id);
    },

    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);
      this.render('sidebars/settings', {
        into: 'organization',
        outlet: 'sidebar'
      });
    }
  });
});