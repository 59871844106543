define('diesel/role/settings/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    redirect: function redirect() {
      var role = this.modelFor('role');
      var contextHref = role.get('links.organization');
      var authorizationContext = this.get('authorization').getContextByHref(contextHref);
      var canManageRole = authorizationContext.hasRoleScope('manage', role);

      // Don't allow settings on owner-type roles or roles that the current user
      // can't manage
      if (role.get('isOwner') || !canManageRole) {
        this.transitionTo('role.members');
      }
    },

    actions: {
      save: function save() {
        var _this = this;

        var role = this.currentModel;
        if (role.get('hasDirtyAttributes')) {
          role.save().then(function () {
            var message = role.get('name') + ' saved';

            _ember['default'].get(_this, 'flashMessages').success(message);
            _this.transitionTo('role.members');
          });
        }
      },
      onDeleteRole: function onDeleteRole() {
        var _this2 = this;

        var role = this.currentModel;
        var message = role.get('name') + ' deleted';

        role.deleteRecord();
        role.save().then(function () {
          _ember['default'].get(_this2, 'flashMessages').success(message);
          _this2.transitionTo('organization.roles.type', 'platform');
        });

        return;
      }
    }
  });
});