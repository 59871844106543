define('diesel/components/resource-sidebar/component', ['exports', 'ember', 'diesel/models/database', 'diesel/models/app'], function (exports, _ember, _dieselModelsDatabase, _dieselModelsApp) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    sortBy: ['handle:asc'],
    store: _ember['default'].inject.service(),

    sortedApps: _ember['default'].computed.sort('apps', 'sortBy'),
    sortedDatabases: _ember['default'].computed.sort('databases', 'sortBy'),
    isDatabaseResource: _ember['default'].computed('resource', function () {
      return this.get('resource') instanceof _dieselModelsDatabase['default'];
    }),

    isAppResource: _ember['default'].computed('resource', function () {
      return this.get('resource') instanceof _dieselModelsApp['default'];
    }),

    backLink: _ember['default'].computed('isDatabaseResource', 'isAppResource', function () {
      var backLink = 'account';

      if (this.get('isDatabaseResource')) {
        backLink = 'databases';
      }

      if (this.get('isAppResource')) {
        backLink = 'apps';
      }

      return backLink;
    })
  });
});