define('diesel/components/select-pills-multiple/component', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].Component.extend({
    name: null,
    'class': '',
    itemKey: null,
    itemValue: null,

    // When true, the item value (as grabbed from item.get(itemKey)) will be added
    // to the result set.  When false, the entire item object is added.
    byKey: false,

    items: _ember['default'].computed(function () {
      return _ember['default'].A([]);
    }),

    /**
     * @return [{key, value}]
     */
    // By binding to selected we can more easily detect if an item is selected
    // but this causes the entire component to redraw--this means no fancy
    // button transition effects.
    keyValues: _ember['default'].computed('items.[]', 'itemKey', 'itemValue', 'selected.[]', 'byKey', function () {
      var _getProperties = this.getProperties('items', 'itemKey', 'itemValue', 'selected', 'byKey');

      var items = _getProperties.items;
      var itemKey = _getProperties.itemKey;
      var itemValue = _getProperties.itemValue;
      var selected = _getProperties.selected;
      var byKey = _getProperties.byKey;

      return items.map(function (item) {
        var key = itemKey ? get(item, itemKey) : item;
        var value = itemValue ? get(item, itemValue) : item;
        var isSelected = undefined;

        if (byKey) {
          isSelected = selected && selected.includes(key);
        } else {
          isSelected = selected && selected.includes(item);
        }

        return { key: key, value: value, item: item, isSelected: isSelected };
      });
    }),

    selectItem: function selectItem(item) {
      var _getProperties2 = this.getProperties('selected', 'byKey');

      var selected = _getProperties2.selected;
      var byKey = _getProperties2.byKey;

      var value = byKey ? item.key : item.item;

      selected.pushObject(value);
      this.attrs.update(selected);
    },

    unSelectItem: function unSelectItem(item) {
      var _getProperties3 = this.getProperties('selected', 'byKey');

      var selected = _getProperties3.selected;
      var byKey = _getProperties3.byKey;

      var value = byKey ? item.key : item.item;

      selected.removeObject(value);
      this.attrs.update(selected);
    },

    actions: {
      select: function select(item) {
        if (this.get('disabled')) {
          return;
        }
        var method = item.isSelected ? this.unSelectItem : this.selectItem;

        method.call(this, item);
      }
    }
  });
});