define('diesel/app/vhosts/new/route', ['exports', 'ember', 'diesel/utils/fetch-all-pages'], function (exports, _ember, _dieselUtilsFetchAllPages) {
  exports['default'] = _ember['default'].Route.extend({
    titleToken: function titleToken() {
      var app = this.modelFor('app');
      return 'Add an endpoint - ' + app.get('handle');
    },

    model: function model() {
      var app = this.modelFor('app');
      var account = app.get('account');

      return _ember['default'].RSVP.hash({
        vhost: this.store.createRecord('vhost', { app: app }),
        services: app.get('services'),
        vhosts: app.get('vhosts'),
        certificates: (0, _dieselUtilsFetchAllPages['default'])(this.store, account, 'certificate'),
        account: account
      });
    },

    setupController: function setupController(controller, model) {
      var vhost = model.vhost,
          services = model.services,
          vhosts = model.vhosts,
          certificates = model.certificates;

      controller.set('model', vhost);
      controller.set('services', services);
      controller.set('vhosts', vhosts);
      controller.set('certificates', certificates);
    },

    actions: {
      save: function save(vhost, service) {
        var _this = this;

        var certificatePromise = undefined;
        var account = this.currentModel.account;

        var promise = undefined;

        if (vhost.get("useCertificate")) {
          if (vhost.get('certificateBody')) {
            var certificateBody = vhost.get('certificateBody');
            var privateKey = vhost.get('privateKey');
            var newCertificate = this.store.createRecord('certificate', { certificateBody: certificateBody, account: account, privateKey: privateKey });

            certificatePromise = newCertificate.save();
          } else {
            certificatePromise = new _ember['default'].RSVP.resolve(vhost.get('certificate'));
          }

          promise = certificatePromise.then(function (certificate) {
            vhost.setProperties({ service: service, certificate: certificate, certificateBody: null,
              privateKey: null });

            return vhost.save();
          });
        } else {
          vhost.setProperties({ service: service, certificate: null,
            certificateBody: null, privateKey: null });
          promise = vhost.save();
        }

        promise.then(function () {
          var op = _this.store.createRecord('operation', {
            type: 'provision',
            vhost: vhost
          });

          return op.save();
        }).then(function () {
          var message = 'Endpoint created: ' + vhost.get('virtualDomain') + '.';

          if (vhost.get("isAcme")) {
            message += ' Please wait for next steps.';
          }

          _this.transitionTo('app.vhosts');
          _ember['default'].get(_this, 'flashMessages').success(message);
        })['catch'](function (e) {
          var message = e.message || 'There was an error updating endpoint ' + vhost.get('virtualDomain');
          _ember['default'].get(_this, 'flashMessages').danger(message);
        });
      },

      cancel: function cancel() {
        this.transitionTo('app.vhosts');
      },

      willTransition: function willTransition() {
        this.currentModel.vhost.rollbackAttributes();
      }
    }
  });
});