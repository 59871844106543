define('diesel/utils/banners', ['exports', 'diesel/utils/storage'], function (exports, _dieselUtilsStorage) {

  var BANNER = 'banner-show';

  function state() {
    var raw = _dieselUtilsStorage['default'].read(BANNER);
    return JSON.parse(raw) || {};
  }

  function read(name) {
    var cur = state();
    var val = cur[name];
    var isBool = typeof val === 'boolean';
    // cannot `cur[name] || true` because the value could actually
    // be `false` which would flip the result to `true` accidently.
    if (isBool) {
      return val;
    }

    return isBool ? val : true;
  }

  function set(name, val) {
    var cur = state();
    cur[name] = val;
    _dieselUtilsStorage['default'].write(BANNER, JSON.stringify(cur));
  }

  function shouldShow(name) {
    return read(name);
  }

  function dismiss(name) {
    return set(name, false);
  }

  var api = { shouldShow: shouldShow, dismiss: dismiss };
  exports['default'] = api;
});