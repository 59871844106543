define('diesel/components/billing-invoice-status-icon/component', ['exports', 'ember', 'diesel/models/invoice'], function (exports, _ember, _dieselModelsInvoice) {

  var LOADING_ICON = 'fa-spin fa-spinner';
  var CHECK_ICON = 'fa-check';
  var ATTENTION_ICON = 'fa-exclamation-circle';

  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    invoice: null,
    billingIteration: null,

    invoiceState: _ember['default'].computed('billingIteration.invoiceState', 'invoice.state', function () {
      var state = this.get('billingIteration.invoiceState') || this.get('invoice.state');
      return state;
    }),

    icon: _ember['default'].computed('invoiceState', function () {
      var state = this.get('invoiceState');
      if (!state) {
        return LOADING_ICON;
      }

      if (_dieselModelsInvoice.OK_STATES.includes(state)) {
        return CHECK_ICON;
      }

      return ATTENTION_ICON;
    })
  });
});