define('diesel/gridiron-setup/team/route', ['exports', 'ember', 'diesel/mixins/routes/spd-route', 'diesel/gridiron-settings/team/route'], function (exports, _ember, _dieselMixinsRoutesSpdRoute, _dieselGridironSettingsTeamRoute) {
  exports['default'] = _dieselGridironSettingsTeamRoute['default'].extend(_dieselMixinsRoutesSpdRoute['default'], {
    modalControllerName: 'gridiron-setup',

    actions: {
      onSave: function onSave() {
        var _this = this;

        var organizationProfile = this.modelFor('gridiron-setup');
        organizationProfile.save().then(function () {
          var message = 'Progress saved.';
          _ember['default'].get(_this, 'flashMessages').success(message);
        }, function (e) {
          var message = _ember['default'].getWithDefault(e, 'errors.message', 'An error occurred');
          _ember['default'].get(_this, 'flashMessages').danger('Save Failed! ' + message);
        });
      }
    }
  });
});