define('diesel/mixins/models/approvable-document', ['exports', 'ember', 'ember-data', 'diesel/config/environment'], function (exports, _ember, _emberData, _dieselConfigEnvironment) {
  var _PDF_STATUSES;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var PDF_STATUSES = (_PDF_STATUSES = {}, _defineProperty(_PDF_STATUSES, 'EMPTY', 'empty'), _defineProperty(_PDF_STATUSES, 'GENERATING', 'generating'), _defineProperty(_PDF_STATUSES, 'GENERATED', 'generated'), _PDF_STATUSES);

  exports.PDF_STATUSES = PDF_STATUSES;
  var APPROVAL_DOCUMENT_RELOAD_TIMEOUT = _dieselConfigEnvironment['default'].timeouts.approvalDocumentReload;

  var MAX_RELOAD_COUNT = 15;
  exports.MAX_RELOAD_COUNT = MAX_RELOAD_COUNT;
  var MAX_PDF_RELOAD_TIME = APPROVAL_DOCUMENT_RELOAD_TIMEOUT * MAX_RELOAD_COUNT;

  exports.MAX_PDF_RELOAD_TIME = MAX_PDF_RELOAD_TIME;
  exports['default'] = _ember['default'].Mixin.create({
    name: _emberData['default'].attr('string'),

    approvingAuthorityUserName: _emberData['default'].attr('string'),
    approvingAuthorityUserEmail: _emberData['default'].attr('string'),
    approvingAuthorityUrl: _emberData['default'].attr('string'),
    approvingAuthorityUserTitle: _emberData['default'].attr('string'),
    approvedAt: _emberData['default'].attr('iso-8601-timestamp'),
    approvalDescription: _emberData['default'].attr('string'),

    createdByUserName: _emberData['default'].attr('string'),
    createdByUserEmail: _emberData['default'].attr('string'),
    createdByUrl: _emberData['default'].attr('string'),
    createdAt: _emberData['default'].attr('iso-8601-timestamp'),

    version: _emberData['default'].attr('number'),
    revision: _emberData['default'].attr('number'),
    draft: _emberData['default'].attr('number'),

    // PDF
    pdfStatus: _emberData['default'].attr('string', { defaultvalue: PDF_STATUSES.EMPTY }),
    pdfIsEmpty: _ember['default'].computed.equal('pdfStatus', PDF_STATUSES.EMPTY),
    pdfIsGenerating: _ember['default'].computed.equal('pdfStatus', PDF_STATUSES.GENERATING),
    pdfIsGenerated: _ember['default'].computed.equal('pdfStatus', PDF_STATUSES.GENERATED),
    pdfUrl: _ember['default'].computed(function () {
      return this.get('links.pdf_url');
    }),

    _shouldReload: function _shouldReload() {
      if (this._reloadBegin) {
        // This wont' be set on the first shouldReload check
        var currentTime = new Date().getTime();
        var elapsedReloadTime = currentTime - this._reloadBegin;
        var reloadHasTimedOut = elapsedReloadTime >= MAX_PDF_RELOAD_TIME;

        if (reloadHasTimedOut) {
          return false;
        }
      }

      var _getProperties = this.getProperties('pdfIsGenerating', 'isDeleted', 'isDestroying', 'isDestroyed');

      var pdfIsGenerating = _getProperties.pdfIsGenerating;
      var isDeleted = _getProperties.isDeleted;
      var isDestroying = _getProperties.isDestroying;
      var isDestroyed = _getProperties.isDestroyed;

      var currentState = this.get('currentState.stateName');
      var inLoadedState = currentState !== 'root.empty' && currentState !== 'root.deleted.uncommitted';

      return inLoadedState && pdfIsGenerating && !isDeleted && !isDestroying && !isDestroyed;
    },

    observePDFBuilds: function observePDFBuilds() {
      if (this._shouldReload()) {
        this._reloadBegin = new Date().getTime();
        this._recursiveReload();
      }
    },

    _recursiveReload: function _recursiveReload() {
      var _this = this;

      if (this._isReloading) {
        return;
      }

      this._isReloading = true;
      this.reload().then(function () {
        if (_this._shouldReload()) {
          _ember['default'].run.later(_this, '_recursiveReload', APPROVAL_DOCUMENT_RELOAD_TIMEOUT);
        }
      })['catch'](function (err) {
        if (404 === err.status || err.message && err.message.indexOf('notFound') > -1) {
          _this.unloadRecord();
          return;
        }
      })['finally'](function () {
        _this._isReloading = false;
      });
    }
  });
});