define('diesel/organization/admin/billing/payment-methods/new/ach/route', ['exports', 'ember'], function (exports, _ember) {

  var BillingRoute = 'organization.admin.billing';

  exports['default'] = _ember['default'].Route.extend({
    actions: {
      saveBankAccount: function saveBankAccount(bankAccount) {
        var _this = this;

        var billingDetail = this.modelFor(BillingRoute);

        this.controller.set('isSaving', true);
        billingDetail.addBankAccount(bankAccount).then(function () {
          _this.transitionTo(BillingRoute + '.payment-methods');
        })['catch'](function (e) {
          _ember['default'].get(_this, 'flashMessages').danger(e.message);
        })['finally'](function () {
          _this.controller.set('isSaving', false);
        });
      }
    }
  });
});