define("diesel/account/backups/route", ["exports", "ember", "diesel/config/environment", "diesel/mixins/routes/paginated"], function (exports, _ember, _dieselConfigEnvironment, _dieselMixinsRoutesPaginated) {

  var OPERATION_TIMEOUT = _dieselConfigEnvironment["default"].timeouts.databaseBackupOperation;

  exports["default"] = _ember["default"].Route.extend(_dieselMixinsRoutesPaginated["default"], {
    modalDisplay: _ember["default"].inject.service(),

    getPaginatedResourceType: function getPaginatedResourceType() {
      return "backup";
    },
    composeQuery: function composeQuery(page) {
      return {
        account: this.modelFor("account"),
        orphaned: true,
        page: page
      };
    },

    setupController: function setupController(controller, model) {
      controller.set('account', this.modelFor('account'));
      this._super(controller, model);
    },

    title: function title() {
      var account = this.modelFor('account');
      return account.get('handle') + " Backup Management";
    },

    openBackupRetentionPolicyModal: function openBackupRetentionPolicyModal(backupRetentionPolicy, oldBackupRetentionPolicy) {
      this.get('modalDisplay').showModal('modal-edit-backup-retention-policy', { backupRetentionPolicy: backupRetentionPolicy, oldBackupRetentionPolicy: oldBackupRetentionPolicy });
    },

    actions: {
      purgeBackup: function purgeBackup(backup) {
        var _this = this;

        return this.get("store").createRecord("operation", { type: "purge", backup: backup }).save().then(function (operation) {
          _this.get("flashMessages").success("Backup removal scheduled");
          return operation;
        }).then(function (operation) {
          return operation.reloadUntilStatusChanged(OPERATION_TIMEOUT);
        }).then(function () {
          return _this.refresh();
        });
      },
      bulkPurgePage: function bulkPurgePage(model) {
        var _this2 = this;

        // Confirm...
        var confirmMsg = "\nPurging will permanently remove ALL of these backups and their copies and CANNOT be undone.\n\n";
        confirmMsg += 'Are you sure you want to continue?\n';
        if (!confirm(confirmMsg)) {
          return false;
        }

        return _ember["default"].RSVP.all(model.map(function (backup) {
          _this2.get("store").createRecord("operation", { type: "purge", backup: backup }).save();
        })).then(function () {
          _this2.get("flashMessages").success("Backup removals scheduled. Refresh the page in a few moments.");
        }).then(function () {
          return _this2.refresh();
        });
      },
      onEditBackupRetentionPolicy: function onEditBackupRetentionPolicy(backupRetentionPolicy) {
        var newBackupRetentionPolicy = this.store.createRecord('backupRetentionPolicy', {
          account: this.modelFor('account'),
          daily: backupRetentionPolicy.get('daily'),
          monthly: backupRetentionPolicy.get('monthly'),
          keepFinal: backupRetentionPolicy.get('keepFinal'),
          makeCopy: backupRetentionPolicy.get('makeCopy')
        });
        this.openBackupRetentionPolicyModal(newBackupRetentionPolicy, backupRetentionPolicy);
      },
      onCreateBackupRetentionPolicy: function onCreateBackupRetentionPolicy() {
        var backupRetentionPolicy = this.store.createRecord('backupRetentionPolicy', {
          account: this.modelFor('account'),
          daily: 90,
          monthly: 72,
          keepFinal: true,
          makeCopy: true
        });
        this.openBackupRetentionPolicyModal(backupRetentionPolicy, null);
      },
      openRestoreBackupModal: function openRestoreBackupModal(backup) {
        this.get('modalDisplay').showModal('modal-restore-backup', {
          backupToRestore: backup
        });
      }
    }
  });
});