define('diesel/verify-email/route', ['exports', 'ember', 'diesel/config/environment'], function (exports, _ember, _dieselConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var auth = this.get('authorization');

      return _ember['default'].RSVP.hash({
        currentTrial: auth.get('currentOrganization.billingDetail.currentTrial')
      });
    },

    afterModel: function afterModel(model) {
      if (this.session.get('currentUser.verified')) {
        if (model.currentTrial && model.currentTrial.get('active') && _dieselConfigEnvironment['default'].featureFlags.showFTUX) {
          window.location = _dieselConfigEnvironment['default'].aptibleHosts.appUrl + '/create';
        } else {
          this.transitionTo('enclave');
        }
      }
    }
  });
});