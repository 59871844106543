define('diesel/components/billing-schedule/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    schedule: null,
    goldenboyLib: null,

    formattedSchedule: _ember['default'].computed('schedule', function () {
      var schedule = this.get('schedule');
      return this.get('goldenboyLib').formatSchedule(schedule);
    })
  });
});