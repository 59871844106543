define('diesel/adapters/backup', ['exports', 'diesel/adapters/api', 'diesel/utils/build-url-with-prefix-map'], function (exports, _dieselAdaptersApi, _dieselUtilsBuildUrlWithPrefixMap) {
  exports['default'] = _dieselAdaptersApi['default'].extend({
    buildURL: (0, _dieselUtilsBuildUrlWithPrefixMap['default'])({
      'databases': { property: 'database.id', only: ['createRecord'] }
    }),

    query: function query(store, type, _query) {
      var url = this.buildURL(type.modelName, null, null, 'query');

      if (this.sortQueryParams) {
        _query = this.sortQueryParams(_query);
      }

      if (_query.database) {
        url = url.replace('/backups', '/databases/' + _query.database.id + '/backups');
        delete _query.database;
      }

      if (_query.account) {
        url = url.replace('/backups', '/accounts/' + _query.account.id + '/backups');
        delete _query.account;
      }

      return this.ajax(url, 'GET', { data: _query });
    }
  });
});