define("diesel/validators/email", ["exports", "ember-validations/validators/base"], function (exports, _emberValidationsValidatorsBase) {
  var VALID_EMAIL_REGEX = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

  exports.VALID_EMAIL_REGEX = VALID_EMAIL_REGEX;
  exports["default"] = _emberValidationsValidatorsBase["default"].extend({
    call: function call() {
      var possibleEmail = this.model.get(this.property);
      if (!VALID_EMAIL_REGEX.exec(possibleEmail)) {
        this.errors.pushObject("is not valid.");
      }
    }
  });
});