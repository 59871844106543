define('diesel/enclave/index/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    redirect: function redirect() {
      this.transitionTo('accounts');
    },

    model: function model() {
      return this.get('authorization.organizations');
    }
  });
});