define('diesel/models/activity-report', ['exports', 'ember-data', 'diesel/models/base', 'ember'], function (exports, _emberData, _dieselModelsBase, _ember) {
  exports['default'] = _dieselModelsBase['default'].extend({
    filename: _emberData['default'].attr('string'),
    startsAt: _emberData['default'].attr('iso-8601-timestamp'),
    endsAt: _emberData['default'].attr('iso-8601-timestamp'),
    createdAt: _emberData['default'].attr('iso-8601-timestamp'),

    account: _emberData['default'].belongsTo('account', { async: true }),

    downloadUrl: _ember['default'].computed.alias('links.download')
  });
});