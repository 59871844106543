define('diesel/models/certificate', ['exports', 'ember-data', 'diesel/models/base', 'ember', 'diesel/helpers/format-utc-timestamp'], function (exports, _emberData, _dieselModelsBase, _ember, _dieselHelpersFormatUtcTimestamp) {

  var FINGERPRINT_DISPLAY_SIZE = 7;

  exports['default'] = _dieselModelsBase['default'].extend({
    // properties
    certificateBody: _emberData['default'].attr('string'),
    privateKey: _emberData['default'].attr('string'),
    commonName: _emberData['default'].attr('string'),
    issuerCountry: _emberData['default'].attr('string'),
    issuerOrganization: _emberData['default'].attr('string'),
    issuerWebsite: _emberData['default'].attr('string'),
    issuerCommonName: _emberData['default'].attr('string'),
    subjectCountry: _emberData['default'].attr('string'),
    subjectState: _emberData['default'].attr('string'),
    subjectLocale: _emberData['default'].attr('string'),
    subjectOrganization: _emberData['default'].attr('string'),
    createdAt: _emberData['default'].attr('iso-8601-timestamp'),
    notBefore: _emberData['default'].attr('iso-8601-timestamp'),
    notAfter: _emberData['default'].attr('iso-8601-timestamp'),
    isAcme: _emberData['default'].attr('boolean'),
    sha256Fingerprint: _emberData['default'].attr('string'),
    trusted: _emberData['default'].attr('boolean'),

    // relationships
    account: _emberData['default'].belongsTo('account', { async: true }),
    operations: _emberData['default'].hasMany('operation', { async: true }),
    vhosts: _emberData['default'].hasMany('vhost', { async: true }),
    apps: _emberData['default'].hasMany('app', { async: true }),

    inUse: _ember['default'].computed.gt('vhosts.length', 0),

    name: _ember['default'].computed('commonName', 'notBefore', 'notAfter', 'issuerDisplayName', 'shortDisplayFingerprint', function () {
      var bits = [this.get("commonName")];

      var notBefore = this.get('notBefore');
      var notAfter = this.get('notAfter');
      if (notBefore && notAfter) {
        bits.push('Valid: ' + (0, _dieselHelpersFormatUtcTimestamp.formatUtcTimestamp)(notBefore, true) + ' - ' + (0, _dieselHelpersFormatUtcTimestamp.formatUtcTimestamp)(notAfter, true));
      }

      var issuerDisplayName = this.get("issuerDisplayName");
      if (issuerDisplayName) {
        bits.push(issuerDisplayName);
      }

      var fingerprint = this.get("shortDisplayFingerprint");
      if (fingerprint) {
        bits.push(fingerprint);
      }

      return bits.join(" - ");
    }),

    issuerDisplayName: _ember['default'].computed("issuerOrganization", "issuerCommonName", function () {
      return this.get("issuerOrganization") || this.get("issuerCommonName");
    }),

    shortDisplayFingerprint: _ember['default'].computed("sha256Fingerprint", function () {
      var fingerprint = this.get("sha256Fingerprint");
      return fingerprint && fingerprint.slice(0, FINGERPRINT_DISPLAY_SIZE);
    }),

    untrusted: _ember['default'].computed.not('trusted')
  });
});