define('diesel/database/vhosts/route', ['exports', 'ember', 'diesel/mixins/models/statuses'], function (exports, _ember, _dieselMixinsModelsStatuses) {
  exports['default'] = _ember['default'].Route.extend({
    modalDisplay: _ember['default'].inject.service(),

    model: function model() {
      var database = this.modelFor('database');
      return database.get('service').then(function (service) {
        return _ember['default'].RSVP.hash({
          database: database, service: service, vhosts: service ? service.get('vhosts') : []
        });
      });
    },

    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model.vhosts,
        database: model.database,
        service: model.service
      });
    },

    openVhostModal: function openVhostModal(vhostToEdit) {
      this.get('modalDisplay').showModal('modal-edit-vhost', { vhostToEdit: vhostToEdit, service: this.currentModel.service });
    },

    actions: {
      addVhost: function addVhost() {
        var vhost = this.store.createRecord('vhost', {
          service: this.currentModel.service,
          platform: 'elb',
          type: 'tcp',
          internal: false
        });
        this.openVhostModal(vhost);
      },

      deleteVhost: function deleteVhost(vhost) {
        vhost.set('status', _dieselMixinsModelsStatuses['default'].DEPROVISIONING);
        return this.get('operationsManager').createBackgroundOperation(vhost, { type: 'deprovision' });
      },

      editVhost: function editVhost(vhost) {
        this.openVhostModal(vhost);
      },

      revealVhostCredentials: function revealVhostCredentials(vhost) {
        var externalHost = vhost.get('externalHost');
        var portMapping = this.currentModel.database.get('portMapping');

        var vhostCredentials = this.currentModel.database.get('databaseCredentials').map(function (credential) {
          return credential.rewrite(externalHost, portMapping);
        }).filter(function (c) {
          return c;
        });

        this.send('openDatabaseCredentialsModal', vhostCredentials);
      }
    }
  });
});