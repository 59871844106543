define("diesel/password/reset/controller", ["exports", "ember", "ember-validations"], function (exports, _ember, _emberValidations) {
  exports["default"] = _ember["default"].Controller.extend(_emberValidations["default"], {
    hasSubmitted: false,

    validations: {
      'model.email': {
        email: true
      }
    },

    actions: {

      reset: function reset(model) {
        var _this = this;

        var hasSubmitted = this.get('hasSubmitted');

        if (!hasSubmitted) {
          this.set('hasSubmitted', true);
        }

        this.validate().then(function () {
          _this.get('target').send('reset', model);
        })["catch"](function () {
          // Silence the validation exception
        });
      }

    }

  });
});