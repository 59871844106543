define('diesel/account/activity/route', ['exports', 'ember', 'diesel/utils/belongs-to-record-pool-wrapper'], function (exports, _ember, _dieselUtilsBelongsToRecordPoolWrapper) {
  var ACTIVITY_REPORT_BATCH_SIZE = 20;

  exports.ACTIVITY_REPORT_BATCH_SIZE = ACTIVITY_REPORT_BATCH_SIZE;
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params) {
      var account = this.modelFor('account');
      var poolWrapper = new _dieselUtilsBelongsToRecordPoolWrapper['default']([account], 'activity-report');

      return poolWrapper.fetch(params.activityReportCount).then(function (fetchResponse) {
        return {
          poolWrapper: poolWrapper,
          activityReports: fetchResponse.records,
          lastFetch: fetchResponse
        };
      });
    },

    actions: {
      loadMore: function loadMore() {
        var _this = this;

        // TODO: Handle rejection.
        this.controller.get("model.poolWrapper").fetch(ACTIVITY_REPORT_BATCH_SIZE).then(function (fetchResponse) {
          _this.controller.set('model.lastFetch', fetchResponse);

          var activityReports = _this.controller.get('model.activityReports');
          fetchResponse.records.forEach(function (o) {
            return activityReports.pushObject(o);
          });

          _this.controller.set('activityReportCount', activityReports.get("length"));
        })['catch'](function (e) {
          _ember['default'].get(_this, 'flashMessages').danger('An error occured fetching activity reports');
          throw e;
        });
      }
    }
  });
});