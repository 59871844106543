define('diesel/otp-reset-verify/route', ['exports', 'ember', 'diesel/mixins/routes/disallow-authenticated'], function (exports, _ember, _dieselMixinsRoutesDisallowAuthenticated) {
  exports['default'] = _ember['default'].Route.extend(_dieselMixinsRoutesDisallowAuthenticated['default'], {
    model: function model(params) {
      return {
        challengeId: params.challenge_id,
        verificationCode: params.verification_code,
        type: 'otp_reset_challenge'
      };
    },

    actions: {
      save: function save(model) {
        var _this = this;

        var verification = this.store.createRecord('verification', {
          challengeId: model.challengeId,
          verificationCode: model.verificationCode,
          type: model.type
        });
        verification.save().then(function () {
          _this.transitionTo('login');
        }, function (e) {
          verification.destroy();

          var message = e.message || 'An unexpected error occurred.';

          _this.controllerFor('otp-reset-verify').set('error', '\n          There was an error resetting your 2FA: ' + message + '.\n        ');
        });
      }
    }
  });
});