define("diesel/application/torii-adapter", ["exports", "ember", "diesel/config/environment", "diesel/utils/ajax", "diesel/adapters/application", "diesel/utils/tokens"], function (exports, _ember, _dieselConfigEnvironment, _dieselUtilsAjax, _dieselAdaptersApplication, _dieselUtilsTokens) {

  function clearSession() {
    (0, _dieselAdaptersApplication.setAccessToken)(null);
  }

  function persistSession(accessToken) {
    (0, _dieselAdaptersApplication.setAccessToken)(accessToken);
  }

  function pushTokenToStore(tokenPayload, store) {
    var payload = store.normalize('token', tokenPayload);
    store.push({ data: payload });
    return store.peekRecord('token', tokenPayload.id);
  }

  exports["default"] = _ember["default"].Object.extend({
    store: _ember["default"].inject.service(),
    analytics: _ember["default"].inject.service(),

    _authenticateWithPayload: function _authenticateWithPayload(tokenPayload) {
      var _this = this;

      var store = this.get('store');
      return new _ember["default"].RSVP.Promise(function (resolve) {
        persistSession(tokenPayload.access_token);
        resolve(pushTokenToStore(tokenPayload, store));
      }).then(function (token) {
        return _ember["default"].RSVP.hash({
          token: token,
          currentUser: token.get('user'),
          currentActor: token.get('actor'),
          currentOrganization: token.get('organization')
        });
      }).then(function (session) {
        // Load role eagerly
        return session.currentUser.get('roles').then(function () {
          return session;
        });
      }).then(function (session) {
        _this.identifyToAnalytics(session.currentActor || session.currentUser);
        return session;
      })["catch"](function (e) {
        clearSession();
        _this.get('analytics').logout();
        throw e;
      });
    },

    fetch: function fetch() {
      var _this2 = this;

      return (0, _dieselUtilsTokens.getPersistedToken)().then(function (tokenPayload) {
        return _this2._authenticateWithPayload(tokenPayload);
      });
    },

    open: function open(tokenPayload) {
      return this._authenticateWithPayload(tokenPayload);
    },

    close: function close(options) {
      options = options || {};
      var promise = undefined;

      if (options.noDelete) {
        promise = new _ember["default"].RSVP.Promise(function (resolve) {
          return resolve();
        });
      } else {
        var token = options.token;
        _ember["default"].assert("A token must be passed: session.close('aptible', { token: /*token*/ });", !!token);

        var xhrFields = {};
        if (!options.noClearCookies) {
          xhrFields.withCredentials = true;
        }

        promise = (0, _dieselUtilsAjax["default"])(_dieselConfigEnvironment["default"].authBaseUri + "/tokens/" + token.get('id'), {
          type: 'DELETE',
          headers: {
            'Authorization': 'Bearer ' + token.get("accessToken") || (0, _dieselAdaptersApplication.getAccessToken)()
          },
          xhrFields: xhrFields
        });
      }

      return promise.then(function () {
        clearSession();
      });
    },

    identifyToAnalytics: function identifyToAnalytics(user) {
      var email = user.get('email');
      this.get('analytics').identify(user.get('id'), {
        email: email,
        id: user.get('id'),
        name: user.get('name'),
        createdAt: user.get('createdAt')
      });
    }

  });
});