define('diesel/components/create-vhost/component', ['exports', 'ember'], function (exports, _ember) {

  var VHOST_TYPE_GENERIC = 'generic';
  var VHOST_TYPE_DEFAULT = 'default';
  var VHOST_TYPE_ACME = 'acme';

  var VHOST_RESET_PROPERTIES = {};

  VHOST_RESET_PROPERTIES[VHOST_TYPE_GENERIC] = {
    "isDefault": false,
    "isAcme": false,
    "userDomain": null,
    "useCertificate": true
  };

  VHOST_RESET_PROPERTIES[VHOST_TYPE_DEFAULT] = {
    "isDefault": true,
    "isAcme": false,
    "userDomain": null,
    "useCertificate": false
  };

  VHOST_RESET_PROPERTIES[VHOST_TYPE_ACME] = {
    "isDefault": false,
    "isAcme": true,
    "useCertificate": false
  };

  exports['default'] = _ember['default'].Component.extend({
    model: null,
    services: null,
    vhosts: null,
    certificates: null,

    vhostType: null,
    vhostService: null,
    userDomainValid: null,
    ipFilteringValid: null,
    containerPortValid: null,

    TYPE_GENERIC: VHOST_TYPE_GENERIC,
    TYPE_DEFAULT: VHOST_TYPE_DEFAULT,
    TYPE_ACME: VHOST_TYPE_ACME,

    vhostTypeObserver: (function () {
      var vhost = this.get("model");
      var vhostType = this.get("vhostType");
      var resets = VHOST_RESET_PROPERTIES[vhostType] || {};

      for (var k in resets) {
        if (!resets.hasOwnProperty(k)) {
          continue;
        }
        vhost.set(k, resets[k]);
      }
    }).observes("vhostType"),

    init: function init() {
      this._super.apply(this, arguments);
      // The first service is acceptable as a default, but if any is called
      // 'web', we'll choose that one, since it's more likely to be a valid
      // choice.
      var defaultService = this.get("services").objectAt(0);

      var webService = this.get('services').find(function (service) {
        return service.get('processType') === 'web';
      });

      this.set('vhostService', webService || defaultService);

      // Prefer VHOSTs types that require the least configuration from the user.
      var defaultVhostType = VHOST_TYPE_GENERIC;
      if (this.get("acmeVhostAllowed")) {
        defaultVhostType = VHOST_TYPE_ACME;
      }
      if (this.get("defaultVhostAllowed")) {
        defaultVhostType = VHOST_TYPE_DEFAULT;
      }
      this.set('vhostType', defaultVhostType);

      // Prefer ALBs
      var defaultVhostPlatform = "elb";
      if (this.get("albVhostAllowed")) {
        defaultVhostPlatform = "alb";
      }
      this.set("model.platform", defaultVhostPlatform);

      // The observer won't run automatically, so we force it now.
      this.vhostTypeObserver();
    },

    isGeneric: _ember['default'].computed.equal("vhostType", VHOST_TYPE_GENERIC),
    isDefault: _ember['default'].computed.equal("vhostType", VHOST_TYPE_DEFAULT),
    isAcme: _ember['default'].computed.equal("vhostType", VHOST_TYPE_ACME),

    placementNeeded: true,
    userDomainNeeded: _ember['default'].computed.and("isAcme"),

    defaultVhostAllowed: _ember['default'].computed("vhosts.[]", function () {
      return !this.get("vhosts").any(function (vhost) {
        return vhost.get("isDefault");
      });
    }),

    acmeVhostAllowed: _ember['default'].computed.alias('vhostService.account.isV2'),
    albVhostAllowed: _ember['default'].computed.alias('vhostService.account.isV2'),

    formValid: _ember['default'].computed("vhostType", "userDomainValid", "ipFilteringValid", "containerPortValid", function () {
      if (this.get("isAcme")) {
        return this.get("userDomainValid");
      }
      return this.get("ipFilteringValid") && this.get("containerPortValid");
    }),

    formInvalid: _ember['default'].computed.not("formValid"),

    formDisabled: _ember['default'].computed.or("formInvalid", "model.isSaving"),

    actions: {
      save: function save(vhost, service) {
        this.sendAction('save', vhost, service);
      },

      cancel: function cancel() {
        this.sendAction('cancel');
      },

      willTransition: function willTransition() {
        this.sendAction('willTransition');
      }
    }
  });
});