define('diesel/components/billing-contacts-table/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    sortBy: ['name:asc'],

    billingContacts: null,

    sortedBillingContacts: _ember['default'].computed.sort('billingContacts', 'sortBy'),

    actions: {
      destroyBillingContact: function destroyBillingContact(billingContact) {
        this.sendAction('destroyBillingContact', billingContact);
      }
    }
  });
});