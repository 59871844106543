define('diesel/utils/implementation-metrics', ['exports', 'ember', 'diesel/utils/time-ago-in-words', 'diesel/helpers/plural-string'], function (exports, _ember, _dieselUtilsTimeAgoInWords, _dieselHelpersPluralString) {

  var genericResourceType = 'Resource';

  var STATUS_NO_SCOPE = 'NO_SCOPE';
  exports.STATUS_NO_SCOPE = STATUS_NO_SCOPE;
  var STATUS_COMPLETE = 'COMPLETE';
  exports.STATUS_COMPLETE = STATUS_COMPLETE;
  var STATUS_NOT_STARTED = 'NOT_STARTED';
  exports.STATUS_NOT_STARTED = STATUS_NOT_STARTED;
  var STATUS_PARTIAL = 'PARTIAL';

  exports.STATUS_PARTIAL = STATUS_PARTIAL;
  exports['default'] = _ember['default'].Object.extend({
    totalInScope: 0,
    totalCompliant: 0,
    totalIgnored: 0,
    resourceType: genericResourceType,
    lastEvaluation: null,
    resetValues: function resetValues() {
      this.setProperties({
        totalInScope: 0,
        totalCompliant: 0,
        totalIgnored: 0,
        lastEvaluation: null
      });
    },
    isNoScope: _ember['default'].computed.equal('status', STATUS_NO_SCOPE),
    isFullyImplemented: _ember['default'].computed.equal('status', STATUS_COMPLETE),
    isNotStarted: _ember['default'].computed.equal('status', STATUS_NOT_STARTED),
    isPartiallyImplemented: _ember['default'].computed.equal('status', STATUS_PARTIAL),

    isGenericResourceType: _ember['default'].computed.equal('resourceType', genericResourceType),
    statusSort: _ember['default'].computed('progressPercentage', function () {
      var _getProperties = this.getProperties('totalInScope', 'progressPercentage');

      var totalInScope = _getProperties.totalInScope;
      var progressPercentage = _getProperties.progressPercentage;

      if (totalInScope === 0) {
        return 101;
      }
      return progressPercentage;
    }),
    progressPercentage: _ember['default'].computed('totalInScope', 'totalCompliant', function () {
      var _getProperties2 = this.getProperties('totalInScope', 'totalCompliant');

      var totalInScope = _getProperties2.totalInScope;
      var totalCompliant = _getProperties2.totalCompliant;

      if (totalInScope === 0) return 0;
      return Math.round(totalCompliant / totalInScope * 100);
    }),

    lastEvaluationInWords: _ember['default'].computed('lastEvaluation', function () {
      var lastEvaluation = this.get('lastEvaluation');
      return lastEvaluation ? (0, _dieselUtilsTimeAgoInWords.timeAgoInWords)(lastEvaluation.getTime()) : null;
    }),

    implementationAsString: _ember['default'].computed('progressPercentage', 'resourceType', function () {
      // A bit longer, used in print report

      var _getProperties3 = this.getProperties('totalInScope', 'totalCompliant', 'progressPercentage', 'resourceType');

      var totalInScope = _getProperties3.totalInScope;
      var totalCompliant = _getProperties3.totalCompliant;
      var progressPercentage = _getProperties3.progressPercentage;
      var resourceType = _getProperties3.resourceType;

      return [totalCompliant, 'of', totalInScope, (0, _dieselHelpersPluralString.pluralString)(resourceType, totalInScope), '(' + progressPercentage + '%)'].join(' ');
    }),

    implementationSummary: _ember['default'].computed('progressPercentage', 'resourceType', function () {
      // More concise, used in compliance-metrics widget

      var _getProperties4 = this.getProperties('totalInScope', 'lastEvaluationInWords', 'resourceType');

      var totalInScope = _getProperties4.totalInScope;
      var lastEvaluationInWords = _getProperties4.lastEvaluationInWords;
      var resourceType = _getProperties4.resourceType;

      return [totalInScope, (0, _dieselHelpersPluralString.pluralString)(resourceType, totalInScope), '·', lastEvaluationInWords, 'ago'].join(' ');
    }),

    status: _ember['default'].computed('totalInScope', 'progressPercentage', function () {
      var _getProperties5 = this.getProperties('totalInScope', 'progressPercentage');

      var totalInScope = _getProperties5.totalInScope;
      var progressPercentage = _getProperties5.progressPercentage;

      if (totalInScope === 0) return STATUS_NO_SCOPE;else if (progressPercentage === 0) return STATUS_NOT_STARTED;else if (progressPercentage < 100) return STATUS_PARTIAL;
      return STATUS_COMPLETE;
    })
  });
});