define('diesel/mixins/routes/multi-resource-operation-list', ['exports', 'ember', 'diesel/utils/belongs-to-record-pool-wrapper'], function (exports, _ember, _dieselUtilsBelongsToRecordPoolWrapper) {
  var OPERATION_BATCH_SIZE = 20;

  exports.OPERATION_BATCH_SIZE = OPERATION_BATCH_SIZE;
  exports['default'] = _ember['default'].Mixin.create({
    // Methods to override
    getResources: function getResources() {
      _ember['default'].assert('Must override getResources');
    },

    // Ember hooks
    model: function model(params) {
      var _this = this;

      return this.getResources().then(function (resources) {
        var poolWrapper = new _dieselUtilsBelongsToRecordPoolWrapper['default'](resources, 'operation');
        return poolWrapper.fetch(params.operationCount).then(function (fetchResponse) {
          return {
            poolWrapper: poolWrapper,
            operations: fetchResponse.records,
            lastFetch: fetchResponse,

            // This is a hack so that when we are on the database route
            // it can trigger a refresh after modal-restart-database submits
            refresh: function refresh() {
              return _this.refresh.apply(_this, arguments);
            }
          };
        });
      });
    },

    actions: {
      loadMore: function loadMore() {
        var _this2 = this;

        // TODO: Handle rejection.
        this.controller.get("model.poolWrapper").fetch(OPERATION_BATCH_SIZE).then(function (fetchResponse) {
          _this2.controller.set('model.lastFetch', fetchResponse);

          var operations = _this2.controller.get('model.operations');
          fetchResponse.records.forEach(function (o) {
            return operations.pushObject(o);
          });

          _this2.controller.set('operationCount', operations.get("length"));
        })['catch'](function (e) {
          _ember['default'].get(_this2, 'flashMessages').danger('An error occured fetching operations');
          throw e;
        });
      }
    }
  });
});