define('diesel/gridiron-settings/route', ['exports', 'diesel/gridiron-setup/route'], function (exports, _dieselGridironSetupRoute) {
  exports['default'] = _dieselGridironSetupRoute['default'].extend({
    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);

      this.render('sidebars/engine-sidebar', {
        into: 'gridiron-settings',
        outlet: 'sidebar',
        controller: this.controllerFor('gridiron-organization')
      });
    }
  });
});