define('diesel/components/one-way-input/component', ['exports', 'ember'], function (exports, _ember) {

  function handleChangeEvent() {
    var value = this.readDOMAttr('value');
    processValue.call(this, value);
  }

  function processValue(rawValue) {
    var value = this.sanitizeInput(rawValue);

    if (this._sanitizedValue !== value) {
      this._sanitizedValue = value;
      this.attrs.update(value);
    }
  }

  exports['default'] = _ember['default'].Component.extend({
    tagName: 'input',
    attributeBindings: ['type', 'value', 'placeholder', 'data-stripe', 'name', 'autocomplete', 'disabled'],
    type: 'text',
    _sanitizedValue: undefined,

    input: handleChangeEvent,
    change: handleChangeEvent,

    sanitizeInput: function sanitizeInput(input) {
      return input;
    },

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      if (!this.attrs.update) {
        throw new Error('You must provide an `update` action to `{{' + this.templateName + '}}`.');
      }

      // TODO-double-modify This afterRender call should be removed
      _ember['default'].run.schedule('afterRender', function () {
        processValue.call(_this, _this.get('value'));
      });
    }
  });
});