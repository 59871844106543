define('diesel/asset/risks/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var asset = this.modelFor('asset');
      return asset.get('currentRiskProfile');
    },

    setupController: function setupController(controller, riskProfile) {
      var asset = this.modelFor('asset');
      var threatEvents = riskProfile.get('threatEvents').sortBy('riskIndex');
      controller.setProperties({ asset: asset, riskProfile: riskProfile, threatEvents: threatEvents });
    }
  });
});