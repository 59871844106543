define('diesel/components/modal-upgrade-to-alb/component', ['exports', 'ember', 'diesel/mixins/components/modal-base'], function (exports, _ember, _dieselMixinsComponentsModalBase) {
  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsModalBase['default'], {
    // Inputs
    vhostToUpgrade: null,

    title: "Upgrade to ALB",

    description: _ember['default'].computed('vhostToUpgrade.displayHost', function () {
      return this.get("vhostToUpgrade.displayHost");
    }),

    actions: {
      upgradeVhost: function upgradeVhost() {
        this.sendAction('upgradeVhost', this.get("vhostToUpgrade"));
      }
    }
  });
});