define('diesel/mixins/models/impacts', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  var FINANCIAL_VALUE_SCALE = [{ handle: 'low', name: 'Low', tooltip: 'The component processes data of little or no financial value.' }, { handle: 'medium', name: 'Medium', tooltip: 'The component processes data of limited intrinsic financial value, or data that can be resold for moderate value. Examples: PII, PHI' }, { handle: 'high', name: 'High', tooltip: 'The component processes data of intrinsic financial value, or data that can be resold for high value. Examples: cryptocurrency, credit card numbers' }];

  exports.FINANCIAL_VALUE_SCALE = FINANCIAL_VALUE_SCALE;
  var BUSINESS_CRITICALITY_SCALE = [{ handle: 'low', name: 'Low', tooltip: 'Downtime will not significantly affect your ability to run operations as normal.' }, { handle: 'medium', name: 'Medium', tooltip: 'This component performs an important function. Downtime will impact your ability to run normal operations.' }, { handle: 'high', name: 'High', tooltip: 'This component performs a critical function. You would not be able to conduct business as normal without it.' }];

  exports.BUSINESS_CRITICALITY_SCALE = BUSINESS_CRITICALITY_SCALE;
  var IMPACT_SCALE = [{ handle: 'low', name: 'Low', tooltip: 'Limited adverse effect. Examples: minor financial loss, inability to perform secondary operational functions' }, { handle: 'medium', name: 'Medium', tooltip: 'Serious adverse effect. Examples: inability to perform important operational functions, regulatory sanctions, damage to or loss of important assets' }, { handle: 'high', name: 'High', tooltip: 'Severe or catastrophic effect. Examples: inability to perform critical operations, major financial loss or liability' }];

  exports.IMPACT_SCALE = IMPACT_SCALE;
  exports['default'] = _ember['default'].Mixin.create({
    financialValue: _emberData['default'].attr('string', { defaultValue: FINANCIAL_VALUE_SCALE[0].handle }),
    integrityImpact: _emberData['default'].attr('string', { defaultValue: IMPACT_SCALE[0].handle }),
    availabilityImpact: _emberData['default'].attr('string', { defaultValue: IMPACT_SCALE[0].handle }),
    confidentialityImpact: _emberData['default'].attr('string', { defaultValue: IMPACT_SCALE[0].handle }),
    privacyImpact: _emberData['default'].attr('string', { defaultValue: IMPACT_SCALE[0].handle }),

    businessCriticality: _emberData['default'].attr('string', { defaultValue: BUSINESS_CRITICALITY_SCALE[0].handle }),
    businessCriticalityName: _ember['default'].computed('businessCriticality', function () {
      var handle = this.get('businessCriticality');
      return BUSINESS_CRITICALITY_SCALE.findBy('handle', handle).name;
    }),
    financialValueName: _ember['default'].computed('financialValue', function () {
      var handle = this.get('financialValue');
      return FINANCIAL_VALUE_SCALE.findBy('handle', handle).name;
    })
  });
});