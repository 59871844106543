define('diesel/components/service-settings/component', ['exports', 'ember', 'diesel/models/service', 'diesel/mixins/components/service-scaling'], function (exports, _ember, _dieselModelsService, _dieselMixinsComponentsServiceScaling) {
  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsServiceScaling['default'], {
    isSaving: false,
    successMessage: null,
    errorMessage: null,

    stack: null,
    service: null,
    database: null,
    containerSize: null,
    diskSize: null,
    containerCount: null,
    instanceClass: null,
    disk: null,

    containerCountMin: 0,
    containerCountMax: 10,

    initializeValues: function initializeValues() {
      this.set('containerCount', this.get('service.containerCount'));
      this.set('instanceClass', this.get('service.instanceClass'));
      if (this.get('disk')) {
        this.set('diskSize', this.get('disk.size'));
      }

      // existing services may be scaled to a size that is no longer within the min/mx thresholds
      // for the selected profile

      var _get = this.get('containerProfile');

      var minimumContainerSize = _get.minimumContainerSize;
      var name = _get.name;

      var currentSize = this.get('service.containerSize');
      var warning = '\n      Warning: The Memory Limit of this service is currently ' + currentSize / _dieselModelsService.GB + ' GB, which is below the minimum allowed for ' + name + ' services.\n      If you scale this service, the Memory Limit will be adjusted to the minimum of ' + minimumContainerSize / _dieselModelsService.GB + ' GB.\n    ';
      if (currentSize < minimumContainerSize) {
        this.set('containerSize', minimumContainerSize);
        this.set('belowThresholdWarning', warning);
      } else {
        this.set('containerSize', this.get('service.containerSize'));
      }
    },

    init: function init() {
      this._super.apply(this, arguments);
      this.initializeValues();
    },

    availableProfiles: _ember['default'].computed('stack.allowedContainerProfileClassess', function () {
      var allowedClasses = this.get('stack.allowedContainerProfileClassess') || [];
      return Object.keys(_dieselModelsService.CONTAINER_PROFILES).map(function (key) {
        var isEnabled = allowedClasses.includes(key[0].toUpperCase());
        return { key: key, value: _dieselModelsService.CONTAINER_PROFILES[key].name, disabled: !isEnabled };
      }).filter(function (profile) {
        return profile.key !== 't3';
      }); // hide the t3 (Economy) profile.
    }),

    hasProfileChanged: _ember['default'].computed('instanceClass', 'service.instanceClass', function () {
      return this.get('instanceClass') !== this.get('service.instanceClass');
    }),

    hasDiskChanged: _ember['default'].computed('diskSize', 'disk.size', function () {
      var disk = this.get('disk');
      return disk && this.get('diskSize') !== this.get('disk.size');
    }),

    containerSizes: _ember['default'].computed('stack.allowGranularContainerSizes', function () {
      return this.get('stack.allowGranularContainerSizes') ? _dieselModelsService.LINEAR_CONTAINER_SIZES : _dieselModelsService.EXPONENTIAL_CONTAINER_SIZES;
    }),

    availableContainerSizes: _ember['default'].computed('containerProfile', 'containerSizes', function () {
      var _get2 = this.get('containerProfile');

      var minimumContainerSize = _get2.minimumContainerSize;
      var maximumContainerSize = _get2.maximumContainerSize;

      return this.get('containerSizes').filter(function (s) {
        return s >= minimumContainerSize && s <= maximumContainerSize;
      }).map(function (s) {
        return { key: s, value: s < 1024 ? s + ' MB' : s / 1024 + ' GB' };
      });
    }),

    profileChangeSummary: _ember['default'].computed('instanceClass', 'service.instanceClass', function () {
      var previous = _dieselModelsService.CONTAINER_PROFILES[this.get('service.instanceClass')].name;
      var next = _dieselModelsService.CONTAINER_PROFILES[this.get('instanceClass')].name;
      return 'Changed from ' + previous + ' to ' + next;
    }),

    diskSizeSummary: _ember['default'].computed('diskSize', 'disk.size', function () {
      var previous = this.get('disk.size');
      var next = this.get('diskSize');
      return 'Changed from ' + previous + ' GB to ' + next + ' GB';
    }),

    hasCountChanged: _ember['default'].computed('containerCount', 'service.containerCount', function () {
      return this.get('containerCount') !== this.get('service.containerCount');
    }),

    countChangeSummary: _ember['default'].computed('containerCount', 'service.containerCount', function () {
      return 'Changed from ' + this.get('service.containerCount') + ' to ' + this.get('containerCount');
    }),

    hasSizeChanged: _ember['default'].computed('containerSize', 'service.containerSize', function () {
      return this.get('containerSize') !== this.get('service.containerSize');
    }),

    sizeChangeSummary: _ember['default'].computed('containerSize', 'service.containerSize', function () {
      var previous = this.get('service.containerSize') / _dieselModelsService.GB;
      var after = this.get('containerSize') / _dieselModelsService.GB;
      return 'Changed from ' + previous + ' GB to ' + after + ' GB';
    }),

    hasCpuShareChanged: _ember['default'].computed('cpuShare', 'service.cpuShare', function () {
      return this.get('cpuShare') !== this.get('service.cpuShare');
    }),

    cpuShareChangeSummary: _ember['default'].computed('cpuShare', 'service.cpuShare', function () {
      return 'Changed from ' + this.get('service.cpuShare') + ' to ' + this.get('cpuShare');
    }),

    hasPendingChanges: _ember['default'].computed.or('hasProfileChanged', 'hasCountChanged', 'hasSizeChanged', 'hasDiskChanged'),

    containerCountDescription: _ember['default'].computed('containerProfile', function () {
      var _get3 = this.get('containerProfile');

      var name = _get3.name;
      var maximumContainerCount = _get3.maximumContainerCount;

      return '\n      Horizontally scale this service by increasing the number of containers.\n      A count of 2 or more will provide your service with High Availability.\n      Supported Container Counts for ' + name + ' profiles: 0-' + maximumContainerCount + ' containers.\n    ';
    }),

    memoryLimitDescription: _ember['default'].computed('availableContainerSizes', 'containerProfile', function () {
      var _get4 = this.get('containerProfile');

      var name = _get4.name;

      var availableContainerSizes = this.get('availableContainerSizes');
      var min = availableContainerSizes.get('firstObject.value');
      var max = availableContainerSizes.get('lastObject.value');
      return '\n      Vertically scale this service by increasing Memory Limit per Container.\n      ' + name + ' profiles support a memory limit range of: ' + min + '-' + max + '.\n    ';
    }),

    actions: {
      setContainerCount: function setContainerCount(value) {
        var _get5 = this.get('containerProfile');

        var maximumContainerCount = _get5.maximumContainerCount;

        var containerCount = Math.min(parseInt(value, 10), maximumContainerCount);
        this.set('containerCount', containerCount);
      },

      setProfile: function setProfile(profile) {
        this.set('instanceClass', profile);
      },

      setContainerSize: function setContainerSize(size) {
        this.set('containerSize', size);
      },

      setDiskSize: function setDiskSize(size) {
        this.set('diskSize', size);
      },

      cancel: function cancel() {
        this.initializeValues();
      },

      scale: function scale() {
        if (this.get('isSaving')) {
          return;
        }

        var component = this;

        var _getProperties = this.getProperties('service', 'containerSize', 'containerCount', 'instanceClass', 'diskSize', 'database');

        var service = _getProperties.service;
        var containerSize = _getProperties.containerSize;
        var containerCount = _getProperties.containerCount;
        var instanceClass = _getProperties.instanceClass;
        var diskSize = _getProperties.diskSize;
        var database = _getProperties.database;

        this.set('isSaving', true);

        var deferred = _ember['default'].RSVP.defer();

        // the API only expects the disk size to be > 0 when it needs to be scaled
        // otherwise it gets into a broken state
        var diskSizeAdjusted = 0;
        if (this.get('hasDiskChanged')) {
          diskSizeAdjusted = diskSize;
        }

        this.sendAction('onScaleService', database || service, instanceClass, containerCount, containerSize, deferred, diskSizeAdjusted);

        deferred.promise.then(function () {
          if (component.isDestroyed) {
            return;
          }
          component.set('successMessage', 'Scale Completed Successfully');
        })['catch'](function (e) {
          if (component.isDestroyed) {
            return;
          }
          component.set('errorMessage', e.message);
        })['finally'](function () {
          if (component.isDestroyed) {
            return;
          }
          component.set('isSaving', false);
        });
      },

      clearMessages: function clearMessages() {
        this.set('errorMessage', false);
        this.set('successMessage', false);
        this.set('isSaving', false);
      }
    }
  });
});