define("diesel/utils/from-now", ["exports"], function (exports) {
  exports.fromNow = fromNow;
  exports.ago = ago;

  function fromNow(params) {
    var direction = arguments.length <= 1 || arguments[1] === undefined ? 1 : arguments[1];

    var years = params.years || 0;
    var months = params.months || 0;
    var days = params.days || 0;
    var date = new Date();

    date.setYear(date.getFullYear() + direction * years);
    date.setMonth(date.getMonth() + direction * months);
    date.setDate(date.getDate() + direction * days);

    return date;
  }

  function ago(params) {
    return fromNow(params, -1);
  }
});