define('diesel/components/ui-icon/component', ['exports', 'ember'], function (exports, _ember) {

  // List of icons available
  // exclamation-circle-sm
  // check-circle-sm
  // question-circle-sm

  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    icon: null,
    src: _ember['default'].computed('icon', function () {
      var icon = this.get('icon');
      return '/assets/images/ui-icons/' + icon + '.svg';
    })
  });
});