define('diesel/components/asset-panel-risk-item/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    showAllDescription: null,
    showBrief: _ember['default'].computed('risk.briefDescription', 'showAllDescription', function () {
      return this.get('risk.briefDescription') && !this.get('showAllDescription');
    }),

    actions: {
      showAll: function showAll() {
        this.set('showAllDescription', true);
      }
    }
  });
});