define('diesel/components/learn-more-tooltip/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'span',
    on: 'click',
    placement: 'bottom',
    classNames: ['learn-more-tooltip'],
    hrefTarget: '_blank',
    learnMoreText: 'Learn More',
    content: _ember['default'].computed('tooltip.content', 'tooltip.learnMore', function () {
      var tip = this.get('tip');
      var learnMoreText = this.get('learnMoreText');
      var target = this.get('hrefTarget');
      var content = tip.content;
      var learnMore = tip.learnMore;

      if (learnMore) {
        learnMore = '<div class="learn-more-cta">\n                     <a class="learn-more-link" href="' + learnMore + '" target="' + target + '">\n                       ' + learnMoreText + '\n                     </a>\n                   </div>';
      } else {
        learnMore = '';
      }

      return '<div class="learn-more-tooltip-wrapper">\n              <div class="learn-more-content">' + content + '</div>\n              ' + learnMore + '\n            </div>';
    })
  });
});