define('diesel/gridiron-settings/team/roles/route', ['exports', 'ember', 'diesel/models/organization-profile'], function (exports, _ember, _dieselModelsOrganizationProfile) {
  var decamelize = _ember['default'].String.decamelize;
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var _this = this;

      var complianceStatus = this.get('complianceStatus');
      var organizationProfile = this.get('complianceStatus.organizationProfile');

      var _complianceStatus$getProperties = complianceStatus.getProperties('organization', 'invitations');

      var organization = _complianceStatus$getProperties.organization;
      var invitations = _complianceStatus$getProperties.invitations;

      var roleDesignations = [];
      this._roleConfigs = [];

      // Only show role designations that are actually set on the organization
      // profile.
      Object.keys(_dieselModelsOrganizationProfile.ROLE_DESIGNATIONS).forEach(function (designationName) {
        // Must decamelize the links name because ember-data will normalize the
        // api response to be decamelized.
        // TODO-ember-data This should use a Relationship Reference instead
        var designationPath = 'links.' + decamelize(designationName);
        var roleHref = organizationProfile.get(designationPath);
        if (roleHref) {
          // Stash the role config here for easier access later in the controller
          // lifecycle
          _this._roleConfigs.push({
            href: roleHref,
            key: designationName,
            config: _dieselModelsOrganizationProfile.ROLE_DESIGNATIONS[designationName]
          });

          roleDesignations.push(organizationProfile.get(designationName));
        }
      });

      roleDesignations = _ember['default'].RSVP.all(roleDesignations);
      return _ember['default'].RSVP.hash({ organization: organization, invitations: invitations, roleDesignations: roleDesignations });
    },

    afterModel: function afterModel(model) {
      // Preload all memberships so we can determine which ones should be protected
      return _ember['default'].RSVP.all(model.roleDesignations.map(function (r) {
        return r.get('memberships');
      }));
    },

    setupController: function setupController(controller, model) {
      var organizationProfile = this.get('complianceStatus.organizationProfile');
      this._setupProtectedMemberships();
      var organization = this.modelFor('gridiron-organization').get('organization');
      controller.setProperties({ model: model, organizationProfile: organizationProfile, organization: organization });
      controller.set('_roleConfigs', this._roleConfigs);
    },

    // This activate hook will catch new transitions into this route after
    // `setupController` has already been called.
    activate: function activate() {
      if (this.currentModel) {
        this._setupProtectedMemberships();
      }
    },

    _setupProtectedMemberships: function _setupProtectedMemberships() {
      var _this2 = this;

      // This method is used to loop over each role that has "protected designations"
      // A protected designation, means that you cannot remove certain users from a role
      // because they hold a designation that requires them to be in said role.
      // For example, you cannot remove the Incident Response lead from the
      // incident response team.

      // This method works by looping over roles that have `protectedDesignations`,
      // looping over the users in said protecting designations, and marking
      // any membership they hold in this role as protected.  We set the name
      // of the designation protecting the membership as a transient attr named
      // `protectedDesignation`.  Our UI renders this to the user, so they know why
      // a membership can't be destroyed.
      var roles = this.currentModel.roleDesignations;
      var organizationProfile = this.get('complianceStatus.organizationProfile');

      roles.forEach(function (role) {
        var roleConfig = _this2._roleConfigs.findBy('href', role.get('links.self'));
        var roleProtectedDesignations = Object.keys(_dieselModelsOrganizationProfile.USER_DESIGNATIONS).filter(function (designationName) {
          var d = _dieselModelsOrganizationProfile.USER_DESIGNATIONS[designationName];
          return d.role === roleConfig.key;
        });

        if (roleProtectedDesignations) {
          role.get('memberships').forEach(function (membership) {
            membership.set('protectedDesignations', _ember['default'].A()); // Unset all by default
            roleProtectedDesignations.forEach(function (protectedDesignation) {
              var userHref = organizationProfile.get(protectedDesignation + 'Url');
              if (membership.get('user.links.self') === userHref) {
                // This membership reference a user that holds a protected designation
                // This means that we should flag this memberhsip as protected
                // and prevent it from being removed in the UI.

                var designationName = _dieselModelsOrganizationProfile.USER_DESIGNATIONS[protectedDesignation].name;
                membership.get('protectedDesignations').pushObject(designationName);
              }
            });
          });
        }
      });
    }
  });
});