define('diesel/adapters/attestation', ['exports', 'diesel/adapters/gridiron', 'diesel/utils/build-url-with-prefix-map'], function (exports, _dieselAdaptersGridiron, _dieselUtilsBuildUrlWithPrefixMap) {
  exports['default'] = _dieselAdaptersGridiron['default'].extend({
    buildURL: (0, _dieselUtilsBuildUrlWithPrefixMap['default'])({
      'systems': { property: 'system.id', only: ['createRecord'] },
      'components': { property: 'component.id', only: ['createRecord'] },
      'organization_profiles': { property: 'organizationProfile.id', only: ['createRecord'] }
    }),

    updateRecord: function updateRecord() {
      // Attestations are append only.  Overloading updateRecord with createRecord
      // forces attestation updates to POST rather than PUT
      return this.createRecord.apply(this, arguments);
    }
  });
});