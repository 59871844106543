define('diesel/components/invoice-usage/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    lineItems: null,
    goldenboyLib: null,

    sortBy: ['unit:asc'],

    sortedUsageRows: _ember['default'].computed.sort('usageRows', 'sortBy'),

    usageRows: _ember['default'].computed('lineItems.[]', function () {
      var goldenboyLib = this.get('goldenboyLib');
      var usageRows = {};

      this.get('lineItems').forEach(function (lineItem) {
        if (goldenboyLib.showUnit(_ember['default'].get(lineItem, 'unit'))) {
          if (!usageRows[lineItem.unit]) {
            usageRows[lineItem.unit] = {
              allocation: 0,
              usage: 0
            };
          }

          if (lineItem.quantity < 0) {
            var lineItemAllocation = -lineItem.quantity;
            usageRows[lineItem.unit].allocation += lineItemAllocation;
          } else {
            usageRows[lineItem.unit].usage += lineItem.quantity;
          }
        }
      });

      return Object.keys(usageRows).map(function (unit) {
        var allocation = usageRows[unit].allocation;
        var usage = usageRows[unit].usage;

        return { unit: unit, allocation: allocation, usage: usage };
      });
    })
  });
});