define('diesel/components/service-resource-usage/component', ['exports', 'ember'], function (exports, _ember) {
  var HOURS_PER_MONTH = 730;

  exports.HOURS_PER_MONTH = HOURS_PER_MONTH;
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'tr',

    hasContainers: _ember['default'].computed.gt('release.containers.length', 0),

    total: _ember['default'].computed('service.usage', 'hourlyRate', function () {
      return this.get('service.usage') * this.get('hourlyRate') * HOURS_PER_MONTH;
    })
  });
});