define("diesel/organization/single-sign-on/route", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Route.extend({
    modalDisplay: _ember["default"].inject.service(),

    model: function model() {
      var _this = this;

      var authorizationContext = this.modelFor("organization");
      var organization = authorizationContext.get("organization");
      var whitelist = this.get("store").query("whitelistMembership", { organization: organization }).then(function (wlMems) {
        return wlMems.map(function (wlm) {
          return wlm.get("user");
        }).sortBy("name");
      });

      return _ember["default"].RSVP.hash({
        authorizationContext: authorizationContext,
        organization: organization,
        whitelist: whitelist,
        samlConfig: this.get("store").findAll("saml-configuration").then(function (configs) {
          return configs.findBy("links.organization", organization.get("links.self"));
        }).then(function (model) {
          return model ? model : _this.get("store").createRecord("saml-configuration", {
            organization: organization
          });
        }),
        eligible: whitelist.then(function (wl) {
          return _ember["default"].RSVP.all(wl).then(function (whitelist_members) {
            var organizationUsers = authorizationContext.get("users");
            var ownerRoles = authorizationContext.get("organizationOwnerRoles");
            var nonWhitelist = organizationUsers.filter(function (user) {
              return !whitelist_members.includes(user);
            });

            var userRolePromises = nonWhitelist.map(function (user) {
              return user.get("roles");
            });
            return _ember["default"].RSVP.all(userRolePromises).then(function (roles) {
              return nonWhitelist.filter(function (user, index) {
                return !roles[index].any(function (r) {
                  return ownerRoles.includes(r);
                });
              });
            });
          });
        })
      });
    },

    actions: {
      openEditSamlModal: function openEditSamlModal() {
        var modalDisplay = this.get("modalDisplay");
        modalDisplay.showModal("modal-edit-single-sign-on", {
          newSaml: _ember["default"].get(this.currentModel, "samlConfig")
        });
      },

      remove: function remove(saml) {
        var _this2 = this;

        this.controller.set("isRemoving", true);

        saml.destroyRecord().then(function () {
          var message = "SSO provider removed";
          _ember["default"].get(_this2, "flashMessages").success(message);
          _this2.controller.set("isRemoving", false);
          _this2.refresh();
        })["catch"](function (e) {
          var message = e.message || "There was an error removing your SSO provider";
          _ember["default"].get(_this2, "flashMessages").danger(message);
          _this2.controller.set("isRemoving", false);
        });
      },

      toggleSsoEnforce: function toggleSsoEnforce() {
        var _this3 = this;

        this.controller.set("enforceIsSaving", true);
        var org = _ember["default"].get(this.currentModel, "organization");
        var ssoEnforce = org.get("ssoEnforced");
        org.set("ssoEnforced", !ssoEnforce);

        var message = org.get("ssoEnforced") ? "enabled" : "disabled";
        org.save().then(function () {
          _ember["default"].get(_this3, "flashMessages").success("Enforced SSO use is " + message);
          _this3.refresh();
        })["catch"](function (e) {
          var message = e.message || "There was an error updating your SSO enforcement settings.";
          _ember["default"].get(_this3, "flashMessages").danger(message);
        })["finally"](function () {
          _this3.controller.set("enforceIsSaving", false);
        });
      },

      addToWhitelist: function addToWhitelist(user) {
        var _this4 = this;

        var org = _ember["default"].get(this.currentModel, "organization");
        var wlm = this.get("store").createRecord("whitelistMembership", {
          user: user,
          organization: org
        });
        var name = user.get("name");

        wlm.save().then(function () {
          _ember["default"].get(_this4, "flashMessages").success(name + " added to whitelist");
          _this4.controller.set("selectedUser", '');
          _this4.refresh();
        })["catch"](function (e) {
          var message = e.message || "There was an error adding " + name + " to whitelist";
          _ember["default"].get(_this4, "flashMessages").danger(message);
        });
      },

      removeFromWhitelist: function removeFromWhitelist(user) {
        var _this5 = this;

        var whitelist = this.get("store").peekAll("whitelistMembership");
        var wlm = whitelist.find(function (wlm) {
          var userMatch = wlm.get("links.user") == user.get("links.self");
          var orgMatch = wlm.get("links.organization") == _ember["default"].get(_this5.currentModel, "organization.links.self");
          return userMatch && orgMatch;
        });
        var name = user.get("name");

        wlm.destroyRecord().then(function () {
          _ember["default"].get(_this5, "flashMessages").success(name + " removed from allow list");
          _this5.refresh();
        })["catch"](function (e) {
          var message = e.message || "There was an error removing " + name + " from allow list";
          _ember["default"].get(_this5, "flashMessages").danger(message);
        });
      }
    }
  });
});