define("diesel/adapters/gridiron", ["exports", "diesel/config/environment", "diesel/adapters/application", "diesel/mixins/adapters/api-path"], function (exports, _dieselConfigEnvironment, _dieselAdaptersApplication, _dieselMixinsAdaptersApiPath) {
  var PATHS_FOR_TYPES = {
    criterion: 'criteria'
  };

  exports.PATHS_FOR_TYPES = PATHS_FOR_TYPES;
  exports["default"] = _dieselAdaptersApplication["default"].extend(_dieselMixinsAdaptersApiPath["default"], {
    host: _dieselConfigEnvironment["default"].gridironBaseUri,
    pathForType: function pathForType(type) {
      return PATHS_FOR_TYPES[type] || this._super(type);
    }
  });
});