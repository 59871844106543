define('diesel/components/document-activity-item/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['document-activity-item'],
    type: _ember['default'].computed('document.criterion.handle', function () {
      var handles = { training_log: 'Basic',
        developer_training_log: 'Developer',
        security_officer_training_log: 'Security' };

      return handles[this.get('document.criterion.handle')];
    })
  });
});