define('diesel/adapters/criterion', ['exports', 'diesel/adapters/gridiron', 'ember'], function (exports, _dieselAdaptersGridiron, _ember) {
  exports['default'] = _dieselAdaptersGridiron['default'].extend({
    currentOrganization: _ember['default'].inject.service(),

    findHasMany: function findHasMany(store, record, url) {
      var organizationHref = this.get('currentOrganization.organizationHref');
      url = url.replace(/\{\?organization\}/, '?organization=' + organizationHref);
      return this._super(store, record, url);
    }
  });
});