define('diesel/components/modal-edit-incident/component', ['exports', 'ember', 'diesel/mixins/components/modal-base'], function (exports, _ember, _dieselMixinsComponentsModalBase) {
  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsModalBase['default'], {
    // Inputs
    editIncident: null,

    currentTab: null,
    modalTitle: 'Edit Incident Response',

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var tab = _ember['default'].getOwner(this).lookup('controller:application').currentRouteName.split('.').get('lastObject');
      if (tab === 'activity') {
        tab = 'details';
      }
      this.set('currentTab', tab || 'details');
    },

    disableSave: _ember['default'].computed.or('hasError', 'isPending'),
    hasError: _ember['default'].computed.gt('errors.editIncident.title.length', 0),
    isPending: _ember['default'].computed.alias('editIncident.isSaving'),

    severityItems: _ember['default'].computed('editIncident.severityItems', function () {
      // This is necessary to prevent a possible bug where the `items` property
      // passed to `{{select-pills}}` is undefined and the internals of {{select-pills}}
      // throws an error. There is an acceptance test to cover this.
      return this.get('editIncident.severityItems') || [];
    }),

    actions: {
      setTab: function setTab(newTab) {
        this.set('currentTab', newTab);
      },

      beforeDismiss: function beforeDismiss() {
        this.get('editIncident').rollbackAttributes();
      },

      saveIncident: function saveIncident() {
        var incident = this.get('editIncident');
        this.sendAction('onEditIncident', incident);
      }
    }
  });
});