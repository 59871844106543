define('diesel/settings/cli-sso-token/index/controller', ['exports', 'ember'], function (exports, _ember) {

  var DURATIONS = [{ id: '1 Hour', seconds: 3600 }, { id: '4 Hours', seconds: 14400 }, { id: '12 Hours', seconds: 43200 }, { id: '24 Hours', seconds: 86400 }, { id: '5 Days', seconds: 432000 }, { id: '1 Week', seconds: 604800 }];

  exports['default'] = _ember['default'].Controller.extend({
    durationSelection: DURATIONS.find(function (option) {
      return option.id == '12 Hours';
    }),
    durationOptions: DURATIONS
  });
});