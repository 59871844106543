define('diesel/components/billing-invoice-status-summary/component', ['exports', 'ember', 'diesel/models/invoice'], function (exports, _ember, _dieselModelsInvoice) {

  var STATUS_MESSAGES = {
    paid: 'Paid in full',
    overpaid: 'Overpaid',
    due: 'Due',
    forgiven: 'Forgiven',
    partially_forgiven: 'Partially forgiven'
  };

  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    invoice: null,
    billingIteration: null,

    billingIterationId: _ember['default'].computed('billingIteration.id', 'invoice.id', function () {
      var billingIterationId = this.get('billingIteration.id');
      if (billingIterationId) {
        return billingIterationId;
      }

      var invoice = this.get('invoice');
      return invoice.belongsTo('billingIteration').id();
    }),

    summary: _ember['default'].computed('billingIteration.invoiceState', 'invoice.state', function () {
      var state = this.get('billingIteration.invoiceState') || this.get('invoice.state');
      if (!state) {
        return '';
      }

      return STATUS_MESSAGES[state] || state.capitalize();
    }),

    requiresPayment: _ember['default'].computed('billingIteration.invoiceState', 'invoice.state', function () {
      var state = this.get('billingIteration.invoiceState') || this.get('invoice.state');
      if (!state) {
        return false;
      }

      return _dieselModelsInvoice.UNPAID_STATES.includes(state);
    })
  });
});