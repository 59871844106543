define('diesel/risk-assessments/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    showSPDNotice: _ember['default'].computed.or('hasNoRiskAssessments', 'hasNotCompletedSetup'),

    hasNotCompletedSetup: _ember['default'].computed.equal('organizationProfile.hasCompletedSetup', false),
    persistedRiskAssessments: _ember['default'].computed.filterBy('riskAssessments', 'isNew', false),

    hasNoRiskAssessments: _ember['default'].computed.equal('persistedRiskAssessments.length', 0),
    archivedRiskAssessments: _ember['default'].computed.filterBy('persistedRiskAssessments', 'status', 'archived')
  });
});