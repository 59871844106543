define('diesel/mixins/models/statuses', ['exports'], function (exports) {
  exports['default'] = {
    PENDING: 'pending',
    PROVISIONING: 'provisioning',
    DEPROVISIONED: 'deprovisioned',
    PROVISIONED: 'provisioned',
    DEPROVISIONING: 'deprovisioning',
    PROVISION_FAILED: 'provision_failed',
    DEPROVISION_FAILED: 'deprovision_failed'
  };
});