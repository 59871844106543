define('diesel/helpers/not', ['exports', 'ember'], function (exports, _ember) {
  exports.not = not;

  function not(params) {
    for (var i = 0; i < params.length; i++) {
      if (params[i]) {
        return false;
      }
    }
    return true;
  }

  exports['default'] = _ember['default'].Helper.helper(not);
});