define('diesel/asset/controller', ['exports', 'ember'], function (exports, _ember) {
  var assert = _ember['default'].assert;
  exports['default'] = _ember['default'].Controller.extend({
    modalDisplay: _ember['default'].inject.service(),

    editSystem: function editSystem(_ref) {
      var system = _ref.system;
      var roles = _ref.roles;
      var users = _ref.users;
      var organizationProfile = _ref.organizationProfile;

      var modalParams = { system: system, roles: roles, users: users, organizationProfile: organizationProfile };
      var modalDisplay = this.get('modalDisplay');
      modalDisplay.showModal('modal-create-system', { createSystemModal: modalParams }, {
        actions: {
          onSystemUpdate: modalDisplay.modalAction(this, 'onSystemUpdate'),
          onSystemRemove: modalDisplay.modalAction(this, 'onAssetRemove')
        }
      });
    },

    editComponent: function editComponent(_ref2) {
      var component = _ref2.component;
      var organizationProfile = _ref2.organizationProfile;
      var components = _ref2.components;
      var systems = _ref2.systems;
      var roles = _ref2.roles;
      var users = _ref2.users;
      var systemTemplates = _ref2.systemTemplates;

      var componentType = component.get('type');
      var modalDisplay = this.get('modalDisplay');
      assert('Must pass systemTemplates when editing component', !!systemTemplates);

      modalDisplay.showModal('modal-edit-system-component', {
        modalComponentEditorProperties: {
          allowModifyDependencies: false,
          component: component,
          organizationProfile: organizationProfile,
          components: components,
          existingSystems: systems,
          componentType: componentType,
          roles: roles,
          users: users,
          systemTemplates: systemTemplates
        } }, {
        actions: {
          onComponentUpdate: modalDisplay.modalAction(this, 'onComponentUpdate'),
          onComponentRemove: modalDisplay.modalAction(this, 'onAssetRemove')
        }
      });
    },

    actions: {
      onAssetRemove: function onAssetRemove(handle) {
        var message = handle + ' removed from Gridiron!';
        this.get('flashMessages').success(message);
        this.transitionToRoute('assets');
      },

      onSystemUpdate: function onSystemUpdate(system) {
        var organizationProfile = this.get('complianceStatus.organizationProfile');
        var message = system.get('name') + ' configured successfully!';
        this.get('flashMessages').success(message);

        organizationProfile.reloadCurrentSecurityReview();
        organizationProfile.observeDocumentBuilds();
      },

      onComponentUpdate: function onComponentUpdate(component) {
        var message = component.get('handle') + ' configured successfully!';
        this.get('flashMessages').success(message);
      }
    }
  });
});