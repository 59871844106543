define('diesel/components/payments-table-row/component', ['exports', 'ember'], function (exports, _ember) {
  var StatusPending = 0;
  exports.StatusPending = StatusPending;
  var StatusSuccess = 1;
  exports.StatusSuccess = StatusSuccess;
  var StatusFailed = -1;

  exports.StatusFailed = StatusFailed;
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'tr',
    classNameBindings: ['statusClass'],
    attributeBindings: ['payment.id:data-test-payment', 'status:data-test-payment-status'],

    payment: null,
    paymentMethods: null,

    status: _ember['default'].computed('payment.paymentClosure', 'payment.paymentClosure.success', function () {
      var paymentClosure = this.get('payment.paymentClosure');
      if (!paymentClosure) {
        return StatusPending;
      }

      var success = this.get('payment.paymentClosure.success');
      return success ? StatusSuccess : StatusFailed;
    }),

    statusClass: _ember['default'].computed('status', function () {
      var status = this.get('status');

      if (status === StatusPending) {
        return '';
      }

      return status === StatusFailed ? 'danger' : 'success';
    })
  });
});