define('diesel/utils/course-assignment-state', ['exports', 'ember'], function (exports, _ember) {
  exports.courseHasModule = courseHasModule;

  // This function iterates over the module definition for a course in order to
  // determine additional context for the given module key.
  function buildCurrentModuleState(_ref) {
    var course = _ref.course;
    var assignment = _ref.assignment;
    var module = _ref.module;

    _ember['default'].assert('A `course` is required to build a `CourseAssignmentState`', !!course);
    _ember['default'].assert('A `assignment` is required to build a `CourseAssignmentState`', !!assignment);

    var modules = course.get('modules');
    // Module is either provided by route param, the current module stored on the
    // assignment, or the first module in the course
    module = module || assignment.get('currentModule') || modules[0].key;

    var index = course.get('moduleKeys').indexOf(module);
    var moduleConfig = modules[index];
    // This can happen if an assignment saves progress for a given module that has
    // since been removed from the course config.  The route should check that
    // the provided module exists before attempting to get state.
    _ember['default'].assert(module + ' not defined for ' + course.get('name') + ', check \'utils/training-config.js\'', !!moduleConfig);

    var isFirst = index === 0;
    var isLast = index === modules.length - 1;
    var nextModule = isLast ? null : modules[index + 1];
    var previousModule = isFirst ? null : modules[index - 1];
    var nextModuleLabel = nextModule ? 'Next: ' + nextModule.name + ' →' : 'Finish';
    var previousModuleLabel = previousModule ? '← Previous: ' + previousModule.name : null;
    var templateName = 'courses/' + course.get('slug') + '/' + module;
    var progress = index + 1;
    var progressPercent = (progress / modules.length * 100).toFixed(2);

    return Object.assign({
      isFirst: isFirst, isLast: isLast, index: index, assignment: assignment, course: course, module: module, previousModule: previousModule, nextModule: nextModule,
      nextModuleLabel: nextModuleLabel, previousModuleLabel: previousModuleLabel, templateName: templateName, progressPercent: progressPercent, modules: modules,
      progress: progress
    }, moduleConfig);
  }

  function courseHasModule(course, module) {
    return module && course.get('moduleKeys').includes(module);
  }

  exports['default'] = buildCurrentModuleState;
});