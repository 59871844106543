define("diesel/components/modal-create-app/component", ["exports", "ember", "diesel/config/environment", "ember-validations", "diesel/mixins/components/modal-base"], function (exports, _ember, _dieselConfigEnvironment, _emberValidations, _dieselMixinsComponentsModalBase) {
  exports["default"] = _ember["default"].Component.extend(_emberValidations["default"], _dieselMixinsComponentsModalBase["default"], {
    session: _ember["default"].inject.service(),

    newApp: null,
    disableSave: _ember["default"].computed.or('hasError', 'isPending'),
    isPending: _ember["default"].computed.or('newApp.isSaving', 'newApp.isValidating'),
    hasError: _ember["default"].computed.gt('errors.newApp.handle.length', 0),
    title: _ember["default"].computed('account.handle', function () {
      return "Create a new app on " + this.get('account.handle');
    }),

    description: _ember["default"].computed('account.allowPHI', function () {
      if (this.get('account.allowPHI')) {
        return this.get('account.handle') + " is a dedicated environment. Apps\n              created in this environment are safe for regulated data.";
      }

      return this.get('account.handle') + " is a shared environment.  Apps created\n            in this environment are NOT safe for regulated data.";
    }),

    validations: {
      'newApp.handle': {
        uniqueness: {
          message: 'is taken.',
          paramName: 'handle',
          url: function url() {
            return (_dieselConfigEnvironment["default"].apiBaseUri || '') + "/accounts/" + this.get('account.id') + "/claims/app";
          },
          headers: function headers() {
            return { 'Authorization': "Bearer " + this.get("session.token.accessToken") };
          }
        }
      }
    },

    actions: {
      beforeDismiss: function beforeDismiss() {
        this.get('newApp').rollbackAttributes();
      },

      createApp: function createApp() {
        var app = this.get('newApp');
        this.sendAction('onCreateApp', app);
      }
    }
  });
});