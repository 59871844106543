define('diesel/mixins/routes/agreement-owners-route-mixin', ['exports', 'ember'], function (exports, _ember) {
  var assert = _ember['default'].assert;
  var _Ember$String = _ember['default'].String;
  var pluralize = _Ember$String.pluralize;
  var capitalize = _Ember$String.capitalize;
  exports['default'] = _ember['default'].Mixin.create({
    modalDisplay: _ember['default'].inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      assert('Must set \'MODEL_NAME\' prop on AgreementOwnersRouteMixin', !!this.get('MODEL_NAME'));
    },

    title: function title() {
      return 'Gridiron ' + capitalize(this.get('MODEL_NAME')) + ' Management';
    },

    model: function model() {
      var organization = this.get('complianceStatus.organization');
      var organizationProfile = this.modelFor('gridiron-admin');
      var items = organizationProfile.hasMany(pluralize(this.get('MODEL_NAME'))).reload().then(function (reloadedItems) {
        return reloadedItems.sortBy('name');
      });

      return _ember['default'].RSVP.hash({
        organization: organization, organizationProfile: organizationProfile, items: items
      });
    },

    setupController: function setupController(controller, model) {
      controller.setProperties(model);
    },

    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);

      this.render('sidebars/engine-sidebar', {
        into: pluralize(this.get('MODEL_NAME')),
        outlet: 'sidebar',
        controller: this.controllerFor('gridiron-organization')
      });
    },

    openAttachmentModal: function openAttachmentModal(params) {
      var modalDisplay = this.get('modalDisplay');
      var modalName = 'modal-attachment';
      modalDisplay.showModal(modalName, { attachmentModal: params }, {
        actions: {
          // onAgreementUpdate: modalDisplay.modalAction(this, 'onAgreementUpdate')
        }
      });
    },

    actions: {
      openModal: function openModal() {
        var model = this.store.createRecord(this.get('MODEL_NAME'), {
          organizationProfile: this.currentModel.organizationProfile
        });
        var modalDetails = { model: model };
        var modalName = 'modal-' + this.get('MODEL_NAME');
        var modalDisplay = this.get('modalDisplay');
        modalDisplay.showModal(modalName, { modalDetails: modalDetails }, {
          actions: {
            onUpdate: modalDisplay.modalAction(this, 'onUpdate')
          }
        });
      },
      onUpdate: function onUpdate(model) {
        var message = 'Successfully added ' + model.get('name');
        _ember['default'].get(this, 'flashMessages').success(message);
        this.transitionTo(this.get('MODEL_NAME'), model);
      },

      openAttachmentModal: function openAttachmentModal(agreement) {
        this.openAttachmentModal({ agreement: agreement });
      }
    }
  });
});