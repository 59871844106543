define('diesel/services/https-dns', ['exports', 'ember', 'diesel/config/environment'], function (exports, _ember, _dieselConfigEnvironment) {

  var HTTPS_DNS_API = 'https://dns.google.com/resolve';

  exports['default'] = _ember['default'].Service.extend({
    testCnameMatch: function testCnameMatch(from, to) {
      if (_dieselConfigEnvironment['default'].environment === "test") {
        return _ember['default'].RSVP.reject(new Error('disabled in test'));
      }

      return this._testCnameMatch(from, to);
    },

    _normalizeName: function _normalizeName(name) {
      if (name[name.length - 1] === '.') {
        return name;
      }
      return name + '.';
    },

    _testCnameMatch: function _testCnameMatch(from, to) {
      var _this = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _ember['default'].$.ajax(HTTPS_DNS_API, {
          success: function success(data) {
            return resolve(data);
          },
          error: function error(_jqXHR, textStatus, errorThrown) {
            reject(new Error('Error querying ' + HTTPS_DNS_API + ' for ' + from + ': ' + textStatus + ' ' + errorThrown));
          },
          crossDomain: true,
          dataType: 'json',
          data: { name: _this._normalizeName(from), type: 'CNAME' }
        });
      }).then(function (r) {
        if (r.Status !== 0) {
          return false;
        }

        return (r.Answer || []).any(function (answer) {
          return _this._normalizeName(answer.data) === _this._normalizeName(to);
        });
      });
    }
  });
});