define('diesel/utils/authorization-context', ['exports', 'ember', 'diesel/config/environment', 'diesel/models/organization-profile'], function (exports, _ember, _dieselConfigEnvironment, _dieselModelsOrganizationProfile) {

  // This class is meant to bootstrap all models required to render dashboard
  // for a given organizations' users.  It also provides a context to be used
  // for difficult computations based on the current user and their relationship
  // to their organization, e.g. permission checks

  // AuthorizationContext should be instantiated with the following

  // * organization
  // * currentUserRoles
  // * accounts (all accounts for given user, this service will filter)
  // * currentUser

  exports['default'] = _ember['default'].Object.extend({
    load: function load() {
      var _this = this;

      // Expect this to be initialized with a store.

      var _getProperties = this.getProperties('organization', 'store');

      var store = _getProperties.store;
      var organization = _getProperties.organization;

      var organizationProfile = this.loadOrganizationProfile(organization, store);
      if (organizationProfile) {
        this.loadOrganizationFeatures(organizationProfile);
        this.loadPlan(organization, store);
      }

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _ember['default'].RSVP.hash({
          users: organization.get('users'),
          invitations: organization.get('invitations'),
          roles: organization.get('roles'),
          billingDetail: _dieselConfigEnvironment['default'].featureFlags.skipBilling ? null : organization.get('billingDetail'),
          organizationProfile: organizationProfile
        }).then(function (props) {
          // Find all accounts for this organizations
          var organizationHref = _this.get('organization.links.self');
          props.accounts = _this.get('accounts').filterBy('links.organization', organizationHref);

          // NOTE: This cannot be a computed because we cannot create computeds on data.links.
          // TODO This can likely now be made a computed -- it used to rely on data.links but now that
          // it relies on the `links` attribute directly, it should be able to be a CP
          props.unassignedAccounts = props.accounts.filter(function (account) {
            return !account.get('links.stack');
          });

          var stackHrefs = props.accounts.mapBy('links.stack').reduce(function (h, l) {
            h[l] = true;
            return h;
          }, Object.create(null));

          props.stacks = [];
          props.eligibleDevelopmentStacks = [];
          props.eligibleProductionStacks = [];

          _this.get('stacks').forEach(function (stack) {
            if (stackHrefs[stack.get('links.self')]) {
              props.stacks.push(stack);
            }

            // NOTE: We don't use isEligibleForNewAccounts here because the whole
            // "inspecting relationships on objects" thing is very unclear in our
            // Ember version.
            if (stack.get('isV2')) {
              if (!stack.get('links.organization')) {
                props.eligibleDevelopmentStacks.push(stack);
              }

              if (stack.get('links.organization') === organizationHref) {
                props.eligibleProductionStacks.push(stack);
              }
            }
          });

          // All basic relationships are loaded, store those and load permissions
          _this.setProperties(props);
          return _ember['default'].RSVP.all(props.accounts.map(function (s) {
            return s.get('permissions');
          }));
        }).then(function (permissionsByAccount) {
          var permissions = [];

          permissionsByAccount.forEach(function (accountPermissions) {
            permissions = permissions.concat(accountPermissions);
          });

          _this.set('permissions', permissions);
          return permissions;
        }).then(function (_permissions) {
          // In this block, we are going to determine the list of accounts that are
          // eligible for compliance visibility.  This requires that the user fit one
          // of the following criteria:
          // - User is an account_owner
          // - User is a platforom_owner
          // - User has read or manage access to the account
          var accountsWithVisibility = undefined;

          var _getProperties2 = _this.getProperties('accounts', 'userIsEnclaveAdmin', 'userIsOrganizationAdmin');

          var accounts = _getProperties2.accounts;
          var userIsEnclaveAdmin = _getProperties2.userIsEnclaveAdmin;
          var userIsOrganizationAdmin = _getProperties2.userIsOrganizationAdmin;

          if (userIsEnclaveAdmin || userIsOrganizationAdmin) {
            // this user can see everything because they are an admin of some kind
            accountsWithVisibility = accounts;
          } else {
            // Need to check each account for permissions
            accountsWithVisibility = accounts.filter(function (account) {
              return _this.hasAccountScope('read', account);
            });
          }

          _this.set('accountsWithVisibility', accountsWithVisibility);
          resolve(_this);
        })['catch'](function (e) {
          return reject(e);
        });
      });
    },

    loadPlan: function loadPlan(organization, store) {
      var _this2 = this;

      store.query('activePlan', {
        organization_id: organization.id
      }).then(function (data) {
        return data.get('firstObject');
      }).then(function (activePlan) {
        return _this2.set('activePlan', activePlan);
      });
    },

    loadOrganizationProfile: function loadOrganizationProfile(organization, store) {
      if (_dieselConfigEnvironment['default'].featureFlags.skipGridiron) {
        return null;
      } else {
        return _dieselModelsOrganizationProfile['default'].findOrCreate(organization, store);
      }
    },

    loadOrganizationFeatures: function loadOrganizationFeatures(organizationProfile) {
      var _this3 = this;

      organizationProfile.then(function (orgProfile) {
        var enabledFeatures = {};
        // If you want to test enabling a GRC Program:
        // enabledFeatures['complyGrcProgram'] = true;

        (orgProfile.get('features') || []).forEach(function (feature) {
          enabledFeatures[feature.camelize()] = true;
        });
        _this3.setProperties({ enabledFeatures: enabledFeatures });
      });
    },
    notStarterPlan: _ember['default'].computed('activePlan', function () {
      var activePlan = this.get('activePlan');
      var currentPlan = activePlan ? activePlan.get('plan').content : null;

      // Currently checking based on current plan ID, starter == '5'.
      return currentPlan && currentPlan.id !== '5';
    }),
    isLegacyAccount: _ember['default'].computed('activePlan', function () {
      var activePlan = this.get('activePlan');

      // legacy accounts should not have an active plan
      return !activePlan;
    }),
    hasActivePlan: _ember['default'].computed('activePlan', function () {
      var activePlan = this.get('activePlan');
      return activePlan && activePlan.id;
    }),
    planHasSecurityDashboardAccess: _ember['default'].computed('activePlan', function () {
      var activePlan = this.get('activePlan');
      if (activePlan && activePlan.id) return this.activePlan.get('complianceDashboardAccess');
      return true;
    }),
    showSSO: _ember['default'].computed.or('isLegacyAccount', 'notStarterPlan'),
    developmentAccountsWithVisibility: _ember['default'].computed.filterBy('accountsWithVisibility', 'type', 'development'),
    productionAccountsWithVisibility: _ember['default'].computed.filterBy('accountsWithVisibility', 'type', 'production'),
    userHasEnvironmentAccess: _ember['default'].computed.gt('accountsWithVisibility.length', 0),
    showVisibilityTab: _ember['default'].computed.and('organizationHasVisibilityProduct', 'userHasEnvironmentAccess'),
    editableRoles: _ember['default'].computed('roles.@each.type', 'organizationHasGridironProduct', 'organizationHasEnclaveProduct', function () {
      var roles = this.get('roles');

      if (!this.get('organizationHasGridironProduct')) {
        roles = roles.rejectBy('isComplianceOnly');
      }

      if (!this.get('organizationHasEnclaveProduct')) {
        roles = roles.rejectBy('isPlatformOnly');
      }

      return roles;
    }),

    // Computed related to organization's roles
    organizationOwnerRoles: _ember['default'].computed.filterBy('roles', 'type', 'owner'),
    organizationPlatformUserRoles: _ember['default'].computed.filterBy('roles', 'type', 'platform_user'),
    organizationPlatformAdminRoles: _ember['default'].computed.filterBy('roles', 'type', 'platform_owner'),
    organizationComplianceUserRoles: _ember['default'].computed.filterBy('roles', 'type', 'compliance_user'),
    organizationComplianceAdminRoles: _ember['default'].computed.filterBy('roles', 'type', 'compliance_owner'),
    organizationHasGridironProduct: _ember['default'].computed.alias('enabledFeatures.gridiron'),

    // TODO: This will have to be a plan match once Enclave is separate from gridiron
    organizationHasEnclaveProduct: true,

    organizationHasVisibilityProduct: true,

    organizationRequiresDedicatedStack: _ember['default'].computed('accounts.@each.type', function () {
      return this.get("accounts").filterBy('type', 'production').length === 0;
    }),

    // Computeds related to user's roles in organization
    hasVerifiedEmail: _ember['default'].computed.reads('currentUser.verified'),
    userPlatformUserRoles: _ember['default'].computed.filterBy('currentUserRoles', 'type', 'platform_user'),
    userPlatformAdminRoles: _ember['default'].computed.filterBy('currentUserRoles', 'type', 'platform_owner'),
    userGridironUserRoles: _ember['default'].computed.filterBy('currentUserRoles', 'type', 'compliance_user'),
    userGridironAdminRoles: _ember['default'].computed.filterBy('currentUserRoles', 'type', 'compliance_owner'),
    userOrganizationAdminRoles: _ember['default'].computed.filterBy('currentUserRoles', 'type', 'owner'),
    userIsEnclaveUser: _ember['default'].computed.gt('userPlatformUserRoles.length', 0),
    userIsEnclaveAdmin: _ember['default'].computed.gt('userPlatformAdminRoles.length', 0),
    userIsGridironAdmin: _ember['default'].computed.gt('userGridironAdminRoles.length', 0),
    userIsOrganizationAdmin: _ember['default'].computed.gt('userOrganizationAdminRoles.length', 0),

    userCanCreateNewRoles: _ember['default'].computed.or('userIsOrganizationAdmin', 'userIsGridironAdmin', 'userIsEnclaveAdmin'),

    userIsEnclaveOrOrganizationAdmin: _ember['default'].computed.or('userIsEnclaveAdmin', 'userIsOrganizationAdmin'),
    userIsGridironOrOrganizationAdmin: _ember['default'].computed.or('userIsGridironAdmin', 'userIsOrganizationAdmin'),
    userHasEnclaveAccess: _ember['default'].computed.or('userIsEnclaveAdmin', 'userIsEnclaveUser', 'userIsOrganizationAdmin'),

    doesUserHavePrivilegedMembershipForRole: function doesUserHavePrivilegedMembershipForRole(role) {
      // This method is dumb and counts on memberhsips being loaded on the role
      // FIXME: Load memberships from users and cache them in this context
      var privilegedMemberships = role.get('memberships').filterBy('privileged', true);
      return !!privilegedMemberships.findBy('user.id', this.get('currentUser.id'));
    },

    hasAccountScope: function hasAccountScope(scope, account) {
      // Scopes: read, manage
      if (scope === 'admin' && !this.get('hasVerifiedEmail')) {
        return false;
      }

      return this.get('currentUserRoles').any(function (role) {
        return account.permitsRole(role, scope);
      });
    },

    hasOrganizationScope: function hasOrganizationScope(scope) {
      // Scopes: read, manage
      var supportedScopes = ['read', 'manage', 'enclave/manage'];
      _ember['default'].assert('hasOrganizationScope supports: ' + supportedScopes + ' (passed: ' + scope + ')', supportedScopes.includes(scope));

      if (scope === 'read') {
        return true;
      }

      if (scope === 'enclave/manage') {
        if (!this.get('hasVerifiedEmail')) {
          return false;
        }

        return this.get('userIsEnclaveOrOrganizationAdmin');
      }

      return this.get('userIsOrganizationAdmin');
    },

    hasGridironScope: function hasGridironScope(scope) {
      // Scopes: read, manage
      // Eventually will have scope for each gridiron product
      if (scope === 'read') {
        return true;
      }

      if (!this.get('hasVerifiedEmail')) {
        return false;
      }

      if (this.get('userIsGridironOrOrganizationAdmin')) {
        // Admin has all scopes
        return true;
      }

      return false;
    },

    hasRoleScope: function hasRoleScope(scope, role) {
      // Scopes: read, manage, invite
      if (scope === 'read') {
        return true;
      }

      if (!this.get('hasVerifiedEmail')) {
        return false;
      }

      if (this.get('userIsOrganizationAdmin')) {
        return true;
      }

      if (role.get('isPlatform') && this.get('userIsEnclaveAdmin')) {
        return true;
      }

      if (role.get('isComplianceRole') && this.get('userIsGridironAdmin')) {
        return true;
      }

      if (scope === 'invite' && this.doesUserHavePrivilegedMembershipForRole(role)) {
        return true;
      }

      return false;
    }
  });
});