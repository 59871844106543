define('diesel/utils/storage', ['exports'], function (exports) {
  exports.write = write;
  exports.read = read;
  exports.remove = remove;
  exports.clear = clear;
  var serializedPrefix = '_object:';

  var NativeAdapter = function NativeAdapter() {
    this.getItem = function (key) {
      return window.localStorage.getItem(key);
    };
    this.setItem = function (key, val) {
      return window.localStorage.setItem(key, val);
    };
    this.removeItem = function (val) {
      return window.localStorage.removeItem(val);
    };
    this.clear = function () {
      return window.localStorage.clear();
    };
  };

  var MemoryAdapter = function MemoryAdapter() {
    var storage = {};
    this.getItem = function (key) {
      return storage[key];
    };
    this.setItem = function (key, val) {
      return storage[key] = val;
    };
    this.removeItem = function (val) {
      return delete storage[val];
    };
    this.clear = function () {
      storage = {};
    };
  };

  function checkLocalStorage() {
    try {
      window.localStorage.setItem('test', true);
      window.localStorage.removeItem('test');
      return true;
    } catch (e) {
      return false;
    }
  }

  var adapter = undefined;
  if (checkLocalStorage()) {
    adapter = new NativeAdapter();
  } else {
    adapter = new MemoryAdapter();
  }

  function write(key, value) {
    var serialized;
    if (typeof value === 'object') {
      serialized = serializedPrefix + JSON.stringify(value);
    } else {
      serialized = value;
    }
    return adapter.setItem(key, serialized);
  }

  function read(key) {
    var serialized = adapter.getItem(key);
    var value;
    if (typeof serialized === 'string' && serialized.indexOf(serializedPrefix) === 0) {
      value = JSON.parse(serialized.slice(8));
    } else {
      value = serialized;
    }
    return value;
  }

  function remove(key) {
    return adapter.removeItem(key);
  }

  function clear() {
    return adapter.clear();
  }

  var api = { read: read, write: write, remove: remove, clear: clear };

  exports['default'] = api;
});