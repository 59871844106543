define('diesel/components/vendor-certification-item/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['agreement-owner-agreement-item', 'panel', 'component-profile', 'component-profile--vendor-certification'],
    isVisible: _ember['default'].computed.not('certification.isNew'),
    actions: {
      editCertification: function editCertification(certification) {
        this.sendAction('openCertificationModal', certification);
      },
      deleteCertification: function deleteCertification(certification) {
        this.sendAction('removeCertification', certification);
      }
    }
  });
});