define('diesel/utils/changeset', ['exports', 'ember'], function (exports, _ember) {

  var StagedObject = _ember['default'].Object.extend(_ember['default'].Evented, {

    initialValue: function initialValue() {
      if (this._initialValue === undefined) {
        this._initialValue = this.changeset.initialValue(this.keyData);
      }
      return this._initialValue;
    },

    value: function value() {
      return this._currentValue;
    },

    setValue: function setValue(value) {
      this._currentValue = value;
      this.trigger('didChange');
      this.changeset.trigger('didChangeStagedObject', this.keyData);
    }

  });

  exports['default'] = _ember['default'].Object.extend(_ember['default'].Evented, {

    init: function init() {
      this._stagedObjects = Object.create(null);
      _ember['default'].assert('must define `key` method', typeof this.key === 'function');
      _ember['default'].assert('must define `initialValue` method', typeof this.initialValue === 'function');
    },

    _lookupStagedObject: function _lookupStagedObject(keyData) {
      var key = this.key(keyData);
      var stagedObject = this._stagedObjects[key];
      if (!stagedObject) {
        stagedObject = StagedObject.create({
          changeset: this,
          keyData: keyData
        });
        this._stagedObjects[key] = stagedObject;
      }
      return stagedObject;
    },

    value: function value(keyData) {
      var stagedObject = this._lookupStagedObject(keyData);
      var value = stagedObject.value();
      if (value === undefined) {
        stagedObject.setValue(stagedObject.initialValue());
      }
      return stagedObject.value();
    },

    setValue: function setValue(keyData, value) {
      var stagedObject = this._lookupStagedObject(keyData);
      return stagedObject.setValue(value);
    },

    subscribe: function subscribe(keyData, callback) {
      var stagedObject = this._lookupStagedObject(keyData);
      stagedObject.on('didChange', callback);
    },

    subscribeAll: function subscribeAll(callback) {
      this.on('didChangeStagedObject', callback);
    },

    forEachValue: function forEachValue(callback) {
      var _this = this;

      var keys = Object.keys(this._stagedObjects);
      keys.forEach(function (key) {
        var stagedObject = _this._stagedObjects[key];
        callback(stagedObject.keyData, stagedObject.initialValue(), stagedObject.value());
      });
    }
  });
});