define('diesel/utils/filter-risk-components', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = filterComponents;

  function filterComponents(components) {
    var filters = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

    var searchTerms = _ember['default'].$.trim(filters.search);

    if (filters.category) {
      components = components.filter(function (c) {
        return c.get('category.id') === filters.category.get('id');
      });
    }

    if (typeof filters.adversarial === 'boolean') {
      components = components.filterBy('adversarial', filters.adversarial);
    }

    if (filters.pervasiveness || filters.pervasiveness === 0) {
      components = components.filterBy('pervasiveness', filters.pervasiveness);
    }

    if (filters.relevance || filters.relevance === 0) {
      components = components.filterBy('relevance', filters.relevance);
    }

    if (typeof filters.relevant !== 'undefined' && filters.relevant !== null) {
      components = components.filterBy('relevant', filters.relevant);
    }

    if (filters.riskLevel || filters.riskLevel === 0) {
      components = components.filterBy('riskLevel', filters.riskLevel);
    }

    if (filters.minimumRiskLevel || filters.minimumRiskLevel === 0) {
      components = components.filter(function (component) {
        return component.get('riskLevel') >= filters.minimumRiskLevel;
      });
    }

    if (filters.impact || filters.impact === 0) {
      components = components.filterBy('impact', filters.impact);
    }

    if (typeof filters.implemented !== 'undefined' && filters.implemented !== null) {
      if (typeof filters.implemented === 'string') {
        filters.implemented = filters.implemented === 'true';
      }
      components = components.filterBy('implemented', filters.implemented);
    }

    if (filters.severity || filters.severity === 0) {
      components = components.filterBy('severity', filters.severity);
    }

    if (filters.status) {
      components = components.filterBy('status', filters.status);
    }

    if (searchTerms) {
      searchTerms = new RegExp(searchTerms.toLowerCase().replace(/\W/g, ''));

      components = components.filter(function (pc) {
        var _pc$getProperties = pc.getProperties('title', 'description');

        var title = _pc$getProperties.title;
        var description = _pc$getProperties.description;

        var keywords = (title + description).toLowerCase().replace(/\W/g, '');

        return searchTerms.test(keywords);
      });
    }

    if (filters.sort) {
      components = components.slice(0).sort(function (a, b) {
        a = a.get(filters.sort);
        b = b.get(filters.sort);

        if (filters.sort === 'title') {
          if (a === b) {
            return 0;
          }
          if (a < b) {
            return -1;
          }
          return 1;
        } else {
          // everything else is an number
          a = a || 0;
          b = b || 0;

          return Math.round(parseFloat(b, 10)) - Math.round(parseFloat(a, 10));
        }
      });
    }

    return components;
  }
});