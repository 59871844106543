define('diesel/log-drains/route', ['exports', 'ember'], function (exports, _ember) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  exports['default'] = _ember['default'].Route.extend({
    modalDisplay: _ember['default'].inject.service(),

    title: function title() {
      var account = this.modelFor('account');
      return account.get('handle') + ' Log Drains';
    },

    model: function model() {
      var account = this.modelFor('account');

      return _ember['default'].RSVP.hash({
        logDrains: account.get('logDrains'),
        databases: account.get('databases')
      });
    },

    setupController: function setupController(controller, model) {
      var account = this.modelFor('account');
      var esDatabases = model.databases.filterBy('type', 'elasticsearch').filter(function (d) {
        return d.get('connectionUrl');
      });
      controller.setProperties({ account: account, model: model.logDrains, esDatabases: esDatabases });
    },

    actions: {
      openCreateLogDrainModal: function openCreateLogDrainModal() {
        var _modalDisplay$showModal;

        var account = this.modelFor('account');
        var drainAttr = account.get('isV2') ? 'newLogDrainV2' : 'newLogDrainV1';

        var esDatabases = this.controller.get('esDatabases');

        var modalDisplay = this.get('modalDisplay');
        var modalName = 'modal-create-log-drain-' + (account.get('isV2') ? 'v2' : 'v1');
        modalDisplay.showModal(modalName, (_modalDisplay$showModal = {}, _defineProperty(_modalDisplay$showModal, drainAttr, account.createLogDrain()), _defineProperty(_modalDisplay$showModal, 'account', account), _defineProperty(_modalDisplay$showModal, 'esDatabases', esDatabases), _modalDisplay$showModal), {
          actions: {
            onCreateLogDrain: modalDisplay.modalAction(this, 'onCreateLogDrain')
          }
        });
      },

      onCreateLogDrain: function onCreateLogDrain(logDrain) {
        var _this = this;

        return logDrain.save().then(function () {
          return _this.get('operationsManager').createBackgroundOperation(logDrain, { type: 'configure' });
        }, function (e) {
          var defaultMessage = 'There was an error creating new log drain.';
          var message = _ember['default'].getWithDefault(e, 'message', defaultMessage);
          _ember['default'].get(_this, 'flashMessages').danger(message);
          return e; // keep modal from closing
        });
      }
    }
  });
});