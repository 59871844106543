define('diesel/components/modal-create-incident/component', ['exports', 'ember', 'diesel/mixins/components/modal-base'], function (exports, _ember, _dieselMixinsComponentsModalBase) {
  exports['default'] = _ember['default'].Component.extend(_dieselMixinsComponentsModalBase['default'], {
    // Inputs
    newIncident: null,

    disableSave: _ember['default'].computed.or('hasError', 'isPending'),
    isPending: _ember['default'].computed.alias('newIncident.isSaving'),
    hasError: _ember['default'].computed.gt('errors.newIncident.title.length', 0),

    severityItems: _ember['default'].computed('newIncident.severityItems', function () {
      // This is necessary to prevent a possible bug where the `items` property
      // passed to `{{select-pills}}` is undefined and the internals of {{select-pills}}
      // throws an error. There is an acceptance test to cover this.
      return this.get('newIncident.severityItems') || [];
    }),

    title: 'Open a New Incident',

    actions: {
      beforeDismiss: function beforeDismiss() {
        this.get('newIncident').rollbackAttributes();
      },

      createIncident: function createIncident() {
        this.sendAction('onCreateIncident', this.get('newIncident'));
      }
    }
  });
});