define('diesel/training/courses/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    title: function title() {
      var organization = this.get('complianceStatus.organization');
      return 'Enrolled Courses - Gridiron Admin - ' + organization.get('name');
    },

    model: function model() {
      var organizationProfile = this.get('complianceStatus.organizationProfile');
      return organizationProfile.get('courses');
    },

    setupController: function setupController(controller, model) {
      var organizationProfile = this.get('complianceStatus.organizationProfile');

      var _get$getProperties = this.get('complianceStatus.authorizationContext').getProperties('roles', 'users');

      var roles = _get$getProperties.roles;
      var users = _get$getProperties.users;

      controller.setProperties({ courses: model, organizationProfile: organizationProfile, roles: roles, users: users });
    }
  });
});