define('diesel/components/operation-item/component', ['exports', 'ember', 'diesel/utils/location', 'diesel/utils/time-ago-in-words'], function (exports, _ember, _dieselUtilsLocation, _dieselUtilsTimeAgoInWords) {

  function cap(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  exports['default'] = _ember['default'].Component.extend({
    session: _ember['default'].inject.service('session'),
    classNameBindings: [':operation-container'],
    operationLogs: _ember['default'].computed('operation.status', 'operation.links', function () {
      var status = this.get('operation.status');
      if (status !== 'succeeded' && status !== 'failed') {
        return '';
      }

      var links = this.get('operation.links');
      if (links.logs) {
        return links.logs;
      }

      return '';
    }),
    operationAction: _ember['default'].computed('operation.type', 'operation.status', function () {
      var type = this.get('operation.type');
      var status = this.get('operation.status');

      var builder = '';
      switch (type) {
        case 'renew':
          builder += 'Renew';
          break;
        case 'replicate':
          builder += 'Replicate';
          break;
        case 'replicate_logical':
          builder += 'Replicate (logical)';
          break;
        case 'recover':
          builder += 'Recovered';
          break;
        case 'modify':
          builder += 'Modified';
          break;
        case 'execute':
          builder += 'SSH Session';
          break;
        case 'backup':
          builder += 'Backup saved';
          break;
        case 'logs':
          builder += 'Viewed logs';
          break;
        case 'ps':
          builder += 'Listed running processes';
          break;
        case 'captain_comeback_restart':
          builder += 'Restart (simulate-oom)';
          break;
        case 'configure':
          builder += 'Configured app';
          break;
        default:
          builder += cap(type) + ' ' + status;
          break;
      }

      return builder;
    }),
    scaleInfo: _ember['default'].computed('operation.type', 'operation.instance_profile', 'operation.disk_size', 'operation.container_size', 'operation.container_count', function () {
      var type = this.get('operation.type');
      var profile = this.get('operation.instanceProfile');
      var diskSize = this.get('operation.diskSize');
      var containerSize = this.get('operation.containerSize');
      var containerCount = this.get('operation.containerCount');
      if (type !== 'scale' && type !== 'restart') return '';

      var info = [];
      if (profile) {
        info.push('Profile: ' + profile);
      }

      if (diskSize > 0) {
        info.push('Disk Size: ' + diskSize);
      }

      if (containerSize > 0) {
        info.push('Container Size: ' + containerSize + 'MB');
      }

      if (containerCount > 0) {
        info.push('Container Count: ' + containerCount);
      }

      return info.join(', ');
    }),
    operationTooltip: _ember['default'].computed('operation.type', 'operation.env', function () {
      var type = this.get('operation.type');
      var env = this.get('operation.env');
      if (type !== 'configure') {
        return '';
      }

      var keys = [];
      if (env) {
        keys = Object.keys(env);
      }
      return keys.join(', ');
    }),
    duration: _ember['default'].computed('operation.created_at', 'operation.updated_at', 'operation.status', function () {
      var status = this.get('operation.status');
      var fromDate = this.get('operation.updatedAt');
      if (status === 'running') {
        fromDate = new Date();
      }

      var toDate = this.get('operation.createdAt');
      return (0, _dieselUtilsTimeAgoInWords.timeAgoInWords)(toDate, fromDate);
    }),
    actions: {
      download: function download(href) {
        var _this = this;

        this.set('errors', null);
        var opts = {
          headers: {
            'Authorization': 'Bearer ' + this.get("session.token.accessToken")
          }
        };
        _ember['default'].$.ajax(href, opts).then(function (data) {
          _dieselUtilsLocation['default'].replace(data);
        })['catch'](function () {
          var msg = 'Unable to retrieve the operation\'s logs. If the issue persists please contact support for assistance.';
          _this.set('errors', msg);
        });
      }
    }
  });
});