define('diesel/initializers/current-organization', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(application) {
    application.inject('service:current-organization', 'organizationRoute', 'route:organization');
  }

  exports['default'] = {
    name: 'current-organization',
    initialize: initialize
  };
});