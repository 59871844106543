define('diesel/models/invitation', ['exports', 'ember-data', 'diesel/models/base'], function (exports, _emberData, _dieselModelsBase) {
  exports['default'] = _dieselModelsBase['default'].extend({
    email: _emberData['default'].attr('string'),
    createdAt: _emberData['default'].attr('date'),
    updatedAt: _emberData['default'].attr('date'),
    organizationName: _emberData['default'].attr(),
    inviterName: _emberData['default'].attr(),

    role: _emberData['default'].belongsTo('role', { async: true }),

    // This relationship is necessary to populate the
    // inverse relationship organization#hasMany('invitations'),
    // so that creating a new invitation for an organization
    // causes it to show up in the UI.
    // This relationship is not surfaced directly in the Auth API
    organization: _emberData['default'].belongsTo('organization', { async: true })
  });
});