define('diesel/components/permission-toggle/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    busy: false,
    className: 'account-permission-toggle',
    hasPermissions: _ember['default'].computed.gt('permissions.length', 0),
    permission: null,
    store: _ember['default'].inject.service(),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      _ember['default'].run.later(function () {
        return _this.set('initialized', true);
      });
    },

    isChecked: _ember['default'].computed('account.permissions.[]', function () {
      return this.get('account').hasRoleScope(this.get('role'), this.get('scope')) || this.get('isDisabled');
    }),

    isDisabled: _ember['default'].computed('account.permissions.[]', function () {
      var currentScope = this.get('scope');
      var adminValue = this.get('account').hasRoleScope(this.get('role'), 'admin');

      var deployValue = this.get('account').hasRoleScope(this.get('role'), 'deploy');
      var destoyValue = this.get('account').hasRoleScope(this.get('role'), 'destroy');
      var opsValue = this.get('account').hasRoleScope(this.get('role'), 'observability');
      var sensValue = this.get('account').hasRoleScope(this.get('role'), 'sensitive');
      var tunnelValue = this.get('account').hasRoleScope(this.get('role'), 'tunnel');
      var readValue = this.get('account').hasRoleScope(this.get('role'), 'read');

      // If any other scope is selected, basic_read is disabled   
      if (currentScope === 'basic_read') {
        if (readValue || deployValue || destoyValue || opsValue || sensValue || tunnelValue) {
          return true;
        }
      }

      // returns the admin value, except for the admin scope. If selected, disable all other roles and if not selected enable all other roles.
      if (currentScope === 'admin') {
        return false;
      } else {
        return adminValue;
      }
    }),

    roleIsOwner: _ember['default'].computed.or('role.isAccountOwner', 'role.isPlatformOwner'),
    ownerTooltipTitle: _ember['default'].computed('role.name', 'account.handle', function () {
      return this.get('role.name') + ' is granted all permissions for ' + this.get('account.handle');
    }),
    userIsAuthorized: _ember['default'].computed.reads('authorizationContext.userIsEnclaveOrOrganizationAdmin'),
    userIsUnauthorized: _ember['default'].computed.not('userIsAuthorized'),

    actions: {
      onToggle: function onToggle(valueOptions) {
        var _this2 = this;

        if (!this.get('initialized')) {
          return;
        }
        var isOn = !!valueOptions.newValue;

        var _getProperties = this.getProperties('role', 'scope', 'account');

        var role = _getProperties.role;
        var scope = _getProperties.scope;
        var account = _getProperties.account;

        var permission = account.findPermission(role, scope);

        // NOTE: No error occurs if a permission with this role and scope already
        // exist for this account. Including this for an existing permission to
        // avoid a duplicate.
        if (isOn && permission !== undefined) {
          return;
        }

        this.set('busy', true);
        if (isOn) {
          this.get('store').createRecord('permission', {
            account: account,
            scope: scope,
            role: role
          }).save().then(function () {
            _this2.set('busy', false);
          });
        } else {
          permission.destroyRecord().then(function () {
            _this2.set('busy', false);
          });
        }

        return true;
      }
    }
  });
});