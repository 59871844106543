define('diesel/utils/aggregate-customer-controls', ['exports', 'ember', 'diesel/utils/implementation-metrics'], function (exports, _ember, _dieselUtilsImplementationMetrics) {
  exports.createMainControlAggregateFromGlobalControls = createMainControlAggregateFromGlobalControls;

  var GlobalControlAggregate = _ember['default'].Object.extend(_ember['default'].Evented, {
    id: null,
    globalControl: null,
    customerControls: null,
    cumulativeMetrics: null,

    calculateCustomerControlMetrics: function calculateCustomerControlMetrics(cc) {
      var metrics = this.get('cumulativeMetrics');

      var _cc$get = cc.get('metrics');

      var totalInScope = _cc$get.totalInScope;
      var totalCompliant = _cc$get.totalCompliant;
      var totalIgnored = _cc$get.totalIgnored;
      var resourceType = _cc$get.resourceType;
      var lastEvaluation = _cc$get.lastEvaluation;

      metrics.incrementProperty('totalInScope', totalInScope);

      if (!cc.get('isDisabled')) {
        metrics.incrementProperty('totalCompliant', totalCompliant);
      }

      metrics.incrementProperty('ignored', totalIgnored);

      if (lastEvaluation > metrics.get('lastEvaluation')) {
        metrics.set('lastEvaluation', lastEvaluation);
      }
      if (resourceType && metrics.get('isGenericResourceType')) {
        metrics.set('resourceType', resourceType);
      }
    },

    recalculate: function recalculate() {
      var metrics = this.get('cumulativeMetrics');
      metrics.resetValues();
      if (this.get('globalControl.isOrganizationScoped')) {
        // Woah there. This is an organizational scoped control, which means we should
        // NOT iterate all associated customer controls.  Instead, just grab the first.
        if (this.get('customerControls.length')) {
          this.calculateCustomerControlMetrics(this.get('customerControls.firstObject'));
        }
      } else {
        this.get('customerControls').forEach(this.calculateCustomerControlMetrics.bind(this));
      }

      this.trigger('metricsRecalculated');
    },

    addCustomerControl: function addCustomerControl(cc) {
      this.get('customerControls').addObject(cc);
    },

    beginListeningForMetricsChanges: function beginListeningForMetricsChanges() {
      var _this = this;

      this.get('customerControls').forEach(function (cc) {
        cc.on('metricsRecalculated', _this.recalculate.bind(_this));
      });
    }
  });

  var LOGO_IMG_PATH = '/assets/images/frameworks';

  var FrameworkMetric = _ember['default'].Object.extend(_ember['default'].Evented, {
    controlCount: 0,
    resourcesCount: 0,
    totalControlResourcesCount: 0,
    compliantControlResourcesCount: 0,
    handle: '',
    name: null,
    description: null,
    logo: null,

    fullLogoPath: _ember['default'].computed('logo', function () {
      return LOGO_IMG_PATH + '/' + this.get('logo');
    }),

    progressPercentage: _ember['default'].computed('compliantControlResourcesCount', 'totalControlResourcesCount', function () {
      var _getProperties = this.getProperties('compliantControlResourcesCount', 'totalControlResourcesCount');

      var compliantControlResourcesCount = _getProperties.compliantControlResourcesCount;
      var totalControlResourcesCount = _getProperties.totalControlResourcesCount;

      return totalControlResourcesCount > 0 ? Math.round(compliantControlResourcesCount / totalControlResourcesCount * 100) : 0;
    }),

    isComplete: _ember['default'].computed.equal('progressPercentage', 100),
    styleVariant: _ember['default'].computed('progressPercentage', function () {
      var progressPercentage = this.get('progressPercentage');
      if (progressPercentage === 100) return 'success';
      if (progressPercentage === 0) return 'danger';
      return 'warn';
    }),
    resetValues: function resetValues() {
      this.setProperties({
        controlCount: 0,
        resourcesCount: 0,
        totalControlResourcesCount: 0,
        compliantControlResourcesCount: 0
      });
    }
  });

  var MainControlAggregate = _ember['default'].Object.extend(_ember['default'].Evented, {
    aggregatedControls: null,
    frameworks: {
      'all': FrameworkMetric.create({
        name: 'All Controls',
        handle: 'all',
        description: 'The full list of security and availability controls provided by Aptible'
      }),
      'hipaa-required': FrameworkMetric.create({
        name: 'HIPAA Required Controls',
        logo: 'hipaa.png',
        handle: 'hipaa-required',
        description: 'The controls that satisfy the "Required" requirements of the HIPAA Security Rule'
      }),
      'hipaa-addressable': FrameworkMetric.create({
        name: 'HIPAA Addressable Controls',
        logo: 'hipaa.png',
        handle: 'hipaa-addressable',
        description: 'The controls that satisfy the "Addressable" requirements of the HIPAA Security Rule'
      }),
      'hitrust-inheritable': FrameworkMetric.create({
        name: 'HITRUST Inheritable Controls',
        logo: 'hitrust.png',
        handle: 'hitrust-inheritable',
        description: 'The controls inheritable under Aptible\'s HITRUST CSF Validated Assessment'
      })
    },

    categories: _ember['default'].computed('aggregatedControls.@each.globalControl.area', function () {
      return this.get('aggregatedControls').mapBy('globalControl.area').uniq();
    }),

    init: function init() {
      this._super.apply(this, arguments);
    },

    recalculateFrameworkMetrics: function recalculateFrameworkMetrics() {
      var frameworks = this.get('frameworks');
      Object.keys(frameworks).forEach(function (h) {
        return frameworks[h].resetValues();
      });
      this.get('aggregatedControls').forEach(function (ag) {
        var _ag$get$getProperties = ag.get('cumulativeMetrics').getProperties('totalInScope', 'totalCompliant');

        var totalInScope = _ag$get$getProperties.totalInScope;
        var totalCompliant = _ag$get$getProperties.totalCompliant;

        frameworks.all.incrementProperty('controlCount', 1);
        frameworks.all.incrementProperty('totalControlResourcesCount', totalInScope);
        frameworks.all.incrementProperty('compliantControlResourcesCount', totalCompliant);

        ag.get('globalControl.globalControlFrameworks').forEach(function (gcf) {
          var handle = gcf.get('metricHandle');

          frameworks[handle].incrementProperty('controlCount', 1);
          frameworks[handle].incrementProperty('totalControlResourcesCount', totalInScope);
          frameworks[handle].incrementProperty('compliantControlResourcesCount', totalCompliant);
        });
      });
    },

    ingestNewCustomerControlSet: function ingestNewCustomerControlSet(customerControls) {
      var _this2 = this;

      var aggregatedControls = {};
      this.get('aggregatedControls').forEach(function (ag) {
        aggregatedControls[ag.get('id')] = ag;
      });
      customerControls.forEach(function (cc) {
        var gcid = cc.get('globalControl.id');
        var aggregate = aggregatedControls[gcid];
        aggregate.addCustomerControl(cc);
        aggregate.calculateCustomerControlMetrics(cc);
      });

      _ember['default'].run.later(function () {
        _this2.beginListeningForMetricsChanges();
      });
    },

    beginListeningForMetricsChanges: function beginListeningForMetricsChanges() {
      var _this3 = this;

      this.get('aggregatedControls').forEach(function (ag) {
        ag.beginListeningForMetricsChanges();
        ag.on('metricsRecalculated', _this3.recalculateFrameworkMetrics.bind(_this3));
        ag.recalculate();
      });

      this.recalculateFrameworkMetrics();
    }
  });

  function createMainControlAggregateFromGlobalControls(globalControls) {
    var aggregatedControls = {};
    globalControls.forEach(function (gc) {
      var gcid = gc.get('id');
      aggregatedControls[gcid] = GlobalControlAggregate.create({
        id: gcid,
        globalControl: globalControls.findBy('id', gcid),
        customerControls: _ember['default'].A([]),
        cumulativeMetrics: _dieselUtilsImplementationMetrics['default'].create()
      });
    });

    return MainControlAggregate.create({
      aggregatedControls: _ember['default'].A(Object.keys(aggregatedControls).map(function (gcid) {
        return aggregatedControls[gcid];
      }))
    });
  }
});