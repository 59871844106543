define("diesel/controls/controller", ["exports", "ember", "diesel/mixins/controllers/filterable-controls", "diesel/utils/storage"], function (exports, _ember, _dieselMixinsControllersFilterableControls, _dieselUtilsStorage) {

  var COLLAPSE_STATE_KEY = 'sidebar-collapsed';

  exports["default"] = _ember["default"].Controller.extend(_dieselMixinsControllersFilterableControls["default"], {
    isLoading: true,
    showOrgSwitcher: _ember["default"].computed.or('authorization.hasManyOrganizations', 'authorization.hasInaccessibleOrganizations'),
    setup: _ember["default"].on('init', function () {
      this._super.apply(this, arguments);
      var currentState = (0, _dieselUtilsStorage.read)(COLLAPSE_STATE_KEY);

      if (!currentState) {
        (0, _dieselUtilsStorage.write)(COLLAPSE_STATE_KEY, 'EXPANDED');
      }

      this.set('isSidebarCollapsed', currentState === 'COLLAPSED' ? true : false);
    }),

    actions: {
      onFrameworkSelect: function onFrameworkSelect(framework) {
        this.set('framework', framework);
      },

      onCategorySelect: function onCategorySelect(category) {
        this.set('category', category);
      },

      onSortSelect: function onSortSelect(sort) {
        this.set('sort', sort);
      },

      onScopeSelect: function onScopeSelect(scope) {
        this.set('scope', scope);
        this.set('isLoading', true);
      },

      onSelectAggregate: function onSelectAggregate(type) {},

      onToggleCollapse: function onToggleCollapse(state) {
        if (state === "expand") {
          this.set('isSidebarCollapsed', false);
          (0, _dieselUtilsStorage.write)(COLLAPSE_STATE_KEY, 'EXPANDED');
        }
        if (state === "collapse") {
          this.set('isSidebarCollapsed', true);
          (0, _dieselUtilsStorage.write)(COLLAPSE_STATE_KEY, 'COLLAPSED');
        }
      }
    }
  });
});