define('diesel/components/vulnerability-filters/component', ['exports', 'ember', 'diesel/utils/six-point-scale'], function (exports, _ember, _dieselUtilsSixPointScale) {

  var SORT = [{ title: 'Most Severe', value: 'severity' }, { title: 'Applied Security Controls', value: 'securityControls.length' }, { title: 'Title', value: 'title' }];

  exports['default'] = _ember['default'].Component.extend({
    filters: { search: '', sort: SORT[0], severity: null },

    classNames: ['risk-assessment__filters form-inline'],
    sorts: SORT,
    severities: _dieselUtilsSixPointScale['default'].slice(1),
    showClear: _ember['default'].computed('filters.search', 'filters.severity', function () {
      var currentSearch = this.get('filters.search') || '';
      var severity = this.get('filters.severity');

      return _ember['default'].$.trim(currentSearch) !== '' || severity && severity !== '';
    }),

    actions: {
      clear: function clear() {
        this.set('filters.sort', 'severity');
        this.set('filters.severity', null);
        this.set('filters.search', '');
      },

      update: function update() {
        var sort = this.$('select.sort').val();
        var severity = parseInt(this.$('select.severity').val(), 10);

        this.set('filters.sort', sort);
        this.set('filters.severity', severity);
      }
    }
  });
});