define('diesel/organization/roles/index/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.modelFor('organization');
    },

    redirect: function redirect(model) {
      // FIXME: app/organization/roles/type/route.js does its own redirects, with
      // different conditions. This has the potential for creating redirect loops.
      if (model.get('userHasEnclaveAccess')) {
        this.transitionTo('organization.roles.type', 'platform');
      } else {
        this.transitionTo('organization.roles.type', 'compliance');
      }
    }
  });
});