define('diesel/components/database-service-overview-panel/component', ['exports', 'ember', 'diesel/mixins/components/service-scaling'], function (exports, _ember, _dieselMixinsComponentsServiceScaling) {
  exports['default'] = _ember['default'].Component.extend({
    service: null,
    database: null,
    classNames: ['c-panel', 'service-overview-panel'],
    estimatedCostInDollars: _ember['default'].computed('service.estimatedMonthlyCostInCents', 'database.disk.estimatedMonthlyCostInCents', function () {
      var serviceCost = this.get('service.estimatedMonthlyCostInCents') || 0;
      var diskCost = this.get('database.disk.estimatedMonthlyCostInCents') || 0;
      return (0, _dieselMixinsComponentsServiceScaling.centsToDollars)(serviceCost + diskCost);
    })
  });
});