define("diesel/organization/admin/billing/plan/controller", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Controller.extend({
    org: null,
    planList: null,
    activePlanList: null,
    paymentMethod: null,
    actions: {
      refreshModel: function refreshModel() {
        // TODO - find a real way to navigate
        window.location.reload(true);
      }
    }
  });
});