define("diesel/components/modal-org-picker/component", ["exports", "ember", "diesel/mixins/components/modal-base"], function (exports, _ember, _dieselMixinsComponentsModalBase) {
  exports["default"] = _ember["default"].Component.extend(_dieselMixinsComponentsModalBase["default"], {
    // Inputs
    authorization: null,

    title: "Select an organization",
    description: "",

    actions: {
      selectOrg: function selectOrg(organization) {
        this.sendAction("onOrgPicked", organization);
      },
      reauthenticate: function reauthenticate(organization) {
        this.sendAction("onReauthenticate", organization);
      }
    }
  });
});