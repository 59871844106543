define('diesel/components/log-drain-actions/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),

    logDrain: null,

    sendError: function sendError(e, message) {
      message = e.message || message;
      this.sendAction('failedAction', message);
    },

    actions: {
      restart: function restart() {
        var message = 'Restarting ' + this.logDrain.get('handle') + '...';
        var errorMessage = 'There was an error restarting ' + this.logDrain.get('handle') + '.';
        var component = this;
        var op = this.get('store').createRecord('operation', {
          type: 'configure',
          logDrain: this.logDrain
        });
        op.save().then(function () {
          component.logDrain.set('status', 'provisioning');
          component.logDrain.save().then(function () {
            component.sendAction('completedAction', message);
          })['catch'](function (e) {
            component.sendError(e, errorMessage);
          });
        })['catch'](function (e) {
          component.sendError(e, errorMessage);
        });
      },

      deprovision: function deprovision() {
        // Confirm...
        var confirmMsg = '\nDeprovisioning will remove ' + this.logDrain.get('handle') + ' and CANNOT be undone.\n\n';
        confirmMsg += 'Are you sure you want to continue?\n';
        if (!confirm(confirmMsg)) {
          return false;
        }

        var message = 'Deprovisioning ' + this.logDrain.get('handle') + '...';
        var errorMessage = 'There was an error deprovisioning ' + this.logDrain.get('handle') + '.';
        var component = this;
        var logDrain = this.logDrain; // Keep a ref for the run later
        var op = this.get('store').createRecord('operation', {
          type: 'deprovision',
          logDrain: logDrain
        });
        op.save().then(function () {
          component.sendAction('completedAction', message);
          logDrain.set('status', 'deprovisioning');
        })['catch'](function (e) {
          component.sendError(e, errorMessage);
        });
      }
    }
  });
});