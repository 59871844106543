define('diesel/components/c3-chart/component', ['exports', 'ember', 'c3', 'diesel/config/environment'], function (exports, _ember, _c3, _dieselConfigEnvironment) {

  function withObservedChartAttribute(attrName, handler) {
    var lastExecution = 0;
    var nextExecution = 0;

    return _ember['default'].on('init', _ember['default'].observer(attrName, function () {
      var _this = this;

      nextExecution++;
      var thisExecution = nextExecution;

      _ember['default'].RSVP.resolve(this.get(attrName)).then(function (attrValue) {
        if (lastExecution > thisExecution) {
          return;
        }
        lastExecution = thisExecution;

        if (_ember['default'].isEmpty(attrValue)) {
          return;
        }

        return handler.bind(_this, attrValue)();
      });
    }));
  }

  exports['default'] = _ember['default'].Component.extend({
    tagName: 'div',
    classNames: ['c3-chart-component'],

    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);

      // Create promises that'll create the chart
      ["Data", "Axis", "Grid", "Element"].forEach(function (attr) {
        _this2.set('_waitFor' + attr, new _ember['default'].RSVP.Promise(function (resolve) {
          _this2.set('_submitInitial' + attr, function (x) {
            // Store the value and resolve the promise, but don't resolve the promise:
            // the value must be looked up via the stored values (because they can change
            // if another property takes a while to show up).
            _this2.set('_valueFor' + attr, x);
            resolve();
          });
        }));
      });

      _ember['default'].RSVP.hash({
        data: this.get('_waitForData'),
        axis: this.get('_waitForAxis'),
        grid: this.get('_waitForGrid'),
        bindto: this.get('_waitForElement')
      }).then(function () {
        var chartConfig = {
          data: _this2.get('_valueForData'),
          axis: _this2.get('_valueForAxis'),
          grid: _this2.get('_valueForGrid'),
          bindto: _this2.get('_valueForElement')
        };

        // We need to disable transitions during test (they make tests flakey).
        if (_dieselConfigEnvironment['default'].environment === "test") {
          chartConfig.transition = { duration: null };
        }

        var chart = _c3['default'].generate(chartConfig);
        _this2.set('_chart', chart);
      });
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.get('_submitInitialElement')(this.$().get(0));
    },

    willDestroyElement: function willDestroyElement() {
      var _this3 = this;

      /* This is not elegant, but unfortunately, it's needed. We need to delay
       * destroying the c3 chart element (which delete all of its attributes),
       * otherwise running transitions might crash. In practice, this is not an
       * issue for end-users (at worst, `window.onerror` will be called, and they
       * won't even know...), but in testing, it is an issue: tests will
       * routinely crash when a transition attempts to complete after the object
       * has been torn down.
       *
       * Ideally, we would use callbacks to ensure we don't attempt to destroy
       * the element while transitions are running. Unfortunately, this is not
       * possible, because c3.js exposes a single onrendered callback we can use
       * to be notified when transitions end, but we can't differentiate between
       * multiple transitions, so if we schedule two transitions in a short
       * timespan (which could happen e.g. when we render memory limits), then we
       * end up thinking all our transitions have completed although perhaps only
       * one did.
       *
       * Now, we could use the same callbacks to ensure we don't schedule a
       * transition if we already scheduled one, but at this point we're adding a
       * substantial amount of complexity, and we run the risk of locking up the
       * chart if for some reason the onrendered callback isn't called (which
       * seems possible considering c3.js makes no guarantees as to when this
       * callback will be called), which doesn't seem worth if the only upside is
       * saving a few seconds on running tests.
       */
      _ember['default'].run.later(function () {
        var chart = _this3.get('_chart');
        if (chart) {
          chart.destroy();
          if (!_this3.isDestroyed) {
            _this3.set('_chart', null);
          }
        }
      }, 500);
    },

    dataDidChange: withObservedChartAttribute('data', function (data) {
      var _this4 = this;

      if (Object.keys(data).length === 0) {
        // Do not attempt to render with no data (which is fine for other attributes)
        return;
      }

      this._applyToChart(function () {
        _this4.get('_submitInitialData')(data);
      }, function (chart) {
        data.unload = true;
        chart.load(data);
      });
    }),

    gridDidChange: withObservedChartAttribute('grid', function (grid) {
      var _this5 = this;

      this._applyToChart(function () {
        _this5.get('_submitInitialGrid')(grid);
      }, function (chart) {
        ["x", "y"].forEach(function (axis) {
          if (grid[axis] && grid[axis].lines) {
            chart[axis + 'grids'](grid[axis].lines);
          }
        });
      });
    }),

    axisDidChange: withObservedChartAttribute('axis', function (axis) {
      var _this6 = this;

      this._applyToChart(function () {
        _this6.get('_submitInitialAxis')(axis);
      }, function (chart) {
        // TODO: Support more options?
        if (axis.y) {
          chart.axis.max(axis.y.max);
        }
      });
    }),

    _applyToChart: function _applyToChart(noChartCallback, updateChartCallback) {
      var chart = this.get('_chart');
      if (!chart) {
        noChartCallback();
        return;
      }

      updateChartCallback(chart);
    }
  });
});