define('diesel/components/invited-user-row/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'tr',
    classNames: ['aptable__pending-invite-row'],
    requiredScope: 'manage',
    permittable: null,
    invitation: null,
    actions: {
      resendInvitation: function resendInvitation(invitation) {
        this.sendAction('resendInvitation', invitation);
      },

      destroyInvitation: function destroyInvitation(invitation) {
        this.sendAction('destroyInvitation', invitation);
      }
    }
  });
});