define("diesel/welcome/plan/controller", ["exports", "ember", "diesel/utils/location"], function (exports, _ember, _dieselUtilsLocation) {
  exports["default"] = _ember["default"].Controller.extend({
    model: null,
    actions: {
      doLogout: function doLogout() {
        return this.get('session').close('application', {
          token: this.get('session.token')
        })["finally"](function () {
          return _dieselUtilsLocation["default"].replaceAndWait('/');
        });
      },
      refreshModel: function refreshModel() {
        // noop
      },
      savePlan: function savePlan() {
        this.transitionToRoute('welcome.payment-info');
      }
    }
  });
});