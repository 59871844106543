define('diesel/initializers/reroute-verification-code', ['exports', 'diesel/utils/url-parameters', 'diesel/utils/location'], function (exports, _dieselUtilsUrlParameters, _dieselUtilsLocation) {
  exports.initialize = initialize;

  function initialize(application) {
    application.deferReadiness();

    var verificationCode = (0, _dieselUtilsUrlParameters.getUrlParameter)(window.location, 'verification_code');
    var invitationId = (0, _dieselUtilsUrlParameters.getUrlParameter)(window.location, 'invitation_id');
    var resetCode = (0, _dieselUtilsUrlParameters.getUrlParameter)(window.location, 'reset_code');
    var userId = (0, _dieselUtilsUrlParameters.getUrlParameter)(window.location, 'user_id');
    if (resetCode) {
      _dieselUtilsLocation['default'].replace('/password/new/' + resetCode + '/' + userId);
    } else if (invitationId) {
      _dieselUtilsLocation['default'].replace('/claim/' + invitationId + '/' + verificationCode);
    } else if (verificationCode) {
      _dieselUtilsLocation['default'].replace('/verify/' + verificationCode);
    } else {
      application.advanceReadiness();
    }
  }

  exports['default'] = {
    name: 'reroute-verification-code',
    initialize: initialize
  };
});