define('diesel/models/invoice', ['exports', 'ember', 'ember-data', 'diesel/models/base'], function (exports, _ember, _emberData, _dieselModelsBase) {

  // TODO: Dedupe with projected-invoice?
  var OK_STATES = ['paid', 'overpaid', 'forgiven'];
  exports.OK_STATES = OK_STATES;
  var UNPAID_STATES = ['due', 'partially_forgiven'];

  exports.UNPAID_STATES = UNPAID_STATES;
  exports['default'] = _dieselModelsBase['default'].extend({
    isProjection: false,
    billingIteration: _emberData['default'].belongsTo('billing-iteration', { async: true }),

    state: _emberData['default'].attr('string'),

    startsAt: _emberData['default'].attr('iso-8601-timestamp'),
    endsAt: _emberData['default'].attr('iso-8601-timestamp'),
    dueAt: _emberData['default'].attr('iso-8601-timestamp'),
    // TODO: effectiveAt
    date: _emberData['default'].attr('iso-8601-timestamp'),

    totalCashValue: _emberData['default'].attr('number'),
    totalPretaxCashValue: _emberData['default'].attr('number'),
    totalSalesTaxValue: _emberData['default'].attr('number'),

    subtotals: _emberData['default'].attr('humps'),
    lineItems: _emberData['default'].attr('humps'),

    payments: _emberData['default'].hasMany('payment'),

    requiresPayment: _ember['default'].computed('state', function () {
      return UNPAID_STATES.includes(this.get('state'));
    })
  });
});