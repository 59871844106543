define('diesel/models/document', ['exports', 'ember-data', 'diesel/models/base', 'ember'], function (exports, _emberData, _dieselModelsBase, _ember) {
  var DEFAULT_DOCUMENT_EXPIRY_IN_YEARS = 1;

  exports.DEFAULT_DOCUMENT_EXPIRY_IN_YEARS = DEFAULT_DOCUMENT_EXPIRY_IN_YEARS;
  exports['default'] = _dieselModelsBase['default'].extend({
    app: _emberData['default'].attr(),

    createdAt: _emberData['default'].attr('iso-8601-timestamp'),
    expiresAt: _emberData['default'].attr('iso-8601-timestamp'),
    name: _emberData['default'].attr('string'),
    organizationUrl: _emberData['default'].attr('string'),
    organization: _emberData['default'].attr('string'),
    userUrl: _emberData['default'].attr('string'),
    appUrl: _emberData['default'].attr('string'),
    printVersionUrl: _emberData['default'].attr('string'),
    criterionId: _emberData['default'].attr(),
    criterion: _emberData['default'].belongsTo('criterion', { async: true }),
    assignment: _emberData['default'].belongsTo('assignment', { async: true }),
    dataJson: _emberData['default'].attr(),

    isExpired: _ember['default'].computed('expiresAt', 'createdAt', function () {
      var defaultExpiration = new Date(this.get('createdAt').getTime());
      defaultExpiration.setYear(defaultExpiration.getFullYear() + DEFAULT_DOCUMENT_EXPIRY_IN_YEARS);

      var expiresAt = this.get('expiresAt') || defaultExpiration;

      return new Date() > expiresAt;
    })
  });
});