define('diesel/apps/controller', ['exports', 'ember', 'diesel/config/environment'], function (exports, _ember, _dieselConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    newApp: null, // Set this to open new app modal
    sortBy: ['handle:asc'],

    persistedApps: _ember['default'].computed.filterBy('model', 'isNew', false),
    hasNoApps: _ember['default'].computed.equal('persistedApps.length', 0),
    // Apps by status
    deployedApps: _ember['default'].computed.filterBy('persistedApps', 'isProvisioned'),
    pendingApps: _ember['default'].computed.filterBy('persistedApps', 'isPending'),
    deprovisionedApps: _ember['default'].computed.filterBy('persistedApps', 'hasBeenDeprovisioned'),
    failedDeprovisionApps: _ember['default'].computed.filterBy('persistedApps', 'hasFailedDeprovision'),

    // Sorted apps by status
    sortedDeployedApps: _ember['default'].computed.sort('deployedApps', 'sortBy'),
    sortedPendingApps: _ember['default'].computed.sort('pendingApps', 'sortBy'),
    sortedDeprovisionedApps: _ember['default'].computed.sort('deprovisionedApps', 'sortBy'),
    sortedFailedDeprovisionApps: _ember['default'].computed.sort('failedDeprovisionApps', 'sortBy'),

    hasActive: _ember['default'].computed.gt('deployedApps.length', 0),
    hasPending: _ember['default'].computed.gt('pendingApps.length', 0),
    hasDeprovisioning: _ember['default'].computed.gt('deprovisionedApps.length', 0),
    hasFailedDeprovision: _ember['default'].computed.gt('failedDeprovisionApps.length', 0),
    showSortableHeader: _ember['default'].computed.or('hasPending', 'hasDeprovisioning'),

    scopesForCreateApp: ['admin', 'deploy', 'sensitive'],
    appUrlToAccountDeployments: _ember['default'].computed('account.id', function () {
      var url = _dieselConfigEnvironment['default'].aptibleHosts.appUrl + '/create/deployments?accounts=' + this.get('account.id');
      return url;
    }),
    ftuxEnabled: _ember['default'].computed('account.onboardingStatus', function () {
      var status = this.get('account.onboardingStatus');
      return _dieselConfigEnvironment['default'].featureFlags.showFTUX && status !== 'unknown';
    })
  });
});