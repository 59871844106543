define('diesel/components/db-selector-option/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    dbType: null,
    selectedDbType: null,
    selectCallback: function selectCallback() {
      return this;
    },

    actions: {
      selectDbType: function selectDbType(dbType) {
        this.get('selectCallback')(dbType);
      }
    }
  });
});