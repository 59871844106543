define('diesel/services/slot-machine', ['exports', 'ember', 'diesel/utils/ajax', 'diesel/config/environment'], function (exports, _ember, _dieselUtilsAjax, _dieselConfigEnvironment) {
  exports.criticalityIndex = criticalityIndex;
  exports.transformVulnerabilities = transformVulnerabilities;

  function criticalityIndex(x) {
    return ({
      'not-applicable-kernel': -10,
      unknown: -1,
      negligible: 0,
      low: 1,
      medium: 2,
      high: 3,
      critical: 4
    })[x.criticality] || 0;
  }

  function transformMeta(meta) {
    return _ember['default'].Object.create({
      scanDate: new Date(meta.scanDate),
      imageDate: new Date(meta.imageDate)
    });
  }

  function transformVulnerabilities(rawVulnerabilities) {
    return rawVulnerabilities.sort(function (x, y) {
      var criticalitySort = criticalityIndex(y) - criticalityIndex(x);
      if (criticalitySort !== 0) {
        return criticalitySort;
      }
      return y.id - x.id;
    }).map(function (v) {
      return _ember['default'].Object.create({
        url: v.url,
        title: v.title,
        criticality: v.criticality,
        packages: v.packages.map(function (p) {
          return _ember['default'].Object.create(p);
        })
      });
    });
  }

  exports['default'] = _ember['default'].Service.extend({
    session: _ember['default'].inject.service(),
    raven: _ember['default'].inject.service(),

    fetchReport: function fetchReport(image) {
      var _this = this;

      var accessToken = this.get("session.token.accessToken");

      var url = _dieselConfigEnvironment['default'].slotMachineBaseUri + '/scan/' + image.get('id');
      var headers = { "Authorization": 'Bearer ' + accessToken };

      return (0, _dieselUtilsAjax['default'])(url, { headers: headers }).then(function (response) {
        return _ember['default'].Object.create({
          meta: transformMeta(response.meta),
          vulnerabilities: transformVulnerabilities(response.vulnerabilities)
        });
      })['catch'](function (e) {
        if (!e.status || e.status >= 500) {
          _this.get('raven').captureException(e);
        }

        var m = e.responseJSON && e.responseJSON.message || 'An unknown error occurred fetching vulnerabilities';
        throw new Error(m);
      });
    }
  });
});