define('diesel/components/validation-feedback/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'span',
    classNames: ['input-group-addon'],
    /** @type {Boolean} Whether errors will show even if no value */
    hasSubmitted: false,
    isSaving: false,
    value: false,

    // Converting model state into easy computed properties
    hasValue: _ember['default'].computed.bool('value'),
    hasError: _ember['default'].computed.bool('errorText'),
    noError: _ember['default'].computed.not('showError'),
    notSaving: _ember['default'].computed.not('isSaving'),
    canValidate: _ember['default'].computed.or('hasValue', 'hasSubmitted'),

    // Computed used to determine visibility of different indicators using above
    // computed properties
    showSuccess: _ember['default'].computed.and('notSaving', 'canValidate', 'noError'),
    showError: _ember['default'].computed.and('notSaving', 'canValidate', 'hasError'),
    showSomething: _ember['default'].computed.or('showSuccess', 'showError'),
    showNothing: _ember['default'].computed.not('showSomething'),

    errorText: _ember['default'].computed('error.[]', 'fieldName', function () {
      var error = this.get('error');
      var fieldName = this.get('fieldName');

      // If multiple errors, join errors with space and comma
      if (Array.isArray(error) && error.join) {
        error = error.join(', ');
      }

      if (fieldName && error) {
        error = fieldName + ' ' + error;
      }

      return error;
    })
  });
});